import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {
    isLagring,
    erSisteStegType,
} from '../../../ducks/pamelding';
import Lagringspinner from "../../Lagringspinner";
import {getKursIdObject, getKursnavn} from "../../../ducks/kursinfo";
import ButtonForrigeSteg from "./ButtonForrigeSteg";
import ButtonAvbryt from "./ButtonAvbryt";
import ButtonFullfor from "./ButtonFullfor";
import ButtonNesteSteg from "./ButtonNesteSteg";
import ButtonTilbakeTilOversikt from "./ButtonTilbakeTilOversikt";
import ButtonTilbakeTilSoknader from "./ButtonTilbakeTilSoknader";
import Text from "../../../primitives/Text";

const StegActions = ({
                         type,
                         validator,
                         settTilbake,
                         actions,
                         onNextClick,
                     }) => {

    const kursnavn = useSelector(getKursnavn);
    const kursIdObject = useSelector(getKursIdObject);
    const soknadIsLagring = useSelector(isLagring);

    const {forrige, neste, fullfor, avbryt} = actions;

    if (settTilbake) {
        return (
            <ButtonTilbakeTilOversikt/>
        )
    }

    if (soknadIsLagring && erSisteStegType(type)) {
        return (
            <Lagringspinner>
                <Text
                    id='påmelding_til_kurset_kurset'
                    values={{
                        kursnavn: kursnavn,
                        kurskode: kursIdObject.kurskode,
                    }}
                />
            </Lagringspinner>
        )
    }

    return (
        <div className="actions">
            {forrige &&
            <ButtonForrigeSteg/>
            }
            {neste &&
            <ButtonNesteSteg validator={validator} onNextClick={onNextClick}/>
            }
            {fullfor &&
            <ButtonFullfor/>
            }
            <div className="padding-top">
                {avbryt ?
                    <ButtonAvbryt/>
                    :
                    <ButtonTilbakeTilSoknader/>
                }
            </div>
        </div>
    )
};

StegActions.propTypes = {
    type: PropTypes.string.isRequired,
    validator: PropTypes.bool.isRequired,
    settTilbake: PropTypes.bool.isRequired,
    actions: PropTypes.shape({
        forrige: PropTypes.bool.isRequired,
        neste: PropTypes.bool.isRequired,
        fullfor: PropTypes.bool.isRequired,
        avbryt: PropTypes.bool.isRequired,
    }),
};

export default StegActions;