import LoginButton from "./LoginButton";
import {EIDAS, FACEBOOK, FEIDE, IDPORTEN, LINKEDIN, TWITTER} from "../../utils/loginUtil";
import React from "react";
import {useSelector} from "react-redux";
import {
    APPEGENSKAPKODE_EIDAS, APPEGENSKAPKODE_FACEBOOK,
    APPEGENSKAPKODE_FEIDE,
    APPEGENSKAPKODE_IDPORTEN, APPEGENSKAPKODE_LINKEDIN, APPEGENSKAPKODE_TWITTER,
    isAppegenskapAktivert
} from "../../ducks/appegenskaper";
import Text from "../../primitives/Text";

const LoginMethods = () => {
    const isIDPortenTillat = useSelector(state => isAppegenskapAktivert(state, APPEGENSKAPKODE_IDPORTEN));
    const isFeideTillat = useSelector(state => isAppegenskapAktivert(state, APPEGENSKAPKODE_FEIDE));
    const isEIDASTillat = useSelector(state => isAppegenskapAktivert(state, APPEGENSKAPKODE_EIDAS));
    const isFacebookTillat = useSelector(state => isAppegenskapAktivert(state, APPEGENSKAPKODE_FACEBOOK));
    const isTwitterTillat = useSelector(state => isAppegenskapAktivert(state, APPEGENSKAPKODE_TWITTER));
    const isLinkedInTillat = useSelector(state => isAppegenskapAktivert(state, APPEGENSKAPKODE_LINKEDIN));

    const isSosialMediaTillat = isFacebookTillat || isTwitterTillat || isLinkedInTillat;
    const isOtherMethodsTillat = isIDPortenTillat || isFeideTillat || isEIDASTillat;

    return (
        <div className='login__box'>
            <h2>
                <Text id='logg_inn_med'/>
            </h2>
            {isOtherMethodsTillat &&
            <ul className="login__other-methods">
                {isIDPortenTillat && <li><LoginButton loginMethod={IDPORTEN}/></li>}
                {isFeideTillat && <li><LoginButton loginMethod={FEIDE}/></li>}
                {isEIDASTillat && <li><LoginButton loginMethod={EIDAS}/></li>}
            </ul>
            }
            {isOtherMethodsTillat && isSosialMediaTillat &&
            <Separator/>
            }
            {isSosialMediaTillat &&
            <div className="login__sosial-media">
                {isFacebookTillat && <LoginButton loginMethod={FACEBOOK}/>}
                {isTwitterTillat && <LoginButton loginMethod={TWITTER}/>}
                {isLinkedInTillat && <LoginButton loginMethod={LINKEDIN}/>}
            </div>
            }
        </div>
    )
};

const Separator = () =>
    <div className='login__methods-separator'>
        <div className='separator'/>
        <div className='text'><Text id='eller'/></div>
    </div>
;

export default LoginMethods;