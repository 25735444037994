export const getFulltKursVarsel = ({
                                       antallLedigePlasser,
                                       automatiskStenging,
                                       automatiskTilbud,
                                       automatiskJaSvar,
                                       automatiskVenteliste
                                   }, harFullfortSoknad = false,
) => {
    if (antallLedigePlasser !== undefined && antallLedigePlasser <= 0 && automatiskStenging && !harFullfortSoknad) {
        if (!automatiskTilbud && automatiskJaSvar && automatiskVenteliste) {
            return {
                varselTextId: 'kurset_er_fullt_og_det_er_derfor_ikke_lenger_mulig_å_søke_om_plass',
                varselTextForetakId: 'kurset_er_fullt_for_foretak_og_det_er_derfor_ikke_lenger_mulig_å_søke_om_plass',
                knappTextId: undefined,
                disabledPamelding: true,
            }
        }
        if (automatiskTilbud && !automatiskVenteliste) {
            return {
                varselTextId: 'kurset_er_fulltegnet_det_er_ikke_mulig_å_melde_seg_på',
                varselTextForetakId: 'kurset_er_fulltegnet_for_foretak_det_er_ikke_mulig_å_melde_seg_på',
                knappTextId: undefined,
                disabledPamelding: true,
            }
        }
        if (automatiskTilbud && automatiskVenteliste) {
            return {
                varselTextId: 'kurset_er_fulltegnet_men_du_kan_sette_deg_på_ventelista',
                varselTextForetakId: 'kurset_er_fulltegnet_for_foretak_men_du_kan_sette_deg_på_ventelista',
                knappTextId: 'sett_meg_på_ventelista',
                disabledPamelding: false,
            }
        }
        if (!automatiskTilbud && !automatiskVenteliste) {
            return {
                varselTextId: 'kurset_er_fullt_og_det_er_derfor_ikke_lenger_mulig_å_søke_om_plass',
                varselTextForetakId: 'kurset_er_fullt_for_foretak_og_det_er_derfor_ikke_lenger_mulig_å_søke_om_plass',
                knappTextId: undefined,
                disabledPamelding: true,
            }
        }
        if (!automatiskTilbud && !automatiskJaSvar) {
            return {
                varselTextId: 'kurset_er_fulltegnet_men_du_kan_søke_om_plass_på_ventelista',
                varselTextForetakId: 'kurset_er_fulltegnet_for_foretak_men_du_kan_søke_om_plass_på_ventelista',
                knappTextId: 'søk_om_ventelisteplass',
                disabledPamelding: false,
            }
        }
    }
    return {
        varselTextId: undefined,
        varselTextForetakId: undefined,
        knappTextId: undefined,
        disabledPamelding: false,
    }
};