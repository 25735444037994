import React from "react";
import PropTypes from "prop-types";

const Highlight = ({pattern, children}) => {
    if (!pattern) return children;
    const parts = children.split(new RegExp(`(${pattern})`, 'gi'));
    return parts.map((part, i) =>
        <span key={i} className={part.toLowerCase() === pattern.toLowerCase() ? 'highlight' : ''}>
            {part}
        </span>
    );
};

Highlight.propTypes = {
    pattern: PropTypes.string.isRequired,
};

export default Highlight;