import React from 'react';
import withTidligereKursLoaded from "../../high-order-components/withTidligereKursLoaded";
import {useDispatch, useSelector} from "react-redux";
import {getTidligereKurs} from "../../ducks/tidligereKurs";
import Feedback from "../Feedback";
import Text from "../../primitives/Text";
import Button from "../../primitives/Button";
import "./tidligereKurs.scss";
import Datoer from "./Datoer";
import {downloadDokument} from "../../services/dokumenterAPI";
import WSError from "../WSError";
import {getAktueltDeltakernr, getEvuapiToken} from "../../ducks/session";
import {showPopup} from "../../ducks/popup";
import {mdiCheck} from "@mdi/js";
import Icon from "../../primitives/Icon";


const TidligereKurs = () => {

    const kursliste = useSelector(getTidligereKurs);

    if (kursliste.length === 0) {
        return (
            <Feedback>
                <Text id='du_har_ingen_tidligere_kurs'/>
            </Feedback>
        )
    }

    return (
        <div className='tidligere-kurs'>
            <div className='tidligere-kurs__header'>
                <Text className='kurs' id={'kurs'}/>
                <Text className='datoer' id={'datoer'}/>
                <Text className='status' id={'status'}/>
                <Text className='kursbevis' id={'kursbevis'}/>
            </div>
            <ol>
                {kursliste.map((kurs, index) =>
                    <li className='tidligere-kurs__row' key={index}>
                        <div className='kurs'>{kurs.kursnavn}</div>
                        <Datoer datoFra={kurs.datoFra} datoTil={kurs.datoTil}/>
                        <Status isGodkjent={kurs.isGodkjent}/>
                        <Kursbevis kurs={kurs}/>
                    </li>
                )}
            </ol>
        </div>
    )
};


const Status = ({isGodkjent}) => {
    const icon = isGodkjent ? <Icon path={mdiCheck}/> : null;
    const statusTextId = isGodkjent ? 'godkjent' : 'ikke_godkjent';

    return <div className='status'>
        {icon}<Text id={statusTextId}/>
    </div>;
};


const Kursbevis = ({kurs}) => {
    const {dokumentnr, filnavn} = kurs;

    const evuApiToken = useSelector(getEvuapiToken);
    const deltakernr = useSelector(getAktueltDeltakernr);
    const dispatch = useDispatch();

    const download = () => {
        downloadDokument(dokumentnr, filnavn, evuApiToken, deltakernr)
            .catch(error => dispatch(showPopup(createErrorPopupContent(error))));
    };

    return (
        <div className='kursbevis'>
            {dokumentnr ?
                <Button onClick={download}>
                    <Text id='hent_kursbevis'/>
                </Button>
                :
                <Text id='kursbevis_er_ikke_utstedt'/>}
        </div>
    )
};


export const createErrorPopupContent = error => ({
    style: {wsError: true},
    children:
        <WSError
            error={error}
        />
});


export default withTidligereKursLoaded(TidligereKurs);
