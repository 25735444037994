import kursApi from '../services/kursApi';
import {getInstitusjonsnr} from "./session";
import {formatDate, getLocale, getTranslation} from "./i18n";
import {getValueByLanguage} from "../utils/common";

export const NAME = 'kursinfo';
const LOAD_KURSINFO_PENDING = `${NAME}/LOAD_KURSINFO_PENDING`;
const LOAD_KURSINFO_SUCCESS = `${NAME}/LOAD_KURSINFO_SUCCESS`;
const LOAD_KURSINFO_FAILURE = `${NAME}/LOAD_KURSINFO_FAILURE`;


export function loadKursinfoPending(kursId) {
    return {
        type: LOAD_KURSINFO_PENDING,
        kursId: kursId,
    };
}

export function loadKursinfoSuccess(kursinfo) {
    return {
        type: LOAD_KURSINFO_SUCCESS,
        data: kursinfo,
    };
}

export function loadKursinfoFailure(error) {
    return {
        type: LOAD_KURSINFO_FAILURE,
        error: error,
    };
}

export function loadKursinfo(kursId) {
    return function (dispatch, getState) {
        dispatch(loadKursinfoPending(kursId));
        const instnr = getInstitusjonsnr(getState());
        return kursApi.getKursinfo(instnr, kursId).then(
            kursinfo => dispatch(loadKursinfoSuccess(kursinfo)),
            error => dispatch(loadKursinfoFailure(error))
        );
    };
}


export const initialState = {
    isLoading: false,
    error: undefined,
    kursId: undefined,
    data: {},
};


function kursinfo(state = initialState, action) {
    switch (action.type) {
        case LOAD_KURSINFO_PENDING:
            return {
                ...state,
                isLoading: true,
                kursId: action.kursId,
                error: undefined
            };
        case LOAD_KURSINFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.data
            };
        case LOAD_KURSINFO_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                data: {...initialState.data}
            };
        default:
            return state
    }
}

export function getState(state) {
    return state[NAME];
}

export function getKursId(state) {
    return getState(state).kursId;
}

export function getData(state) {
    return getState(state).data;
}

export function getKurs(state) {
    const kurs = getData(state);
    const {kursnavn, kategorier, startdato, sluttdato, emner, beskrivelser, merknadKrav, nettsideKrav} = kurs;
    return {
        ...kurs,
        kursnavn: getTranslation(state, kursnavn),
        kategorier: kategorier.map(kategori => ({
            navn: getTranslation(state, kategori.kategorinavn),
        })),
        startdato: formatDate(state, startdato),
        sluttdato: formatDate(state, sluttdato),
        emner: emner.map(emne => ({
            ...emne,
            navn: getTranslation(state, emne.navn),
            vekttypenavn: getTranslation(state, emne.vekttypenavn),
        })),
        beskrivelse: getTranslation(state, beskrivelser),
        merknadKrav: getTranslation(state, merknadKrav),
        nettsideKrav: getTranslation(state, nettsideKrav),
    };
}


export function getMerknadskrav(state) {
    return getKurs(state).merknadKrav;
}

export function getNettsideKrav(state) {
    return getKurs(state).nettsideKrav;
}

export function getPris(state) {
    return getKurs(state).pris;
}

export function getDirektePamelding(state) {
    return getKurs(state).direktePamelding;
}

export function getAutomatiskJaSvar(state) {
    return getKurs(state).automatiskJaSvar;
}

export function getAutomatiskVenteliste(state) {
    return getKurs(state).automatiskVenteliste;
}

export function getKursIdObject(state) {
    return getData(state).id;
}

export function getKursnavn(state) {
    const locale = getLocale(state);
    const kursnavn = getData(state).kursnavn;
    return getValueByLanguage(kursnavn, locale);
}

export function getKursPameldingsfrist(state) {
  return getData(state).pameldingsfrist;
}

export function getKursStartdato(state) {
  return formatDate(state, getData(state).startdato);
}

export function isLoading(state) {
    return getState(state).isLoading;
}

export function getError(state) {
    if (isDataLoaded(state) && !getKursId(state)) {
        return 'Kurset finnes ikke.';
    }
    return getState(state).error;
}

export function isDataLoaded(state, kursId) {
    return Boolean(Object.keys(getData(state)).length) && getKursId(state) === kursId;
}


export function harGSKKrav(state) {
    return getData(state).harGSKKrav;
}

export const getHarKravOmHoyereUtd = state => getData(state).harKravOmHoyereUtd;

export const getHarBareAndreKrav = state => {
    const {harGSKKrav, harKravOmHoyereUtd, harKravOmPraksis, harAndreKrav} = getKurs(state);
    return harAndreKrav && !harGSKKrav && !harKravOmHoyereUtd && !harKravOmPraksis;
};

export default kursinfo;


