import React from 'react';
import PropTypes from "prop-types";
import './error-form-saving.scss';
import Text from "../primitives/Text";
import WSErrorDescription from "./WSErrorDescription";
import Button from "../primitives/Button";
import {tryAgainIconPath} from "../utils/icons";

const ErrorFormSaving = ({error, className = '', provIgjen}) => {
    const classes = `error-form-saving ${className}`;
    return (
        <div className={classes}>
            <div className='error-form-saving__text-wrapper'>
                <h4><Text id='lagring_feilet'/></h4>
                <WSErrorDescription error={error}/>
            </div>
            {provIgjen &&
                <Button
                    onClick={provIgjen}
                    iconPath={tryAgainIconPath}
                >
                    <Text id='prøv_igjen'/>
                </Button>
            }
        </div>
    );
};


export default ErrorFormSaving;


ErrorFormSaving.propTypes = {
    className: PropTypes.string,
    error: PropTypes.object,
};