import React from 'react';
import PropTypes from "prop-types";
import {
    ACTION_TYPE_SE, FRIST_UBEGRENSET,
} from "../../utils/soknadStatus";
import SoknadRowActionButton from "./SoknadRowActionButton";
import SoknadRowActionIcon from "./SoknadRowActionIcon";
import SoknadRowActionText, {ScreenReaderActionText} from "./SoknadRowActionText";
import FormattedDate from "../../primitives/FormattedDate";
import Text from "../../primitives/Text";

export const SoknadRowAction = props => {
    const {type, frist, status, kursId, kursnavn} = props;
    const innenforFrist = frist >= Date.now();
    return (
        <>
            <div className='action'>
                <SoknadRowActionButton
                    type={type}
                    kursId={kursId}
                    kursnavn={kursnavn}
                    status={status}
                    innenforFrist={innenforFrist}
                >
                    <SoknadRowActionIcon
                        type={type}
                        innenforFrist={innenforFrist}
                        status={status}
                    />
                    <SoknadRowActionText
                        type={type}
                        frist={frist}
                        status={status}
                    />
                </SoknadRowActionButton>
                {frist !== FRIST_UBEGRENSET && type !== ACTION_TYPE_SE &&
                <div className={'action__frist'}>
                    <Text
                        id='frist_dato'
                        values={{
                            dato: (
                                <>
                                    <ScreenReaderActionText type={type} status={status} />
                                    <FormattedDate date={frist} className={'frist-dato'}/>
                                </>
                            )
                        }}
                    />
                </div>
                }
            </div>
        </>
    );
};

SoknadRowAction.propTypes = {
    kursnavn: PropTypes.string,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    frist: PropTypes.object.isRequired,
    kursId: PropTypes.PropTypes.shape({
        kurskode: PropTypes.string.isRequired,
        tidskode: PropTypes.string.isRequired,
    }).isRequired,
};

export default SoknadRowAction;