import React from 'react';
import {
    getAntallAvvisteSoknader,
    getAvvisteSoknader,
} from "../../../ducks/soknader";
import {useSelector} from "react-redux";
import Card from "../Card";
import {soknaderIconPath} from "../../../utils/icons";
import {pages} from "../../../utils/common";
import withSoknaderLoaded from "../../../high-order-components/withSoknaderLoaded";
import {compose} from "redux";
import {requireDeltakernummer} from "../../../high-order-components/requireDeltakernummer";
import Text from "../../../primitives/Text";

const VarselAvvisteSoknader = () => {
    const antallAvvisteSoknader = useSelector(getAntallAvvisteSoknader);
    const avvisteSoknader = useSelector(getAvvisteSoknader);
    if (antallAvvisteSoknader === 0) return null;
    const soknad = avvisteSoknader[0];
    const values = {
        antallSoknader: antallAvvisteSoknader,
        kursnavn: soknad.kursnavn,
    };
    return (
        <Card
            tittel={<Text id='svar_på_søknad'/>}
            varsel={true}
            iconPath={soknaderIconPath}
            actions={{
                lenke: {
                    tekst: <Text id='gå_til_søknader'/>,
                    til: pages.soknader
                },
            }}
        >
            <Text
                id={antallAvvisteSoknader > 1 ? 'du_har_fått_svar_på_søknader' : 'du_har_fått_svar_på_søknaden_din'}
                values={values}
            />
        </Card>
    )
};

export default compose(
    Component => requireDeltakernummer(Component, false),
    withSoknaderLoaded,
)(VarselAvvisteSoknader);