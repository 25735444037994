import React from 'react';
import ReactDOM from 'react-dom';
import {createStore, applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk';
import {composeWithDevTools} from '@redux-devtools/extension';
import promise from 'es6-promise';
import "isomorphic-fetch";
import 'babel-polyfill';
import 'mdn-polyfills/Element.prototype.closest';
import {} from './utils/common'; // TODO: Finne ut hvorfor fjerning av denne importen gjør at dataporten token ikke settes i state
import App from './components/App'
import {initialReduxState} from './initialState'
import rootReducer from './rootReducer';
import './sass/less-fun.scss';
import './sass/main.scss';
import {sessionMiddleware} from "./middlewares/sessionMiddleware";
import {applyAxiosInterceptor} from "./middlewares/axiosMiddleware";
import {initialLoad} from "./ducks/initialLoad";
import {addEventListenerLogoutIfAPITokenRemoved, preventIframeLoading} from "./utils/environment";

preventIframeLoading();

promise.polyfill();

const store = createStore(
    rootReducer, initialReduxState(),
    composeWithDevTools(applyMiddleware(thunk, sessionMiddleware)));

applyAxiosInterceptor(store.getState, store.dispatch);

store.dispatch(initialLoad());

addEventListenerLogoutIfAPITokenRemoved(store);

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('app')
);