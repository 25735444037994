import {useSelector} from "react-redux";
import {getSamtykkerOversatt} from "../../ducks/samtykke";
import React from "react";

const UbesvarteSamtykkerListe = () => {
    const samtykker = useSelector(getSamtykkerOversatt);
    const ubesvarteSamtykker = samtykker.filter(samtykke => samtykke.besvarelse === undefined);
    return (
        <ul>
            {ubesvarteSamtykker.map(samtykke =>
                <li key={samtykke.kode} lang={samtykke.langSporsmal}>{samtykke.sporsmal}</li>
            )}
        </ul>
    )
};

export default UbesvarteSamtykkerListe;