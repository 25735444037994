import React from 'react';
import {isLoggedInDataporten, isManglerBrukerInnloggingsError} from "../ducks/session";
import {connect} from "react-redux";
import {pages} from "../utils/common";
import Redirect from "../components/Redirect";

function handleManglerBruker(Component) {

    const mapStateToProps = state => {
        return {
            isLoggedInDataporten: isLoggedInDataporten(state),
            manglerBrukerInnloggingsError: isManglerBrukerInnloggingsError(state),
        }
    };

    return connect(mapStateToProps)(
        class extends React.Component {
            render() {
                const {isLoggedInDataporten, manglerBrukerInnloggingsError} = this.props;

                if (isLoggedInDataporten && manglerBrukerInnloggingsError) {
                    return <Redirect to={pages.nyBruker}/>;
                }

                return <Component/>;
            }
        }
    )
}

export default handleManglerBruker