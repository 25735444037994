import Button from "../../../primitives/Button";
import {showPopup} from "../../../ducks/popup";
import {endreIconPath} from "../../../utils/icons";
import React from "react";
import {useDispatch} from "react-redux";
import Text from "../../../primitives/Text";
import {KorrAdvarsel} from "./KorrAdvarsel";


export const KorrHelpButton = ({mobil}) => {
    const dispatch = useDispatch();
    return (
        <Button onClick={() => dispatch(showPopup(getPopupContent(mobil)))}
                id={mobil ? "privat_mobil" : "privat_epost"}
                iconPath={endreIconPath}
                altText={mobil ? 'trykk_for_å_redigere_mobil' : 'trykk_for_å_redigere_epost'}
                className='help-button'
        />
    )
};

export const getPopupContent = (mobil) => {
    const onCloseClick  = () => {
        setTimeout(function() {
            document.getElementById(mobil ? "privat_mobil" : "privat_epost").focus();
        }, 100);
    }

    return {
        title: <Text id='kontakt_og_reservasjonsregisteret'/>,
        children: <KorrAdvarsel mobil={mobil ? "privat_mobil" : "privat_epost"}/>,
        okButtonContent: <Text id='lukk'/>,
        onCancel: onCloseClick,
        onOK: onCloseClick,
    };
};

export default KorrHelpButton;