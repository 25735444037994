import React from "react"
import * as routes from "./apiRoutes";
import axios from "axios/index";
import {parseIsoDate, sanitizeHtmlText} from "../utils/common";
import {createRequestConfig, downloadFile} from "../utils/request";
import orderBy from "lodash/orderBy";
import {createDokument, createEpost, createSms} from "../ducks/inngaendeDokumenter";
import Text from "../primitives/Text";


export const GSK_DOKUMENTTYPE = 'GSKDOK';
export const ARBEIDSERFARING_DOKUMENTTYPE = 'PRAKSIS';
export const HOYERE_UTDANNING__DOKUMENTTYPE = 'KARVIT';
export const ANNET_DOKUMENTTYPE = 'ANNET';


export const getDokumenterTyper = () => [
    {value: GSK_DOKUMENTTYPE, label: <Text id='generell_studiekompetanse'/>},
    {value: ARBEIDSERFARING_DOKUMENTTYPE, label: <Text id='arbeidserfaring'/>},
    {value: HOYERE_UTDANNING__DOKUMENTTYPE, label: <Text id='høyere_utdanning'/>},
    {value: ANNET_DOKUMENTTYPE, label: <Text id='annet'/>},
];


export const getDokumentLabelByDokumentType = Type => {
    const dokumentType = getDokumenterTyper().find(item => item.value === Type);
    return dokumentType && dokumentType.label;
}


export function getDokumenter(deltakernr, evuapiToken) {

    const route = routes.DOKUMENTER.replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken, {status_inn: true});

    return axios.get(route, config)
        .then(response => {
            return transformOutput(response.data);
        })
        .catch(error => {
            throw error;
        });
}


export function fetchInngaendeDokumenter(deltakernr, evuapiToken) {

    const route = routes.DOKUMENTER.replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken, {status_inn: false});

    return axios.get(route, config)
        .then(response => {
            return (transformInngaendeDokumenterFromWS(response.data));
        })
        .catch(error => {
            throw error;
        });
}


export function transformInngaendeDokumenterFromWS(data) {
    const inngaendeDokumenter = [
        ...data.dokumenter.map(dokument => createDokument({
            ...dokument,
            datoMottatt: parseIsoDate(dokument.dato_sendt),
        })),
        ...data.mail.map(epost => createEpost({
            ...epost,
            datoMottatt: parseIsoDate(epost.dato_sendt),
            innhold: sanitizeHtmlText(epost.innhold),
        })),
        ...data.smser.map(sms => createSms({
            ...sms,
            datoMottatt: parseIsoDate(sms.dato_sendt),
            innhold: sms.smstekst,
        })),
    ];

    return orderBy(inngaendeDokumenter, ['datoMottatt'], ['desc']);
}


export async function downloadDokument(dokumentId, filnavn, evuapiToken, deltakernr) {
    const route = routes.DOKUMENT.replace(':dokumentid', dokumentId).replace(':deltakernr', deltakernr);
    await downloadFile(route, evuapiToken, filnavn);
}

export function deleteDokument(id, evuapiToken, deltakernr) {

    const route = routes.DOKUMENT.replace(':dokumentid', id)
        .replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);

    return axios.delete(route, config)
        .catch(error => {
            throw error;
        });

}

function transformOutput(data) {
    const dokumenter = data.dokumenter.map(dokument => transformDokumentFromWS(dokument));
    return orderBy(dokumenter, 'datoOpplastet', 'desc');
}


function transformDokumentFromWS(dokument) {
    return {
        id: dokument.dokumentnr,
        datoOpplastet: dokument.dateRecievedObject || (dokument.dato_mottatt ? parseIsoDate(dokument.dato_mottatt) : undefined),
        filnavn: dokument.filnavn,
        sperreSletting: dokument.sletting_sperret,
        type:transformDokumenttype(dokument.dokumenttype),
    }
}

const transformDokumenttype = type => {
    const tillateTyper = [GSK_DOKUMENTTYPE, HOYERE_UTDANNING__DOKUMENTTYPE, ARBEIDSERFARING_DOKUMENTTYPE, ANNET_DOKUMENTTYPE];
    return tillateTyper.includes(type) ? type : ANNET_DOKUMENTTYPE;
};


export function transformFileForWS(file, base64EncodedFile, type) {
    return {
        "dokumenttype": type || ANNET_DOKUMENTTYPE,
        "filnavn": file.name,
        "extension": file.name.split('.').pop().toUpperCase(),
        "dokument": base64EncodedFile
    };
}


export const uploadDokument = async (file, deltakernr, evuapiToken, type) => {

    const route = routes.DOKUMENT_UPLOAD.replace(':deltakernr', deltakernr);
    const config = createRequestConfig(evuapiToken);

    const base64EncodedFile = await getBase64(file);
    const input = transformFileForWS(file, base64EncodedFile, type);

    const response = await axios.post(route, input, config);

    return makeSavedDok(file.name, response, type);

};


export function getBase64(file) {

    function removeFileDescription(result) {
        return result.split(',').pop();
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(removeFileDescription(reader.result));
        reader.onerror = error => reject(error);
    });
}


//midlertidig løsning, håper å få dette i response fra ws eller på en annen måte
function makeSavedDok(filename, response, type) {
    return transformDokumentFromWS(
        {
            dokumentnr: parseInt(response.data.self.split('/').pop()),
            dateRecievedObject: new Date(),
            filnavn: filename,
            sletting_sperret: true,
            dokumenttype: type,
        }
    )

}

