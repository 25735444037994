import React from "react";
import Text from "../../../primitives/Text";
import './kursmangler.scss';

const Kursmangler = ({kursmangler}) => {

    if (kursmangler.length === 0) {
        return null;
    }

    return (
        <div className='kursmangler'>
            <h4>
                <Text id={'vi_kan_ikke_se_at_du_har_dokumentert_følgende_krav'}/>
            </h4>
            <ul>
                {kursmangler.map(kursmangel =>
                    <li key={kursmangel.mangelkode}>
                        <span className='punkt'>•</span>
                        {kursmangel.mangeltekst}
                    </li>
                )}
            </ul>
            <div>
                <Text id={'last_opp_manglende_dokumentasjon_under_kategoriene_nederst'}/>
            </div>
        </div>
    )
};

export default Kursmangler;