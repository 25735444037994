import {createSlice} from '@reduxjs/toolkit';
import {getAktueltPersonlopenr, getEvuapiToken} from "./session";
import {getForetak, postGodkjenninger} from "../services/foretaksgodkjenningAPI";
import {getTranslation} from "./i18n";
import {showPopup} from "./popup";
import {createErrorPopupContent} from "../utils/request";
import GodkjenningsFeil from "../components/foretaksgodkjenning/soknader/GodkjenningsFeil";
import React from "react";

export const NAME = 'foretaksgodkjenning';

export const createForetak = ({kode, navn}) => ({
    kode,
    navn,
});

export const createForetakKurs = ({kursId, navn = [], foretakkode, collapsed = false, skjulBesvarteSoknader = true, hash, harGodkjenning = true, godkjenningsfrist, makskapasitet}) => ({
    kursId,
    navn,
    foretakkode,
    collapsed,
    skjulBesvarteSoknader,
    hash,
    harGodkjenning,
    godkjenningsfrist,
    makskapasitet,
});

export const createForetakSoknad = ({deltakernr, kursId, foretakkode, navn, epost, isGodkjent, status, pending = false}) => ({
    deltakernr,
    kursId,
    foretakkode,
    navn,
    epost,
    isGodkjent,
    status,
    pending,
});

export const createGodkjenning = ({
                                      deltakernr,
                                      isGodkjent,
                                  }) => ({
    deltakernr,
    isGodkjent,
});

export const initialState = {
    loading: false,
    isDataLoaded: false,
    error: undefined,
    selectedForetakkode: undefined,
    foretak: [],
    kurs: [],
    soknader: [],
};


export const loadForetak = () => (dispatch, getState) => {
    dispatch(fetchPending());
    const evuapiToken = getEvuapiToken(getState());
    const personlopenr = getAktueltPersonlopenr(getState());
    return getForetak(evuapiToken, personlopenr).then(
        result => dispatch(fetchSuccess(result)),
        error => dispatch(fetchFailure(error))
    );
};

export const godkjennSoknader = (kursId, foretakkode, godkjenninger) => async (dispatch, getState) => {

    dispatch(godkjennSoknaderPending({
        kursId, foretakkode, godkjenninger
    }));

    try {
        const evuapiToken = getEvuapiToken(getState());
        const personlopenr = getAktueltPersonlopenr(getState());
        const result = await postGodkjenninger(evuapiToken, personlopenr, foretakkode, kursId, godkjenninger);
        dispatch(godkjennSoknaderSuccess({
            kursId, foretakkode, godkjenninger: result
        }));
        dispatch(visVarselOmFeil(result));
    } catch(error){
        dispatch(showPopup(createErrorPopupContent(error)));
        dispatch(godkjennSoknaderFailure({
            kursId, foretakkode, godkjenninger
        }));
    }

};

export const visVarselOmFeil = godkjenninger => dispatch => {
    const errors = godkjenninger.filter(godkjenning => Boolean(godkjenning.error));
    if(errors.length > 0){
        const popupContent = {
            children: <GodkjenningsFeil antallFeil={errors.length} antallGodkjenninger={godkjenninger.length}/>
        };
        dispatch(showPopup(popupContent))
    }
};

export const godkjennUbesvarteSoknader = (kursId, foretakkode, isGodkjent) => (dispatch, getState) => {
    const soknader = getUbesvarteSoknaderForKurs(getState(), kursId, foretakkode);
    const godkjenninger = soknader.map(soknad => createGodkjenning({
        deltakernr: soknad.deltakernr,
        isGodkjent,
    }));
    dispatch(godkjennSoknader(kursId, foretakkode, godkjenninger));
};

const {actions, reducer} = createSlice({
    initialState,
    name: NAME,
    reducers: {
        fetchPending: (state) => {
            state.loading = true;
            state.error = undefined;
        },
        fetchSuccess: (state, {payload}) => {
            const {foretak, kurs, soknader} = payload;
            state.loading = false;
            state.isDataLoaded = true;
            state.foretak = foretak;
            state.kurs = kurs;
            state.soknader = soknader;
            if (!state.selectedForetakkode && foretak.length > 0) state.selectedForetakkode = foretak[0].kode;
        },
        fetchFailure: (state, {payload}) => {
            state.loading = false;
            state.isDataLoaded = true;
            state.error = payload;
        },
        setSelectedForetakkode: (state, {payload}) => {
            state.selectedForetakkode = payload;
        },
        toggleCollapseKurs: (state, {payload}) => {
            const index = state.kurs.findIndex(kurs => kurs.kursId === payload);
            const kurs = state.kurs[index];
            kurs.collapsed = !kurs.collapsed;
        },
        toggleSkjulBesvarteSoknader: (state, {payload}) => {
            const index = state.kurs.findIndex(kurs => kurs.kursId === payload);
            const kurs = state.kurs[index];
            kurs.skjulBesvarteSoknader = !kurs.skjulBesvarteSoknader;
        },
        godkjennSoknaderPending: (state, {payload}) => {
            const {foretakkode, kursId, godkjenninger} = payload;
            state.soknader = state.soknader.map(soknad => ({
                ...soknad,
                pending: soknadInGodkjenninger(foretakkode, kursId, godkjenninger, soknad) ? true : soknad.pending,
            }))
        },
        godkjennSoknaderSuccess: (state, {payload}) => {
            const {foretakkode, kursId, godkjenninger} = payload;
            state.soknader = state.soknader.map(soknad => {
                const isInGodkjenninger = soknadInGodkjenninger(foretakkode, kursId, godkjenninger, soknad);
                const {status, isGodkjent, error} = godkjenninger.find(({deltakernr}) => deltakernr === soknad.deltakernr) || {};
                return {
                    ...soknad,
                    isGodkjent: isInGodkjenninger && !error ? isGodkjent : soknad.isGodkjent,
                    pending: isInGodkjenninger ? false : soknad.pending,
                    status: isInGodkjenninger && status ? status : soknad.status,
                }
            })
        },
        godkjennSoknaderFailure: (state, {payload}) => {
            const {foretakkode, kursId, godkjenninger} = payload;
            state.soknader = state.soknader.map(soknad => ({
                ...soknad,
                pending: soknadInGodkjenninger(foretakkode, kursId, godkjenninger, soknad) ? false : soknad.pending,
            }))
        },
    }
});

const soknadInGodkjenninger = (foretakkode, kursId, godkjenninger, soknad) =>
    foretakkode === soknad.foretakkode
    && kursId === soknad.kursId
    && godkjenninger.some(({deltakernr}) => deltakernr === soknad.deltakernr);

export const {
    fetchPending,
    fetchSuccess,
    fetchFailure,
    setSelectedForetakkode,
    toggleCollapseKurs,
    toggleSkjulBesvarteSoknader,
    godkjennSoknaderPending,
    godkjennSoknaderSuccess,
    godkjennSoknaderFailure,
} = actions;

export default reducer;

const getState = state => state[NAME];

export const getError = state => getState(state).error;
export const isLoading = state => getState(state).loading;
export const isDataLoaded = state => getState(state).isDataLoaded;

export const getForetaksListe = state => getState(state).foretak;
export const getForetaksListeAsSelectOptions = state => getForetaksListe(state).map(({kode, navn}) => ({
    value: kode,
    label: navn
}));

export const getKurs = state => getState(state).kurs;
export const getKursFromId = (state, kursId, foretakkode) => getKurs(state).find(kurs => kurs.kursId === kursId && kurs.foretakkode === foretakkode);
export const getSoknader = state => getState(state).soknader;

export const getSelectedForetaksKode = state => getState(state).selectedForetakkode;
export const getSelectedForetaksKurs = state => getKurs(state).filter(kurs => kurs.foretakkode === getSelectedForetaksKode(state));
export const getSelectedForetaksKursOversatt = state => getSelectedForetaksKurs(state).map(kurs => ({
    ...kurs,
    navn: getTranslation(state, kurs.navn),
}));

export const getSoknaderForKurs = (state, kursId, foretakkode) => getSoknader(state).filter(soknad => soknad.kursId === kursId && soknad.foretakkode === foretakkode);
export const getSoknaderForKursFiltrert = (state, kursId, foretakkode) => {
    const soknaderForKurs = getSoknaderForKurs(state, kursId, foretakkode);
    const {skjulBesvarteSoknader} = getKursFromId(state, kursId, foretakkode);
    return skjulBesvarteSoknader ? soknaderForKurs.filter(soknad => soknad.isGodkjent === undefined) : soknaderForKurs;
};

export const getAntallSoknaderForKurs = (state, kursId, foretakkode) => getSoknaderForKurs(state, kursId, foretakkode).length;

export const getGodkjenteSoknaderForKurs = (state, kursId, foretakkode) => getSoknaderForKurs(state, kursId, foretakkode).filter(soknad => soknad.isGodkjent);
export const getAntallGodkjenteSoknaderForKurs = (state, kursId, foretakkode) => getGodkjenteSoknaderForKurs(state, kursId, foretakkode).length;

export const getUbesvarteSoknaderForKurs = (state, kursId, foretakkode) => getSoknaderForKurs(state, kursId, foretakkode).filter(soknad => soknad.isGodkjent === undefined && !soknad.pending);
export const getAntallUbesvarteSoknaderForKurs = (state, kursId, foretakkode) => getUbesvarteSoknaderForKurs(state, kursId, foretakkode).length;

export const getFinnesBesvarteSoknader = (state, kursId, foretakkode) => getSoknaderForKurs(state, kursId, foretakkode).some(soknad => soknad.isGodkjent !== undefined);

export const getKursHarGodkjenning = (state, kursId, foretakkode) => getKursFromId(state, kursId, foretakkode).harGodkjenning;
export const getGodkjenningsfristForKurs = (state, kursId, foretakkode) => getKursFromId(state, kursId, foretakkode).godkjenningsfrist;
export const getSoknaderForKursKanGodkjennes = (state, kursId, foretakkode) => {
    const godkjenningsfrist = getGodkjenningsfristForKurs(state, kursId, foretakkode);
    return !godkjenningsfrist || godkjenningsfrist > Date.now();
};

export const getAntallSoknaderTotalt = state => getSoknader(state).length;
export const getUbesvarteSoknader = state => getSoknader(state).filter(soknad => soknad.isGodkjent === undefined);
export const getAntallUbesvarteSoknaderTotalt = state => getUbesvarteSoknader(state).length;

export const getTidligsteGodkjenningsfristForUbesvarteSoknader = state =>
    getUbesvarteSoknader(state).reduce((acc, cur) => {
        const {godkjenningsfrist} = getKursFromId(state, cur.kursId, cur.foretakkode);
        return godkjenningsfrist < acc || !acc ? godkjenningsfrist : acc
    }, undefined);