import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import Text from "../../../primitives/Text";
import {useDispatch, useSelector} from "react-redux";
import {
    getEditIsUploading,
    getEditTempFileUpload, getEditUploadError,
    setTempFile,
    uploadTempFile
} from "../../../ducks/arbeidserfaring";
import Spinner from "../../Spinner";
import Button from "../../../primitives/Button";
import WSError from "../../WSError";
import {tryAgainIconPath, uploadIconPath} from "../../../utils/icons";
import Icon from "../../../primitives/Icon";
import {mdiFileOutline} from "@mdi/js";

const Dropzone = ({id}) => {
    const dispatch = useDispatch();
    const tempFile = useSelector(getEditTempFileUpload);
    const isUploading = useSelector(getEditIsUploading);
    const {name: tempFileName} = tempFile || {};
    const onDrop = useCallback(acceptedFiles => {
        dispatch(setTempFile(id, acceptedFiles))
    }, [dispatch, id]);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    if (isUploading) {
        return (
            <div className='dropzone'>
                <Spinner/>
                <Text id={'laster_opp_fil'}/>
            </div>
        )
    }
    return (
        <>
            <div {...getRootProps()} className='dropzone'>
                <input {...getInputProps()} />
                <DropzoneInnerContent isDragActive={isDragActive} tempFileName={tempFileName}/>
            </div>
            <UploadError id={id}/>
        </>
    )
};

const DropzoneInnerContent = ({isDragActive, tempFileName}) =>
    (!isDragActive && tempFileName) ?
        <>
            <Icon path={mdiFileOutline}/>
            {tempFileName}
        </>
        :
        <>
            <Icon path={uploadIconPath}/>
            <Text
                id={isDragActive ? 'slipp_fil_her' : 'trekk_inn_fil_for_å_laste_opp_eller_bla'}
                values={{
                    bla_igjennom: <span className='link'><Text id='bla_igjennom'/></span>
                }}
            />
        </>;

const UploadError = ({id}) => {
    const dispatch = useDispatch();
    const error = useSelector(getEditUploadError);
    if (!error) return null;
    return (
        <>
            <WSError error={error}/>
            <Button
                onClick={()=>dispatch(uploadTempFile(id))}
                iconPath={tryAgainIconPath}
                className='prov-igjen'
            >
                <Text id={'prøv_igjen'}/>
            </Button>
        </>
    )
};

export default Dropzone;