import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getEvuapiToken,
    getLoginError,
    loadEvuApiToken,
    isManglerBrukerInnloggingsError,
} from "../ducks/session";
import Laster from "../components/Laster";
import WSError from "../components/WSError";

export const withEvuApiTokenLoaded = Component => () => {
    const evuapiToken = useSelector(getEvuapiToken);
    const manglerBrukerInnloggingsError = useSelector(isManglerBrukerInnloggingsError);
    const loginError = useSelector(getLoginError);
    const dispatch = useDispatch();

    const isEvuApiTokenLoadingDone = evuapiToken || loginError;

    useEffect(() => {
        if (!isEvuApiTokenLoadingDone) {
            dispatch(loadEvuApiToken());
        }
    }, [isEvuApiTokenLoadingDone, dispatch]);

    if (!isEvuApiTokenLoadingDone) {
        return <Laster/>;
    }

    if (loginError && !manglerBrukerInnloggingsError) {
        return <WSError error={loginError}/>;
    }

    return <Component/>;
};