import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Laster from "../components/Laster";
import WSError from '../components/WSError';
import Text from "../primitives/Text";

export default class InitialLoadWrapperOld extends Component {

    constructor(props) {

        super(props);

        const {isDataLoaded, isLoading, populateData, args} = props;

        if (!isDataLoaded && !isLoading) {
            populateData(args)
        }

    }

    render() {

        const {isDataLoaded, isLoading, error, loadMessage, children} = this.props;

        if (error) {
            return <WSError error={error}/>;
        }

        if (isLoading || !isDataLoaded) {
            return (
                <Laster>
                    {loadMessage || <Text id='laster_inn' />}
                </Laster>
            )
        }

        return children;
    }

}

InitialLoadWrapperOld.propTypes = {
    isDataLoaded: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    populateData: PropTypes.func.isRequired,
    error: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
    loadMessage: PropTypes.node,
    children: PropTypes.node.isRequired,
    args: PropTypes.any
};