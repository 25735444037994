import FormattedDate from "../../primitives/FormattedDate";
import Button from "../../primitives/Button";
import Text from "../../primitives/Text";
import React from "react";
import {injectIntl} from "react-intl";
import Betalingsfrist from "./Betalingsfrist";
import PropTypes from "prop-types";
import FormattedAmount from "../../primitives/FormattedAmount";
import {useDownloadFaktura} from "../../hooks/useDownloadFaktura";
import {betalingsfristUtgarSnart} from "../../utils/fakturaUtil";
import {mdiClockAlertOutline, mdiDownload} from "@mdi/js";
import Icon from "../../primitives/Icon";


export const UtestaendeFakturaRow = ({betalingsfrist, datoPurret, fakturanr, belop, datoOpprettet, betalingsfristOpprinnelig}) => {

    const downloadFaktura = useDownloadFaktura(fakturanr);
    const fristUtgarSnart = datoPurret || betalingsfristUtgarSnart(betalingsfrist);
    const className = `table-row ${datoPurret && 'purret'} ${fristUtgarSnart && 'frist-utgar-snart'}`;

    return (
        <li className={className}>
            <div className='dato'>
                <Text className='header' id='dato'/>
                <FormattedDate date={datoOpprettet}/>
            </div>
            <div className='nummer'>
                <Text className='header' id='nummer'/>
                {fakturanr}
            </div>
            <Alert datoPurret={datoPurret} fristUtgarSnart={fristUtgarSnart}/>
            <Betalingsfrist betalingsfrist={betalingsfrist} datoPurret={datoPurret}
                            betalingsfristOpprinnelig={betalingsfristOpprinnelig}/>
            <div className='belop'>
                <Text className='header' id='beløp'/>
                <FormattedAmount amount={belop}/>
            </div>
            <div className='actions'>
                <Button onClick={downloadFaktura}
                        iconPath={mdiDownload}
                >
                    <Text id='hent_faktura'/>
                </Button>
            </div>
        </li>
    )

};

const Alert = injectIntl(
    ({datoPurret, fristUtgarSnart, intl}) => (
        <div className='alert'>{
            (datoPurret || fristUtgarSnart) &&
            <Icon path={mdiClockAlertOutline} title='pass_på_fristen'/>
        }
        </div>
    ));


export default UtestaendeFakturaRow;


UtestaendeFakturaRow.propTypes = {
    betalingsfrist: PropTypes.object,
    datoPurret: PropTypes.object,
    fakturanr: PropTypes.number,
    belop: PropTypes.number,
    datoOpprettet: PropTypes.object,
    betalingsfristOpprinnelig: PropTypes.object,
};