import React, {useEffect, useRef} from 'react';
import PropTypes from "prop-types";

const CopyText = ({text}) => {
    const ref = useRef(null);
    useEffect(() => {
        ref.current.select();
        ref.current.setSelectionRange(0, 99999);
        document.execCommand('copy');
    }, [ref]);
    return (
        <div
            style={{
                width: "1px",
                height: "1px",
                padding: 0,
                margin: 0,
                opacity: 0,
                overflow: 'hidden'
            }}>
        <textarea
            defaultValue={text}
            ref={ref}
        />
        </div>
    )
};

CopyText.propTypes = {
    text: PropTypes.string.isRequired,
};

export default CopyText;