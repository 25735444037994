import React from "react";
import Input from "../../../primitives/Input";
import InPlaceError from "../../InPlaceError";

const InputFodselsdato = ({isFocusOn, value, errorMessageId, onChange}) => {
    return (
        <>
            <Input
                autoFocus={isFocusOn}
                label='fodselsdato'
                type='date'
                value={value}
                hasError={Boolean(errorMessageId)}
                onChange={event => onChange(event.target.value)}
                autoComplete='bday'
            />
            <InPlaceError errorMessageId={errorMessageId}/>
        </>
    )
};

export default InputFodselsdato;