import React from 'react';
import SporreskjemaSporsmal from "./SporreskjemaSporsmal";
import {useDispatch, useSelector} from "react-redux";
import {
    getSporsmal, getIsSporreskjemaValid,
    sendSporreskjema,
    setPolarResponse,
    setTextResponse
} from "../../ducks/sporreskjema";
import {getForsoktNeste} from "../../ducks/pamelding";
import withSporreskjemaLoaded from "../../high-order-components/withSporreskjemaLoaded";
import Feedback, {FEEDBACK_TYPE_FEIL} from "../Feedback";
import Text from "../../primitives/Text";

const Sporreskjema = () => {
    const sporsmal = useSelector(getSporsmal);
    const showErrors = useSelector(getForsoktNeste);
    const isSporreskjsemaValid = useSelector(getIsSporreskjemaValid);
    const dispatch = useDispatch();

    return (
        <div className={`sporreskjema`}>
            {sporsmal.map((sporsmal, index) =>
                <SporreskjemaSporsmal
                    showError={showErrors}
                    key={index}
                    setPolarResponse={response => dispatch(setPolarResponse(index, response))}
                    setTextResponse={response => dispatch(setTextResponse(index, response))}
                    sendSvar={() => dispatch(sendSporreskjema(index))}
                    {...sporsmal}
                />
            )}
            {!isSporreskjsemaValid && showErrors &&
            <Feedback type={FEEDBACK_TYPE_FEIL}>
                <Text id='spørreskjema_inneholder_feil'/>
            </Feedback>
            }
        </div>
    )
};

export default withSporreskjemaLoaded(Sporreskjema);