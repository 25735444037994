import React from 'react';
import {connect} from "react-redux";
import {isDataLoaded, isKurskatalogAktivert} from "../ducks/appegenskaper";
import Laster from "../components/Laster";
import Text from "../primitives/Text";
import Redirect from "../components/Redirect";

function requireKurskatalogAktivert(Component, redirectTo) {

    const mapStateToProps = state => {
        return {
            isKurskatalogAktivert: isKurskatalogAktivert(state),
            isAppegenskaperLoaded: isDataLoaded(state),
        }
    };

    return connect(mapStateToProps)(
        class extends React.Component {
            render() {
                const {isKurskatalogAktivert, isAppegenskaperLoaded} = this.props;

                if (!isAppegenskaperLoaded) {
                    return redirectTo ?
                        <Laster>
                            <Text id={'laster_institusjons_innstillinger'}/>
                        </Laster>
                        :
                        null;
                }

                if (isKurskatalogAktivert) {
                    return <Component {...this.props}/>;
                }

                return redirectTo ? <Redirect to={redirectTo}/>: null;
            }
        }
    )
}


export default requireKurskatalogAktivert;