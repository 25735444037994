import React from 'react';
import Text from "../../primitives/Text";
import './inngaendeDokumenter.scss';
import {useSelector} from "react-redux";
import {
    DOKUMENT_DOKUMENTTYPE,
    EPOST_DOKUMENTTYPE,
    getInngaendeDokumenter,
    SMS_DOKUMENTTYPE
} from "../../ducks/inngaendeDokumenter";
import Feedback from "../Feedback";
import withInngaendeDokumenterLoaded from "../../high-order-components/withInngaendeDokumenterLoaded";
import Epost from "./Epost";
import Sms from "./Sms";
import Dokument from "./Dokument";


const InngaendeDokumenter = () => {

    const dokumenter = useSelector(state => getInngaendeDokumenter(state));

    if (dokumenter.length === 0) {
        return <Feedback>
            <Text id='du_har_ingen_inngående_dokumenter'/>
        </Feedback>
    }

    return <div className='inngaende-dokumenter'>
        <div className='inngaende-dokumenter__header'>
            <Text className='type' id={'type'}/>
            <Text className='innhold' id={'innhold'}/>
            <Text className='dato-mottat' id={'dato_mottatt'}/>
        </div>
        <ol>
            {dokumenter.map((dokument, index) => (
                <li className='inngaende-dokumenter__row' key={index}>
                    <Rad dokument={dokument}/>
                </li>
            ))}
        </ol>
    </div>
};

const Rad = ({dokument}) => {
    switch (dokument.type) {
        case EPOST_DOKUMENTTYPE:
            return <Epost dokument={dokument}/>;
        case SMS_DOKUMENTTYPE:
            return <Sms dokument={dokument}/>;
        case DOKUMENT_DOKUMENTTYPE:
            return <Dokument dokument={dokument}/>;
        default:
            return null;
    }
};


export default withInngaendeDokumenterLoaded(InngaendeDokumenter);