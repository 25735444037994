import kursApi, {IKKE_EKSAMENSRETTET} from '../services/kursApi';
import {getInstitusjonsnr} from "./session";
import {formatDate, getTranslation} from "./i18n";
import toArray from "lodash/toArray";
import {FRIST_UBEGRENSET} from "../utils/soknadStatus";
import {sortBy} from "lodash";

export const NAME = 'kursoversikt';

const TOGGLE_KATEGORI = `${NAME}/TOGGLE_KATEGORI`;
const SET_KATEGORIER = `${NAME}/SET_KATEGORIER`;
const SET_FRITEKST_SOK = `${NAME}/SET_FRITEKST_SOK`;
const SET_EKSAMEN_FILTER = `${NAME}/SET_EKSAMEN_FILTER`;
const TOGGLE_NETT_FILTER = `${NAME}/TOGGLE_NETT_FILTER`;
const TOGGLE_SAMLING_FILTER = `${NAME}/SET_SAMLING_FILTER`;
const TOGGLE_UTGATTE_KURS_FILTER = `${NAME}/TOGGLE_UTGATTE_KURS_FILTER`;

const LOAD_KURSLISTE_PENDING = `${NAME}/LOAD_KURSLISTE_PENDING`;
const LOAD_KURSLISTE_SUCCESS = `${NAME}/LOAD_KURSLISTE_SUCCESS`;
const LOAD_KURSLISTE_FAILURE = `${NAME}/LOAD_KURSLISTE_FAILURE`;

export function loadKurslistePending() {
    return {
        type: LOAD_KURSLISTE_PENDING,
    };
}

export function loadKurslisteSuccess(data) {
    const {kursliste, kategorier} = data;
    return {
        type: LOAD_KURSLISTE_SUCCESS,
        kursliste,
        kategorier,
    };
}

export function loadKurslisteFailure(error) {
    return {
        type: LOAD_KURSLISTE_FAILURE,
        error,
    };
}

export function loadKursliste() {
    return function (dispatch, getState) {
        dispatch(loadKurslistePending());
        const instnr = getInstitusjonsnr(getState());
        return kursApi.getAlleKurs(instnr).then(
            data => dispatch(loadKurslisteSuccess(data)),
            error => dispatch(loadKurslisteFailure(error))
        );
    };
}

export const toggleKategori = kode => ({
    type: TOGGLE_KATEGORI,
    kode,
});

export const setKategorier = kategorier => ({
    type: SET_KATEGORIER,
    kategorier,
});

export function setEksamenFilter(value) {
    return {
        type: SET_EKSAMEN_FILTER,
        value,
    }
}

export function toggleNettFilter() {
    return {
        type: TOGGLE_NETT_FILTER,
    }
}

export function toggleSamlingFilter() {
    return {
        type: TOGGLE_SAMLING_FILTER,
    }
}

export const toggleUtgatteKursFilter = () => ({
    type: TOGGLE_UTGATTE_KURS_FILTER,
});

export function setFritekstSok(text) {
    return {
        type: SET_FRITEKST_SOK,
        text,
    };
}

export const createKurs = props => {
    const {
        id = createKursId({}),
        kategorier = [],
        kursnavn = [],
        startdato = undefined,
        sluttdato = undefined,
        harDetaljer = false,
        nettbasert = false,
        samlingsbasert = false,
        pris = 0,
        varighetTimer = 0,
        epost = '',
        nettside = '',
        pameldingsfrist = FRIST_UBEGRENSET,
        emner = [],
        beskrivelser = [],
        eksamen = IKKE_EKSAMENSRETTET,
        direktePamelding = false,
        harGSKKrav,
        harKravOmHoyereUtd,
        harKravOmPraksis,
        harAndreKrav,
        merknadKrav,
        nettsideKrav,
        antallLedigePlasser,
        automatiskStenging,
        automatiskJaSvar,
        automatiskVenteliste,
        automatiskTilbud,
    } = props;
    return {
        id,
        kategorier,
        kursnavn,
        startdato,
        sluttdato,
        harDetaljer,
        nettbasert,
        samlingsbasert,
        pris,
        varighetTimer,
        epost,
        nettside,
        pameldingsfrist,
        emner,
        beskrivelser,
        eksamen,
        direktePamelding,
        harGSKKrav,
        harKravOmHoyereUtd,
        harKravOmPraksis,
        harAndreKrav,
        merknadKrav,
        nettsideKrav,
        antallLedigePlasser,
        automatiskStenging,
        automatiskJaSvar,
        automatiskVenteliste,
        automatiskTilbud,
    }
};

export const createKursId = (kurskode = '', tidskode = '') => {
    return {
        kurskode,
        tidskode,
    }
};

export const createKategori = props => {
    const {
        kode = "",
        rekkefolgenr = "",
        kategorinavn = [],
        id = 0,
    } = props;
    return {
        kode,
        rekkefolgenr,
        kategorinavn,
        id,
    }
};

export const createEmne = (kode = '', navn = '', vektingstall = 0, vekttypenavn = '') => {
    return {
        kode,
        navn,
        vektingstall,
        vekttypenavn,
    }
};


const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    fritekstSok: '',
    kursliste: {},
    kategorier: {},
    valgteKategorier: [],
    eksamenFilter: undefined,
    nettbasertFilter: false,
    samlingFilter: false,
    visUtgatteKurs: false,
};


function kursoversikt(state = initialState, action) {
    switch (action.type) {
        case LOAD_KURSLISTE_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_KURSLISTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                kursliste: action.kursliste,
                kategorier: action.kategorier,
            };
        case LOAD_KURSLISTE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error
            };
        case SET_FRITEKST_SOK:
            return {
                ...state,
                fritekstSok: action.text,
            };
        case TOGGLE_KATEGORI:
            const {kode} = action;
            const {valgteKategorier} = state;
            return {
                ...state,
                valgteKategorier: valgteKategorier.includes(kode)
                    ? valgteKategorier.filter(kat => kat !== kode)
                    : [...valgteKategorier, kode],
            };
        case SET_KATEGORIER:
            const {kategorier} = action;
            return {
                ...state,
                valgteKategorier: [...kategorier]
            };
        case SET_EKSAMEN_FILTER:
            return {
                ...state,
                eksamenFilter: action.value === state.eksamenFilter ? initialState.eksamenFilter : action.value,
            };
        case TOGGLE_NETT_FILTER:
            return {
                ...state,
                nettbasertFilter: !state.nettbasertFilter,
            };
        case TOGGLE_SAMLING_FILTER:
            return {
                ...state,
                samlingFilter: !state.samlingFilter,
            };
        case TOGGLE_UTGATTE_KURS_FILTER:
            return {
                ...state,
                visUtgatteKurs: !state.visUtgatteKurs,
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME];
}

export function getKurs(state, kursId) {
    const kurs = getState(state).kursliste[kursId];
    const kategorier = getKategorier(state);
    return kurs && getFormatertKursData(state, kurs, kategorier);
}

export function getAlleKurs(state) {
    return toArray(getState(state).kursliste);
}

export function getKursliste(state) {
    const kategorier = getKategorier(state);
    return getAlleKurs(state).map(kurs => getFormatertKursData(state, kurs, kategorier));
}

function getFilteredList(state, ...filters) {
    let list = getKursliste(state);
    filters.forEach(filter => {
        list = filter(state, list)
    });
    return [...list];
}

export function getFormatertKursData(state, kurs, kategorier) {
    return {
        ...kurs,
        kursnavn: getTranslation(state, kurs.kursnavn),
        kategorier: kurs.kategorier.map(kode => kategorier.find(kategori => kategori.kode === kode)),
        startdato: formatDate(state, kurs.startdato),
        sluttdato: formatDate(state, kurs.sluttdato),
        emner: kurs.emner.map(emne => ({
            ...emne,
            navn: getTranslation(state, emne.navn),
            vekttypenavn: getTranslation(state, emne.vekttypenavn),
        })),
        beskrivelser: kurs.beskrivelser.map(text => getTranslation(state, text)),
    }
}


export function isLoading(state) {
    return getState(state).isLoading;
}

export function isDataLoaded(state) {
    return getState(state).isDataLoaded;
}

export function getError(state) {
    return getState(state).error;
}

export function getKategorier(state) {
    const kategorier = getState(state).kategorier;
    return Object.keys(kategorier).map(key => {
        const kategori = kategorier[key];
        return {
            ...kategori,
            kategorinavn: getTranslation(state, kategori.kategorinavn),
        }
    });
}

export function getValgteKategorier(state) {
    return getState(state).valgteKategorier;
}

export function erKategoriValgt(state, id) {
    return getValgteKategorier(state).includes(id);
}

export function getVisibleKursliste(state) {
    return getFilteredList(
        state,
        fritekstFilter,
        eksamenFilter,
        kategorifilter,
        undervisningFilter,
        utgatteKursFilter,
    );
}

export const getVisibleKurslisteSortert = state => sortBy(getVisibleKursliste(state),['kursnavn']);

function fritekstFilter(state, kursliste) {
    const fritekstSok = getFritekstSok(state);
    const regExp = new RegExp(fritekstSok, "i");
    return fritekstSok ?
        kursliste.filter(
            kurs => kurs.kursnavn.match(regExp)
        )
        : kursliste;
}

export function eksamenFilter(state, kursliste) {
    const eksamenFilter = getEksamenFilter(state);
    return eksamenFilter !== undefined ?
        kursliste.filter(
            kurs => kurs.eksamen === eksamenFilter
        )
        : kursliste;
}

export function utgatteKursFilter(state, kursliste) {
    const visUtgatteKursFilter = getUtgatteKursFilter(state);
    return visUtgatteKursFilter ?
        kursliste
        :
        kursliste.filter(
            kurs => kurs.pameldingsfrist > Date.now()
        );
}

export function getEksamenCounter(state) {
    let kursliste = getFilteredList(
        state,
        fritekstFilter,
        kategorifilter,
        undervisningFilter,
        utgatteKursFilter,
    );
    const counter = {};
    kursliste.forEach(kurs => {
        const {eksamen} = kurs;
        counter[eksamen] = counter[eksamen] === undefined ? 1 : counter[eksamen] + 1;
    });
    return eksamenstype => counter[eksamenstype] || 0;
}

export function undervisningFilter(state, kursliste) {
    const nettbasertFilter = getNettbasertFilter(state);
    const samlingFilter = getSamlingFilter(state);
    if (!nettbasertFilter && !samlingFilter) {
        return kursliste;
    }
    return kursliste.filter(
        kurs => (kurs.nettbasert && nettbasertFilter) || (kurs.samlingsbasert && samlingFilter)
    );
}

export function getUndervisningsCounter(state) {
    let kursliste = getFilteredList(
        state,
        fritekstFilter,
        eksamenFilter,
        kategorifilter,
        utgatteKursFilter,
    );
    return {
        erNettbasert: kursliste.filter(kurs => kurs.nettbasert).length,
        erSamlingsbasert: kursliste.filter(kurs => kurs.samlingsbasert).length,
    };
}

function kategorifilter(state, kursliste) {
    const valgteKategorier = getValgteKategorier(state);
    return valgteKategorier.length > 0 ?
        kursliste.filter(({kategorier}) =>
            kategorier.some(({kode}) =>
                valgteKategorier.includes(kode)
            )
        )
        : kursliste;
}

export function getKategoriCounter(state) {
    let kursliste = getFilteredList(
        state,
        fritekstFilter,
        eksamenFilter,
        undervisningFilter,
        utgatteKursFilter,
    );
    const counter = {};
    kursliste.forEach(({kategorier}) => {
        kategorier.forEach(({kode}) => {
            counter[kode] = counter[kode] === undefined ? 1 : counter[kode] + 1;
        })
    });
    return kode => counter[kode] || 0;
}

export const getUtgatteKursCounter = state => {
    let kursliste = getFilteredList(
        state,
        fritekstFilter,
        eksamenFilter,
        undervisningFilter,
        kategorifilter,
    );
    return kursliste.filter(kurs => kurs.pameldingsfrist < Date.now()).length;
};

export function getFritekstSok(state) {
    return getState(state).fritekstSok;
}

export function getEksamenFilter(state) {
    return getState(state).eksamenFilter;
}

export function getNettbasertFilter(state) {
    return getState(state).nettbasertFilter;
}

export function getSamlingFilter(state) {
    return getState(state).samlingFilter;
}

export function getUtgatteKursFilter(state) {
    return getState(state).visUtgatteKurs;
}

export default kursoversikt;