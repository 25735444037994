import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Input from "../../primitives/Input";
import {
    attemptLoginSomBruker,
    evuapiTokenSkriverettighet, getInstitusjonsNavn,
    getLoginSomError,
} from "../../ducks/session";
import Checkbox from "../../primitives/Checkbox";
import {
    getBrukernrSaksbehandling,
    getLoginSomType,
    getSkriverettigheterAktivert,
    setBrukenrSaksbehandling,
    setLoginSomType,
    setSkriverettigheterAktiv,
    LOGIN_SOM_TYPE_DELTAKER,
    LOGIN_SOM_TYPE_BEDRIFTSBRUKER,
} from "../../ducks/menu";
import {isEnterPressed} from "../../utils/common";
import {RadioButton} from "../../primitives/RadioButton";

import './logg-inn-som-bruker-form.scss';
import Text from "../../primitives/Text";

const DELTAKERNUMMER_MAX_LENGTH = 6;
const PERSONLOPENR_MAX_LENGTH = 8;

const LoggInnSomBrukerForm = () => {

    const loginSomType = useSelector(getLoginSomType);
    const deltakernrSaksbehandling = useSelector(getBrukernrSaksbehandling);
    const skriverettigheterAktivert = useSelector(getSkriverettigheterAktivert);
    const skriverettigheter = useSelector(evuapiTokenSkriverettighet);
    const loginSomError = useSelector(getLoginSomError);
    const institusjonsNavn = useSelector(getInstitusjonsNavn);
    const dispatch = useDispatch();

    const handleEnterKeyPress = event => {
        if (isEnterPressed(event)) {
            dispatch(attemptLoginSomBruker());
        }
    };

    const inputLabelTextId = loginSomType === LOGIN_SOM_TYPE_BEDRIFTSBRUKER ? 'personløpenr' : 'deltakernummer';

    return (
        <div className={'logg-inn-som-bruker-form'}>
            <div className='bruker-type'>
                <fieldset>
                    <RadioButton
                        label={LOGIN_SOM_TYPE_DELTAKER}
                        onChange={() => dispatch(setLoginSomType(LOGIN_SOM_TYPE_DELTAKER))}
                        checked={loginSomType === LOGIN_SOM_TYPE_DELTAKER}
                    />
                    <RadioButton
                        label={LOGIN_SOM_TYPE_BEDRIFTSBRUKER}
                        onChange={() => dispatch(setLoginSomType(LOGIN_SOM_TYPE_BEDRIFTSBRUKER))}
                        checked={loginSomType === LOGIN_SOM_TYPE_BEDRIFTSBRUKER}
                    />
                </fieldset>
            </div>
            <Input
                className={'bruker-nummer'}
                label={inputLabelTextId}
                autoFocus={true}
                onChange={e => dispatch(setBrukenrSaksbehandling(e.target.value))}
                onKeyPress={handleEnterKeyPress}
                hasError={loginSomError !== undefined}
                value={deltakernrSaksbehandling}
                maxLength={loginSomType === LOGIN_SOM_TYPE_DELTAKER ? DELTAKERNUMMER_MAX_LENGTH : PERSONLOPENR_MAX_LENGTH}
            />
            {skriverettigheter &&
            <Checkbox
                label={<Text id={'skriverettigheter'}/>}
                onChange={e => dispatch(setSkriverettigheterAktiv(e.target.checked))}
                checked={skriverettigheterAktivert}
            />
            }
            {loginSomError &&
            <span className='error-message-in-place '>
                <Text
                    id={loginSomError === 'ws_feil' ? 'finner_ikke_bruker' : loginSomError}
                    institusjon={institusjonsNavn}
                />
            </span>
            }
        </div>
    )
};

export default LoggInnSomBrukerForm;