import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {fetchInngaendeDokumenter} from "../services/dokumenterAPI";
import {getTranslation} from "./i18n";

export const NAME = 'inngaendeDokumenter';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;


export const DOKUMENT_DOKUMENTTYPE = "dokument";
export const EPOST_DOKUMENTTYPE = "e_post";
export const SMS_DOKUMENTTYPE = "sms";


export function loadPending() {
    return {
        type: LOAD_PENDING,
    };
}

export function loadSuccess(data) {
    return {
        type: LOAD_SUCCESS,
        data,
    };
}

export function loadFailure(error) {
    return {
        type: LOAD_FAILURE,
        error,
    };
}


export function loadInngaendeDokumenter() {
    return function (dispatch, getState) {

        if (isLoading(getState())) {
            return;
        }
        dispatch(loadPending());

        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());

        return fetchInngaendeDokumenter(deltakernr, evuapiToken).then(
            data => dispatch(loadSuccess(data)),
            error => dispatch(loadFailure(error))
        );
    };
}

export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    data: [],
};

export function createDokument({dokumentnr, filnavn, datoMottatt, dokumenttypenavn}) {
    return {
        type: DOKUMENT_DOKUMENTTYPE,
        dokumentnr,
        datoMottatt,
        filnavn,
        dokumenttypenavn
    }
}


export function createEpost({datoMottatt, avsender, emne, innhold, mottaker}) {
    return {
        type: EPOST_DOKUMENTTYPE,
        datoMottatt,
        avsender,
        emne,
        innhold,
        mottaker,
    }
}

export function createSms({datoMottatt, innhold, telefonnr}) {
    return {
        type: SMS_DOKUMENTTYPE,
        datoMottatt,
        innhold,
        telefonnr,
    }
}

function inngaendeDokumenter(state = initialState, action) {

    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                isDataLoaded: false,
                error: undefined,
                data: [],
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                data: action.data,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error
            };
        default:
            return state
    }
}

function getState(state) {
    return state[NAME];
}

export function getError(state) {
    return getState(state).error;
}

export function isLoading(state) {
    return getState(state).isLoading;
}

export function isDataLoaded(state) {
    return getState(state).isDataLoaded;
}

export function getInngaendeDokumenter(state) {
    return getState(state).data.map(dokument => {
        return {
            ...dokument,
            dokumenttypenavn: getTranslation(state, dokument.dokumenttypenavn)
        }
    });
}

export default inngaendeDokumenter;