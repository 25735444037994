import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {isEnterPressed, isEscPressed, isShiftTabPressed, isSpacePressed, isTabPressed} from "../utils/common";


class OnKeyDownCatcher extends Component {

    constructor(props) {
        super(props);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(event) {
        const {onEnter, onTab, onShiftTab, onEsc, onSpace, onAnyKey, preventDefaultOnTab} = this.props;

        if (onEnter && isEnterPressed(event)) {
            onEnter();
            return;
        }

        if (onShiftTab && isShiftTabPressed(event)) {
            preventDefaultOnTab && event.preventDefault();
            onShiftTab();
            return;
        }

        if (onTab && isTabPressed(event)) {
            preventDefaultOnTab && event.preventDefault();
            onTab();
            return;
        }

        if (onEsc && isEscPressed(event)) {
            onEsc();
            return;
        }

        if (onSpace && isSpacePressed(event)) {
            onSpace();
            return;
        }

        if(onAnyKey){
            onAnyKey();
        }

    };

    render() {
        const {children, className='', onClick, onFocus} = this.props;
        return (
            //eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                onKeyDown={this.handleKeyPress}
                onClick={onClick}
                onFocus={onFocus}
                className={className}
            >
                {children}
            </div>
        )
    }
}

OnKeyDownCatcher.propTypes = {
    children: PropTypes.node.isRequired,
    onEnter: PropTypes.func,
    onClick: PropTypes.func,
    onTab: PropTypes.func,
    onShiftTab: PropTypes.func,
    onEsc: PropTypes.func,
    onSpace: PropTypes.func,
    onAnyKey: PropTypes.func,
    className: PropTypes.string,
};


export default OnKeyDownCatcher