import {useDispatch, useSelector} from "react-redux";
import {getAktueltDeltakernr, getEvuapiToken} from "../ducks/session";
import {downloadDokument} from "../services/dokumenterAPI";
import {showPopup} from "../ducks/popup";
import {createErrorPopupContent} from "../utils/request";

export const useDownloadDokument = () => {

    const evuApiToken = useSelector(getEvuapiToken);
    const deltakernr = useSelector(getAktueltDeltakernr);
    const dispatch = useDispatch();

    const download = (dokumentnr, filnavn) => {
        downloadDokument(dokumentnr, filnavn, evuApiToken, deltakernr)
            .catch(error => dispatch(showPopup(createErrorPopupContent(error))));
    };

    return download;
};