import React from 'react';
import {useSelector} from "react-redux";
import {getAktueltDeltakernr, getRedirectionPath} from "../ducks/session";
import Redirect from "../components/Redirect";

const redirectIfDeltakerFinnes = Component => props => {

    const deltakernr = useSelector(getAktueltDeltakernr);
    const redirectionPath = useSelector(getRedirectionPath);

    if (deltakernr) return <Redirect to={redirectionPath}/>;

    return <Component {...props} />;
};

export default redirectIfDeltakerFinnes