import React from 'react';
import PropTypes from 'prop-types';

const StegContent = ({aktivt, buttonId, contentId, children}) => {
    if (!aktivt){
        return <div className='container'/>;
    }
    return (
        <div className="container"
             id={contentId}
             aria-labelledby={buttonId}
        >
            <div className="content">
                {children}
            </div>
        </div>
    )
};

StegContent.propTypes = {
    aktivt: PropTypes.bool,
    buttonId: PropTypes.string,
    contentId: PropTypes.string,
};

export default StegContent;