import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {nanoid} from "nanoid";


class OnClickOutsideCatcher extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: 'keep-focus-here-' + nanoid()
        }
    }

    handleClick = event => {
        if (this.isClickWithinNode(event)) {
            return;
        }
        const {onCatch} = this.props;
        onCatch();
    };

    isClickWithinNode = event => {
        const {id} = this.state;
        return event.target.closest(`#${id}`)
            || (document.activeElement && document.activeElement.closest(`#${id}`))
            || (event.target.closest('.react-select__option'))
    };

    getUniqeId = () => {
        const {id} = this.state;
        return id;
    };

    componentDidMount() {
        document.addEventListener('click', this.handleClick, {capture: true});
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClick, {capture: true});
    };


    render() {
        const {children, className} = this.props;
        return (
            <div id={this.getUniqeId()} className={className}>
                {children}
            </div>

        )
    }
}

OnClickOutsideCatcher.propTypes = {
    children: PropTypes.node.isRequired,
    onCatch: PropTypes.func.isRequired,
    className: PropTypes.string,
};


export default OnClickOutsideCatcher