import React from "react";
import Button from "../../../primitives/Button";
import {useDispatch, useSelector} from "react-redux";
import {
    enterEditMode,
    getEditError,
    getEditId,
    getIsInEditMode
} from "../../../ducks/arbeidserfaring";

import './legg-til-endre-arbeidserfaring.scss';
import InputStillingsprosent from "./InputStillingsprosent";
import InputPeriode from "./InputPeriode";
import InputStillingstittel from "./InputStillingstittel";
import InputArbeidsgiver from "./InputArbeidsgiver";
import Dokumentasjon from "../vedlegg/Dokumentasjon";
import Text from "../../../primitives/Text";
import WSError from "../../WSError";
import Actions from "./Actions";
import {mdiPlus} from "@mdi/js";

const LeggTilEndreArbeidserfaring = () => {
    const isEditMode = useSelector(getIsInEditMode);
    return (
        <div className='legg-til-endre-arbeidserfaring'>
            {isEditMode ? <Form/> : <AddNewButton/>}
        </div>
    )
};

export const AddNewButton = () => {
    const dispatch = useDispatch();
    return (
        <div className='legg-til-button'>
            <Button
                onClick={() => dispatch(enterEditMode())}
                iconPath={mdiPlus}
            >
                <Text id='legg_til_arbeidserfaring'/>
            </Button>
        </div>
    )
};

const Form = () => {
    const id = useSelector(getEditId);
    return (
        <div className='edit-form'>
            <h2>
                <Text id={id ? 'endre_arbeidserfaring' : 'legg_til_arbeidserfaring'}/>
            </h2>
            <InputPeriode/>
            <InputArbeidsgiver/>
            <InputStillingstittel/>
            <InputStillingsprosent/>
            <Dokumentasjon id={id} isInEditMode={true} />
            <Error/>
            <Actions/>
        </div>
    )
};


const Error = () => {
    const error = useSelector(getEditError);
    if (!error) return null;
    return <WSError error={error}/>
};

export default LeggTilEndreArbeidserfaring;