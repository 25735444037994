import {
    getEvuapiTokenPayload,
    getInstitusjonsnr,
    LOGIN_SET_INSTITUSJON,
    REMOVE_EVU_API_TOKEN,
    resetInst,
} from "../ducks/session";

export const sessionMiddleware = ({getState, dispatch}) => {
    return next => action => {

        const state = getState();

        if(instTokenUlikInstState(state) && !erEndreInstAction(action)){
            // Reset inst i state slik at bruker må spesifisere ønsket instutisjon på ny
            dispatch(resetInst());
        }

        next(action);
    };
};

const instTokenUlikInstState = state => {
    const token = getEvuapiTokenPayload(state);
    const institusjonsnrState = getInstitusjonsnr(state);
    if(!token || !institusjonsnrState){
        return false;
    }
    const {institusjonsnr: institusjonsnrToken} = token;
    return institusjonsnrToken !== institusjonsnrState;
};

const erEndreInstAction = action => [LOGIN_SET_INSTITUSJON, REMOVE_EVU_API_TOKEN].includes(action.type);