import Text from "../../../primitives/Text";
import FormFocusControl from "../../inline-edit/FormFocusControl";
import React from "react";
import Landskode from "../Landskode";
import InputTelefonNummer from "./InputTelefonNummer";
import PropTypes from "prop-types";

const TelefonForm = ({typeTelefon, onChangeKode, onChangeNummer, telefon, errors, closeEditing, attemptedClosed}) => {
    const {landskode = '', landnummer = undefined, nummer = ''} = telefon || {};
    return (
        <fieldset>
            <legend>
                <Text id={typeTelefon}/>
            </legend>
            <FormFocusControl onFocusOut={closeEditing}>
                <Landskode landskode={landskode}
                           landnummer={landnummer}
                           onChange={selectedOption => onChangeKode(selectedOption.value)}
                />
                <InputTelefonNummer
                    nummer={nummer}
                    errorMessageId={errors.nummer}
                    onChange={event => onChangeNummer(event.target.value)}
                    typingDone={attemptedClosed}
                />
            </FormFocusControl>
        </fieldset>
    )
};

TelefonForm.propTypes = {
    typeTelefon: PropTypes.string.isRequired,
    onChangeKode: PropTypes.func.isRequired,
    onChangeNummer: PropTypes.func.isRequired,
    closeEditing: PropTypes.func.isRequired,
    telefon: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    attemptedClosed: PropTypes.bool,
};

export default TelefonForm;