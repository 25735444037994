import Text from "../../primitives/Text";
import React from "react";
import FormattedDate from "../../primitives/FormattedDate";
import Link from "../../primitives/Link";
import ClickableArea from "../ClickableArea";
import {useDownloadDokument} from "../../hooks/useDownloadDokument";
import {DOKUMENT_DOKUMENTTYPE} from "../../ducks/inngaendeDokumenter";
import {mdiFile} from "@mdi/js";
import Icon from "../../primitives/Icon";

export const Dokument = ({dokument}) => {

    const download = useDownloadDokument();
    const {dokumenttypenavn, dokumentnr, filnavn, datoMottatt} = dokument;

    return (
        <ClickableArea onClick={() => download(dokumentnr, filnavn)}>
            <span className='type'>
                <Icon path={mdiFile}/>
                {dokumenttypenavn ? <span>{dokumenttypenavn}</span> : <Text id={DOKUMENT_DOKUMENTTYPE}/>}
            </span>
            <div className='innhold'>
                <Link href='#'>{filnavn}</Link>
            </div>
            <FormattedDate className='dato-mottat' date={datoMottatt} withTime={true}/>
        </ClickableArea>
    )
};


export default Dokument;