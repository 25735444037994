import * as routes from "./apiRoutes";
import axios from "axios";
import {createApptekst} from "../ducks/apptekster";
import {sanitizeHtmlText} from "../utils/common";

export function getApptekster(instnr) {

    const route = routes.APPTEKSTER.replace(':instnr',instnr);

    return axios.get(route)
        .then(response => {
            return transformDatafromWS(response.data);
        })
        .catch(error => {
            throw error;
        });

}

const transformDatafromWS = data => {
  return data.map(({apptekstkode, verditekster}) =>
      createApptekst({
          kode: apptekstkode,
          tekst: transformTeksterFromWs(verditekster),
      })
  );
};

const transformTeksterFromWs = tekster => {
    return tekster.map(({sprakkode, verditekst, htmlMulig}) => ({
        lang: sprakkode,
        value: sanitizeHtmlText(verditekst),
        htmlMulig,
    }))
};

