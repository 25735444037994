import React from 'react';
import {checkGSK, getGSKError, getGSKIsLoading, isGskDataLoaded} from "../ducks/dokumenter";
import InitialLoadWrapper from "../wrappers/InitialLoadWrapper";
import Text from "../primitives/Text";


const withGSK = Component => props => {
    return (
        <InitialLoadWrapper
            loadAction={checkGSK}
            loadingSelector={getGSKIsLoading}
            loadedSelector={isGskDataLoaded}
            errorSelector={getGSKError}
            loadMessage={<Text id='sjekker_GSK_vedtak'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )
};

export default withGSK;