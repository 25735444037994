import React from 'react';
import Input from "../../../primitives/Input";
import {useDispatch, useSelector} from "react-redux";
import {getFritekstSok, setFritekstSok} from "../../../ducks/kursoversikt";

const FilterFritekst = () => {
    const fritekstSok = useSelector(getFritekstSok);
    const dispatch = useDispatch();
    return (
        <div className="filtre__filter">
            <Input
                value={fritekstSok}
                labelColon={false}
                onChange={e => dispatch(setFritekstSok(e.target.value))}
                label={'søk_etter_kurs'}
            />
        </div>
    )
};

export default FilterFritekst;