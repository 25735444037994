import {useDispatch, useSelector} from "react-redux";
import {
    changeBetalingsinfoField,
    getErrorLagringForFelt,
    getFakturareferanse,
    lagreBetalingsinfo
} from "../../ducks/betalingsinfo";
import React from "react";
import Input from "../../primitives/Input";
import ErrorFormSaving from "../ErrorFormSaving";

export const FELT_FAKTURAREFERANSE = 'fakturareferanse';

const InputFakturareferanse = () => {
    const fakturareferanse = useSelector(getFakturareferanse);
    const errorLagring = useSelector(state => getErrorLagringForFelt(state, FELT_FAKTURAREFERANSE));
    const dispatch = useDispatch();
    const onBlur = () => dispatch(lagreBetalingsinfo(FELT_FAKTURAREFERANSE));

    return (
        <div className='bedrift-input'>
            <Input
                label='fakturareferanse'
                value={fakturareferanse}
                onChange={e => dispatch(changeBetalingsinfoField('fakturareferanse', e.target.value))}
                onBlur={onBlur}
                hasError={!!errorLagring}
            />
            {errorLagring &&
            <ErrorFormSaving
                error={errorLagring}
                provIgjen={onBlur}
            />
            }
        </div>
    )
};

export default InputFakturareferanse;