import {useDispatch} from "react-redux";
import Button from "../../../primitives/Button";
import React from "react";
import {showPopup} from "../../../ducks/popup";
import Oppratkskrav from "../opptakskrav/Opptakskrav";
import InfoFilopplasting from "../opptakskrav/InfoFilopplasting";
import Text from "../../../primitives/Text";
import {mdiHelpCircleOutline} from "@mdi/js";

const MerInfoButton = () => {
    const dispatch = useDispatch();
    return (
        <Button onClick={() => dispatch(showPopup(getPopupContent()))}
                iconPath={mdiHelpCircleOutline}
                altText='trykk_for_mer_info'
                className='mer-info-button'
        />
    )
};

export const getPopupContent = () => {
    return {
        title: <Text id='krav'/>,
        children: <>
            <Oppratkskrav withTitle={false}/>
            <InfoFilopplasting/>
        </>
    };
};

export default MerInfoButton;