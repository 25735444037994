import {FELT_ORGANISASJON, InputOrganisasjon} from "./InputOrganisasjon";
import InputEpost, {FELT_EPOST} from "./InputEpost";
import React from "react";
import {useSelector} from "react-redux";
import {
    getIsLagringFelt,
    isBedriftBetaler
} from "../../ducks/betalingsinfo";
import Spinner from "../Spinner";
import InputFakturamottaker, {FELT_FAKTURAMOTTAKER} from "./InputFakturamottaker";
import InputFakturareferanse, {FELT_FAKTURAREFERANSE} from "./InputFakturareferanse";


const GenerelleInputFields = () => {

    const isLagringFelt = useSelector(getIsLagringFelt);
    const isLagring = [
        FELT_FAKTURAMOTTAKER,
        FELT_ORGANISASJON,
        FELT_FAKTURAREFERANSE,
        FELT_EPOST
    ].includes(isLagringFelt);
    const bedriftBetaler = useSelector(isBedriftBetaler);

    return (
        <div className='input-fields input-group'>
            {isLagring && <Spinner/>}
            <InputFakturamottaker/>
            {bedriftBetaler &&
            <div className='bedrift-input-fields-wrapper'>
                <InputOrganisasjon/>
                <InputFakturareferanse/>
                <InputEpost/>
            </div>
            }
        </div>
    )
};

export default GenerelleInputFields;
