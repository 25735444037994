import React from "react";
import {useSelector} from "react-redux";
import {
    getIsInEditMode,
    getIsNySoknadstilknytningLoading,
    getTilknyttetArbeidserfaringer,
} from "../../../ducks/arbeidserfaring";
import TilknyttetArbeidserfaring from "./TilknyttetArbeidserfaring";
import LeggTilEndreArbeidserfaring from "../../arbeidserfaring/redigering/LeggTilEndreArbeidserfaring";
import Text from "../../../primitives/Text";
import Spinner from "../../Spinner";
import {mdiBriefcaseOutline} from "@mdi/js";
import Icon from "../../../primitives/Icon";

const TilknyttedeArbeidserfaringer = () => {

    const arbeidserfaringer = useSelector(getTilknyttetArbeidserfaringer);
    const isEditMode = useSelector(getIsInEditMode);
    const isLoading = useSelector(getIsNySoknadstilknytningLoading);

    if (!arbeidserfaringer.length) {
        return <div className='tilknyttede-arbeidserfaringer-wrapper'>
            <Icon path={mdiBriefcaseOutline} className='briefcase-icon'/>
            <em><Text id='ingen_arbeidserfaringer_registrert'/></em>
            <LeggTilEndreArbeidserfaring/>
            {isLoading && <Spinner/>}
        </div>
    }

    return (
        <div className='tilknyttede-arbeidserfaringer-wrapper'>
            <LeggTilEndreArbeidserfaring/>
            <ol className={`tilknyttede-arbeidserfaringer ${isEditMode && 'hidden'}`}>
                {isLoading && <li><Spinner/></li>}
                {arbeidserfaringer.map(arbeidserfaring =>
                    <TilknyttetArbeidserfaring
                        key={arbeidserfaring.id}
                        arbeidserfaring={arbeidserfaring}
                    />)}
            </ol>
        </div>
    )
};


export default TilknyttedeArbeidserfaringer;