import * as routes from "./apiRoutes";
import axios from "axios";
import {createAPIInfo} from "../ducks/apiInfo";

export async function getAPIInfoFraAPI() {
    const response = await axios.get(routes.API_INFO);
    const data = response.data;
    if(!data) return createAPIInfo({});
    return createAPIInfo({
        buildTimestamp: data.bygget,
        lastCommit: data.commit,
    });
}


