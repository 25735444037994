import {isUtv} from "../utils/environment";

export const NAME = 'menu';

export const SET_BRUKER_NUMMER = `${NAME}/SET_BRUKER_NUMMER`;
export const SET_SKRIVERETTIGHETER = `${NAME}/SET_SKRIVERETTIGHETER`;
export const RESET_LOGIN_SOM = `${NAME}/RESET_LOGIN_SOM`;
export const SET_LOGIN_SOM_TYPE = `${NAME}/SET_LOGIN_SOM_TYPE`;

export const LOGIN_SOM_TYPE_DELTAKER = 'LOGIN_SOM_TYPE_DELTAKER';
export const LOGIN_SOM_TYPE_BEDRIFTSBRUKER = 'LOGIN_SOM_TYPE_BEDRIFTSBRUKER';

export const setLoginSomType = loginSomType => ({
    type: SET_LOGIN_SOM_TYPE,
    loginSomType,
});

export const setBrukenrSaksbehandling = deltakernr => ({
    type: SET_BRUKER_NUMMER,
    deltakernr,
});

export const setSkriverettigheterAktiv = skriverettigheterAktiv => ({
    type: SET_SKRIVERETTIGHETER,
    skriverettigheterAktiv,
});

export const resetLoginSom = () => ({
    type: RESET_LOGIN_SOM,
});


export const initialState = {
    loginSomType: LOGIN_SOM_TYPE_DELTAKER,
    brukernrSaksbehandling: '',
    skriverettighetAktivert: isUtv(),
};

function menu(state = initialState, action) {
    switch (action.type) {
        case SET_BRUKER_NUMMER:
            return {
                ...state,
                brukernrSaksbehandling: action.deltakernr,
            };
        case SET_SKRIVERETTIGHETER:
            return {
                ...state,
                skriverettighetAktivert: action.skriverettigheterAktiv,
            };
        case RESET_LOGIN_SOM:
            return {
                ...state,
                brukernrSaksbehandling: initialState.brukernrSaksbehandling,
                skriverettighetAktivert: initialState.skriverettighetAktivert,
            };
        case SET_LOGIN_SOM_TYPE:
            return {
                ...state,
                loginSomType: action.loginSomType,
            };
        default:
            return state
    }
}

const getState = state => state[NAME];

export const getBrukernrSaksbehandling = state => getState(state).brukernrSaksbehandling;
export const getSkriverettigheterAktivert = state => getState(state).skriverettighetAktivert;
export const getLoginSomType = state => getState(state).loginSomType;

export default menu;