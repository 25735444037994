import React from "react";
import {useLocation} from "react-router";
import {pages} from "../utils/common";
import {getValueFromLocalStorage, removeFromLocalStorage, saveToLocalStorage} from "../utils/sessionUtil";
import Redirect from "./Redirect";

const LOCAL_STORAGE_TEST = 'local-storage-test';
const LOCAL_STORAGE_FAIL = 'local-storage-fail';

const localStorageIsDeactivated = () => {
    saveToLocalStorage(LOCAL_STORAGE_TEST, LOCAL_STORAGE_TEST);
    const result = getValueFromLocalStorage(LOCAL_STORAGE_TEST, LOCAL_STORAGE_FAIL);
    removeFromLocalStorage(LOCAL_STORAGE_TEST);
    return result === LOCAL_STORAGE_FAIL;
};

const pageIsAllowed = location =>
    [
        pages.cookies,
        pages.localStorage,
    ].some(
        path => location.pathname.includes(path)
    );

const LocalStorageCheck = () => {
    const location = useLocation();
    return (localStorageIsDeactivated() && !pageIsAllowed(location)) ?
        <Redirect to={pages.localStorage}/>
        : null
};

export default LocalStorageCheck;