import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {getSamtykker as getSamtykkerAPI, postSamtykke} from "../services/samtykkerAPI";
import {getTranslation, getTranslationLang} from "./i18n";

export const NAME = 'samtykke';

const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;

const SUBMIT_RESPONSE_PENDING = `${NAME}/SUBMIT_RESPONSE_PENDING`;
const SUBMIT_RESPONSE_SUCCESS = `${NAME}/SUBMIT_RESPONSE_SUCCESS`;
const SUBMIT_RESPONSE_FAILURE = `${NAME}/SUBMIT_RESPONSE_FAILURE`;

export const loadPending = () => ({
    type: LOAD_PENDING,
});

export const loadSuccess = samtykker => ({
    type: LOAD_SUCCESS,
    samtykker,
});

export const loadFailure = error => ({
    type: LOAD_FAILURE,
    error,
});

export const loadSamtykker = () => (dispatch, getState) => {

    if (isLoading(getState())) {
        return;
    }

    const deltakernr = getAktueltDeltakernr(getState());
    const evuapiToken = getEvuapiToken(getState());
    dispatch(loadPending());
    return getSamtykkerAPI(evuapiToken, deltakernr).then(
        samtykker => dispatch(loadSuccess(samtykker)),
        error => dispatch(loadFailure(error))
    );
};

export const submitResponsePending = kode => ({
    type: SUBMIT_RESPONSE_PENDING,
    kode,
});

export const submitResponseSuccess = (kode, besvarelse) => ({
    type: SUBMIT_RESPONSE_SUCCESS,
    kode,
    besvarelse,
});

export const submitResponseFailure = (kode, error) => ({
    type: SUBMIT_RESPONSE_FAILURE,
    kode,
    error,
});

export const submitResponse = (kode, besvarelse) => (dispatch, getState) => {
    const deltakernr = getAktueltDeltakernr(getState());
    const evuapiToken = getEvuapiToken(getState());
    dispatch(submitResponsePending(kode));
    return postSamtykke(evuapiToken, deltakernr, kode, besvarelse).then(
        () => dispatch(submitResponseSuccess(kode, besvarelse)),
        error => dispatch(submitResponseFailure(kode, error))
    );
};

export const createSamtykke = props => {
    const {
        kode = '',
        sporsmal = [],
        undertekst = [],
        besvarelse = undefined,
        isLoading = false,
        error = undefined,
    } = props;
    return {
        kode,
        sporsmal,
        undertekst,
        besvarelse,
        isLoading,
        error,
    }
};

const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    samtykker: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error,
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                samtykker: action.samtykker,
            };
        case SUBMIT_RESPONSE_PENDING:
            return {
                ...state,
                samtykker: endreSamtykke(state, action.kode, {
                    isLoading: true,
                    error: undefined,
                }),
            };
        case SUBMIT_RESPONSE_FAILURE:
            return {
                ...state,
                samtykker: endreSamtykke(state, action.kode, {
                    isLoading: false,
                    error: action.error,
                }),
            };
        case SUBMIT_RESPONSE_SUCCESS:
            return {
                ...state,
                samtykker: endreSamtykke(state, action.kode, {
                    isLoading: false,
                    besvarelse: action.besvarelse,
                }),
            };
        default:
            return state
    }
};

const endreSamtykke = (state, kode, props) => {
    return state.samtykker.map(
        samtykke => samtykke.kode !== kode ? samtykke : {
            ...samtykke,
            ...props,
        }
    )
};


export default reducer;

const getState = state => state[NAME];

export const isLoading = state => getState(state).isLoading;
export const isDataLoaded = state => getState(state).isDataLoaded;
export const getError = state => getState(state).error;

export const getSamtykker = state => getState(state).samtykker;

export const getSamtykkerOversatt = state => getSamtykker(state).map(samtykke => ({
    ...samtykke,
    sporsmal: getTranslation(state, samtykke.sporsmal),
    langSporsmal: getTranslationLang(state, samtykke.sporsmal),
    undertekst: getTranslation(state, samtykke.undertekst),
    langUndertekst: getTranslationLang(state, samtykke.undertekst)

}));

export const isSamtykkerValid = state => getSamtykker(state).every(samtykke => samtykke.besvarelse !== undefined);