import React from "react";
import {ShowMoreButton} from "./ShowMoreButton";
import PropTypes from "prop-types";
import Text from "../primitives/Text";

const MIN_TEXT_LENGTH = 300;

export class ReadMoreText extends React.Component {

    constructor() {
        super();
        this.state = {
            collapsed: true,
        };
    }

    toggleExtend = () => {
        this.setState({
            ...this.state,
            collapsed: !this.state.collapsed,
        });
    };

    render() {
        const {children, className = '', textLength, minTextLength = MIN_TEXT_LENGTH} = this.props;
        const {collapsed} = this.state;

        return (
            <div className={`read-more ${className}`}>
                {textLength && textLength <= minTextLength ?
                    children
                    :
                    <React.Fragment>
                        <div className={`read-more__text ${collapsed ? 'collapsed' : ''}`}>
                            {children}
                        </div>
                        <ShowMoreButton onClick={this.toggleExtend}
                                        extended={!collapsed}
                                        showMoreText={<Text id='les_mer'/>}
                        />
                    </React.Fragment>
                }
            </div>
        )
    }

}

ReadMoreText.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    textLength: PropTypes.number,
    minTextLength: PropTypes.number,
};
