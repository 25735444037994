import React from "react";
import Text from "../../primitives/Text";
import TilknyttedeArbeidserfaringer from "./arbeidserfaring-elementer/TilknyttedeArbeidserfaringer";
import VelgFraTidligereBrukt from "./arbeidserfaring-elementer/VelgFraTidligereBrukt";
import '../arbeidserfaring/arbeidserfaring.scss'

const ArbeidserfaringKategori = () => {
    return (
        <div className='dokumentasjon-kategori arbeidserfaring-kategori'>
            <h4><Text id='arbeidserfaring'/></h4>
            <TilknyttedeArbeidserfaringer/>
            <VelgFraTidligereBrukt/>
        </div>
    )
};


export default ArbeidserfaringKategori;