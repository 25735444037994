import React from 'react';
import RedirectIfLoggedIn from '../wrappers/RedirectIfLoggedIn'

function blockedIfLoggedIn(Component) {
    return class extends React.Component {
        render() {
            return (
                <RedirectIfLoggedIn>
                    <Component {... this.props}/>
                </RedirectIfLoggedIn>
            )
        }
    }
}

export default blockedIfLoggedIn