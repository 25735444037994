import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import PropTypes from "prop-types";
import {
    endreAktivtSteg,
    getAlleSteg,
    tilbakeTilSisteSteg,
} from '../../../ducks/pamelding'
import {TERTIARY} from "../../../primitives/Button";
import Button from "../../../primitives/Button";
import {
    STEG_TYPE_PLANINFO,
    STEG_TYPE_DOKUMENTASJON,
    STEG_TYPE_OVERSIKT,
    STEG_TYPE_PROFIL,
    STEG_TYPE_SPORRESKJEMA, STEG_TYPE_EKSAMEN, STEG_TYPE_SAMTYKKE, STEG_TYPE_FAKTURAINFO
} from "../steg/Steg";
import StegOversiktProfil from "./StegOversiktProfil";
import StegOversiktSporreskjema from "./StegOversiktSporreskjema";
import StegOversiktDokumentasjon from "./StegOversiktDokumentasjon";
import StegOversiktPlaninfo from "./StegOversiktPlaninfo";

import './steg-oversikt.scss';
import {generateStegHeaderRef} from "../../../utils/common";
import StegOversiktEksamen from "./StegOversiktEksamen";
import StegOversiktAkseptanser from "./StegOversiktSamtykke";
import {StegOversiktFakturainfo} from "./StegOversiktFakturainfo";
import Text from "../../../primitives/Text";
import Heading from "../../../primitives/Heading";

export const StegOversikt = () => {

    const alleSteg = useSelector(getAlleSteg);
    const dispatch = useDispatch();

    const endreSteg = id => {
        const stegHeaderRef = generateStegHeaderRef(id);
        dispatch(endreAktivtSteg(id));
        dispatch(tilbakeTilSisteSteg(id));
        document.getElementById(stegHeaderRef).focus();
    };

    const stegUntattOversikt = alleSteg.filter(steg => steg.type !== STEG_TYPE_OVERSIKT);

    return (
        <div className="steg-oversikt">
            {stegUntattOversikt.map(steg =>
                <div className="steg-oversikt__steg" key={steg.type}>
                    <Heading id={`STEG/${steg.type}`} headingLevel={3}/>

                    {steg.redigerbart && steg.type !== STEG_TYPE_PLANINFO &&
                    <Button
                        emphasis={TERTIARY}
                        onClick={() => endreSteg(steg.id)}
                    >
                        <Text id='endre'/>
                    </Button>
                    }

                    <StegDetaljer type={steg.type}/>

                </div>
            )}
        </div>
    )
};

export const StegDetaljer = ({type}) => {
    switch (type) {
        case STEG_TYPE_PROFIL:
            return <StegOversiktProfil/>;
        case STEG_TYPE_DOKUMENTASJON:
            return <StegOversiktDokumentasjon/>;
        case STEG_TYPE_FAKTURAINFO:
            return <StegOversiktFakturainfo/>;
        case STEG_TYPE_SPORRESKJEMA:
            return <StegOversiktSporreskjema/>;
        case STEG_TYPE_PLANINFO:
            return <StegOversiktPlaninfo/>;
        case STEG_TYPE_EKSAMEN:
            return <StegOversiktEksamen/>;
        case STEG_TYPE_SAMTYKKE:
            return <StegOversiktAkseptanser/>;
        default:
            return <div>Steg type {type} mangler oversiktsteg</div>;
    }
};

StegDetaljer.propTypes = {
    type: PropTypes.string.isRequired,
};

export default StegOversikt;