import Text from "../../primitives/Text";
import FormFocusControl from "../inline-edit/FormFocusControl";
import InputLand from "./InputLand";
import InputLinje1 from "./InputLinje1";
import InputLinje2 from "./InputLinje2";
import {isAdressePaNorskFormat} from "../../utils/adresseUtil";
import InputPostnummer from "./InputPostnummer";
import InputPostadresse from "./InputPostadresse";
import React from "react";
import PropTypes from "prop-types";

export const FELT_LAND = 'land';
export const FELT_LINJE1 = 'linje1';
export const FELT_LINJE2 = 'linje2';
export const FELT_POSTNUMMER = 'postnummer';
export const FELT_POSTADRESSE = 'postadresse';

const AdresseForm = ({typeAdresse, onChange, adresse, errors, closeEditing, attemptedClosed, onBlur}) => {
    const {linje1, linje2, postnummer, postadresse, land: landnummer} = adresse;
    return (
        <fieldset>
            <legend>
                <Text id={typeAdresse}/>
            </legend>
            <FormFocusControl onFocusOut={closeEditing} ignore={!closeEditing}>
                <InputLand
                    value={landnummer}
                    onChange={onChange}
                    onBlur={onBlur && (() => onBlur(FELT_LAND))}
                />
                <InputLinje1
                    typeAdresse={typeAdresse}
                    value={linje1}
                    error={errors.linje1}
                    showError={attemptedClosed}
                    onChange={onChange}
                    onBlur={onBlur && (() => onBlur(FELT_LINJE1))}
                />
                <InputLinje2
                    typeAdresse={typeAdresse}
                    value={linje2}
                    error={errors.linje2}
                    showError={attemptedClosed}
                    onChange={onChange}
                    onBlur={onBlur && (() => onBlur(FELT_LINJE2))}
                />
                {isAdressePaNorskFormat(adresse) ?
                    <InputPostnummer
                        value={postnummer}
                        error={errors.postnummer}
                        showError={attemptedClosed}
                        onChange={onChange}
                        onBlur={onBlur && (() => onBlur(FELT_POSTNUMMER))}
                    /> :
                    <InputPostadresse
                        value={postadresse}
                        error={errors.postadresse}
                        showError={attemptedClosed}
                        onChange={onChange}
                        onBlur={onBlur && (() => onBlur(FELT_POSTADRESSE))}
                    />
                }
            </FormFocusControl>
        </fieldset>
    )
};

AdresseForm.propTypes = {
    typeAdresse: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    adresse: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    closeEditing: PropTypes.func,
    attemptedClosed: PropTypes.bool,
};

export default AdresseForm;