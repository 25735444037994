import * as institusjonerAPI from "../services/institusjonerAPI";
import {getLocale} from "./i18n";
import {getValueByLanguage} from "../utils/common";
import sortBy from "lodash/sortBy";

export const NAME = 'institusjoner';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;


export function loadPending() {
    return {
        type: LOAD_PENDING,
    };
}

export function loadSuccess(institusjoner) {
    return {
        type: LOAD_SUCCESS,
        institusjoner: institusjoner,
    };
}

export function loadFailure(error) {
    return {
        type: LOAD_FAILURE,
        error: error
    };
}


export function loadInstitusjoner() {
    return function (dispatch) {
        dispatch(loadPending());
        return institusjonerAPI.getInstitusjoner().then(
            institusjoner => dispatch(loadSuccess(institusjoner)),
            error => dispatch(loadFailure(error))
        );
    };
}


const initialState = {
    isLoading: false,
    error: undefined,
    data: [],
};


function institusjoner(state = initialState, action) {

    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.institusjoner,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME];
}


export function getInstitusjoner(state) {
    return getState(state).data;
}


export function getInstitusjonerSortedByLabel(state) {
    const locale = getLocale(state);

    const options = getInstitusjoner(state).map(inst => {
        const navn = getValueByLanguage(inst.navn, locale);
        return {
            value: inst.akronym,
            label: navn
        }
    });

    return sortBy(options, 'label');
}


export function isLoading(state) {
    return getState(state).isLoading;
}


export function getError(state) {
    return getState(state).error;
}


export function isDataLoaded(state) {
    return Boolean(getInstitusjoner(state).length);
}


export function getInstitusjonByAkronym(state, akronym) {

    const institusjoner = getInstitusjoner(state);
    return institusjoner.find(inst => inst.akronym === akronym);

}


export default institusjoner;