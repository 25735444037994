import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getPlaninfoFromKode, lagreBekreftelse} from '../../ducks/planinfo';
import Checkbox from "../../primitives/Checkbox";
import PropTypes from 'prop-types';
import ReadOnlyField from "../ReadOnlyField";
import Laster from "../Laster";
import {injectIntl} from 'react-intl';
import Error from "../Error";
import {getForsoktNeste} from "../../ducks/pamelding";
import {formatDate} from "../../ducks/i18n";
import Text from "../../primitives/Text";
import {checkmarkIconPath} from "../../utils/icons";

export const PlaninfoBekreftelse = ({kode}) => {
    const {
        merknadtekst,
        merknadtekstLang,
        dato_bekreftet,
        status_bekreftet,
        isLagring,
        wsError,
    } = useSelector(state => getPlaninfoFromKode(state, kode));
    const showErrors = useSelector(getForsoktNeste);
    const datoBekreftetFormatert = useSelector(state => formatDate(state, dato_bekreftet));
    const dispatch = useDispatch();

    if (isLagring) {
        return (
            <Laster>
                <Text id="lagrer_bekreftelse"/>
            </Laster>
        )
    }
    if (status_bekreftet) {
        return (
            <ReadOnlyField
                className='planinfo__element__bekreftelse'
                iconPath={checkmarkIconPath}
                value={
                    <Text
                        id="bekreftet_dato"
                        values={{
                            dato: datoBekreftetFormatert
                        }}
                    />
                }
            />
        );
    }
    return (
        <div className='planinfo__element__bekreftelse'>
            <Checkbox
                checked={false}
                label={<Text id="jeg_er_kjent_med_innholdet"/>}
                onChange={() => dispatch(lagreBekreftelse(kode))}
            />
            {showErrors &&
            <Error>
                <Text id="må_bekreftes"/>
                <br/><span lang={merknadtekstLang}>{merknadtekst}</span>
            </Error>
            }
            {wsError &&
            <Error>
                <Text id="feil_under_lagring_av_bekreftelse"/>
            </Error>
            }
        </div>);
};

PlaninfoBekreftelse.propTypes = {
    kode: PropTypes.string.isRequired,
};

export default injectIntl(PlaninfoBekreftelse);