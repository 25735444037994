import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormField,
    getEpost,
    getForsoktOpprett,
    getIsEpostFraKorr,
    getErrorOpprett, getEpostArbeid
} from "../../ducks/nyBruker";
import ReadOnlyField from "../ReadOnlyField";
import {emailIconPath, workIconPath} from "../../utils/icons";
import KorrHelpButton from "../profil/korr/KorrHelpButton";
import InputEpost from "../profil/eposter/InputEpost";
import {getErrorMessageId, isEpostRelatertApiError} from "../../utils/epostUtil";
import Text from "../../primitives/Text";


const Epost = () => {
    const isEpostFraKorr = useSelector(getIsEpostFraKorr);
    return (
        <div className='flat-card'>
            <h2><Text id='e_post'/></h2>
            <div className='content e-post'>
                {isEpostFraKorr ? <EpostPrivatHentetFraKorr/> : <InputEpostPrivat/>}
                <InputEpostArbeid/>
            </div>
        </div>
    )
};

const EpostPrivatHentetFraKorr = () => {
    const epost = useSelector(getEpost);
    return (
        <div className='info-with-button-wrapper privat-epost'>
            <ReadOnlyField value={epost}
                           iconPath={emailIconPath}
                           title={'privat'}
            />
            <KorrHelpButton mobil={false}/>
        </div>
    )
};

const InputEpostPrivat = () => {
    const epost = useSelector(getEpost);
    const doneEditing = useSelector(getForsoktOpprett);
    const errorLagring = useSelector(getErrorOpprett);

    const dispatch = useDispatch();

    const onChange = value => {
        dispatch(changeFormField('epost', value));
    };

    const apiError = isEpostRelatertApiError(errorLagring) ? errorLagring : undefined;
    const errorMessageId = getErrorMessageId(epost, true, apiError, doneEditing);

    return <InputEpost
        epost={epost}
        onChange={onChange}
        errorMessageId={errorMessageId}
        labelId='privat'
        iconPath={emailIconPath}
    />

};

const InputEpostArbeid = () => {
    const epostArbeid = useSelector(getEpostArbeid);
    const doneEditing = useSelector(getForsoktOpprett);
    const dispatch = useDispatch();

    const errorMessageId = getErrorMessageId(epostArbeid, false, undefined, doneEditing);

    const onChange = value => {
        dispatch(changeFormField('epostArbeid', value));
    };

    return <InputEpost
        epost={epostArbeid}
        onChange={onChange}
        errorMessageId={errorMessageId}
        className='arbeidsepost'
        labelId='arbeid'
        iconPath={workIconPath}
    />

};

export default Epost