import {useDispatch, useSelector} from "react-redux";
import {
    changeBetalingsinfoField,
    getDefaultFakturamottaker,
    getErrorLagringForFelt,
    getFakturamottaker,
    isBedriftBetaler,
    isFakturamottakerForLang,
    lagreBetalingsinfo
} from "../../ducks/betalingsinfo";
import React from "react";
import Input from "../../primitives/Input";
import ErrorFormSaving from "../ErrorFormSaving";
import Error from "../Error";
import Text from "../../primitives/Text";

export const FELT_FAKTURAMOTTAKER = 'fakturamottaker';

const InputFakturamottaker = () => {
    const fakturamottaker = useSelector(getFakturamottaker);
    const bedriftBetaler = useSelector(isBedriftBetaler);
    const defaultFakturamottaker = useSelector(getDefaultFakturamottaker);
    const errorFakturamottaker = useSelector(state => getErrorLagringForFelt(state, FELT_FAKTURAMOTTAKER));
    const fakturamottakerForLang = useSelector(state => isFakturamottakerForLang(state));
    const dispatch = useDispatch();
    const onBlur = () => dispatch(lagreBetalingsinfo(FELT_FAKTURAMOTTAKER));

    return (
        <div className='bedrift-input'>
            <Input
                label='fakturamottaker'
                value={fakturamottaker || defaultFakturamottaker}
                autoComplete={bedriftBetaler ? 'organization' : 'name'}
                onChange={e => dispatch(changeBetalingsinfoField('fakturamottaker', e.target.value))}
                onBlur={onBlur}
                hasError={!!errorFakturamottaker}
            />
            {errorFakturamottaker &&
            <ErrorFormSaving
                error={errorFakturamottaker}
                provIgjen={onBlur}
            />
            }
            {fakturamottakerForLang &&
            <Error className={"error-message-in-place"}><Text id={"fakturamottaker_lengdeerror"}/></Error>}
        </div>
    )
};

export default InputFakturamottaker;