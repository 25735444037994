import {Link} from "react-router-dom";
import React from "react";
import {useLocation} from "react-router";

const ListLink = ({to, children}) => (
    <li>
        <Link to={to}>
            {children}
        </Link>
    </li>
);

export const ListOnClickLink = ({onClick, children}) => {

    const location = useLocation();

    const trigger = e => {
        e.preventDefault();
        onClick();
    };

    return (
        <li>
            <a href={location.pathname} onClick={trigger}>
                {children}
            </a>
        </li>
    )
};

export default ListLink;