import React from 'react'
import {useSelector} from "react-redux";
import {getKanEndreStatsborgerskap,} from "../../../ducks/profil";
import Malform from "./Malform";
import Statsborgerskap from "./Statsborgerskap";
import Text from "../../../primitives/Text";

export const Annet = () => {
    const kanEndreStatsborgerskap = useSelector(getKanEndreStatsborgerskap);
    return (
        <div className='flat-card'>
            <h2>
                <Text id='annet'/>
            </h2>
            <div className='content'>
                <Statsborgerskap editDisabled={!kanEndreStatsborgerskap}/>
                <Malform/>
            </div>
        </div>
    );
};

export default Annet;
