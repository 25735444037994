import React from 'react';
import PropTypes from "prop-types";
import {
    ACTION_TYPE_ANGRE_TREKK,
    ACTION_TYPE_DOKUMENTASJON,
    ACTION_TYPE_ENDRE, ACTION_TYPE_ENDRE_FAKTURAINFO, ACTION_TYPE_ENDRE_SVAR,
    ACTION_TYPE_SE, ACTION_TYPE_SLETTE, ACTION_TYPE_SVARE,
    ACTION_TYPE_TREKKE, ACTION_TYPE_TREKKE_FRA_VENTELISTE,
    STATUS_IKKE_FULLFORT, STATUS_PAMELDT
} from "../../utils/soknadStatus";
import Text from "../../primitives/Text";

const getTextByType = (type, status) => {
    switch (type) {
        case ACTION_TYPE_ENDRE:
            return status === STATUS_IKKE_FULLFORT ?
                {
                    buttonText: 'fullfor_soknad',
                    screenReaderText: 'for_å_fullfore_soknaded',
                }
                :
                {
                    buttonText: 'endre_soknad',
                    screenReaderText: 'for_å_endre_søknad',
                };
        case ACTION_TYPE_SE:
            return {
                buttonText: 'se_søknad',
                screenReaderText: 'for_å_se_søknad',
            };
        case ACTION_TYPE_TREKKE_FRA_VENTELISTE:
            return {
                buttonText: 'trekk_fra_venteliste',
                screenReaderText: 'for_å_trekke_fra_venteliste',
            };
        case ACTION_TYPE_SLETTE:
            return {
                buttonText: 'slette_soknad',
                screenReaderText: 'for_å_slette_søknad',
            };
        case ACTION_TYPE_TREKKE:
            return status === STATUS_PAMELDT ?
                {
                    buttonText: 'trekk_påmelding',
                    screenReaderText: 'for_å_trekke_påmelding',
                }
                :
                {
                    buttonText: 'trekke_soknad',
                    screenReaderText: 'for_å_trekke_soknad',
                };
        case ACTION_TYPE_ANGRE_TREKK:
            return {
                buttonText: 'angre_trekk',
                screenReaderText: 'frist_for_å_angre_trekk',
            };
        case ACTION_TYPE_DOKUMENTASJON:
            return {
                buttonText: 'legg_til_dokumentasjon',
                screenReaderText: 'for_å_legge_til_dokumentasjon',
            };
        case ACTION_TYPE_SVARE:
            return {
                buttonText: 'svar_på_tilbud',
                screenReaderText: 'for_å_svare_på_tilbud',
            };
        case ACTION_TYPE_ENDRE_SVAR:
            return {
                buttonText: 'endre_svar',
                screenReaderText: 'for_å_endre_svar',
            };
        case ACTION_TYPE_ENDRE_FAKTURAINFO:
            return {
                buttonText: 'legg_til_betalingsinfo',
                screenReaderText: 'for_å_legge_til_betalingsinfo',
            };
        default:
            return {};
    }
};

export const SoknadRowActionText = ({type, status}) => {
    const {buttonText} = getTextByType(type, status);
    return (
        <span className='action__text'>
            <Text id={buttonText}/>
        </span>
    )
};

SoknadRowActionText.propTypes = {
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};

export const ScreenReaderActionText = ({type, status}) => {
    const {screenReaderText} = getTextByType(type, status);
    return (
        <span className='vis-for-sr'>
            <Text id={screenReaderText}/>
        </span>
    )
};

ScreenReaderActionText.propTypes = {
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
};


export default SoknadRowActionText;