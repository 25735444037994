import React from 'react';
import {loadPamelding, isPameldingLoading, getLoadError, isDataLoaded} from '../ducks/pamelding';
import InitialLoadWrapper from "../wrappers/InitialLoadWrapper";
import Text from "../primitives/Text";


const withPameldingLoaded = (Component, reloadOnMount) => props => {
    return (
        <InitialLoadWrapper
            reloadOnMount={reloadOnMount}
            loadAction={loadPamelding}
            loadingSelector={isPameldingLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getLoadError}
            loadMessage={<Text id='laster_inn_påmelding'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )

};

export default withPameldingLoaded;