import {createSlice} from "@reduxjs/toolkit";
import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {getKursId} from "./kursinfo";
import {postSkalTaEksamen} from "../services/eksamenAPI";
import * as pameldingApi from "../services/pameldingApi";
import {getAlleSteg, settStegTilIkkeFullfort} from "./pamelding";
import {loadSoknader} from "./soknader";

export const NAME = 'eksamen';
export const initialState = {
    isLagring: false,
    errorLagring: undefined,
    skalTaEksamenOriginal: undefined,
    skalTaEksamen: undefined,
};

const eksamenSlice = createSlice({
    name: NAME,
    initialState: initialState,
    reducers: {
        loadSkalTaEksamenSuccess(state, {payload: value}) {
            state.isLagring = false;
            state.errorLagring = undefined;
            state.skalTaEksamenOriginal = value;
            state.skalTaEksamen = value;
        },
        lagreEksamenPending(state) {
            state.isLagring = true;
            state.errorLagring = undefined;
        },
        lagreEksamenSuccess(state) {
            state.isLagring = false;
            state.skalTaEksamenOriginal = state.skalTaEksamen;
        },
        lagreEksamenFailure(state, {payload: error}) {
            state.isLagring = false;
            state.errorLagring = error;
            state.skalTaEksamen = state.skalTaEksamenOriginal;
        },
        endreSkalTaEksamen(state, {payload: value}) {
            state.skalTaEksamen = value;
            state.errorLagring = undefined;
        },
    }
});

export const {
    loadSkalTaEksamenSuccess,
    lagreEksamenPending,
    lagreEksamenSuccess,
    lagreEksamenFailure,
    endreSkalTaEksamen,
} = eksamenSlice.actions;


export const endreSkalTaEksamenOgLagre = value => async dispatch => {
    dispatch(endreSkalTaEksamen(value));
    dispatch(lagreSkalTaEksamen());
};

export const lagreSkalTaEksamen = () => async (dispatch, getState) => {
    const isLagring = getIsLagring(getState());
    if (isLagring) {
        return;
    }
    dispatch(lagreEksamenPending());
    try {
        const state = getState();
        const evuapiToken = getEvuapiToken(state);
        const deltakernr = getAktueltDeltakernr(state);
        const kursId = getKursId(state);
        const value = getSkalTaEksamen(state);
        await postSkalTaEksamen(evuapiToken, deltakernr, kursId, value);
        dispatch(lagreEksamenSuccess());
        dispatch(loadSoknader());
        getAlleSteg(state).filter(steg => steg.fullfort).forEach(steg => {
            dispatch(settStegTilIkkeFullfort(steg.id));
            pameldingApi.deleteFullfortSteg(evuapiToken, kursId, deltakernr, steg.type).then().catch()
        })
    } catch (error) {
        dispatch(lagreEksamenFailure(error))
    }
};


export const getEksamen = state => state[NAME];
export const getIsLagring = state => getEksamen(state).isLagring;
export const getErrorLagring = state => getEksamen(state).errorLagring;
export const getSkalTaEksamen = state => getEksamen(state).skalTaEksamen;

export function getEksamenBesvart(state) {
    return getEksamen(state).skalTaEksamen !== undefined;
}


export default eksamenSlice.reducer