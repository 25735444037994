import React from "react";
import {useSelector} from "react-redux";
import {formatAmount} from "../ducks/i18n";


export const FormattedAmount = ({amount, className}) => {
    const formattedAmount = useSelector(state => formatAmount(state, amount));
    return amount ? <span className={className}>{formattedAmount}</span> : null;
};

export default FormattedAmount;
