import React from 'react';
import InitialLoadWrapper from '../wrappers/InitialLoadWrapper';
import {getError, isDataLoaded, isLoading, loadAppegenskaper} from "../ducks/appegenskaper";
import Text from "../primitives/Text";

const withAppegenskaperLoaded = Component => props => {
    return (
        <InitialLoadWrapper
            loadAction={loadAppegenskaper}
            loadingSelector={isLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getError}
            loadMessage={<Text id='laster_institusjons_innstillinger'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )

};

export default withAppegenskaperLoaded;