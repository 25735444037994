import axios from 'axios';
import * as routes from './apiRoutes'
import {nullToUndefined, TYPE_ADRESSE_ARBEID, TYPE_ADRESSE_HJEM} from "../utils/common";
import dateFormat from "dateformat";
import {createAdresse, isAdressePaNorskFormat} from "../utils/adresseUtil";
import {createRequestConfig} from "../utils/request";

const WS_TYPE_TELEFON_MOBIL = 'MOBIL';
const WS_TYPE_TELEFON_ARBEID = 'ARB';

const WS_TYPE_ADRESSE_HJEM = 'HJEM';
const WS_TYPE_ADRESSE_ARBEID = 'JOBB';

export async function getProfilData(deltakernr, evuapiToken) {

    const route = routes.PROFIL.replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);
    const response = await axios.get(route, config);
    return transformDataFromWS(response.data);
}


export async function lagreProfil(deltakernr, data, evuapiToken) {

    const route = routes.PROFIL.replace(':deltakernr', deltakernr);
    const input = transformDataToWSFormat(data);

    const config = createRequestConfig(evuapiToken);
    return await axios.post(route, input, config);
}


export async function lagreAdresse(data, type, deltakernr, evuapiToken) {

    const wsTyperMap = {
        [TYPE_ADRESSE_HJEM]: WS_TYPE_ADRESSE_HJEM,
        [TYPE_ADRESSE_ARBEID]: WS_TYPE_ADRESSE_ARBEID,
    };

    const route = routes.ADRESSE
        .replace(':deltakernr', deltakernr)
        .replace(':type', wsTyperMap[type]);

    const input = transformAdresseToWSFormat(data);
    const config = createRequestConfig(evuapiToken);
    return await axios.post(route, input, config);
}


function transformDataFromWS(data) {
    return {
        deltakernr: data.deltakernr,
        fornavn: data.fornavn,
        etternavn: data.etternavn,
        fodselsdato: data.dato_fodt,
        kjonn: data.kjonn,
        statsborgerskap: nullToUndefined(data.landnr_hjemland),
        malform: data.sprakkode_malform,
        hjemstedsadresse: transformHjemmeadresseFromWS(data),
        arbeidsadresse: transformArbeidadresseFromWS(data),
        epostPrivat: data.emailadresse_privat,
        epostArbeid: data.emailadresse_arbeid,
        mobiltelefon: transformTelefonFromWS(data.telefoner, WS_TYPE_TELEFON_MOBIL),
        arbeidstelefon: transformTelefonFromWS(data.telefoner, WS_TYPE_TELEFON_ARBEID),
        epostFraKorr: data.emailadresse_privat_sperret,
        mobilFraKorr: isMobilFraKorr(data.telefoner),
        adresseSperret: data.adresse_sperret,
        statsborgerskapSperret: data.statsborgerskap_sperret,
        navnFraDsf: data.navn_sperret,
        harFSFiktivtFodselsnummer: data.harFSFiktivtFodselsnummer,
    }
}

const isMobilFraKorr = telefoner => {
    const mobiltelefon = telefoner.find(telefon => telefon.telefonnrtypekode === WS_TYPE_TELEFON_MOBIL);
    return mobiltelefon && mobiltelefon.sperret;
};


function transformHjemmeadresseFromWS(data) {
    return createAdresse({
        linje1: data.adrlin1_hjem,
        linje2: data.adrlin2_hjem,
        land: nullToUndefined(data.adresselandnr_hjem),
        postadresse: data.adrlin3_hjem,
        postnummer: data.postnr_hjem,
    });
}


function transformArbeidadresseFromWS(data) {
    return createAdresse({
        linje1: data.adrlin1_job,
        linje2: data.adrlin2_job,
        land: nullToUndefined(data.adresselandnr_job),
        postadresse: data.adrlin3_job,
        postnummer: data.postnr_job,
    });
}


function transformTelefonFromWS(telefoner, type) {

    const telefon = telefoner.find(telefon => telefon.telefonnrtypekode === type);
    const {telefonnr, telefonlandnr, landnr} = telefon || {};

    return {
        landskode: telefonlandnr,
        landnummer: landnr,
        nummer: telefonnr,
    };
}


function transformDataToWSFormat(data) {
    return {
        emailadresse_privat: data.epostPrivat,
        epostadresseArbeid: data.epostArbeid,
        landnr_hjemland: data.statsborgerskap,
        sprakkode_malform: data.malform,
        kjonn: data.kjonn,
        dato_fodt: data.fodselsdato && dateFormat(new Date(data.fodselsdato), 'yyyy-mm-dd'),
        fornavn: data.fornavn,
        etternavn: data.etternavn,
    };
}


function transformAdresseToWSFormat(adresse) {

    if (!adresse)
        return undefined;

    return {
        adresselinje1: isVerdiTom(adresse.linje1),
        adresselinje2: isVerdiTom(adresse.linje2),
        landnummer: isAdressePaNorskFormat(adresse) ? null : adresse.land,
        sted: isAdressePaNorskFormat(adresse) ? '' : adresse.postadresse,
        postnummer: isAdressePaNorskFormat(adresse) ? isVerdiTom(adresse.postnummer) : null,
    }
}

function isVerdiTom(verdi) {
    return verdi === '' ? null : verdi;
}






