import React, {useState} from "react";
import OnKeyDownCatcher from "../OnKeyDownCatcher";
import PropTypes from "prop-types";
import useInlineEditInputsFocusControl from "../../hooks/useInlineEditInputsFocusControl";
import {useDispatch, useSelector} from "react-redux";
import {nanoid} from "nanoid";
import {getAktivInlineEditFormId, setAktivInlineEditFormId} from "../../ducks/aktivInlineEdit";
import {useMountEffect} from "../../hooks/mounting-hooks";


//children må ha isFocusOn prop
const FormFocusControl = ({onFocusOut, children, className, ignore}) => {
    const [id] = useState(nanoid());
    const dispatch = useDispatch();
    const aktivId = useSelector(getAktivInlineEditFormId);
    const isFormAktiv = aktivId === id;
    const onFormFocus = () => !isFormAktiv && dispatch(setAktivInlineEditFormId(id));

    useMountEffect(onFormFocus);

    if (ignore) {
        return children;
    }

    if (!children.length) {
        return (
            <OneInputFocusControl
                onFocusOut={onFocusOut}
                child={children}
                isFormAktiv={isFormAktiv}
                onFormFocus={onFormFocus}
                className={className}
            />
        )
    }
    return (
        <SeveralInputsFocusControl
            onFocusOut={onFocusOut}
            children={children.filter(Boolean)}
            isFormAktiv={isFormAktiv}
            onFormFocus={onFormFocus}
            className={className}
        />
    )
};

const OneInputFocusControl = ({child, onFocusOut, isFormAktiv, onFormFocus, className}) => {
    return (
        <OnKeyDownCatcher
            onEnter={onFocusOut}
            preventDefaultOnTab
            onTab={onFocusOut}
            onShiftTab={onFocusOut}
            className={className}
            onFocus={onFormFocus}
        >
            {React.cloneElement(child, {isFocusOn: isFormAktiv, autoFocus: true})}
        </OnKeyDownCatcher>
    )
};


const SeveralInputsFocusControl = ({children, onFocusOut, isFormAktiv, onFormFocus, className}) => {
    const [focusIndex, setFocusIndex, moveForward, moveBack] = useInlineEditInputsFocusControl(onFocusOut, children.length);
    return children.map((child, index) => (
        <OnKeyDownCatcher
            key={index}
            onEnter={() => moveForward(index)}
            preventDefaultOnTab
            onTab={() => moveForward(index)}
            onShiftTab={() => moveBack(index)}
            onClick={() => setFocusIndex(index)}
            onFocus={onFormFocus}
            className={className}
        >
            {React.cloneElement(child, {isFocusOn: (isFormAktiv && index === focusIndex)})}
        </OnKeyDownCatcher>
    ));
};

FormFocusControl.propTypes = {
    children: PropTypes.node.isRequired,
    onFocusOut: PropTypes.func,
    className: PropTypes.string,
};


export default FormFocusControl;