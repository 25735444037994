import {useDispatch, useSelector} from "react-redux";
import {
    getDokumentIdFromArbeidserfaring,
    getDokumentNavnFromArbeidserfaring, getEditIsUploading, setDeleteDokumentOnSave,
} from "../../../ducks/arbeidserfaring";
import {useDownloadDokument} from "../../../hooks/useDownloadDokument";
import ClickableArea from "../../ClickableArea";
import Text from "../../../primitives/Text";
import React from "react";
import Button from "../../../primitives/Button";
import {showPopup} from "../../../ducks/popup";
import {deleteIconPath} from "../../../utils/icons";
import {mdiFileOutline} from "@mdi/js";
import Icon from "../../../primitives/Icon";

const Dokument = ({id, isInEditMode = false}) => {
    const dispatch = useDispatch();
    const dokId = useSelector(state => getDokumentIdFromArbeidserfaring(state, id));
    const dokNavn = useSelector(state => getDokumentNavnFromArbeidserfaring(state, id));
    const isInProgress = useSelector(getEditIsUploading);
    const download = useDownloadDokument();

    const slettOnClick = () => {
        const popUpContent = {
            onOK: () => dispatch(setDeleteDokumentOnSave()),
            title: <Text id='slette_dokument'/>,
            children: <Text
                id='er_du_sikker_på_at_du_vil_slette_fil'
                values={{filnavn: <em>{dokNavn}</em>}}
            />,
            okButtonContent: <Text id='ja'/>,
            cancelButtonContent: <Text id='nei'/>,
        };
        dispatch(showPopup(popUpContent));
    };

    return (
        <div className='dokument'>
            <Icon path={mdiFileOutline}/>
            <ClickableArea
                onClick={() => download(dokId, dokNavn)}
                className={'link'}
            >
                {dokNavn || <Text id={'dokument'}/>}
            </ClickableArea>
            {isInEditMode &&
            <Button
                onClick={slettOnClick}
                className='delete'
                iconPath={deleteIconPath}
                altText='slett_dokument'
                isInProgress={isInProgress}
            />
            }
        </div>
    );
};

export default Dokument;