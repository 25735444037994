export const appendScript = (scriptToAppend) => {
    const script = document.createElement("script");
    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
}

export const removeScript = (scriptToremove) => {
    let allScripts = document.getElementsByTagName("script");
    for (let i = allScripts.length; i >= 0; i--) {
        if (allScripts[i] && allScripts[i].getAttribute("src") !== null
            && allScripts[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1) {
            allScripts[i].parentNode.removeChild(allScripts[i])
        }
    }
}