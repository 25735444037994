import React from 'react'
import {useSelector} from 'react-redux'
import KursPreview from './KursPreview'
import {kursIdToString} from "../../utils/common"
import Feedback from "../Feedback"
import {getVisibleKurslisteSortert} from "../../ducks/kursoversikt"
import useLoadSoknader from "../../hooks/useLoadSoknader";

import './kursliste.scss';
import Text from "../../primitives/Text";

const VisibleKursliste  = () => {

    useLoadSoknader();

    const kursliste = useSelector(getVisibleKurslisteSortert);

    return (
        <div className="kursliste">
            {kursliste.length === 0 &&
            <Feedback>
                <Text id={'finner_ingen_kurs_med_valgte_kriterier'}/>
            </Feedback>
            }
            {kursliste.map((kurs, index) =>
                <div className="kursliste__kurs" key={`${kursIdToString(kurs.id)}${index}`}>
                    <KursPreview kurs={kurs}/>
                </div>
            )}
        </div>
    );
};

export default VisibleKursliste;