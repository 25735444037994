import React from 'react'

const ShowOnlyForScreenReader = (props) => {
    const {override, children, ...restOfProps} = props;
    const classes = override ? '' : 'vis-for-sr';

    return (
        <span className={classes} {...restOfProps}>
            {children}
        </span>);
};

export const ShowOnlyForScreenReaderDiv = (props) => {
    const {override, children, ...restOfProps} = props;
    const classes = override ? '' : 'vis-for-sr';

    return (
        <div className={classes} {...restOfProps}>
            {children}
        </div>);
};

export default ShowOnlyForScreenReader