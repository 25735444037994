import {useEffect} from 'react';
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import {APPLICATION_NAME} from "../utils/common";

const PageTitle = ({id, values, text, intl}) => {
    const setPageTitle = () => {
        if (text) {
            document.title = `${text} - ${APPLICATION_NAME}`;
            return;
        }
        const {formatMessage} = intl;
        document.title = id ? `${formatMessage({id}, values)} - ${APPLICATION_NAME}` : APPLICATION_NAME;
    };

    useEffect(setPageTitle, [id, values, text, intl]);
    return null;
};


PageTitle.propTypes = {
    id: PropTypes.string,
    values: PropTypes.object,
    text: PropTypes.string,
};

export default injectIntl(PageTitle);