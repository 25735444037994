import {useDispatch} from "react-redux";
import Text from "../../primitives/Text";
import React from "react";
import HTMLReactParser from "html-react-parser";
import FormattedDate from "../../primitives/FormattedDate";
import {EPOST_DOKUMENTTYPE} from "../../ducks/inngaendeDokumenter";
import {removeHtml} from "../../utils/common";
import ClickableArea from "../ClickableArea";
import {showPopup} from "../../ducks/popup";
import {mdiEmail} from '@mdi/js';
import Icon from "../../primitives/Icon";


export const Epost = ({dokument}) => {

    const dispatch = useDispatch();
    const {emne, innhold, datoMottatt} = dokument;

    return (
        <ClickableArea onClick={() => dispatch(showPopup(getEpostPopupContent(dokument)))}>
            <span className='type'><Icon path={mdiEmail}/><Text id={EPOST_DOKUMENTTYPE}/></span>
            <div className='innhold'>
                {emne ? emne : removeHtml(innhold)}
            </div>
            <FormattedDate className='dato-mottat' date={datoMottatt} withTime={true}/>
        </ClickableArea>
    )
};


const getEpostPopupContent = ({datoMottatt, avsender, emne, innhold, mottaker}) => {
    return {
        title: emne,
        style: {wide: true, wrapLongHeader: true},
        children: (
            <dl className='epost-innhold'>
                <div>
                    <dt><Text id='fra'/>:</dt>
                    <dd>{avsender}</dd>
                </div>
                <div>
                    <dt><Text id='dato'/>:</dt>
                    <dd><FormattedDate date={datoMottatt}/></dd>
                </div>
                <div>
                    <dt><Text id='til'/>:</dt>
                    <dd>{mottaker}</dd>
                </div>
                <div className='body'>
                    <dt><Text id='innhold'/>:</dt>
                    <dd>{HTMLReactParser(innhold)}</dd>
                </div>
            </dl>
        )
    }
};

export default Epost;