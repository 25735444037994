import React from 'react';
import PropTypes from "prop-types";
import {getClassFromStatus, getTextIdFromStatus} from "../../utils/soknadStatus";
import Actions from "./Actions";
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import Text from "../../primitives/Text";

const SoknadRow = ({id, kursnavn, status, manglerBetalingsinfo}) =>
        <div className='soknader__liste__soknad'>
            <div className='soknader__liste__soknad__kursnavn'>
                <h2>
                    {kursnavn}
                </h2>
            </div>
            <div className={`soknader__liste__soknad__status ${getClassFromStatus(status)}`}>
                <strong>
                    <Text id={getTextIdFromStatus(status)}/>
                </strong>
            </div>

            {manglerBetalingsinfo &&
            <Feedback type={FEEDBACK_TYPE_VARSEL} showIcon={false}>
                <Text id={'denne_søknaden_mangler_betalingsinfo'}/>
            </Feedback>
            }

            <div className='soknader__liste__soknad__actions'>
                <Actions soknadId={id} kursnavn={kursnavn} status={status}/>
            </div>
        </div>
;

SoknadRow.propTypes = {
    id: PropTypes.PropTypes.shape({
        kurskode: PropTypes.string.isRequired,
        tidskode: PropTypes.string.isRequired,
    }).isRequired,
    kursnavn: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired,
};

export default SoknadRow