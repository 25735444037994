import {useSelector} from "react-redux";
import {
    changeFormField,
    getKanRedigerePersoninfo, getStatsborgerskap
} from "../../ducks/nyBruker";
import ReadOnlyField from "../ReadOnlyField";
import React from "react";
import HelpButton from "./HelpButton";
import InputLand from "../InputLand";
import {mdiEarth} from "@mdi/js";
import {getLandByNummer} from "../../ducks/land";

export const Statsborgerskap = ({forsoktOpprett, dispatch}) => {
    const statsborgerskap = useSelector(getStatsborgerskap);
    const statsborgerskapLand = useSelector(state => getLandByNummer(state, statsborgerskap));
    const kanRedigerePersoninfo = useSelector(getKanRedigerePersoninfo);
    const onChangeStatsborgerskap = selectedOption => dispatch(changeFormField('statsborgerskap', selectedOption.value));
    const errorMessageIdStatsborgerskap = statsborgerskap === undefined && forsoktOpprett && 'statsborgerskap_obligatorisk';

    if (kanRedigerePersoninfo) {
        return <InputLand title='statsborgerskap'
                          withIcon
                          clearable={false}
                          value={statsborgerskap}
                          onChange={onChangeStatsborgerskap}
                          errorMessageId={errorMessageIdStatsborgerskap}
        />
    }

    return (
        <div className='info-with-button-wrapper'>
            <ReadOnlyField iconPath={mdiEarth} title='statsborgerskap' value={statsborgerskapLand}/>
            <HelpButton/>
        </div>
    )
};


export default Statsborgerskap;