import * as postnumreAPI from "../services/postnumreAPI";
import {getEvuapiToken} from "./session";
import {isObjectEmpty} from "../utils/common";

export const NAME = 'poststeder';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;


export function loadPending() {
    return {
        type: LOAD_PENDING,
    };
}

export function loadSuccess(postnummer, poststed) {
    return {
        type: LOAD_SUCCESS,
        postnummer: postnummer,
        poststed: poststed,
    };
}

export function loadFailure(error) {
    return {
        type: LOAD_FAILURE,
        error: error
    };
}


export function loadPoststed(postnummer) {
    return function (dispatch, getState) {
        dispatch(loadPending());
        const evuapiToken = getEvuapiToken(getState());
        return postnumreAPI.getPoststed(postnummer, evuapiToken).then(
            poststed => dispatch(loadSuccess(postnummer, poststed)),
            error => dispatch(loadFailure(error))
        );
    };
}


const initialState = {
    isLoading: false,
    error: undefined,
    data: {},
};


function poststeder(state = initialState, action) {

    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: {
                    ...state.data,
                    [action.postnummer]: action.poststed,
                }
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME];
}

export function getPoststeder(state) {
    return getState(state).data;
}

export function getPoststed(state, postnummer) {
    return getState(state).data[postnummer];
}


export function isLoading(state) {
    return getState(state).isLoading;
}

export function isPoststederLoaded(state) {
    return !isObjectEmpty(getPoststeder(state));
}


export default poststeder;