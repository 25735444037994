import React from "react";
import TilknyttedeDokumenter from "./dokumenter-elementer/TilknyttedeDokumenter";
import Dropzone from "../Dropzone";
import {getGSKIsLoading, harGSK as harGSKSelektor, uploadDokumenter} from "../../ducks/dokumenter";
import VelgFraTidligereOpplastetFiler from "./dokumenter-elementer/VelgFraTidligereOpplastetFiler";
import {GSK_DOKUMENTTYPE} from "../../services/dokumenterAPI";
import {useSelector} from "react-redux";
import GSK from "../gsk/GSK";
import Text from "../../primitives/Text";

const GenerellStudiekompetanse = () => {
    const harGsk = useSelector(harGSKSelektor);
    const isLoadingGSK = useSelector(getGSKIsLoading);

    return (
        <div className='dokumentasjon-kategori gsk-kategori'>
            <h4><Text id='generell_studiekompetanse_GSK'/></h4>
            <GSK/>
            {!harGsk && !isLoadingGSK &&  <>
                <TilknyttedeDokumenter type={GSK_DOKUMENTTYPE} withActions/>
                <Dropzone dispatchOnDrop={acceptedFiles => uploadDokumenter(acceptedFiles, true, GSK_DOKUMENTTYPE)}/>
                <VelgFraTidligereOpplastetFiler type={GSK_DOKUMENTTYPE}/>
            </>}
        </div>
    )
};


export default GenerellStudiekompetanse;