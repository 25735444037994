import React from 'react';
import PropTypes from 'prop-types'
import withLandLoaded from "../high-order-components/withLandLoaded";
import {getLandSortedByLabel} from "../ducks/land";
import {useSelector} from "react-redux";
import Select from "../primitives/Select";
import InPlaceError from "./InPlaceError";
import {mdiEarth} from "@mdi/js";

const InputLand = ({value, onChange, title, errorMessageId, conditionForFocus, withIcon, ...restOfProps}) => {
    const land = useSelector(getLandSortedByLabel);
    return (
        <>
            <Select
                label={title || 'land'}
                className='land'
                options={land}
                value={value}
                onChange={onChange}
                clearable={false}
                hasError={Boolean(errorMessageId)}
                autoComplete='country'
                conditionForFocus={conditionForFocus}
                iconPathForLabel={withIcon && mdiEarth}
                {...restOfProps}
            />
            <InPlaceError errorMessageId={errorMessageId}/>
        </>
    )
};

InputLand.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    title: PropTypes.string,
    conditionForFocus: PropTypes.bool,
    errorMessageId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};


export default withLandLoaded(InputLand);