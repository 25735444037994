import {getIsFetchingApiToken} from "./ducks/session";

const delayUntilTokenIsFetched = getState =>
    new Promise(resolve =>
        setTimeout(async () => {
            const isFetchingApiToken = getIsFetchingApiToken(getState());
            if (isFetchingApiToken) {
                await delayUntilTokenIsFetched(getState);
            }
            resolve();
        }, 5)
    )
;

export default delayUntilTokenIsFetched;