import React from "react";
import Text from "../../../primitives/Text";
import {ExternalLink} from "../../../primitives/Link";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import "./korr-advarsel.scss";
import {getAktueltDeltakernr} from "../../../ducks/session";
import {SyncMedKorrForProfil} from "./SyncMedKorrForProfil";
import {SyncMedKorrForNyBruker} from "./SyncMedKorrForNyBruker";


export const KorrAdvarsel = injectIntl(({mobil, intl}) => {


    const deltakernr = useSelector(getAktueltDeltakernr);


    return (
        <div className='korr-advarsel'>
            <div>
                {mobil ?
                    <Text id='vi_har_hentet_din_mobiltelefon_fra_kontakt_og_reservasjonsregisteret'/>
                    :
                    <Text id='vi_har_hentet_din_epost_fra_kontakt_og_reservasjonsregisteret'/>
                }
                {deltakernr ? <SyncMedKorrForProfil/> : <SyncMedKorrForNyBruker/>}
            </div>

            <div>
                <Text id='du_kan_endre_dataene_dine_rett_i_registeret'/>
                <div className='korr-advarsel__korr-lenke'>
                    <ExternalLink href={intl.formatMessage({id: 'korr_lenke'})}>
                        <Text id='gå_til_kontakt_og_reservasjonsregisteret'/>
                    </ExternalLink>
                </div>
            </div>
        </div>
    )
});
