import React from 'react';
import InitialLoadWrapperOld from '../wrappers/InitialLoadWrapperOld';
import {getError, isDataLoaded, isLoading, loadDokumenter} from '../ducks/dokumenter';
import {connect} from 'react-redux';
import Text from "../primitives/Text";


function withDokumenterLoaded(Component) {

    const mapStateToProps = state => {
        return {
            isDataLoaded: isDataLoaded(state),
            isLoading: isLoading(state),
            error: getError(state),
        }
    };

    const mapDispatchToProps = {
        populateData: loadDokumenter
    };

    return connect(mapStateToProps, mapDispatchToProps)(
        class extends React.Component {
            render() {

                const {populateData, isDataLoaded, isLoading, error} = this.props;

                return (
                    <InitialLoadWrapperOld
                        populateData={populateData}
                        isDataLoaded={isDataLoaded}
                        isLoading={isLoading}
                        error={error}
                        loadMessage={<Text id='laster_inn_dokumenter' />}
                    >
                        <Component {...this.props} />
                    </InitialLoadWrapperOld>
                )
            }
        }
    )
}


export default withDokumenterLoaded;