import {createSlice} from "@reduxjs/toolkit";
import {getAPIInfoFraAPI} from "../services/apiInfoAPI";
import {formatVersjonsTimestamp} from "../utils/dateUtil";

export const NAME = 'apiInfo';

export const createAPIInfo = ({buildTimestamp, lastCommit}) => ({
    buildTimestamp,
    lastCommit
});


export const loadAPIInfo = () => async dispatch => {
    try {
        const data = await getAPIInfoFraAPI();
        dispatch(loadAPIInfoSuccess(data));
    } catch (e) {
    }
};

const apiInfoSlice = createSlice({
    name: NAME,
    initialState: createAPIInfo({}),
    reducers: {
        loadAPIInfoSuccess(state, {payload}) {
            const {buildTimestamp, lastCommit} = payload;
            state.buildTimestamp = buildTimestamp;
            state.lastCommit = lastCommit;
        }
    },
});

export const {
    loadAPIInfoSuccess,
} = apiInfoSlice.actions;


export const getState = state => state[NAME];

export const getAPIBuildTimestamp = state => getState(state).buildTimestamp;
export const getAPIBuildTimestampFormatted = state => {
    return formatVersjonsTimestamp(getAPIBuildTimestamp(state))
};
export const getAPILastCommit = state => getState(state).lastCommit;
export const getAPILastCommitShort = state => {
    const lastCommit = getAPILastCommit(state);
    return lastCommit ? lastCommit.slice(0, 7) : '?';
};

export default apiInfoSlice.reducer