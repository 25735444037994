import React from 'react';
import Hjemmeadresse from "./Hjemmeadresse";
import Arbeidsadresse from "./Arbeidsadresse";
import Heading from "../../../primitives/Heading";
import {headingLevelByPage} from "../../../utils/headingUtil";


export default function Adresser({page}) {
    return (
        <div className='adresser flat-card'>
            <div className='content'>
                <Heading id='adresser' headingLevel={headingLevelByPage(page)}/>
                <div className='cards-container'>
                    <div className='venstre'>
                        <Hjemmeadresse/>
                    </div>
                    <div className='hoyre'>
                        <Arbeidsadresse/>
                    </div>
                </div>
            </div>
        </div>
    )
}


