import {useSelector} from "react-redux";
import {getArbeidserfaringFromId, getSoknadstilknytningerErrors} from "../../../ducks/arbeidserfaring";
import Text from "../../../primitives/Text";
import Periode from "../../arbeidserfaring/Periode";
import React from "react";
import WSErrorDescription from "../../WSErrorDescription";

const ErrorsArbeidserfaringTilknytninger = () => {
    const errorsTilknytninger = useSelector(getSoknadstilknytningerErrors);

    return (<div className='errors-tilknytninger'>
        <p>
            <Text id='følgende_arbeidserfaring_ble_ikke_knyttet_til_søknaden'/>
        </p>
        <ul>
            {errorsTilknytninger.map(errorMap =>
                <ErrorTilknytning
                    key={errorMap.id}
                    id={errorMap.id}
                    error={errorMap.error}
                />)}
        </ul>
    </div>)
};


const ErrorTilknytning = ({id, error}) => {
    const arbeidserfaring = useSelector(state => getArbeidserfaringFromId(state, id));
    const {fraDato, tilDato, arbeidsgiver} = arbeidserfaring;

    return (
        <li>
            <span className='errors-tilknytninger__arbeidserfaring element-beskrivelse'>
                <Periode fraDato={fraDato} tilDato={tilDato} monthInDigits/>, <span>{arbeidsgiver}</span>
            </span>
            <WSErrorDescription error={error}/>
        </li>
    )

};

export default ErrorsArbeidserfaringTilknytninger;