import React from 'react'
import PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {
    endreAktivtSteg,
    erAktivtSteg,
    erStegFullfort,
    erStegPabegynt,
    getAktivtStegnr,
    IDLE_STEG_ID
} from '../../../ducks/pamelding'
import ClickableArea from "../../ClickableArea";
import {checkmarkIconPath} from "../../../utils/icons";
import Text from "../../../primitives/Text";
import Icon from "../../../primitives/Icon";
import Heading from "../../../primitives/Heading";

export const StegHeader = (props) => {

    const {
        buttonId,
        contentId,
        sperreNavigasjon,
        id,
        sekvensnr,
        type,
        valgfritt,
        error,
    } = props;

    const aktivt = useSelector(state => erAktivtSteg(state, id));
    const fullfort = useSelector(state => erStegFullfort(state, id));
    const pabegynt = useSelector(state => erStegPabegynt(state, id));
    const aktivtStegnr = useSelector(getAktivtStegnr);
    const disabled = sperreNavigasjon || !pabegynt;

    const dispatch = useDispatch();

    const endreStegOnClick = () => {
        dispatch(endreAktivtSteg(aktivtStegnr === id ? IDLE_STEG_ID : id));
    };

    return (
        <ClickableArea
            onClick={endreStegOnClick}
            className="velg-steg"
            disabled={disabled}
            id={buttonId}
            aria-label={type}
            aria-controls={contentId}
            aria-expanded={aktivt}
        >
            {fullfort ?
                <Icon path={checkmarkIconPath} className='steg-completed-icon' title='steg_fullført'/>
                :
                <div className="stegnummer">
                    <div className="number">
                        {sekvensnr}
                    </div>
                </div>
            }

            <div className="stegnavn">
                <div className="name">
                    <Heading id={`STEG/${type}`} headingLevel={2} className='margin-zero'/>
                </div>
                {valgfritt && (
                    <div className="optional">
                        <Text id='valgfritt'/>
                    </div>
                )}
                {error && (
                    <div>
                        Skjemaet inneholder feil
                    </div>
                )}
            </div>
        </ClickableArea>
    )
};

StegHeader.propTypes = {
    buttonId: PropTypes.string.isRequired,
    contentId: PropTypes.string.isRequired,
    sperreNavigasjon: PropTypes.bool,
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    sekvensnr: PropTypes.number.isRequired,
    valgfritt: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

export default StegHeader;