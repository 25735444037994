import * as routes from "./apiRoutes";
import axios from "axios";
import {createSamtykke} from "../ducks/samtykke";
import {sanitizeHtmlText} from "../utils/common";

export const getSamtykker = (evuapiToken, deltakernr) => {

    const route = routes.SAMTYKKER.replace(':deltakernr', deltakernr);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        }
    };

    return axios.get(route, config)
        .then(response => {
            return transformSamtykkeFromWS(response.data);
        })
        .catch(error => {
            throw error;
        });

};

export const postSamtykke = (evuapiToken, deltakernr, kode, besvarelse) => {

    const route = routes.SAMTYKKER.replace(':deltakernr', deltakernr);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        }
    };

    const payload = {
        akseptansetypekode: kode,
        status_svar: transformSvarToWS(besvarelse),
    };

    return axios.post(route, payload, config)
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });

};


const transformSamtykkeFromWS = akseptanser =>
    akseptanser.map(akseptanse => createSamtykke({
            kode: akseptanse.akseptansetypekode,
            sporsmal: transformTeksterFromWs(akseptanse.skjemasporsmal),
            undertekst: transformTeksterFromWs(akseptanse.skjematekst),
            besvarelse: transformSvarFromWS(akseptanse.status_svar),
        })
    );

const transformSvarFromWS = status_svar => status_svar === null ? undefined : status_svar;

const transformTeksterFromWs = tekster => {
    return tekster.map(({lang, value}) => ({
        lang,
        value: sanitizeHtmlText(value),
    }))
};

const transformSvarToWS = status_svar => status_svar === undefined ? null : status_svar;