import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {knyttFlereDokumenterTilSoknad} from "../../../ducks/soknadsdokumenter";
import Button, {TERTIARY} from "../../../primitives/Button";
import Text from "../../../primitives/Text";
import TidligereOpplastetDokumenter from "./TidligereOpplastetDokumenter";

const TidligereOpplastetBox = ({type, close}) => {

    const [selected, setSelected] = useState([]);
    const dispatch = useDispatch();

    const onVelg = () => {
        dispatch(knyttFlereDokumenterTilSoknad(selected));
        close();
    };

    return (
        <div className='tidligere-opplastet-boks'>
            <h4><Text id='tidligere_opplastet'/></h4>
            <TidligereOpplastetDokumenter
                type={type}
                selected={selected}
                setSelected={setSelected}
            />
            <div className='tidligere-opplastet-boks__buttons'>
                <Button onClick={close} emphasis={TERTIARY}>
                    <Text id='avbryt'/>
                </Button>
                <Button onClick={onVelg}>
                    <Text id='velg'/>
                </Button>
            </div>
        </div>
    )
};


export default TidligereOpplastetBox;
