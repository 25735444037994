import {useState, useEffect} from "react";


const useInlineEdit = (submitChanges, isDataValid, errorSubmitting) => {

    const openInEditModeWithErrors = !isDataValid;
    const [isInEditMode, setIsInEditMode] = useState(openInEditModeWithErrors);
    const [attemptedClosed, setAttemptedClosed] = useState(openInEditModeWithErrors);

    useEffect(() => {
        errorSubmitting && openEditing();
    }, [errorSubmitting]);

    const openEditing = () => {
        setAttemptedClosed(false);
        setIsInEditMode(true);
    };

    const closeEditing = () => {
        if(!isDataValid) {
            setAttemptedClosed(true);
            return;
        }
        submitChanges();
        setIsInEditMode(false);
    };


    return [
        isInEditMode,
        openEditing,
        closeEditing,
        attemptedClosed
    ]

};

export default useInlineEdit;