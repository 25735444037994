import React from 'react';
import Input from "../../primitives/Input";
import Valgfritt from "./Valgfritt";
import Error from "../Error";
import {injectIntl} from 'react-intl';
import PropTypes from "prop-types";
import {MAX_LENGDE_FRITEKST_SPORSMAL} from "../../utils/common";
import Lagringspinner from "../Lagringspinner";
import ErrorFormSaving from "../ErrorFormSaving";
import Text from "../../primitives/Text";

export class SvarFreeText extends React.Component {

    handleChange = e => {
        const {setResponse} = this.props;
        setResponse(e.target.value);
    };

    render() {
        const {response, mandatory, showError, hasError, errorText, sendSvar, wsError, isLoading, children: sporsmalstekst, sporsmalLang} = this.props;
        const {formatMessage} = this.props.intl;

        return (
            <div className={`sporreskjema-sporsmal__svar__freetext ${isLoading && 'loading'}`} lang={sporsmalLang}>
                <Input
                    placeholder={formatMessage({id: 'svaret_ditt'})}
                    onChange={this.handleChange}
                    value={response || ``}
                    label={mandatory ? sporsmalstekst : <span>{sporsmalstekst}<Valgfritt/></span>}
                    labelColon={false}
                    onBlur={sendSvar}
                    translateLabel={false}
                    hasError={(showError && hasError) || wsError}
                    disabled={isLoading}
                />
                {showError && hasError && !wsError &&
                <Error>
                    <Text id={errorText} values={{textResponseMaxLength: MAX_LENGDE_FRITEKST_SPORSMAL}}/>
                    <span> {sporsmalstekst}</span>
                </Error>
                }
                {wsError &&
                <ErrorFormSaving
                    error={wsError}
                    provIgjen={sendSvar}
                />
                }
                <Lagringspinner />
            </div>
        )
    }
}

SvarFreeText.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    response: PropTypes.string,
    mandatory: PropTypes.bool.isRequired,
    showError: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    errorText: PropTypes.string,
    children: PropTypes.string.isRequired,
    sendSvar: PropTypes.func.isRequired,
    wsError: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
};

export default injectIntl(SvarFreeText);