import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import './main-info.scss';
import {formatDate} from "../ducks/i18n";
import Text from "../primitives/Text";
import Icon from "../primitives/Icon";
import {mdiTimerOutline, mdiCalendarRange, mdiTagTextOutline} from '@mdi/js';


const MainInfo = ({pris, pameldingsfrist, startdato, direktePamelding, formatDate}) => {

    return (
        <div className='main-info'>

            <MainInfoElement
                title={<Text id='pris'/>}
                value={pris && (pris + ' kr')}
                iconPath={mdiTagTextOutline}
            />

            <MainInfoElement
                title={direktePamelding ? <Text id='påmeldingsfrist'/> : <Text id='søknadsfrist'/>}
                value={formatDate(pameldingsfrist)}
                iconPath={mdiTimerOutline}
            />

            <MainInfoElement
                title={<Text id='starter'/>}
                value={startdato}
                iconPath={mdiCalendarRange}
            />

        </div>
    );
};


const MainInfoElement = ({title, value, iconPath }) => {
    return (
        <div className='info-element'>
            <div className='info-element__title'><Icon path={iconPath}/>{title}</div>
            <div className='info-element__value'>{value ? value : '-'}</div>
        </div>
    )
};


MainInfo.propTypes = {
    pris: PropTypes.number,
    pameldingsfrist: PropTypes.object,
    startdato: PropTypes.string,
    direktePamelding: PropTypes.bool,
    formatDate: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    return {
        formatDate: date => formatDate(state, date),
    }
};

export default connect(mapStateToProps)(MainInfo);
