import React from 'react';
import InitialLoadWrapperOld from '../wrappers/InitialLoadWrapperOld';
import {isDataLoaded, isLoading, getError, setupBekreftelse} from '../ducks/planinfo';
import {connect} from 'react-redux';


function withPlaninfoLoaded(Component) {

    const mapStateToProps = state => {
        return {
            isDataLoaded: isDataLoaded(state),
            isLoading: isLoading(state),
            error: getError(state),
        }
    };

    const mapDispatchToProps = {
        populateData: setupBekreftelse
    };

    return connect(mapStateToProps, mapDispatchToProps)(
        class extends React.Component {
            render() {
                const {populateData, isDataLoaded, isLoading, error} = this.props;
                return (
                    <InitialLoadWrapperOld
                        populateData={populateData}
                        isDataLoaded={isDataLoaded}
                        isLoading={isLoading}
                        error={error}
                        loadMessage={`Laster inn bekreftelser`}
                    >
                        <Component {...this.props} />
                    </InitialLoadWrapperOld>)
            }
        }
    )
}


export default withPlaninfoLoaded;