import React from 'react';
import withAktiveKursLoaded from "../../high-order-components/withAktiveKursLoaded";
import {useSelector} from "react-redux";
import Feedback from "../Feedback";
import Text from "../../primitives/Text";
import {getAktiveKursSortert} from "../../ducks/aktiveKurs";
import "./aktiveKurs.scss";
import Datoer from "./Datoer";


const AktiveKurs = () => {
    const kursliste = useSelector(getAktiveKursSortert);

    if (kursliste.length === 0) {
        return (
            <Feedback>
                <Text id='du_har_ingen_aktive_kurs'/>
            </Feedback>

        )
    }

    return (
        <div className='aktive-kurs'>
            <ol>
                {kursliste.map((kurs, index) =>
                    <li className='aktive-kurs__row' key={index}>
                        <div className='kurs'>{kurs.kursnavn}</div>
                        <Datoer datoFra={kurs.datoFra} datoTil={kurs.datoTil}/>
                        {kurs.info && <div className='info'>{kurs.info}</div>}
                    </li>
                )}
            </ol>
        </div>
    )
};


export default withAktiveKursLoaded(AktiveKurs);