import React from 'react';
import {injectIntl } from 'react-intl';
import PropTypes from "prop-types";
import Text from "../../primitives/Text";

export class Valgfritt extends React.Component {
    render() {
        const {formatMessage} = this.props.intl;
        return (
            <div className={`valgfritt`}
                 title={formatMessage({id: 'dette_spørsmålet_er_valgfritt_og_må_ikke_svares_på'})}
            >
                <Text id="valgfritt_spørsmål"/>
            </div>
        )
    }
}

Valgfritt.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }).isRequired
};

export default injectIntl(Valgfritt);