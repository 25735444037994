import * as profilApi from '../services/profilApi';
import * as telefonerAPI from '../services/telefonerAPI';
import * as korrAPI from '../services/korrAPI';
import {
    brukerNavnFromProfilData,
    deepFindChanges,
    hasTrueProperty,
    isEmailValid,
    isObjectEmpty,
    getTelefonError,
    NORGE_KODE, TYPE_ADRESSE_HJEM,
} from "../utils/common";


import {
    getAktueltDeltakernr,
    getAktueltPersonlopenr,
    getEvuapiToken,
    loginSetBrukerNavn
} from "./session";
import {NORGE_LANDNUMMER} from "./land";
import {getSoknader, getSoknaderMedBekreftetEksamen} from "./soknader";
import {getErrorsAdresse, isAdresseValid} from "../utils/adresseUtil";
import {getPerson} from "../services/personAPI";
import {formatTelefonnummerMedLandskode, TYPE_TELEFON_MOBIL} from "../utils/telefonUtil";


export const NAME = 'profil';

export const EPOST_FELT = 'epostPrivat';
export const EPOST_ARBEID_FELT = 'epostArbeid';


export const LOAD_PROFIL_PENDING = `${NAME}/LOAD_PROFIL_PENDING`;
export const LOAD_PROFIL_SUCCESS = `${NAME}/LOAD_PROFIL_SUCCESS`;
export const LOAD_PROFIL_FAILURE = `${NAME}/LOAD_PROFIL_FAILURE`;

export const LAGRE_PROFIL_PENDING = `${NAME}/LAGRE_PROFIL_PENDING`;
export const LAGRE_PROFIL_SUCCESS = `${NAME}/LAGRE_PROFIL_SUCCESS`;
export const LAGRE_PROFIL_FAILURE = `${NAME}/LAGRE_PROFIL_FAILURE`;
export const RESET_ERROR_LAGRING_FOR_FELT = `${NAME}/RESET_ERROR_LAGRING_FOR_FELT`;

export const DELETE_TELEFON_SUCCESS = `${NAME}/DELETE_TELEFON_SUCCESS`;

export const PROFIL_CHANGE_FORMDATA = `${NAME}/PROFIL_CHANGE_FORMDATA`;
export const PROFIL_CHANGE_FORMDATA_OBJECT = `${NAME}/PROFIL_CHANGE_FORMDATA_OBJECT`;
export const CHANGE_TELEFONKODE = `${NAME}/CHANGE_TELEFONKODE`;
export const PROFIL_RESET_FORMDATA = `${NAME}/PROFIL_RESET_FORMDATA`;
export const RESET_TELEFON = `${NAME}/RESET_TELEFON`;

export const SYNCKORR_PENDING = `${NAME}/SYNCKORR_PENDING`;
export const SYNCKORR_SUCCESS = `${NAME}/SYNCKORR_SUCCESS`;
export const SYNCKORR_FAILURE = `${NAME}/SYNCKORR_FAILURE`;

export function loadProfilPending() {
    return {
        type: LOAD_PROFIL_PENDING,
    };
}

export function loadProfilSuccess(data) {
    const {epostFraKorr, mobilFraKorr, adresseSperret, statsborgerskapSperret, navnFraDsf, ...profilData} = data;
    return {
        type: LOAD_PROFIL_SUCCESS,
        data: profilData,
        epostFraKorr,
        mobilFraKorr,
        adresseSperret,
        statsborgerskapSperret,
        navnFraDsf,
    };
}

export function loadProdilFailure(error) {
    return {
        type: LOAD_PROFIL_FAILURE,
        error: error
    };
}

export function lagreProfilPending() {
    return {
        type: LAGRE_PROFIL_PENDING,
    };
}

export function lagreProfilSuccess() {
    return {
        type: LAGRE_PROFIL_SUCCESS,
    };
}

export function lagreProfilFailure(error, felt) {
    return {
        type: LAGRE_PROFIL_FAILURE,
        error,
        felt
    };
}

export function resetErrorLagringForFelt(felt) {
    return {
        type: RESET_ERROR_LAGRING_FOR_FELT,
        felt
    };
}

export function deleteTelefonSuccess(typeTelefon) {
    return {
        type: DELETE_TELEFON_SUCCESS,
        typeTelefon: typeTelefon
    };
}

export function resetTelefon(typeTelefon) {
    return {
        type: RESET_TELEFON,
        typeTelefon: typeTelefon
    };
}

export function lagreProfilFelt(felt) {
    return function (dispatch, getState) {

        const state = getState();
        const errors = getFormErrors(state);

        if (errors[felt]) {
            return;
        }

        const formData = getFormData(state);
        const originalData = getOriginalData(state);

        if (formData[felt] === originalData[felt]) {
            return;
        }

        dispatch(lagreProfilPending());

        const deltakernr = getAktueltDeltakernr(state);
        const evuapiToken = getEvuapiToken(getState());

        profilApi.lagreProfil(deltakernr, formData, evuapiToken).then(
            () => dispatch(lagreProfilSuccess()),
            error => dispatch(lagreProfilFailure(error, felt))
        );

    };
}

export function lagreAdresse(type) {
    return function (dispatch, getState) {

        const state = getState();
        if (hasErrorAdresse(state, type)) {
            return;
        }

        const formDataAdresse = getFormDataAdresse(state, type);
        const originalDataAdresse = getOriginalDataAdresse(state, type);

        const changes = deepFindChanges(formDataAdresse, originalDataAdresse);
        if (!changes) {
            return;
        }

        dispatch(lagreProfilPending());

        const deltakernr = getAktueltDeltakernr(state);
        const evuapiToken = getEvuapiToken(getState());

        profilApi.lagreAdresse(formDataAdresse, type, deltakernr, evuapiToken).then(
            () => dispatch(lagreProfilSuccess()),
            error => dispatch(lagreProfilFailure(error, type))
        );

    };
}

export function lagreTelefon(type) {
    return function (dispatch, getState) {

        const state = getState();
        if (hasErrorTelefon(state, type)) {
            return;
        }

        const formDataTelefon = getFormDataTelefon(state, type);
        const originalDataTelefon = getOriginalDataTelefon(state, type);

        const hasChangesInLand = formDataTelefon.landskode !== originalDataTelefon.landskode
            || formDataTelefon.landnummer !== originalDataTelefon.landnummer;

        const hasChangesToSave = formDataTelefon.nummer !== originalDataTelefon.nummer
            || (formDataTelefon.nummer && hasChangesInLand);

        if (!hasChangesToSave) {
            return;
        }

        dispatch(lagreProfilPending());

        const deltakernr = getAktueltDeltakernr(state);
        const evuapiToken = getEvuapiToken(state);

        const telefonFinnesFraFor = originalDataTelefon.nummer || originalDataTelefon.landskode;
        if (!telefonFinnesFraFor) {
            telefonerAPI.createTelefon(formDataTelefon, type, deltakernr, evuapiToken).then(
                () => dispatch(lagreProfilSuccess()),
                error => dispatch(lagreProfilFailure(error, type))
            );
            return;
        }

        const nummerBleSlettet = originalDataTelefon.nummer && !formDataTelefon.nummer;
        if (nummerBleSlettet) {
            telefonerAPI.deleteTelefon(type, deltakernr, evuapiToken).then(
                () => dispatch(deleteTelefonSuccess(type)),
                error => dispatch(lagreProfilFailure(error, type))
            );
            return;
        }

        telefonerAPI.updateTelefon(formDataTelefon, type, deltakernr, evuapiToken).then(
            () => dispatch(lagreProfilSuccess()),
            error => dispatch(lagreProfilFailure(error, type))
        );

    };
}


export function loadProfil() {
    return function (dispatch, getState) {
        const state = getState();
        if (isProfilLoading(state)) {
            return;
        }
        dispatch(loadProfilPending());

        const deltakernr = getAktueltDeltakernr(state);
        const personlopenr = getAktueltPersonlopenr(state);
        const evuapiToken = getEvuapiToken(state);

        if (!deltakernr && personlopenr) {
            return getPerson(evuapiToken, personlopenr).then(result => {
                dispatch(loginSetBrukerNavn(result.navn));
            });
        }

        return profilApi.getProfilData(deltakernr, evuapiToken).then(
            result => {
                dispatch(loadProfilSuccess(result));
                dispatch(loginSetBrukerNavn(brukerNavnFromProfilData(result)));
            },
            error => dispatch(loadProdilFailure(error))
        );
    };
}

export function synckorrPending() {
    return {
        type: SYNCKORR_PENDING,
    };
}

export function synckorrSuccess() {
    return {
        type: SYNCKORR_SUCCESS,
    };
}


export function synckorrError(error) {
    return {
        type: SYNCKORR_FAILURE,
        error,
    };
}

export const syncMedKorr = (doLoadProfil = true) => async (dispatch, getState) => {
    if (isSyncingMedKorr(getState())) {
        return;
    }
    dispatch(synckorrPending());
    const deltakernr = getAktueltDeltakernr(getState());
    const evuapiToken = getEvuapiToken(getState());
    try {
        await korrAPI.syncKorr(deltakernr, evuapiToken);
        await dispatch(synckorrSuccess());
        doLoadProfil && await dispatch(loadProfil());
    } catch (error) {
        dispatch(synckorrError(error))
    }
};


export function changeFormField(key, value) {
    return {
        type: PROFIL_CHANGE_FORMDATA,
        key: key,
        value: value
    }
}

export const changeFormFieldObject = (objectKey, value, formDataKey) => {
    return {
        type: PROFIL_CHANGE_FORMDATA_OBJECT,
        formDataKey: formDataKey,
        objectKey: objectKey,
        value: value
    }
};

export function changeTelefonkode(typeTelefon, landskode, landnummer) {
    return {
        type: CHANGE_TELEFONKODE,
        typeTelefon,
        landskode,
        landnummer,
    }
}

export function resetFormdata() {
    return {
        type: PROFIL_RESET_FORMDATA,
    }
}

const initialStateAdresse = {
    linje1: '',
    linje2: '',
    land: undefined,
    postadresse: '',
    postnummer: '',
};

const initialStateTelefon = {
    landskode: '',
    landnummer: '',
    nummer: '',
};

export const initialStateProfil = {
    isLoading: false,
    error: undefined,
    isLagring: false,
    errorLagring: undefined,
    epostFraKorr: false,
    mobilFraKorr: false,
    isSyncingMedKorr: false,
    isSyncSuccess: false,
    errorSyncMedKorr: undefined,
    data: {
        originalData: {},
        formData: {
            fornavn: '',
            etternavn: '',
            fodselsdato: '',
            kjonn: '',
            statsborgerskap: '',
            malform: '',
            hjemstedsadresse: {...initialStateAdresse},
            arbeidsadresse: {...initialStateAdresse},
            epostPrivat: '',
            epostArbeid: '',
            mobiltelefon: {...initialStateTelefon},
            arbeidstelefon: {...initialStateTelefon},
        },
        kanEndreFodselsdato: false,
        kanEndreStatsborgerskap: false,
    }
};

function profil(state = initialStateProfil, action) {
    switch (action.type) {
        case LOAD_PROFIL_PENDING:
        case LOAD_PROFIL_SUCCESS:
        case LOAD_PROFIL_FAILURE:
            return load(state, action);

        case LAGRE_PROFIL_PENDING:
        case LAGRE_PROFIL_SUCCESS:
        case LAGRE_PROFIL_FAILURE:
        case DELETE_TELEFON_SUCCESS:
        case RESET_ERROR_LAGRING_FOR_FELT:
            return lagre(state, action);
        case PROFIL_CHANGE_FORMDATA:
        case PROFIL_CHANGE_FORMDATA_OBJECT:
        case PROFIL_RESET_FORMDATA:
        case RESET_TELEFON:
        case CHANGE_TELEFONKODE:
            return endreFormdata(state, action);
        case SYNCKORR_PENDING:
        case SYNCKORR_SUCCESS:
        case SYNCKORR_FAILURE:
            return korr(state, action);
        default:
            return state;
    }
}

function load(state = initialStateProfil, action) {
    switch (action.type) {
        case LOAD_PROFIL_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_PROFIL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                epostFraKorr: action.epostFraKorr,
                mobilFraKorr: action.mobilFraKorr,
                adresseSperret: action.adresseSperret,
                statsborgerskapSperret: action.statsborgerskapSperret,
                navnFraDsf: action.navnFraDsf,
                data: {
                    originalData: action.data,
                    formData: action.data,
                    kanEndreFodselsdato: !action.data.fodselsdato,
                    kanEndreStatsborgerskap: action.data.statsborgerskap === undefined,
                }
            };
        case LOAD_PROFIL_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state;
    }
}

function lagre(state = initialStateProfil, action) {
    switch (action.type) {
        case LAGRE_PROFIL_PENDING:
            return {
                ...state,
                isLagring: true,
                errorLagring: errorLagring(state.errorLagring, action)
            };
        case LAGRE_PROFIL_SUCCESS:
            return {
                ...state,
                isLagring: false,
                errorLagring: errorLagring(state.errorLagring, action),
                data: {
                    ...state.data,
                    originalData: state.data.formData,
                },

            };
        case DELETE_TELEFON_SUCCESS:
            return {
                ...state,
                isLagring: false,
                errorLagring: errorLagring(state.errorLagring, action),
                data: {
                    ...state.data,
                    originalData: {
                        ...state.data.originalData,
                        [action.typeTelefon]: {}
                    },
                    formData: {
                        ...state.data.formData,
                        [action.typeTelefon]: {}
                    },
                },

            };
        case LAGRE_PROFIL_FAILURE:
            return {
                ...state,
                isLagring: false,
                errorLagring: errorLagring(state.errorLagring, action),
            };
        case RESET_ERROR_LAGRING_FOR_FELT:
            return {
                ...state,
                errorLagring: errorLagring(state.errorLagring, action),
            };
        default:
            return state;
    }
}

function errorLagring(state = initialStateProfil.errorLagring, action) {
    switch (action.type) {
        case LAGRE_PROFIL_PENDING:
        case LAGRE_PROFIL_SUCCESS:
        case DELETE_TELEFON_SUCCESS:
            return undefined;
        case LAGRE_PROFIL_FAILURE:
            return {
                ...state,
                [action.felt]: action.error
            };
        case RESET_ERROR_LAGRING_FOR_FELT:
            return {
                ...state,
                [action.felt]: undefined
            };
        default:
            return state;
    }
}

function endreFormdata(state = initialStateProfil, action) {
    switch (action.type) {
        case PROFIL_CHANGE_FORMDATA:
        case PROFIL_CHANGE_FORMDATA_OBJECT:
        case RESET_TELEFON:
        case CHANGE_TELEFONKODE:
            return {
                ...state,
                data: {
                    ...state.data,
                    formData: formData(state.data.formData, action),
                }
            };
        case PROFIL_RESET_FORMDATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    formData: state.data.originalData,
                },
                errorLagring: undefined
            };
        default:
            return state;
    }
}

function formData(state = initialStateProfil.data.formData, action) {
    switch (action.type) {

        case PROFIL_CHANGE_FORMDATA:

            return {
                ...state,
                [action.key]: action.value,
            };

        case PROFIL_CHANGE_FORMDATA_OBJECT:

            const object = state[action.formDataKey];

            if (object === undefined) {
                return state;
            }

            return {
                ...state,
                [action.formDataKey]: {
                    ...object,
                    [action.objectKey]: action.value
                }
            };
        case CHANGE_TELEFONKODE:

            const telefon = state[action.typeTelefon];

            if (telefon === undefined) {
                return state;
            }

            return {
                ...state,
                [action.typeTelefon]: {
                    ...telefon,
                    landskode: action.landskode,
                    landnummer: action.landnummer,

                }
            };
        case RESET_TELEFON:
            return {
                ...state,
                [action.typeTelefon]: {}
            };
        default:
            return state;
    }

}

function korr(state = initialStateProfil, action) {

    switch (action.type) {
        case SYNCKORR_PENDING:
            return {
                ...state,
                isSyncingMedKorr: true,
                isSyncSuccess: false,
                errorSyncMedKorr: undefined
            };
        case SYNCKORR_SUCCESS:
            return {
                ...state,
                isSyncingMedKorr: false,
                isSyncSuccess: true,
                errorSyncMedKorr: undefined,
            };
        case SYNCKORR_FAILURE:
            return {
                ...state,
                isSyncingMedKorr: false,
                isSyncSuccess: false,
                errorSyncMedKorr: action.error
            };
        default:
            return state;
    }
}

export function isLagring(state) {
    return getState(state).isLagring;
}

export function getState(state) {
    return state[NAME];
}

export function isProfilLoading(state) {
    return getState(state).isLoading;
}


export function getOriginalData(state) {
    return getData(state).originalData;
}

export function getFormData(state) {
    return getData(state).formData;
}

function getData(state) {
    return getState(state).data;
}


export function getKanEndreStatsborgerskap(state) {
    return getData(state).kanEndreStatsborgerskap;
}

export function getKanEndreFodselsdato(state) {
    return getData(state).kanEndreFodselsdato;
}


export function isProfilFormValid(state) {
    const errors = getFormErrors(state);
    return !hasTrueProperty(errors);
}


export function getFormErrors(state) {
    return {
        hjemstedsadresse: getFormErrorsAdresse(state, 'hjemstedsadresse'),
        arbeidsadresse: getFormErrorsAdresse(state, 'arbeidsadresse'),
        epostPrivat: getFormErrorsEpost(state),
        epostArbeid: !isEmailValid(getFormDataEpostArbeid(state), false),
        mobiltelefon: getFormErrorsTelefon(state, 'mobiltelefon'),
        arbeidstelefon: getFormErrorsTelefon(state, 'arbeidstelefon'),
    }
}

export const getFormErrorsAdresse = (state, type) => {
    const adresse = getFormDataAdresse(state, type);
    return getErrorsAdresse(state, adresse, adresseErObligatorisk(state, type));
};


export function hasErrorAdresse(state, typeAdresse) {
    return !isAdresseValid(getFormErrorsAdresse(state, typeAdresse));
}


export function adresseErObligatorisk(state, type) {
    return type === TYPE_ADRESSE_HJEM && hjemmeadresseErObligatorisk(state);
}

export function hjemmeadresseErObligatorisk(state) {
    const harFSFiktivtFodselsnummer = getHarFSFiktivtFodselsnummer(state);
    const soknaderMedBekreftetEksamen = getSoknaderMedBekreftetEksamen(state);
    return harFSFiktivtFodselsnummer && soknaderMedBekreftetEksamen.length > 0;
}

export const getFormDataAdresse = (state, type) => {
    return getFormData(state)[type];
};


export function getOriginalDataAdresse(state, typeAdresse) {
    return getOriginalData(state)[typeAdresse];
}

export function getFormDataEpost(state) {
    return getFormData(state).epostPrivat;
}

export function getFormDataEpostArbeid(state) {
    return getFormData(state).epostArbeid;
}

export function isAdressePaNorskFormat(state, typeAdresse) {
    return (getFormDataAdresse(state, typeAdresse).land === NORGE_LANDNUMMER);
}

export function isDataLoaded(state) {
    return !isObjectEmpty(getOriginalData(state));
}

export function getError(state) {
    return getState(state).error;
}

export function getErrorLagring(state, felt) {
    return getState(state).errorLagring && getState(state).errorLagring[felt];
}


export function getFormDataTelefon(state, typeTelefon) {
    return getFormData(state)[typeTelefon];
}


export function getOriginalDataTelefon(state, typeTelefon) {
    return getOriginalData(state)[typeTelefon];
}


export function harOriginalDataTelefon(state, typeTelefon) {
    const telefon = getOriginalDataTelefon(state, typeTelefon);
    return Boolean(telefon && telefon.nummer);
}


export function getTelefonPresentasjon(state, typeTelefon) {
    const telefon = getOriginalDataTelefon(state, typeTelefon);
    const {landskode = NORGE_KODE, nummer = ''} = telefon || {};

    return formatTelefonnummerMedLandskode(landskode, nummer);
}


export function hasErrorTelefon(state, type) {
    const error = getFormErrorsTelefon(state, type);
    return Boolean(error.landskode || error.nummer);
}


export function getFormErrorsTelefon(state, type) {
    const telefon = getFormDataTelefon(state, type);

    return {
        landskode: undefined,
        nummer: getTelefonError(telefon.landskode, telefon.nummer, telefonErObligatorisk(state, type)),
    }
}

export function getFormErrorsEpost(state) {
    return !isEmailValid(getFormData(state).epostPrivat, mobilOgEpostErObligatorisk(state))
}

export function telefonErObligatorisk(state, type) {
    return type === TYPE_TELEFON_MOBIL && mobilOgEpostErObligatorisk(state);
}


export function telefonMangler(state, type) {
    const mobil = getFormDataTelefon(state, type);
    return !mobil.nummer;
}

export function mobilOgEpostErObligatorisk(state) {
    const soknader = getSoknader(state);
    return soknader.length > 0;
}

export function epostMangler(state) {
    const epost = getFormDataEpost(state);
    return !epost;
}

export function mobilEllerEpostMangler(state) {
    return telefonMangler(state, TYPE_TELEFON_MOBIL) || epostMangler(state);
}

export const isMobilFraKorr = state => getState(state).mobilFraKorr;
export const isEpostFraKorr = state => getState(state).epostFraKorr;

export const getIsAdresseSperret = state => getState(state).adresseSperret;
export const getIsStatsborgerskapSperret = state => getState(state).statsborgerskapSperret;
export const getIsNavnFraDsf = state => getState(state).navnFraDsf;

export const isSyncingMedKorr = state => getState(state).isSyncingMedKorr;
export const isSyncSuccess = state => getState(state).isSyncSuccess;
export const getErrorSyncMedKorr = state => getState(state).errorSyncMedKorr;

export const getFormDataMalform = state => getFormData(state).malform;
export const getFormDataStatsborgerskap = state => getFormData(state).statsborgerskap;
export const getFormDataFodselsdato = state => getFormData(state).fodselsdato;
export const getFormDataKjonn = state => getFormData(state).kjonn;
export const getFulltNavn = state => {
    const formData = getFormData(state);
    return (`${formData.fornavn} ${formData.etternavn}`);
};

export const getHarFSFiktivtFodselsnummer = state => getOriginalData(state).harFSFiktivtFodselsnummer;

export default profil;