import React, {useEffect} from 'react';
import {getIsLoading, getOrganisasjonsnavn, loadOrganisasjon} from "../../ducks/organisasjoner";
import Laster from "../Laster";
import {useDispatch, useSelector} from "react-redux";
import {isOrganisasjonsnummerValid} from "../../utils/organisasjonUtil";


export const Organisasjonsnavn = ({organisasjonsnummer}) => {

    const isLoading = useSelector(getIsLoading);
    const organisasjonsnavn = useSelector(state => getOrganisasjonsnavn(state, organisasjonsnummer));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!organisasjonsnavn && isOrganisasjonsnummerValid(organisasjonsnummer)) {
            dispatch(loadOrganisasjon(organisasjonsnummer));
        }
    }, [organisasjonsnummer, dispatch, organisasjonsnavn]);

    return (
        isLoading ?
            <Laster className='in-line-spinner organisasjonsnavn'/>
            :
            organisasjonsnavn ?
                <div className='organisasjonsnavn'>{organisasjonsnavn}</div>
                :
                null
    )

};