import {useDispatch} from "react-redux";
import Button from "../../../primitives/Button";
import React from "react";
import {showPopup} from "../../../ducks/popup";
import InfoFilopplasting from "./InfoFilopplasting";
import Text from "../../../primitives/Text";
import {mdiHelpCircleOutline} from "@mdi/js";

const MerInfoButton = () => {
    const dispatch = useDispatch();
    return (
        <Button onClick={() => dispatch(showPopup(getPopupContent()))}
                iconPath={mdiHelpCircleOutline}
                altText='trykk_for_mer_info'
                className='file-types-info-button'
        />
    )
};

export const getPopupContent = () => {
    return {
        title: <Text id='tillate_filtyper'/>,
        children: <InfoFilopplasting/>
    };
};

export default MerInfoButton;