import React from 'react';
import InitialLoadWrapperOld from '../wrappers/InitialLoadWrapperOld';
import {loadProfil, isDataLoaded, isProfilLoading, getError} from '../ducks/profil';
import {connect} from 'react-redux';
import Text from "../primitives/Text";


function withProfildataLoaded(Component) {

    const mapStateToProps = state => {
        return {
            isDataLoaded: isDataLoaded(state),
            isLoading: isProfilLoading(state),
            error: getError(state),
        }
    };

    const mapDispatchToProps = {
        populateData: loadProfil
    };

    return connect(mapStateToProps, mapDispatchToProps)(
        class extends React.Component {
            render() {

                const {populateData, isDataLoaded, isLoading, error} = this.props;

                return (
                    <InitialLoadWrapperOld
                        populateData={populateData}
                        isDataLoaded={isDataLoaded}
                        isLoading={isLoading}
                        error={error}
                        loadMessage={<Text id='laster_inn_profil' />}
                    >
                        <Component {...this.props} />
                    </InitialLoadWrapperOld>
                )
            }
        }
    )
}


export default withProfildataLoaded;