import {getInstitusjonsnr} from "./session";
import {getAppegenskaper as fetchAppegenskaper} from "../services/appegenskaperAPI";

export const NAME = 'appegenskaper';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;

export const APPEGENSKAPKODE_KURSKATALOG = 'FORSIDE';
export const APPEGENSKAPKODE_FAKTURAADRESSE = 'FAKTURAADR';
export const APPEGENSKAPKODE_OK_FILER = 'OK_FILER';
export const APPEGENSKAPKODE_IDPORTEN = 'LOGONIDPOR';
export const APPEGENSKAPKODE_FEIDE = 'LOGONFEIDE';
export const APPEGENSKAPKODE_EIDAS = 'LOGONEIDAS';
export const APPEGENSKAPKODE_FACEBOOK = 'LOGONFACEB';
export const APPEGENSKAPKODE_TWITTER = 'LOGONTWITT';
export const APPEGENSKAPKODE_LINKEDIN = 'LOGONLI';

export const DEFAULT_ALLOWED_FILETYPES = "doc, docx, gif, jpg, jpeg, png, pdf, txt";


export function loadPending() {
    return {
        type: LOAD_PENDING,
    };
}

export function loadSuccess(appegenskaper) {
    return {
        type: LOAD_SUCCESS,
        appegenskaper,
    };
}

export function loadFailure(error) {
    return {
        type: LOAD_FAILURE,
        error,
    };
}

export function loadAppegenskaper() {
    return function (dispatch, getState) {
        const instnr = getInstitusjonsnr(getState());
        const isPending = isLoading(getState());
        if (!instnr || isPending) {
            return;
        }
        dispatch(loadPending());
        return fetchAppegenskaper(instnr).then(
            appegenskaper => dispatch(loadSuccess(appegenskaper)),
            error => dispatch(loadFailure(error))
        );
    };
}

export const createAppegenskap = props => {
    const {
        kode = '',
        statusAktiv = false,
        value,
    } = props;
    return {
        kode,
        statusAktiv,
        value
    }
};

export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    appegenskaper: [],
};

function appegenskaper(state = initialState, action) {

    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                isDataLoaded: false,
                error: undefined,
                appegenskaper: [],
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                appegenskaper: action.appegenskaper,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error
            };
        default:
            return state
    }
}

function getState(state) {
    return state[NAME];
}

export function getError(state) {
    return getState(state).error;
}

export function isLoading(state) {
    return getState(state).isLoading;
}

export function isDataLoaded(state) {
    return getState(state).isDataLoaded;
}

export function getAppegenskaper(state) {
    return getState(state).appegenskaper;
}

export function getAppegenskapByCode(state, kode) {
    return getAppegenskaper(state).find(appegenskap => appegenskap.kode === kode);
}
export function getAppegenskapsValue(state, kode) {
    const appegenskap = getAppegenskapByCode(state, kode);
    return appegenskap && appegenskap.value;
}

export function isAppegenskapAktivert(state, kode) {
    const appegenskap = getAppegenskapByCode(state, kode);
    return appegenskap && appegenskap.statusAktiv;
}

export function getIsLoginTillat(state) {
    const loginAppegenskapskoder = [
        APPEGENSKAPKODE_IDPORTEN, APPEGENSKAPKODE_FEIDE, APPEGENSKAPKODE_EIDAS,
        APPEGENSKAPKODE_FACEBOOK, APPEGENSKAPKODE_TWITTER, APPEGENSKAPKODE_LINKEDIN
    ];
    return loginAppegenskapskoder.some(kode => isAppegenskapAktivert(state, kode));
}

export const isKurskatalogAktivert = state => isAppegenskapAktivert(state, APPEGENSKAPKODE_KURSKATALOG);

export function getAllowedFiletypes(state) {
    return getAppegenskapsValue(state, APPEGENSKAPKODE_OK_FILER);
}

export function getAllowedFiletypesFormatted(state) {
    const filetypes = getAllowedFiletypes(state);
    return filetypes ? filetypes.replace(/\|/g,', ') : DEFAULT_ALLOWED_FILETYPES;
}

export default appegenskaper;
