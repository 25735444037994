import {createForetak, createForetakKurs, createForetakSoknad} from "../ducks/foretaksgodkjenning";
import * as routes from "./apiRoutes";
import axios from "axios";
import {kursIdStringToPathVariable, nullToUndefined, parseIsoDate} from "../utils/common";
import {createRequestConfig} from "../utils/request";
import orderBy from "lodash/orderBy";

export const getForetak = async (evuapiToken, personlopenr) => {

    const route = routes.FORETAK.replace(':personlopenr', personlopenr);

    const config = createRequestConfig(evuapiToken);

    const {data} = await axios.get(route, config);
    return transformDataFromWS(data);

};

const transformDataFromWS = result => {
    const foretak = transformForetaklisteFromWS(result);
    const kursliste = transformKurslisteFromWS(result);
    const soknader = transformSoknaderFromKursliste(kursliste);
    return {
        foretak: foretak.map(foretak => createForetak(foretak)),
        kurs: kursliste.map(kurs => createForetakKurs(kurs)),
        soknader: orderBy(soknader.map(soknad => createForetakSoknad(soknad)), ['isGodkjent','navn'], ['desc','asc']),
    };
};

const transformForetaklisteFromWS = result => result.map(foretak => ({
    navn: foretak.foretaknavn,
    kode: foretak.foretakkode,
}));

const transformKurslisteFromWS = result =>
    result.reduce((acc, curr) => [
            ...acc,
            ...curr.kurs.map(kurs => ({
                ...kurs,
                foretakkode: curr.foretakkode,
                navn: kurs.kursnavn,
                kursId: `${kurs.kurskode},${kurs.tidskode}`,
                harGodkjenning: kurs.godkjenning,
                skjulBesvarteSoknader: kurs.godkjenning,
                godkjenningsfrist: parseIsoDate(kurs.godkjenningsfrist),
                makskapasitet: nullToUndefined(kurs.makskapasitet),
            }))
        ]
        , []);

const transformSoknaderFromKursliste = kursliste =>
    kursliste.reduce((acc, curr) => [
            ...acc,
            ...curr.soknader.map(soknad => ({
                ...soknad,
                kursId: curr.kursId,
                foretakkode: curr.foretakkode,
                isGodkjent: soknad.isGodkjent === null ? undefined : soknad.isGodkjent,
                navn: `${soknad.fornavn} ${soknad.etternavn}`,
            }))
        ]
        , []);

export const postGodkjenninger = async (evuapiToken, personlopenr, foretakkode, kursId, godkjenninger) => {

    const route = routes.FORETAK_GODKJENNING
        .replace(':personlopenr', personlopenr)
        .replace(':foretakkode', foretakkode)
        .replace(':kursid', kursIdStringToPathVariable(kursId));

    const config = createRequestConfig(evuapiToken);

    const payload = transformGodkjenningerForWS(godkjenninger);

    const {data} = await axios.post(route, payload, config);

    return transformGodkjenningerFromWS(data);
};

const transformGodkjenningerForWS = godkjenninger =>
    godkjenninger.map(godkjenning => transformGodkjenningForWS(godkjenning));

const transformGodkjenningForWS = ({deltakernr, isGodkjent}) => ({
    deltakernr,
    godkjent: isGodkjent,
});

const transformGodkjenningerFromWS = godkjenninger =>
    godkjenninger.map(godkjenning => transformGodkjenningFromWS(godkjenning));

const transformGodkjenningFromWS = ({error, soknad}) => {
    const {deltakernr, status, godkjent} = soknad;
    return {
        error,
        deltakernr,
        status,
        isGodkjent: godkjent,
    }
};