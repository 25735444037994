import React from "react";
import Text from "../../primitives/Text";
import {mdiCheck} from "@mdi/js";
import Icon from "../../primitives/Icon";

const GSKGodkjent = () =>
    <div className='gsk-vedtak'>
        <Icon path={mdiCheck} className='bekreftet'/>
        <Text id='registrert_GSK'/>
    </div>
;

export default GSKGodkjent;