import {initialState as initialSession} from "./ducks/session";
import {DATAPORTEN_TOKENS, EVU3_WS_TOKEN, getValueFromLocalStorage, PERSISTED_STATE} from "./utils/sessionUtil";

export function initialReduxState() {
    const dataportenToken = getDataportenTokenFromLocaleStorage();
    const sessionFromLocalStorage = getValueFromLocalStorage(PERSISTED_STATE, initialSession.persisted);
    const evuapiToken = getValueFromLocalStorage(EVU3_WS_TOKEN, undefined);
    return {
        session: {
            ...initialSession,
            dataportenToken: dataportenToken,
            evuapiToken: evuapiToken,
            persisted: sessionFromLocalStorage
        }
    };
}

function getDataportenTokenFromLocaleStorage() {

    const jsoTokens = getValueFromLocalStorage(DATAPORTEN_TOKENS, undefined);

    if (!jsoTokens) {
        return undefined;
    }

    if (jsoTokens.length) {
        return jsoTokens[0];
    }

}
