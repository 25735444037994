import React from 'react';
import InitialLoadWrapper from '../wrappers/InitialLoadWrapper';
import {getError, isDataLoaded, isLoading, loadAktiveKurs} from '../ducks/aktiveKurs';
import Text from "../primitives/Text";

const  withAktiveKursLoaded = Component => props => {
    return (
        <InitialLoadWrapper
            loadAction={loadAktiveKurs}
            loadingSelector={isLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getError}
            loadMessage={<Text id='laster_inn_dine_kurs'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )

};

export default withAktiveKursLoaded;