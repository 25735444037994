import React from "react";
import InputMalform from "../InputMalform";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormField,
    getForsoktOpprett,
    getMalform,
} from "../../ducks/nyBruker";
import Text from "../../primitives/Text";
import Statsborgerskap from "./Statsborgerskap";

const Annet = () => {
    const malform = useSelector(getMalform);
    const forsoktOpprett = useSelector(getForsoktOpprett);
    const dispatch = useDispatch();
    const onChangeMalform = selectedValue => dispatch(changeFormField('malform', selectedValue));
    const errorMessageIdMalform = !malform && forsoktOpprett && 'malform_obligatorisk';

    return (
        <div className='flat-card annet'>
            <h2><Text id='annet'/></h2>
            <div className='content'>
                <Statsborgerskap dispatch={dispatch}
                                 forsoktOpprett={forsoktOpprett}
                />
                <InputMalform value={malform}
                              onChange={onChangeMalform}
                              withIcon
                              errorMessageId={errorMessageIdMalform}
                />
            </div>
        </div>
    )
};


export default Annet