import {useDispatch, useSelector} from "react-redux";
import {
    forsokNeste,
    fullforSteg,
    getForsoktNeste,
} from "../../../ducks/pamelding";
import Button, {PRIMARY} from "../../../primitives/Button";
import React from "react";
import PropTypes from "prop-types";
import Text from "../../../primitives/Text";


const ButtonNesteSteg = ({validator, onNextClick}) => {
    const forsoktNeste = useSelector(getForsoktNeste);
    const dispatch = useDispatch();

    const gaTilNesteSteg = () => {
        if (validator === undefined || validator === true) {
            dispatch(fullforSteg());
        }
    };

    const onClick = () => {
        dispatch(forsokNeste());
        onNextClick && onNextClick();
        gaTilNesteSteg();
    };

    return (
        <Button
            emphasis={PRIMARY}
            onClick={onClick}
            disabled={validator === false && forsoktNeste}
        >
            <Text id='neste'/>
        </Button>
    )
};

ButtonNesteSteg.propTypes = {
    validator: PropTypes.bool.isRequired,
};

export default ButtonNesteSteg;