import {getRequestErrorTextId} from "./request";
import dateFormat from "dateformat";

export const getErrorMessageId = (dato, obligatorisk, apiError, doneEditing = true, obligatoriskFeilmelding = 'obligatorisk_felt') => {

    if (doneEditing && !dato && obligatorisk) {
        return obligatoriskFeilmelding;
    }

    if (!dato) {
        return undefined;
    }

    if (doneEditing && !isTextValidISODate(dato)) {
        return 'feil_datoformat'
    }

    if (apiError) {
        return getRequestErrorTextId(apiError);
    }

    return undefined;
};

//For IE og Safari som ikke forstår <input type='date'/>
export const isTextValidISODate = text => {
    if (!text) return true;


    //datoer type 2021-33-44
    const date = new Date(text);
    if (isNaN(date.getTime())) {
        return false;
    }

    //datoer type 2021-02-31
    return isValidDayOfMonth(text);
};

const isValidDayOfMonth = (text) => {
    const result = /(\d{4})-(\d{2})-(\d{2})/.exec(text);
    if (result === null) {
        return false;
    }
    const parsedDay = parseInt(result[3]);
    const parsedMonth = parseInt(result[2]);

    const date = new Date(text);
    if (date.getUTCDate() !== parsedDay) {
        return false;
    }
    return date.getUTCMonth() + 1 === parsedMonth;
}

export const formatVersjonsTimestamp = timestamp => {
    const date = timestamp && new Date(timestamp);
    return date && dateFormat(date, 'dd.mm.yyyy HH:MM:ss', true);
};