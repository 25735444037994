import React from 'react';
import RedirectIfLoggedInDataporten from "../wrappers/RedirectIfLoggedInDataporten";

function blockedIfLoggedInDataporten(Component) {
    return class extends React.Component {
        render() {
            return (
                <RedirectIfLoggedInDataporten>
                    <Component {... this.props}/>
                </RedirectIfLoggedInDataporten>
            )
        }
    }
}

export default blockedIfLoggedInDataporten