import * as routes from "./apiRoutes";
import axios from "axios/index";
import {parseIsoDate} from "../utils/common";


export const checkGSK = async (deltakernr, evuapiToken) => {

    const route = routes.GSK.replace(':deltakernr', deltakernr);
    const config = {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        }
    };

    const response = await axios.get(route, config);
    return transformOutput(response.data);
};

const transformOutput = data => ({
    harGsk: data.har_gsk,
    vitnemal: data.vitnemal.map(vitnemal => transformVitnemalFromWS(vitnemal)),
});

const transformVitnemalFromWS = ({dato_utstedt, gsk_ok, ...restOfProps}) => ({
    ...restOfProps,
    datoUtstedt: parseIsoDate(dato_utstedt),
    isGskOk: gsk_ok,
});