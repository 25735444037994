import React from 'react';
import PropTypes from 'prop-types';
import './feedback.scss';
import Icon from "../primitives/Icon";
import {mdiAlertCircleOutline, mdiCheckboxMarkedCircleOutline, mdiInformationVariant} from "@mdi/js";

export const FEEDBACK_TYPE_FEIL = 'FEEDBACK_TYPE_FEIL';
export const FEEDBACK_TYPE_VARSEL = 'FEEDBACK_TYPE_VARSEL';
export const FEEDBACK_TYPE_OK = 'FEEDBACK_TYPE_OK';
export const FEEDBACK_TYPE_INFO = 'FEEDBACK_TYPE_INFO';


const getTypeProps = type => {
    switch (type) {
        case FEEDBACK_TYPE_FEIL:
            return {
                className: 'feedback-full__error',
                iconPath: mdiAlertCircleOutline,
            };
        case FEEDBACK_TYPE_VARSEL:
            return {
                className: 'feedback-full__warning',
                iconPath: mdiAlertCircleOutline,
            };
        case FEEDBACK_TYPE_OK:
            return {
                className: 'feedback-full__ok',
                iconPath: mdiCheckboxMarkedCircleOutline,
            };
        case FEEDBACK_TYPE_INFO:
        default:
            return {
                className: 'feedback-full__info',
                iconPath: mdiInformationVariant,
            };
    }
};

const Feedback = (props) => {

    const {type = 'info', showIcon = true, children, className='', role, ...restOfProps} = props;

    const {className: typeClassName, iconPath} = getTypeProps(type);

    const classes = `feedback-full ${typeClassName} ${!showIcon && 'no-icon'} ${className}`;

    return (
        <div className={classes} {...restOfProps} role={role}>
            {showIcon &&
            <div className="feedback-full__icon">
                <Icon path={iconPath}/>
            </div>
            }
            <div className={showIcon ? "feedback-full__text" : "feedback-full__text__no-icon"}>
                <div className='feedback-full__text__content'>
                    {children}
                </div>
            </div>
        </div>
    )

};

Feedback.propTypes = {
    children: PropTypes.node,
    farge: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    showIcon: PropTypes.bool,
};

export default Feedback