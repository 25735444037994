import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import MenubarDropdown from "./MenubarDropdown";
import {
    isLoggedInDataporten as isLoggedInDataportenSelector,
    isSaksbehandler as isSaksbehandlerSelector,
    getIsBedriftsbruker,
    getInnloggetBrukerNavn,
    attemptLoginSomBruker,
    logoutDeltaker,
    getIsSaksbehandlerLoggedInSomBruker, logoutUser,
} from "../../ducks/session";
import {showPopup} from "../../ducks/popup";
import {pages} from "../../utils/common";

import {
    soknaderIconPath,
    brukerIconPath,
    samtykkeIconPath,
    fakturaIconPath,
    foretaksgodkjenningIconPath
} from "../../utils/icons";
import {getNyBrukerNavnForMenybar} from "../../ducks/nyBruker";
import LoggInnSomBrukerForm from "./LoggInnSomBrukerForm";
import ShowIfLoggedIn from "../../wrappers/ShowIfLoggedIn";
import {requireDeltakernummer} from "../../high-order-components/requireDeltakernummer";
import ListLink, {ListOnClickLink} from "../../primitives/ListLink";
import Text from "../../primitives/Text";
import {
    mdiAccountOff,
    mdiAccountSearch,
    mdiCardAccountDetails,
    mdiEmail,
    mdiFolder,
    mdiPower,
    mdiSchool
} from "@mdi/js";
import Icon from "../../primitives/Icon";

const MenubarUser = () => {

    const dispatch = useDispatch();

    const isSaksbehandler = useSelector(isSaksbehandlerSelector);
    const isSaksbehandlerLoggedInSomBruker = useSelector(getIsSaksbehandlerLoggedInSomBruker);
    const isBedriftsbruker = useSelector(getIsBedriftsbruker);
    const isLoggedInDataporten = useSelector(isLoggedInDataportenSelector);
    const innloggetBrukerNavn = useSelector(getInnloggetBrukerNavn);
    const nyBrukerNavn = useSelector(getNyBrukerNavnForMenybar);

    const logout = () => {
        dispatch(logoutUser());
    };

    const openDeltakernrDialog = () => {
        const popupContent = {
            title: <Text id="logg_inn_som_bruker"/>,
            onOK: () => dispatch(attemptLoginSomBruker()),
            preventCloseOnOK: true,
            children: <LoggInnSomBrukerForm/>
        };
        dispatch(showPopup(popupContent));
    };

    const openLogoutDeltakerDialog = () => {
        const popupContent = {
            title: <Text id="logg_ut_deltaker"/>,
            onOK: () => dispatch(logoutDeltaker()),
            children: <Text id="du_blir_nå_logget_ut_som_deltaker"/>
        };
        dispatch(showPopup(popupContent));
    };

    if (!isLoggedInDataporten) {
        return null;
    }

    return (
        <div className='menubar-button'>
            <MenubarDropdown
                className='personmeny'
                ariaLabelTextId='brukermeny'
                toggler={{
                    Icon: <Icon path={brukerIconPath}/>,
                    text: innloggetBrukerNavn || nyBrukerNavn || ' '
                }}
            >
                <DeltakerPages/>
                {isBedriftsbruker &&
                <ListLink to={pages.foretaksgodkjenning}>
                    <Icon path={foretaksgodkjenningIconPath}/>
                    <Text id="foretaksgodkjenning"/>
                </ListLink>
                }
                {isSaksbehandler &&
                <ListOnClickLink onClick={openDeltakernrDialog}>
                    <Icon path={mdiAccountSearch}/>
                    <Text id="logg_inn_som_bruker"/>
                </ListOnClickLink>
                }
                {isSaksbehandlerLoggedInSomBruker &&
                <ListOnClickLink onClick={openLogoutDeltakerDialog}>
                    <Icon path={mdiAccountOff}/>
                    <Text id="logg_ut_deltaker"/>
                </ListOnClickLink>
                }
                {isLoggedInDataporten &&
                <ListOnClickLink onClick={logout}>
                    <Icon path={mdiPower}/>
                    <Text id="logout"/>
                </ListOnClickLink>
                }
            </MenubarDropdown>
        </div>
    )
};

const DeltakerPages = requireDeltakernummer(() => {
        return (
            <ShowIfLoggedIn>
                <ListLink to={pages.profil}>
                    <Icon path={mdiCardAccountDetails}/>
                    <Text id="profil"/>
                </ListLink>
                <ListLink to={pages.soknader}>
                    <Icon path={soknaderIconPath}/>
                    <Text id="søknader"/>
                </ListLink>
                <ListLink to={pages.dokumenter}>
                    <Icon path={mdiFolder}/>
                    <Text id="dokumentasjon"/>
                </ListLink>
                <ListLink to={pages.meldingsarkiv}>
                    <Icon path={mdiEmail}/>
                    <Text id="meldingsarkiv"/>
                </ListLink>
                <ListLink to={pages.mineKurs}>
                    <Icon path={mdiSchool}/>
                    <Text id="mine_kurs"/>
                </ListLink>
                <ListLink to={pages.samtykker}>
                    <Icon path={samtykkeIconPath}/>
                    <Text id="samtykker"/>
                </ListLink>
                <ListLink to={pages.fakturaoversikt}>
                    <Icon path={fakturaIconPath}/>
                    <Text id="fakturaoversikt"/>
                </ListLink>
            </ShowIfLoggedIn>
        )
    }, false
);

export default MenubarUser;