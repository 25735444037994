import React from 'react';
import PageTitle from "../PageTitle";
import Tabs from "../Tabs";
import AktiveKurs from "./AktiveKurs";
import TidligereKurs from "./TidligereKurs";
import requireLogin from "../../high-order-components/requireLogin";
import Text from "../../primitives/Text";


const MineKurs = () => {

    const labels = ['aktive', 'tidligere'];
    const contents = [
        <AktiveKurs/>,
        <TidligereKurs/>,
    ];

    return <div>
        <PageTitle id='mine_kurs'/>
        <h1>
            <Text id='mine_kurs'/>
        </h1>

        <Tabs
            labels={labels}
            contents={contents}
        />
    </div>


};


export default requireLogin(MineKurs);