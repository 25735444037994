import {useSelector} from "react-redux";
import {getTilknytningErrors} from "../../../ducks/soknadsdokumenter";
import Text from "../../../primitives/Text";
import {getDokumentById} from "../../../ducks/dokumenter";
import React from "react";
import WSErrorDescription from "../../WSErrorDescription";

const ErrorsTilknytninger = () => {
    const errorsTilknytninger = useSelector(getTilknytningErrors);

    if (!errorsTilknytninger.length) return null;

    return (<>
        <p>
            <Text id='følgende_dokumenter_ble_ikke_knyttet_til_søknaden'/>
        </p>
        <ul>
            {errorsTilknytninger.map(errorMap =>
                <ErrorTilknytning
                    key={errorMap.id}
                    id={errorMap.id}
                    error={errorMap.error}
                />)}
        </ul>
    </>)
};


const ErrorTilknytning = ({id, error}) => {
    const dokument = useSelector(state => getDokumentById(state, id));
    return (
        <li>
            <span className='element-beskrivelse'>{dokument && dokument.filnavn}</span>
            <WSErrorDescription error={error}/>
        </li>
    )

};

export default ErrorsTilknytninger;