import React from 'react';
import VisibleKursliste from "./VisibleKursliste";
import './kursoversikt.scss';
import withKursoversiktLoaded from "../../high-order-components/withKursoversiktLoaded";
import PageTitle from "../PageTitle";
import {pages} from "../../utils/common";
import {compose} from "redux";
import requireInstitusjonSelected from "../../high-order-components/requireInstitusjonSelected";
import requireKurskatalogAktivert from "../../high-order-components/requireKurskatalogAktivert";
import TextFraInstitusjon from "./TextFraInstitusjon";
import Filtre from "./filtre/Filtre";
import Text from "../../primitives/Text";

const Kursoversikt = () =>  {
    return (
        <div id='kursoversikt'>
            <PageTitle id='kursoversikt'/>
            <h1><Text id="kursoversikt"/></h1>
            <TextFraInstitusjon/>
            <Filtre/>
            <VisibleKursliste/>
        </div>
    );
};

export default compose(
    requireInstitusjonSelected,
    Component => requireKurskatalogAktivert(Component, pages.minSide),
    withKursoversiktLoaded,
)(Kursoversikt);