import React from "react";
import {pages} from "../utils/common";
import {getIsBedriftsbruker} from "../ducks/session";
import {useSelector} from "react-redux";
import Redirect from "../components/Redirect";

const requireBedriftsbruker = (Component, doRedirect = true) => props => {

    const isBedriftsbruker = useSelector(getIsBedriftsbruker);

    if(isBedriftsbruker){
        return <Component {...props}/>
    }

    if(doRedirect){
        return <Redirect to={pages.minSide}/>
    }

    return null
};

export default requireBedriftsbruker;