import React from "react";
import useInlineEdit from "../../../hooks/useInlineEdit";
import EditableField from "../../EditableField";
import {toUnicode} from "punycode";
import {emailIconPath} from "../../../utils/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormField, EPOST_FELT, getErrorLagring,
    getFormDataEpost,
    isEpostFraKorr as isEpostFraKorrSelector, lagreProfilFelt,
    mobilOgEpostErObligatorisk, resetErrorLagringForFelt, resetFormdata
} from "../../../ducks/profil";
import InputEpost from "./InputEpost";
import {getErrorMessageId, isEpostHasError} from "../../../utils/epostUtil";
import FormFocusControl from "../../inline-edit/FormFocusControl";
import InlineEdit from "../../inline-edit/InlineEdit";
import KorrHelpButton from "../korr/KorrHelpButton";


export const PrivatEpost = () => {

    const epost = useSelector(getFormDataEpost);
    const obligatorisk = useSelector(mobilOgEpostErObligatorisk);
    const errorLagring = useSelector(state => getErrorLagring(state, EPOST_FELT));
    const isChangesValid = !isEpostHasError(epost, obligatorisk);
    const isEpostFraKorr = useSelector(isEpostFraKorrSelector);

    const dispatch = useDispatch();
    const submitChanges = () => dispatch(lagreProfilFelt(EPOST_FELT));
    const resetChanges = () => dispatch(resetFormdata());

    const [isInEditMode, openEditing, closeEditing, attemptedClosed] = useInlineEdit(submitChanges, isChangesValid, errorLagring);
    const errorMessageId = getErrorMessageId(epost, obligatorisk, errorLagring, attemptedClosed);

    return (
        <InlineEdit
            resetChanges={resetChanges}
            editDisabled={isEpostFraKorr}
            isInEditMode={isInEditMode}
            openEditing={openEditing}
            closeEditing={closeEditing}
        >
            <FormFocusControl onFocusOut={closeEditing}>
                <Input epost={epost} errorMessageId={errorMessageId}/>
            </FormFocusControl>
            <Presentation epost={epost} isEpostFraKorr={isEpostFraKorr}/>
        </InlineEdit>
    );
};


const Input = ({isFocusOn, epost, errorMessageId}) => {
    const dispatch = useDispatch();
    const onChange = value => {
        dispatch(changeFormField(EPOST_FELT, value));
        dispatch(resetErrorLagringForFelt(EPOST_FELT));
    };
    return (
        <InputEpost
            epost={epost}
            onChange={onChange}
            errorMessageId={errorMessageId}
            isFocusOn={isFocusOn}
            labelId='privat_epost'
        />
    )
};


const Presentation = ({epost, isEpostFraKorr}) =>
    <EditableField value={epost && toUnicode(epost)}
                   valueNameId='e_post'
                   iconPath={emailIconPath}
                   justIconInHeader
    >
        {isEpostFraKorr && <KorrHelpButton mobil={false}/>}
    </EditableField>
;

export default PrivatEpost;