import React from "react";
import Input from "../../primitives/Input";
import {MAX_LENGDE_ADRESSELINJE, TYPE_ADRESSE_ARBEID} from "../../utils/common";
import Error from "../Error";
import Text from "../../primitives/Text";

const InputLinje1 = ({isFocusOn, typeAdresse, value, onChange, error, showError, onBlur}) => (
    <>
        <Input
            conditionForFocus={isFocusOn}
            label={typeAdresse === TYPE_ADRESSE_ARBEID? 'arbeidsgiver' : 'adresselinje1'}
            value={value}
            hasError={error && showError}
            onChange={event => onChange('linje1', event.target.value)}
            autoComplete={typeAdresse === TYPE_ADRESSE_ARBEID ? 'organization' : 'address-line1'}
            onBlur={onBlur}
        />
        {error && showError &&
        <Error>
            <Text
                id='max_antall_tegn_er'
                values={{antall: MAX_LENGDE_ADRESSELINJE}}
            />
        </Error>
        }
    </>
);

export default InputLinje1;