import React from 'react';
import {useSelector} from "react-redux";
import {
    getEditTempFileUpload,
    getEditWasSaveClicked,
    getHarArbeidserfaringDokument
} from "../../../ducks/arbeidserfaring";
import './dokumentasjon.scss';
import Dokument from "./Dokument";
import Dropzone from "./Dropzone";
import InPlaceError from "../../InPlaceError";

const Dokumentasjon = ({id, isInEditMode}) => {
    const harDokumentasjon = useSelector(state => getHarArbeidserfaringDokument(state, id));
    const tempFileUpload = useSelector(getEditTempFileUpload);
    const saveWasClicked = useSelector(getEditWasSaveClicked);
    const hasError = saveWasClicked && !tempFileUpload;

    return (
        <div className='arbeidserfaring__dokumentasjon'>
            {harDokumentasjon ?
                <Dokument id={id} isInEditMode={isInEditMode}/>
                :
                <>
                    <Dropzone id={id}/>
                    <InPlaceError errorMessageId={hasError && 'vennligst_legg_ved_dokumentasjon'}/>
                </>
            }
        </div>
    )
};

export default Dokumentasjon;