import React from 'react';
import Text from "../../primitives/Text";
import {pages} from "../../utils/common";
import {ReactLink} from "../../primitives/Link";
import './footer.scss';
import {useSelector} from "react-redux";
import {
    isLoggedIn as isLoggedInSelector,
    getIsBedriftsbruker,
    getInstitusjon
} from "../../ducks/session";
import {isKurskatalogAktivert as isKurskatalogAktivertSelector} from "../../ducks/appegenskaper";
import {requireDeltakernummer} from "../../high-order-components/requireDeltakernummer";


export const SiteMap = () => {

    const isKurskatalogAktivert = useSelector(isKurskatalogAktivertSelector);
    const institusjon = useSelector(getInstitusjon);
    const isBedriftsbruker = useSelector(getIsBedriftsbruker);
    const isLoggedIn = useSelector(isLoggedInSelector);

    return (
        <div className='site-map'>
            <h2>
                <Text id='nettstedoversikt'/>
            </h2>
            <ul>
                {isLoggedIn &&
                <li>
                    <ReactLink to={pages.minSide}><Text id='min_side'/></ReactLink>
                </li>
                }
                {!isLoggedIn &&
                <li>
                    <ReactLink to={pages.institusjon}><Text id='institusjoner'/></ReactLink>
                </li>
                }
                {!isLoggedIn && institusjon &&
                <li>
                    <ReactLink to={pages.login}><Text id='login'/></ReactLink>
                </li>
                }
                {isKurskatalogAktivert &&
                <li>
                    <ReactLink to={pages.kurs}><Text id='kursoversikt'/></ReactLink>
                </li>
                }
                <DeltakerPages/>
                {isBedriftsbruker &&
                <li>
                    <ReactLink to={pages.foretaksgodkjenning}><Text id='foretaksgodkjenning'/></ReactLink>
                </li>
                }
            </ul>
        </div>
    )
};

const DeltakerPages = requireDeltakernummer(() => {
        return (
            <>
                <li><ReactLink to={pages.soknader}><Text id='søknader'/></ReactLink></li>
                <li><ReactLink to={pages.profil}><Text id='profil'/></ReactLink></li>
                <li><ReactLink to={pages.dokumenter}><Text id='dokumentasjon'/></ReactLink></li>
                <li><ReactLink to={pages.meldingsarkiv}><Text id='meldingsarkiv'/></ReactLink></li>
                <li><ReactLink to={pages.fakturaoversikt}><Text id='fakturaoversikt'/></ReactLink></li>
                <li><ReactLink to={pages.mineKurs}><Text id='mine_kurs'/></ReactLink></li>
                <li><ReactLink to={pages.samtykker}><Text id='samtykker'/></ReactLink></li>
            </>
        )
    }, false
);