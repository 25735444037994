import Link from "../../primitives/Link";
import React from "react";
import {useDownloadDokument} from "../../hooks/useDownloadDokument";
import {formatFilename} from "../../utils/files";

export const DownloadLink = ({dokumentnr, filnavn}) => {
    const download = useDownloadDokument();
    return(
        <div className='inline-block'>
            <Link onClick={() => download(dokumentnr, filnavn)} href='#'>
                {formatFilename(filnavn)}
            </Link>
        </div>
    )
};