import * as routes from "./apiRoutes";
import {createArbeidserfaring, createSoknadstilknytning} from "../ducks/arbeidserfaring";
import {getBase64, transformFileForWS} from "./dokumenterAPI";
import {createRequestConfig} from "../utils/request";
import {kursIdToUrlString, nullToUndefined} from "../utils/common";
import axios from "axios";


export const getArbeidserfaring = async (evuapiToken, deltakernr) => {

    const route = routes.ARBEIDSERFARINGER.replace(':deltakernr', deltakernr);
    const config = createRequestConfig(evuapiToken);

    const response = await axios.get(route, config);
    return transformDataFromWS(response.data);

};

const transformDataFromWS = data => {
    const {arbeidserfaringer, kurskoblinger: soknadstilknytninger} = data;
    return {
        arbeidserfaringer: arbeidserfaringer.map(arbeidserfaring =>
            transformArbeidserfaringFromWS(arbeidserfaring)
        ),
        soknadstilknytninger: soknadstilknytninger.map(soknadstilknytning =>
            transformSoknadstilknytningFromWs(soknadstilknytning)
        ),
    }
};

const transformArbeidserfaringFromWS = arbeidserfaring =>
    createArbeidserfaring({
        ...arbeidserfaring,
        stillingsprosent: nullToUndefined(arbeidserfaring.stillingsprosent),
        fraDato: nullToUndefined(arbeidserfaring.datoFra),
        tilDato: nullToUndefined(arbeidserfaring.datoTil),
        dokumentid: nullToUndefined(arbeidserfaring.dokumentnr),
        dokumentnavn: nullToUndefined(arbeidserfaring.dokumentnavn),
    });

const transformSoknadstilknytningFromWs = soknadstilknytning =>
    createSoknadstilknytning({
        ...soknadstilknytning,
        id: soknadstilknytning.lopenr,
    });

export const postArbeidserfaring = async (evuapiToken, deltakernr, arbeidserfaring) => {

    const route = routes.ARBEIDSERFARINGER.replace(':deltakernr', deltakernr);
    const config = createRequestConfig(evuapiToken);

    const payload = transformArbeidserfaringToWS(arbeidserfaring);

    const response = await axios.post(route, payload, config);

    return transformArbeidserfaringFromWS(response.data);
};

const transformArbeidserfaringToWS = arbeidserfaring => ({
    lopenr: arbeidserfaring.id,
    arbeidsgiver: arbeidserfaring.arbeidsgiver,
    stillingstittel: arbeidserfaring.stillingstittel,
    datoFra: arbeidserfaring.fraDato,
    datoTil: arbeidserfaring.isVedvarende ? null : arbeidserfaring.tilDato,
    praksistypekode: "GENANNEN",
    stillingsprosent: arbeidserfaring.stillingsprosent,
});

export const deleteArbeidserfaring = async (evuapiToken, deltakernr, id) => {

    const route = routes.ARBEIDSERFARING
        .replace(':deltakernr', deltakernr)
        .replace(':id', id);
    const config = createRequestConfig(evuapiToken);

    await axios.delete(route, config);

};

export const postArbeidserfaringDokument = async (evuapiToken, deltakernr, id, fil) => {

    const route = routes.ARBEIDSERFARING_DOKUMENT
        .replace(':deltakernr', deltakernr)
        .replace(':id', id);
    const config = createRequestConfig(evuapiToken);

    const base64EncodedFile = await getBase64(fil);
    const payload = transformFileForWS(fil, base64EncodedFile);

    const response = await axios.post(route, payload, config);
    const {dokumentnr} = response.data;

    return {
        dokumentnavn: fil.name,
        dokumentid: dokumentnr,
    }

};

export const postSoknadstilknytning = async (evuapiToken, deltakernr, kursIdObject, id) => {

    const route = routes.ARBEIDSERFARING_SOKNASTILKNYTNING
        .replace(':deltakernr', deltakernr)
        .replace(':id', id)
        .replace(':kursId', kursIdToUrlString(kursIdObject));
    const config = createRequestConfig(evuapiToken);

    await axios.post(route, {}, config);
};

export const deleteSoknadstilknytning = async (evuapiToken, deltakernr, kursIdObject, id) => {

    const route = routes.ARBEIDSERFARING_SOKNASTILKNYTNING
        .replace(':deltakernr', deltakernr)
        .replace(':id', id)
        .replace(':kursId', kursIdToUrlString(kursIdObject));
    const config = createRequestConfig(evuapiToken);

    await axios.delete(route, config);
};