import {getInstitusjonsnr} from "./session";
import {getApptekster as fetchApptekster} from "../services/appteksterAPI";
import {getLocale} from "./i18n";
import {getValueByLanguage} from "../utils/common";

export const NAME = 'apptekster';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;

export const APPTEKST_PERSONVERN_URL = 'PERSONVERN';
export const APPTEKST_KONTAKTINFO_EPOST = 'INST_EPOST';
export const APPTEKST_KONTAKTINFO_TELEFON = 'INST_TLF';
export const APPTEKST_KONTAKTINFO_ADRESSE = 'INST_ADRESSE';
export const APPTEKST_KONTAKTINFO_STED = 'INST_STED';


export function loadPending() {
    return {
        type: LOAD_PENDING,
    };
}

export function loadSuccess(apptekster) {
    return {
        type: LOAD_SUCCESS,
        apptekster,
    };
}

export function loadFailure(error) {
    return {
        type: LOAD_FAILURE,
        error,
    };
}

export function loadApptekster() {
    return function (dispatch, getState) {
        const instnr = getInstitusjonsnr(getState());
        if (!instnr) {
            return;
        }
        dispatch(loadPending());
        return fetchApptekster(instnr).then(
            apptekster => dispatch(loadSuccess(apptekster)),
            error => dispatch(loadFailure(error))
        );
    };
}

export const createApptekst = props => {
    const {
        kode = '',
        tekst = [],
    } = props;
    return {
        kode,
        tekst,
    }
};

export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    apptekster: [],
};

function apptekster(state = initialState, action) {

    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                isDataLoaded: false,
                error: undefined,
                apptekster: [],
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                apptekster: action.apptekster,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error
            };
        default:
            return state
    }
}

function getState(state) {
    return state[NAME];
}

export function getError(state) {
    return getState(state).error;
}

export function isLoading(state) {
    return getState(state).isLoading;
}

export function isDataLoaded(state) {
    return getState(state).isDataLoaded;
}

export function getApptekstliste(state) {
    const locale = getLocale(state);
    return getState(state).apptekster.map(apptekst => ({
        ...apptekst,
        tekst: getValueByLanguage(apptekst.tekst, locale)
    }));
}

export function getApptekst(state, kode) {
    const apptekst = getApptekstliste(state).find(apptekst => apptekst.kode === kode);
    return apptekst && apptekst.tekst;
}

export default apptekster;
