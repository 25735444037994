import React from 'react';
import Select from "../primitives/Select";
import PropTypes from "prop-types"
import InPlaceError from "./InPlaceError";
import Text from "../primitives/Text";
import {mdiTranslate} from "@mdi/js";


export const InputMalform = ({value, onChange, autoFocus, errorMessageId, withIcon, justIconInLabel, ...restOfProps}) => {
    return (
        <React.Fragment>
            <Select
                label='malform'
                iconPathForLabel={(withIcon || justIconInLabel) ? mdiTranslate : undefined}
                options={malformer}
                clearable={false}
                value={value}
                onChange={(selectedOption) => onChange(selectedOption.value)}
                autoFocus={autoFocus}
                justIconInLabel={justIconInLabel}
                hasError={Boolean(errorMessageId)}
                autoComplete='language'
                {...restOfProps}
            />
            <InPlaceError errorMessageId={errorMessageId}/>
        </React.Fragment>
    );
};


export const malformer = [
    {value: 'BOKMÅL', label: <Text id='language.nb'/>},
    {value: 'NYNORSK', label: <Text id='language.nn'/>},
    {value: 'ENGELSK', label: <Text id='language.en'/>},
];


export function getMalformByCode(code) {
    const option = malformer.find(option => option.value === code);
    return (option && option.label) || code;
}


InputMalform.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    errorMessageId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    withIcon: PropTypes.bool,
    justIconInLabel: PropTypes.bool,
};


export default InputMalform;