import React from "react";
import TilknyttedeDokumenter from "./dokumenter-elementer/TilknyttedeDokumenter";
import Dropzone from "../Dropzone";
import {uploadDokumenter} from "../../ducks/dokumenter";
import VelgFraTidligereOpplastetFiler from "./dokumenter-elementer/VelgFraTidligereOpplastetFiler";
import {ANNET_DOKUMENTTYPE} from "../../services/dokumenterAPI";
import Text from "../../primitives/Text";
import {useSelector} from "react-redux";
import {getHarBareAndreKrav} from "../../ducks/kursinfo";

const Annet = () => {
    const harBareAndreKrav = useSelector(getHarBareAndreKrav);
    const className = `dokumentasjon-kategori ${harBareAndreKrav ? 'bare-andre-krav' : ''}`;
    return (
        <div className={className}>
            {!harBareAndreKrav && <h4><Text id='annet'/></h4>}
            <TilknyttedeDokumenter type={ANNET_DOKUMENTTYPE} withActions/>
            <Dropzone dispatchOnDrop={acceptedFiles => uploadDokumenter(acceptedFiles, true, ANNET_DOKUMENTTYPE)}/>
            <VelgFraTidligereOpplastetFiler type={ANNET_DOKUMENTTYPE}/>
        </div>
    )
};


export default Annet;