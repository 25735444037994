import React from "react";
import PropTypes from "prop-types";
import {MailToLink} from "../../primitives/Link";
import Infoelement from "../Infoelement";
import {EKSAMENSRETTET, FRIVILLIG_EKSAMENSRETTET, IKKE_EKSAMENSRETTET} from "../../services/kursApi";
import {Krav} from "./Krav";
import Text from "../../primitives/Text";
import {mdiTimelapse, mdiCalendarRange, mdiEmail, mdiCertificate} from '@mdi/js';
import Icon from "../../primitives/Icon";


export const ExtraInfo = ({varighetTimer, sluttdato, eksamen, emner, epost}) => {

    return (
        <div className='kursinfo__extra-info'>

            <Infoelement
                title={<Text id={'varighet'}/>}
                value={varighetTimer && <Text id={'varighet_timer'}
                                              values={{varighet: varighetTimer}}/>}
                iconPath={mdiTimelapse}
            />

            <Infoelement
                title={<Text id={'slutter'}/>}
                value={sluttdato}
                iconPath={mdiCalendarRange}
            />

            <Eksamen eksamen={eksamen} emner={emner}/>

            <Krav classname='info-element'/>

            <Infoelement
                title={<Text id={'e_post'}/>}
                value={epost && <MailToLink email={epost}/>}
                iconPath={mdiEmail}
            />

        </div>
    );
};



const EKSAMEN_TYPE_TEXT_ID = {
    [EKSAMENSRETTET]: 'eksamen',
    [FRIVILLIG_EKSAMENSRETTET]: 'frivillig_eksamen',
};

const Eksamen = ({eksamen, emner}) => {

    if (eksamen === IKKE_EKSAMENSRETTET) {
        return null;
    }

    const harEmner = Boolean(emner.length);

    return (
        <div className='info-element'>
            <div>
                <Icon path={mdiCertificate}/>
                <Text id={EKSAMEN_TYPE_TEXT_ID[eksamen]}/>
                {harEmner && <Text id={'i_emner'}/>}
            </div>
            {harEmner &&
            <ul>
                {emner.map(emne =>
                    <li key={emne.kode}>
                        <Emne emne={emne}/>
                    </li>
                )}
            </ul>
            }
        </div>
    )
};


const Emne = ({emne}) => {
    return (
        <span>
            {emne.navn} ({emne.kode})
            {emne.vektingstall ?
                <span>: {emne.vektingstall} {forkortet(emne.vekttypenavn)}
                </span>
                : null
            }
        </span>

    )
};

const forkortet = (vekttypenavn) => {

    const forkortelser = {
        studiepoeng: 'stp',
        credits: 'ECTS',
    };

    const forkortelse = forkortelser[vekttypenavn && vekttypenavn.toLowerCase()];
    return forkortelse ? forkortelse : vekttypenavn;
};


ExtraInfo.propTypes = {
    varighetTimer: PropTypes.number,
    sluttdato: PropTypes.string,
    eksamen: PropTypes.string,
    emner: PropTypes.array,
    gskKrav: PropTypes.bool,
    andreKrav: PropTypes.string,
    epost: PropTypes.string,
};