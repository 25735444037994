import React, {Component} from 'react'
import Sporreskjema from "../../sporreskjema/Sporreskjema";

class StegSporreskjema extends Component {

    render() {
        return (
            <Sporreskjema />
        )
    }
}

export default StegSporreskjema;