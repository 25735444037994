import React, {Component} from 'react';
import PropTypes from "prop-types";
import ShowOnlyForScreenReader from "../wrappers/ShowOnlyForScreenReader";
import './checkbox.scss';


class Checkbox extends Component {

    state = {
        focused: false
    };

    onFocus = () => {
        this.setState({
            focused: true
        });
    };

    onBlur = () => {
        this.setState({
            focused: false
        });
    };

    render() {
        const {onChange, checked, label, className = '', loading = false, textForScreenReader} = this.props;
        const {focused} = this.state;
        const classNameContainer = `checkbox-container ${className} ${focused ? 'focused' : 'blurred'}`;
        const classNameCheckmark = `checkbox-checkmark ${loading ? 'loading' : ''}`;

        return (
            <label className={classNameContainer}>
                {textForScreenReader ?
                    <ShowOnlyForScreenReader>
                        {textForScreenReader}
                    </ShowOnlyForScreenReader>
                    :
                    label
                }
                <input type="checkbox"
                       checked={checked}
                       onChange={onChange}
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                />
                <span className={classNameCheckmark}/>
            </label>
        );
    }
}

Checkbox.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    loading: PropTypes.bool,
    label: PropTypes.string,
    textForScreenReader: PropTypes.string,
};


export class TableCheckbox extends Component {

    constructor() {
        super();
        this.state = {
            focused: false
        };
    }

    onFocus = () => {
        this.setState({
            focused: true
        });
    };

    onBlur = () => {
        this.setState({
            focused: false
        });
    };

    render() {
        const {onChange, checked, textForScreenReader = 'Velg', className = '', loading = false} = this.props;
        const {focused} = this.state;
        const classNameContainer = `checkbox-container without-label ${className} ${focused ? 'focused' : 'blurred'}`;
        const classNameCheckmark = `checkbox-checkmark ${loading ? 'loading' : ''}`;
        return (
            <label className={classNameContainer}>
                <ShowOnlyForScreenReader>
                    {textForScreenReader}
                </ShowOnlyForScreenReader>
                <input type='checkbox'
                       checked={checked}
                       onChange={onChange}
                       onFocus={this.onFocus}
                       onBlur={this.onBlur}
                />
                <span className={classNameCheckmark}/>
            </label>
        );
    }
}


Checkbox.propTypes = {
    onChange: PropTypes.func,
    checked: PropTypes.bool,
    loading: PropTypes.bool,
    textForScreenReader: PropTypes.node,
    className: PropTypes.string,
};

export default Checkbox


