import {CSSTransition, TransitionGroup} from "react-transition-group";
import {kursIdToString} from "../../utils/common";
import SoknadRow from "./SoknadRow";
import React from "react";
import Feedback from "../Feedback";
import {useSelector} from "react-redux";
import {getSoknader} from "../../ducks/soknader";
import withSoknaderLoaded from "../../high-order-components/withSoknaderLoaded";
import Text from "../../primitives/Text";

export const SoknaderListe = () => {

    const soknader = useSelector(getSoknader);

    if (soknader.length === 0) {
        return (
            <Feedback>
                <Text id='du_har_ingen_søknader_under_behandling'/>
            </Feedback>
        )
    }

    return (
        <div className='soknader__liste'>
            <TransitionGroup>
                {soknader.map(soknad =>
                    <CSSTransition key={kursIdToString(soknad.id)}
                                   timeout={500}
                                   classNames='fade-transition'
                    >
                        <SoknadRow {...soknad}/>
                    </CSSTransition>
                )}
            </TransitionGroup>
        </div>)
};

export default withSoknaderLoaded(SoknaderListe);