import React from "react";
import useInlineEdit from "../../../hooks/useInlineEdit";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormFieldObject,
    getErrorLagring, getFormDataAdresse, getFormErrorsAdresse,
    lagreAdresse, resetErrorLagringForFelt,
    resetFormdata
} from "../../../ducks/profil";
import {TYPE_ADRESSE_ARBEID} from "../../../utils/common";
import {isAdresseValid} from "../../../utils/adresseUtil";
import InlineEdit from "../../inline-edit/InlineEdit";
import AdressePresentasjon from "../../adresse/AdressePresentation";
import AdresseForm from "../../adresse/AdresseForm";
import ErrorFormSaving from "../../ErrorFormSaving";

export const Arbeidsadresse = () => {
    const adresse = useSelector(state => getFormDataAdresse(state, TYPE_ADRESSE_ARBEID));
    const errors = useSelector(state => getFormErrorsAdresse(state, TYPE_ADRESSE_ARBEID));
    const errorLagring = useSelector(state => getErrorLagring(state, TYPE_ADRESSE_ARBEID));
    const isChangesValid = isAdresseValid(errors);

    const dispatch = useDispatch();
    const submitChanges = () => dispatch(lagreAdresse(TYPE_ADRESSE_ARBEID));
    const resetChanges = () => dispatch(resetFormdata());
    const formDataOnChange = (key, value) => {
        dispatch(changeFormFieldObject(key, value, TYPE_ADRESSE_ARBEID));
        dispatch(resetErrorLagringForFelt(TYPE_ADRESSE_ARBEID));
    };
    const [isInEditMode, openEditing, closeEditing, attemptedClosed] = useInlineEdit(submitChanges, isChangesValid, errorLagring);

    return (
        <InlineEdit
            resetChanges={resetChanges}
            isInEditMode={isInEditMode}
            openEditing={openEditing}
            closeEditing={closeEditing}
        >
            <AdresseForm
                typeAdresse={TYPE_ADRESSE_ARBEID}
                onChange={formDataOnChange}
                adresse={adresse}
                errors={errors}
                closeEditing={closeEditing}
                attemptedClosed={attemptedClosed}
            />
            <AdressePresentasjon
                typeAdresse={TYPE_ADRESSE_ARBEID}
                adresse={adresse}
            />
            {errorLagring && <ErrorFormSaving error={errorLagring}/>}
        </InlineEdit>
    );
};


export default Arbeidsadresse;