import * as routes from "./apiRoutes";
import axios from "axios/index";
import {createRequestConfig} from "../utils/request";

export const syncKorr = async (deltakernr, evuapiToken) => {

    const route = routes.SYNC_KORR.replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);
    return await axios.post(route, undefined, config);
};