import React from 'react'
import {Icon as MdiIcon} from '@mdi/react'
import {useIntl} from "react-intl";
import './icon.scss';

const Icon = ({path, title, className=''}) => {
    const intl = useIntl();
    return (
        <MdiIcon path={path}
                 className={`icon ${className}`}
                 title={title && intl.formatMessage({id: title})}
                 alt={title && intl.formatMessage({id: title})}
                 focusable={false}
                 aria-hidden={!title}
        />
    );
};

export default Icon;