import axios from 'axios';
import {KURSLISTE, KURSINFO} from "./apiRoutes";
import {
    kursIdStringToPathVariable,
    kursIdToString,
    nullToUndefined,
    parseIsoDate,
    sanitizeHtmlText
} from "../utils/common";
import {createEmne, createKategori, createKurs, createKursId} from "../ducks/kursoversikt";

export const EKSAMENSRETTET = 'EKSAMENSRETTET';
export const FRIVILLIG_EKSAMENSRETTET = 'FRIVILLIG EKSAMENSRETTET';
export const IKKE_EKSAMENSRETTET = 'IKKE EKSAMENSRETTET';

export const EKSAMENSTYPER = [
    EKSAMENSRETTET,
    IKKE_EKSAMENSRETTET,
    FRIVILLIG_EKSAMENSRETTET,
];

class KursApi {

    static getAlleKurs(instnr) {
        const route = KURSLISTE.replace(':instnr', instnr);
        return axios.get(route)
            .then(response => {
                const {kursliste, kategorier} = transformKurslisteDataFromWS(response.data);
                return {
                    kursliste,
                    kategorier,
                };
            })
            .catch(error => {
                throw error;
            });
    }

    static getKursinfo(instnr, kursId) {
        const route = KURSINFO
            .replace(':kursId', kursIdStringToPathVariable(kursId))
            .replace(':instnr', instnr);
        return axios.get(route)
            .then(response => {
                const result = transformKursDataFromWS(response.data);
                if (!result.id) {
                    throw new Error('ugyldig_kurs')
                }
                return transformKursDataFromWS(response.data);
            })
            .catch(error => {
                throw error;
            });
    }

}

function transformKursDataFromWS(kurs) {
    return createKurs({
        id: createKursId(kurs.kurskode, kurs.tidskode),
        pris: kurs.pris ? Number(kurs.pris) : undefined,
        startdato: kurs.datofra ? parseIsoDate(kurs.datofra) : undefined,
        sluttdato: kurs.datotil ? parseIsoDate(kurs.datotil) : undefined,
        kategorier: transformKategorierFromWS(kurs.kategorier),
        kursnavn: kurs.kursnavn,
        nettbasert: Boolean(kurs.status_nettbasert_und),
        samlingsbasert: Boolean(kurs.status_samlingsbasert),
        varighetTimer: kurs.varighettimer && Number(kurs.varighettimer),
        epost: kurs.emailadresse,
        nettside: kurs.url,
        pameldingsfrist: kurs.fristSoknad ? parseIsoDate(kurs.fristSoknad) : undefined,
        harGSKKrav: kurs.status_gsk_krav,
        harKravOmHoyereUtd: kurs.status_krav_hoyere_utd,
        harKravOmPraksis: kurs.status_krav_praksis,
        harAndreKrav: kurs.status_krav_andre,
        merknadKrav: kurs.merknadKrav,
        nettsideKrav: kurs.urlKrav,
        emner: kurs.emner && kurs.emner.map(emne => createEmne(emne.emnekode, emne.emnenavn, emne.vektingstall, emne.vekttypenavn)),
        beskrivelser: transformKursinfotekster(kurs.kursinfo),
        eksamen: kurs.eksamen,
        direktePamelding: kurs.status_aut_jasvar && kurs.status_aut_tilbud,
        antallLedigePlasser: nullToUndefined(kurs.antallLedigePlasser),
        automatiskStenging: kurs.status_aut_steng_pameld,
        automatiskJaSvar: kurs.status_aut_jasvar,
        automatiskVenteliste: kurs.status_aut_venteliste,
        automatiskTilbud: kurs.status_aut_tilbud,
    });
}

function transformKurslisteObjectFromWS(kurs) {
    return createKurs({
        id: createKursId(kurs.kurskode, kurs.tidskode),
        pris: kurs.pris ? Number(kurs.pris) : undefined,
        startdato: kurs.datofra ? parseIsoDate(kurs.datofra) : undefined,
        sluttdato: kurs.datotil ? parseIsoDate(kurs.datotil) : undefined,
        kategorier: kurs.kategorier && kurs.kategorier.map(kategori => kategori.kurskategorikode),
        kursnavn: kurs.kursnavn,
        nettbasert: Boolean( kurs.status_nettbasert_und),
        samlingsbasert: Boolean(kurs.status_samlingsbasert),
        varighetTimer: kurs.varighettimer && Number(kurs.varighettimer),
        pameldingsfrist: kurs.fristSoknad ? parseIsoDate(kurs.fristSoknad) : undefined,
        eksamen: kurs.eksamen,
        direktePamelding: kurs.status_aut_jasvar && kurs.status_aut_tilbud,
        harDetaljer: harDetaljer(kurs),
        antallLedigePlasser: nullToUndefined(kurs.antallLedigePlasser),
        automatiskStenging: kurs.status_aut_steng_pameld,
        automatiskJaSvar: kurs.status_aut_jasvar,
        automatiskVenteliste: kurs.status_aut_venteliste,
        automatiskTilbud: kurs.status_aut_tilbud,
    });
}

const harDetaljer = kurs => {
    return Boolean(kurs.harBeskrivelse || kurs.emailadresse || kurs.url || (kurs.emner && kurs.emner.length) || kurs.harOpptakskrav);
};

function transformKurslisteDataFromWS(kurs) {
    return {
        kategorier: getKategorierFromKursListeWS(kurs),
        kursliste: kursListeToMap(kurs),
    }
}

const kursListeToMap = kurs => kurs.map(kurs => transformKurslisteObjectFromWS(kurs)).reduce(function (map, obj) {
    map[kursIdToString(obj.id)] = obj;
    return map;
}, {});

function transformKursinfotekster(kursinfo = []) {
    return (
        kursinfo.map(oversettelse => ({
                lang: oversettelse.lang,
                value: sanitizeHtmlText(oversettelse.value)
            })
        )
    )
}

function getKategorierFromKursListeWS(kursliste) {
    const kategorier = {};
    kursliste.forEach(kurs => {
        if (kurs.kategorier) {
            kurs.kategorier.forEach(({kurskategorikode: kode, ...restOfProps}) => {
                if (!kategorier[kode]) {
                    kategorier[kode] = createKategori({
                        kode,
                        ...restOfProps,
                    });
                }
            })
        }
    });
    return kategorier;
}

function transformKategorierFromWS(kategorier) {
    return kategorier.map(kategori => kategori)
}


export default KursApi;