import {useDispatch, useSelector} from "react-redux";
import {createGodkjenning, getSoknaderForKursKanGodkjennes, godkjennSoknader} from "../../../ducks/foretaksgodkjenning";
import {STATUS_SENDT_TIL_GODKJENNING} from "../../../utils/soknadStatus";
import Button from "../../../primitives/Button";
import React, {useState} from "react";
import OnClickOutsideCatcher from "../../OnClickOutsideCatcher";
import {mdiCheck, mdiClose} from "@mdi/js";
import {endreIconPath} from "../../../utils/icons";

const Actions = ({deltakernr, kursId, foretakkode, isGodkjent, harGodkjenning, status}) => {
    const dispatch = useDispatch();
    const erBesvart = isGodkjent !== undefined;
    const [editMode, setEditMode] = useState(!erBesvart);
    const skalGodkjennes = harGodkjenning || status === STATUS_SENDT_TIL_GODKJENNING;
    const kanGodkjennes = useSelector(state => getSoknaderForKursKanGodkjennes(state, kursId, foretakkode));
    const godkjennSoknad = svar => {
        svar !== isGodkjent && dispatch(godkjennSoknader(
            kursId,
            foretakkode,
            [
                createGodkjenning({
                    deltakernr,
                    isGodkjent: svar,
                })
            ]
        ));
        setEditMode(false);
    };
    if(!skalGodkjennes)return null;
    if(!editMode){
        return (
            <Button
                onClick={()=>setEditMode(true)}
                className={'endre'}
                iconPath={endreIconPath}
                altText={'endre_svar'}
                disabled={!kanGodkjennes}
            />
        )
    }
    return (
        <OnClickOutsideCatcher onCatch={()=>erBesvart && setEditMode(false)}>
            <Button
                iconPath={mdiClose}
                altText={'avslå_søknad'}
                className={'avvis'}
                onClick={() => godkjennSoknad(false)}
                disabled={!kanGodkjennes}
            />
            <Button
                iconPath={mdiCheck}
                altText={'godkjenn_søknad'}
                className={'godkjenn'}
                onClick={() => godkjennSoknad(true)}
                disabled={!kanGodkjennes}
            />
        </OnClickOutsideCatcher>
    )
};

export default Actions;