import React from 'react';
import {getError, isDataLoaded, isLoadingFirstTime, loadSoknader} from '../ducks/soknader';
import InitialLoadWrapper from "../wrappers/InitialLoadWrapper";
import Text from "../primitives/Text";

const withSoknaderLoaded = Component => props =>
    <InitialLoadWrapper
        loadAction={loadSoknader}
        loadingSelector={isLoadingFirstTime}
        loadedSelector={isDataLoaded}
        errorSelector={getError}
        loadMessage={<Text id='laster_inn_søknader'/>}
    >
        <Component {...props} />
    </InitialLoadWrapper>
;


export default withSoknaderLoaded;