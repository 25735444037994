import {useDispatch, useSelector} from "react-redux";
import {getAktueltDeltakernr, getEvuapiToken} from "../ducks/session";
import {showPopup} from "../ducks/popup";
import {downloadFaktura} from "../services/fakturaoversiktAPI";
import {getFakturaFilnavn} from "../utils/fakturaUtil";
import {createErrorPopupContent} from "../utils/request";

export const useDownloadFaktura = fakturanr => {

    const evuApiToken = useSelector(getEvuapiToken);
    const deltakernr = useSelector(getAktueltDeltakernr);
    const dispatch = useDispatch();

    const download = () => {
        const filnavn = getFakturaFilnavn(fakturanr);
        downloadFaktura(fakturanr, filnavn, evuApiToken, deltakernr)
            .catch(error => dispatch(showPopup(createErrorPopupContent(error))));
    };

    return download;
};