import * as soknaderAPI from '../services/soknaderAPI';
import {
    fristHarUtgatt,
    getValueByLanguage,
    kursIdToString,
} from "../utils/common";

import {
    ACTION_TYPE_ANGRE_TREKK,
    ACTION_TYPE_DOKUMENTASJON,
    ACTION_TYPE_ENDRE, ACTION_TYPE_ENDRE_FAKTURAINFO,
    ACTION_TYPE_ENDRE_SVAR,
    ACTION_TYPE_SE,
    ACTION_TYPE_SVARE,
    FRIST_UBEGRENSET,
    STATUS_IKKE_GODKJENT, STATUS_PAMELDT, STATUS_SOKNAD_REGISTRERT, STATUS_TILBUD_AKSEPTERT,
    STATUS_TRUKKET,
    STATUS_TRUKKET_FRA_VENTELISTE, STATUS_VENTELISTE,
} from "../utils/soknadStatus";
import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {getLocale} from "./i18n";
import {
    STATUS_AUTOMATISK_TRUKKET_FRA_VENTELISTE,
    STATUS_AVLYST,
    STATUS_AVSLAG,
    STATUS_BORTFALT, STATUS_IKKE_FULLFORT, STATUS_IKKE_TILBUD, STATUS_MANGLER_DOKUMENTASJON,
    STATUS_TILBUD, STATUS_TILBUD_ANNULLERT,
    STATUS_TILBUD_OM_VENTELISTEPLASS, STATUS_TILBUD_OM_VENTELISTEPLASS_ANNULLERT, STATUS_UBESVART_TILBUD
} from "../utils/soknadStatus";
import {closePopup, showPopup} from "./popup";
import {createErrorPopupContent} from "../utils/request";

export const NAME = 'soknader';
const LOAD_SOKNADER_PENDING = `${NAME}/LOAD_SOKNADER_PENDING`;
const LOAD_SOKNADER_SUCCESS = `${NAME}/LOAD_SOKNADER_SUCCESS`;
const LOAD_SOKNADER_FAILURE = `${NAME}/LOAD_SOKNADER_FAILURE`;
const SVARE_PA_TILBUD = `${NAME}/SVARE_PA_TILBUD`;
const SOKNAD_ACTION_PENDING = `${NAME}/SOKNAD_ACTION_PENDING`;
const SOKNAD_ACTION_FAILURE = `${NAME}/SOKNAD_ACTION_FAILURE`;
const RESETT_SOKNAD_ACTION = `${NAME}/RESETT_SOKNAD_ACTION`;
const SETT_SLETTET_SOKNAD = `${NAME}/SETT_SLETTET_SOKNAD`;
const RESETT_SLETTET_SOKNAD = `${NAME}/RESETT_SLETTET_SOKNAD`;


export function loadSoknaderPending() {
    return {
        type: LOAD_SOKNADER_PENDING,
    };
}


export function loadSoknaderSuccess(soknader) {
    return {
        type: LOAD_SOKNADER_SUCCESS,
        data: soknader,
    };
}


export function loadSoknaderFailure(error) {
    return {
        type: LOAD_SOKNADER_FAILURE,
        error: error
    };
}


export function loadSoknader() {
    return function (dispatch, getState) {
        if (isLoading(getState())) {
            return;
        }
        dispatch(loadSoknaderPending());
        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());
        return soknaderAPI.getSoknader(deltakernr, evuapiToken).then(
            soknader => dispatch(loadSoknaderSuccess(soknader)),
            error => dispatch(loadSoknaderFailure(error))
        );
    };
}


export function svarePaTilbud(svar) {
    return {
        type: SVARE_PA_TILBUD,
        svar,
    };
}

export function soknadActionPending() {
    return {
        type: SOKNAD_ACTION_PENDING,
    };
}

export const soknadActionFailure = error => ({
    type: SOKNAD_ACTION_FAILURE,
    error,
});

export const resettSoknadAction = () => ({
    type: RESETT_SOKNAD_ACTION,
});

export const settSlettetSoknad = soknad => ({
    type: SETT_SLETTET_SOKNAD,
    soknad,
});

export const resettSlettetSoknad = () => ({
    type: RESETT_SLETTET_SOKNAD
});


export function sendSvarPaTilbud(kursId) {
    return function (dispatch, getState) {
        const state = getState();
        const svarPaTilbud = getSvarPaTilbud(state);
        if (svarPaTilbud === undefined) {
            dispatch(closePopup());
            return;
        }

        dispatch(soknadActionPending());

        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());

        return soknaderAPI.sendSvarPaTilbud(evuapiToken, deltakernr, kursIdToString(kursId), svarPaTilbud).then(
            () => {
                dispatch(resettSoknadAction());
                dispatch(loadSoknader());
                dispatch(closePopup());
            },
            error => {
                dispatch(soknadActionFailure(error));
            }
        );
    };
}

export function trekkSoknad(kursId) {
    return function (dispatch, getState) {

        dispatch(soknadActionPending());

        const state = getState();
        const deltakernr = getAktueltDeltakernr(state);
        const evuapiToken = getEvuapiToken(state);

        return soknaderAPI.trekkSoknad(evuapiToken, deltakernr, kursId).then(
            () => {
                dispatch(resettSoknadAction());
                dispatch(loadSoknader());
                dispatch(closePopup());
            },
            error => {
                dispatch(soknadActionFailure(error));
            }
        );
    };
}

export function angreTrekkSoknad(kursId) {
    return function (dispatch, getState) {

        dispatch(soknadActionPending());

        const state = getState();
        const deltakernr = getAktueltDeltakernr(state);
        const evuapiToken = getEvuapiToken(state);

        return soknaderAPI.angreTrekkSoknad(evuapiToken, deltakernr, kursId).then(
            () => {
                dispatch(resettSoknadAction());
                dispatch(loadSoknader());
                dispatch(closePopup());
            },
            error => {
                dispatch(resettSoknadAction());
                dispatch(showPopup(createErrorPopupContent(error)));
            }
        );
    };
}

export function slettUfullfortSoknad(id, showPopupOnError) {
    return function (dispatch, getState) {

        dispatch(soknadActionPending());

        const state = getState();
        const deltakernr = getAktueltDeltakernr(state);
        const evuapiToken = getEvuapiToken(state);
        const soknad = getSoknadById(state, id);

        return soknaderAPI.slettSoknad(evuapiToken, deltakernr, id).then(
            () => {
                dispatch(settSlettetSoknad(soknad));
                dispatch(resettSoknadAction());
                dispatch(loadSoknader());
                dispatch(closePopup());
            },
            error => {
                dispatch(soknadActionFailure(error));
                if (showPopupOnError) {
                    dispatch(showPopup(createErrorPopupContent(
                        error,
                        'noe_gikk_galt_ved_sletting_av_søknaden_din'
                    )))
                }
            }
        );
    };
}


export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    soknadActionPending: false,
    soknadActionError: undefined,
    data: [],
    slettetSoknad: undefined,
    svarPaTilbud: undefined,
};

export const createAction = ({
                                 type = ACTION_TYPE_ENDRE,
                                 frist = FRIST_UBEGRENSET,
                             }) => {
    return {
        type,
        frist,
    }
};


function soknader(state = initialState, action) {
    switch (action.type) {
        case LOAD_SOKNADER_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_SOKNADER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                data: action.data
            };
        case LOAD_SOKNADER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case SVARE_PA_TILBUD:
            return {
                ...state,
                svarPaTilbud: action.svar,
            };
        case SOKNAD_ACTION_PENDING:
            return {
                ...state,
                soknadActionPending: true,
            };
        case SOKNAD_ACTION_FAILURE:
            return {
                ...state,
                soknadActionPending: false,
                soknadActionError: action.error,
            };
        case RESETT_SOKNAD_ACTION:
            return {
                ...state,
                soknadActionPending: false,
                soknadActionError: undefined,
                svarPaTilbud: undefined,
            };
        case SETT_SLETTET_SOKNAD:
            return {
                ...state,
                slettetSoknad: action.soknad,
            };
        case RESETT_SLETTET_SOKNAD:
            return {
                ...state,
                slettetSoknad: initialState.slettetSoknad
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME];
}

export function getData(state) {
    return getState(state).data;
}

export function getSoknader(state) {
    const locale = getLocale(state);
    return getData(state).map(soknad => {
        const kursnavn = getValueByLanguage(soknad.kursnavn, locale);
        return {
            ...soknad,
            kursnavn: kursnavn,
        }
    });
}

export function getRelevanteActions(state, soknadId) {
    const actions = getSoknadActionsById(state, soknadId);
    const {antallLedigePlasser} = getSoknadById(state, soknadId) || {};
    const endreFrist = getEndreFrist(actions);
    const actionsMedFrist = actions.filter(action => Boolean(action.frist));
    const actionsFiltrert = actionsMedFrist.filter(action => !erAngreTrekkOgKursErFullt(action, antallLedigePlasser));
    return leggTilSeActionForUtgattSoknad(actionsFiltrert, endreFrist);
}

const leggTilSeActionForUtgattSoknad = (actions, endreFrist) => {
    if (endreFrist < Date.now() && actions.find(action => action.type === ACTION_TYPE_ENDRE)) {
        return [
            ...actions,
            createAction({type: ACTION_TYPE_SE}),
        ]
    }
    return actions;
};

function getEndreFrist(actions) {
    const endreAction = actions.find(action => action.type === ACTION_TYPE_ENDRE);
    return endreAction && endreAction.frist;
}

const erAngreTrekkOgKursErFullt = (action, antallLedigePlasser) =>
    action.type === ACTION_TYPE_ANGRE_TREKK && antallLedigePlasser === 0;

export function isDataLoaded(state) {
    return getState(state).isDataLoaded;
}


export function isLoading(state) {
    return getState(state).isLoading;
}

export const isLoadingFirstTime = state => isLoading(state) && !isDataLoaded(state);

export function getError(state) {
    return getState(state).error;
}

export function getSoknaderSomVenterPaSvar(state) {
    return getSoknader(state).filter(
        soknad => {
            const action = soknad.actions.find(a => a.type === ACTION_TYPE_SVARE);
            return action && action.frist > Date.now() && [
                STATUS_TILBUD_OM_VENTELISTEPLASS,
                STATUS_TILBUD,
            ].includes(soknad.status)
        }
    )
}

export function getSoknaderSomVenterPaDokumenter(state) {
    return getSoknader(state).filter(
        soknad => {
            const action = soknad.actions.find(a => a.type === ACTION_TYPE_DOKUMENTASJON);
            return action && action.frist > Date.now() && [
                STATUS_MANGLER_DOKUMENTASJON
            ].includes(soknad.status)
        }
    )
}

export function getAvvisteSoknader(state) {
    return getSoknader(state).filter(
        soknad => [
            STATUS_AVSLAG,
            STATUS_AVLYST,
            STATUS_BORTFALT,
            STATUS_IKKE_TILBUD,
            STATUS_TILBUD_ANNULLERT,
            STATUS_TILBUD_OM_VENTELISTEPLASS_ANNULLERT,
            STATUS_UBESVART_TILBUD,
            STATUS_AUTOMATISK_TRUKKET_FRA_VENTELISTE,
        ].includes(soknad.status)
    )
}

export function getUferdigeSoknader(state) {
    return getSoknader(state).filter(
        soknad => {
            const action = soknad.actions.find(a => a.type === ACTION_TYPE_ENDRE);
            return action && action.frist > Date.now() && [
                STATUS_IKKE_FULLFORT
            ].includes(soknad.status)
        }
    )
}

export function getEarliestSvarFrist(state) {
    let nearest = undefined;
    getSoknaderSomVenterPaSvar(state).forEach(soknad => {
        soknad.actions.forEach(action => {
            if (action.frist && action.type === ACTION_TYPE_SVARE) {
                const frist = action.frist;
                if (frist < nearest || !nearest) nearest = frist;
            }
        });
    });
    return nearest;
}

export function finnesSoknaderSomVenterPaSvar(state) {
    return getSoknaderSomVenterPaSvar(state).length > 0;
}

export function finnesSoknaderSomVenterPaDokumenter(state) {
    return Boolean(getSoknaderSomVenterPaDokumenter(state).length);
}

export function finnesAvvisteSoknader(state) {
    return Boolean(getAvvisteSoknader(state).length);
}

export function finnesUferdigeSoknader(state) {
    return Boolean(getUferdigeSoknader(state).length);
}

export function antallVarseltyper(state) {
    return [
        finnesSoknaderSomVenterPaSvar(state),
        finnesSoknaderSomVenterPaDokumenter(state),
        finnesAvvisteSoknader(state),
        finnesUferdigeSoknader(state),
    ].filter(Boolean).length
}

export function getAntallSoknader(state) {
    return getSoknader(state).length;
}

export function getAntallSoknaderSomVenterPaSvar(state) {
    return getSoknaderSomVenterPaSvar(state).length;
}

export function getAntallSoknaderSomVenterPaDokumenter(state) {
    return getSoknaderSomVenterPaDokumenter(state).length;
}

export function getAntallAvvisteSoknader(state) {
    return getAvvisteSoknader(state).length;
}

export function getAntallUferdigeSoknader(state) {
    return getUferdigeSoknader(state).length;
}

export function getSoknadById(state, id) {
    return getSoknader(state)
        .find(soknad => JSON.stringify(soknad.id) === JSON.stringify(id))
}


export function getSoknadActionsById(state, id) {
    const soknad = getSoknadById(state, id);
    return soknad ? soknad.actions : [];
}


export function getSvarfristById(state, id) {
    const actions = getSoknadActionsById(state, id);

    const svareAction = actions.find(action => action.type === ACTION_TYPE_SVARE);

    return svareAction ? svareAction.frist : undefined;
}

export function getEndreSvarfristById(state, id) {
    const actions = getSoknadActionsById(state, id);

    const endreSvarAction = actions.find(action => action.type === ACTION_TYPE_ENDRE_SVAR);

    return endreSvarAction ? endreSvarAction.frist : undefined;
}


export function getStatusById(state, id) {
    const soknad = getSoknadById(state, id);
    return soknad ? soknad.status : undefined;
}

export function getKursnavnById(state, id) {
    const soknad = getSoknadById(state, id);
    return soknad ? soknad.kursnavn : undefined;
}

export function getSvarPaTilbudOriginalById(state, id) {
    const soknad = getSoknadById(state, id);
    return soknad ? soknad.svarPaTilbudOriginal : undefined;
}

export function getSvarPaTilbud(state) {
    return getState(state).svarPaTilbud;
}

export const getSoknadActionError = state => getState(state).soknadActionError;
export const isSoknadActionPending = state => getState(state).soknadActionPending;

export const getSlettetSoknad = state => getState(state).slettetSoknad && getState(state).slettetSoknad.kursnavn;

export const finnesSoknadForKurs = (state, kursId) => Boolean(getSoknadById(state, kursId));

export const getSoknadKanEndresInnenforFristen = (state, kursId) => {
    const actions = getRelevanteActions(state, kursId);
    return Boolean(actions && actions.find(action => action.type === ACTION_TYPE_ENDRE));
};

export const finnesFullfortSoknadForKurs = (state, kursId) =>
    Boolean(getSoknadById(state, kursId)) && getSoknadById(state, kursId).status !== STATUS_IKKE_FULLFORT;

export const getTidsIntervallForSoknad = (state, kursId) => {
    const soknad = getSoknadById(state, kursId);
    return {
        datoFra: soknad ? soknad.datoFra : undefined,
        datoTil: soknad ? soknad.datoTil : undefined,
    }
};

export const getSoknaderInnenforTidsintervall = (state, datoFra, datoTil) =>
    (!datoFra || !datoTil) ?
        []
        :
        getSoknader(state).filter(soknad => soknadErInnenforIntervall(soknad, datoFra, datoTil))
;

const soknadErInnenforIntervall = (soknad, datoFra, datoTil) => !(soknad.datoFra > datoTil || soknad.datoTil < datoFra);

const IRRELEVANTE_STATUSER_FOR_OVERLAPP = [
    STATUS_AUTOMATISK_TRUKKET_FRA_VENTELISTE,
    STATUS_AVLYST,
    STATUS_AVSLAG,
    STATUS_TILBUD_OM_VENTELISTEPLASS_ANNULLERT,
    STATUS_TRUKKET,
    STATUS_IKKE_TILBUD,
    STATUS_BORTFALT,
    STATUS_TRUKKET_FRA_VENTELISTE,
    STATUS_IKKE_GODKJENT,
];

export const getOverlappendeSoknader = (state, kursId) => {
    const {datoFra, datoTil} = getTidsIntervallForSoknad(state, kursId);
    return getSoknaderInnenforTidsintervall(state, datoFra, datoTil)
        .filter(soknad =>
            kursIdToString(soknad.id) !== kursIdToString(kursId) && !IRRELEVANTE_STATUSER_FOR_OVERLAPP.includes(soknad.status)
        );
};

export const getSoknaderSomManglerFakturainfo = state => {
    return getSoknader(state).filter(soknad => visManglerBetalingsinfoForSoknad(soknad));
};

const STATUSER_FOR_MANGLER_BETALINGSINFO_VARSEL = [
    STATUS_VENTELISTE,
    STATUS_TILBUD_OM_VENTELISTEPLASS,
    STATUS_PAMELDT,
    STATUS_SOKNAD_REGISTRERT,
    STATUS_TILBUD,
    STATUS_TILBUD_AKSEPTERT,
    STATUS_MANGLER_DOKUMENTASJON
];

export const visManglerBetalingsinfoForSoknad = soknad => {
    if (!soknad.manglerBetalingsinfo) {
        return false;
    }
    const action = soknad.actions.find(a => a.type === ACTION_TYPE_ENDRE_FAKTURAINFO);
    return action && !fristHarUtgatt(action.frist) && STATUSER_FOR_MANGLER_BETALINGSINFO_VARSEL.includes(soknad.status);
};

export const getSoknaderMedBekreftetEksamen = state =>
    getSoknader(state).filter(soknad => Boolean(soknad.skalTaEksamen))
;

export const getAntallSoknaderSomManglerFakturainfo = state => getSoknaderSomManglerFakturainfo(state).length;

export default soknader;