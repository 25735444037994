import EditableField from "../../EditableField";
import React from "react";
import {telefonIconPaths} from "../../../utils/icons";
import {formatTelefonnummerMedLandskode} from "../../../utils/telefonUtil";
import PropTypes from "prop-types";
import KorrHelpButton from "../korr/KorrHelpButton";

const TelefonPresentation = ({typeTelefon, telefon, hentetFraKorr}) => {
    const {landskode, nummer} = telefon;
    return (
        <EditableField value={formatTelefonnummerMedLandskode(landskode, nummer)}
                       iconPath={telefonIconPaths[typeTelefon]}
                       valueNameId={typeTelefon}
                       justIconInHeader
        >
            {hentetFraKorr && <KorrHelpButton mobil={true}/>}
        </EditableField>
    )
};

TelefonPresentation.propTypes = {
    typeTelefon: PropTypes.string.isRequired,
    telefon: PropTypes.object.isRequired,
    hentetFraKorr: PropTypes.bool,
};

export default TelefonPresentation;