import * as dokumenterAPI from "../services/dokumenterAPI";
import * as gskAPI from "../services/gskAPI";
import {getEvuapiToken} from "./session";
import {getAktueltDeltakernr} from "./session";
import orderBy from "lodash/orderBy";
import {showPopup} from "./popup";
import {createErrorPopupContent} from "../utils/request";
import {knyttTilSoknad, resetTilknytningErrors} from "./soknadsdokumenter";
import React from "react";
import FeedbackErrors from "../components/FeedbackErrors";
import ErrorsUpload from "../components/steg-dokumentasjon/dokumenter-elementer/ErrorsUpload";
import ErrorsTilknytninger from "../components/steg-dokumentasjon/dokumenter-elementer/ErrorsTilknytninger";

export const NAME = 'dokumenter';
const LOAD_DOKUMENTER_PENDING = `${NAME}/LOAD_DOKUMENTER_PENDING`;
const LOAD_DOKUMENTER_SUCCESS = `${NAME}/LOAD_DOKUMENTER_SUCCESS`;
const LOAD_DOKUMENTER_FAILURE = `${NAME}/LOAD_DOKUMENTER_FAILURE`;
const UPLOAD_DOKUMENT_PENDING = `${NAME}/UPLOAD_DOKUMENT_PENDING`;
const UPLOAD_DOKUMENT_SUCCESS = `${NAME}/UPLOAD_DOKUMENT_SUCCESS`;
const UPLOAD_DOKUMENT_FAILURE = `${NAME}/UPLOAD_DOKUMENT_FAILURE`;
const CLEAR_UPLOAD = `${NAME}/CLEAR_UPLOAD`;

const CHECK_GSK_PENDING = `${NAME}/CHECK_GSK_PENDING`;
const CHECK_GSK_SUCCESS = `${NAME}/CHECK_GSK_SUCCESS`;
const CHECK_GSK_FAILURE = `${NAME}/CHECK_GSK_FAILURE`;

const DELETE_DOKUMENT_PENDING = `${NAME}/DELETE_DOKUMENT_PENDING`;
const DELETE_DOKUMENT_SUCCESS = `${NAME}/DELETE_DOKUMENT_SUCCESS`;
const DELETE_DOKUMENT_FAILURE = `${NAME}/DELETE_DOKUMENT_FAILURE`;

const SET_SPERRE_SLETTING = `${NAME}/SET_SPERRE_SLETTING`;

const SET_DOM_ELEMENT_TO_FOCUS = `${NAME}/SET_DOM_ELEMENT_TO_FOCUS`;


export function loadDokumenterPending() {
    return {
        type: LOAD_DOKUMENTER_PENDING,
    };
}

export function loadDokumenterSuccess(dokumenter) {
    return {
        type: LOAD_DOKUMENTER_SUCCESS,
        data: dokumenter,
    };
}


export function loadDokumenterFailure(error) {
    return {
        type: LOAD_DOKUMENTER_FAILURE,
        error: error
    };
}

export function loadDokumenter() {
    return function (dispatch, getState) {

        if (isLoading(getState())) {
            return;
        }

        dispatch(loadDokumenterPending());

        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());
        return dokumenterAPI.getDokumenter(deltakernr, evuapiToken).then(
            dokumenter => dispatch(loadDokumenterSuccess(dokumenter)),
            error => dispatch(loadDokumenterFailure(error))
        );
    };
}


export function deleteDokumentPending(id) {
    return {
        type: DELETE_DOKUMENT_PENDING,
        id
    };
}

export function deleteDokumentSuccess(id) {
    return {
        type: DELETE_DOKUMENT_SUCCESS,
        id,
    };
}


export function deleteDokumentFailure(id, error) {
    return {
        type: DELETE_DOKUMENT_FAILURE,
        id,
        error,
    };
}

export function slettDokument(id) {
    return function (dispatch, getState) {
        dispatch(deleteDokumentPending(id));

        const state = getState();
        const deltakernr = getAktueltDeltakernr(state);
        const evuapiToken = getEvuapiToken(state);

        return dokumenterAPI.deleteDokument(id, evuapiToken, deltakernr).then(
            () => dispatch(deleteDokumentSuccess(id)),
            error => {
                dispatch(showPopup(createErrorPopupContent(error)));
                dispatch(deleteDokumentFailure(id, error));
            }
        );

    };
}


export function checkGSKPending() {
    return {
        type: CHECK_GSK_PENDING,
    };
}

export function checkGSKSuccess(isRegistrert, vitnemal = []) {
    return {
        type: CHECK_GSK_SUCCESS,
        isRegistrert,
        vitnemal,
    };
}


export function checkGSKFailure(error) {
    return {
        type: CHECK_GSK_FAILURE,
        error: error
    };
}

export function checkGSK() {
    return function (dispatch, getState) {

        if (getGSKIsLoading(getState())) {
            return;
        }

        dispatch(checkGSKPending());

        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());
        return gskAPI.checkGSK(deltakernr, evuapiToken).then(
            ({harGsk, vitnemal}) => dispatch(checkGSKSuccess(harGsk, vitnemal)),
            error => dispatch(checkGSKFailure(error))
        );
    };
}


export function uploadDokumentPending(id, filnavn, type) {
    return {
        type: UPLOAD_DOKUMENT_PENDING,
        id,
        filnavn,
        typeDokument: type,
    };
}

export function uploadDokumentSuccess(id, dokument) {
    return {
        type: UPLOAD_DOKUMENT_SUCCESS,
        id: id,
        dokument: dokument,
    };
}

export function uploadDokumentFailure(id, error) {
    return {
        type: UPLOAD_DOKUMENT_FAILURE,
        id: id,
        error: error,
    };
}

export function clearUpload() {
    return {
        type: CLEAR_UPLOAD,
    };
}

export function uploadDokumenter(dokumenter, skalKnyttesTilSoknad, type) {
    return function (dispatch, getState) {

        if (!dokumenter.length) {
            return;
        }

        const state = getState();
        const deltakernr = getAktueltDeltakernr(state);
        const evuapiToken = getEvuapiToken(state);

        dispatch(clearUpload());
        dispatch(resetTilknytningErrors());

        dokumenter.forEach((file, index) => {
            dispatch(uploadDokumentPending(index, file.name, type));

            dokumenterAPI.uploadDokument(file, deltakernr, evuapiToken, type).then(
                savedDok => {
                    dispatch(uploadDokumentSuccess(index, savedDok));
                    if (skalKnyttesTilSoknad) {
                        dispatch(knyttTilSoknad(savedDok.id));
                    }
                },
                error => {
                    dispatch(uploadDokumentFailure(index, error));
                    dispatch(showPopup(createDokumentUploadErrorPopupContent()))
                }
            );
        });

    };
}

export function setSperreSletting(id, value) {
    return {
        type: SET_SPERRE_SLETTING,
        id,
        value
    };
}

export function setDomElementToFoucs(element) {
    return {
        type: SET_DOM_ELEMENT_TO_FOCUS,
        domElementToFocus: element
    }
}

function createDokumentUploadErrorPopupContent() {
    return {
        style: {wsError: true},
        children:
            <FeedbackErrors>
                <ErrorsUpload/>
                <ErrorsTilknytninger/>
            </FeedbackErrors>,
    };
}

export const initialState = {
    dokumentarkiv: {
        isLoading: false,
        isDataLoaded: false,
        error: undefined,
        data: [],
        deleted: [],
        underDeletion: [],
    },
    upload: {},
    gsk: {
        isLoading: false,
        error: undefined,
        isRegistrert: undefined,
        vitnemal: [],
    },
    domElementToFocus: undefined,
};


function dokumenter(state = initialState, action) {
    switch (action.type) {
        case LOAD_DOKUMENTER_PENDING:
        case LOAD_DOKUMENTER_SUCCESS:
        case LOAD_DOKUMENTER_FAILURE:
        case DELETE_DOKUMENT_PENDING:
        case DELETE_DOKUMENT_SUCCESS:
        case DELETE_DOKUMENT_FAILURE:
        case SET_SPERRE_SLETTING:
            return {
                ...state,
                dokumentarkiv: dokumentarkiv(state.dokumentarkiv, action),
            };
        case UPLOAD_DOKUMENT_PENDING:
        case UPLOAD_DOKUMENT_FAILURE:
        case CLEAR_UPLOAD:
            return {
                ...state,
                upload: upload(state.upload, action),
            };
        case UPLOAD_DOKUMENT_SUCCESS:
            return {
                ...state,
                upload: upload(state.upload, action),
                dokumentarkiv: dokumentarkiv(state.dokumentarkiv, action),
            };
        case CHECK_GSK_PENDING:
        case CHECK_GSK_SUCCESS:
        case CHECK_GSK_FAILURE:
            return {
                ...state,
                gsk: gsk(state.gsk, action),
            };
        case SET_DOM_ELEMENT_TO_FOCUS:
            return {
                ...state,
                domElementToFocus: action.domElementToFocus
            }
        default:
            return state
    }
}


function dokumentarkiv(state = initialState.dokumentarkiv, action) {

    switch (action.type) {
        case LOAD_DOKUMENTER_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_DOKUMENTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                data: [...action.data],
            };
        case LOAD_DOKUMENTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error
            };
        case UPLOAD_DOKUMENT_SUCCESS:
            return {
                ...state,
                data: [
                    action.dokument,
                    ...state.data,
                ],
            };
        case DELETE_DOKUMENT_PENDING:
            return {
                ...state,
                underDeletion: [
                    ...state.underDeletion,
                    action.id,
                ],
            };
        case DELETE_DOKUMENT_SUCCESS:
            return {
                ...state,
                underDeletion: state.underDeletion.filter(id => id !== action.id),
                deleted: [
                    ...state.deleted,
                    action.id,
                ]
            };
        case DELETE_DOKUMENT_FAILURE:
            return {
                ...state,
                underDeletion: state.underDeletion.filter(id => id !== action.id),
            };
        case SET_SPERRE_SLETTING:
            return {
                ...state,
                data: state.data.map(dokument => {
                    if (dokument.id === action.id) {
                        return {...dokument, sperreSletting: action.value}
                    }
                    return dokument;
                })
            };
        default:
            return state
    }

}

function upload(state = initialState.upload, action) {
    switch (action.type) {
        case CLEAR_UPLOAD:
            return {};
        case UPLOAD_DOKUMENT_PENDING:
            return {
                ...state,
                [action.id]: {
                    isUploading: true,
                    error: undefined,
                    filnavn: action.filnavn,
                    type: action.typeDokument
                }
            };
        case UPLOAD_DOKUMENT_SUCCESS:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    isUploading: false,
                }
            };
        case UPLOAD_DOKUMENT_FAILURE:
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    isUploading: false,
                    error: action.error,
                }
            };
        default:
            return state

    }
}


function gsk(state = initialState.gsk, action) {

    switch (action.type) {
        case CHECK_GSK_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case CHECK_GSK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isRegistrert: action.isRegistrert,
                vitnemal: [...action.vitnemal],
            };
        case CHECK_GSK_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state
    }
}

export function getState(state) {
    return state[NAME];
}

export function getDokumentarkiv(state) {
    return getState(state).dokumentarkiv;
}

export function getDokumenter(state) {
    return getDokumentarkiv(state).data;
}

export function getDokumenterByType(state, type) {
    const dokumenter = getDokumenter(state);
    return dokumenter.filter(dokument => dokument.type === type);
}

export function getDokumentById(state, id) {
    const dokumenter = getDokumenter(state);
    return dokumenter.find(dokument => dokument.id === id);
}


export function getSperreSlettingFraDokumentarkiv(state, id) {
    const dokument = getDokumentById(state, id);
    return dokument && dokument.sperreSletting;
}

export function isDataLoaded(state) {
    return getDokumentarkiv(state).isDataLoaded
}

export function isLoading(state) {
    return getDokumentarkiv(state).isLoading;
}

export function getError(state) {
    return getDokumentarkiv(state).error;
}

export function getIsUnderDeletion(state, id) {
    return getUnderDeletion(state).includes(id);
}

export function getUnderDeletion(state) {
    return getDokumentarkiv(state).underDeletion;
}

export function getIsDeleted(state, id) {
    return getDeleted(state).includes(id);
}

export function getDeleted(state) {
    return getDokumentarkiv(state).deleted;
}

export const getGSK = state => getState(state).gsk;
export const getGSKIsLoading = state => getGSK(state).isLoading;
export const getGSKError = state => getGSK(state).error;

export const isGskDataLoaded = state => getGSK(state).isRegistrert !== undefined;
export const isRegistrertGSK = state => getGSK(state).isRegistrert;

export const getVitnemal = state => getGSK(state).vitnemal;
export const finnesVitnemalMedGsk = state => getVitnemal(state).some(vitnemal => Boolean(vitnemal.isGskOk));

export const harGSK = state => finnesVitnemalMedGsk(state) || isRegistrertGSK(state);

export function getUpload(state) {
    return getState(state).upload;
}

export function getUploadSorted(state) {
    return orderBy(getUpload(state), ['error', 'isUploading'], ['asc', 'asc']) || [];
}

export function getUploadsMedFeil(state) {
    return getUploadSorted(state).filter(dokument => Boolean(dokument.error));
}

export function getUploadsSomLaster(state) {
    return getUploadSorted(state).filter(dokument => dokument.isUploading);
}

export function getIsUploadingByType(state, type) {
    return getUploadsSomLaster(state).some(dokument => dokument.type === type);
}

export function getUploadsSomErFerdigLastetOpp(state) {
    return getUploadSorted(state).filter(dokument => !dokument.isUploading && !dokument.error);
}

export function getDomElementToFocus(state) {
    return getState(state).domElementToFocus;
}

export function isAllUploadsSuccessful(state) {

    const uploadStates = Object.values(getUpload(state));
    if (!uploadStates.length) {
        return false;
    }

    const notSuccessful = uploadStates.filter(uploadState => uploadState.isUploading || uploadState.error);
    return !notSuccessful.length;
}


export function isAllUploadsDone(state) {

    const uploadStates = Object.values(getUpload(state));
    if (!uploadStates.length) {
        return false;
    }

    const pending = uploadStates.filter(uploadState => uploadState.isUploading);
    return !pending.length;
}

export default dokumenter;