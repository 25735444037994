import {useDispatch, useSelector} from "react-redux";
import {endreAktivtSteg, getForrigeRedigerbareSteg} from "../../../ducks/pamelding";
import Button from "../../../primitives/Button";
import React from "react";
import Text from "../../../primitives/Text";

const ButtonForrigeSteg = () => {
    const forrigeRedigerbareSteg = useSelector(getForrigeRedigerbareSteg);
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(endreAktivtSteg(forrigeRedigerbareSteg));
    };
    return (
        <Button
            onClick={onClick}
        >
            <Text id='forrige'/>
        </Button>
    )
};

export default ButtonForrigeSteg;