import React from 'react';
import {useSelector} from "react-redux";
import {getSkalTaEksamen} from "../../../ducks/eksamen";
import Text from "../../../primitives/Text";
import {mdiCancel, mdiCheck} from "@mdi/js";
import Icon from "../../../primitives/Icon";

const StegOversiktEksamen = () => {
    const skalTaEksamen = useSelector(getSkalTaEksamen);
    return (
        <div className='steg-oversikt__steg__eksamen'>
            {skalTaEksamen ? <Icon path={mdiCheck} className='skal-ta-eksamen'/> :
                <Icon path={mdiCancel} className='skal-ikke-ta-eksamen'/>}
            <Text id={skalTaEksamen ? 'skal_ta_eksamen' : 'skal_ikke_ta_eksamen'}/>
        </div>
    )
};

export default StegOversiktEksamen;