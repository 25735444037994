import React from 'react';
import {useSelector} from "react-redux";
import Card from "../Card";
import {foretaksgodkjenningIconPath} from "../../../utils/icons";
import {pages} from "../../../utils/common";
import {compose} from "redux";
import requireBedriftsbruker from "../../../high-order-components/requireBedriftsbruker";
import withForetakLoaded from "../../../high-order-components/withForetakLoaded";
import {
    getAntallSoknaderTotalt,
    getAntallUbesvarteSoknaderTotalt,
    getTidligsteGodkjenningsfristForUbesvarteSoknader
} from "../../../ducks/foretaksgodkjenning";
import Text from "../../../primitives/Text";
import FormattedDate from "../../../primitives/FormattedDate";

const VarselSoknaderTilGodkjenning = () => {
    const antallSoknaderTotalt = useSelector(getAntallSoknaderTotalt);
    const antallUbesvarteSoknaderTotalt = useSelector(getAntallUbesvarteSoknaderTotalt);
    const tidligsteGodkjenningsfrist = useSelector(getTidligsteGodkjenningsfristForUbesvarteSoknader);
    return (
        <Card
            tittel={<Text id='foretaksgodkjenning'/>}
            varsel={true}
            iconPath={foretaksgodkjenningIconPath}
            actions={{
                lenke: {
                    tekst: <Text id='gå_til_godkjenning'/>,
                    til: pages.foretaksgodkjenning
                },
            }}
        >
            <Text
                id='du_har_ubehandlede_soknader'
                antallSoknader={antallSoknaderTotalt}
            />
            {antallUbesvarteSoknaderTotalt > 0 &&
                <>
                    <Text
                        id='n_av_disse_må_behandles_av_deg'
                        antallUbesvarteSoknader={antallUbesvarteSoknaderTotalt}
                    />
                    <Text
                        frist={<FormattedDate date={tidligsteGodkjenningsfrist} withTime={true} />}
                        id={'godkjenningsfrist'}
                    />
                </>
            }
        </Card>
    )
};

export default compose(
    Component => requireBedriftsbruker(Component, false),
    withForetakLoaded,
)(VarselSoknaderTilGodkjenning);