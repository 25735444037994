import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {
    getEksamenFilter,
    getFritekstSok,
    getNettbasertFilter,
    setEksamenFilter,
    setFritekstSok,
    toggleNettFilter,
    toggleSamlingFilter,
    getSamlingFilter,
    getValgteKategorier,
    setKategorier,
    getUtgatteKursFilter,
    toggleUtgatteKursFilter
} from '../../../ducks/kursoversikt'
import {SECONDARY} from "../../../primitives/Button";
import {ShowMoreButton} from "../../ShowMoreButton";
import {pages} from "../../../utils/common";
import * as queryString from "query-string/index";
import {setLocation} from "../../../utils/location";
import FilterEksamen from "./FilterEksamen";
import FilterUndervisning from "./FilterUndervisning";
import FilterKategorier from "./FilterKategorier";
import FilterFritekst from "./FilterFritekst";
import {getInstitusjonsAkronym} from "../../../ducks/session";
import FilterUtgatteKurs from "./FilterUtgatteKurs";
import Text from "../../../primitives/Text";
import {useLocation} from "react-router";

const Filtre = () => {
    const [extended, setExtended] = useState(false);

    const fritekstSok = useSelector(getFritekstSok);
    const eksamenFilter = useSelector(getEksamenFilter);
    const nettbasertFilter = useSelector(getNettbasertFilter);
    const samlingbasertFilter = useSelector(getSamlingFilter);
    const valgteKategorier = useSelector(getValgteKategorier);
    const visUtgatteKurs = useSelector(getUtgatteKursFilter);
    const inst = useSelector(getInstitusjonsAkronym);
    const dispatch = useDispatch();

    const location = useLocation();

    useEffect(() => {
        const {fritekst, eksamen, nettbasert, samlingbasert, utgatt, kategorier} = queryString.parse(location.search);
        fritekst && dispatch(setFritekstSok(fritekst));
        eksamen && dispatch(setEksamenFilter(eksamen));
        nettbasert && dispatch(toggleNettFilter());
        samlingbasert && dispatch(toggleSamlingFilter());
        utgatt && dispatch(toggleUtgatteKursFilter());
        kategorier && dispatch(setKategorier(kategorier.split(',')));
    }, [dispatch, location]);

    useEffect(() => {
        const params = {
            ...(fritekstSok && {fritekst: fritekstSok}),
            ...(eksamenFilter && {eksamen: eksamenFilter}),
            ...(nettbasertFilter && {nettbasert: nettbasertFilter}),
            ...(samlingbasertFilter && {samlingbasert: samlingbasertFilter}),
            ...(visUtgatteKurs && {utgatt: visUtgatteKurs}),
            ...(valgteKategorier.length > 0 && {kategorier: valgteKategorier.join(',')}),
            inst,
        };
        setLocation(pages.kurs, params);
    }, [fritekstSok, eksamenFilter, nettbasertFilter, samlingbasertFilter, valgteKategorier, visUtgatteKurs, inst]);

    return (
        <div className="filtre">
            <FilterFritekst/>
            <div className={`filtre__ekstra ${extended ? `extended` : `collapsed`}`}>
                <FilterEksamen/>
                <FilterUndervisning/>
                <FilterKategorier/>
                <FilterUtgatteKurs/>
            </div>
            <div className="filtre__toggler">
                <ShowMoreButton
                    onClick={() => setExtended(!extended)}
                    extended={extended}
                    showMoreText={<Text id={'flere_filter'}/>}
                    showLessText={<Text id={'færre_filter'}/>}
                    emphasis={SECONDARY}
                />
            </div>
        </div>
    );
};

export default Filtre;