import React from 'react';
import {
    getAntallSoknaderSomVenterPaSvar,
    getEarliestSvarFrist,
    getSoknaderSomVenterPaSvar,
    resettSoknadAction,
    sendSvarPaTilbud
} from "../../../ducks/soknader";
import {useDispatch, useSelector} from "react-redux";
import Card from "../Card";
import {soknaderIconPath} from "../../../utils/icons";
import withSoknaderLoaded from "../../../high-order-components/withSoknaderLoaded";
import FormattedDate from "../../../primitives/FormattedDate";
import Button, {PRIMARY} from "../../../primitives/Button";
import {showPopup} from "../../../ducks/popup";
import {compose} from "redux";
import {requireDeltakernummer} from "../../../high-order-components/requireDeltakernummer";
import Text from "../../../primitives/Text";
import {getFirstOf, pages} from "../../../utils/common";
import SvarPaTilbud from "../../soknader/SvarPaTilbud";
import OKButtonWithFeedback from "../../soknader/OKButtonWithFeedback";

const VarselSoknaderVenterPaSvar = () => {

    const soknaderSomVenterPaSvar = useSelector(getSoknaderSomVenterPaSvar);
    const antallSoknaderSomVenterPaSvar = useSelector(getAntallSoknaderSomVenterPaSvar);

    const earliestSvarFrist = useSelector(getEarliestSvarFrist);
    if (antallSoknaderSomVenterPaSvar === 0) return null;

    const {kursnavn} = getFirstOf(soknaderSomVenterPaSvar);
    const textId = antallSoknaderSomVenterPaSvar === 1 ? 'din_søknad_på_kursnavn_venter_på_svar' : 'du_har_søknader_som_venter_pa_svar';

    return (
        <Card
            tittel={<Text id='søknad_venter_på_svar'/>}
            varsel={true}
            iconPath={soknaderIconPath}
            actions={{
                lenke: antallSoknaderSomVenterPaSvar > 1 ? {
                    tekst: <Text id='gå_til_søknader'/>,
                    til: pages.soknader
                } : null,
                button: antallSoknaderSomVenterPaSvar === 1 ? <SvarPaTilbudButton/> : null,
            }}
        >
            <div>
                <Text
                    id={textId}
                    antallSoknader={antallSoknaderSomVenterPaSvar}
                    kursnavn={kursnavn}
                />
            </div>
            {earliestSvarFrist &&
            <div>
                <Text
                    id='svarfristen_er'
                    frist={<FormattedDate date={earliestSvarFrist} withTime={true}/>}
                />
            </div>
            }
        </Card>
    )
};

const SvarPaTilbudButton = () => {

    const soknaderSomVenterPaSvar = useSelector(getSoknaderSomVenterPaSvar);
    const kursId =  soknaderSomVenterPaSvar.length && soknaderSomVenterPaSvar[0].id;
    const dispatch = useDispatch();


    const makePopupContent = () => ({
        title: <Text id='svar_pa_tilbud'/>,
        customOKButton: <OKButtonWithFeedback onClick={() => dispatch(sendSvarPaTilbud(kursId))}/>,
        onCancel: () => dispatch(resettSoknadAction()),
        preventCloseOnOK: true,
        children: <SvarPaTilbud kursId={kursId}/>,
    });

    return (
        <Button
            emphasis={PRIMARY}
            onClick={() => dispatch(showPopup(makePopupContent()))}
        >
            <Text id='svar_på_tilbud'/>
        </Button>
    )
};

export default compose(
    Component => requireDeltakernummer(Component, false),
    withSoknaderLoaded,
)(VarselSoknaderVenterPaSvar);