import {SOKNAD, SOKNADER, PAMELDING, PAMELDING_STEG_STATUS, FULLFOR_SOKNAD} from "./apiRoutes";
import axios from "axios/index";
import {
    fristHarUtgatt,
    kursIdToUrlString,
    nullToUndefined,
    parseIsoDate,
} from "../utils/common";

import {
    ACTION_TYPE_DOKUMENTASJON,
    ACTION_TYPE_ENDRE, ACTION_TYPE_ENDRE_FAKTURAINFO,
    actionToFristTypes,
} from "../utils/soknadStatus";
import {
    STEG_TYPE_SAMTYKKE,
    STEG_TYPE_DOKUMENTASJON,
    STEG_TYPE_OVERSIKT,
    STEG_TYPE_PROFIL, STEG_TYPE_FAKTURAINFO
} from "../components/pamelding/steg/Steg";
import {STATUS_IKKE_FULLFORT, STATUS_MANGLER_DOKUMENTASJON, STATUS_SOKNAD_REGISTRERT} from "../utils/soknadStatus";
import {isNotFound404, ApiError, createRequestConfig} from "../utils/request";
import {createFrist} from "../ducks/pamelding";
import {transformBetalingsinfoFromWS} from "./betalingsinfoAPI";
import {transformSkalTaEksamenFromWS} from "./eksamenAPI";

export const getPameldingsdata = async (evuapiToken, instnr, kursIdObject, deltakernr, skrivingSkalBlokkeres, foretakkode, hash) => {

    const config = createRequestConfig(evuapiToken);

    const hentOgLeggTilPameldingssteg = async (responseSoknad, isNySoknad) => {
        const responsePamelding = await getPameldingssteg(deltakernr, config, instnr, kursIdObject);
        return transformOutput(responseSoknad.data, responsePamelding.data, isNySoknad);
    };

    try {
        const route = SOKNAD
            .replace(':kursId', kursIdToUrlString(kursIdObject))
            .replace(':deltakernr', deltakernr);
        const responseSoknad = await axios.get(route, config);
        return await hentOgLeggTilPameldingssteg(responseSoknad);
    } catch (error) {
        if (skrivingSkalBlokkeres) {
            throw new ApiError('4010001');
        }
        if (isNotFound404(error)) {
            const responseSoknad = await postSoknad(config, instnr, kursIdObject, deltakernr, foretakkode, hash);
            return await hentOgLeggTilPameldingssteg(responseSoknad, true);
        }
        throw error;
    }
};


const transformOutput = (soknad, pameldingssteg, isNySoknad = false) => ({
    isNySoknad,
    status: soknad.status,
    foretakkode: nullToUndefined(soknad.foretakkode),
    foretaknavn: nullToUndefined(soknad.foretaknavn),
    antallLedigePlasser: nullToUndefined(soknad.antallLedigePlasser),
    skalTaEksamen: transformSkalTaEksamenFromWS(soknad),
    skalGodkjennes: nullToUndefined(soknad.statusGodkjenning),
    frister: transformFristerFromWS(soknad.frister),
    kanFullfores: endrefristErIkkeUtgatt(soknad.frister),
    betalingsinfo: transformBetalingsinfoFromWS(soknad),
    steg: pameldingssteg.map((steg, id) =>
        transformPameldingStegFromWS(steg, id, soknad.status, soknad.frister)
    )
});


const postSoknad = async (config, instnr, kursIdObject, deltakernr, foretakkode, hash) => {

    const route = SOKNADER.replace(':deltakernr', deltakernr);

    const payload = {
        kurskode: kursIdObject.kurskode,
        tidskode: kursIdObject.tidskode,
        registreringsformkode: "EVUWEB"
    };

    if (foretakkode && hash) {
        payload.foretakkode = foretakkode;
        payload.hash = hash;
    }

    return axios.post(route, payload, config);

};

const getPameldingssteg = async (deltakernr, config, instnr, kursIdObject) => {

    const route = PAMELDING
        .replace(':kursId', kursIdToUrlString(kursIdObject))
        .replace(':deltakernr', deltakernr)
        .replace(':instnr', instnr);

    return axios.get(route, config);
};

export const postFullfortSteg = async (evuapiToken, kursId, deltakernr, stegkode) => {

    const route = PAMELDING_STEG_STATUS
        .replace(':kursId', kursId)
        .replace(':deltakernr', deltakernr)
        .replace(':stegkode', '');

    const config = {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        }
    };

    const payload = {
        stegkode,
    };

    return axios.post(route, payload, config).then().catch();
};

export const deleteFullfortSteg = async (evuapiToken, kursId, deltakernr, stegkode) => {

    const route = PAMELDING_STEG_STATUS
        .replace(':kursId', kursId)
        .replace(':deltakernr', deltakernr)
        .replace(':stegkode', stegkode);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        }
    };

    return axios.delete(route, config).then().catch();
};

const endrefristErIkkeUtgatt = (frister = []) =>
    frister.some(({type, dato}) =>
        type === actionToFristTypes[ACTION_TYPE_ENDRE] && parseIsoDate(dato) > Date.now()
    );

function transformPameldingStegFromWS(steg, id, status, frister) {
    const type = steg.stegkode || steg.type; //TODO: fjerne type når ws-en fikser det
    const frist = getRelevantFrist(type, frister);
    return {
        id: id,
        sekvensnr: steg.sekvensnr,
        valgfritt: steg.valgfritt,
        redigerbart: isStegRedigerbart(type, frist, status),
        fullfort: Boolean(steg.datoFullfort),
        type,
    }
}

const transformFristerFromWS = frister => frister.map(frist => createFrist({
    type: frist.type,
    dato: parseIsoDate(frist.dato),
}));

function getRelevantFrist(type, frister) {
    switch (type) {
        case STEG_TYPE_DOKUMENTASJON:
            return frister.find(frist =>
                frist.type === actionToFristTypes[ACTION_TYPE_DOKUMENTASJON]
            );
        case STEG_TYPE_FAKTURAINFO:
            return frister.find(frist =>
                frist.type === actionToFristTypes[ACTION_TYPE_ENDRE_FAKTURAINFO]
            );
        default:
            return frister.find(frist =>
                frist.type === actionToFristTypes[ACTION_TYPE_ENDRE]
            );

    }
}

function isStegRedigerbarUansettStatus(type) {
    const STEG_TYPER_ALLTID_REDIGERBARE = [
        STEG_TYPE_OVERSIKT,
        STEG_TYPE_PROFIL,
        STEG_TYPE_SAMTYKKE,
    ];
    return STEG_TYPER_ALLTID_REDIGERBARE.includes(type);
}

function isSoknadStengtForRedigering(status) {
    const REDIGERBARE_STATUSER = [
        STATUS_IKKE_FULLFORT,
        STATUS_SOKNAD_REGISTRERT,
        STATUS_MANGLER_DOKUMENTASJON
    ];
    return !REDIGERBARE_STATUSER.includes(status);
}

export function isStegRedigerbart(type, frist, status) {

    if (isStegRedigerbarUansettStatus(type)) {
        return true;
    }

    const fristDato = parseIsoDate(frist.dato);
    if (type === STEG_TYPE_FAKTURAINFO) {
        return !fristHarUtgatt(fristDato);
    }

    if (isSoknadStengtForRedigering(status)) {
        return false;
    }

    return !fristHarUtgatt(fristDato);
}

export const fullforSoknad = async (evuapiToken, deltakernr, kursId) => {

    const config = createRequestConfig(evuapiToken);

    const route = FULLFOR_SOKNAD
        .replace(':kursId', kursId)
        .replace(':deltakernr', deltakernr);

    const response = await axios.post(route, undefined, config);
    return response.data && response.data.status;
};