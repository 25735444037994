import React from 'react';
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import withInstitusjonerLoaded from "../../high-order-components/withInstitusjonerLoaded";
import {getInstitusjonByAkronym, getInstitusjonerSortedByLabel} from "../../ducks/institusjoner";
import './institusjon.scss'
import ReactSelect from "react-select";
import {pages} from "../../utils/common";
import PageTitle from "../PageTitle";
import {getRedirectionAfterSelectedInstitutionPath, setInstitusjon,} from "../../ducks/session";
import {compose} from "redux";
import Text from "../../primitives/Text";
import {useNavigate} from "react-router";


export const Institusjon = (props) => {

    const navigate = useNavigate();

    const onChange = selectedOption => {
        const {getInstitusjonByAkronym, setInstitusjon, redirectPath} = props;
        const institusjon = getInstitusjonByAkronym(selectedOption.value);
        setInstitusjon(institusjon);
        navigate(redirectPath ? redirectPath : pages.kurs);
    };


    const {institusjoner} = props;
    const id = 'institusjon';

    const style = {
        control: (base) => ({
            ...base,
            borderColor: '#707070',
            '&:hover': {borderColor: '#888888'}
        }),
        dropdownIndicator: base => ({
            ...base,
            'color': '#707070',
            '&:hover': {color: '#888888'}
        })
    }

    return (
        <div className='institusjon'>
            <PageTitle id='velg_institusjon' />
            <h1>
                <label htmlFor={id}>
                    <Text id='velg_institusjon' />
                </label>
            </h1>
            <ReactSelect
                inputId={id}
                aria-labelledby={id}
                options={institusjoner}
                onChange={onChange}
                placeholder={<Text id='velg_placeholder' />}
                classNamePrefix='react-select'
                noOptionsMessage={() => <Text id='ingen_treff' />}
                styles={style}
            />
        </div>

    )
}

Institusjon.propTypes = {
    institusjoner: PropTypes.arrayOf(
        PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
            }
        )).isRequired,
    setInstitusjon: PropTypes.func.isRequired,
    getInstitusjonByAkronym: PropTypes.func.isRequired,

};


const mapStateToProps = state => {
    return {
        institusjoner: getInstitusjonerSortedByLabel(state),
        redirectPath: getRedirectionAfterSelectedInstitutionPath(state),
        getInstitusjonByAkronym: (akronym) => getInstitusjonByAkronym(state, akronym),
    }
};

const mapDispatchToProps = {
    setInstitusjon,
};

export default compose(
    withInstitusjonerLoaded,
    connect(mapStateToProps, mapDispatchToProps)
)(Institusjon);