import {loadAppegenskaper} from "./appegenskaper";
import {loadApptekster} from "./apptekster";
import {loadAPIInfo} from "./apiInfo";


export function initialLoad() {
    return function (dispatch) {
        dispatch(loadAppegenskaper());
        dispatch(loadApptekster());
        dispatch(loadAPIInfo());
    };
}