import {useSelector} from "react-redux";
import {getArbeidserfaringerSorted} from "../../ducks/arbeidserfaring";
import Feedback from "../Feedback";
import Text from "../../primitives/Text";
import React from "react";
import withArbeidserfaringLoaded from "../../high-order-components/withArbeidserfaringLoaded";
import ArbeidserfaringPresentation from "../arbeidserfaring/ArbeidserfaringPresentation";
import ArbeidserfaringListItem from "../arbeidserfaring/ArbeidserfaringListItem";

const ArbeidserfaringKategori = ({id}) => {
    const arbeidserfaringer = useSelector(getArbeidserfaringerSorted);
    if (!arbeidserfaringer.length) {
        return (
            <Feedback id={id} aria-labelledby={'tab_' + id}>
                <Text id='du_har_ingen_tidligere_brukt_arbeidserfaringer'/>
            </Feedback>
        )
    }
    return (
        <div id={id} className='dokumentasjon-side__arbeidserfaringer' aria-labelledby={'tab_' + id}>
            <ol>
                {arbeidserfaringer.map(arbeidserfaring =>
                    <ArbeidserfaringListItem key={arbeidserfaring.id}>
                        <ArbeidserfaringPresentation arbeidserfaring={arbeidserfaring}/>
                    </ArbeidserfaringListItem>
                )}
            </ol>
        </div>
    )
};


export default withArbeidserfaringLoaded(ArbeidserfaringKategori);