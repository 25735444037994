import React from 'react';
import Text from "../../../primitives/Text";

import './soknader-table.scss';

export const SoknaderTable = ({children, harGodkjenning}) => {
    return (
        <table>
            <thead>
            <tr>
                {harGodkjenning &&
                <th className='godkjenning'>
                    <Text id={'godkjenning'}/>
                </th>
                }
                <th className='soker'>
                    <Text id={'søker'}/>
                </th>
                <th className='status'>
                    <Text id={'status'}/>
                </th>
                <th className='alle-felt'>
                    <Text id={'søker'}/>
                </th>
            </tr>
            </thead>
            <tbody>
            {children}
            </tbody>
        </table>
    )
};

export default SoknaderTable;