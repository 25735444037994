import React from "react";
import {Link} from "react-router-dom";
import {pages} from "../../utils/common";
import requireKurskatalogAktivert from "../../high-order-components/requireKurskatalogAktivert";
import Text from "../../primitives/Text";
import Icon from "../../primitives/Icon";
import {mdiViewList} from "@mdi/js";

const MenubarKursoversikt = () => {
    return (
        <div className='menubar-button'>
            <Link to={pages.kurs}>
                <Icon path={mdiViewList}/>
                <Text id="kursoversikt"/>
            </Link>
        </div>
    )
};

export default requireKurskatalogAktivert(MenubarKursoversikt);
