import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {closePopup, getShowPopup, getPopupContent} from "../ducks/popup";
import FocusLock from 'react-focus-lock'
import Button, {PRIMARY, TERTIARY} from "../primitives/Button";
import OnKeyDownCatcher from "./OnKeyDownCatcher";
import Text from "../primitives/Text";
import {mdiClose} from "@mdi/js";

const Popup = () => {

    const dispatch = useDispatch();
    const show = useSelector(getShowPopup);
    const {
        style = {},
        onOK,
        onCancel,
        preventCloseOnOK = false,
        cancelButtonDanger = false,
        title = <Text id='advarsel'/>,
        cancelButtonContent = <Text id='avbryt'/>,
        okButtonContent = <Text id={onOK ? 'ok' : 'lukk'}/>,
        customOKButton,
        children,
    } = useSelector(getPopupContent);

    if (!show) {
        return null;
    }

    const onOKClick = () => {
        onOK && onOK();
        !preventCloseOnOK && dispatch(closePopup());
    };

    const onCancelClick = () => {
        onCancel && onCancel();
        dispatch(closePopup());
    };

    const {wide = false, wsError = false, wrapLongHeader = true,} = style;
    const modalClassName = `modal ${wide && 'wide'} ${wsError && 'ws-error'}`;

    const visAvbrytknapp = Boolean(onOK || onCancel || customOKButton);

    return (
        <div>
            <div
                className='modal-overlay'
                onClick={onCancelClick}
                role='presentation'
            />
            <FocusLock autoFocus={false} returnFocus={true}>
                <OnKeyDownCatcher onEsc={onCancelClick}>
                    <div className={modalClassName} role='dialog' aria-labelledby='popup-title'>
                        <div className={`modal-header ${wrapLongHeader && 'wrap-long-header'}`}>
                            <h2 id='popup-title'>
                                {title}
                            </h2>
                            <Button
                                onClick={onCancelClick}
                                iconPath={mdiClose}
                                altText='lukk'
                            />
                        </div>
                        <div className='modal-content'>
                            {children}
                        </div>
                        <div className='modal-footer'>

                            {visAvbrytknapp &&
                            <Button
                                onClick={onCancelClick}
                                emphasis={TERTIARY}
                                danger={cancelButtonDanger}
                            >
                                {cancelButtonContent}
                            </Button>
                            }

                            {customOKButton ||
                            <Button
                                onClick={onOKClick}
                                emphasis={PRIMARY}
                            >
                                {okButtonContent}
                            </Button>
                            }

                        </div>
                    </div>
                </OnKeyDownCatcher>
            </FocusLock>
        </div>
    )
};

export default Popup;