import {useSelector} from "react-redux";
import {getSamtykkerOversatt} from "../../ducks/samtykke";
import React from "react";
import Samtykke from "./Samtykke";
import withSamtykkerLoaded from "../../high-order-components/withSamtykkerLoaded";

const SamtykkerListe = () => {
    const samtykker = useSelector(getSamtykkerOversatt);
    return samtykker.map(samtykke =>
        <Samtykke
            key={samtykke.kode}
            kode={samtykke.kode}
            sporsmal={samtykke.sporsmal}
            langSporsmal={samtykke.langSporsmal}
            undertekst={samtykke.undertekst}
            langUndertekst={samtykke.langUndertekst}
            besvarelse={samtykke.besvarelse}
            isLoading={samtykke.isLoading}
            error={samtykke.error}
        />
    )
};

export default withSamtykkerLoaded(SamtykkerListe);