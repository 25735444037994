import {useSelector} from "react-redux";
import {getErrorOpprett, getIsEpostFraKorr, isOpprettingSuksessfull} from "../../ducks/nyBruker";
import WSError from "../WSError";
import Feedback, {FEEDBACK_TYPE_OK} from "../Feedback";
import React from "react";
import {isEpostRelatertApiError} from "../../utils/epostUtil";
import Text from "../../primitives/Text";

const FeedbackOppretting = () => {

    const success = useSelector(isOpprettingSuksessfull);
    const error = useSelector(getErrorOpprett);
    const epostFraKorr = useSelector(getIsEpostFraKorr);
    const showError = error && (!isEpostRelatertApiError(error) || epostFraKorr);

    if (showError) {
        return <WSError error={error}/>;
    }

    if (success) {
        return (
            <Feedback type={FEEDBACK_TYPE_OK}>
                <Text id='bruker_opprettet'/>
            </Feedback>
        )
    }

    return null;
};

export default FeedbackOppretting;