import OnClickOutsideCatcher from "../OnClickOutsideCatcher";
import React from "react";
import OnKeyDownCatcher from "../OnKeyDownCatcher";
import PropTypes from "prop-types";

const FormResetAndClickOutsideControl = ({children, closeEditing, resetChanges, className}) => (
    <OnClickOutsideCatcher onCatch={closeEditing} className={className}>
        <OnKeyDownCatcher
            onEsc={() => {
                resetChanges && resetChanges();
                closeEditing();
            }}
        >
            {children}
        </OnKeyDownCatcher>
    </OnClickOutsideCatcher>
);


FormResetAndClickOutsideControl.propTypes = {
    children: PropTypes.node.isRequired,
    resetChanges: PropTypes.func,
    closeEditing: PropTypes.func.isRequired,
    className: PropTypes.string,
};


export default FormResetAndClickOutsideControl;