import React from "react";
import {
    getSoknadActionError, getSoknadById,
} from "../../ducks/soknader";
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import './trekk-soknad.scss';
import {useSelector} from "react-redux";
import Text from "../../primitives/Text";
import FeedbackWSError from "../FeedbackWSError";

const TrekkSoknad = ({textId, kursId}) => {
    const soknadActionError = useSelector(state => getSoknadActionError(state));
    const {antallLedigePlasser, kursnavn} = useSelector(state => getSoknadById(state, kursId));
    return (
        <div className='trekk-soknad'>
            {antallLedigePlasser !== undefined && antallLedigePlasser <= 0 &&
            <Feedback type={FEEDBACK_TYPE_VARSEL} className='kurset-er-fult-varsel'>
                <Text id={'kurset_er_fulltegnet_det_er_mulig_du_ikke_får_angret_handlingen'} />
            </Feedback>
            }
             <Text
                id={textId}
                values={{kursnavn: <em>{kursnavn}</em>}}
            />
            <FeedbackWSError error={soknadActionError}/>
        </div>
    );
};

export default TrekkSoknad;