import {useDispatch, useSelector} from "react-redux";
import {
    changeFormField,
    getEtternavn,
    getFornavn,
    getForsoktOpprett, getFulltNavn,
    getKanRedigerePersoninfo
} from "../../ducks/nyBruker";
import Input from "../../primitives/Input";
import {MAX_LENGDE_ETTERNAVN, MAX_LENGDE_FORNAVN} from "../../utils/common";
import Error from "../Error";
import ReadOnlyField from "../ReadOnlyField";
import React from "react";
import Text from "../../primitives/Text";
import {personIconPath} from "../../utils/icons";
import HelpButton from "./HelpButton";

export const Navn = () => {
    const fulltNavn = useSelector(getFulltNavn);
    const kanRedigerePersoninfo = useSelector(getKanRedigerePersoninfo);

    if (kanRedigerePersoninfo)
        return <InputFornavnOgEtternavn/>

    return (
        <div className='info-with-button-wrapper'>
            <ReadOnlyField
                iconPath={personIconPath}
                title='navn'
                value={fulltNavn}
            />
            <HelpButton/>
        </div>
    )
};


const InputFornavnOgEtternavn = () => {
    const fornavn = useSelector(getFornavn);
    const etternavn = useSelector(getEtternavn);
    const forsoktOpprett = useSelector(getForsoktOpprett);
    const fornavnHasError = forsoktOpprett && !fornavn;
    const etternavnHasError = forsoktOpprett && !etternavn;
    const dispatch = useDispatch();
    const onChange = (event, felt) => dispatch(changeFormField(felt, event.target.value));

    return (
        <div className='navn'>
            <div className='navn__fornavn'>
                <Input
                    label='fornavn'
                    iconPathForLabel={personIconPath}
                    onChange={event => onChange(event, 'fornavn')}
                    hasError={fornavnHasError}
                    value={fornavn}
                    autoComplete='given-name'
                    maxLength={MAX_LENGDE_FORNAVN}
                />
                {fornavnHasError &&
                <Error>
                    <Text id={'fornavn_obligatorisk'}/>
                </Error>
                }
            </div>

            <div className='navn__etternavn'>
                <Input
                    label='etternavn'
                    onChange={event => onChange(event, 'etternavn')}
                    hasError={etternavnHasError}
                    value={etternavn}
                    autoComplete='family-name'
                    maxLength={MAX_LENGDE_ETTERNAVN}
                />
                {etternavnHasError &&
                <Error>
                    <Text id={'etternavn_obligatorisk'}/>
                </Error>
                }
            </div>
        </div>
    )
}

export default Navn;