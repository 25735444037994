import React from "react";
import Landskode from "../profil/Landskode";
import InputTelefonNummer from "../profil/telefoner/InputTelefonNummer";
import {
    changeTelefonland,
    changeTelefonnummer,
    getFormErrors,
    getForsoktOpprett,
    getTelefon, isMobilFraKorr,
} from "../../ducks/nyBruker";
import {useDispatch, useSelector} from "react-redux";
import ReadOnlyField from "../ReadOnlyField";
import {telefonIconPaths} from "../../utils/icons";
import KorrHelpButton from "../profil/korr/KorrHelpButton";
import {formatTelefonnummerMedLandskode, TYPE_TELEFON_MOBIL} from "../../utils/telefonUtil";
import Text from "../../primitives/Text";


export const Telefon = () => {
    const telefon = useSelector(getTelefon);
    const telefonError = useSelector(getFormErrors).telefon;
    const forsoktOpprett = useSelector(getForsoktOpprett);
    const mobilFraKorr = useSelector(isMobilFraKorr);
    const dispatch = useDispatch();

    const {nummer, landskode, landnummer} = telefon || {};

    return (
        <div className='flat-card'>
            <h2><Text id='mobiltelefon'/></h2>
            <div className='content mobiltelefon'>
                {mobilFraKorr ?
                    <div className='info-with-button-wrapper'>
                        <ReadOnlyField value={formatTelefonnummerMedLandskode(landskode, nummer)}
                                       iconPath={telefonIconPaths[TYPE_TELEFON_MOBIL]}
                                       altText={TYPE_TELEFON_MOBIL}
                        />
                        <KorrHelpButton mobil={true}/>
                    </div>
                    :
                    <>
                        <Landskode landskode={landskode}
                                   landnummer={landnummer}
                                   onChange={selectedOption => dispatch(changeTelefonland(selectedOption.value))}
                        />
                        <InputTelefonNummer
                            nummer={nummer}
                            errorMessageId={telefonError}
                            onChange={event => dispatch(changeTelefonnummer(event.target.value))}
                            typingDone={forsoktOpprett}
                        />
                    </>
                }
            </div>
        </div>
    )
};


export default Telefon;
