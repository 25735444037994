import Feedback, {FEEDBACK_TYPE_OK} from "../Feedback";
import {Link} from "react-router-dom";
import {pages} from "../../utils/common";
import React from "react";
import {useSelector} from "react-redux";
import {
    getDirektePamelding,
    getKursIdObject,
    getKursnavn
} from "../../ducks/kursinfo";
import {getStatus} from "../../ducks/pamelding";
import {
    STATUS_PAMELDT, STATUS_SENDT_TIL_GODKJENNING,
    STATUS_SOKNAD_REGISTRERT,
    STATUS_TILBUD,
    STATUS_TILBUD_OM_VENTELISTEPLASS,
    STATUS_VENTELISTE
} from "../../utils/soknadStatus";
import Text from "../../primitives/Text";

const PameldingRegistrert = () => {
    const kursnavn = useSelector(getKursnavn);
    const kursId = useSelector(getKursIdObject);
    const direktePamelding = useSelector(getDirektePamelding);
    const nyStatus = useSelector(getStatus);

    return (
        <div className='pamelding__opprettet'>
            <Feedback type={FEEDBACK_TYPE_OK}>
                <Text
                    id={getTextId(nyStatus, direktePamelding)}
                    values={{kursnavn: kursnavn, kurskode: kursId.kurskode}}
                />
            </Feedback>
            <Link className="link" to={pages.soknader}>
                <Text id='gå_til_siden_for_mine_søknader'/>
            </Link>
        </div>
    )
};

const getTextId = (nyStatus, direktePamelding) => {
    switch (nyStatus) {
        case STATUS_SOKNAD_REGISTRERT:
            return 'søknad_er_registrert_på_kurs';
        case STATUS_PAMELDT:
            return 'du_er_nå_påmeldt_til_kurs';
        case STATUS_VENTELISTE:
            return 'du_er_nå_satt_på_venteliste_til_kurs';
        case STATUS_TILBUD:
            return 'søknad_er_registrert_og_du_har_fått_tilbud';
        case STATUS_TILBUD_OM_VENTELISTEPLASS:
            return 'søknad_er_registrert_og_du_har_fått_tilbud_på_ventelisteplass';
        case STATUS_SENDT_TIL_GODKJENNING:
            if (direktePamelding) {
                return 'påmelding_ble_sendt_til_godkjenning';
            } else {
                return 'søknad_ble_sendt_til_godkjenning';
            }
        default:
            return 'søknad_er_registrert_på_kurs';
    }
};

export default PameldingRegistrert;