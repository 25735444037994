import React, {useEffect} from 'react';
import requireInstitusjonSelected from "./requireInstitusjonSelected";
import {compose} from "redux";
import {isLoggedIn, loginSetPersistedRedirect} from "../ducks/session";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {pages} from "../utils/common";
import Redirect from "../components/Redirect";

const requireLogin = Component => props => {

    const dispatch = useDispatch();
    const loggedIn = useSelector(isLoggedIn);

    const location = useLocation();
    const {pathname, search} = location;

    useEffect(() => {
        dispatch(loginSetPersistedRedirect(`${pathname}${search}`));
    }, [pathname, search, dispatch]);

    if (!loggedIn) {
        return <Redirect to={pages.login} />
    }

    return <Component {...props}/>;
};

export default compose(
    requireLogin,
    requireInstitusjonSelected,
)