import queryString from "query-string";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {attemptLoginSomBruker, getPersonlopenrUnderSaksbehandling, isSaksbehandler,} from "../ducks/session";
import {LOGIN_SOM_TYPE_BEDRIFTSBRUKER, setBrukenrSaksbehandling, setLoginSomType} from "../ducks/menu";
import {useLocation} from "react-router";

const useBedriftsbrukerFromLocation = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const params = queryString.parse(location.search);
    const personlopenr = parseInt(params.bedriftsbruker);
    const aktivtPersonlopenr = useSelector(getPersonlopenrUnderSaksbehandling);
    const saksbehandler = useSelector(isSaksbehandler);
    useEffect(() => {
        if (saksbehandler && personlopenr && personlopenr !== aktivtPersonlopenr) {
            dispatch(setLoginSomType(LOGIN_SOM_TYPE_BEDRIFTSBRUKER));
            dispatch(setBrukenrSaksbehandling(personlopenr));
            dispatch(attemptLoginSomBruker());
        }
    }, [personlopenr, aktivtPersonlopenr, saksbehandler, dispatch]);
};


export default useBedriftsbrukerFromLocation;