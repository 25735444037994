import React, {useState} from 'react';
import PropTypes from "prop-types";
import {
    getRequestErrorCode,
    getRequestErrorHttpStatus,
    getRequestErrorTextId
} from "../utils/request";
import {useSelector} from "react-redux";
import {getInstitusjonsNavn} from "../ducks/session";
import {getAllowedFiletypesFormatted} from "../ducks/appegenskaper";
import {ShowMoreButton} from "./ShowMoreButton";
import FormattedDate from "../primitives/FormattedDate";
import './ws-error-descrition.scss';
import Text from "../primitives/Text";

const WSErrorDescription = ({error, className = ''}) => {
    const classes = `ws-error-description ${className}`;
    const institusjon = useSelector(getInstitusjonsNavn);
    const allowedFiletypes = useSelector(getAllowedFiletypesFormatted);
    const [showDetails, setShowDetails] = useState(false);

    return (
            <div role="alert" className={classes}>
                <Text id={getRequestErrorTextId(error)}
                      values={{
                          institusjon: institusjon,
                          allowedFiletypes: allowedFiletypes
                      }}
                />
                <ShowMoreButton onClick={() => setShowDetails(!showDetails)} extended={showDetails}/>
                {showDetails && <Details error={error}/>}
            </div>
    );
};

const Details = ({error}) => {
    const httpStatus = getRequestErrorHttpStatus(error);
    const errorCode = getRequestErrorCode(error);
    return(
        <p className='ws-error-description__details' lang='en'>
            {error && error.toString()}<br/>
            Http status: {httpStatus || '-'}<br/>
            Error code: {errorCode || '-'}<br/>
            <FormattedDate date={new Date()} withTime/><br/>
        </p>
    )
};


export default WSErrorDescription;


WSErrorDescription.propTypes = {
    className: PropTypes.string,
    error: PropTypes.object.isRequired,
};