import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

const Heading = ({className, id, values, lang, headingLevel = 1, ...restOfProps}) => {
    const HeadingLevel = `h${headingLevel}`;

    return (
        <HeadingLevel className={className} lang={lang}>
            <FormattedMessage id={id} values={{...values, ...restOfProps}} />
        </HeadingLevel>
    )
}


Text.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    values: PropTypes.object,
    lang: PropTypes.string,
    headingLevel: PropTypes.number,
};


export default Heading;
