import {useSelector} from "react-redux";
import {getLandByNummer} from "../../ducks/land";
import {isAdressePaNorskFormat, landSkalVises} from "../../utils/adresseUtil";
import Poststed from "./Poststed";
import React from "react";
import {AdresseIcon} from "./AdresseIcon";
import PropTypes from "prop-types";
import "./adresse.scss"

export const Adresse = ({adresse, type, className}) => {
    const {linje1, linje2, postnummer, postadresse, land: landnummer} = adresse;
    const land = useSelector(state => landnummer && getLandByNummer(state, landnummer));

    return (
        <div className={`adresse ${className}`}>
            <AdresseIcon type={type}/>
            <address className='adresse__tekst'>
                {linje1 && <>{linje1}<br /></>}
                {linje2 && <>{linje2}<br /></>}
                {isAdressePaNorskFormat(adresse) && postnummer ?
                    <>{postnummer} <Poststed postnummer={postnummer}/><br /></>
                    :
                    postadresse && <>{postadresse}<br /></>
                }
                {landSkalVises(adresse) && land}
            </address>
        </div>

    )
};

Adresse.propTypes = {
    adresse: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
};

export default Adresse;