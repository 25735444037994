import React from 'react';
import PropTypes from "prop-types";
import {
    ACTION_TYPE_ANGRE_TREKK,
    ACTION_TYPE_DOKUMENTASJON,
    ACTION_TYPE_ENDRE, ACTION_TYPE_ENDRE_FAKTURAINFO,
    ACTION_TYPE_ENDRE_SVAR, ACTION_TYPE_SE, ACTION_TYPE_SLETTE,
    ACTION_TYPE_SVARE,
    ACTION_TYPE_TREKKE,
    ACTION_TYPE_TREKKE_FRA_VENTELISTE, STATUS_IKKE_FULLFORT, STATUS_MANGLER_DOKUMENTASJON,
} from '../../utils/soknadStatus'
import Icon from "../../primitives/Icon"
import {mdiFilePlus, mdiDeleteForever, mdiRestore, mdiSend, mdiCash, mdiEye} from '@mdi/js';
import {endreIconPath} from "../../utils/icons";


export const SoknadRowActionIcon = ({type, status, innenforFrist}) => {
    const importanceClassName = isFristViktig(type, status, innenforFrist) ? 'viktig' : 'ikke-viktig';
    const dangerClassName = isActionDeleting(type) ? 'is-deleting-action' : '';
    return (
        <span className={`action__icon ${importanceClassName} ${dangerClassName}`}>
            <Icon path={getIconPathByType(type)}/>
        </span>
    );
};

function isActionDeleting(type) {
    return [ACTION_TYPE_TREKKE_FRA_VENTELISTE, ACTION_TYPE_TREKKE, ACTION_TYPE_SLETTE].includes(type);
}

function isFristViktig(type, status, innenforFrist) {
    if (!innenforFrist) {
        return false;
    }
    switch (type) {
        case ACTION_TYPE_SVARE:
            return true;
        case ACTION_TYPE_DOKUMENTASJON:
            return status === STATUS_MANGLER_DOKUMENTASJON;
        case ACTION_TYPE_ENDRE:
            return status === STATUS_IKKE_FULLFORT;
        default:
            return false;
    }
}


const getIconPathByType = type => {
    switch (type) {
        case ACTION_TYPE_DOKUMENTASJON:
            return mdiFilePlus;
        case ACTION_TYPE_TREKKE_FRA_VENTELISTE:
        case ACTION_TYPE_TREKKE:
        case ACTION_TYPE_SLETTE:
            return mdiDeleteForever;
        case ACTION_TYPE_ANGRE_TREKK:
            return mdiRestore;
        case ACTION_TYPE_ENDRE_SVAR:
        case ACTION_TYPE_SVARE:
            return mdiSend;
        case ACTION_TYPE_ENDRE_FAKTURAINFO:
            return mdiCash;
        case ACTION_TYPE_ENDRE:
            return endreIconPath;
        case ACTION_TYPE_SE:
        default:
            return mdiEye;
    }
};

SoknadRowActionIcon.propTypes = {
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    innenforFrist: PropTypes.bool,
};

export default SoknadRowActionIcon;