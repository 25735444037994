import React from 'react';
import PageTitle from "../PageTitle";
import OpplastedeDokumenter from "./OpplastedeDokumenter";
import requireLogin from "../../high-order-components/requireLogin";
import Tabs from "../Tabs";
import {
    ANNET_DOKUMENTTYPE,
    GSK_DOKUMENTTYPE,
    HOYERE_UTDANNING__DOKUMENTTYPE
} from "../../services/dokumenterAPI";
import GSK from "../gsk/GSK";
import ArbeidserfaringKategori from "./ArbeidserfaringKategori";
import Text from "../../primitives/Text";

const Dokumentasjon = () => {

    const labels = ['generell_studiekompetanse', 'høyere_utdanning', 'arbeidserfaring', 'annet'];

    return <div className='dokumentasjon-side'>
        <PageTitle id='dokumentasjon'/>
        <h1>
            <Text id='dokumentasjon'/>
        </h1>

        <Tabs labels={labels}
              contents={[
                  <GSKKategori id={labels[0]}/>,
                  <OpplastedeDokumenter id={labels[1]} type={HOYERE_UTDANNING__DOKUMENTTYPE}/>,
                  <ArbeidserfaringKategori id={labels[2]}/>,
                  <OpplastedeDokumenter id={labels[3]} type={ANNET_DOKUMENTTYPE}/>,
              ]}
        />
    </div>
};



const GSKKategori = ({id}) =>
    <div id={id} aria-labelledby={'tab_' + id}>
        <GSK/>
        <OpplastedeDokumenter type={GSK_DOKUMENTTYPE}/>
    </div>
;

export default requireLogin(Dokumentasjon);