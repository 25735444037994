import React from 'react';
import Text from "./Text";
import "./radioButton.scss"


export const RadioButton = ({label, checked, onChange}) => {
    return (
        <label className={`radio ${checked && 'checked'}`}>
            <input type="radio" checked={checked}
                   onChange={onChange}
            />
            <Text id={label}/>
        </label>
    )
};


