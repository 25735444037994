import React from 'react';
import Checkbox from "../../../primitives/Checkbox";
import Filter from "./Filter";
import {useDispatch, useSelector} from "react-redux";
import {
    getUtgatteKursCounter,
    getUtgatteKursFilter,
    toggleUtgatteKursFilter
} from "../../../ducks/kursoversikt";
import {injectIntl} from "react-intl";

const FilterUtgatteKurs = ({intl}) => {
    const counter = useSelector(getUtgatteKursCounter);
    const visUtgatteKurs = useSelector(getUtgatteKursFilter);
    const dispatch = useDispatch();
    if (counter === 0) return null;
    return (
        <Filter
            labelTextId={`frist`}
        >
            <Checkbox
                label={intl.formatMessage({id: 'vis_utgatte_kurs_counter'}, {counter})}
                onChange={() => dispatch(toggleUtgatteKursFilter())}
                checked={visUtgatteKurs}
            />
        </Filter>
    )
};

export default injectIntl(FilterUtgatteKurs);