import React from 'react';
import {Link} from "react-router-dom";
import {pages} from "../../utils/common";
import Text from "../../primitives/Text";
import Icon from "../../primitives/Icon";
import {mdiBank} from "@mdi/js";

const MenuBarInstitusjoner = () => {
    return (
        <div className='menubar-button'>
            <Link to={pages.institusjon}>
                <Icon path={mdiBank} className='institusjon-icon'/>
                <Text id="institusjoner"/>
            </Link>
        </div>
    )
};

export default MenuBarInstitusjoner;