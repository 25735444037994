import React from 'react';
import InitialLoadWrapper from '../wrappers/InitialLoadWrapper';
import {getError, isDataLoaded, isLoading, loadForetak} from "../ducks/foretaksgodkjenning";
import Text from "../primitives/Text";

const withForetakLoaded = Component => props => {
    return (
        <InitialLoadWrapper
            loadAction={loadForetak}
            loadingSelector={isLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getError}
            loadMessage={<Text id='laster_inn_foretak'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )

};

export default withForetakLoaded;