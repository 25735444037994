import React from 'react';
import PropTypes from 'prop-types'
import Text from "./Text";
import Icon from "./Icon";


const Label = (props) => {
    const {text, justForSR, iconPath, justIcon, altText} = props;
    return justForSR ?
        <LabelForSR {...props}/>
        :
        justIcon ?
            <LabelIcon {...props} altText={altText ? altText : text}/>
            :
            iconPath ?
                <LabelTextAndIcon {...props}/>
                :
                <LabelText {...props}/>;
};


Label.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    justForSR: PropTypes.bool,
    iconPath: PropTypes.string,
    justIcon: PropTypes.bool,
    showColon: PropTypes.bool,
    translate: PropTypes.bool,
    altText: PropTypes.string,
};


const LabelText = ({htmlFor, text, showColon, translate, className = ''}) => {
    return (
        <label htmlFor={htmlFor} className={className}>
            {translate ? <Text id={text}/> : text}{showColon && `:`}
        </label>
    )
};

const LabelForSR = ({htmlFor, text, translate, className = ''}) => {
    return (
        <label htmlFor={htmlFor} className={className}>
                <span className="vis-for-sr">
                    {translate ? <Text id={text}/> : text}
                </span>
        </label>
    )
};

LabelText.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    className: PropTypes.string,
    showColon: PropTypes.bool,
    translate: PropTypes.bool,
};


const LabelTextAndIcon = ({htmlFor, text, iconPath, showColon, translate, className = ''}) => {
    return (
        <React.Fragment>
            <Icon path={iconPath} size='1.3em' className='icon-for-label'/>
            <label htmlFor={htmlFor} className={`label-with-icon ${className}`}>
                {translate ? <Text id={text}/> : text}{showColon && `:`}
            </label>
        </React.Fragment>
    )
};

LabelTextAndIcon.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    iconPath: PropTypes.string.isRequired,
};


const LabelIcon = ({htmlFor, iconPath, altText}) => {
    return (
        <label htmlFor={htmlFor} className={'label-just-icon'}>
            <Icon path={iconPath} title={altText}/>
        </label>
    )
};

LabelIcon.propTypes = {
    iconPath: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
    className: PropTypes.string,
};


export default Label