import React, {Component} from 'react';
import Mobiltelefon from "./Mobiltelefon";
import Arbeidstelefon from "./Arbeidstelefon";
import Heading from "../../../primitives/Heading";
import {headingLevelByPage} from "../../../utils/headingUtil";


export class Telefoner extends Component {

    render() {
        const {page} = this.props;
        return (
            <div className='telefoner flat-card'>
                <Heading id='telefoner' headingLevel={headingLevelByPage(page)}/>
                <div className='content'>
                    <Mobiltelefon/>
                    <Arbeidstelefon/>
                </div>
            </div>
        )
    }
}


export default Telefoner;