import React from 'react';
import Kontaktinfo from "./Kontaktinfo";
import Annet from "./annet/Annet";
import GrunnleggendeInformasjon from "./grunnleggende/GrunnleggendeInformasjon";
import './profil.scss';
import withProfildataLoaded from "../../high-order-components/withProfildataLoaded";
import withLandLoaded from "../../high-order-components/withLandLoaded";
import PageTitle from "../PageTitle";
import {requireDeltakernummer} from "../../high-order-components/requireDeltakernummer";
import {compose} from "redux";
import requireLogin from "../../high-order-components/requireLogin";
import Text from "../../primitives/Text";

export const Profil = () =>
    <div>
        <PageTitle id='profil'/>
        <h1>
            <Text id='person_og_kontaktinformasjon'/>
        </h1>
        <div className='profil'>
            <div className="cards-container">
                <GrunnleggendeInformasjon/>
                <Annet/>
            </div>
            <Kontaktinfo/>
        </div>
    </div>
;

export default compose(
    requireLogin,
    requireDeltakernummer,
    withProfildataLoaded,
    withLandLoaded,
)(Profil);