import {
    TYPE_ADRESSE_ARBEID, TYPE_ADRESSE_FAKTURA,
    TYPE_ADRESSE_HJEM
} from "../../utils/common";
import React from "react";
import PropTypes from "prop-types";
import Icon from "../../primitives/Icon";
import {mdiHome, mdiDomain, mdiMapMarker } from '@mdi/js'


export const AdresseIcon = ({type}) => {
    const iconsByType = {
        [TYPE_ADRESSE_HJEM]: mdiHome,
        [TYPE_ADRESSE_ARBEID]: mdiDomain ,
        [TYPE_ADRESSE_FAKTURA]: mdiMapMarker,
    };

    const path = iconsByType[type];
    if (!path) return null;

    return <Icon path={path} className = 'adresse-icon' title={type}/>;
};

AdresseIcon.propTypes = {
    type: PropTypes.string.isRequired,
};