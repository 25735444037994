import {logoutSuccess} from "../ducks/session";
import {EVU3_WS_TOKEN} from "./sessionUtil";

export const APP_MILJO_PROD = 'PROD';
export const APP_MILJO_TEST = 'TEST';
export const APP_MILJO_UTV = 'UTV';

export function getMiljo() {
    return process.env.REACT_APP_MILJO;
}

export function isProd() {
    return getMiljo() === APP_MILJO_PROD;
}

export function isUtv() {
    return getMiljo() === APP_MILJO_UTV;
}

export const preventIframeLoading = () => {
    if (window.location !== window.parent.location) {
        throw new Error("NO IFRAME LOADING");
    }
};


export const addEventListenerLogoutIfAPITokenRemoved = (store) => {
    window.addEventListener('storage', e => {
        if (e.key === EVU3_WS_TOKEN && e.oldValue && !e.newValue) {
            store.dispatch(logoutSuccess());
        }
    });
};