import React from "react";
import './infoelement.scss'
import PropTypes from "prop-types";
import Icon from "../primitives/Icon";

const Infoelement = ({title, value, iconPath, extraText}) => {
    if (!value) return null;
    return (
        <div className='info-element'>
            <Icon path={iconPath}/>{title}: {value}
            {extraText && <span className='info-element__extra-text'> {extraText}</span>}
        </div>
    )
};


Infoelement.propTypes = {
    title: PropTypes.node,
    value: PropTypes.any,
    iconPath: PropTypes.string,
    extraText: PropTypes.node,
};


export default Infoelement;