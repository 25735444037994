import React from 'react'
import PropTypes from 'prop-types'
import Error from "./Error";
import {useSelector} from "react-redux";
import {getInstitusjonsNavn} from "../ducks/session";
import Text from "../primitives/Text";

const InPlaceError = ({errorMessageId, className = '', ...restOfProps}) => {
    const institusjon = useSelector(getInstitusjonsNavn);
    if (errorMessageId) {
        return (
            <Error className={className} {...restOfProps}>
                <Text id={errorMessageId}
                      values={{
                          institusjon: institusjon,
                      }}
                />
            </Error>
        )
    }
    return null;
};

InPlaceError.propTypes = {
    errorMessageId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    className: PropTypes.string,
};

export default InPlaceError