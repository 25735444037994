import React from 'react';
import {getError, isDataLoaded, isLoading, loadArbeidserfaring} from '../ducks/arbeidserfaring';
import InitialLoadWrapper from "../wrappers/InitialLoadWrapper";
import Text from "../primitives/Text";

const withArbeidserfaringLoaded = Component => props =>
    <InitialLoadWrapper
        loadAction={loadArbeidserfaring}
        loadingSelector={isLoading}
        loadedSelector={isDataLoaded}
        errorSelector={getError}
        loadMessage={<Text id='laster_inn_arbeidserfaring'/>}
    >
        <Component {...props} />
    </InitialLoadWrapper>
;


export default withArbeidserfaringLoaded;