import {createSlice} from "@reduxjs/toolkit";
import {
    getArbeidserfaring,
    postArbeidserfaring,
    postArbeidserfaringDokument,
    deleteArbeidserfaring as deleteArbeidserfaringAPI, deleteSoknadstilknytning, postSoknadstilknytning,
} from "../services/arbeidserfaringAPI";
import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {deleteDokument as deleteDokumentAPI} from "../services/dokumenterAPI";
import {showPopup} from "./popup";
import {createErrorPopupContent} from "../utils/request";
import {orderBy} from "lodash";
import {parseIsoDate} from "../utils/common";
import {getKursIdObject} from "./kursinfo";
import FeedbackErrors from "../components/FeedbackErrors";
import React from "react";
import ErrorsArbeidserfaringTilknytninger
    from "../components/steg-dokumentasjon/arbeidserfaring-elementer/ErrorsArbeidserfaringTilknytninger";
import {isTextValidISODate} from "../utils/dateUtil";

export const NAME = 'arbeidserfaring';

export const createArbeidserfaring = ({
                                          id,
                                          fraDato,
                                          tilDato,
                                          arbeidsgiver,
                                          stillingstittel,
                                          stillingsprosent = 100,
                                          dokumentid,
                                          dokumentnavn,
                                          isSaving = false,
                                          isDeleting = false,
                                          error,
                                          saveClicked = false,
                                          isUploading = false,
                                          uploadError,
                                          tempFileUpload,
                                          deleteDokumentOnSave,
                                      }) => ({
    id,
    fraDato,
    tilDato,
    isVedvarende: !Boolean(tilDato) && Boolean(id),
    arbeidsgiver,
    stillingstittel,
    stillingsprosent,
    dokumentid,
    dokumentnavn,
    tempFileUpload,
    deleteDokumentOnSave,
    isSaving,
    isDeleting,
    isUploading,
    error,
    saveClicked,
    uploadError,
});

export const createSoknadstilknytning = ({
                                             id,
                                             kurskode,
                                             tidskode,
                                         }) => ({
    id,
    kurskode,
    tidskode,
});

export const loadArbeidserfaring = () => async (dispatch, getState) => {
    if (isLoading(getState())) {
        return;
    }
    dispatch(fetchPending());
    const evuapiToken = getEvuapiToken(getState());
    const deltakernr = getAktueltDeltakernr(getState());
    try {
        const result = await getArbeidserfaring(evuapiToken, deltakernr);
        dispatch(fetchSuccess(result));
    } catch (error) {
        dispatch(fetchFailure(error));
    }

};

export const setTempFile = (id, files) => async dispatch => {
    await dispatch(setTempFileUpload(files[0]));
};

export const uploadTempFile = id => async (dispatch, getState) => {
    const tempFileUpload = getEditTempFileUpload(getState());
    if (!tempFileUpload) {
        return;
    }
    dispatch(setUploadPending(id));
    try {
        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());
        const {dokumentnavn, dokumentid} = await postArbeidserfaringDokument(evuapiToken, deltakernr, id, tempFileUpload);
        dispatch(setUploadSuccess({
            id,
            dokumentnavn,
            dokumentid,
        }))
    } catch (error) {
        dispatch(setUploadFailure(error));
    }
};

export const deleteDokument = dokId => async (dispatch, getState) => {
    if (!dokId) {
        return;
    }
    try {
        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());
        await deleteDokumentAPI(dokId, evuapiToken, deltakernr);
        dispatch(deleteDokumentSuccess());
    } catch (error) {
        //viser ikke feil til brukeren
    }
};

export const saveArbeidserfaring = () => async (dispatch, getState) => {
    const isSaving = getEditIsSaving(getState());
    if (isSaving) {
        return;
    }
    dispatch(setSavingPending());
    const arbeidserfaring = getEditArbeidserfaring(getState());
    const isNy = !arbeidserfaring.id;
    if (isNy) {
        dispatch(opprettNyArbeidserfaring());
    } else {
        dispatch(updateArbeidserfaring());
    }
};

export const updateArbeidserfaring = () => async (dispatch, getState) => {
    const arbeidserfaring = getEditArbeidserfaring(getState());
    const id = arbeidserfaring.id;
    try {
        const deleteDokumentOnSave = getEditDeleteDokumentOnSave(getState());
        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());
        const dokId = getDokumentIdFromArbeidserfaring(getState(), id);

        const svarArbeidserfaring = await postArbeidserfaring(evuapiToken, deltakernr, arbeidserfaring);
        dispatch(setSavingSuccess(svarArbeidserfaring));

        await dispatch(uploadTempFile(id));
        const uploadError = getEditUploadError(getState());
        if (!uploadError) {
            dispatch(exitEditMode());
        }
        if (!uploadError && deleteDokumentOnSave) {
            dispatch(deleteDokument(dokId));
        }
    } catch (error) {
        dispatch(setSavingFailure(error));
    }
};

export const opprettNyArbeidserfaring = () => async (dispatch, getState) => {
    const arbeidserfaring = getEditArbeidserfaring(getState());
    try {
        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());
        const nyArbeidserfaring = await postArbeidserfaring(evuapiToken, deltakernr, arbeidserfaring);
        const id = nyArbeidserfaring.id;
        dispatch(setSavingSuccess(nyArbeidserfaring));

        await dispatch(uploadTempFile(id));
        const uploadError = getEditUploadError(getState());
        if (!uploadError) {
            dispatch(exitEditMode());
        } else {
            dispatch(setArbeidserfaringEditId(id));
        }
        dispatch(toggleArbeidserfaringSoknadstilknytning(id));
    } catch (error) {
        dispatch(setSavingFailure(error));
    }
};

export const deleteArbeidserfaring = id => async (dispatch, getState) => {
    const isSaving = getEditIsSaving(getState());
    if (isSaving) {
        return;
    }
    dispatch(setDeletePending(id));
    try {
        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());
        deleteDokument(id);
        const erKnyttetTilSoknad = getIsArbeidserfaringKnyttetTilSoknad(getState(), id);
        erKnyttetTilSoknad && await dispatch(toggleArbeidserfaringSoknadstilknytning(id));
        await deleteArbeidserfaringAPI(evuapiToken, deltakernr, id);
        dispatch(setDeleteSuccess(id))
    } catch (error) {
        dispatch(showPopup(createErrorPopupContent(error)));
        dispatch(setDeleteFailure(id));
    }
};

export const toggleFlereArbeidserfaringSoknadstilknytninger = idListe => dispatch => {
    dispatch(resetSoknadstilknytningerErrors());
    idListe.forEach(id => {
        dispatch(toggleArbeidserfaringSoknadstilknytning(id));
    });
};


export const toggleArbeidserfaringSoknadstilknytning = id => async (dispatch, getState) => {

    const isSaving = getIsSoknadstilknytningLoading(getState(), id);
    if (isSaving) {
        return;
    }

    dispatch(setSoknadstilknytningPending(id));

    const skalSlettes = getIsArbeidserfaringKnyttetTilSoknad(getState(), id);
    const apiService = skalSlettes ? deleteSoknadstilknytning : postSoknadstilknytning;
    const successDispatchFunction = skalSlettes ? removeSoknadstilknytningSuccess : addSoknadstilknytningSuccess;
    try {
        const evuapiToken = getEvuapiToken(getState());
        const deltakernr = getAktueltDeltakernr(getState());
        const kursIdObject = getKursIdObject(getState());
        await apiService(evuapiToken, deltakernr, kursIdObject, id);
        dispatch(successDispatchFunction({id, kursIdObject}));
    } catch (error) {
        dispatch(soknadstilknytningFailure({id, error}));
        if (skalSlettes) {
            dispatch(showPopup(createErrorPopupContent(error)));
        } else {
            dispatch(showPopup(createTilknytningerErrorPopupContent()));
        }
    }
};

function createTilknytningerErrorPopupContent() {
    return {
        style: {wsError: true},
        children:
            <FeedbackErrors>
                <ErrorsArbeidserfaringTilknytninger/>
            </FeedbackErrors>,
    };
}


export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    arbeidserfaringer: [],
    soknadstilknytninger: [],
    soknadstilknytningerPending: [],
    soknadstilknytningerErrors: [],
    isInEditMode: false,
    editArbeidserfaring: createArbeidserfaring({}),
};

const {actions, reducer} = createSlice({
    initialState,
    name: NAME,
    reducers: {
        fetchPending: (state) => {
            state.isLoading = true;
            state.error = undefined;
        },
        fetchSuccess: (state, {payload}) => {
            const {arbeidserfaringer, soknadstilknytninger} = payload;
            state.isLoading = false;
            state.isDataLoaded = true;
            state.arbeidserfaringer = arbeidserfaringer;
            state.soknadstilknytninger = soknadstilknytninger;
        },
        fetchFailure: (state, {payload: error}) => {
            state.isLoading = false;
            state.isDataLoaded = true;
            state.error = error;
        },
        enterEditMode: (state, {payload: id}) => {
            const editArbeidserfaring = state.arbeidserfaringer.find(arbeidserfaring => arbeidserfaring.id === id);
            state.isInEditMode = true;
            state.editArbeidserfaring = editArbeidserfaring || createArbeidserfaring({});
        },
        exitEditMode: (state) => {
            state.isInEditMode = false;
        },
        setArbeidserfaringEditId: (state, {payload: id}) => {
            state.editArbeidserfaring.id = id;
        },
        setEditFieldValue: (state, {payload}) => {
            const {field, value} = payload;
            state.editArbeidserfaring = {
                ...state.editArbeidserfaring,
                [field]: value,
            }
        },
        setSavingPending: (state) => {
            state.editArbeidserfaring = updateTempArbeidserfaring(state, {
                isSaving: true,
                error: undefined,
            })
        },
        setSavingSuccess: (state, {payload: nyArbeidserfaring}) => {
            const arbeidserfaringer = [
                ...state.arbeidserfaringer.filter(arbeidserfaring => arbeidserfaring.id !== nyArbeidserfaring.id),
                nyArbeidserfaring,
            ];
            state.editArbeidserfaring = updateTempArbeidserfaring(state, {
                isSaving: false,
            });
            state.arbeidserfaringer = arbeidserfaringer;
        },
        setSavingFailure: (state, {payload: error}) => {
            state.editArbeidserfaring = updateTempArbeidserfaring(state, {
                isSaving: false,
                error,
            })
        },
        setDeletePending: (state, {payload: id}) => {
            state.arbeidserfaringer = updateArbeidserfaringIListe(state, id, {
                isDeleting: true,
            });
        },
        setDeleteSuccess: (state, {payload: id}) => {
            state.arbeidserfaringer = [
                ...state.arbeidserfaringer.filter(arbeidserfaring => arbeidserfaring.id !== id),
            ];
        },
        setDeleteFailure: (state, {payload: id}) => {
            state.arbeidserfaringer = updateArbeidserfaringIListe(state, id, {
                isDeleting: false,
            });
        },
        setTempFileUpload: (state, {payload: file}) => {
            const changes = {
                tempFileUpload: file,
                uploadError: undefined,
            };
            state.editArbeidserfaring = updateTempArbeidserfaring(state, changes)
        },
        setUploadPending: (state) => {
            const changes = {
                isUploading: true,
                uploadError: undefined,
            };
            state.editArbeidserfaring = updateTempArbeidserfaring(state, changes)
        },
        setUploadSuccess: (state, {payload}) => {
            const {id, dokumentid, dokumentnavn} = payload;
            state.editArbeidserfaring = updateTempArbeidserfaring(state, {
                dokumentid,
                dokumentnavn,
                tempFileUpload: undefined,
                isUploading: false,
            });
            state.arbeidserfaringer = updateArbeidserfaringIListe(state, id, {
                dokumentid,
                dokumentnavn,
            });
        },
        setUploadFailure: (state, {payload: error}) => {
            const changes = {
                isUploading: false,
                uploadError: error,
            };
            state.editArbeidserfaring = updateTempArbeidserfaring(state, changes)
        },
        setDeleteDokumentOnSave: (state) => {
            const changes = {
                deleteDokumentOnSave: true,
            };
            state.editArbeidserfaring = updateTempArbeidserfaring(state, changes)
        },
        deleteDokumentSuccess: (state) => {
            const changes = {
                deleteDokumentOnSave: undefined,
            };
            state.editArbeidserfaring = updateTempArbeidserfaring(state, changes)
        },
        resetSoknadstilknytningPending: (state) => {
            state.soknadstilknytningerPending = [];
        },
        setSoknadstilknytningPending: (state, {payload: id}) => {
            state.soknadstilknytningerPending.push(id);
        },
        soknadstilknytningFailure: (state, {payload}) => {
            const {id, error} = payload;
            removePendingSoknadstilknytning(state, id);
            state.soknadstilknytningerErrors.push({id, error});
        },
        resetSoknadstilknytningerErrors: (state) => {
            state.soknadstilknytningerErrors = [];
        },
        addSoknadstilknytningSuccess: (state, {payload}) => {
            const {id, kursIdObject} = payload;
            removePendingSoknadstilknytning(state, id);
            state.soknadstilknytninger = [
                ...state.soknadstilknytninger,
                createSoknadstilknytning({
                    id,
                    kurskode: kursIdObject.kurskode,
                    tidskode: kursIdObject.tidskode,
                })
            ]
        },
        removeSoknadstilknytningSuccess: (state, {payload}) => {
            const {id, kursIdObject} = payload;
            removePendingSoknadstilknytning(state, id);
            state.soknadstilknytninger =
                state.soknadstilknytninger.filter(tilknytning =>
                    !isSoknadsTilknytningEqual(tilknytning, id, kursIdObject)
                );
        },
    }
});

const removePendingSoknadstilknytning = (state, id) => {
    state.soknadstilknytningerPending =
        state.soknadstilknytningerPending.filter(
            tilknytningId => tilknytningId !== id
        );
};

const isSoknadsTilknytningEqual = (tilknytning, id, kursId) =>
    tilknytning.id === id &&
    tilknytning.kurskode === kursId.kurskode &&
    tilknytning.tidskode === kursId.tidskode;


const updateArbeidserfaringIListe = (state, id, changes) => {
    return state.arbeidserfaringer.map(arbeidserfaring => {
        return arbeidserfaring.id !== id ? arbeidserfaring : {
            ...arbeidserfaring,
            ...changes,
        }
    });
};

const updateTempArbeidserfaring = (state, changes) => ({
    ...state.editArbeidserfaring,
    ...changes,
});

export const {
    fetchPending,
    fetchSuccess,
    fetchFailure,
    enterEditMode,
    exitEditMode,
    setArbeidserfaringEditId,
    setEditFieldValue,
    setSavingPending,
    setSavingSuccess,
    setSavingFailure,
    setDeletePending,
    setDeleteSuccess,
    setDeleteFailure,
    setTempFileUpload,
    setUploadPending,
    setUploadSuccess,
    setUploadFailure,
    deleteDokumentSuccess,
    resetSoknadstilknytningPending,
    setSoknadstilknytningPending,
    addSoknadstilknytningSuccess,
    removeSoknadstilknytningSuccess,
    soknadstilknytningFailure,
    resetSoknadstilknytningerErrors,
    setDeleteDokumentOnSave,
} = actions;

const getState = state => state[NAME];

export const isLoading = state => getState(state).isLoading;
export const isDataLoaded = state => getState(state).isDataLoaded;
export const getError = state => getState(state).error;
export const getIsInEditMode = state => getState(state).isInEditMode;

export const getArbeidserfaringer = state => getState(state).arbeidserfaringer;
export const getArbeidserfaringerSorted = state => orderBy(getArbeidserfaringer(state), ['fraDato', 'tilDato'], ['desc', 'desc']);

export const getArbeidserfaringFromId = (state, id) =>
    id ?
        getArbeidserfaringer(state).find(arbeidserfaring => arbeidserfaring.id === id) || {}
        :
        getEditArbeidserfaring(state);

export const getDokumentIdFromArbeidserfaring = (state, id) => getArbeidserfaringFromId(state, id).dokumentid;

export const getHarArbeidserfaringDokument = (state, id) =>
    Boolean(getDokumentIdFromArbeidserfaring(state, id))
    && !getEditDeleteDokumentOnSave(state);

export const getDokumentNavnFromArbeidserfaring = (state, id) => getArbeidserfaringFromId(state, id).dokumentnavn;

export const getEditArbeidserfaring = state => getState(state).editArbeidserfaring;
export const getEditField = (state, field) => getEditArbeidserfaring(state)[field];
export const getEditId = state => getEditField(state, 'id');
export const getEditArbeidsgiver = state => getEditField(state, 'arbeidsgiver');
export const getEditStillingstittel = state => getEditField(state, 'stillingstittel');
export const getEditFraDato = state => getEditField(state, 'fraDato');
export const getEditTilDato = state => getEditField(state, 'tilDato');
export const getEditStillingsprosent = state => getEditField(state, 'stillingsprosent');
export const getEditIsSaving = state => getEditField(state, 'isSaving');
export const getEditError = state => getEditField(state, 'error');
export const getEditIsVedvarende = state => getEditField(state, 'isVedvarende');
export const getEditWasSaveClicked = state => getEditField(state, 'saveClicked');
export const getEditDeleteDokumentOnSave = state => getEditField(state, 'deleteDokumentOnSave');
export const getEditTempFileUpload = state => getEditField(state, 'tempFileUpload');
export const getEditUploadError = state => getEditField(state, 'uploadError');
export const getEditIsUploading = state => getEditField(state, 'isUploading');

export const getEditHarPeriode = state =>
    Boolean(getEditFraDato(state)) && (Boolean(getEditTilDato(state)) || getEditIsVedvarende(state));

export const getIsEditFerdigUtfylt = state =>
    Boolean(getEditArbeidsgiver(state)) &&
    Boolean(getEditStillingstittel(state)) &&
    Boolean(getHarDokumentasjon(state)) &&
    getIsStillingsProsentGyldig(state) &&
    getEditHarPeriode(state);

export const getHarDokumentasjon = state =>
    getEditTempFileUpload(state) || getHarArbeidserfaringDokument(state);

export const getIsEditValid = state => {
    const fraDato = getEditFraDato(state);
    const tilDato = getEditTilDato(state);

    return getIsEditFerdigUtfylt(state)
        && !getIsEditFraDatoStorreEnnTilDato(state)
        && isTextValidISODate(fraDato)
        && isTextValidISODate(tilDato)
};

export const getIsEditFraDatoStorreEnnTilDato = state =>
    Boolean(getEditTilDato(state)) && !getEditIsVedvarende(state) && parseIsoDate(getEditTilDato(state)) < parseIsoDate(getEditFraDato(state));

export const getIsStillingsProsentGyldig = state =>
    Boolean(getEditStillingsprosent(state)) && getEditStillingsprosent(state) >= 1 && getEditStillingsprosent(state) <= 100;

export const getSoknadstilknytninger = state => getState(state).soknadstilknytninger;
export const getSoknadstilknytningerPending = state => getState(state).soknadstilknytningerPending;

export const getIsArbeidserfaringKnyttetTilSoknad = (state, id) => {
    const soknadstilknytninger = getSoknadstilknytninger(state);
    const kursIdObject = getKursIdObject(state);
    return Boolean(kursIdObject) && soknadstilknytninger.some(tilknytning =>
        isSoknadsTilknytningEqual(tilknytning, id, kursIdObject)
    );
};

export const getIsSoknadstilknytningLoading = (state, id) => getSoknadstilknytningerPending(state).includes(id);

export const getIsNySoknadstilknytningLoading = (state) => {
    const idListeIsLoading = getSoknadstilknytningerPending(state);
    const idListeTilknytninger = getTilknyttetArbeidserfaringerIdListe(state);
    return idListeIsLoading.some(id => !idListeTilknytninger.includes(id));
};

export const getTilknyttetArbeidserfaringer = (state) => {
    const tilknytningerIdListe = getTilknyttetArbeidserfaringerIdListe(state);
    return getArbeidserfaringerSorted(state)
        .filter(arbeiderfaring => tilknytningerIdListe.includes(arbeiderfaring.id));
};

export const getLedigeArbeidserfaringer = (state) => {
    const tilknytningerIdListe = getTilknyttetArbeidserfaringerIdListe(state);
    return getArbeidserfaringerSorted(state)
        .filter(arbeiderfaring => !tilknytningerIdListe.includes(arbeiderfaring.id));
};

export const getTilknyttetArbeidserfaringerIdListe = state => {
    const kursIdObject = getKursIdObject(state);
    return getSoknadstilknytninger(state)
        .filter(tilknytning => isKursIdEqual(kursIdObject, tilknytning))
        .map(tilknytning => tilknytning.id)
};

export const getSoknadstilknytningerErrors = state => getState(state).soknadstilknytningerErrors;


export const getHarTilknyttetArbeidserfaringer = (state, kursId) =>
    getSoknadstilknytninger(state)
        .filter(tilknytning => isKursIdEqual(kursId, tilknytning))
        .length > 0;


const isKursIdEqual = (kursId1, kursId2) =>
    kursId1.kurskode === kursId2.kurskode && kursId1.tidskode === kursId2.tidskode;

export default reducer;