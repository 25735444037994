import React from "react";
import {useSelector} from "react-redux";
import {getSamtykkerOversatt} from "../../../ducks/samtykke";
import HTMLReactParser from "html-react-parser";
import {samtykkeIconPath} from "../../../utils/icons";
import withSamtykkerLoaded from "../../../high-order-components/withSamtykkerLoaded";
import Text from "../../../primitives/Text";
import Icon from "../../../primitives/Icon";

const StegOversiktSamtykke = () => {
    const samtykker = useSelector(getSamtykkerOversatt);
    return (
        <div className='steg-oversikt__steg__samtykker'>
            {samtykker.map(samtykke =>
                <Samtykke
                    key={samtykke.kode}
                    sporsmal={samtykke.sporsmal}
                    besvarelse={samtykke.besvarelse}
                />
            )}
        </div>
    )
};

const Samtykke = ({sporsmal, besvarelse}) => {
    return (
        <div className='steg-oversikt__steg__samtykker__samtykke'>
            <div className='steg-oversikt__steg__samtykker__samtykke__tekst'>
                <Icon path={samtykkeIconPath}/>
                {HTMLReactParser(sporsmal)}
            </div>
            <div className='steg-oversikt__steg__samtykker__samtykke__besvarelse'>
                {besvarelse ? <Text id='ja'/> : <Text id='nei'/>}
            </div>
        </div>
    )
};

export default withSamtykkerLoaded(StegOversiktSamtykke);