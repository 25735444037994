import React from 'react'
import {useSelector} from "react-redux";
import {
    getDeltakernrUnderSaksbehandling,
    getInstitusjonsNavn,
    getInstitusjonsnr,
    skrivingSkalBlokkeres as skrivingSkalBlokkeresSelector,
    getPersonlopenrUnderSaksbehandling, getIsSaksbehandlerLoggedInSomBruker,
} from "../../ducks/session";
import ShowIfLoggedIn from "../../wrappers/ShowIfLoggedIn";
import {Link} from "react-router-dom";
import {getLangByLocale} from "../../utils/common";
import * as routes from "../../services/apiRoutes";
import {injectIntl} from "react-intl";
import {getMiljo, isProd} from "../../utils/environment";
import {getLocale} from "../../ducks/i18n";
import './header.scss'
import Text from "../../primitives/Text";
import {mdiAlertOutline} from "@mdi/js";
import Icon from "../../primitives/Icon";

const Header = ({intl}) => {

    const isSaksbehandlerLoggedInSomBruker = useSelector(getIsSaksbehandlerLoggedInSomBruker);
    const deltakernrUnderSaksbehandling = useSelector(getDeltakernrUnderSaksbehandling);
    const personlopenrUnderSaksbehandling = useSelector(getPersonlopenrUnderSaksbehandling);
    const institusjon = useSelector(getInstitusjonsNavn);
    const skrivingSkalBlokkeres = useSelector(skrivingSkalBlokkeresSelector);
    const instnr = useSelector(getInstitusjonsnr);
    const malform = useSelector(state => getLangByLocale(getLocale(state)));

    const miljo = getMiljo();
    const getSrcForLogo = () => {
        return encodeURI(
            routes.LOGOER
                .replace(':instnr', instnr)
                .replace(':malform', malform)
        );
    };

    return (
        <header className='fun'>
            <ShowIfLoggedIn>
                {isSaksbehandlerLoggedInSomBruker && <div className='header-for-saksbehandling'>
                    <div className="container">
                        <Text
                            id="logget_inn_som_deltaker"
                            values={{
                                deltaker:
                                    <strong>{deltakernrUnderSaksbehandling || personlopenrUnderSaksbehandling}</strong>
                            }}
                        />
                        {!skrivingSkalBlokkeres &&
                        <div className={'skriving-warning'}>
                            <Icon path={mdiAlertOutline} title='warning'/> <Text id="skriving_aktivert"/>
                        </div>
                        }
                    </div>
                </div>}
            </ShowIfLoggedIn>
            <div className="container">
                <Link to="/evuweb">
                    <img src={getSrcForLogo()}
                         className="logo"
                         alt={intl.formatMessage({id: 'til_forsiden'})}
                    />
                    <div
                        className='appilacation-name'>EVUweb {miljo && !isProd() && `(${miljo})`}</div>
                    <div className='institution-name'>{institusjon}</div>
                </Link>
            </div>
        </header>
    )
};
export default injectIntl(Header);
