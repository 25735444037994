import React from 'react';
import {useSelector} from "react-redux";
import Feedback from "../../Feedback";
import {getAllPlaninfoErForEksamen} from "../../../ducks/planinfo";
import Planinfo from "../../planinfo/Planinfo";
import {getSkalTaEksamen} from "../../../ducks/eksamen";
import Text from "../../../primitives/Text";
import withPlaninfoLoaded from "../../../high-order-components/withPlaninfoLoaded";

const StegPlaninfo = () => {
    const skalTaEksamen = useSelector(getSkalTaEksamen);
    const allPlaninfoErForEksamen = useSelector(getAllPlaninfoErForEksamen);
    if (allPlaninfoErForEksamen && !skalTaEksamen) {
        return (
            <Feedback className='steg-ikke-nodvendig'>
                <h3>
                    <Text id='planinfo_ikke_nødvendig'/>
                </h3>
                <p>
                    <Text id='ikke_behov_for_planinfo_uten_eksamen'/>
                </p>
            </Feedback>
        )
    }
    return <Planinfo/>;
};

export default withPlaninfoLoaded(StegPlaninfo);