import {NORGE_KODE} from "../utils/common";
import * as routes from "./apiRoutes";
import {createRequestConfig} from "../utils/request";
import axios from "axios";
import {NORGE_LANDNUMMER} from "../ducks/land";
import {TYPE_TELEFON_ARBEID, TYPE_TELEFON_MOBIL} from "../utils/telefonUtil";

function getWSTypeTelefon(appType) {
    const wsTyperMap = {
        [TYPE_TELEFON_MOBIL]: 'MOBIL',
        [TYPE_TELEFON_ARBEID]: 'ARB',
    };

    return wsTyperMap[appType];
}


export function createTelefon(telefon, type, deltakernr, evuapiToken) {

    const route = routes.TELEFONER
        .replace(':deltakernr', deltakernr)
        .replace(':type', '');


    const config = createRequestConfig(evuapiToken);

    const input = {
        type: getWSTypeTelefon(type),
        ...transformTelefonToWSFormat(telefon)
    };

    return axios.post(route, input, config)
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error;
        });

}


export function deleteTelefon(type, deltakernr, evuapiToken) {

    const route = routes.TELEFONER
        .replace(':deltakernr', deltakernr)
        .replace(':type', getWSTypeTelefon(type));


    const config = createRequestConfig(evuapiToken);

    return axios.delete(route, config)
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error;
        });
}


export function updateTelefon(telefon, type, deltakernr, evuapiToken) {

    const route = routes.TELEFONER
        .replace(':deltakernr', deltakernr)
        .replace(':type', getWSTypeTelefon(type));


    const config = createRequestConfig(evuapiToken);

    const input = transformTelefonToWSFormat(telefon);
    return axios.post(route, input, config)
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error;
        });
}


function transformTelefonToWSFormat(telefon) {

    if (!telefon)
        return undefined;

    return {
        telefonlandnummer: telefon.landskode || NORGE_KODE,
        landnummer: telefon.landnummer || NORGE_LANDNUMMER,
        nummer: telefon.nummer,
    }
}