import React from "react";
import {isLoggedIn} from "../ducks/session";
import {connect} from "react-redux";
import {pages} from "../utils/common";
import PropTypes from "prop-types";
import Redirect from "./Redirect";

const Landingsside = ({isLoggedIn}) =>
    (isLoggedIn ? <Redirect to={pages.minSide}/> : <Redirect to={pages.kurs}/>)


const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
    }
};


Landingsside.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
};


export default connect(mapStateToProps)(Landingsside);