import {injectIntl} from "react-intl";
import {useDispatch} from "react-redux";
import Checkbox from "../../primitives/Checkbox";
import React from "react";
import HTMLReactParser from "html-react-parser";

import './samtykke.scss';
import {submitResponse} from "../../ducks/samtykke";
import PropTypes from "prop-types";
import FeedbackWSError from "../FeedbackWSError";

const Samtykke = ({kode, sporsmal, langSporsmal, undertekst, langUndertekst, besvarelse, isLoading, error, intl}) => {
    const dispatch = useDispatch();
    const {formatMessage} = intl;
    return (
        <fieldset className='samtykke'>
            <legend lang={langSporsmal}>
                {HTMLReactParser(sporsmal)}
            </legend>
            {undertekst &&
            <p lang={langUndertekst}>
                {HTMLReactParser(undertekst)}
            </p>
            }
            <Checkbox
                label={formatMessage({id: 'ja'})}
                checked={besvarelse === true}
                loading={isLoading}
                onChange={() => dispatch(submitResponse(kode, true))}
            />
            <Checkbox
                label={formatMessage({id: 'nei'})}
                checked={besvarelse === false}
                loading={isLoading}
                onChange={() => dispatch(submitResponse(kode, false))}
            />
            <FeedbackWSError error={error}/>
        </fieldset>
    )
};

Samtykke.propTypes = {
    kode: PropTypes.string.isRequired,
    sporsmal: PropTypes.string.isRequired,
    undertekst: PropTypes.string.isRequired,
    besvarelse: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
};

export default injectIntl(Samtykke);