import ClickableArea from "../ClickableArea";
import React from "react";
import PropTypes from "prop-types";

const InlinePresentation = ({children, openEditing, className}) => {
    return (
        <ClickableArea onClick={openEditing} className={className}>
            {children}
        </ClickableArea>
    )
};

InlinePresentation.propTypes = {
    children: PropTypes.node.isRequired,
    openEditing: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default InlinePresentation;