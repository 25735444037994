import {useSelector} from "react-redux";
import {getLedigeDokumenterByType} from "../../../ducks/soknadsdokumenter";
import Feedback from "../../Feedback";
import Text from "../../../primitives/Text";
import {getSperreSlettingFraDokumentarkiv} from "../../../ducks/dokumenter";
import useDeleteDocument from "../../../hooks/useDeleteDocument";
import Checkbox from "../../../primitives/Checkbox";
import {DownloadLink} from "../../dokumentasjon/DownloadLink";
import FormattedDate from "../../../primitives/FormattedDate";
import Button from "../../../primitives/Button";
import {deleteIconPath} from "../../../utils/icons";
import React, {useRef} from "react";
import useMoveFocusToNextElementDocumentation from "../../../hooks/useMoveFocusToNextElementDocumentation";


const TidligereOpplastetDokumenter = ({type, selected, setSelected}) => {

    const dokumenter = useSelector(state => getLedigeDokumenterByType(state, type));
    const isChecked = dokumentId => selected.includes(dokumentId);

    const checkboxOnChange = dokumentId => () => {
        const nySelected =
            isChecked(dokumentId) ?
                selected.filter(id => id !== dokumentId)
                :
                [...selected, dokumentId];

        setSelected(nySelected);
    };

    if (!dokumenter.length) {
        return (
            <Feedback>
                <Text id='du_har_ingen_tidligere_opplastet_dokumenter_under_den_kategorien'/>
            </Feedback>
        )
    }

    return (
        <div className='tidligere-opplastet'>
            <ol>
                {dokumenter.map(dokument =>
                    <Dokument
                        key={dokument.id}
                        {...dokument}
                        isChecked={isChecked(dokument.id)}
                        checkboxOnChange={checkboxOnChange(dokument.id)}
                    />)
                }
            </ol>
        </div>
    )
};


const Dokument = ({id, filnavn, datoOpplastet, isChecked, checkboxOnChange}) => {

    const sperreSletting = useSelector(state => getSperreSlettingFraDokumentarkiv(state, id));
    const [deleteDocument, isUnderDeletion, isDeleted] = useDeleteDocument(id, filnavn);
    const ref = useRef(null);
    useMoveFocusToNextElementDocumentation(ref, isDeleted, 'input[type=checkbox]', true)

    const className = `tidligere-opplastet__dokument ${isChecked && 'selected'} ${isDeleted && 'deleted'}`;

    return <li className={className}>
        <div className='tidligere-opplastet__checkbox'>
            <Checkbox
                onChange={checkboxOnChange}
                checked={isChecked}
                textForScreenReader={<Text id={"legg_ved_filen"} filnavn={filnavn} />}
            />
        </div>
        <div className='tidligere-opplastet__filnavn'>
            <DownloadLink dokumentnr={id} filnavn={filnavn}/>
        </div>
        <div className='tidligere-opplastet__dato'>
            <FormattedDate date={datoOpplastet} withTime={true}/>
        </div>
        <div className='tidligere-opplastet__actions'>
            {!sperreSletting &&
            <div ref={ref}>
                <Button onClick={deleteDocument}
                        iconPath={deleteIconPath}
                        altText='slett'
                        isInProgress={isUnderDeletion}
                        isSuccessfullyDone={isDeleted}
                />
            </div>
            }
        </div>
    </li>

};

export default TidligereOpplastetDokumenter;