import * as routes from "./apiRoutes";
import axios from "axios/index";
import dateFormat from "dateformat";
import {createTelefon} from "../ducks/nyBruker";
import {NORGE_KODE} from "../utils/common";
import {NORGE_LANDNUMMER} from "../ducks/land";


export function getPersonData(instnr, dataportenToken) {

    // return new Promise(
    //     (resolve) => {
    //         setTimeout(
    //             () => {
    //                 console.log('Dummy-getter opprett bruker');
    //                 resolve(transformDataFromWS({
    //                     "fornavn": "Goran León-Marley",
    //                     "etternavn": "Grimnes",
    //                     "kjonn": "M",
    //                     "dato_fodt": "1984-02-25",
    //                     "epostadresse": "kjetihoy+gorgri296658@unit.no",
    //                     "epostadresseArbeid": "",
    //                     "epostadresseFraKorr": true,
    //                     "statsborgerskaplandnr": 616,
    //                     "malform": "BOKMÅL",
    //                     "harFnr": true,
    //                     "personinfoHentetFra": "FINNES_I_DSF",
    //                     "kanRedigerePersoninfo": false,
    //                     "mobiltelefon": {
    //                         type: "MOBIL",
    //                         telefonlandnummer: "505",
    //                         landnummer: 664,
    //                         nummer: "123159",
    //                         telefonnrFraKorr: true
    //                     }
    //                 }))
    //             },
    //             1000)
    //     }
    // );


    const route = routes.OPPRETT_BRUKER.replace(':inst', instnr);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + dataportenToken,
        }
    };

    return axios.get(route, config)
        .then(response => {
            return transformDataFromWS(response.data);
        })
        .catch(error => {
            throw error;
        });
}


function transformDataFromWS(data) {
    return {
        fornavn: data.fornavn || '',
        etternavn: data.etternavn || '',
        fodselsdato: data.dato_fodt,
        kjonn: data.kjonn || '',
        statsborgerskap: data.statsborgerskaplandnr,
        malform: data.malform || '',
        epost: data.epostadresse || '',
        epostArbeid: data.epostadresseArbeid || '',
        telefon: transformTelefonFromWS(data.mobiltelefon),
        epostFraKorr: data.epostadresseFraKorr,
        mobilFraKorr: data.mobiltelefon && data.mobiltelefon.telefonnrFraKorr,
        personinfoHentetFra: data.personinfoHentetFra,
        kanRedigerePersoninfo: data.kanRedigerePersoninfo,
    }
}

function transformTelefonFromWS(telefon) {

    if (!telefon) {
        return undefined;
    }

    return createTelefon({
            nummer: telefon.nummer,
            landnummer: telefon.landnummer,
            landskode: telefon.telefonlandnummer,
        }
    )
}

function transformDataToWSFormat(data) {
    const telefon = data.telefon;
    return {
        fornavn: data.fornavn,
        etternavn: data.etternavn,
        kjonn: data.kjonn,
        epostadresse: data.epost,
        epostadresseArbeid: data.epostArbeid,
        datoFodt: data.fodselsdato && dateFormat(new Date(data.fodselsdato), 'dd.mm.yyyy'),
        statsborgerskaplandnr: data.statsborgerskap,
        malform: data.malform,
        telefonlandnummer: telefon && (telefon.landskode || NORGE_KODE),
        landnummer: telefon && (telefon.landnummer || NORGE_LANDNUMMER),
        nummer: telefon && telefon.nummer
    }
}


export async function opprettBruker(data, instnr, dataportenToken) {

    // await sleep(1000);
    // return Promise.resolve('OK');
    // return Promise.reject(createCustomResponseError(409010));

    const route = routes.OPPRETT_BRUKER.replace(':inst', instnr);
    const input = transformDataToWSFormat(data);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + dataportenToken
        }
    };

    return axios.post(route, input, config)
        .then(response => {
            return response;
        })
        .catch(error => {
            throw error;
        });
}
