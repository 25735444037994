import React from 'react';
import {useSelector} from "react-redux";
import withGSK from "../../high-order-components/withGSK";
import {
    getVitnemal, harGSK as harGSKSelektor,
} from "../../ducks/dokumenter";
import FormattedDate from "../../primitives/FormattedDate";
import './gsk.scss'
import GSKGodkjent from "./GSKGodkjent";
import Text from "../../primitives/Text";


const GSK = () => {
    const harGSK = useSelector(harGSKSelektor);
    const vitnemalListe = useSelector(getVitnemal);

    if (harGSK) return <GSKGodkjent/>;

    if (vitnemalListe.length > 0) return (
        <div className='gsk-vedtak with-list'>
            <Text id='du_er_ikke_registrert_med_GSK_vi_har_innhentet_dokumenter_elektronisk'/>
            <VitnemalListe vitnemalListe={vitnemalListe}/>
        </div>
    );

    return null;
};


const VitnemalListe = ({vitnemalListe}) => {
    return (
        <ul>
            {vitnemalListe.map((props, id) => <Vitnemal key={id} {...props}/>)}
        </ul>
    )
};

const Vitnemal = ({doktypekode, dokumentnr, skolenavn, datoUtstedt, isGskOk}) => {
    const dokumentType = doktypekode === 'VM' ? 'vitnemål' : 'kompetansebevis';
    const gskTextId = isGskOk ? 'har_GSK' : 'uten_GSK';
    return (
        <li>
            <div><Text id={dokumentType}/> nr.{dokumentnr},</div>
            <div>{skolenavn}, <FormattedDate date={datoUtstedt}/></div>
            <div><Text id={gskTextId}/></div>
        </li>
    )
};


export default withGSK(GSK);

