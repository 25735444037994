import React from 'react'
import Kontaktinfo from "../../profil/Kontaktinfo";
import ObligatoriskAdvarsel from "../../profil/ObligatoriskAdvarsel";
import './steg-profil.scss';
import Feedback, {FEEDBACK_TYPE_FEIL} from "../../Feedback";
import Text from "../../../primitives/Text";
import {useSelector} from "react-redux";
import {getForsoktNeste} from "../../../ducks/pamelding";
import {isProfilFormValid as getIsProfilFormValid} from "../../../ducks/profil";

const StegProfil = () => {
    const showErrors = useSelector(getForsoktNeste);
    const isProfilFormValid = useSelector(getIsProfilFormValid);
    return (
        <div className='steg-profil'>
            <Kontaktinfo page='stegProfil'/>
            <ObligatoriskAdvarsel/>
            {!isProfilFormValid && showErrors &&
            <Feedback type={FEEDBACK_TYPE_FEIL}>
                <Text id='kontaktinfo_inneholder_feil'/>
            </Feedback>
            }
        </div>
    );
};

export default StegProfil