import React from "react";
import {Link} from "react-router-dom";
import {pages} from "../utils/common";
import PageTitle from "./PageTitle";
import Text from "../primitives/Text";

const LocalStorage = () => (
    <div id="local-storage">
        <PageTitle id='lokal_lagring'/>
        <h1>
            <Text id="lokal_lagring"/>
        </h1>
        <p>
            <Text id="for_å_kunne_bruke_EVUweb_trenger_vi_at_du_har_aktivert_lokal_lagring"/>
        </p>
        <p>
            <Text
                id="for_mer_informasjon_om_hvordan_vi_bruker_dette"
                values={{
                    link:
                        <Link to={pages.cookies} className='link'>
                            <Text id="for_mer_informasjon_om_hvordan_vi_bruker_dette_link_tekst"/>
                        </Link>
                }}
            />
        </p>
        <p>
            <Text
                id="når_du_har_aktivert_lokal_lagring"
                values={{
                    link:
                        <Link to={pages.institusjon} className='link'>
                            <Text id="når_du_har_aktivert_lokal_lagring_link_tekst"/>
                        </Link>
                }}
            />
        </p>
    </div>
);

export default LocalStorage;