import {useDispatch, useSelector} from "react-redux";
import {fullforSoknad, getSkalGodkjennes, isSoknadValid, kanFullfores} from "../../../ducks/pamelding";
import Button, {PRIMARY} from "../../../primitives/Button";
import React from "react";
import {getKurs} from "../../../ducks/kursinfo";
import {getFulltKursVarsel} from "../../../utils/kursVarsel";
import Text from "../../../primitives/Text";

const ButtonFullfor = () => {

    const kurs = useSelector(getKurs);
    const soknadKanFullfores = useSelector(kanFullfores);
    const valid = useSelector(isSoknadValid);
    const dispatch = useDispatch();

    const {knappTextId: varselTextId, disabledPamelding} = getFulltKursVarsel(kurs);

    const knappTextId = useKnappTekst(varselTextId);

    return (
        <Button
            emphasis={PRIMARY}
            onClick={() => dispatch(fullforSoknad())}
            disabled={!soknadKanFullfores || !valid || disabledPamelding}
        >
            <Text id={knappTextId}/>
        </Button>
    )
};

const useKnappTekst = varselTextId => {
    const skalGodkjennes = useSelector(getSkalGodkjennes);
    if(varselTextId){
        return varselTextId;
    }
    return skalGodkjennes ? 'send_til_godkjenning' : 'fullfør';
};

export default ButtonFullfor;