import React from 'react';
import Popup from './Popup';
import Header from "./header/Header";
import Container from "./Container";
import Footer from "./footer/Footer";
import Menubar from "./menubar/Menubar";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import MinSide from "./min-side/MinSide";
import Profil from "./profil/Profil";
import Soknader from "./soknader/Soknader";
import Pamelding from "./pamelding/Pamelding";
import LoginHandler from "./login/LoginHandler";
import Login from "./login/Login";
import NyBruker from "./ny-bruker/NyBruker";
import Institusjon from "./institusjon/Institusjon";
import Cookies from "./Cookies";
import NoRoute from "./NoRoute";
import LocalStorageCheck from "./LocalStorageCheck";
import LocalStorage from "./LocalStorage";
import Kursoversikt from "./kursoversikt/Kursoversikt";
import Kursinfo from "./kursside/Kursinfo";
import ScrollToTop from "./ScrollToTop";
import Landingsside from "./Landingsside";
import Dokumenter from "./dokumentasjon/Dokumentasjon";
import MineKurs from "./mine-kurs/MineKurs";
import Samtykker from "./samtykke/Samtykker";
import useInstFromLocation from "../hooks/useInstFromLocation";
import withInstitusjonerLoaded from "../high-order-components/withInstitusjonerLoaded";
import useDeltakernummerFromLocation from "../hooks/useDeltakernummerFromLocation";
import Foretaksgodkjenning from "./foretaksgodkjenning/Foretaksgodkjenning";
import Fakturaoversikt from "./fakturaoversikt/Fakturaoversikt";
import useBedriftsbrukerFromLocation from "../hooks/useBedriftsbrukerFromLocation";
import useForetakkodeHashFromLocation from "../hooks/useForetakkodeHashFromLocation";
import Meldingsarkiv from "./meldingsarkiv/Meldingsarkiv";
import {IntlProvider} from "react-intl";
import {useSelector} from "react-redux";
import {getLocale} from "../ducks/i18n";
import {MESSAGES} from "../utils/i18nUtil";
import Redirect from "./Redirect";
import {appendScript, removeScript} from "../utils/matomoUtils";


const LocationWrapper = withInstitusjonerLoaded(({children}) => {
    useInstFromLocation();
    useDeltakernummerFromLocation();
    useBedriftsbrukerFromLocation();
    useForetakkodeHashFromLocation();

    //Matomo. Fjerner scriptet
    removeScript("/evuweb/matomoSettings.js")
    removeScript("/evuweb/matomo.js")
    //Matomo. Legger til scriptene igjen
    appendScript("/evuweb/matomoSettings.js")
    appendScript("/evuweb/matomo.js")

    return children;
});

const App = () => {
        const locale = useSelector(getLocale);
        return (
            <IntlProvider locale={locale} messages={MESSAGES[locale]}>
                <Router>
                    <LocationWrapper>
                        <ScrollToTop/>
                        <Menubar/>
                        <Header/>
                        <main id='content' tabIndex="-1">
                            <Container>
                                <Routes>
                                    <Route path="/" element={<Redirect to={"/evuweb"}/>}/>
                                    <Route path="/evuweb/" element={<Landingsside/>}/>
                                    <Route path="/evuweb/kurs/:kursId" element={<Kursinfo/>}/>
                                    <Route path="/evuweb/kurs" element={<Kursoversikt/>}/>
                                    <Route path="/evuweb/min-side/" element={<MinSide/>}/>
                                    <Route path="/evuweb/profil/" element={<Profil/>}/>
                                    <Route path="/evuweb/soknader/" element={<Soknader/>}/>
                                    <Route path="/evuweb/pamelding/:kursId" element={<Pamelding/>}/>
                                    <Route path="/evuweb/dokumentasjon/" element={<Dokumenter/>}/>
                                    <Route path="/evuweb/meldingsarkiv/" element={<Meldingsarkiv/>}/>
                                    <Route path="/evuweb/mine-kurs/" element={<MineKurs/>}/>
                                    <Route path="/evuweb/login/" element={<Login/>}/>
                                    <Route path="/evuweb/loginHandler/" element={<LoginHandler/>}/>
                                    <Route path="/evuweb/ny-bruker/" element={<NyBruker/>}/>
                                    <Route path="/evuweb/institusjon/" element={<Institusjon/>}/>
                                    <Route path="/evuweb/cookies/" element={<Cookies/>}/>
                                    <Route path="/evuweb/local-storage/" element={<LocalStorage/>}/>
                                    <Route path="/evuweb/samtykke/" element={<Samtykker/>}/>
                                    <Route path="/evuweb/fakturaoversikt/" element={<Fakturaoversikt/>}/>
                                    <Route path="/evuweb/foretaksgodkjenning/" element={<Foretaksgodkjenning/>}/>
                                    <Route path="*" element={<NoRoute/>}/>
                                </Routes>
                            </Container>
                        </main>
                        <Popup/>
                        <Footer/>
                        <LocalStorageCheck/>
                    </LocationWrapper>
                </Router>
            </IntlProvider>
        )
    }
;

export default App;