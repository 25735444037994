import React, {useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fjernTilknytning, getIsNyTilknyttningLoadingByType,
    getTilknyttedeDokumenterByType, isTilknytningLoading
} from "../../../ducks/soknadsdokumenter";
import Button from "../../../primitives/Button";
import {DownloadLink} from "../../dokumentasjon/DownloadLink";
import './tilknyttede-dokumenter.scss';
import {injectIntl} from "react-intl";
import Text from "../../../primitives/Text";
import {showPopup} from "../../../ducks/popup";
import Spinner from "../../Spinner";
import {getIsUploadingByType} from "../../../ducks/dokumenter";
import Icon from "../../../primitives/Icon";
import {mdiCloseCircle, mdiFileOutline, mdiPaperclip} from "@mdi/js";
import useMoveFocusToNextElementDocumentation from "../../../hooks/useMoveFocusToNextElementDocumentation";

const TilknyttedeDokumenter = ({type, withActions}) => {

    const dokumenter = useSelector(state => getTilknyttedeDokumenterByType(state, type));
    const isTilknytting = useSelector(state => getIsNyTilknyttningLoadingByType(state, type));
    const isUploadingDokument = useSelector(state => getIsUploadingByType(state, type));
    const isLoading = isTilknytting || isUploadingDokument;


    if (!dokumenter.length) {
        return <div className='tilknyttede-dokumenter'>
            <Icon path={mdiPaperclip} className='ingen-filer-icon'/>
            <em><Text id='ingen_filer_vedlagt'/></em>
            {isLoading && <Spinner/>}
        </div>
    }

    return (
        <div className='tilknyttede-dokumenter'>
            <ul>
                {dokumenter.map(dokument => <TilknyttetDokument key={dokument.id} {...dokument}
                                                                withActions={withActions}/>)}
                {isLoading && <li><Spinner/></li>}
            </ul>
        </div>
    )
};

const TilknyttetDokument = ({id, filnavn, withActions}) =>
    <li className='tilknyttet-dokument'>
        <Icon path={mdiFileOutline} title='vedlegg' className='file-icon'/>
        <DownloadLink dokumentnr={id} filnavn={filnavn}/>
        {withActions && <RemoveButton id={id}/>}
    </li>;


const RemoveButton = injectIntl(({intl, id}) => {
    const isInProgress = useSelector(state => isTilknytningLoading(state, id));
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [isFjernerTilknytning, setIsFjernerTilknytning] = useState(false);

    useMoveFocusToNextElementDocumentation(ref, isFjernerTilknytning, 'a');

    const slettOnClick = () => {
        const popUpContent = {
            onOK: () => {dispatch(fjernTilknytning(id)); setIsFjernerTilknytning(true)},
            title: <Text id='fjerne_tilknytning'/>,
            children: <Text id='er_du_sikker_på_at_du_vil_fjerne_tilknytningen'/>,
            okButtonContent: <Text id='ja'/>,
            cancelButtonContent: <Text id='nei'/>,
        };
        dispatch(showPopup(popUpContent));
    };

    return (
        <div className='inline-block'>
            <div ref={ref}>
                <Button
                    onClick={slettOnClick}
                    iconPath={mdiCloseCircle}
                    className='fjern-tilknytning-button'
                    isInProgress={isInProgress}
                    altText='fjern_tilknytning'
                />
            </div>
        </div>
    )
});


export default TilknyttedeDokumenter;