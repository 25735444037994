import {
    formatAmountByLocale,
    formatDateByLocale, getTranslationLangByLanguage,
    getValueByLanguage,
} from '../utils/common'
import {I18N_LOCAL_STORAGE_KEY, saveToLocalStorage} from "../utils/sessionUtil";
import {getInitialLocale, setHtmlLang} from "../utils/i18nUtil";

export const NAME = 'intl';
export const SET_LOCALE = `${NAME}/SET_LOCALE`;

export const changeLocale = locale => dispatch => {
    setHtmlLang(locale);
    saveToLocalStorage(I18N_LOCAL_STORAGE_KEY, locale);
    dispatch(setLocale(locale));
};

export function setLocale(locale) {
    return {
        type: SET_LOCALE,
        locale: locale
    }
}

export const initialState = {
    locale: getInitialLocale(),
};

function i18n(state = initialState, action) {
    switch (action.type) {
        case SET_LOCALE:
            return {
                ...state,
                locale: action.locale,
            };
        default:
            return state;
    }
}

export function getState(state) {
    return state[NAME];
}

export function getLocale(state) {
    return getState(state).locale;
}

export function getMessages(state) {
    return getState(state).messages;
}

export function getExternalMessages(state) {
    return getState(state).externalMessages;
}

export function getTranslationForList(state, translations) {
    return translations[getLocale(state)];
}

export function getTranslation(state, translations) {
    return getValueByLanguage(translations, getLocale(state));
}

export function getTranslationLang(state, translations) {
    return getTranslationLangByLanguage(translations, getLocale(state));
}

export function formatDate(state, date, withTime) {
    return formatDateByLocale(date, getLocale(state), withTime);
}

export function formatAmount(state, amount) {
    return formatAmountByLocale(amount, getLocale(state));
}

export default i18n;