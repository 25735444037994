import React from 'react';
import Text from "../../../primitives/Text";

const GodkjenningsFeil = ({antallFeil, antallGodkjenninger}) => {
    return (
        <Text
            id={antallGodkjenninger === 1 ? 'feil_ved_innsendig_av_svar' : 'antall_feil_av_antall_godkjenninger_svar_feilet_ved_innsending'}
            antallFeil={antallFeil}
            antallGodkjenninger={antallGodkjenninger}
        />
    )
};

export default GodkjenningsFeil;