import React from 'react';
import {getAntallSoknaderSomManglerFakturainfo, getSoknaderSomManglerFakturainfo,} from "../../../ducks/soknader";
import {useSelector} from "react-redux";
import Card from "../Card";
import {soknaderIconPath} from "../../../utils/icons";
import {getFirstOf, getPathToSteg, pages} from "../../../utils/common";
import withSoknaderLoaded from "../../../high-order-components/withSoknaderLoaded";
import Button from "../../../primitives/Button";
import {STEG_TYPE_FAKTURAINFO} from "../../pamelding/steg/Steg";
import {compose} from "redux";
import {requireDeltakernummer} from "../../../high-order-components/requireDeltakernummer";
import {ACTION_TYPE_ENDRE_FAKTURAINFO} from "../../../utils/soknadStatus";
import FormattedDate from "../../../primitives/FormattedDate";
import Text from "../../../primitives/Text";
import {useNavigate} from "react-router";

const VarselSoknaderManglerFakturainfo = () => {

    const soknaderSomManglerFakturainfo = useSelector(getSoknaderSomManglerFakturainfo);
    const antallSoknaderSomManglerFakturainfo = useSelector(getAntallSoknaderSomManglerFakturainfo);

    if (antallSoknaderSomManglerFakturainfo === 0) return null;

    const {actions, kursnavn} = getFirstOf(soknaderSomManglerFakturainfo);
    const action = actions.find(action => action.type === ACTION_TYPE_ENDRE_FAKTURAINFO);
    const frist = <FormattedDate date={action.frist} withTime={true} />;
    const textId = antallSoknaderSomManglerFakturainfo === 1 ? 'din_soknad_på_kurset_kurs_mangler_betalingsinformasjon' : 'du_har_soknader_som_mangler_fakturainfo';

    return (
        <Card
            tittel={<Text id='fakturainfo_mangler'/>}
            varsel={true}
            iconPath={soknaderIconPath}
            actions={{
                lenke: antallSoknaderSomManglerFakturainfo > 1 && {
                    tekst: <Text id='gå_til_søknader'/>,
                    til: pages.soknader
                },
                button: <LeggTilButton/>
            }}
        >
            <Text
                id={textId}
                antallSoknader={antallSoknaderSomManglerFakturainfo}
                frist={frist}
                kursnavn={kursnavn}
            />
        </Card>
    )
};

const LeggTilButton = () => {
    const soknaderSomManglerFakturainfo = useSelector(getSoknaderSomManglerFakturainfo);
    const antallSoknaderSomManglerFakturainfo = useSelector(getAntallSoknaderSomManglerFakturainfo);
    const navigate = useNavigate();

    if (antallSoknaderSomManglerFakturainfo !== 1) return null;

    const {id} = soknaderSomManglerFakturainfo[0];
    const path = getPathToSteg(id, STEG_TYPE_FAKTURAINFO);

    return (
        <Button onClick={() => navigate(path)}>
            <Text id='legg_til_fakturainfo'/>
        </Button>
    )
};

export default compose(
    Component => requireDeltakernummer(Component, false),
    withSoknaderLoaded,
)(VarselSoknaderManglerFakturainfo);