import React from 'react';
import {
    getError,
    isDataLoaded,
    isLoading,
    loadDataForNyBruker,
} from '../ducks/nyBruker';

import InitialLoadWrapper from "../wrappers/InitialLoadWrapper";
import Text from "../primitives/Text";


const withPersonDataForNyBruker = Component => props => {
    return (
        <InitialLoadWrapper
            loadAction={loadDataForNyBruker}
            loadingSelector={isLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getError}
            loadMessage={<Text id='laster_inn_persondata'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )
};


export default withPersonDataForNyBruker;