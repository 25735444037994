import React from 'react';
import PropTypes from 'prop-types'
import {getFritekstSok} from "../../ducks/kursoversikt";
import {useSelector} from "react-redux";
import Highlight from "./Highlight";
import KursPreviewActions from "./KursPreviewActions";
import {formatDate} from "../../ducks/i18n";
import Infoelement from "../Infoelement";
import {fristHarUtgatt} from "../../utils/common";
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import {getFulltKursVarsel} from "../../utils/kursVarsel";
import {finnesFullfortSoknadForKurs} from "../../ducks/soknader";
import {EksamenTag} from "../kursside/EksamenTag";
import Text from "../../primitives/Text";
import {mdiTimerOutline, mdiCalendarRange, mdiTagTextOutline, mdiArrowRight} from '@mdi/js';
import Icon from "../../primitives/Icon";


const KursPreview = ({kurs}) => {

    const {
        id,
        kursnavn,
        startdato,
        sluttdato,
        kategorier,
        pris,
        harDetaljer,
        eksamen,
        nettbasert,
        samlingsbasert,
        pameldingsfrist,
        direktePamelding,
    } = kurs;

    const harFullfortSoknad = useSelector(state => finnesFullfortSoknadForKurs(state, id));
    const {varselTextId, knappTextId, disabledPamelding} = getFulltKursVarsel(kurs, harFullfortSoknad);

    const fritekstSok = useSelector(getFritekstSok);
    const pameldingsfristFormatert = useSelector(state => formatDate(state, pameldingsfrist));


    return (
        <div className="kursliste__kurs__preview">
            <h2>
                <Highlight
                    pattern={fritekstSok}
                >
                    {kursnavn}
                </Highlight>
            </h2>
            <div className="kursliste__kurs__preview__kategorier">
                <EksamenTag
                    className='kursliste__kurs__preview__kategorier__kategori'
                    eksamen={eksamen}
                />
                {nettbasert &&
                <Tag className='nettbasert'>
                    <Text id={'nettbasert'}/>
                </Tag>
                }
                {samlingsbasert &&
                <Tag className='nettbasert'>
                    <Text id={'samlingsbasert'}/>
                </Tag>
                }
                {kategorier.map(kategori =>
                    <Tag key={kategori.kode}>
                        {kategori.kategorinavn}
                    </Tag>
                )}
            </div>
            {varselTextId &&
            <Feedback type={FEEDBACK_TYPE_VARSEL} showIcon={false}>
                <Text id={varselTextId}/>
            </Feedback>
            }
            <div className="kursliste__kurs__preview__info">
                <Infoelement
                    title={direktePamelding ? <Text id='påmeldingsfrist'/> : <Text id='søknadsfrist'/>}
                    value={pameldingsfristFormatert}
                    iconPath={mdiTimerOutline}
                    extraText={fristHarUtgatt(pameldingsfrist) && <Text id='utgått'/>}
                />
                {startdato &&
                <Infoelement
                    title={<Text id='datoer'/>}
                    value={<>{startdato} <Icon path={mdiArrowRight} title='til'/> {sluttdato}</>}
                    iconPath={mdiCalendarRange}
                />
                }
                {pris !== 0 &&
                <Infoelement
                    title={<Text id='pris'/>}
                    value={`${Math.round(pris)} kr`}
                    iconPath={mdiTagTextOutline}
                />
                }
            </div>
            <KursPreviewActions
                id={id}
                pameldingsfrist={pameldingsfrist}
                direktePamelding={direktePamelding}
                harDetaljer={harDetaljer}
                disablePamelding={disabledPamelding}
                pameldingButtonOverrideTextId={knappTextId}
            />
        </div>
    );
};

const Tag = ({className, children}) =>
    <span
        className={`kursliste__kurs__preview__kategorier__kategori ${className}`}
    >
        {children}
    </span>
;


KursPreview.propTypes = {
    kurs: PropTypes.shape({
        id: PropTypes.shape({
            kurskode: PropTypes.string.isRequired,
            tidskode: PropTypes.string.isRequired
        }).isRequired,
        kategorier: PropTypes.arrayOf(PropTypes.shape({
            kategorinavn: PropTypes.string.isRequired,
            kode: PropTypes.string.isRequired,
        })).isRequired,
        kursnavn: PropTypes.string.isRequired,
        startdato: PropTypes.string,
        sluttdato: PropTypes.string,
        pameldingsfrist: PropTypes.object,
        pris: PropTypes.number.isRequired,
        harDetaljer: PropTypes.bool.isRequired,
        eksamen: PropTypes.string.isRequired,
        nettbasert: PropTypes.bool.isRequired,
        antallLedigePlasser: PropTypes.number,
        automatiskStenging: PropTypes.bool,
        automatiskTilbud: PropTypes.bool,
        automatiskJaSvar: PropTypes.bool,
        automatiskVenteliste: PropTypes.bool,
    }).isRequired,
};

export default KursPreview;