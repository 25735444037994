import {
    mdiAccount,
    mdiAccountBoxMultiple,
    mdiBallotOutline,
    mdiCash, mdiCellphone, mdiCheck, mdiChevronDown,
    mdiClipboardText, mdiCloudUpload, mdiDelete, mdiDomain, mdiEmail,
    mdiPencil, mdiRefresh
} from "@mdi/js";

export const soknaderIconPath = mdiClipboardText;
export const brukerIconPath = mdiAccount;
export const samtykkeIconPath = mdiBallotOutline;
export const fakturaIconPath = mdiCash;
export const foretaksgodkjenningIconPath = mdiAccountBoxMultiple;

export const endreIconPath = mdiPencil;
export const uploadIconPath = mdiCloudUpload;
export const tryAgainIconPath = mdiRefresh;
export const deleteIconPath = mdiDelete;
export const checkmarkIconPath = mdiCheck;
export const menuDownIconPath = mdiChevronDown;

export const emailIconPath = mdiEmail;
export const personIconPath = mdiAccount;
export const workIconPath = mdiDomain;
export const telefonIconPaths = {
    mobiltelefon: mdiCellphone,
    arbeidstelefon: mdiDomain,
};