import React from "react";
import Text from "../../../primitives/Text";
import "./dsf-advarsel.scss";
import SyncMedDSF from "./SyncMedDSF";
import {useSelector} from "react-redux";
import {getKanSynkeDsf} from "../../../ducks/session";


const DSFAdvarsel = ({typeFelt}) => {
    const kanSynkeDsf = useSelector(getKanSynkeDsf);
    return (
        <div className='dsf-advarsel'>
            <div>
                <Text id='vi_har_hentet_ditt_felt_fra_folkeregisteret'
                      values={{dittFelt: <Text id={typeFelt}/>}}
                />
                {kanSynkeDsf && <SyncMedDSF/>}
            </div>
        </div>
    )
};

export default DSFAdvarsel;