import Text from "../../primitives/Text";
import {RadioButton} from "../../primitives/RadioButton";
import {useDispatch, useSelector} from "react-redux";
import {getPris} from "../../ducks/kursinfo";
import FormattedAmount from "../../primitives/FormattedAmount";
import React from "react";
import {
    getErrorLagringForFelt,
    getIsLagringFelt,
    isBedriftBetaler,
    lagreBetalingsinfo,
    setBedriftBetaler,
} from "../../ducks/betalingsinfo";
import Spinner from "../Spinner";
import ErrorFormSaving from "../ErrorFormSaving";

const FELT_BEDRIFT_BETALER = 'bedriftBetaler';

const VelgPrivatEllerBedrift = () => {
    const bedriftBetaler = useSelector(isBedriftBetaler);
    const isLagringFelt = useSelector(getIsLagringFelt);
    const isLagring = isLagringFelt === FELT_BEDRIFT_BETALER;
    const error = useSelector(state => getErrorLagringForFelt(state, FELT_BEDRIFT_BETALER));
    const dispatch = useDispatch();
    const onChange = value => {
        dispatch(setBedriftBetaler(value));
        dispatch(lagreBetalingsinfo(FELT_BEDRIFT_BETALER))
    };

    return (
        <div className='privat-bedrift-group input-group'>
            {isLagring && <Spinner/>}
            <fieldset>
                <legend className='privat-bedrift-group__label'>
                    <Text id='velg_hvem_som_skal_få_faktura'/><Pris/>:
                </legend>
                <RadioButton label={'privat_person'}
                             onChange={() => onChange(false)}
                             checked={!bedriftBetaler}/>
                <RadioButton label={'bedrift'}
                             onChange={() => onChange(true)}
                             checked={bedriftBetaler}/>
            </fieldset>
            {error && <ErrorFormSaving error={error}/>}
        </div>
    )
};

const Pris = () => {
    const pris = useSelector(getPris);
    return (
        pris ?
            <> (<FormattedAmount amount={pris}/>)</>
            :
            null
    )
};

export default VelgPrivatEllerBedrift;