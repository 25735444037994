import {useDispatch, useSelector} from "react-redux";
import {
    exitEditMode,
    getEditIsSaving,
    saveArbeidserfaring,
    setEditFieldValue,
    getEditWasSaveClicked,
    getIsEditValid
} from "../../../ducks/arbeidserfaring";
import Text from "../../../primitives/Text";
import Button, {PRIMARY} from "../../../primitives/Button";
import React from "react";

const Actions = () => {
    const dispatch = useDispatch();
    const isSaving = useSelector(getEditIsSaving);
    const isValid = useSelector(getIsEditValid);
    const wasSaveClicked = useSelector(getEditWasSaveClicked);
    const disabled = wasSaveClicked && !isValid;

    const lagreOnClick = () => {
        dispatch(setEditFieldValue({
            field: 'saveClicked',
            value: true,
        }));
        isValid && dispatch(saveArbeidserfaring());
    };

    return (
        <div className='actions'>
            <Button
                onClick={() => dispatch(exitEditMode())}
                disabled={isSaving}
            >
                <Text id={'avbryt'}/>
            </Button>
            <Button
                onClick={lagreOnClick}
                disabled={disabled}
                isInProgress={isSaving}
                emphasis={PRIMARY}
            >
                <Text id={'lagre'}/>
            </Button>
        </div>
    )
};

export default Actions;