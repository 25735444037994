import * as landskoderAPI from "../services/landskoderAPI";
import {getLocale} from "./i18n";
import {getValueByLanguage, NORGE_KODE} from "../utils/common";
import sortBy from 'lodash/sortBy';
import {getInstitusjonsnr} from "./session";


export const NAME = 'landskoder';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;


export function loadPending() {
    return {
        type: LOAD_PENDING,
    };
}

export function loadSuccess(landskoder) {
    return {
        type: LOAD_SUCCESS,
        landskoder: landskoder,
    };
}

export function loadFailure(error) {
    return {
        type: LOAD_FAILURE,
        error: error
    };
}


export function loadLandskoder() {
    return function (dispatch, getState) {
        dispatch(loadPending());

        const instnr = getInstitusjonsnr(getState());
        return landskoderAPI.getLandskoder(instnr).then(
            landskoder => dispatch(loadSuccess(landskoder)),
            error => dispatch(loadFailure(error))
        );
    };
}


const initialState = {
    isLoading: false,
    error: undefined,
    data: [],
};


function landskoder(state = initialState, action) {

    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.landskoder,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME];
}

export function getData(state) {
    return getState(state).data;
}


export function getLandskoder(state) {
    const locale = getLocale(state);

    let result = getData(state).map(land => {
        const landnavn = getValueByLanguage(land.landnavn, locale);
        const landskode = land.telefonlandnr;
        const landnummer = land.landnr;
        return {
            value: {landskode, landnummer},
            label: `${landnavn} (+${landskode})`
        }
    });

    result = sortBy(result, 'label');
    result = withNorgeOnTop(result);

    return result;
}

function withNorgeOnTop(list) {

    const norge = list.find(elem => elem.value.landskode === NORGE_KODE);
    if (!norge) {
        return list;
    }

    return [
        norge,
        ...list.filter(elem => elem.value.landskode !== NORGE_KODE)
    ];

}

export function isLoading(state) {
    return getState(state).isLoading;
}


export function getError(state) {
    return getState(state).error;
}


export function isDataLoaded(state) {
    return Boolean(getData(state).length);
}


export default landskoder;