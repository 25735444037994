import React from 'react';
import InitialLoadWrapper from '../wrappers/InitialLoadWrapper';
import {getError, isDataLoaded, isLoading, loadTidligereKurs} from '../ducks/tidligereKurs';
import Text from "../primitives/Text";

const  withTidligereKursLoaded = Component => props => {
    return (
        <InitialLoadWrapper
            loadAction={loadTidligereKurs}
            loadingSelector={isLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getError}
            loadMessage={<Text id='laster_inn_dine_kurs'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )

};

export default withTidligereKursLoaded;