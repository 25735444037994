import React from "react";
import useInlineEdit from "../../../hooks/useInlineEdit";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormFieldObject, changeTelefonkode,
    getErrorLagring, getFormDataTelefon, getFormErrorsTelefon, hasErrorTelefon,
    lagreTelefon, resetErrorLagringForFelt,
    resetFormdata
} from "../../../ducks/profil";
import InlineEdit from "../../inline-edit/InlineEdit";
import TelefonForm from "./TelefonForm";
import TelefonPresentation from "./TelefonPresentation";
import {TYPE_TELEFON_ARBEID} from "../../../utils/telefonUtil";
import ErrorFormSaving from "../../ErrorFormSaving";


export const Arbeidstelefon = () => {
    const telefon = useSelector(state => getFormDataTelefon(state, TYPE_TELEFON_ARBEID));
    const errors = useSelector(state => getFormErrorsTelefon(state, TYPE_TELEFON_ARBEID));
    const errorLagring = useSelector(state => getErrorLagring(state, TYPE_TELEFON_ARBEID));
    const isChangesValid = useSelector(state => !hasErrorTelefon(state, TYPE_TELEFON_ARBEID));

    const dispatch = useDispatch();
    const submitChanges = () => dispatch(lagreTelefon(TYPE_TELEFON_ARBEID));
    const resetChanges = () => dispatch(resetFormdata());
    const onChangeKode = ({landskode, landnummer}) => {
        dispatch(changeTelefonkode(TYPE_TELEFON_ARBEID, landskode, landnummer));
        dispatch(resetErrorLagringForFelt(TYPE_TELEFON_ARBEID));
    };
    const onChangeNummer = value => {
        dispatch(changeFormFieldObject('nummer', value, TYPE_TELEFON_ARBEID));
        dispatch(resetErrorLagringForFelt(TYPE_TELEFON_ARBEID));
    };

    const [isInEditMode, openEditing, closeEditing, attemptedClosed] = useInlineEdit(submitChanges, isChangesValid, errorLagring);

    return (
        <InlineEdit
            resetChanges={resetChanges}
            isInEditMode={isInEditMode}
            openEditing={openEditing}
            closeEditing={closeEditing}
        >
            <TelefonForm
                typeTelefon={TYPE_TELEFON_ARBEID}
                onChangeKode={onChangeKode}
                onChangeNummer={onChangeNummer}
                telefon={telefon}
                errors={errors}
                closeEditing={closeEditing}
                attemptedClosed={attemptedClosed}
            />
            <TelefonPresentation
                typeTelefon={TYPE_TELEFON_ARBEID}
                telefon={telefon}
            />
            {errorLagring && <ErrorFormSaving error={errorLagring}/>}
        </InlineEdit>
    );
};


export default Arbeidstelefon;