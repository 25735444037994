import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl';
import Text from "../primitives/Text";
import Icon from "../primitives/Icon";


export class FieldHeader extends Component {
    render() {
        const {text, iconPath, altText, className = ''} = this.props;
        return (
            <div className={`field-header ${className}`}>
                {iconPath && <Icon
                    path={iconPath}
                    className='header-icon'
                    title={text ? '' : altText ? altText : ''}
                />}
                {text && <span className={iconPath ? 'title-with-icon' : ''}>
                    <Text id={text}/>:
                </span>}
            </div>
        )
    }
}

FieldHeader.propTypes = {
    text: PropTypes.string,
    iconPath: PropTypes.string,
    altText: PropTypes.string,
    className: PropTypes.string,
};


export default injectIntl(FieldHeader)