import * as routes from "./apiRoutes";
import axios from "axios";
import {createAppegenskap} from "../ducks/appegenskaper";

export async function getAppegenskaper(instnr) {
    const route = routes.APPEGENSKAPER.replace(':instnr', instnr);
    const response = await axios.get(route);
    return transformDatafromWS(response.data);
}

const transformDatafromWS = data => {
  return data.map(appegenskap =>
      createAppegenskap({
          kode: appegenskap.appegenskapkode,
          statusAktiv: appegenskap.status_aktiv,
          value: appegenskap.url_informasjon,
      })
  );
};


