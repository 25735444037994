import * as routes from "./apiRoutes";
import axios from "axios";

export const getPerson = async (evuapiToken, personlopenr) => {

    const route = routes.PERSON.replace(':personlopenr', personlopenr);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        }
    };

    const response = await axios.get(route, config);
    return transformDataFromWS(response.data);
};

const transformDataFromWS = result => ({
    navn: `${result.fornavn} ${result.etternavn}`,
    deltakernr: result.deltakernr,
    erBedriftsbruker: result.erBedriftsbruker,
});