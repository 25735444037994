import React from 'react';
import {getAntallUferdigeSoknader, getUferdigeSoknader,} from "../../../ducks/soknader";
import {useSelector} from "react-redux";
import Card from "../Card";
import {soknaderIconPath} from "../../../utils/icons";
import {getFirstOf, getPathToPameldingForKurs, pages} from "../../../utils/common";
import withSoknaderLoaded from "../../../high-order-components/withSoknaderLoaded";
import Button from "../../../primitives/Button";
import {compose} from "redux";
import {requireDeltakernummer} from "../../../high-order-components/requireDeltakernummer";
import Text from "../../../primitives/Text";
import FormattedDate from "../../../primitives/FormattedDate";
import {ACTION_TYPE_ENDRE} from "../../../utils/soknadStatus";
import {useNavigate} from "react-router";

const VarselUferdigeSoknader = () => {

    const uferdigeSoknader = useSelector(getUferdigeSoknader);
    const antallUferdigeSoknader = useSelector(getAntallUferdigeSoknader);

    if (antallUferdigeSoknader === 0) return null;

    const {actions} = uferdigeSoknader[0];
    const action = actions.find(action => action.type === ACTION_TYPE_ENDRE);

    const {kursnavn} = getFirstOf(uferdigeSoknader);
    const textId = antallUferdigeSoknader === 1 ? 'du_har_en_uferdig_søknad_på_kursnavn' : 'du_har_uferdige_soknader';

    return (
        <Card
            tittel={<Text id='uferdig_soknad'/>}
            varsel={true}
            iconPath={soknaderIconPath}
            actions={{
                lenke: antallUferdigeSoknader > 1 ? {
                    tekst: <Text id='gå_til_søknader'/>,
                    til: pages.soknader
                } : null,
                button: <FullforButton/>
            }}
        >
            <Text
                id={textId}
                kursnavn={kursnavn}
                antallSoknader={antallUferdigeSoknader}
            />
            <Text
                id='søknadsfristen_er'
                frist={<FormattedDate date={action.frist} withTime={true}/>}
            />
        </Card>
    )
};

const FullforButton = () => {
    const uferdigeSoknader = useSelector(getUferdigeSoknader);
    const antallUferdigeSoknader = useSelector(getAntallUferdigeSoknader);
    const navigate = useNavigate();
    if (antallUferdigeSoknader !== 1) return null;
    const soknad = uferdigeSoknader[0];
    const path = getPathToPameldingForKurs(soknad.id);
    return (
        <Button onClick={() => navigate(path)}>
            <Text id='fullfor_soknad'/>
        </Button>
    )
};

export default compose(
    Component => requireDeltakernummer(Component, false),
    withSoknaderLoaded,
)(VarselUferdigeSoknader);