import {EKSAMENSRETTET, FRIVILLIG_EKSAMENSRETTET} from "../../services/kursApi";
import React from "react";
import Text from "../../primitives/Text";

export const EksamenTag = ({className, eksamen}) => {
    const testIds = {
        [EKSAMENSRETTET]: 'eksamen',
        [FRIVILLIG_EKSAMENSRETTET]: 'frivillig_eksamen',
    };

    const textId = testIds[eksamen];
    return textId ? <Text className={`eksamen ${className}`} id={textId}/> : null;
};