import React, {Component} from 'react';
import PropTypes from 'prop-types'
import withLandskoderLoaded from "../../high-order-components/withLandskoderLoaded";
import {getLandskoder} from "../../ducks/landskoder";
import {connect} from "react-redux";
import Select from "../../primitives/Select";
import {NORGE_KODE} from "../../utils/common";
import {NORGE_LANDNUMMER} from "../../ducks/land";


export class Landskode extends Component {

    render() {
        const {landskoder, landskode, landnummer, onChange, isFocusOn, ...restOfProps} = this.props;

        const value = landskode ? {landskode, landnummer}
            : {
                landskode: NORGE_KODE,
                landnummer: NORGE_LANDNUMMER
            };


        return (
            <Select
                {...restOfProps}
                label='landskode'
                className='landskode'
                options={landskoder}
                value={value}
                onChange={onChange}
                clearable={false}
                autoComplete='country'
                conditionForFocus={isFocusOn}
            />
        )
    }
}

Landskode.propTypes = {
    landskoder: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    landskode: PropTypes.string,
    landnummer: PropTypes.number,
    conditionForFocus: PropTypes.bool,
};


const mapStateToProps = state => {
    return {
        landskoder: getLandskoder(state),
    }
};

export default withLandskoderLoaded(connect(mapStateToProps)(Landskode))