import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";

export const CardActions = ({actions}) => {
    return (
        <div className="card-actions">
            {actions.button &&
            <div className="card-actions-button">
                {actions.button}
            </div>
            }
            {actions.lenke &&
            <div className="card-actions-link">
                <Link className="link" to={actions.lenke.til}>{actions.lenke.tekst}</Link>
            </div>
            }
        </div>
    );
};


CardActions.propTypes = {
    actions: PropTypes.shape({
            lenke: PropTypes.oneOfType([
                PropTypes.shape({
                    til: PropTypes.string.isRequired,
                    tekst: PropTypes.oneOfType([
                        PropTypes.string,
                        PropTypes.node
                    ]).isRequired,
                }),
                PropTypes.bool
            ]),
            button: PropTypes.node,
        }
    ).isRequired,
};


export default CardActions