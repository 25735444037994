import React from 'react';
import {getError, isDataLoaded, isLoading, loadInstitusjoner} from '../ducks/institusjoner';
import InitialLoadWrapper from "../wrappers/InitialLoadWrapper";
import Text from "../primitives/Text";

const withInstitusjonerLoaded = Component => props => {
    return (
        <InitialLoadWrapper
            loadAction={loadInstitusjoner}
            loadingSelector={isLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getError}
            loadMessage={<Text id='laster_inn_institusjoner'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )

};

export default withInstitusjonerLoaded;