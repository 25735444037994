import Text from "../../primitives/Text";
import {RadioButton} from "../../primitives/RadioButton";
import {Adresse} from "../adresse/Adresse";
import {TYPE_ADRESSE_ARBEID, TYPE_ADRESSE_HJEM} from "../../utils/common";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFormDataAdresse} from "../../ducks/profil";
import {
    getErrorLagringForFelt,
    getIsLagringFelt,
    isBedriftBetaler,
    getBrukKontaktinfoadresse,
    lagreBetalingsinfo,
    setBrukKontaktinfoadresse
} from "../../ducks/betalingsinfo";
import Spinner from "../Spinner";
import ErrorFormSaving from "../ErrorFormSaving";

export const FELT_BRUK_KONTAKTINFOADRESSE = 'brukKontaktinfoadresse';

const VelgAdresse = () => {
    const brukKontaktinfoadresse = useSelector(getBrukKontaktinfoadresse);
    const bedriftBetaler = useSelector(isBedriftBetaler);
    const isLagringFelt = useSelector(getIsLagringFelt);
    const isLagring = isLagringFelt === FELT_BRUK_KONTAKTINFOADRESSE;
    const hjemmeadresse = useSelector(state => getFormDataAdresse(state, TYPE_ADRESSE_HJEM));
    const arbeidsadresse = useSelector(state => getFormDataAdresse(state, TYPE_ADRESSE_ARBEID));
    const errorLagring = useSelector(state => getErrorLagringForFelt(state, FELT_BRUK_KONTAKTINFOADRESSE));
    const dispatch = useDispatch();
    const onChange = value => {
        dispatch(setBrukKontaktinfoadresse(value));
        dispatch(lagreBetalingsinfo(FELT_BRUK_KONTAKTINFOADRESSE))
    };
    return (
        <div className='adresser-group input-group'>
            {isLagring && <Spinner/>}
            <fieldset>
                <legend>
                    <Text id='velg_fakturaadresse'/>:
                </legend>
                <RadioButton label={bedriftBetaler ? 'bruk_arbeidsadresse' : 'bruk_hjemmeadresse'}
                             onChange={() => onChange(true)}
                             checked={brukKontaktinfoadresse}/>
                {brukKontaktinfoadresse &&
                <Adresse
                    adresse={bedriftBetaler ? arbeidsadresse : hjemmeadresse}
                    type={bedriftBetaler ? TYPE_ADRESSE_ARBEID : TYPE_ADRESSE_HJEM}
                    withIcon={true}
                    className='kontaktinfoadresse'
                />}
                <RadioButton label={'bruk_en_annen_adresse'}
                             onChange={() => onChange(false)}
                             checked={!brukKontaktinfoadresse}/>
            </fieldset>
            {errorLagring && <ErrorFormSaving error={errorLagring}/>}
        </div>
    )
};

export default VelgAdresse;