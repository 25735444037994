import EditableField from "../../EditableField";
import React from "react";
import InlineEdit from "../../inline-edit/InlineEdit";
import FormFocusControl from "../../inline-edit/FormFocusControl";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormField,
    getErrorLagring, getFormDataStatsborgerskap, getIsStatsborgerskapSperret,
    lagreProfilFelt, resetErrorLagringForFelt,
    resetFormdata
} from "../../../ducks/profil";
import useInlineEdit from "../../../hooks/useInlineEdit";
import {getRequestErrorTextId} from "../../../utils/request";
import {getLandByNummer} from "../../../ducks/land";
import InputLand from "../../InputLand";
import DSFHelpButton, {TYPE_DSF_FELT_STATSBORGERSAP} from "../dsf/DSFHelpButton";

const STATSBORGERSKAP_FELT = 'statsborgerskap';

export const Statsborgerskap = ({editDisabled}) => {

    const landnr = useSelector(getFormDataStatsborgerskap);
    const landnavn = useSelector(state => getLandByNummer(state, landnr));
    const errorLagring = useSelector(state => getErrorLagring(state, STATSBORGERSKAP_FELT));
    const errorMessageId = errorLagring && getRequestErrorTextId(errorLagring);
    const isStatsborgerskapSperret = useSelector(getIsStatsborgerskapSperret);

    const dispatch = useDispatch();
    const submitChanges = () => dispatch(lagreProfilFelt(STATSBORGERSKAP_FELT));
    const resetChanges = () => dispatch(resetFormdata());
    const onChange = value => {
        dispatch(changeFormField(STATSBORGERSKAP_FELT, value));
        dispatch(resetErrorLagringForFelt(STATSBORGERSKAP_FELT));
    };

    const [isInEditMode, openEditing, closeEditing] = useInlineEdit(submitChanges, true, errorLagring);

    return (
        <InlineEdit
            resetChanges={resetChanges}
            isInEditMode={isInEditMode}
            openEditing={openEditing}
            closeEditing={closeEditing}
            editDisabled={editDisabled || isStatsborgerskapSperret}
        >
            <FormFocusControl onFocusOut={closeEditing}>
                <InputLand
                    title='statsborgerskap'
                    value={landnr}
                    onChange={selectedOption => onChange(selectedOption.value)}
                    errorMessageId={errorMessageId}
                />
            </FormFocusControl>
            <EditableField value={landnavn} valueNameId='statsborgerskap' editDisabled={editDisabled}>
                {isStatsborgerskapSperret && <DSFHelpButton typeFelt={TYPE_DSF_FELT_STATSBORGERSAP}/>}
            </EditableField>
        </InlineEdit>
    );
};

export default Statsborgerskap;
