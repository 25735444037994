import React from 'react';
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import {useSelector} from "react-redux";
import {getForetakNavn, getSkalGodkjennesAvForetakVarselSkalVises} from "../../ducks/pamelding";
import Text from "../../primitives/Text";

const SkalGodkjennesAvForetakVarsel = () => {
    const foretaknavn = useSelector(getForetakNavn);
    const skalVises = useSelector(getSkalGodkjennesAvForetakVarselSkalVises);
    return skalVises ?
        <Feedback type={FEEDBACK_TYPE_VARSEL}>
            <Text
                id={'denne_søknaden_må_godkjennes_av_en_kontaktperson_for_foretaknavn'}
                foretaknavn={foretaknavn}
            />
        </Feedback>
        :
        null
};

export default SkalGodkjennesAvForetakVarsel;