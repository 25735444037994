import React from 'react';
import InitialLoadWrapper from '../wrappers/InitialLoadWrapper';
import {getError, isDataLoaded, isLoading, loadFakturaer} from "../ducks/fakturaoversikt";
import Text from "../primitives/Text";

const withFakturaerLoaded = Component => props => {
    return (
        <InitialLoadWrapper
            loadAction={loadFakturaer}
            loadingSelector={isLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getError}
            loadMessage={<Text id='laster_inn_fakturaer'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )

};

export default withFakturaerLoaded;