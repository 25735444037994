import React from 'react';
import PropTypes from "prop-types";
import Feedback, {FEEDBACK_TYPE_FEIL} from "./Feedback";
import WSErrorDescription from "./WSErrorDescription";

const FeedbackWSError = ({error}) => {
    return error ?
        <Feedback type={FEEDBACK_TYPE_FEIL}>
            <WSErrorDescription error={error} className='feedback-ws-error'/>
        </Feedback>
        :
        null
};

export default FeedbackWSError;


FeedbackWSError.propTypes = {
    error: PropTypes.object,
};