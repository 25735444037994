import React from "react";
import Navn from "./Navn";
import Fodselsdato from "./Fodselsdato";
import Kjonn from "./Kjonn";
import Text from "../../primitives/Text";

export const GrunnleggendeInformasjon = () =>
    <div className='flat-card grunnleggende-informasjon'>
        <h2><Text id='grunnleggende_informasjon'/></h2>
        <div className='content'>
            <Navn/>
            <Fodselsdato/>
            <Kjonn/>
        </div>
    </div>
;


export default GrunnleggendeInformasjon;

