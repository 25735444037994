import {useDispatch, useSelector} from "react-redux";
import Text from "../../../primitives/Text";
import Pameldingslink from "./Pameldingslink";
import {showPopup} from "../../../ducks/popup";
import Button from "../../../primitives/Button";
import React from "react";
import {getAntallUbesvarteSoknaderForKurs, godkjennUbesvarteSoknader} from "../../../ducks/foretaksgodkjenning";

import './kurs-actions.scss';
import {mdiCheck, mdiClose, mdiLinkVariant} from "@mdi/js";

const KursActions = ({kursId, foretakkode, hash}) => {
    const dispatch = useDispatch();
    const antallUbesvarte = useSelector(state => getAntallUbesvarteSoknaderForKurs(state, kursId, foretakkode));
    const pameldingsContent = {
        title: <Text id={'lenke_til_påmelding'}/>,
        style: {wide: true,},
        children: <Pameldingslink kursId={kursId} foretakkode={foretakkode} hash={hash}/>,
    };
    const showLink = () => dispatch(showPopup(pameldingsContent));
    const showBesvarelseConfirmation = isGodkjent => dispatch(showPopup(({
        title: <Text id={'massebesvarelse'}/>,
        onOK: () => dispatch(godkjennUbesvarteSoknader(kursId, foretakkode, isGodkjent)),
        children: <Text id={isGodkjent ? 'godkjenne_alle_bekreftelse' : 'avslå_alle_bekreftelse'}/>
    })));
    return (
        <div className='kurs-actions'>
            <Button
                onClick={showLink}
                iconPath={mdiLinkVariant}
            >
                <Text id={'hent_lenke_til_påmelding'}/>
            </Button>
            {antallUbesvarte > 0 &&
            <>
                <Button
                    onClick={()=>showBesvarelseConfirmation(false)}
                    iconPath={mdiClose}
                    className='avvis'
                >
                    <Text id={'avvis_ubesvarte'}/>
                </Button>
                <Button
                    onClick={()=>showBesvarelseConfirmation(true)}
                    iconPath={mdiCheck}
                    className='godkjenn'
                >
                    <Text id={'godkjenn_ubesvarte'}/>
                </Button>
            </>
            }
        </div>
    )
};

export default KursActions;