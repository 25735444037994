import React from "react";
import Input from "../../primitives/Input";
import Error from "../Error";
import Poststed from "./Poststed";
import Text from "../../primitives/Text";

const InputPostnummer = ({isFocusOn, value, onChange, error, showError, onBlur}) => (
    <>
        <Input
            conditionForFocus={isFocusOn}
            label='postnummer'
            value={value}
            hasError={error && showError}
            onChange={event => onChange('postnummer', event.target.value)}
            className='postnummer'
            autoComplete='postal-code'
            onBlur={onBlur}
        />
        <Poststed className='for-postnummer-input' postnummer={value}/>
        {error && (showError || error === 'ugyldig_postnummer') &&
        <Error>
            <Text id={error}/>
        </Error>
        }
    </>
);

export default InputPostnummer;