import React from 'react';
import ForetakSelect from "./ForetakSelect";
import Kurs from "./kurs/Kursliste";

import './foretaksgodkjenning.scss';
import PageTitle from "../PageTitle";
import Text from "../../primitives/Text";
import withForetakLoaded from "../../high-order-components/withForetakLoaded";
import requireLogin from "../../high-order-components/requireLogin";
import {compose} from "redux";
import requireBedriftsbruker from "../../high-order-components/requireBedriftsbruker";

const Foretaksgodkjenning = () => {
    return (
        <div className='foretaksgodkjenning'>
            <PageTitle id={'foretaksgodkjenning'} />
            <h1>
                <Text id={'foretaksgodkjenning'}/>
            </h1>
            <ForetakSelect />
            <Kurs />
        </div>
    )
};

export default compose(
    requireLogin,
    requireBedriftsbruker,
    withForetakLoaded,
)(Foretaksgodkjenning);