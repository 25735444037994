import {useDispatch, useSelector} from "react-redux";
import {getKursIdObject} from "../../../ducks/kursinfo";
import {loadSoknader, slettUfullfortSoknad} from "../../../ducks/soknader";
import {pages} from "../../../utils/common";
import {showPopup} from "../../../ducks/popup";
import Button, {TERTIARY} from "../../../primitives/Button";
import React from "react";
import Text from "../../../primitives/Text";
import {useNavigate} from "react-router";

const ButtonAvbryt = () => {
    const navigate = useNavigate();
    const kursIdObject = useSelector(getKursIdObject);
    const dispatch = useDispatch();
    const onClick = () => {
        const popUpContent = {
            onOK: () => {
                dispatch(loadSoknader());
                navigate(pages.soknader);
            },
            onCancel: () => {
                dispatch(slettUfullfortSoknad(kursIdObject, true));
                navigate(pages.soknader);
            },
            title: <Text id='avbryt_soknad'/>,
            children: <Text id='vil_du_beholde_søknaden_din'/>,
            okButtonContent: <Text id='ja'/>,
            cancelButtonContent: <Text id='nei_slett_den'/>,
            cancelButtonDanger: true
        };
        dispatch(showPopup(popUpContent));
    };
    return (
        <Button
            emphasis={TERTIARY}
            onClick={onClick}
        >
            <Text id='avbryt'/>
        </Button>
    )
};

export default ButtonAvbryt;