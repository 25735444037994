import {logge} from './common'
import {FEEDBACK_TYPE_FEIL, FEEDBACK_TYPE_VARSEL} from "../components/Feedback";
import WSError from "../components/WSError";
import React from "react";
import axios from "axios";
import saveAs from "file-saver";

const HTTP_STATUS_CODE_404_NOT_FOUND = 404;

export const ERROR_CODE_SOKNAD_FINNES = 409001;
export const ERROR_CODE_SOKNAD_FULLFORT = 409002;
export const ERROR_CODE_EPOST_REGISTRERT_PA_ANNEN_BRUKER = 409010;

export function makeRequest(route) {

    logge("making request to " + route);

    const headers = requestHeaders();
    const request = new Request(route, {
        method: 'GET',
        headers: headers
    });


    return fetch(request).then(response => {
        return response.json();
    }).catch(error => {
        return error;
    });
}


function requestHeaders() {
    return {}
}


export function getRequestErrorMessage(error) {
    if (typeof error === 'string') {
        return error
    }

    const errorData = getRequestErrorData(error);

    if (typeof errorData === 'string') {
        return errorData
    }

    return errorData && `${errorData.errorMessage}: ${errorData.errorMessageDetail}`;
}


export function getRequestErrorData(error) {
    return error && error.response && error.response.data;
}

export function getRequestErrorCode(error) {
    const errorData = getRequestErrorData(error);
    return errorData && errorData.errorCode;
}

export function isNetConnectionMissing(error) {
    return error && error.message === 'Network Error';
}

export function getRequestErrorTextId(error) {
    const errorCode = getRequestErrorCode(error);
    if (isNetConnectionMissing(error)) {
        return errorTextIdByCode.noNet
    }

    const errorCodeTextId = errorTextIdByCode[errorCode];
    if (errorCodeTextId) {
        return errorCodeTextId;
    }

    const httpStatusCode = getRequestErrorHttpStatus(error);
    const httpStatusCodeTextId = getErrorTextIdByHttpStatusCode(httpStatusCode);

    return httpStatusCodeTextId || errorTextIdByCode.default;
}

export function getRequestErrorHttpStatus(error) {
    const errorData = getRequestErrorData(error);
    return errorData && errorData.httpStatus;
}

export const getErrorTextIdByHttpStatusCode = httpStatusCode => {
    if (!httpStatusCode) {
        return undefined;
    }
    if (httpStatusCode >= 200 && httpStatusCode < 300) {
        return 'klientfeil'
    }
    if (httpStatusCode >= 400 && httpStatusCode < 500) {
        return 'klientfeil'
    }
    if (httpStatusCode >= 500) {
        return 'serverfeil'
    }
    return undefined;
};

export const errorTextIdByCode = {
    '400000': 'bad_request',
    '400001': 'institusjon_pakrevd',
    '400002': 'ugyldig_kursid',
    '400004': 'mangler_parametre',
    '400005': 'feil_format',
    '400050': 'ugyldig_lengde_orgnr',
    '400060': 'ugyldig_kontrollsiffer_orgnr',
    '400070': 'orgnr_ikke_nummerisk',
    '4010001': 'ingen_skriverett_for_soknadsopprettelse',
    '403001': 'kan_ikke_opprette_dokument',
    '403002': 'kan_ikke_slette_dokument',
    '403003': 'dokument_sperret_sletting',
    '403004': 'dokument_knyttet_til_soknad',
    '403005': 'dokument_knyttet_til_kursdeltakelse',
    '403006': 'dokument_knyttet_til_godkjsak',
    '403007': 'dokument_knyttet_til_personpraksis',
    '403050': 'ugyldig_hash',
    '403060': 'ufullstendig_foretakinfo',
    '403070': 'ulovlig_kurs_foretak',
    '403090': 'innlogging_fikk_ikke_fnr',
    '404003': 'kurs_not_found',
    '404007': 'soknad_not_found',
    '404008': 'dokument_not_found',
    '404009': 'deltaker_not_found',
    '404040': 'foretak_not_found_brreg',
    '405001': 'soknadsfrist_utlopt',
    '405002': 'ettersendingsfrist_utlopt',
    '405003': 'trekkfrist_utlopt',
    '405004': 'ikke_eier_dokument',
    '405005': 'kurs_har_ikke_opptakskrav',
    '405006': 'person_og_deltaker_finns',
    '405007': 'fs_nummer_ikke_tillatt',
    '405009': 'krav_om_fødselsnummer',
    '405012': 'svar_på_tilbud_svarfrist_utgått',
    '405020': 'kurset_er_fullt',
    '405030': 'søknad_ikke_sendt_til_godkjenning',
    '405040': 'godkjenningsfrist_utløpt',
    '405051': 'svar_på_tilbud_ikke_fikk_tilbud',
    '405052': 'svar_på_tilbud_ugyldig_parameter',
    '405056': 'søknaden_kan_ikke_trekkes',
    '405057': 'svar_på_tilbud_søknad_trukket',
    '405058': 'innloggingsmetode_er_ikke_gyldig',
    '409000': 'konflikt',
    '409010': 'epost_registrert_på_en_annen_bruker',
    '409020': 'søknaden_er_ikke_trukket',
    '409021': 'søknaden_er_allerede_trukket',
    '413001': 'fil_for_stor',
    '415010': 'feil_filtype',
    '500000': 'internal_server_error',
    '5000001': 'ingen_deltaker',
    '9999999': 'mangler_skrivetilgang',
    noNet: 'mistet_nettilkobling',
    default: 'ws_feil'
};

export const getFeedbackTypeFromError = error =>
    getFeedbackTypeFromCode(getRequestErrorCode(error));

export const getFeedbackTypeFromCode = code => {
    switch (code) {
        case 4010001:
            return FEEDBACK_TYPE_VARSEL;
        default:
            return FEEDBACK_TYPE_FEIL;
    }
};

export function createRequestConfig(evuapiToken, params) {
    return {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        },
        ...(params && {params: params})
    };
}


export function isNotFound404(error) {
    return error && error.response && error.response.status === HTTP_STATUS_CODE_404_NOT_FOUND;
}

export class ApiError extends Error {
    constructor(errorCode, ...args) {
        super(...args);
        this.response = {
            data: {
                errorCode
            }
        };
    }
}

export const createCustomResponseError = errorCode => ({
    response: {
        data: {
            errorCode,
        }
    }
});

export const createErrorPopupContent = (error, tittel) => ({
    style: {wsError: true},
    children:
        <WSError
            error={error}
            tittel={tittel}
        />
});


export async function downloadFile(route, evuapiToken, filnavn) {

    const config = {
        ...createRequestConfig(evuapiToken),
        responseType: 'blob',
    };

    try {
        const response = await axios.get(route, config);
        const blob = new Blob([response.data]);
        saveAs(blob, filnavn);
    } catch (error) {
        if (!error.response) {
            throw error;
        }
        const reader = new FileReader();
        reader.onloadend = event => {
            const errorWithJSONData = {
                ...error,
                response: {
                    ...error.response,
                    data: JSON.parse(event.target.result)
                }
            };
            throw errorWithJSONData;
        };
        reader.readAsText(error.response.data);
    }
}