import React from 'react';
import PropTypes from "prop-types";
import './sporreskjema-sporsmal.scss';
import SvarFreetext from "./SvarFreeText";
import SvarPolar from "./SvarPolar";
import {QUESTION_TYPE_FREETEXT, QUESTION_TYPE_POLAR} from "../../ducks/sporreskjema";

export class SporreskjemaSporsmal extends React.Component {

    render() {
        const {
            isLoading,
            showError,
            hasError,
            errorText,
            wsError,
            type,
            mandatory,
            text,
            responseText,
            responsePolar,
            setPolarResponse,
            setTextResponse,
            sendSvar,
            sporsmalLang
        } = this.props;
        return (
            <div className={`flat-card sporreskjema-sporsmal`}>
                <div className={`content sporreskjema-sporsmal__svar`}>
                    {type === QUESTION_TYPE_FREETEXT &&
                    <SvarFreetext
                        setResponse={setTextResponse}
                        response={responseText}
                        mandatory={mandatory}
                        showError={showError}
                        errorText={errorText}
                        hasError={hasError}
                        sendSvar={sendSvar}
                        isLoading={isLoading}
                        wsError={wsError}
                        sporsmalLang={sporsmalLang}
                    >
                        {text}
                    </SvarFreetext>
                    }
                    {type === QUESTION_TYPE_POLAR &&
                    <SvarPolar
                        setResponse={setPolarResponse}
                        response={responsePolar}
                        mandatory={mandatory}
                        showError={showError}
                        hasError={hasError}
                        sendSvar={sendSvar}
                        isLoading={isLoading}
                        wsError={wsError}
                        sporsmalLang={sporsmalLang}
                    >
                        {text}
                    </SvarPolar>
                    }
                </div>
            </div>
        )
    }

}

SporreskjemaSporsmal.propTypes = {
    sendSvar: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    showError: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    wsError: PropTypes.object,
    type: PropTypes.string.isRequired,
    mandatory: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired,
    errorText: PropTypes.string,
    responseText: PropTypes.string,
    responsePolar: PropTypes.bool,
    setPolarResponse: PropTypes.func,
    setTextResponse: PropTypes.func,
};


export default SporreskjemaSporsmal;