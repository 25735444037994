import React from "react";
import {pages} from "../../utils/common";
import feideLogo from '../../resources/img/feide_logo.svg'
import idPortenLogo from '../../resources/img/id-porten_logo.svg'
import eidasLogo from '../../resources/img/eidas_logo.svg'
import {EIDAS, FACEBOOK, FEIDE, IDPORTEN, LINKEDIN, TWITTER} from "../../utils/loginUtil";
import Button from "../../primitives/Button";
import {resetLoginFailed} from "../../ducks/session";
import {useDispatch} from "react-redux";
import {ButtonWithDescription} from "./ButtonWithDescription";
import PropTypes from "prop-types";
import {mdiFacebook, mdiLinkedin, mdiTwitter} from "@mdi/js";
import {useNavigate} from "react-router";

const LoginButton = ({loginMethod}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const login = () => {
        dispatch(resetLoginFailed());
        navigate(pages.loginHandler.replace(':loginMethod', loginMethod));
    };

    switch (loginMethod) {
        case FEIDE:
            return <FeideButton onClick={login}/>;
        case IDPORTEN:
            return <IDPortenButton onClick={login}/>;
        case EIDAS:
            return <EIDASButton onClick={login}/>;
        case FACEBOOK:
            return <FacebookButton onClick={login}/>;
        case TWITTER:
            return <TwitterButton onClick={login}/>;
        case LINKEDIN:
            return <LinkedinButton onClick={login}/>;
        default:
            return null;
    }
};

const FeideButton = ({onClick}) =>
    <ButtonWithDescription className='feide'
                           onClick={onClick}
                           logoSrc={feideLogo}
                           altForLogo='Feide logo'
                           title='Feide'
                           descriptionId='brukere_fra_norske_universiteter_og_skoler'
    />
;


const IDPortenButton = ({onClick}) =>
    <ButtonWithDescription className='id-porten'
                           onClick={onClick}
                           logoSrc={idPortenLogo}
                           altForLogo='ID-porten logo'
                           title='ID-porten'
                           descriptionId='logg_inn_via_idporten_med_minid_bankid_buypass_eller_comfides'
    />
;


const EIDASButton = ({onClick}) =>
    <ButtonWithDescription className='eidas'
                           onClick={onClick}
                           logoSrc={eidasLogo}
                           altForLogo='eIDAS logo'
                           title='eIDAS'
                           descriptionId='logg_inn_med_elektronisk_id_fra_et_eu_medlemsland'
    />
;

const FacebookButton = ({onClick}) =>
    <Button className='facebook sosial-media-button'
            iconPath={mdiFacebook}
            onClick={onClick}
    >
        Facebook
    </Button>
;

const TwitterButton = ({onClick}) =>
    <Button className='twitter sosial-media-button'
            iconPath={mdiTwitter}
            onClick={onClick}
    >
        Twitter
    </Button>
;

const LinkedinButton = ({onClick}) =>
    <Button className='linkedin sosial-media-button'
            iconPath={mdiLinkedin}
            onClick={onClick}
    >
        LinkedIn
    </Button>
;


LoginButton.propTypes = {
    loginMethod: PropTypes.string.isRequired,
};

export default LoginButton;