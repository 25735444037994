import React from 'react';
import InitialLoadWrapperOld from '../wrappers/InitialLoadWrapperOld';
import {getError, isDataLoaded, isLoading, loadKursinfo} from '../ducks/kursinfo';
import {connect} from 'react-redux';
import {compose} from "redux";
import Text from "../primitives/Text";
import {useParams} from "react-router";

function withKursInfoLoaded(Component) {

    const mapStateToProps = state => {
        return {
            isDataLoaded: kursId => isDataLoaded(state, kursId),
            isLoading: isLoading(state),
            error: getError(state),
        }
    };

    const mapDispatchToProps = {
        populateData: loadKursinfo
    };

    return compose(
        connect(mapStateToProps, mapDispatchToProps)
    )(props => {
        const params = useParams();
        const {populateData, isDataLoaded, isLoading, error} = props;
        const kursId = params.kursId ? params.kursId : 'ukjent_kurs';
        return (
            <InitialLoadWrapperOld
                populateData={populateData}
                isDataLoaded={isDataLoaded(kursId)}
                isLoading={isLoading}
                error={error}
                args={kursId}
                loadMessage={<Text id='laster_inn_kursinfo'/>}
            >
                <Component {...props} />
            </InitialLoadWrapperOld>
        )
    });
}


export default withKursInfoLoaded;