import {
    getDataportenAccessToken,
    getInstitusjonsnr,
    loginFailed, logoutUser,
    resetLoginFailed,
    setEvuapiToken,
    setIsFetchingApiToken
} from "./ducks/session";
import {createRequestConfig} from "./utils/request";
import {saveAPITokenToLocalStorage} from "./utils/sessionUtil";
import {TOKEN_ISSUER} from "./services/apiRoutes";
import axios from "axios/index";

export const fetchApiToken = () => async (dispatch, getState) => {
    dispatch(setIsFetchingApiToken(true));
    dispatch(resetLoginFailed());
    try {
        const institusjonnr = getInstitusjonsnr(getState());

        const accessToken = getDataportenAccessToken(getState());
        const route = TOKEN_ISSUER.replace(':inst', institusjonnr);
        const isTokenUTV = TOKEN_ISSUER.match(/tokenUTV/)
        const result = await axios.get(route, createRequestConfig(isTokenUTV ? null : accessToken));
        const token = result.data;
        dispatch(setEvuapiToken(token));
        saveAPITokenToLocalStorage(token);
    } catch (error) {
        dispatch(loginFailed(error.message));
        dispatch(logoutUser());
    }
    dispatch(setIsFetchingApiToken(false));
};