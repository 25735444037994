import React from 'react';
import Text from "../../primitives/Text";

const SkipLink = () => {
  return (
      <div id="menu-bar__content-link">
          <a href='#content'>
              <Text id="innhold"/>
          </a>
      </div>
  )
};

export default SkipLink;