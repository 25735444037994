import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getEditIsSaving,
    getEditStillingsprosent,
    getEditWasSaveClicked, getIsStillingsProsentGyldig,
    setEditFieldValue
} from "../../../ducks/arbeidserfaring";
import Input from "../../../primitives/Input";
import Error from "../../Error";
import Text from "../../../primitives/Text";

const InputStillingsprosent = () => {
    const dispatch = useDispatch();
    const stillingsprosent = useSelector(getEditStillingsprosent);
    const isStillingsProsentGyldig = useSelector(getIsStillingsProsentGyldig);
    const isSaving = useSelector(getEditIsSaving);
    const saveWasClicked = useSelector(getEditWasSaveClicked);
    const hasError = saveWasClicked && !isStillingsProsentGyldig;
    const update = e => dispatch(setEditFieldValue({
        field: 'stillingsprosent',
        value: e.target.value,
    }));
    return (
        <>
            <Input
                label={'stillingsprosent'}
                onChange={update}
                value={stillingsprosent}
                type={'number'}
                min="1"
                max="100"
                disabled={isSaving}
                hasError={hasError}
            />
            {hasError &&
            <Error>
                <Text id={'vennligst_legg_inn_stillingsprosent'}/>
            </Error>
            }
        </>
    )
};

export default InputStillingsprosent;