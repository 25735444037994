import React from 'react';
import {isLoggedInDataporten} from "../ducks/session";
import {connect} from "react-redux";
import {pages} from "../utils/common";
import {compose} from "redux";
import requireInstitusjonSelected from "./requireInstitusjonSelected";
import Redirect from "../components/Redirect";

function requireDataportenLogin(Component) {

    const mapStateToProps = state => {
        return {
            isLoggedInDataporten: isLoggedInDataporten(state)
        }
    };

    return connect(mapStateToProps)(
        class extends React.Component {
            render() {
                const {isLoggedInDataporten} = this.props;

                if (!isLoggedInDataporten) {
                    return <Redirect to={pages.login}/>;
                }

                return <Component {...this.props}/>;
            }
        }
    )
}

export default compose(
    requireInstitusjonSelected,
    requireDataportenLogin
)