import * as planinfoApi from '../services/planinfoApi';
import {getKursId as getValgtKursId, getKursIdObject} from './kursinfo'
import {getEvuapiToken} from './session';
import {getAktueltDeltakernr} from './session'
import {getTranslation, getTranslationLang} from "./i18n";
import sortBy from 'lodash/sortBy';
import {getSkalTaEksamen} from "./eksamen";

export const NAME = 'planinfo';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;

export const LAGRE_BEKREFTELSE_PENDING = `${NAME}/LAGRE_BEKREFTELSER_PENDING`;
export const LAGRE_BEKREFTELSE_FAILURE = `${NAME}/LAGRE_BEKREFTELSE_FAILURE`;
export const LAGRE_BEKREFTELSE_SUCCESS = `${NAME}/LAGRE_BEKREFTELSE_SUCCESS`;

export const loadPending = kursId => ({
    type: LOAD_PENDING,
    kursId,
});

export const loadFailure = error => ({
    type: LOAD_FAILURE,
    error,
});

export const loadSuccess = data => ({
    type: LOAD_SUCCESS,
    data,
});

export const lagreBekreftelsePending = kode => ({
    type: LAGRE_BEKREFTELSE_PENDING,
    kode,
});

export const lagreBekreftelseFailure = (kode, error) => ({
    type: LAGRE_BEKREFTELSE_FAILURE,
    kode,
    error,
});

export const lagreBekreftelseSuccess = (kode, date) => ({
    type: LAGRE_BEKREFTELSE_SUCCESS,
    kode,
    date,
});

export function setupBekreftelse() {
    return function (dispatch, getState) {

        if (isLoading(getState())) {
            return;
        }

        dispatch(loadPending(getValgtKursId(getState())));
        const token = getEvuapiToken(getState());
        const kursId = getKursIdObject(getState());
        const deltakernr = getAktueltDeltakernr(getState());
        return planinfoApi.getPlaninfoBekreftelse(token, kursId, deltakernr).then(
            bekreftelseData => dispatch(loadSuccess(bekreftelseData, new Date())),
            error => dispatch(loadFailure(error))
        );
    }
}

export function lagreBekreftelse(kode) {
    return function (dispatch, getState) {
        const token = getEvuapiToken(getState());
        const deltakernr = getAktueltDeltakernr(getState());
        const kursId = getKursIdObject(getState());
        dispatch(lagreBekreftelsePending(kode));
        planinfoApi.lagreBekreftelser(token, deltakernr, kursId, kode).then(
            () => dispatch(lagreBekreftelseSuccess(kode, new Date())),
            error => dispatch(lagreBekreftelseFailure(kode, error))
        );
    }
}


export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    kursId: undefined,
    data: []
};

export const createPlaninfoData = props => {
    const {
        kode = '',
        rekkefolgenr = 1,
        status_svar_eksamen = true,
        url = [],
        merknadtekst = [],
        status_bekreft = true,
        status_bekreftet = false,
        dato_bekreftet = undefined,
        isLagring = false,
        wsError = undefined,
    } = props;
    return {
        kode,
        rekkefolgenr,
        status_svar_eksamen,
        url,
        merknadtekst,
        status_bekreft,
        status_bekreftet,
        dato_bekreftet,
        isLagring,
        wsError,
    }
};

function planinfo(state = initialState, action) {
    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                kursId: action.kursId,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                error: action.error,
                isLoading: false,
                isDataLoaded: true,
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                data: action.data,
                isLoading: false,
                isDataLoaded: true,
            };
        case LAGRE_BEKREFTELSE_PENDING:
            return {
                ...state,
                data: state.data.map(data =>
                    data.kode === action.kode ?
                        {
                            ...data,
                            isLagring: true
                        }
                        : data)
            };
        case LAGRE_BEKREFTELSE_FAILURE:
            return {
                ...state,
                data: state.data.map(data =>
                    data.kode === action.kode ?
                        {
                            ...data,
                            isLagring: false,
                            wsError: action.error,
                        }
                        : data)
            };
        case LAGRE_BEKREFTELSE_SUCCESS:
            return {
                ...state,
                data: state.data.map(data =>
                    data.kode === action.kode ?
                        {
                            ...data,
                            isLagring: false,
                            wsError: undefined,
                            status_bekreftet: true,
                            dato_bekreftet: action.date,
                        }
                        : data)
            };
        default:
            return state;
    }
}

export function getState(state) {
    return state[NAME];
}

export function getData(state) {
    return getState(state).data;
}

export function getRelevantePlaninfoData(state) {
    const planinfoData = getData(state);
    const skalTaEksamen = getSkalTaEksamen(state);
    return skalTaEksamen
        ? planinfoData
        : planinfoData.filter(planinfo => !planinfo.status_svar_eksamen);
}

export function getSortedPlaninfoData(state) {
    const planinfoData = getRelevantePlaninfoData(state);
    return sortBy(planinfoData, ['rekkefolgenr']);
}

export const getTranslatedPlaninfoData = state =>
    getSortedPlaninfoData(state).map(data => translatePlaninfoData(state, data));

export const translatePlaninfoData = (state, data) => {
    const {merknadtekst, url, ...restOfProps} = data;
    return {
        ...restOfProps,
        url: getTranslation(state, url),
        urlLang: getTranslationLang(state, url),
        merknadtekst: getTranslation(state, merknadtekst),
        merknadtekstLang: getTranslationLang(state, merknadtekst),
    }
};

export function getTranslatedPlaninfoDataMedBekreftelse(state) {
    return getTranslatedPlaninfoData(state)
        .filter(data => data.status_bekreft)
}

export const getPlaninfoFromKode = (state, kode) => getTranslatedPlaninfoData(state).find(planinfo => planinfo.kode === kode);

export const isPlaninfobekreftelseValid = state => getRelevantePlaninfoData(state).every(data => !data.status_bekreft || data.status_bekreftet);

export const getError = state => getState(state).error;
export const isLoading = state => getState(state).isLoading;
export const isDataLoaded = state => getState(state).isDataLoaded && getValgtKursId(state) === getKursId(state);
export const getKursId = state => getState(state).kursId;

export const getAllPlaninfoErForEksamen = state => getData(state).every(planinfo => planinfo.status_svar_eksamen);

export default planinfo;