import React from 'react';
import {compose} from "redux";
import './soknader.scss';
import PageTitle from "../PageTitle";
import {requireDeltakernummer} from "../../high-order-components/requireDeltakernummer";
import SlettetSoknad from "./SlettetSoknad";
import withKursoversiktLoaded from "../../high-order-components/withKursoversiktLoaded";
import SoknaderListe from "./SoknaderListe";
import {useMountEffect} from "../../hooks/mounting-hooks";
import {useDispatch} from "react-redux";
import {loadSoknader} from "../../ducks/soknader";
import requireLogin from "../../high-order-components/requireLogin";
import Text from "../../primitives/Text";

export const Soknader = () => {
    const dispatch = useDispatch();
    useMountEffect(()=>{
        dispatch(loadSoknader());
    });
    return (
        <div className='soknader'>
            <PageTitle id='søknader'/>
            <h1>
                <Text id='søknader'/>
            </h1>
            <SlettetSoknad/>
            <SoknaderListe/>
        </div>
    );
};

export default compose(
    requireLogin,
    requireDeltakernummer,
    withKursoversiktLoaded,
)(Soknader);