import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {getKursmangler as getKursmanglerApi} from "../services/kursmanglerAPI";
import {getKursId} from "./kursinfo";
import {getTranslation} from "./i18n";

export const NAME = 'kursmangler';
export const LOAD_KURSMANGLER_PENDING = `${NAME}/LOAD_KURSMANGLER_PENDING`;
export const LOAD_KURSMANGLER_SUCCESS = `${NAME}/LOAD_KURSMANGLER_SUCCESS`;
export const LOAD_KURSMANGLER_FAILURE = `${NAME}/LOAD_KURSMANGLER_FAILURE`;

export function loadKursmanglerPending() {
    return {
        type: LOAD_KURSMANGLER_PENDING,
    };
}

export function loadKursmanglerSuccess(kursmangler) {
    return {
        type: LOAD_KURSMANGLER_SUCCESS,
        kursmangler: kursmangler,
    };
}

export function loadKursmanglerFailure(error) {
    return {
        type: LOAD_KURSMANGLER_FAILURE,
        error: error
    };
}

export function loadKursmangler() {
    return function (dispatch, getState) {
        const evuapiToken = getEvuapiToken(getState());
        const deltakernr = getAktueltDeltakernr(getState());
        dispatch(loadKursmanglerPending());
        return getKursmanglerApi(evuapiToken, deltakernr).then(
            sporreskjema => dispatch(loadKursmanglerSuccess(sporreskjema)),
            error => dispatch(loadKursmanglerFailure(error))
        );
    };
}

export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    kursmangler: [],
};

function kursmangler(state = initialState, action) {
    switch (action.type) {
        case LOAD_KURSMANGLER_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_KURSMANGLER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                kursmangler: [
                    ...action.kursmangler,
                ]
            };
        case LOAD_KURSMANGLER_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error
            };
        default:
            return state;
    }
}

export function getState(state) {
    return state[NAME];
}

export function isLoading(state) {
    return getState(state).isLoading;
}

export function isDataLoaded(state) {
    return getState(state).isDataLoaded;
}

export function getError(state) {
    return getState(state).error;
}

export function getKursmangler(state) {
    const kursmanglerRaw = getState(state).kursmangler;
    return kursmanglerRaw.map(kursmangel => {
        return {
            ...kursmangel,
            mangeltekst: getTranslation(state, kursmangel.mangeltekst)
        }
    });
}

export function getKursmanglerForValgtKurs(state) {
    const kursId = getKursId(state);
    const alleKursmangler = getKursmangler(state);
    return alleKursmangler.filter(kursmangel => kursmangel.kursId === kursId);
}

export default kursmangler;