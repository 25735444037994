import React from 'react';
import PropTypes from "prop-types";
import OnClickOutsideCatcher from "../OnClickOutsideCatcher";
import OnKeyDownCatcher from "../OnKeyDownCatcher";
import {injectIntl} from "react-intl";
import {menuDownIconPath} from "../../utils/icons";
import Icon from "../../primitives/Icon";

export class MenubarDropdown extends React.Component {

    state = {
        extended: false,
        clicked: false,
    };

    extend = () => {
        const {extended} = this.state;
        if(!extended){
            this.setState({
                extended: true
            });
        }
    };

    collapse = () => {
        const {extended} = this.state;
        if(extended){
            this.setState({
                extended: false
            });
        }
    };

    toggle = (clicked = false) => {
        const {extended} = this.state;
        this.setState({
            extended: !extended,
            clicked: clicked,
        });
    };

    render(){
        const {children, toggler, className = '', ariaLabelTextId = 'meny', intl} = this.props;
        const {formatMessage} = intl;
        const {extended, clicked} = this.state;
        return (
            <OnClickOutsideCatcher onCatch={this.collapse}>
                <div className={`dropdown ${extended ? 'extended' : 'collapsed'} ${clicked ? 'clicked' : 'keyed'} ${className}`}>
                    <OnKeyDownCatcher
                        onShiftTab={this.collapse}
                        onEsc={this.collapse}
                    >
                        <button
                            className='dropdown__toggler'
                            onClick={() => { this.toggle(true) }}
                            aria-label={formatMessage({id: ariaLabelTextId})}
                        >
                            <span className={`dropdown__toggler__icon ${(!toggler.text || toggler.text === ' ') && 'tving'}`}>
                                {toggler.Icon}
                            </span>
                            <span className='dropdown__toggler__text'>
                                {toggler.text}
                            </span>
                            <span className='dropdown__toggler__shortcut'>
                                {toggler.shortcut}
                            </span>
                            {toggler.text && <Icon path={menuDownIconPath} className='utvid-icon'/>}
                        </button>
                    </OnKeyDownCatcher>
                    <OnKeyDownCatcher
                        onEsc={this.collapse}
                    >
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                        <ul className='dropdown__links' onClick={this.collapse}>
                            {children}
                        </ul>
                    </OnKeyDownCatcher>
                </div>
            </OnClickOutsideCatcher>
        )
    }

}

MenubarDropdown.propTypes = {
    children: PropTypes.node,
    toggler: PropTypes.shape({
        Icon: PropTypes.node,
        text: PropTypes.node,
        shortcut: PropTypes.node,
    }).isRequired,
    className: PropTypes.string,
    ariaLabelTextId: PropTypes.string,
};

export default injectIntl(MenubarDropdown);