import React from 'react';
import {isLoggedInDataporten as isLoggedInDataportenSelector} from '../ducks/session';
import {pages} from "../utils/common";
import {useSelector} from "react-redux";
import Redirect from "../components/Redirect";


export const RedirectIfLoggedInDataporten = ({children}) => {
    const isLoggedInDataporten = useSelector(state => isLoggedInDataportenSelector(state));
    return isLoggedInDataporten ? <Redirect to={pages.loginHandler}/> : children;
};



export default RedirectIfLoggedInDataporten;