import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getAktueltDeltakernr} from "../ducks/session";
import {isDataLoaded, loadSoknader} from "../ducks/soknader";

const useLoadSoknader = () => {
    const deltakernummer = useSelector(getAktueltDeltakernr);
    const soknaderLoaded = useSelector(isDataLoaded);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(deltakernummer && !soknaderLoaded){
            dispatch(loadSoknader());
        }
    },[deltakernummer, soknaderLoaded, dispatch]);
};

export default useLoadSoknader;