import {useSelector} from "react-redux";
import {
    getAntallGodkjenteSoknaderForKurs,
    getAntallSoknaderForKurs,
    getAntallUbesvarteSoknaderForKurs,
    getGodkjenningsfristForKurs
} from "../../../ducks/foretaksgodkjenning";
import {FormattedNumber} from "react-intl";
import React from "react";
import FormattedDate from "../../../primitives/FormattedDate";
import Text from "../../../primitives/Text";

const KursDetaljer = props => {
    return (
        <p className='detaljer'>
            <AntallSoknader {...props} />
            <AntallDeltakere {...props} />
            <Godkjenningsfrist {...props} />
        </p>
    )
};

const AntallSoknader = ({kursId, foretakkode}) => {
    const antallSoknader = useSelector(state => getAntallSoknaderForKurs(state, kursId, foretakkode));
    const antallUbesvarteSoknader = useSelector(state => getAntallUbesvarteSoknaderForKurs(state, kursId, foretakkode));
    return (
        <Text
            id={antallUbesvarteSoknader > 0 ? 'antall_soknader_ubesvarte_soknader' : 'antall_soknader'}
            antallSoknaderFormatert={<FormattedNumber value={antallSoknader}/>}
            antallUbesvarteSoknaderFormatert={<FormattedNumber value={antallUbesvarteSoknader}/>}
            antallUbesvarteSoknader={antallUbesvarteSoknader}
        />
    )
};

const AntallDeltakere = ({kursId, foretakkode, makskapasitet}) => {
    const antallDeltakere = useSelector(state => getAntallGodkjenteSoknaderForKurs(state, kursId, foretakkode));
    return (
        <Text
            id={makskapasitet === undefined ? 'antall_deltakere' : 'antall_deltakere_av_makskapasitet'}
            antallDeltakere={<FormattedNumber value={antallDeltakere}/>}
            makskapasitet={<FormattedNumber value={makskapasitet || 0}/>}
        />
    )
};

const Godkjenningsfrist = ({kursId, foretakkode}) => {
    const godkjenningsfrist = useSelector(state => getGodkjenningsfristForKurs(state, kursId, foretakkode));
    if (!godkjenningsfrist) return null;
    return (
        <Text
            id='godkjenningsfrist'
            values={{frist: <FormattedDate date={godkjenningsfrist}/>}}
        />
    )
};

export default KursDetaljer;