import React, {useEffect, useRef} from "react";
import {DownloadLink} from "./DownloadLink";
import {formatDate} from "../../ducks/i18n";
import {deleteIconPath} from "../../utils/icons";
import Button from "../../primitives/Button";
import useDeleteDocument from "../../hooks/useDeleteDocument";
import {useDispatch, useSelector} from "react-redux"
import {
    getDomElementToFocus,
    getIsDeleted,
    getIsUnderDeletion,
    getSperreSlettingFraDokumentarkiv, setDomElementToFoucs
} from "../../ducks/dokumenter";

const DokumentRow = ({id, filnavn, datoOpplastet}) =>  {
    const formattedDate = useSelector(state => formatDate(state, datoOpplastet, true));
    const [deleteClick] = useDeleteDocument(id, filnavn);
    const isUnderDeletion = useSelector(state => getIsUnderDeletion(state, id));
    const isDeleted = useSelector(state => getIsDeleted(state, id));
    const isSperreSletting = useSelector(state => getSperreSlettingFraDokumentarkiv(state, id));
    const domELementToFocus = useSelector(state => getDomElementToFocus(state));
    const dispatch = useDispatch();

    const deleteOgFocusTilNeste = () => {
        const siblings = ref.current.parentNode.children;
        let nextElemnent = null;
        for (let i=0; i < siblings.length; i++) {
            if (ref.current === siblings[i]) {
                nextElemnent = siblings[i+1];
            }
        }
        dispatch(setDomElementToFoucs(nextElemnent));
        deleteClick();
    }

    useEffect(() => {
        const nextElementTitle = domELementToFocus && domELementToFocus.querySelector('a');
        isDeleted && nextElementTitle && nextElementTitle.focus();
    }, [domELementToFocus, isDeleted]);

    const ref = useRef(null);

    return (
        <tr className={`document-row ${isDeleted && 'deleted'}`} ref={ref}>
            <td>
                <DownloadLink dokumentnr={id} filnavn={filnavn}/>
            </td>
            <td>
                {formattedDate}
            </td>
            <td>
                {!isSperreSletting && <Button onClick={deleteOgFocusTilNeste}
                                              iconPath={deleteIconPath}
                                              altText='slett'
                                              isInProgress={isUnderDeletion}
                                              isSuccessfullyDone={isDeleted}
                />}
            </td>
        </tr>
    );
}

export default DokumentRow;