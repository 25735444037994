import {useSelector} from "react-redux";
import React from "react";
import {
    getError,
    isDataLoaded as isDataLoadedSelector,
    isLoading as isLoadingSelector,
    loadDataForNyBruker,
} from '../../../ducks/nyBruker';
import {HentPaNyttButtonMedFeedback} from "./HentPaNyttButtonMedFeedback";

export const SyncMedKorrForNyBruker = () => {
    const isLoading = useSelector(isLoadingSelector);
    const isDataLoaded = useSelector(isDataLoadedSelector);
    const error = useSelector(getError);

    return <HentPaNyttButtonMedFeedback
        isLoading={isLoading}
        isDataLoaded={isDataLoaded}
        error={error}
        onClickAction={loadDataForNyBruker()}
    />;
};