import Land from "../InputLand";
import React from "react";

const InputLand = ({isFocusOn, value, onChange, onBlur}) => (
    <Land
        value={value}
        onChange={selectedOption => onChange('land', selectedOption.value)}
        conditionForFocus={isFocusOn}
        onBlur={onBlur}
    />
);

export default InputLand;