import {FormattedDate} from "react-intl";
import Text from "../../primitives/Text";
import React from "react";

const Periode = ({fraDato, tilDato, monthInDigits}) => {
    const monthFormat = monthInDigits ? "2-digit" : "long";
    return (
        <span className='periode'>
            <span className='fra'>
                <FormattedDate
                    value={fraDato}
                    year="numeric"
                    month={monthFormat}
                />
            </span>
            <span className='til'>
                <span className='label'>
                    <Text id={'til'}/>
                </span>
                {tilDato ?
                    <FormattedDate
                        value={tilDato}
                        year="numeric"
                        month={monthFormat}
                    />
                    :
                    <Text id={'vedvarende'}/>
                }
            </span>
        </span>
    )
};

export default Periode;