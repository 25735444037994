import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import queryString from 'query-string';
import {
    hasPameldingFeilet,
    endreAktivtSteg,
    getAlleSteg,
    lagrePameldingResett,
    getStatus,
    erSoknadStengt, getLagreError, getAktivtStegnr,
} from '../../ducks/pamelding'
import {
  getDirektePamelding,
  getPris,
  getKursnavn,
  getKursIdObject,
  getKursPameldingsfrist,
  getKursStartdato
} from '../../ducks/kursinfo'
import {pages} from '../../utils/common'
import withPameldingLoaded from "../../high-order-components/withPameldingLoaded";
import withKursInfoLoaded from "../../high-order-components/withKursInfoLoaded";
import './pamelding.scss';
import PageTitle from "../PageTitle";
import {getClassFromStatus, getTextIdFromStatus} from "../../utils/soknadStatus";
import {requireDeltakernummer} from "../../high-order-components/requireDeltakernummer";
import {compose} from "redux";
import MainInfo from "../MainInfo";
import Steg from "./steg/Steg";
import OverlappVarsel from "./OverlappVarsel";
import FulltKursVarsel from "./FulltKursVarsel";
import UtgattFristVarsel from "./UtgattFristVarsel";
import {useMountEffect} from "../../hooks/mounting-hooks";
import SkalGodkjennesAvForetakVarsel from "./SkalGodkjennesAvForetakVarsel";
import requireLogin from "../../high-order-components/requireLogin";
import ForetakNavn from "./ForetakNavn";
import PameldingRegistrert from "./PameldingRegistrert";
import WSError from "../WSError";
import {focusOnSteg} from "../../utils/pameldingUtil";
import Text from "../../primitives/Text";
import {useLocation} from "react-router";

const getInitialStegId = (location, alleSteg) => {
    const params = queryString.parse(location.search);
    const steg = alleSteg.find(steg => steg.type === params.steg);
    return parseInt(steg ? steg.id : 0);
};
export const Pamelding = () => {

    const alleSteg = useSelector(getAlleSteg);
    const kursnavn = useSelector(getKursnavn);
    const kursId = useSelector(getKursIdObject);
    const pris = useSelector(getPris);
    const pameldingsfrist = useSelector(getKursPameldingsfrist);
    const startdato = useSelector(getKursStartdato);
    const direktePamelding = useSelector(getDirektePamelding);
    const status = useSelector(getStatus);
    const pameldingFeilet = useSelector(hasPameldingFeilet);
    const lagringError = useSelector(getLagreError);
    const soknadStengt = useSelector(erSoknadStengt);
    const aktivtStegnr = useSelector(getAktivtStegnr);

    const location = useLocation();

    const dispatch = useDispatch();

    useMountEffect(() => {
        const initialStegId = getInitialStegId(location, alleSteg);
        dispatch(lagrePameldingResett());
        if (initialStegId) {
            dispatch(endreAktivtSteg(initialStegId));
        }
    });

    useEffect(() => {
        aktivtStegnr !== undefined && focusOnSteg(aktivtStegnr)
    }, [aktivtStegnr]);


    if (soknadStengt) {
        return <PameldingRegistrert/>
    }

    return (
        <div className='pamelding'>

            <PageTitle
                id='påmelding_til_kurset_kurset'
                values={{kursnavn: kursnavn, kurskode: kursId.kurskode}}
            />

            <h1>
                <Text
                    id='påmelding_til_kurset_kurset'
                    values={{kursnavn: kursnavn, kurskode: kursId.kurskode}}
                />
            </h1>

            <MainInfo
              pris={pris}
              pameldingsfrist={pameldingsfrist}
              startdato={startdato}
              direktePamelding={direktePamelding}
            />

            <ForetakNavn/>

            <strong className={getClassFromStatus(status)}>
                <Text id={getTextIdFromStatus(status)}/>
            </strong>

            <UtgattFristVarsel/>

            <OverlappVarsel/>

            <FulltKursVarsel/>

            <SkalGodkjennesAvForetakVarsel/>

            <AlleSteg/>

            {pameldingFeilet && (
                <WSError error={lagringError}
                         tittel='tjenesten_klarte_ikke_å_melde_deg_opp_til_kurset'
                />
            )}

        </div>
    );

};

const AlleSteg = () => {
    const alleSteg = useSelector(getAlleSteg);
    return alleSteg
        .filter(steg => steg.redigerbart)
        .map(steg =>
            <Steg
                key={steg.id}
                steg={steg}
            />
        );
};

export default compose(
    requireLogin,
    Component => requireDeltakernummer(Component, true, pages.nyBruker),
    withKursInfoLoaded,
    Component => withPameldingLoaded(Component, true),
)(Pamelding);
