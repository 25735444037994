import React from 'react';
import {useSelector} from "react-redux";
import {getAktueltDeltakernr} from "../ducks/session";
import {pages} from "../utils/common";
import Redirect from "../components/Redirect";

export const requireDeltakernummer = (Component, doRedirect = true, redirectionTarget = pages.minSide) =>
    props => {

        const deltakernr = useSelector(getAktueltDeltakernr);

        if (deltakernr) {
            return <Component {...props} />;
        }

        if (doRedirect) {
            return <Redirect to={redirectionTarget}/>;
        }

        return null;
    };