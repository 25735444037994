import React, {Component} from 'react'
import PropTypes from "prop-types";
import Label from "./Label";
import {nanoid} from "nanoid";
import './input.scss';


export class Input extends Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            id: nanoid(),
        };
    }

    componentDidMount() {
        const {autoFocus} = this.props;
        this.setFocusOnCondition(autoFocus);
    }

    componentDidUpdate() {
        const {conditionForFocus} = this.props;
        this.setFocusOnCondition(conditionForFocus);
    }

    setFocusOnCondition = condition => {
        if (condition) {
            this.inputRef.current.focus();
        }
    };

    render() {
        const {
            label: textInLabel,
            labelJustForSR,
            iconPathForLabel,
            altText,
            justIconInLabel,
            className = '',
            overrideId,
            hasError,
            labelColon = true,
            translateLabel = true,
            type = 'text',
            autoFocus,
            conditionForFocus, // prevent this from being passed to input,
            value,
            ...restOfProps
        } = this.props;

        const {id} = this.state;

        const label = <Label htmlFor={overrideId ? overrideId : id}
                             text={textInLabel}
                             justForSR={labelJustForSR}
                             iconPath={iconPathForLabel}
                             altText={altText}
                             justIcon={justIconInLabel}
                             showColon={labelColon}
                             translate={translateLabel}
        />;

        const classes = `${className} ${hasError ? 'validate-field-error' : ''} basic-input`;
        const input =
            <input
                type={type}
                id={overrideId ? overrideId : id}
                className={classes}
                ref={this.inputRef}
                value={value || ''}
                autoFocus={autoFocus || conditionForFocus}
                placeholder={type==='date' ? 'yyyy-mm-dd' : undefined}
                {...restOfProps}
            />;

        return (
            justIconInLabel ?
                <div className={`input-with-label input-with-label-just-icon ${className}`}>
                    {label}
                    <div className='just-icon-in-label'>
                        {input}
                    </div>
                </div>
                :
                <div className={`input-with-label ${className}`}>
                    {label}
                    {input}
                </div>
        )
    }

}


export default Input;


Input.propTypes = {
    id: PropTypes.oneOf([undefined]),
    label: PropTypes.node,
    type: PropTypes.string,
    labelJustForSR: PropTypes.bool,
    iconPathForLabel: PropTypes.string,
    justIconInLabel: PropTypes.bool,
    altText: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    hasError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    conditionForFocus: PropTypes.bool,
    autoFocus: PropTypes.bool,
    labelColon: PropTypes.bool,
    className: PropTypes.string,
    overrideId: PropTypes.string,
    translateLabel: PropTypes.bool,
};