import PageTitle from "../PageTitle";
import {Link} from "react-router-dom";
import {pages} from "../../utils/common";
import React from "react";
import WSError from "../WSError";
import {useDispatch, useSelector} from "react-redux";
import {
    getDataportenIdTokenForLogoutOnError,
    getLoginError,
    logoutUser
} from "../../ducks/session";
import Button from "../../primitives/Button";
import Feedback from "../Feedback";
import Text from "../../primitives/Text";

const InnloggingFeilet = () => {
    const error = useSelector(getLoginError);
    const dataportenIdTokenForLogoutOnError = useSelector(getDataportenIdTokenForLogoutOnError);
    const dispatch = useDispatch();
    return (
        <div className='innloging-feilet'>
            <PageTitle id='innlogging_feilet'/>
            <WSError tittel='innlogging_feilet' error={error}/>
            {dataportenIdTokenForLogoutOnError &&
            <div>
                <Feedback className='fortsatt-palogget'>
                    <Text id='du_er_fortsatt_pålogget_i_dataporten'/>
                    <Button onClick={() => dispatch(logoutUser())}>
                        <Text id='logg_meg_ut'/>
                    </Button>
                </Feedback>
            </div>
            }
            <Link className="link" to={pages.login}>
                <Text id='gå_tilbake_til_innloggingssiden'/>
            </Link>
        </div>
    )
};


export default InnloggingFeilet;