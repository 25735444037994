export const MALFORMER = {
    nb: [
        {value: 'BOKMAL', label: 'Bokmål'},
        {value: 'NYNORSK', label: 'Nynorsk'},
        {value: 'ENGELSK', label: 'Engelsk'},
    ],
    nn: [
        {value: 'BOKMAL', label: 'Bokmål'},
        {value: 'NYNORSK', label: 'Nynorsk'},
        {value: 'ENGELSK', label: 'Engelsk'},
    ],
    en: [
        {value: 'BOKMAL', label: 'Norwegian Bokmål'},
        {value: 'NYNORSK', label: 'Norwegian Nynorsk'},
        {value: 'ENGELSK', label: 'English'},
    ],
};


export const KJONN = {
    nb: [
        {value: 'M', label: 'Mann'},
        {value: 'K', label: 'Kvinne'},
    ],
    nn: [
        {value: 'M', label: 'Mann'},
        {value: 'K', label: 'Kvinne'},
    ],
    en: [
        {value: 'M', label: 'Man'},
        {value: 'K', label: 'Woman'},
    ],

};