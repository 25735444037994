import {getDataportenAccessToken, getInstitusjonsnr, getKanSynkeDsf} from "./session";
import * as dsfAPI from '../services/dsfAPI';
import {loadProfil} from "./profil";

export const NAME = 'syncDSF';

export const SYNC_DSF_PENDING = `${NAME}/SYNC_DSF_PENDING`;
export const SYNC_DSF_SUCCESS = `${NAME}/SYNC_DSF_SUCCESS`;
export const SYNC_DSF_FAILURE = `${NAME}/SYNC_DSF_FAILURE`;

export function syncDSFPending() {
    return {
        type: SYNC_DSF_PENDING,
    };
}

export function syncDSFSuccess() {
    return {
        type: SYNC_DSF_SUCCESS,
    };
}


export function syncDSFError(error) {
    return {
        type: SYNC_DSF_FAILURE,
        error,
    };
}

export const syncMedDSF = (doLoadProfil = true) => async (dispatch, getState) => {
    if (!getKanSynkeDsf(getState())) {
        return;
    }
    if (isSyncingMedDSF(getState())) {
        return;
    }
    dispatch(syncDSFPending());
    const state = getState();
    const instnr = getInstitusjonsnr(state);
    const dataportenToken = getDataportenAccessToken(state);
    try {
        await dsfAPI.syncDSF(instnr, dataportenToken);
        await dispatch(syncDSFSuccess());
        doLoadProfil && await dispatch(loadProfil());
    } catch (error) {
        dispatch(syncDSFError(error))
    }
};

export const initialState = {
    isSyncingMedDSF: false,
    isSyncMedDSFSuccess: false,
    errorSyncMedDSF: undefined,
};

function syncDSF(state = initialState, action) {
    switch (action.type) {
        case SYNC_DSF_PENDING:
            return {
                ...state,
                isSyncingMedDSF: true,
                isSyncMedDSFSuccess: false,
                errorSyncMedDSF: undefined
            };
        case SYNC_DSF_SUCCESS:
            return {
                ...state,
                isSyncingMedDSF: false,
                isSyncMedDSFSuccess: true,
                errorSyncMedDSF: undefined,
            };
        case SYNC_DSF_FAILURE:
            return {
                ...state,
                isSyncingMedDSF: false,
                isSyncMedDSFSuccess: false,
                errorSyncMedDSF: action.error
            };
        default:
            return state;
    }
}
export function getState(state) {
    return state[NAME];
}
export const isSyncingMedDSF = state => getState(state).isSyncingMedDSF;
export const isSyncDSFSuccess = state => getState(state).isSyncMedDSFSuccess;
export const getErrorSyncMedDSF = state => getState(state).errorSyncMedDSF;

export default syncDSF;