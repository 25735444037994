import {DATAPORTEN, getJwtPayload, getNonceFromLocalStorage, saveNonceToLocalStorage} from "./sessionUtil";
import {nanoid} from "nanoid";
import {JSO} from "jso";
import {redirectTo} from "./common";

const redirectUri = process.env.REACT_APP_LOGIN_REDIRECT_URL;
const clientId = process.env.REACT_APP_LOGIN_CLIENT_ID;
const logoutRedirectUri = process.env.REACT_APP_LOGOUT_REDIRECT_URL;

export const FEIDE = 'feide';
export const IDPORTEN = 'idporten';
export const EIDAS = 'eidas';
export const TWITTER = 'twitter';
export const FACEBOOK = 'facebook';
export const LINKEDIN = 'linkedin';

const logoutURL = 'https://auth.dataporten.no/openid/endsession?post_logout_redirect_uri=:post_logout_redirect_uri&id_token_hint=:id_token_hint';

export const getClient = (loginMethod, feideDomene) => {
    return new JSO({
        providerID: DATAPORTEN,
        response_type: 'id_token token',
        client_id: clientId,
        redirect_uri: redirectUri,
        authorization: 'https://auth.dataporten.no/oauth/authorization',
        debug: false,
        request: {
            login_hint: getLoginHint(loginMethod, feideDomene),
            nonce: getAndSaveNonce(),
            scope: 'openid'
        },
    });
};

const getLoginHint = (loginMethod, feideDomene) => {
    if (loginMethod === FEIDE && feideDomene) {
        return `feide|realm|${feideDomene}`;
    } else {
        return loginMethod || "";
    }
};

const getAndSaveNonce = () => {
    const nonceFraLocalStorage = getNonceFromLocalStorage();
    if (nonceFraLocalStorage) {
        return nonceFraLocalStorage
    }

    const nonce = nanoid();
    saveNonceToLocalStorage(nonce);
    return nonce;
};


export const checkNonce = (dataportenToken) => {
    const nonceApp = getNonceFromLocalStorage();
    const nonceFromToken = getNonceFromToken(dataportenToken);
    if (nonceApp !== nonceFromToken || !nonceFromToken) {
        throw new Error('BAD TOKEN');
    }
};

export const getNonceFromToken = (dataportenToken) => {
    const id_token = dataportenToken && dataportenToken.id_token;
    const payload = id_token && getJwtPayload(id_token);
    return payload && payload.nonce;
};


export const logout = id_token => {
    const endpoint = logoutURL
        .replace(':post_logout_redirect_uri', logoutRedirectUri)
        .replace(':id_token_hint', id_token);
    redirectTo(endpoint);
};