import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getEditIsSaving,
    getEditStillingstittel,
    getEditWasSaveClicked,
    setEditFieldValue
} from "../../../ducks/arbeidserfaring";
import Input from "../../../primitives/Input";
import {STILLINGSTITTEL_MAX_LENGDE} from "../../../utils/arbeidserfaringUtil";
import Error from "../../Error";
import Text from "../../../primitives/Text";

const InputStillingstittel = () => {
    const dispatch = useDispatch();
    const stillingstittel = useSelector(getEditStillingstittel);
    const isSaving = useSelector(getEditIsSaving);
    const saveWasClicked = useSelector(getEditWasSaveClicked);
    const hasError = saveWasClicked && !stillingstittel;
    const update = e => dispatch(setEditFieldValue({
        field: 'stillingstittel',
        value: e.target.value,
    }));
    return (
        <>
            <Input
                label={'stillingstittel'}
                onChange={update}
                value={stillingstittel}
                disabled={isSaving}
                maxLength={STILLINGSTITTEL_MAX_LENGDE}
                hasError={hasError}
            />
            {hasError &&
            <Error>
                <Text id={'vennligst_legg_inn_stillingstittel'}/>
            </Error>
            }
        </>
    )
};

export default InputStillingstittel;