import PageTitle from "../PageTitle";
import Laster from "../Laster";
import React from "react";
import Text from "../../primitives/Text";

const LoggerPaSpinner = () =>
    <>
        <PageTitle id='prøver_å_logge_deg_på'/>
        <Laster>
            <Text id='prøver_å_logge_deg_på'/>
        </Laster>
    </>
;

export default LoggerPaSpinner;