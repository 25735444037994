import React from "react";
import Text from "../primitives/Text";
import Feedback, {FEEDBACK_TYPE_FEIL} from "./Feedback";
import './feedback-errors.scss'

const FeedbackErrors = ({children}) => {
    return (
        <div role="alert" className='feedback-errors'>
            <Feedback type={FEEDBACK_TYPE_FEIL}>
                <h4>
                    <Text id='noe_feilet_under_oppkobling_til_API'/>:
                </h4>

            </Feedback>
            <div className='feedback-errors__detaljert-info'>
                {children}
            </div>
        </div>

    )
};

export default FeedbackErrors;