import React from "react";
import Icon from "./Icon";
import './button-as-link.scss'


const ButtonAsLink = ({children, iconPath, className = '', type = 'button', ...restOfProps}) => {

    const classNames = `button-as-link ${className} `;
    return (
        <button className={classNames} {...restOfProps} type={type}>
            {children}
            {iconPath && <Icon path={iconPath}/>}
        </button>
    );
};

export default ButtonAsLink;