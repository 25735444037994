import {useSelector} from "react-redux";
import React from "react";
import {HentPaNyttButtonMedFeedback} from "../korr/HentPaNyttButtonMedFeedback";
import {getErrorSyncMedDSF, isSyncDSFSuccess, isSyncingMedDSF, syncMedDSF} from "../../../ducks/syncDSF";

const SyncMedDSF = () => {
    const isSyncing = useSelector(isSyncingMedDSF);
    const isSyncSuccess = useSelector(isSyncDSFSuccess);
    const errorSync = useSelector(getErrorSyncMedDSF);

    return <HentPaNyttButtonMedFeedback
        isLoading={isSyncing}
        isDataLoaded={isSyncSuccess}
        error={errorSync}
        onClickAction={syncMedDSF()}
    />;
};

export default SyncMedDSF;