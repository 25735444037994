import jwt_decode from "jwt-decode";
import {MILLISECONDS_IN_SECOND} from "./common";

export const I18N_LOCAL_STORAGE_KEY = 'i18n';
export const DATAPORTEN = 'dataporten';
export const DATAPORTEN_TOKENS = `tokens-${DATAPORTEN}`;
export const EVU3_WS_TOKEN = 'evuapi-token';
export const PERSISTED_STATE = 'redux-state';
export const NONCE = 'nonce';


export function clearLocalStorage() {
    const i18nValue = getValueFromLocalStorage(I18N_LOCAL_STORAGE_KEY);
    try {
        localStorage.clear();
    } catch (e) {
    }
    i18nValue && saveToLocalStorage(I18N_LOCAL_STORAGE_KEY, i18nValue);
}

export function clearTokensFromLocalStorage() {
    clearEvuapiTokenFromLocalStorage();
    clearDataportenTokensFromLocalStorage();
    clearNonceFromLocalStorage();
}

export function clearEvuapiTokenFromLocalStorage() {
    removeFromLocalStorage(EVU3_WS_TOKEN);
}

export function clearDataportenTokensFromLocalStorage() {
    removeFromLocalStorage(DATAPORTEN_TOKENS);
}

export function clearNonceFromLocalStorage() {
    removeFromLocalStorage(NONCE);
}

export function saveNonceToLocalStorage(nonce) {
    saveToLocalStorage(NONCE, nonce);
}

export function saveAPITokenToLocalStorage(token) {
    saveToLocalStorage(EVU3_WS_TOKEN, token);
}


export function getNonceFromLocalStorage(defaultValue) {
    return getValueFromLocalStorage(NONCE, defaultValue);
}

export function getJwtPayload(jwt) {
    return jwt_decode(jwt);
}

export const isJwtExpired = token => {
    const dateJwtExpires = getDateJwtExpires(token);
    return dateJwtExpires && Date.now() > dateJwtExpires;
}

export const getDateJwtExpires = token => {
    const tokenPayload = getJwtPayload(token);
    const expires = tokenPayload && tokenPayload.exp;
    if (!expires) {
        return undefined;
    }
    return new Date(expires * MILLISECONDS_IN_SECOND)
}

export function saveToLocalStorage(key, value) {
    try {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem(key, JSON.stringify(value));
        }
    } catch (e) {
    }
}

export function getValueFromLocalStorage(key, defaultValue) {
    try {
        return localStorage.hasOwnProperty(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

export function removeFromLocalStorage(key) {
    try {
        localStorage.removeItem(key);
    } catch (e) {
    }
}