import * as routes from "./apiRoutes";
import axios from "axios/index";

export function getLand(instnr) {

    const route = routes.LAND.replace(':instnr',instnr);


    return axios.get(route)
        .then(response => {
            return response.data.land;
        })
        .catch(error => {
            throw error;
        });
}

