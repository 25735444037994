import React, {Component} from 'react';
import PropTypes from 'prop-types'
import Laster from "../Laster";
import {connect} from "react-redux";
import {getPoststed, isLoading, loadPoststed} from "../../ducks/poststeder";
import {isPostnummerValid} from "../../utils/common";
import {UKJENT_POSTSTED} from "../../services/postnumreAPI";
import Text from "../../primitives/Text";


export class Poststed extends Component {

    constructor(props) {
        super(props);
        this.fetchPoststedIfNeeded = this.fetchPoststedIfNeeded.bind(this);
    }


    fetchPoststedIfNeeded() {
        const {postnummer, getPoststed, fetchPoststed} = this.props;

        if (isPostnummerValid(postnummer) && !getPoststed(postnummer)) {
            fetchPoststed(postnummer);
        }
    }


    componentDidMount() {
        this.fetchPoststedIfNeeded();
    }


    componentDidUpdate(prevProps) {
        const {postnummer} = this.props;

        if (postnummer !== prevProps.postnummer) {
            this.fetchPoststedIfNeeded();
        }
    }


    render() {
        const {postnummer, getPoststed, isLoading, className = ''} = this.props;
        const poststed = getPoststed(postnummer);

        const classes = `poststed ${className}`;

        return (
            isLoading ?
                <Laster className='in-line-spinner'/>
                :
                <span className={classes}>
                    {poststed === UKJENT_POSTSTED ?
                        <Text id='ukjent_poststed'/>
                        : poststed
                    }
                </span>
        )
    }
}

Poststed.propTypes = {
    postnummer: PropTypes.string,
    fetchPoststed: PropTypes.func.isRequired,
    getPoststed: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};


const mapStateToProps = state => {
    return {
        getPoststed: (postnummer) => getPoststed(state, postnummer),
        isLoading: isLoading(state),
    }
};


const mapDispatchToProps = dispatch => {
    return {
        fetchPoststed: (postnummer) => {
            dispatch(loadPoststed(postnummer))
        },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Poststed)


