import React from 'react';
import Feedback from "../Feedback";
import Text from "../../primitives/Text";
import "./betalingsinformasjon.scss"
import {FakturaInputAdresse} from "./FakturaInputAdresse";
import VelgPrivatEllerBedrift from "./VelgPrivatEllerBedrift";
import VelgAdresse from "./VelgAdresse";
import GenerelleInputFields from "./GenerelleInputFields";
import {useSelector} from "react-redux";
import {getKontaktinfoadresseFinnes, getBrukKontaktinfoadresse} from "../../ducks/betalingsinfo";
import {getBetalingsinfoFrist} from "../../ducks/pamelding";
import FormattedDate from "../../primitives/FormattedDate";
import withProfildataLoaded from "../../high-order-components/withProfildataLoaded";

const Betalingsinformasjon = () => {
    const brukKontaktinfoadresse = useSelector(getBrukKontaktinfoadresse);
    const kontaktinfoadresseFinnes = useSelector(getKontaktinfoadresseFinnes);
    return (
        <div className='fakturainformasjon'>
            <DuKanOppgiInfoSenereVarsel/>
            <div className='fakturainformasjon__input-container'>
                <VelgPrivatEllerBedrift/>
                <GenerelleInputFields/>
                {kontaktinfoadresseFinnes && <VelgAdresse/>}
                {!brukKontaktinfoadresse && <FakturaInputAdresse/>}
            </div>
        </div>
    );
};

const DuKanOppgiInfoSenereVarsel = () => {
    const frist = useSelector(getBetalingsinfoFrist);
    return (
        <Feedback>
            <Text id='du_kan_hoppe_over_dette_steget_og_oppgi_fakturainformasjon'/>
            {frist ?
                <div>
                    <Text id='frem_til'/><FormattedDate date={frist} withTime/>
                </div>
                :
                <Text id='senere'/>
            }
        </Feedback>
    )
};

export default withProfildataLoaded(Betalingsinformasjon);