import React from 'react'
import PropTypes from 'prop-types'
import {Link as ReactRouterLink} from 'react-router-dom';
import {mdiOpenInNew} from "@mdi/js";
import Icon from "./Icon";

const Link = (props) => {
    const {children, ...restOfProps} = props;

    const classes = `link`;

    return (
        <a className={classes} {...restOfProps}>{children}</a>
    );
};

Link.propTypes = {
    children: PropTypes.node,
};

export const ReactLink = (props) => {
    const {children, className = '', to,  ...restOfProps} = props;

    const classes = `link  ${className}`;

    return (
        <ReactRouterLink className={classes} to={to} {...restOfProps}>{children}</ReactRouterLink>
    );
};




export const ExternalLink = (props) => {
    const {children, className = '', href, showIcon=true} = props;

    const classes = `link  ${className}`;

    return (
        <a className={classes}
           href={href}
           target="_blank"
           rel="noopener noreferrer">
            {children}
            {showIcon && <Icon path={mdiOpenInNew} title='ekstern_lenke' className='external-link-icon'/>}
        </a>
    );
};

ExternalLink.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    href: PropTypes.string,
};


export const MailToLink = (props) => {
    const {className = '', email} = props;

    const classes = `link  ${className}`;

    return (
        <a className={classes}
           href={`mailto:${email}`}>
            {email}
        </a>
    );
};

MailToLink.propTypes = {
    className: PropTypes.string,
    email: PropTypes.string.isRequired,
};

export default Link
