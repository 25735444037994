export const FRIST_UBEGRENSET = new Date(9999, 12, 1);
export const FRIST_PASSERT = new Date(2000, 1, 1);

export const STATUS_VENTELISTE = 'VENTELISTE';
export const STATUS_TILBUD_OM_VENTELISTEPLASS = 'TILBUD_OM_VENTELISTEPLASS';
export const STATUS_TILBUD = 'TILBUD';
export const STATUS_TILBUD_AKSEPTERT = 'TILBUD_AKSEPTERT';
export const STATUS_TILBUD_AVSLATT = 'TILBUD_AVSLATT';
export const STATUS_MANGLER_DOKUMENTASJON = 'MANGLER_DOKUMENTASJON';
export const STATUS_SOKNAD_REGISTRERT = 'SOKNAD_REGISTRERT';
export const STATUS_PAMELDT = 'PAMELDT';
export const STATUS_AVSLAG = 'AVSLAG';
export const STATUS_AVLYST = 'AVLYST';
export const STATUS_BORTFALT = 'BORTFALT';
export const STATUS_IKKE_TILBUD = 'IKKE_TILBUD';
export const STATUS_TILBUD_ANNULLERT = 'TILBUD_ANNULLERT';
export const STATUS_TILBUD_OM_VENTELISTEPLASS_ANNULLERT = 'TILBUD_OM_VENTELISTEPLASS_ANNULLERT';
export const STATUS_TRUKKET = 'TRUKKET';
export const STATUS_TRUKKET_FRA_VENTELISTE = 'TRUKKET_FRA_VENTELISTE';
export const STATUS_UBESVART_TILBUD = 'UBESVART_TILBUD';
export const STATUS_AUTOMATISK_TRUKKET_FRA_VENTELISTE = 'AUTOMATISK_TRUKKET_FRA_VENTELISTE';
export const STATUS_IKKE_FULLFORT = 'IKKE_FULLFORT';
export const STATUS_IKKE_GODKJENT = 'IKKE_GODKJENT';
export const STATUS_SENDT_TIL_GODKJENNING = 'SENDT_TIL_GODKJENNING';

export const ACTION_TYPE_ENDRE = 'ENDRE';
export const ACTION_TYPE_TREKKE = 'TREKK';
export const ACTION_TYPE_ANGRE_TREKK = 'ANGRE_TREKK';
export const ACTION_TYPE_DOKUMENTASJON = 'DOKUMENTASJON';
export const ACTION_TYPE_SVARE = 'SVARE';
export const ACTION_TYPE_ENDRE_SVAR = 'ENDRE_SVAR';
export const ACTION_TYPE_TREKKE_FRA_VENTELISTE = 'TREKKE_FRA_VENTELISTE';
export const ACTION_TYPE_SE = 'ACTION_TYPE_SE';
export const ACTION_TYPE_SLETTE = 'ACTION_TYPE_SLETTE';
export const ACTION_TYPE_ENDRE_FAKTURAINFO = 'ACTION_TYPE_ENDRE_FAKTURAINFO';

export const actionToFristTypes = {
    [ACTION_TYPE_ENDRE]: 'ENDRE',
    [ACTION_TYPE_ENDRE_FAKTURAINFO]: 'BETALINGSINFO',
    [ACTION_TYPE_SVARE]: 'SVAR',
    [ACTION_TYPE_TREKKE]: 'TREKK',
    [ACTION_TYPE_ANGRE_TREKK]: 'ENDRE',
    [ACTION_TYPE_DOKUMENTASJON]: 'ETTERSENDING',
    [ACTION_TYPE_ENDRE_SVAR]: 'SVAR',
};

export function getTextIdFromStatus(status) {
    return (status && `STATUS/${status}`) || 'STATUS/UKJENT';
}

export const getClassFromStatus = status => {
    switch (status) {
        case STATUS_AVSLAG:
        case STATUS_AVLYST:
        case STATUS_BORTFALT:
        case STATUS_IKKE_TILBUD:
        case STATUS_TILBUD_ANNULLERT:
        case STATUS_TILBUD_OM_VENTELISTEPLASS_ANNULLERT:
        case STATUS_TRUKKET:
        case STATUS_TRUKKET_FRA_VENTELISTE:
        case STATUS_UBESVART_TILBUD:
        case STATUS_AUTOMATISK_TRUKKET_FRA_VENTELISTE:
        case STATUS_TILBUD_AVSLATT:
        case STATUS_IKKE_GODKJENT:
            return 'status__avslag';
        case STATUS_TILBUD_OM_VENTELISTEPLASS:
        case STATUS_TILBUD:
        case STATUS_MANGLER_DOKUMENTASJON:
            return 'status__varsel';
        case STATUS_PAMELDT:
        case STATUS_TILBUD_AKSEPTERT:
            return 'status__godkjent';
        default:
            return 'status__standard';
    }
};

export function getActionsFromStatus(status) {
    switch (status) {
        case STATUS_VENTELISTE:
            return [ACTION_TYPE_TREKKE_FRA_VENTELISTE, ACTION_TYPE_SE];
        case STATUS_TILBUD_OM_VENTELISTEPLASS:
        case STATUS_TILBUD:
            return [ACTION_TYPE_SVARE, ACTION_TYPE_SE];
        case STATUS_TILBUD_AKSEPTERT:
            return [ACTION_TYPE_ENDRE_SVAR, ACTION_TYPE_TREKKE, ACTION_TYPE_SE];
        case STATUS_TILBUD_AVSLATT:
            return [ACTION_TYPE_ENDRE_SVAR, ACTION_TYPE_SE];
        case STATUS_MANGLER_DOKUMENTASJON:
            return [ACTION_TYPE_DOKUMENTASJON, ACTION_TYPE_ENDRE, ACTION_TYPE_TREKKE];
        case STATUS_SOKNAD_REGISTRERT:
            return [ACTION_TYPE_ENDRE, ACTION_TYPE_DOKUMENTASJON, ACTION_TYPE_TREKKE];
        case STATUS_PAMELDT:
            return [ACTION_TYPE_TREKKE, ACTION_TYPE_SE];
        case STATUS_IKKE_FULLFORT:
            return [ACTION_TYPE_ENDRE, ACTION_TYPE_SLETTE];
        case STATUS_TRUKKET:
        case STATUS_TRUKKET_FRA_VENTELISTE:
            return [ACTION_TYPE_ANGRE_TREKK, ACTION_TYPE_SE];
        case STATUS_AVSLAG:
        case STATUS_AVLYST:
        case STATUS_BORTFALT:
        case STATUS_IKKE_TILBUD:
        case STATUS_TILBUD_ANNULLERT:
        case STATUS_TILBUD_OM_VENTELISTEPLASS_ANNULLERT:
        case STATUS_UBESVART_TILBUD:
        case STATUS_AUTOMATISK_TRUKKET_FRA_VENTELISTE:
        case STATUS_IKKE_GODKJENT:
        default:
            return [ACTION_TYPE_SE];
    }
}