import Text from "../../primitives/Text";
import FormattedDate from "../../primitives/FormattedDate";
import React from "react";

const Betalingsfrist = ({betalingsfrist, datoPurret, betalingsfristOpprinnelig}) => {
    return (
        <div className='betalingsfrist'>
            <div className='wrapper-1'>
                <Text className='header' id='betalingsfrist'/>
                {datoPurret &&
                <s className='betalingsfrist__old-value'><FormattedDate date={betalingsfristOpprinnelig}/></s>}
            </div>
            <div className='wrapper-2'>
                {datoPurret && <Text className='betalingsfrist__purret-header' id={'purret_ny_frist'}/>}
                <FormattedDate className='betalingsfrist__value' date={betalingsfrist}/>
            </div>
        </div>
    )
};

export default Betalingsfrist;