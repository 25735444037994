import {ERROR_CODE_EPOST_REGISTRERT_PA_ANNEN_BRUKER, getRequestErrorCode, getRequestErrorTextId} from "./request";

export const MAX_LENGDE_EPOST = 100;


export const getErrorMessageId = (epost, obligatorisk, apiError, doneEditing=true) => {

    if (doneEditing && !epost && obligatorisk) {
        return 'epost_obligatorisk'
    }

    if (!epost) {
        return undefined;
    }

    if (doneEditing && !isValidEmail(epost)) {
        return 'du_må_oppgi_en_gyldig_epost'
    }

    if (apiError) {
        return getRequestErrorTextId(apiError);
    }

    return undefined;
};

export const isEpostHasError = (epost, obligatorisk, apiError) => {
    return Boolean(getErrorMessageId(epost, obligatorisk, apiError, true));
};


export function isValidEmail(email) {
    // Kopiert fra StudentWeb:
    // Tatt fra http://www.regular-expressions.info/email.html
    // Utvidet til å akseptere store bokstaver.
    const emailPattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;
    return emailPattern.test(email);
}

export const isEpostRelatertApiError = error => (
    getRequestErrorCode(error) === ERROR_CODE_EPOST_REGISTRERT_PA_ANNEN_BRUKER
);