import React from 'react'
import PropTypes from 'prop-types'

const Error = (props) => {

    const {children, className = '', ...restOfProps} = props;

    const classes = `error-message-in-place ${className}`;

    return (
        <span className={classes} {...restOfProps}>{children}</span>
    )
};

Error.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Error