import React from 'react';
import {useSelector} from "react-redux";
import {getBetalteFakturaer} from "../../ducks/fakturaoversikt";
import Feedback from "../Feedback";
import {BetaltFakturaRow} from "./BetaltFakturaRow";
import Text from "../../primitives/Text";

export const Betalte = () => {

    const fakturaer = useSelector(getBetalteFakturaer);

    if (fakturaer.length === 0) {
        return (
            <Feedback  role="tabpanel" id="betalte">
                <Text id='du_har_ingen_betalte_fakturaer'/>
            </Feedback>

        )
    }

    return (
        <div role="tabpanel" className='betalte' id="betalte">
            <div className='table-header'>
                <Text className='dato' id={'dato'}/>
                <Text className='nummer' id={'nummer'}/>
                <Text className='belop' id={'beløp'}/>
                <span className='actions'/>
            </div>
            <ol>
                {fakturaer.map(faktura => <BetaltFakturaRow {...faktura} key={faktura.fakturanr}/>)}
            </ol>
        </div>
    )
};

export default Betalte;