import * as routes from "./apiRoutes";
import axios from "axios/index";

export function getInstitusjoner() {

    return axios.get(routes.INSTITUSJONER)
        .then(response => {
            return response.data.institusjoner;
        })
        .catch(error => {
            throw error;
        });
}

