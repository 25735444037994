import React from 'react';
import {useSelector} from "react-redux";
import {getApptekst} from "../../ducks/apptekster";
import HTMLReactParser from "html-react-parser";
import {ReadMoreText} from "../ReadMoreText";

const TextFraInstitusjon = () => {

    const tekst = useSelector(state => getApptekst(state, 'EVU_KATALOGTEKST'));

    if(!tekst){
        return null;
    }

    return (
        <ReadMoreText className='text-fra-institusjon' textLength={tekst.length}>
            {HTMLReactParser(tekst + "")}
        </ReadMoreText>
    )
};

export default TextFraInstitusjon;