import React from 'react';
import Checkbox from "../../primitives/Checkbox";
import Valgfritt from "./Valgfritt";
import Error from "../Error";
import {injectIntl} from 'react-intl';
import PropTypes from "prop-types";
import ErrorFormSaving from "../ErrorFormSaving";
import Text from "../../primitives/Text";


export class SvarPolar extends React.Component {

    handleChange = newResponse => {
        const {response, setResponse, sendSvar} = this.props;
        setResponse(response === newResponse ? undefined : newResponse);
        sendSvar();
    };

    render() {

        const {response, mandatory, showError, hasError, wsError, isLoading, intl, children: sporsmalstekst, sporsmalLang} = this.props;
        const {formatMessage} = intl;

        return (
            <fieldset className={`sporreskjema-sporsmal__svar__polar ${isLoading && 'loading'}`}  lang={sporsmalLang}>
                <legend>
                    {sporsmalstekst}
                    {!mandatory && <Valgfritt/>}
                </legend>
                <Checkbox
                    label={formatMessage({id: 'ja'})}
                    checked={response === true}
                    loading={isLoading}
                    onChange={() => this.handleChange(true)}
                />
                <Checkbox
                    label={formatMessage({id: 'nei'})}
                    checked={response === false}
                    loading={isLoading}
                    onChange={() => this.handleChange(false)}
                />
                {showError && hasError && !wsError &&
                <Error>
                    <Text id="spørsmal_ikke_valgfritt_og_må_svares_på"/>
                    <span> {sporsmalstekst}</span>
                </Error>
                }
                {wsError &&
                <ErrorFormSaving
                    error={wsError}
                />
                }
            </fieldset>
        )
    }
}

SvarPolar.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
    }).isRequired,
    response: PropTypes.bool,
    mandatory: PropTypes.bool.isRequired,
    showError: PropTypes.bool.isRequired,
    hasError: PropTypes.bool.isRequired,
    wsError: PropTypes.object,
    children: PropTypes.string.isRequired,
    sendSvar: PropTypes.func.isRequired,
};

export default injectIntl(SvarPolar);