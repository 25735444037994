import React from 'react';
import {useSelector} from "react-redux";
import {getAllowedFiletypesFormatted} from "../ducks/appegenskaper";
import Text from "../primitives/Text";

const AllowedFiletypes = () => {
    const filetypesFormatted = useSelector(getAllowedFiletypesFormatted);
    return (
        <Text
            id='du_kan_laste_opp'
            values={{filtyper: filetypesFormatted}}
        />
    );
};

export default AllowedFiletypes;

