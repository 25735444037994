import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {triggersButton} from "../utils/common";


export class ClickableArea extends Component {

    constructor(props) {
        super(props);

        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleKeyPress(event) {
        const {onClick} = this.props;
        if (triggersButton(event)) {
            onClick();
        }
    };


    render() {
        const {children, onClick, disabled, className='', ariaLabel, ...restOfProps} = this.props;


        const classes = `clickable-area ${disabled ? 'disabled': ''} ${className}`;

        return (
            // eslint-disable-next-line jsx-a11y/interactive-supports-focus
            <div className={classes}
                 role='button'
                 aria-label={ariaLabel}
                 aria-disabled={disabled}
                 tabIndex={disabled ? undefined : 0}
                 onClick={disabled ? undefined : onClick}
                 onKeyPress={disabled ? undefined : this.handleKeyPress}
                 {...restOfProps}>
                {children}
            </div>
        )
    }
}

ClickableArea.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
};


export default ClickableArea;