import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {EKSAMENSRETTET, EKSAMENSTYPER, FRIVILLIG_EKSAMENSRETTET, IKKE_EKSAMENSRETTET} from "../../../services/kursApi";
import Checkbox from "../../../primitives/Checkbox";
import Filter from "./Filter";
import {getEksamenCounter, getEksamenFilter, setEksamenFilter} from "../../../ducks/kursoversikt";
import {injectIntl} from "react-intl";

const EKSAMEN_TYPE_TEXT_ID = {
    [EKSAMENSRETTET]: 'ja_counter',
    [FRIVILLIG_EKSAMENSRETTET]: 'frivillig_counter',
    [IKKE_EKSAMENSRETTET]: 'nei_counter',
};

const FilterEksamen = ({intl}) => {
    const eksamenCounter = useSelector(getEksamenCounter);
    const eksamenFilter = useSelector(getEksamenFilter);
    const dispatch = useDispatch();
    return (
        <Filter
            labelTextId={`eksamen`}
        >
            {EKSAMENSTYPER.map(eksamentype =>
                <Checkbox
                    key={eksamentype}
                    label={intl.formatMessage({id: EKSAMEN_TYPE_TEXT_ID[eksamentype]}, {counter: eksamenCounter(eksamentype)})}
                    onChange={() => dispatch(setEksamenFilter(eksamentype))}
                    checked={eksamenFilter === eksamentype}
                />
            )}
        </Filter>
    )
};

export default injectIntl(FilterEksamen);