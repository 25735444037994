import React from 'react';
import PrivatEpost from "./PrivatEpost";
import Arbeidsepost from "./Arbeidsepost";
import Heading from "../../../primitives/Heading";
import {headingLevelByPage} from "../../../utils/headingUtil";


export const Eposter = ({page}) => {
    return(
        <div className='e-post flat-card'>
            <Heading id={'e_post'} headingLevel={headingLevelByPage(page)} />
            <div className='content'>
                <PrivatEpost />
                <Arbeidsepost />
            </div>
        </div>
    )
}


export default Eposter;