import {createSlice} from "@reduxjs/toolkit";
import {getOrganisasjon} from "../services/organisasjonAPI";
import {getEvuapiToken} from "./session";

export const NAME = 'organisasjoner';

export const initialState = {
    isLoading: false,
    error: undefined,
    organisasjoner: {},
};


export const loadOrganisasjon = organisasjonsnummer => async (dispatch, getState) => {
    const evuapiToken = getEvuapiToken(getState());
    dispatch(fetchPending());
    try {
        const organisasjonsnavn = await getOrganisasjon(organisasjonsnummer, evuapiToken);
        dispatch(fetchSuccess({organisasjonsnummer, organisasjonsnavn}));
    } catch (error) {
        dispatch(fetchFailure(error));
    }
};

const {actions, reducer} = createSlice({
    initialState,
    name: NAME,
    reducers: {
        fetchPending: (state) => {
            state.isLoading = true;
            state.error = undefined;
        },
        fetchSuccess: (state, {payload}) => {
            const {organisasjonsnummer, organisasjonsnavn} = payload;
            state.isLoading = false;
            state.organisasjoner = {
                ...state.organisasjoner,
                [organisasjonsnummer]: organisasjonsnavn,
            };
        },
        fetchFailure: (state, {payload: error}) => {
            state.isLoading = false;
            state.error = error;
        },
        resetError: (state) => {
            state.error = undefined;
        },
    }
});


export const {fetchPending, fetchSuccess, fetchFailure, resetError} = actions;

export default reducer;

const getState = state => state[NAME];

export const getAPIError = state => getState(state).error;
export const getIsLoading = state => getState(state).isLoading;
export const getOrganisasjoner = state => getState(state).organisasjoner;
export const getOrganisasjonsnavn = (state, organisasjonsnummer) => getOrganisasjoner(state)[organisasjonsnummer];

