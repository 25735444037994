import React from 'react';
import {useSelector} from "react-redux";
import {getForetakNavn} from "../../ducks/pamelding";

import './foretak-navn.scss';

const ForetakNavn = () => {
    const foretakNavn = useSelector(getForetakNavn);
    if(!foretakNavn)return null;
    return (
        <strong className='foretaknavn'>
            {foretakNavn}
        </strong>
    )
};

export default ForetakNavn;