import React from "react";
import Input from "../../primitives/Input";
import {
    MAX_LENGDE_POSTADRESSE,
} from "../../utils/common";
import Error from "../Error";
import Text from "../../primitives/Text";

const InputPostadresse = ({isFocusOn, value, onChange, error, showError, onBlur}) => (
    <>
        <Input
            conditionForFocus={isFocusOn}
            label='postadresse'
            value={value}
            hasError={error && showError}
            onChange={event => onChange('postadresse', event.target.value)}
            autoComplete='address-level2'
            onBlur={onBlur}
            maxLength={MAX_LENGDE_POSTADRESSE}
        />
        {error && showError &&
        <Error>
            <Text id={error}/>
        </Error>}
    </>
);

export default InputPostadresse;