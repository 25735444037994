import React from "react";
import {mobilEllerEpostMangler, mobilOgEpostErObligatorisk} from "../../ducks/profil";
import withSoknaderLoaded from "../../high-order-components/withSoknaderLoaded";
import withProfildataLoaded from "../../high-order-components/withProfildataLoaded";
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import {connect} from "react-redux";
import Text from "../../primitives/Text";

export class ObligatoriskAdvarsel extends React.Component {

    render() {
        const {mobilOgEpostErObligatorisk, mobilEllerEpostMangler} = this.props;
        if (mobilOgEpostErObligatorisk && mobilEllerEpostMangler) {
            return (
                <Feedback type={FEEDBACK_TYPE_VARSEL}>
                    <Text id='obligatoriske_kontaktfelter' />
                </Feedback>
            )
        }
        return null;
    }
}

const mapStateToProps = state => {
    return {
        mobilOgEpostErObligatorisk: mobilOgEpostErObligatorisk(state),
        mobilEllerEpostMangler: mobilEllerEpostMangler(state),
    }
};

export default withProfildataLoaded(
    withSoknaderLoaded(
        connect(mapStateToProps)(ObligatoriskAdvarsel)
    )
);