import * as routes from "./apiRoutes";
import {createRequestConfig} from "../utils/request";
import axios from "axios";

export const postSkalTaEksamen = async (evuapiToken, deltakernr, kursId, value) => {
    const route = routes.EKSAMEN
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursId)
    ;
    const config = createRequestConfig(evuapiToken);
    const payload = {
        status_eksamen: Boolean(value)
    };
    await axios.post(route, payload, config);
};


export const transformSkalTaEksamenFromWS = soknad =>
    soknad.statusEksamen === null ? undefined : soknad.statusEksamen;
