import React from 'react';
import preval from 'preval.macro';
import {pages} from "../../utils/common";
import {useSelector} from "react-redux";
import {
    APPTEKST_KONTAKTINFO_ADRESSE,
    APPTEKST_KONTAKTINFO_EPOST,
    APPTEKST_KONTAKTINFO_STED,
    APPTEKST_KONTAKTINFO_TELEFON,
    APPTEKST_PERSONVERN_URL,
    getApptekst
} from "../../ducks/apptekster";
import {ExternalLink, MailToLink, ReactLink} from "../../primitives/Link";
import {SiteMap} from "./SiteMap";
import './footer.scss';
import {getAPIBuildTimestampFormatted, getAPILastCommitShort} from "../../ducks/apiInfo";
import {formatVersjonsTimestamp} from "../../utils/dateUtil";
import Text from "../../primitives/Text";
import { getLocale } from '../../ducks/i18n';


const buildDate = preval`
module.exports = new Date().toISOString();
`;

export const Footer = () => {
    const personvernURL = useSelector(state => getApptekst(state, APPTEKST_PERSONVERN_URL));
    const kontaktinfoEpost = useSelector(state => getApptekst(state, APPTEKST_KONTAKTINFO_EPOST));
    const kontaktinfoTelefon = useSelector(state => getApptekst(state, APPTEKST_KONTAKTINFO_TELEFON));
    const kontaktinfoSted = useSelector(state => getApptekst(state, APPTEKST_KONTAKTINFO_STED));
    const kontaktinfoAdresse = useSelector(state => getApptekst(state, APPTEKST_KONTAKTINFO_ADRESSE));
    const apiBuildTimestamp = useSelector(getAPIBuildTimestampFormatted);
    const apiLastCommit = useSelector(getAPILastCommitShort);
    const appBuildTimestamp = formatVersjonsTimestamp(buildDate);

    function getTilgjengelighetURL(language) {
        const tilgjengelighetURLs = {
            "nb": "https://uustatus.no/nb/erklaringer/publisert/aad707b0-391b-4b8b-8c07-328ab801050e",
            "nn": "https://uustatus.no/nn/erklaringer/publisert/aad707b0-391b-4b8b-8c07-328ab801050e"
        };

        return (tilgjengelighetURLs[language] ?? tilgjengelighetURLs.nb);
    }

    return (
        <footer>
            <div className='container'>

                <div className="footer-info">
                    <ul>
                        <li>
                            <Text id='footer_tittel' values={{appBuildTimestamp}}/>
                        </li>
                        <li>
                            <Text id='API_versjon' values={{apiBuildTimestamp, apiLastCommit}}/>
                        </li>
                        {(kontaktinfoSted || kontaktinfoAdresse) &&
                        <li>
                            <address>
                                {kontaktinfoSted && <>{kontaktinfoSted}<br /></>}
                                {kontaktinfoAdresse}
                            </address>
                        </li>
                        }
                        {kontaktinfoEpost &&
                        <li>
                            <Text id='footer_kontakt_email'
                                  values={{
                                      email: <MailToLink email={kontaktinfoEpost}/>
                                  }}
                            />
                        </li>
                        }
                        {kontaktinfoTelefon &&
                        <li>
                            <Text id='footer_kontakt_telefon' values={{telefon: kontaktinfoTelefon}}/>
                        </li>
                        }
                        {personvernURL &&
                        <li>
                            <ExternalLink href={personvernURL}>
                                <Text id='personvernerklæring'/>
                            </ExternalLink>
                        </li>
                        }
                        <li>
                            <ExternalLink href={getTilgjengelighetURL(useSelector(getLocale))}>
                                <Text id='tilgjengelighetserklæring'/>
                            </ExternalLink>
                        </li>
                        <li>
                            <ReactLink to={pages.cookies}>
                                <Text id='les_om_vår_bruk_av_cookies'/>
                            </ReactLink>
                        </li>
                    </ul>
                </div>

                <SiteMap/>
            </div>

        </footer>
    )
};

export default Footer;