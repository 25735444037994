import React from "react";
import {useSelector} from 'react-redux';
import withDokumenterLoaded from "../../../high-order-components/withDokumenterLoaded";
import withSoknadsdokumenter from "../../../high-order-components/withSoknadsdokumenter";
import {compose} from "redux";
import {getHarBareAndreKrav, getKurs} from "../../../ducks/kursinfo";
import TilknyttedeDokumenter from "../../steg-dokumentasjon/dokumenter-elementer/TilknyttedeDokumenter";
import {ANNET_DOKUMENTTYPE, GSK_DOKUMENTTYPE, HOYERE_UTDANNING__DOKUMENTTYPE} from "../../../services/dokumenterAPI";
import Text from "../../../primitives/Text";
import {getTilknyttetArbeidserfaringer} from "../../../ducks/arbeidserfaring";
import Periode from "../../arbeidserfaring/Periode";
import ArbeidsgiverOgStillingstittel from "../../arbeidserfaring/ArbeidsgiverOgStillingstittel";
import Vedlegg from "../../Vedlegg";
import withArbeidserfaringLoaded from "../../../high-order-components/withArbeidserfaringLoaded";
import {harGSK as harGSKSelektor} from "../../../ducks/dokumenter";
import GSK from "../../gsk/GSK";
import {mdiBriefcaseOutline} from "@mdi/js";
import Icon from "../../../primitives/Icon";

const StegOversiktDokumentasjon = () => {

    const {harGSKKrav, harKravOmHoyereUtd, harKravOmPraksis, harAndreKrav} = useSelector(getKurs);
    const harBareAndreKrav = useSelector(getHarBareAndreKrav);
    const harGSK = useSelector(harGSKSelektor);

    return (
        <div className='steg-oversikt__steg__dokumentasjon'>

            {harGSKKrav &&
            <>
                <h4><Text id='generell_studiekompetanse_GSK'/></h4>
                {!harGSK && <TilknyttedeDokumenter type={GSK_DOKUMENTTYPE}/>}
                <GSK/>
            </>}

            {harKravOmHoyereUtd &&
            <>
                <h4><Text id='høyere_utdanning'/></h4>
                <TilknyttedeDokumenter type={HOYERE_UTDANNING__DOKUMENTTYPE}/>
            </>}

            {harKravOmPraksis &&
            <>
                <h4><Text id='arbeidserfaring'/></h4>
                <Arbeidserfaringer/>
            </>}

            {harAndreKrav &&
            <>
                {!harBareAndreKrav && <h4><Text id='annet'/></h4>}
                <TilknyttedeDokumenter type={ANNET_DOKUMENTTYPE}/>
            </>}
        </div>
    );
};

const Arbeidserfaringer = () => {

    const arbeidserfaringer = useSelector(getTilknyttetArbeidserfaringer);
    if (!arbeidserfaringer.length) {
        return <div className='tilknyttede-arbeidserfaringer-wrapper'>
            <Icon path={mdiBriefcaseOutline} className='briefcase-icon'/>
            <em><Text id='ingen_arbeidserfaringer_registrert'/></em>
        </div>
    }

    return (
        <div className='tilknyttede-arbeidserfaringer-wrapper'>
            <ol className='tilknyttede-arbeidserfaringer'>
                {arbeidserfaringer.map(arbeidserfaring =>
                    <Arbeidserfaring
                        key={arbeidserfaring.id}
                        {...arbeidserfaring}
                    />)}
            </ol>
        </div>
    )
};

const Arbeidserfaring = ({fraDato, tilDato, arbeidsgiver, stillingsprosent, stillingstittel, dokumentid, dokumentnavn}) =>
    <li className='steg-oversikt__arbeidserfaring'>
        <Periode fraDato={fraDato} tilDato={tilDato} monthInDigits/>
        <ArbeidsgiverOgStillingstittel
            arbeidsgiver={arbeidsgiver}
            stillingsprosent={stillingsprosent}
            stillingstittel={stillingstittel}
        />
        <Vedlegg dokumentid={dokumentid} dokumentnavn={dokumentnavn}/>
    </li>
;


export default compose(
    withSoknadsdokumenter,
    withDokumenterLoaded,
    withArbeidserfaringLoaded
)(StegOversiktDokumentasjon);