import * as routes from "./apiRoutes";
import axios from "axios";
import {createTidligereKurs} from "../ducks/tidligereKurs";
import {createKursId} from "../ducks/kursoversikt";
import {parseIsoDate} from "../utils/common";


export function fetchTidligereKurs(deltakernr, evuapiToken) {

    const route = routes.TIDLIGERE_KURS.replace(':deltakernr', deltakernr);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        }
    };

    return axios.get(route, config)
        .then(response => {
            return transformOutput(response.data);
        })
        .catch(error => {
            throw error;
        });
}


function transformOutput(data){
    return data.map(kurs => createTidligereKurs({
        kursnavn: kurs.kursnavn,
        kursId: createKursId(kurs.kurskode, kurs.tidskode),
        datoFra: parseIsoDate(kurs.dato_fra),
        datoTil: parseIsoDate(kurs.dato_til),
        datoKursbevis: kurs.dato_kursbevis,
        dokumentnr: kurs.dokumentnr,
        filnavn: kurs.filnavn,
        isGodkjent: kurs.status_godkjent,
    }));
}