import React from 'react';
import PropTypes from 'prop-types';

const Laster = (props) => {

    const {children, className = '', ...restOfProps} = props;

    return (
        <div className={`laster-data-container ${className}`} {...restOfProps}>
            <div className='loader' />
            <div className='description'>
                {children}
            </div>
        </div>
    )
};

Laster.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Laster