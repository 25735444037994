import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {fetchFakturaer} from "../services/fakturaoversiktAPI";
import {createSlice} from "@reduxjs/toolkit";

export const NAME = 'fakturaoversikt';

export const createFaktura = ({
                                  fakturanr,
                                  datoOpprettet,
                                  betalingsfrist,
                                  datoPurret,
                                  isBetalt,
                                  betalingsfristOpprinnelig,
                                  belop
                              }) =>
    ({
        fakturanr,
        datoOpprettet,
        betalingsfrist,
        datoPurret,
        isBetalt,
        betalingsfristOpprinnelig,
        belop
    });


export const loadFakturaer = () => (dispatch, getState) => {

    dispatch(fetchPending());

    const evuapiToken = getEvuapiToken(getState());
    const deltakernr = getAktueltDeltakernr(getState());

    return fetchFakturaer(deltakernr, evuapiToken).then(
        result => dispatch(fetchSuccess(result)),
        error => dispatch(fetchFailure(error))
    );
};

const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    fakturaer: [],
};

const {actions, reducer} = createSlice({
    initialState,
    name: NAME,
    reducers: {
        fetchPending: (state) => {
            state.isLoading = true;
            state.isDataLoaded = false;
            state.error = undefined;
        },
        fetchSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.isDataLoaded = true;
            state.fakturaer = payload;
        },
        fetchFailure: (state, {payload}) => {
            state.isLoading = false;
            state.isDataLoaded = true;
            state.error = payload;
        },
    }
});


export const {fetchPending, fetchSuccess, fetchFailure} = actions;

export default reducer;

const getState = state => state[NAME];

export const getError = state => getState(state).error;
export const isLoading = state => getState(state).isLoading;
export const isDataLoaded = state => getState(state).isDataLoaded;

export const getFakturaer = state => getState(state).fakturaer;

export const getUtestaendeFakturaer = state => getFakturaer(state).filter(faktura => !faktura.isBetalt);
export const getBetalteFakturaer = state => getFakturaer(state).filter(faktura => faktura.isBetalt);