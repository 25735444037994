import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    getFeideDomene,
    hasLoginFailed as hasLoginFailedSelector,
    loadEvuApiToken,
    loginFailed,
    removeDataportenToken,
    setDataportenToken,
} from '../../ducks/session';
import handleManglerBruker from "../../high-order-components/handleManglerBruker";
import {compose} from "redux";
import blockedIfLoggedIn from "../../high-order-components/blockedIfLoggedIn";
import {useMountEffect} from "../../hooks/mounting-hooks";
import {clearTokensFromLocalStorage,} from "../../utils/sessionUtil";
import {checkNonce, getClient} from "../../utils/loginUtil";
import LoggerPaSpinner from "./LoggerPaSpinner";
import InnloggingFeilet from "./InnloggingFeilet";
import queryString from "query-string";
import {useLocation} from "react-router";


const LoginHandler = () => {

    const hasLoginFailed = useSelector(hasLoginFailedSelector);
    const feideDomene = useSelector(getFeideDomene);
    const dispatch = useDispatch();
    const location = useLocation();
    const params = queryString.parse(location.search);

    useMountEffect(() => {
        login(dispatch, params.loginMethod, feideDomene);
    });

    if (hasLoginFailed) {
        return <InnloggingFeilet/>
    }

    return <LoggerPaSpinner/>
};


const login = async (dispatch, loginMethod, feideDomene) => {

    const client = getClient(loginMethod, feideDomene);

    try {
        client.callback();
        const dataportenToken = await client.getToken();
        checkNonce(dataportenToken);
        dispatch(setDataportenToken(dataportenToken));
        dispatch(loadEvuApiToken());
    } catch (error) {
        dispatch(loginFailed(error));
        dispatch(removeDataportenToken());
        clearTokensFromLocalStorage()
    }

};


export default compose(
    blockedIfLoggedIn,
    handleManglerBruker,
)(LoginHandler);

