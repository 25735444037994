import * as routes from "./apiRoutes";
import axios from "axios";
import {createFaktura} from "../ducks/fakturaoversikt";
import {parseIsoDate} from "../utils/common";
import {createRequestConfig, downloadFile} from "../utils/request";

export function fetchFakturaer(deltakernr, evuapiToken) {

    const route = routes.FAKTURAER.replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);

    return axios.get(route, config)
        .then(response => {
            return transformDataFromWS(response.data);
        })
        .catch(error => {
            throw error;
        });
}

export async function downloadFaktura(fakturanr, filnavn, evuapiToken, deltakernr) {
    const route = routes.FAKTURA.replace(':fakturanr', fakturanr).replace(':deltakernr', deltakernr);
    await downloadFile(route, evuapiToken, filnavn);
}


function transformDataFromWS(data) {
    return data.map(faktura => createFaktura({
        fakturanr: faktura.fakturanr,
        datoOpprettet: parseIsoDate(faktura.datoOpprettet),
        betalingsfrist: parseIsoDate(faktura.betalingsfrist),
        datoPurret: parseIsoDate(faktura.datoPurret),
        isBetalt: faktura.isBetalt,
        betalingsfristOpprinnelig: parseIsoDate(faktura.betalingsfristOpprinnelig),
        belop: faktura.belop,
    }));
}