import React from 'react'
import PropTypes from 'prop-types'
import Laster from './Laster'

const Lagringspinner = (props) => {

    const { children, ...restOfProps } = props;

    return (
        <div {...restOfProps}>
            <Laster>
                {children}
            </Laster>
        </div>
    )

};

Lagringspinner.propTypes = {
    children: PropTypes.node,
};

export default Lagringspinner