import {DownloadLink} from "./dokumentasjon/DownloadLink";
import {mdiPaperclip, mdiFileOutline } from '@mdi/js';
import React from "react";
import './vedlegg.scss';
import Text from "../primitives/Text";
import Icon from "../primitives/Icon";

const Vedlegg = ({dokumentid, dokumentnavn}) => (
    dokumentid ?
        <div className='vedlegg'>
            <Icon path={mdiFileOutline} className='file-icon' title='vedlegg'/>
            <DownloadLink dokumentnr={dokumentid} filnavn={dokumentnavn}/>
        </div>
        :
        <div className='vedlegg'>
            <Icon path={mdiPaperclip} className='ingen-filer-icon'/>
            <em><Text id='ingen_filer_vedlagt'/></em>
        </div>
);

export default Vedlegg;