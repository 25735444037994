import React from "react";
import Card from "../Card";
import {useSelector} from "react-redux";
import {antallVarseltyper as antallVarseltyperSelector} from "../../../ducks/soknader";
import {
    getAktueltDeltakernr,
    getIsSaksbehandlerLoggedInSomBruker,
    isSaksbehandler as isSaksbehandlerSelector
} from "../../../ducks/session";
import {personIconPath} from "../../../utils/icons";
import {getAntallUbesvarteSoknaderTotalt} from "../../../ducks/foretaksgodkjenning";
import Text from "../../../primitives/Text";

const VarselSaksbehandlerDefault = () => {
    const antallVarseltyper = useSelector(antallVarseltyperSelector);
    const isSaksbehandler = useSelector(isSaksbehandlerSelector);
    const deltakernummer = useSelector(getAktueltDeltakernr);
    const isLoggedInSomBedriftsbruker = useSelector(getIsSaksbehandlerLoggedInSomBruker);
    const antallUbesvarteSoknaderTotalt = useSelector(getAntallUbesvarteSoknaderTotalt);
    if (!isSaksbehandler
        || antallVarseltyper > 0 || antallUbesvarteSoknaderTotalt > 0
        || deltakernummer || isLoggedInSomBedriftsbruker) {
        return null;
    }
    return (
        <Card
            tittel={<Text id='velkommen_til_evuweb'/>}
            iconPath={personIconPath}
        >
            <Text id='bruk_personmenyen_oppe_til_høyre'/>
        </Card>
    )
};

export default VarselSaksbehandlerDefault;