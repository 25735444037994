import * as routes from "./apiRoutes";
import axios from "axios/index";
import {
    kursIdToUrlString,
    parseIsoDate,
    sanitizeHtmlText,
} from "../utils/common";
import {createPlaninfoData} from "../ducks/planinfo";
import {createRequestConfig} from "../utils/request";


export async function getPlaninfoBekreftelse(evuapiToken, kursId, deltakernr) {
    const route = routes.PLANINFO_BEKREFTELSE
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursIdToUrlString(kursId));

    const config = createRequestConfig(evuapiToken);

    const response = await axios.get(route, config);
    return transformBekreftelseDataFromWS(response.data);
}


function transformBekreftelseDataFromWS(data) {

    if (!data) {
        return [];
    }

    return data.map(planinfo =>
        createPlaninfoData({
            kode: planinfo.planinformasjonkode,
            rekkefolgenr: planinfo.rekkefolgenr,
            status_svar_eksamen: planinfo.statusSvarEksamen,
            url: transformPlaninfoteksterAttributeToTranslations(planinfo.planinfotekster, 'url'),
            merknadtekst: transformPlaninfoteksterAttributeToTranslations(planinfo.planinfotekster, 'merknadstekst'),
            status_bekreft: planinfo.statusBekreft,
            status_bekreftet: planinfo.statusBekreftet,
            dato_bekreftet: parseIsoDate(planinfo.datoBekreftet),
        }));
}

export const transformPlaninfoteksterAttributeToTranslations = (planinfotekster, attribute) =>
    planinfotekster.map(({sprakkode: lang, [attribute]: value}) => ({
            lang,
            value: sanitizeHtmlText(value),
        })
    );


export async function lagreBekreftelser(evuapiToken, deltakernr, kursId, kode) {
    const route = routes.PLANINFO_BEKREFTELSE
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursIdToUrlString(kursId));

    const input = transformBekreftelseDataToWS(kode);

    const config = createRequestConfig(evuapiToken);

    return await axios.post(route, input, config);
}

function transformBekreftelseDataToWS(kode) {
    return {
        planinformasjonkode: kode
    }
}
