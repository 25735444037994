import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    deleteArbeidserfaring,
    getIsInEditMode, getLedigeArbeidserfaringer,
} from "../../../ducks/arbeidserfaring";
import withArbeidserfaringLoaded from "../../../high-order-components/withArbeidserfaringLoaded";
import Feedback from "../../Feedback";
import Text from "../../../primitives/Text";
import Checkbox from "../../../primitives/Checkbox";
import {showPopup} from "../../../ducks/popup";
import Button from "../../../primitives/Button";
import ArbeidserfaringPresentation from "../../arbeidserfaring/ArbeidserfaringPresentation";
import ArbeidserfaringListItem from "../../arbeidserfaring/ArbeidserfaringListItem";
import {mdiDelete} from "@mdi/js";


const ArbeidserfaringerTidlegereBrukt = ({selected, setSelected}) => {
    const arbeidserfaringer = useSelector(getLedigeArbeidserfaringer);
    const isEditMode = useSelector(getIsInEditMode);

    const isChecked = arbeidserfaringId => selected.includes(arbeidserfaringId);

    const checkboxOnChange = arbeidserfaringId => () => {
        const nySelected =
            isChecked(arbeidserfaringId) ?
                selected.filter(id => id !== arbeidserfaringId)
                :
                [...selected, arbeidserfaringId];

        setSelected(nySelected);
    };


    if (!arbeidserfaringer.length) {
        return (
            <Feedback>
                <Text id='du_har_ingen_tidligere_brukt_arbeidserfaringer'/>
            </Feedback>
        )
    }

    return (
        <div className='tidligere-brukt-arbeidserfaringer'>
            <ol className={isEditMode ? 'hidden' : ''}>
                {arbeidserfaringer.map(arbeidserfaring =>
                    <Arbeidserfaring
                        key={arbeidserfaring.id}
                        arbeidserfaring={arbeidserfaring}
                        isChecked={isChecked(arbeidserfaring.id)}
                        checkboxOnChange={checkboxOnChange(arbeidserfaring.id)}
                    />
                )}
            </ol>
        </div>
    )
};


const Arbeidserfaring = ({arbeidserfaring, isChecked, checkboxOnChange}) => {
    const {id, arbeidsgiver, isDeleting} = arbeidserfaring;
    return (
        <ArbeidserfaringListItem className='tidligere-brukt'>
            <div className='toggler'>
                <Checkbox
                    checked={isChecked}
                    onChange={checkboxOnChange}
                />
            </div>
            <ArbeidserfaringPresentation arbeidserfaring={arbeidserfaring} withEndreButton/>
            <div className='arbeidserfaring__actions'>
                <DeleteButton id={id} arbeidsgiver={arbeidsgiver} isDeleting={isDeleting}/>
            </div>
        </ArbeidserfaringListItem>
    )
};


const DeleteButton = ({id, arbeidsgiver, isDeleting}) => {
    const dispatch = useDispatch();
    const slettOnClick = () => {
        const popUpContent = {
            onOK: () => dispatch(deleteArbeidserfaring(id)),
            title: <Text id='slette_arbeidserfaring'/>,
            children: <Text
                id='er_du_sikker_på_at_du_vil_slette_arbeidserfaring'
                values={{arbeidsgiver: <em>{arbeidsgiver}</em>}}
            />,
            okButtonContent: <Text id='ja'/>,
            cancelButtonContent: <Text id='nei'/>,
        };
        dispatch(showPopup(popUpContent));
    };

    return (
        <Button
            onClick={slettOnClick}
            iconPath={mdiDelete}
            isInProgress={isDeleting}
            className='delete-button'
            altText='slett'
        />
    )
};

export default withArbeidserfaringLoaded(ArbeidserfaringerTidlegereBrukt);