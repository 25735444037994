import React from 'react';
import {useSelector} from "react-redux";
import {
    getFinnesBesvarteSoknader, getKursHarGodkjenning, getSoknaderForKursFiltrert,
} from "../../../ducks/foretaksgodkjenning";
import Feedback, {FEEDBACK_TYPE_INFO, FEEDBACK_TYPE_VARSEL} from "../../Feedback";
import SoknaderTable from "./SoknaderTable";
import Soknad from "./Soknad";
import UbesvartToggler from "./UbesvartToggler";
import Text from "../../../primitives/Text";

export const Soknader = ({kursId, foretakkode}) => {
    const soknaderFiltrert = useSelector(state => getSoknaderForKursFiltrert(state, kursId, foretakkode));
    const finnesBesvarteSoknader = useSelector(state => getFinnesBesvarteSoknader(state, kursId, foretakkode));
    const harGodkjenning = useSelector(state => getKursHarGodkjenning(state, kursId, foretakkode));
    if (soknaderFiltrert.length === 0) {
        return (
            <>
                <Feedback type={finnesBesvarteSoknader ? FEEDBACK_TYPE_INFO : FEEDBACK_TYPE_VARSEL}>
                    <Text
                        id={finnesBesvarteSoknader ? `ingen_nye_søknader_for_dette_kurset` : `ingen_søknader_for_dette_kurset`}
                    />
                </Feedback>
                <UbesvartToggler
                    kursId={kursId}
                    foretakkode={foretakkode}
                />
            </>
        )
    }
    return (
        <>
            <SoknaderTable harGodkjenning={harGodkjenning}>
                {soknaderFiltrert.map(soknad => <Soknad key={soknad.deltakernr} {...soknad}/>)}
            </SoknaderTable>
            <UbesvartToggler
                kursId={kursId}
                foretakkode={foretakkode}
            />
        </>
    )
};


export default Soknader;