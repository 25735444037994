import {useDispatch} from "react-redux";
import React, {useState} from "react";
import Button from "../../../primitives/Button";
import Text from "../../../primitives/Text";
import {tryAgainIconPath} from "../../../utils/icons";
import FeedbackWSError from "../../FeedbackWSError";

export const HentPaNyttButtonMedFeedback = ({isLoading, isDataLoaded, error, onClickAction}) => {

    const [reloadStarted, setReloadStarted] = useState(false);
    const isSyncSuccess = isDataLoaded && !error && reloadStarted;
    const errorSync = reloadStarted && error;
    const dispatch = useDispatch();

    return (<>
        <div className='button-and-feedback-wrapper'>
            <Button
                onClick={() => {
                    setReloadStarted(true);
                    dispatch(onClickAction)
                }}
                isInProgress={isLoading}
                isSuccessfullyDone={isSyncSuccess}
                className='hent-pa-nytt'
                iconPath={tryAgainIconPath}>
                <Text id='hent_på_nytt'/>
            </Button>
            {isSyncSuccess && <Text id={'kontaktinformasjonen_din_ble_oppdatert'}/>}
        </div>
        {errorSync && <FeedbackWSError error={errorSync}/>}
    </>)
};