import {SPORRESKJEMA} from "./apiRoutes";
import axios from "axios";
import {QUESTION_TYPE_FREETEXT, QUESTION_TYPE_POLAR} from "../ducks/sporreskjema";
import {kursIdStringToPathVariable} from "../utils/common";
import {createRequestConfig} from "../utils/request";

export function getSporreskjema(evuapiToken, deltakernr, kursId) {

    const route = SPORRESKJEMA
        .replace(':kursId', kursIdStringToPathVariable(kursId))
        .replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);

    return axios.get(route, config)
        .then(response => {
            return transformOutput(response.data);
        })
        .catch(error => {
            throw error;
        });
}

function transformOutput(data) {
    return data ? data.map(sporsmal => transformSporreskjemaFromWS(sporsmal)) : [];
}

function isFritekstSporsmalWS(sporsmal) {
    return sporsmal.isFritekst;
}

function isPolarSporsmalWS(sporsmal) {
    return !isFritekstSporsmalWS(sporsmal);
}

function fritekstSvarFromWS(sporsmal) {
    return isFritekstSporsmalWS(sporsmal) ? sporsmal.svar : "";
}

function polarSvarFromWS(sporsmal) {
    if (isPolarSporsmalWS(sporsmal)) {
        switch (sporsmal.svar) {
            case "J":
                return true;
            case "N":
                return false;
            default:
                return undefined;
        }
    }
    return undefined;
}

function transformSporreskjemaFromWS(sporsmal) {
    return {
        type: isFritekstSporsmalWS(sporsmal) ? QUESTION_TYPE_FREETEXT : QUESTION_TYPE_POLAR,
        kode: sporsmal.sporsmalskode,
        mandatory: sporsmal.isObligatorisk,
        text: sporsmal.kurssporsmaltekst,
        responseText: fritekstSvarFromWS(sporsmal),
        responsePolar: polarSvarFromWS(sporsmal),
        hasError: false,
        errorText: null,
    }
}

export function postSporreskjema(evuapiToken, deltakernr, kursId, sporsmal) {

    const route = SPORRESKJEMA
        .replace(':kursId', kursIdStringToPathVariable(kursId))
        .replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);

    const payload = transformSporsmalToWS(sporsmal);

    return axios.post(route, payload, config)
        .then(response => {
            return transformOutput(response.data);
        })
        .catch(error => {
            throw error;
        });

}

function transformSporsmalToWS(sporsmal) {
    return {
        sporsmalskode: sporsmal.kode,
        svar: transformSvarToWS(sporsmal),
    }
}

function isFritekstSporsmal(sporsmal) {
    return sporsmal.type === QUESTION_TYPE_FREETEXT;
}

function isPolarSporsmal(sporsmal) {
    return sporsmal.type === QUESTION_TYPE_POLAR;
}

function transformSvarToWS(sporsmal) {
    if (isFritekstSporsmal(sporsmal)) {
        return sporsmal.responseText || "";
    }
    if (isPolarSporsmal(sporsmal)) {
        switch (sporsmal.responsePolar) {
            case true:
                return "J";
            case false:
                return "N";
            default:
                return "";
        }
    }
    return "";
}