import React from 'react';
import CopyText from "../../CopyText";
import Feedback, {FEEDBACK_TYPE_FEIL, FEEDBACK_TYPE_OK} from "../../Feedback";
import Text from "../../../primitives/Text";
import {pages} from "../../../utils/common";

const PAMELDINGSLENKE = `${window.location.origin}${pages.pamelding}/:kursId?foretakkode=:foretakkode&hash=:hash`;

const Pameldingslink = ({kursId, foretakkode, hash}) => {

    const url = PAMELDINGSLENKE
        .replace(':kursId', encodeURIComponent(kursId))
        .replace(':foretakkode', encodeURIComponent(foretakkode))
        .replace(':hash', encodeURIComponent(hash));

    return (
        <div className={'pamelingslink'}>
            {hash ?
                <>
                    <CopyText text={url}/>
                    <Feedback type={FEEDBACK_TYPE_OK}>
                        <p>
                            <Text id={'lenke_kopiert_til_utklippstavlen'}/>
                        </p>
                        <p className='url'>
                            {url}
                        </p>
                    </Feedback>
                </>
                :
                <Feedback type={FEEDBACK_TYPE_FEIL}>
                    <Text id={'mangler_hash'}/>
                </Feedback>
            }
        </div>
    )
};

export default Pameldingslink;
