import React from 'react';
import Checkbox from "../../../primitives/Checkbox";
import Filter from "./Filter";
import {useDispatch, useSelector} from "react-redux";
import {
    getKategoriCounter,
    getKategorier,
    getValgteKategorier,
    toggleKategori
} from "../../../ducks/kursoversikt";

const FilterKategorier = () => {
    const kategorier = useSelector(getKategorier);
    const kategoriCounter = useSelector(getKategoriCounter);
    const valgteKategorier = useSelector(getValgteKategorier);
    const dispatch = useDispatch();
    if(kategorier.length===0)return null;
    return (
        <Filter
            labelTextId={`kategorier`}
        >
            {kategorier.map(({kode, kategorinavn}) =>
                <Checkbox
                    key={kode}
                    label={`${kategorinavn} (${kategoriCounter(kode)})`}
                    onChange={() => dispatch(toggleKategori(kode))}
                    checked={valgteKategorier.includes(kode)}
                />
            )}
        </Filter>
    )
};

export default FilterKategorier;