import React from 'react';
import DokumentRow from './DokumentRow'
import {useSelector} from "react-redux";
import {getDokumenterByType} from "../../ducks/dokumenter";
import './dokumenter.scss';
import withDokumenterLoaded from "../../high-order-components/withDokumenterLoaded";
import {requireDeltakernummer} from "../../high-order-components/requireDeltakernummer";
import {compose} from "redux";
import Feedback from "../Feedback";
import Text from "../../primitives/Text";
import Table from "../../primitives/Table";
import {getDokumentLabelByDokumentType} from "../../services/dokumenterAPI";


const OpplastedeDokumenter = ({id, type}) => {

    const dokumenter = useSelector(state => getDokumenterByType(state, type));

    if (!dokumenter.length) {
        return (
            <Feedback id={id} aria-labelledby={'tab_' + id}>
                <Text id='du_har_ingen_tidligere_opplastet_dokumenter_under_den_kategorien'/>
            </Feedback>
        )
    }

    return (
        <div id={id} className='dokumenter' aria-labelledby={'tab_' + id}>
            {dokumenter.length > 0 &&
            <Table>
                <caption className='vis-for-sr'>
                    <Text id='dokumenter_for' type={getDokumentLabelByDokumentType(dokumenter[0].type)}/>
                </caption>
                <thead>
                    <tr className='document-heading'>
                        <th className='filnavn'><Text id='dokument'/></th>
                        <th className='dato'><Text id='dato_opplastet'/></th>
                        <th className='action vis-for-sr'><Text id='handling'/></th>
                    </tr>
                </thead>
                <tbody>
                    {dokumenter.map(dokument =>
                        <DokumentRow key={dokument.id}
                                     id={dokument.id}
                                     filnavn={dokument.filnavn}
                                     datoOpplastet={dokument.datoOpplastet}
                        />
                    )}
                </tbody>
            </Table>
            }
        </div>
    );
};

export default compose(
    requireDeltakernummer,
    withDokumenterLoaded,
)(OpplastedeDokumenter);

