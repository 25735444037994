import {useState} from "react";
import {useDidUpdateEffect} from "../../hooks/useDidUpdateEffect";

const useSavedAnimation = isInEditMode => {
    const [runSavedAnimation, setRunSavedAnimation] = useState();

    useDidUpdateEffect(() => {
        if (!isInEditMode) {
            setRunSavedAnimation(true);
            setTimeout(() => {
                setRunSavedAnimation(false)
            }, 1000)
        }
    }, [isInEditMode]);

    return runSavedAnimation;
};

export default useSavedAnimation;
