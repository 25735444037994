import Input from "../../../primitives/Input";
import React from "react";
import PropTypes from "prop-types";
import {MAX_LENGDE_EPOST} from "../../../utils/common";
import InPlaceError from "../../InPlaceError";

export const InputEpost = ({epost, onChange, errorMessageId, isFocusOn, justIconInLabel, className, labelId, iconPath}) => {

    return (
        <div className={className}>
            <Input
                conditionForFocus={isFocusOn}
                label={labelId || 'e_post'}
                iconPathForLabel={iconPath}
                justIconInLabel={justIconInLabel}
                type='email'
                value={epost}
                hasError={errorMessageId}
                onChange={e => onChange(e.target.value)}
                autoComplete='email'
                maxLength={MAX_LENGDE_EPOST}
            />
            <InPlaceError errorMessageId={errorMessageId}/>
        </div>
    )
};

InputEpost.propTypes = {
    epost: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    errorMessageId: PropTypes.string,
    autoFocus: PropTypes.bool,
    justIconInLabel: PropTypes.bool,
    className: PropTypes.string,
    labelId: PropTypes.string,
    iconPath: PropTypes.string,
};

export default InputEpost;