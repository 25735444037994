import queryString from "query-string";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {attemptLoginSomBruker, getDeltakernrUnderSaksbehandling, isSaksbehandler,} from "../ducks/session";
import {LOGIN_SOM_TYPE_DELTAKER, setBrukenrSaksbehandling, setLoginSomType} from "../ducks/menu";
import {useLocation} from "react-router";

const useDeltakernummerFromLocation = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const params = queryString.parse(location.search);
    const deltakernummer = parseInt(params.deltakernummer);
    const aktivtDeltakernummer = useSelector(getDeltakernrUnderSaksbehandling);
    const saksbehandler = useSelector(isSaksbehandler);
    useEffect(() => {
        if (saksbehandler && deltakernummer && deltakernummer !== aktivtDeltakernummer) {
            dispatch(setLoginSomType(LOGIN_SOM_TYPE_DELTAKER));
            dispatch(setBrukenrSaksbehandling(deltakernummer));
            dispatch(attemptLoginSomBruker());
        }
    }, [deltakernummer, aktivtDeltakernummer, saksbehandler, dispatch]);
};


export default useDeltakernummerFromLocation;