import {useEffect} from "react";
import {useDidMount} from "./useDidMount";

export const useDidUpdateEffect = (effect, deps) => {
    const didMount = useDidMount();
    useEffect(() => {
            didMount && effect()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        deps
    );
};