import React from "react";
import {connect} from 'react-redux';
import {getOriginalData} from "../../../ducks/profil";
import {getLandByNummer} from "../../../ducks/land";
import withLandLoaded from "../../../high-order-components/withLandLoaded";
import PropTypes from "prop-types";
import {NORGE_KODE, TYPE_ADRESSE_ARBEID, TYPE_ADRESSE_HJEM} from "../../../utils/common";
import withProfildataLoaded from "../../../high-order-components/withProfildataLoaded";
import {formatDate} from "../../../ducks/i18n";
import {emailIconPath, personIconPath, telefonIconPaths} from "../../../utils/icons";
import {formatTelefonnummerMedLandskode, TYPE_TELEFON_ARBEID, TYPE_TELEFON_MOBIL} from "../../../utils/telefonUtil";
import Icon from "../../../primitives/Icon";
import {AdresseIcon} from "../../adresse/AdresseIcon";

const isValidTelefon = telefon => {
    return telefon && telefon.nummer
};

const isValidAdresse = adresse => {
    return adresse && (adresse.linje1 || adresse.linje2);
};

class StegOversiktProfil extends React.Component {
    render(){
        const {profildata, getLandByNummer, formatDate} = this.props;
        return (
            <div className='steg-oversikt__steg__profil'>
                <div className='steg-oversikt__steg__profil__personalia'>
                    <Icon path={personIconPath} title='person'/>
                    {profildata.fornavn} {profildata.etternavn} ({profildata.fodselsdato ? formatDate(new Date(profildata.fodselsdato)):'-'})
                </div>
                {profildata.epostPrivat &&
                    <div className='steg-oversikt__steg__profil__epost'>
                        <Icon path={emailIconPath} title='epost'/>
                        {profildata.epostPrivat}
                    </div>
                }
                {isValidTelefon(profildata.mobiltelefon) &&
                    <StegOversiktProfilTelefon
                        type={TYPE_TELEFON_MOBIL}
                        {...profildata.mobiltelefon}
                    />
                }
                {isValidTelefon(profildata.arbeidstelefon) &&
                    <StegOversiktProfilTelefon
                        type={TYPE_TELEFON_ARBEID}
                        {...profildata.arbeidstelefon}
                    />
                }
                <div className='steg-oversikt__steg__profil__adresser'>
                    {isValidAdresse(profildata.hjemstedsadresse) &&
                        <StegOversiktProfilAdresse
                            type={TYPE_ADRESSE_HJEM}
                            getLandByNummer={getLandByNummer}
                            {...profildata.hjemstedsadresse}
                        />
                    }
                    {isValidAdresse(profildata.arbeidsadresse) &&
                        <StegOversiktProfilAdresse
                            type={TYPE_ADRESSE_ARBEID}
                            getLandByNummer={getLandByNummer}
                            {...profildata.arbeidsadresse}
                        />
                    }
                </div>
            </div>
            )
    }
}

const StegOversiktProfilAdresse = ({linje1, linje2, postadresse, land, getLandByNummer, type}) => {
    return (
        <div className='steg-oversikt__steg__profil__adresser__adresse'>
            <div className='steg-oversikt__steg__profil__adresser__adresse__icon'>
                <AdresseIcon type={type}/>
            </div>
            <div className='steg-oversikt__steg__profil__adresser__adresse__body'>
                {linje1 && <div>{linje1}</div>}
                {linje2 && <div>{linje2}</div>}
                {postadresse && <div>{postadresse}</div>}
                {land!==undefined && <div>{getLandByNummer(land)}</div>}
            </div>
        </div>
    )
};


StegOversiktProfilAdresse.propTypes = {
      linje1: PropTypes.string
    , linje2: PropTypes.string
    , postadresse: PropTypes.string
    , land: PropTypes.number
    , type: PropTypes.string.isRequired
};

const StegOversiktProfilTelefon = ({landskode=NORGE_KODE, nummer, type}) => {
    return (
        <div className='steg-oversikt__steg__profil__telefon'>
            <Icon path={telefonIconPaths[type]} title={type}/>
            {formatTelefonnummerMedLandskode(landskode, nummer)}
        </div>
    )
};

StegOversiktProfilTelefon.propTypes = {
      landskode: PropTypes.string
    , nummer: PropTypes.string.isRequired
    , type: PropTypes.string.isRequired
};


const mapStateToProps = state => {
    return {
        profildata: getOriginalData(state)
        , getLandByNummer: landnummer => getLandByNummer(state, landnummer)
        , formatDate: (date) => formatDate(state, date)
    }
};


StegOversiktProfil.propTypes = {
    profildata: PropTypes.shape({
          fornavn: PropTypes.string.isRequired
        , etternavn: PropTypes.string.isRequired
        , fodselsdato: PropTypes.string
        , epostPrivat: PropTypes.string
        , mobiltelefon: PropTypes.object.isRequired
        , arbeidstelefon: PropTypes.object.isRequired
        , hjemstedsadresse: PropTypes.object.isRequired
        , arbeidsadresse: PropTypes.object.isRequired
    }).isRequired,
    getLandByNummer: PropTypes.func.isRequired,
    formatDate: PropTypes.func.isRequired,
};

export default withProfildataLoaded(
    withLandLoaded(
        connect(mapStateToProps)(StegOversiktProfil)
    )
);