import React, {useState} from "react";
import {useDispatch} from "react-redux";
import Button, {TERTIARY} from "../../../primitives/Button";
import Text from "../../../primitives/Text";
import ArbeidserfaringerListe from "./ArbeidserfaringerTidlegereBrukt";
import {toggleFlereArbeidserfaringSoknadstilknytninger} from "../../../ducks/arbeidserfaring";

const TidligereBruktBox = ({close}) => {

    const [selected, setSelected] = useState([]);
    const dispatch = useDispatch();

    const onVelg = () => {
        dispatch(toggleFlereArbeidserfaringSoknadstilknytninger(selected));
        close();
    };

    return (
        <div className='tidligere-opplastet-boks'>
            <h4><Text id='tidligere_brukt'/></h4>
            <ArbeidserfaringerListe selected={selected} setSelected={setSelected}/>
            <div className='tidligere-opplastet-boks__buttons'>
                <Button onClick={close} emphasis={TERTIARY}>
                    <Text id='avbryt'/>
                </Button>
                <Button onClick={onVelg}>
                    <Text id='velg'/>
                </Button>
            </div>
        </div>
    )
};


export default TidligereBruktBox;
