import React from 'react';
import PropTypes from 'prop-types'
import LeggTilNyVerdi from "./LeggTilNyVerdi";
import ReadOnlyField from "./ReadOnlyField";
import {endreIconPath} from "../utils/icons";
import Icon from "../primitives/Icon";

const EditableField = ({value, valueNameId, iconPath, justIconInHeader, editDisabled, children: endreButton}) => {
    return (
        value ?
            <div className={`editable-field ${(editDisabled || endreButton) && 'editable-field--disabled'}`}>
                <ReadOnlyField title={justIconInHeader ? undefined : valueNameId}
                               value={value}
                               iconPath={iconPath}
                               altText={valueNameId}
                />
                {endreButton ?
                    endreButton
                    :
                    !editDisabled && <Icon path={endreIconPath} className='editable-indikator'/>
                }
            </div>
            :
            <LeggTilNyVerdi valueName={valueNameId}/>
    )
};

EditableField.propTypes = {
    value: PropTypes.any,
    valueNameId: PropTypes.string.isRequired,
    iconPath: PropTypes.string,
    justIconInHeader: PropTypes.bool,
    children: PropTypes.node,
    editDisabled: PropTypes.bool,
};


export default EditableField;
