export const NAME = 'popup';
const SHOW_POPUP = `${NAME}/SHOW_POPUP`;
const CLOSE_POPUP = `${NAME}/CLOSE_POPUP`;

export function showPopup(content) {
    return {
        type: SHOW_POPUP,
        content: content,
    };
}

export function closePopup() {
    return {
        type: CLOSE_POPUP,
    };
}

const initialState = {
    show: false,
    content: {}
};


function popup(state = initialState, action) {
    switch (action.type) {
        case SHOW_POPUP:
            return {
                ...state,
                show: true,
                content: action.content
            };
        case CLOSE_POPUP:
            return {
                ...state,
                show: false,
                content: {}
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME];
}

export function getPopupContent(state) {
    return getState(state).content;
}

export function getShowPopup(state) {
    return getState(state).show;
}


export default popup