import React from 'react';
import PropTypes from 'prop-types'
import Button from "../../primitives/Button";
import {kursIdToString, pages} from "../../utils/common";
import Text from "../../primitives/Text";
import PameldingButton from "./PameldingButton";
import {mdiEye} from '@mdi/js';
import {useNavigate} from "react-router";


const KursPreviewActions = ({id, pameldingsfrist, harDetaljer, direktePamelding, disablePamelding=false, pameldingButtonOverrideTextId=undefined}) => {
    const navigate = useNavigate();
    const goToDetaljer = () => navigate(`${pages.kurs}/${kursIdToString(id)}`);

    return (
        <div className="kursliste__kurs__preview__actions">
            {harDetaljer &&
            <Button
                onClick={goToDetaljer}
                iconPath={mdiEye}
            >
                <Text id={'detaljer'}/>
            </Button>
            }
            <PameldingButton
                id={id}
                pameldingsfrist={pameldingsfrist}
                direktePamelding={direktePamelding}
                disablePamelding={disablePamelding}
                overrideTextId={pameldingButtonOverrideTextId}
            />
        </div>
    );
};

KursPreviewActions.propTypes = {
    id: PropTypes.shape({
        kurskode: PropTypes.string.isRequired,
        tidskode: PropTypes.string.isRequired
    }).isRequired,
    pameldingsfrist: PropTypes.object,
    harDetaljer: PropTypes.bool.isRequired,
    direktePamelding: PropTypes.bool.isRequired,
    disablePamelding: PropTypes.bool,
    pameldingButtonTextOverride: PropTypes.string,
};

export default KursPreviewActions;