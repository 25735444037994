import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getFinnesBesvarteSoknader,
    getKursFromId, getKursHarGodkjenning,
    toggleSkjulBesvarteSoknader
} from "../../../ducks/foretaksgodkjenning";
import Button, {TERTIARY} from "../../../primitives/Button";
import Text from "../../../primitives/Text";
import {menuDownIconPath} from "../../../utils/icons";

const UbesvartToggler = ({kursId, foretakkode}) => {
    const dispatch = useDispatch();
    const finnesBesvarteSoknader = useSelector(state => getFinnesBesvarteSoknader(state, kursId, foretakkode));
    const {skjulBesvarteSoknader} = useSelector(state => getKursFromId(state, kursId, foretakkode));
    const harGodkjenning = useSelector(state => getKursHarGodkjenning(state, kursId, foretakkode));
    return finnesBesvarteSoknader && harGodkjenning &&
        <div className={`besvarte-soknader-toggler ${skjulBesvarteSoknader? 'collapsed' : 'extended'}`}>
            <Button
                onClick={() => dispatch(toggleSkjulBesvarteSoknader(kursId))}
                emphasis={TERTIARY}
                iconPath={menuDownIconPath}
            >
                <Text id={skjulBesvarteSoknader ? 'vis_besvarte_søknader' : 'skjul_besvarte_søknader'}/>
            </Button>
        </div>
};

export default UbesvartToggler;