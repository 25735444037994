import React from 'react';
import {useSelector} from "react-redux";
import {
    getSelectedForetaksKursOversatt
} from "../../../ducks/foretaksgodkjenning";
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../../Feedback";
import Text from "../../../primitives/Text";
import Kurs from "./Kurs";

const Kursliste = () => {
    const kursListe = useSelector(getSelectedForetaksKursOversatt);
    return kursListe.length === 0 ?
        <Feedback type={FEEDBACK_TYPE_VARSEL}>
            <Text id={'ingen_kurs_registrert_for_dette_foretaket'}/>
        </Feedback>
        :
        kursListe.map(kurs => <Kurs key={kurs.kursId} {...kurs}/>);
};




export default Kursliste;