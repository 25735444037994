import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getEditArbeidsgiver,
    getEditIsSaving,
    getEditWasSaveClicked,
    setEditFieldValue
} from "../../../ducks/arbeidserfaring";
import Input from "../../../primitives/Input";
import {ARBEIDSGIVER_MAX_LENGDE} from "../../../utils/arbeidserfaringUtil";
import Error from "../../Error";
import Text from "../../../primitives/Text";

const InputArbeidsgiver = () => {
    const dispatch = useDispatch();
    const arbeidsgiver = useSelector(getEditArbeidsgiver);
    const isSaving = useSelector(getEditIsSaving);
    const saveWasClicked = useSelector(getEditWasSaveClicked);
    const hasError = saveWasClicked && !arbeidsgiver;
    const update = e => dispatch(setEditFieldValue({
        field: 'arbeidsgiver',
        value: e.target.value,
    }));
    return (
        <>
            <Input
                label={'arbeidsgiver'}
                onChange={update}
                value={arbeidsgiver}
                disabled={isSaving}
                maxLength={ARBEIDSGIVER_MAX_LENGDE}
                hasError={hasError}
            />
            {hasError &&
            <Error>
                <Text id={'vennligst_legg_inn_arbeidsgiver'}/>
            </Error>
            }
        </>
    )
};

export default InputArbeidsgiver;