import React from "react";
import PropTypes from "prop-types";
import ButtonAsLink from "../primitives/ButtonAsLink";
import {mdiMenuDown} from '@mdi/js';
import Text from "../primitives/Text";


export class ShowMoreButton extends React.Component {

    render() {
        const {onClick, extended,
            showMoreText=<Text id='vis_mer'/>,
            showLessText=<Text id='vis_mindre'/>,
        } = this.props;

        const classes = `show-more-button ${extended ? 'extended' : ''}`;
        return (
            <ButtonAsLink className={classes} onClick={onClick} iconPath={mdiMenuDown}>
                {extended ? showLessText : showMoreText}
            </ButtonAsLink>
        )
    }


}

ShowMoreButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    extended: PropTypes.bool.isRequired,
    showLessText: PropTypes.node || PropTypes.string,
    showMoreText: PropTypes.node || PropTypes.string,
};
