import React, {useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {uploadIconPath} from "../utils/icons";
import Text from "../primitives/Text";
import {useDispatch} from "react-redux";
import './dropzone.scss'
import Icon from "../primitives/Icon";

const Dropzone = ({dispatchOnDrop}) => {

    const dispatch = useDispatch();
    const onDrop = useCallback(acceptedFiles => {
        dispatch(dispatchOnDrop(acceptedFiles))
    }, [dispatch, dispatchOnDrop]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div {...getRootProps()} className='dropzone'>
            <input {...getInputProps()} />
            <Icon path={uploadIconPath}/>
            <Text className='dropzone__text-desktop'
                id={isDragActive ? 'slipp_fil_her' : 'trekk_inn_fil_for_å_laste_opp_eller_bla'}
                values={{
                    bla_igjennom: <span className='link'><Text id='bla_igjennom'/></span>
                }}
            />
            <Text className='dropzone__text-mobile' id='trykk_for_å_bla'/>
        </div>
    )
};

export default Dropzone;