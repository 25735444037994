import messages_nb from "../translations/nb";
import messages_nn from "../translations/nn";
import messages_en from "../translations/en";
import {getValueFromLocalStorage, I18N_LOCAL_STORAGE_KEY} from "./sessionUtil";

export const MESSAGES = {
    'nb': messages_nb,
    'nn': messages_nn,
    'en': messages_en
};

export const LOCALES_LIST = ['nb', 'nn', 'en'];
const DEFAULT_LOCALE = 'nb';

export function getInitialLocale() {

    const localeFromLocalStorage = getValueFromLocalStorage(I18N_LOCAL_STORAGE_KEY);
    if(localeFromLocalStorage){
        return localeFromLocalStorage;
    }

    if (typeof navigator === 'undefined') {
        return DEFAULT_LOCALE;
    } else {
        const browserLocale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;
        const baseBrowserLocale = browserLocale.substring(0, 2);
        return LOCALES_LIST.indexOf(baseBrowserLocale) >= 0 ? baseBrowserLocale : DEFAULT_LOCALE;
    }
}

export const getLocalLabel = locale => MESSAGES[locale][`language.${locale}`];

export function setHtmlLang(locale){
    document.documentElement.lang = locale;
}