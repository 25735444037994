import {NORGE_KODE} from "./common";

export const TYPE_TELEFON_MOBIL = 'mobiltelefon';
export const TYPE_TELEFON_ARBEID = 'arbeidstelefon';

export function formatTelefonnummerMedLandskode(landskode=NORGE_KODE, nummer) {
    if (!nummer) {
        return ''
    }
    return `+${landskode} ${formatTelefonNummer(nummer)}`;
}

export function formatTelefonNummer(nummer) {
    const firstDigit = nummer.substring(0, 1);
    if (nummer.length === 8) {
        if (["4", "9"].includes(firstDigit)) {
            return `${nummer.substring(0, 3)} ${nummer.substring(3, 5)} ${nummer.substring(5, 8)}`; // xxx xx xxx
        }
        return nummer.replace(/(\d{2})/g, '$1 ').replace(/(^\s+|\s+$)/, ''); // xx xx xx xx
    }
    return nummer;
}