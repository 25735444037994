import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {injectIntl} from 'react-intl';
import Text from "../primitives/Text";
import { mdiPlus } from '@mdi/js';
import Icon from "../primitives/Icon";


export class LeggTilNyVerdi extends Component {
    render() {
        const {valueName} = this.props;
        const {formatMessage} = this.props.intl;

        return (
            <div className='legg-til-ny-verdi'>
                <Icon path={mdiPlus}/> <Text id='legg_til'/> {formatMessage({id: valueName}).toLowerCase()}
            </div>
        )
    }
}

LeggTilNyVerdi.propTypes = {
    valueName: PropTypes.string.isRequired,
};


export default injectIntl(LeggTilNyVerdi);