import FeedbackWSError from "../FeedbackWSError";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getEndreSvarfristById,
    getKursnavnById,
    getSoknadActionError,
    getStatusById,
    getSvarfristById, getSvarPaTilbudOriginalById, svarePaTilbud
} from "../../ducks/soknader";
import {getTextIdFromStatus} from "../../utils/soknadStatus";
import Text from "../../primitives/Text";
import './svar-pa-tilbud.scss';
import FormattedDate from "../../primitives/FormattedDate";
import {RadioButton} from "../../primitives/RadioButton";

const SvarPaTilbud = ({kursId}) => {

    const soknadActionError = useSelector(getSoknadActionError);
    const kursnavn = useSelector(state => getKursnavnById(state, kursId));
    const status = useSelector(state => getStatusById(state, kursId));
    const svarfrist = useSelector(state => getSvarfristById(state, kursId));
    const endreSvarfrist = useSelector(state => getEndreSvarfristById(state, kursId));
    const svarPaTilbudOriginal = useSelector(state => getSvarPaTilbudOriginalById(state, kursId));
    const dispatch = useDispatch();
    const [svarPaTilbud, setSvarPaTilbud] = useState(svarPaTilbudOriginal);

    const svarOnChange = (value) => {
        setSvarPaTilbud(value);
        dispatch(svarePaTilbud(value));
    };

    return (
        <div className='svar-pa-tilbud'>
            <div className='svar-pa-tilbud__kursnavn'>
                {kursnavn}
            </div>

            <div className='svar-pa-tilbud__status'>
                <Text id='status' className='svar-pa-tilbud__label'/>:
                <Text id={getTextIdFromStatus(status)} className='svar-pa-tilbud__value'/>
            </div>

            <div className='svar-pa-tilbud__frist'>
                <Text id='svarfrist' className='svar-pa-tilbud__label'/>:
                <FormattedDate date={svarfrist || endreSvarfrist} withTime className='svar-pa-tilbud__value'/>
            </div>


            <fieldset>
                <legend className='svar-pa-tilbud__label'>
                    <Text id='ditt_svar' />:
                </legend>
                <RadioButton label={'ja'}
                             onChange={() => svarOnChange(true)}
                             checked={svarPaTilbud===true}/>
                <RadioButton label={'nei'}
                             onChange={() => svarOnChange(false)}
                             checked={svarPaTilbud===false}/>
            </fieldset>

            <FeedbackWSError error={soknadActionError}
                             overrideDefaultId='noe_feilet_ved_innsending'
            />
        </div>

    )
};

export default SvarPaTilbud;