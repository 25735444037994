import {useState} from "react";


const useInlineEditInputsFocusControl = (onFocusOut, numberOfInputs) => {

    const [focusIndex, setFocusIndex] = useState(0);

    const moveForward = (current) => {
        if (current >= numberOfInputs - 1) {
            onFocusOut();
            return;
        }
        setFocusIndex(current + 1);
    };

    const moveBack = (current) => {
        if (current <= 0) {
            onFocusOut();
            return;
        }
        setFocusIndex(current - 1);
    };


    return [
        focusIndex,
        setFocusIndex,
        moveForward,
        moveBack,
    ]

};

export default useInlineEditInputsFocusControl;