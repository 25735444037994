import {createSlice} from "@reduxjs/toolkit";

export const NAME = 'aktivInlineEdit';
export const initialState = {
    formId: undefined,
};

const aktivInlineEditSlice = createSlice({
    name: NAME,
    initialState: initialState,
    reducers: {
        setAktivInlineEditFormId(state, {payload: formId}) {
            state.formId = formId;
        },
    }
});

export const {
    setAktivInlineEditFormId,
} = aktivInlineEditSlice.actions;


export const getState = state => state[NAME];

export const getAktivInlineEditFormId = state => getState(state).formId;

export default aktivInlineEditSlice.reducer