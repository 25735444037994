const DELIMITER = '.';

export const formatFilename = fileName => {

    const nameParts = fileName.split(DELIMITER);

    const name = nameParts.slice(0, nameParts.length - 1).join(DELIMITER);
    const extension = `(${nameParts.slice(-1)[0]})`.toUpperCase();

    return `${name} ${extension}`;

};