import {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {isLoggedIn} from '../ducks/session'

export class ShowIfLoggedIn extends Component {

    render() {
        const { children, isLoggedIn } = this.props;

        if (!isLoggedIn) {
            return null;
        }

        return children;
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
    }
};

ShowIfLoggedIn.propTypes = {
    children: PropTypes.node.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ShowIfLoggedIn)

