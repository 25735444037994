import React from 'react';
import PropTypes from "prop-types";
import Button, {SECONDARY} from "../../primitives/Button";
import Text from "../../primitives/Text";
import {fristHarUtgatt, kursIdToString, pages} from "../../utils/common";
import {useSelector} from "react-redux";
import {getInstitusjonsAkronym} from "../../ducks/session";
import {finnesSoknadForKurs, getSoknadKanEndresInnenforFristen} from "../../ducks/soknader";
import {mdiEye, mdiPencilPlus} from '@mdi/js';
import {endreIconPath} from "../../utils/icons";
import {useNavigate} from "react-router";


const PameldingButton = props => {

    const {id, pameldingsfrist, direktePamelding} = props;
    const fristUtgatt = fristHarUtgatt(pameldingsfrist);

    const finnesSoknad = useSelector(state => finnesSoknadForKurs(state, id));
    const soknadKanEndresInnenforFristen = useSelector(state => getSoknadKanEndresInnenforFristen(state, id));
    const instAkronym = useSelector(getInstitusjonsAkronym);

    const navigate = useNavigate();
    const onClick = () => navigate(`${pages.pamelding}/${kursIdToString(id)}?inst=${instAkronym}`);


    if (finnesSoknad) {
        if (fristUtgatt || !soknadKanEndresInnenforFristen) {
            return <SeSoknadButton onClick={onClick} direktePamelding={direktePamelding}/>
        }
        return <EndreSoknadButton onClick={onClick} direktePamelding={direktePamelding}/>
    }

    return <OpprettSoknadButton onClick={onClick} fristUtgatt={fristUtgatt} {...props} />;
};

PameldingButton.propTypes = {
    id: PropTypes.shape({
        kurskode: PropTypes.string.isRequired,
        tidskode: PropTypes.string.isRequired
    }).isRequired,
    pameldingsfrist: PropTypes.object,
};

export const OpprettSoknadButton = ({
                                        onClick,
                                        fristUtgatt,
                                        direktePamelding,
                                        overrideTextId,
                                        disablePamelding = false,
                                        emphasis = SECONDARY
                                    }) => {
    return (
        <Button
            onClick={onClick}
            iconPath={mdiPencilPlus}
            disabled={fristUtgatt || disablePamelding}
            emphasis={emphasis}
            className={'pamelding-button'}
        >
            <Text id={overrideTextId || (direktePamelding ? 'meld_deg_på' : 'registrer_søknad')}/>
        </Button>
    )
};

OpprettSoknadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    fristUtgatt: PropTypes.bool.isRequired,
    direktePamelding: PropTypes.bool.isRequired,
    overrideTextId: PropTypes.string,
    disablePamelding: PropTypes.bool,
    emphasis: PropTypes.string,
};

export const EndreSoknadButton = ({onClick, direktePamelding}) => {
    return (
        <Button
            onClick={onClick}
            iconPath={endreIconPath}
            emphasis={SECONDARY}
            className={'endre-soknad-button'}
        >
            <Text id={direktePamelding ? 'endre_påmelding' : 'endre_soknad'}/>
        </Button>
    )
};

export const SeSoknadButton = ({onClick, direktePamelding}) => {
    return (
        <Button
            onClick={onClick}
            iconPath={mdiEye}
            emphasis={SECONDARY}
            className={'endre-soknad-button'}
        >
            <Text id={direktePamelding ? 'se_påmelding' : 'se_soknad'}/>
        </Button>
    )
};

EndreSoknadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

SeSoknadButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};


export default PameldingButton;