import React from 'react'
import {injectIntl} from 'react-intl';
import {useSelector} from "react-redux";
import {getFulltNavn, getIsNavnFraDsf, getKanEndreFodselsdato} from "../../../ducks/profil";
import Fodselsdato from "./Fodselsdato";
import EditableField from "../../EditableField";
import DSFHelpButton, {TYPE_DSF_FELT_NAVN} from "../dsf/DSFHelpButton";
import Text from "../../../primitives/Text";

export const GrunnleggendeInformasjon = () => {
    const fulltNavn = useSelector(getFulltNavn);
    const kanEndreFodselsdato = useSelector(getKanEndreFodselsdato);
    const navnFraDsf = useSelector(getIsNavnFraDsf);
    return (
        <div className='flat-card'>
            <h2><Text id='grunnleggende_informasjon'/></h2>
            <div className='content'>
                <EditableField value={fulltNavn} valueNameId='navn' editDisabled>
                    {navnFraDsf && <DSFHelpButton typeFelt={TYPE_DSF_FELT_NAVN}/>}
                </EditableField>
                <Fodselsdato editDisabled={!kanEndreFodselsdato}/>
            </div>
        </div>
    )
};

export default injectIntl(GrunnleggendeInformasjon);