import {useSelector} from "react-redux";
import {getRelevanteActions} from "../../ducks/soknader";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import Action from "./SoknadRowAction";
import React from "react";

export const Actions = ({soknadId, kursnavn, status}) => {

    const actions = useSelector(state => getRelevanteActions(state, soknadId));

    return (
        <TransitionGroup>
            {actions.map(action =>
                <CSSTransition key={action.type}
                               timeout={500}
                               classNames='fade-transition'
                >
                    <Action
                        key={action.type}
                        frist={action.frist}
                        type={action.type}
                        kursId={soknadId}
                        kursnavn={kursnavn}
                        status={status}
                    />
                </CSSTransition>
            )}
        </TransitionGroup>
    )

};

export default Actions;