import InngaendeDokumenter from "./InngaendeDokumenter";
import PageTitle from "../PageTitle";
import requireLogin from "../../high-order-components/requireLogin";
import React from "react";
import Text from "../../primitives/Text";

const Meldingsarkiv = () => {
    return <div>
        <PageTitle id='meldingsarkiv'/>
        <h1>
            <Text id='meldingsarkiv'/>
        </h1>
        <InngaendeDokumenter/>
    </div>
};

export default requireLogin(Meldingsarkiv);