import React from "react";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

const Text = ({className, id, values, lang, ...restOfProps}) => (
    <span className={className} lang={lang}>
      <FormattedMessage id={id} values={{...values, ...restOfProps}}/>
    </span>
);


Text.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    values: PropTypes.object,
    lang: PropTypes.string,
};


export default Text;
