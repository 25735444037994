import {useEffect} from "react";

const useMoveFocusToNextElementDocumentation = (ref, isFjernerTilknytning, element, selectFirstchildButton = false) => {

    useEffect(() => {
        const moveFocusToNextElement = () => {
            const buttonContainer = ref.current.parentNode;
            const listItem = buttonContainer.parentNode;
            const nextListItem = listItem.nextSibling;
            const arrIndex = nextListItem && nextListItem.children.length === 3 ? 1 : 0;
            const listItemContainer = listItem.parentNode;
            const listContainer = listItemContainer.parentNode;
            const documentSelector = listContainer.nextSibling;

            if (nextListItem) {
                nextListItem.children[arrIndex].querySelector(element).focus();
            } else {
                selectFirstchildButton ? documentSelector.firstChild.focus() : documentSelector.focus();
            }
        }

        isFjernerTilknytning && moveFocusToNextElement();
    }, [ref, isFjernerTilknytning, element, selectFirstchildButton]);
}

export default useMoveFocusToNextElementDocumentation;