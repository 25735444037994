import React from "react";
import useInlineEdit from "../../../hooks/useInlineEdit";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormFieldObject, changeTelefonkode,
    getErrorLagring, getFormDataTelefon, getFormErrorsTelefon, hasErrorTelefon, isMobilFraKorr,
    lagreTelefon, resetErrorLagringForFelt,
    resetFormdata
} from "../../../ducks/profil";
import InlineEdit from "../../inline-edit/InlineEdit";
import TelefonForm from "./TelefonForm";
import TelefonPresentation from "./TelefonPresentation";
import {TYPE_TELEFON_MOBIL} from "../../../utils/telefonUtil";
import ErrorFormSaving from "../../ErrorFormSaving";


export const Mobiltelefon = () => {
    const telefon = useSelector(state => getFormDataTelefon(state, TYPE_TELEFON_MOBIL));
    const mobilFraKorr = useSelector(isMobilFraKorr);
    const errors = useSelector(state => getFormErrorsTelefon(state, TYPE_TELEFON_MOBIL));
    const errorLagring = useSelector(state => getErrorLagring(state, TYPE_TELEFON_MOBIL));
    const isChangesValid = useSelector(state => !hasErrorTelefon(state, TYPE_TELEFON_MOBIL));

    const dispatch = useDispatch();
    const submitChanges = () => dispatch(lagreTelefon(TYPE_TELEFON_MOBIL));
    const resetChanges = () => dispatch(resetFormdata());
    const onChangeKode = ({landskode, landnummer}) => {
        dispatch(changeTelefonkode(TYPE_TELEFON_MOBIL, landskode, landnummer));
        dispatch(resetErrorLagringForFelt(TYPE_TELEFON_MOBIL));
    };
    const onChangeNummer = value => {
        dispatch(changeFormFieldObject('nummer', value, TYPE_TELEFON_MOBIL));
        dispatch(resetErrorLagringForFelt(TYPE_TELEFON_MOBIL));
    };

    const [isInEditMode, openEditing, closeEditing, attemptedClosed] = useInlineEdit(submitChanges, isChangesValid, errorLagring);

    return (
        <InlineEdit
            resetChanges={resetChanges}
            isInEditMode={isInEditMode}
            openEditing={openEditing}
            closeEditing={closeEditing}
            editDisabled={mobilFraKorr}
        >
            <TelefonForm
                typeTelefon={TYPE_TELEFON_MOBIL}
                onChangeKode={onChangeKode}
                onChangeNummer={onChangeNummer}
                telefon={telefon}
                errors={errors}
                closeEditing={closeEditing}
                attemptedClosed={attemptedClosed}
            />
            <TelefonPresentation
                typeTelefon={TYPE_TELEFON_MOBIL}
                telefon={telefon}
                hentetFraKorr={mobilFraKorr}
            />
            {errorLagring && <ErrorFormSaving error={errorLagring}/>}
        </InlineEdit>
    );
};


export default Mobiltelefon;