import React from "react";
import SamtykkerListe from "./SamtykkerListe";
import PageTitle from "../PageTitle";
import Text from "../../primitives/Text";

const Samtykker = () => {
    return (
        <>
            <PageTitle id='samtykker'/>
            <h1>
                <Text id="samtykker"/>
            </h1>
            <SamtykkerListe/>
        </>
    )
};

export default Samtykker;