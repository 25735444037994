import {useEffect} from 'react';
import {scrollToTopOfPage} from "../utils/common";
import {useLocation} from "react-router";

const ScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
        scrollToTopOfPage();
    }, [location])

    return null;
};

export default ScrollToTop