import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {isEmailValid} from "../../utils/common";
import Input from "../../primitives/Input";
import Error from "../Error";
import {
    changeBetalingsinfoField,
    getErrorLagringForFelt,
    getFakturaepost,
    lagreBetalingsinfo
} from "../../ducks/betalingsinfo";
import ErrorFormSaving from "../ErrorFormSaving";
import Text from "../../primitives/Text";

export const FELT_EPOST = 'epost';

export const InputEpost = () => {
    const epost = useSelector(getFakturaepost);
    const dispatch = useDispatch();
    const [focused, setFocused] = useState(false);
    const errorLagring= useSelector(state => getErrorLagringForFelt(state, FELT_EPOST));
    const hasError = !focused && !isEmailValid(epost);
    const onBlur = () => dispatch(lagreBetalingsinfo(FELT_EPOST));

    return (
        <div className='input-epost bedrift-input'>
            <Input label='e_post_valgfritt'
                   value={epost}
                   hasError={hasError || errorLagring}
                   onChange={e => dispatch(changeBetalingsinfoField('epost', e.target.value))}
                   onBlur={() => {
                       setFocused(false);
                       onBlur();
                   }}
                   onFocus={() => setFocused(true)}
            />
            {hasError &&
            <Error>
                <Text id='du_må_oppgi_en_gyldig_epost'/>
            </Error>}
            {errorLagring &&
            <ErrorFormSaving
                error={errorLagring}
                provIgjen={onBlur}
            />
            }
        </div>
    )

};

export default InputEpost;