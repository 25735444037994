import queryString from "query-string";
import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {setForetakkodeOgHashForNySoknad} from "../ducks/pamelding";
import {useLocation} from "react-router";

const useForetakkodeHashFromLocation = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const params = queryString.parse(location.search);
    const {foretakkode, hash} = params;
    useEffect(() => {
        dispatch(setForetakkodeOgHashForNySoknad(foretakkode, hash));
    }, [dispatch, foretakkode, hash]);
};


export default useForetakkodeHashFromLocation;