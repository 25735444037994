import {useDispatch, useSelector} from "react-redux";
import {endreAktivtSteg, getSisteSteg, tilbakeTilSisteSteg} from "../../../ducks/pamelding";
import Button, {PRIMARY} from "../../../primitives/Button";
import React from "react";

const ButtonTilbakeTilOversikt = () => {
    const {id} = useSelector(getSisteSteg);
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(tilbakeTilSisteSteg(id));
        dispatch(endreAktivtSteg(id));
    };
    return (
        <Button
            emphasis={PRIMARY}
            onClick={onClick}
        >
            Tilbake til Se gjennom
        </Button>
    )
};

export default ButtonTilbakeTilOversikt;
