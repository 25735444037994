import React from "react";
import AkseptanseListe from "../../samtykke/SamtykkerListe";
import {useSelector} from "react-redux";
import {isSamtykkerValid} from "../../../ducks/samtykke";
import Error from "../../Error";
import {getForsoktNeste} from "../../../ducks/pamelding";
import Text from "../../../primitives/Text";
import UbesvarteSamtykkerListe from "../../samtykke/UbesvarteSamtykkerListe";

const StegAkseptanser = () => {
    const valid = useSelector(isSamtykkerValid);
    const forsoktNeste = useSelector(getForsoktNeste);
    return (
        <>
            <AkseptanseListe/>
            {!valid && forsoktNeste &&
            <Error>
                <Text id='samtykker_må_besvares'/>
                <UbesvarteSamtykkerListe/>
            </Error>
            }
        </>
    )
};

export default StegAkseptanser;