import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getSlettetSoknad, resettSlettetSoknad} from "../../ducks/soknader";
import Feedback, {FEEDBACK_TYPE_OK} from "../Feedback";
import Text from "../../primitives/Text";

const SlettetSoknad = () => {

    const slettetSoknad = useSelector(state => getSlettetSoknad(state));
    const dispatch = useDispatch();

    useEffect(() => () => {
        dispatch(resettSlettetSoknad())
    }, [dispatch]);

    if (!slettetSoknad) return null;
    return (
        <Feedback type={FEEDBACK_TYPE_OK}>
            <Text
                id='soknaden_din_ble_slettet'
                values={{slettetSoknad: <strong>{slettetSoknad}</strong>}}
            />
        </Feedback>
    )
};

export default SlettetSoknad;