import {KURSMANGLER} from "./apiRoutes";
import axios from "axios";
import {kurskodeOgTidskodeToString} from "../utils/common";
import {createRequestConfig} from "../utils/request";

export function getKursmangler(evuapiToken, deltakernr) {

    const route = KURSMANGLER
        .replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);

    return axios.get(route, config)
        .then(response => {
            return transformOutput(response.data);
        })
        .catch(error => {
            throw error;
        });
}

function transformOutput(data) {
    return data.map(kursmangel => transformKursmangelFromWS(kursmangel));
}

function transformKursmangelFromWS(kursmangel) {
    return {
        kursId: kurskodeOgTidskodeToString(kursmangel.kurskode, kursmangel.tidskode),
        mangelkode: kursmangel.kravkode,
        mangeltekst: kursmangel.kravnavn,
    };
}