import PageTitle from "../PageTitle";
import React from "react";
import {compose} from "redux";
import requireLogin from "../../high-order-components/requireLogin";
import withFakturaerLoaded from "../../high-order-components/withFakturaerLoaded";
import Utestaende from "./Utestaende";
import Betalte from "./Betalte";
import Tabs from "../Tabs";
import "./fakturaoversikt.scss";
import Text from "../../primitives/Text";


export const Fakturaoversikt = () => {
    return (
        <div className='fakturaoversikt'>
            <PageTitle id='fakturaoversikt'/>
            <h1>
                <Text id="fakturaoversikt"/>
            </h1>
            <Tabs
                labels={['utestaende', 'betalte']}
                contents={[<Utestaende/>, <Betalte/>]}
            />
        </div>
    )
};

export default compose(
    requireLogin,
    withFakturaerLoaded,
)(Fakturaoversikt);