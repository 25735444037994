import axios from 'axios';
import {TOKEN_ISSUER} from './apiRoutes';


export async function getEvuapiTokenFromWs(dataportenToken, institusjonsnr) {
    const route = TOKEN_ISSUER.replace(':inst', institusjonsnr);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + dataportenToken
        }
    };

    const response = await axios.get(route, config);
    return response.data;
}