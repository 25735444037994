import React from 'react';
import InitialLoadWrapperOld from '../wrappers/InitialLoadWrapperOld';
import {
    getError,
    getIsDataLoaded,
    getIsLoading,
    loadSoknadsdokumenter
} from '../ducks/soknadsdokumenter';
import {connect} from 'react-redux';
import Text from "../primitives/Text";


function withSoknadsdokumenter(Component) {

    const mapStateToProps = state => {
        return {
            isDataLoaded: getIsDataLoaded(state),
            isLoading: getIsLoading(state),
            error: getError(state),
        }
    };

    const mapDispatchToProps = {
        populateData: loadSoknadsdokumenter
    };

    return connect(mapStateToProps, mapDispatchToProps)(
        class extends React.Component {
            render() {
                const {populateData, isDataLoaded, isLoading, error} = this.props;

                return (
                    <InitialLoadWrapperOld
                        populateData={populateData}
                        isDataLoaded={isDataLoaded}
                        isLoading={isLoading}
                        error={error}
                        loadMessage={<Text id='laster_inn_tilknyttede_dokumenter' />}
                    >
                        <Component {...this.props} />
                    </InitialLoadWrapperOld>
                )
            }
        }
    )
}


export default withSoknadsdokumenter;