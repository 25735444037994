import React from "react";
import TilknyttedeDokumenter from "./dokumenter-elementer/TilknyttedeDokumenter";
import Dropzone from "../Dropzone";
import {uploadDokumenter} from "../../ducks/dokumenter";
import VelgFraTidligereOpplastetFiler from "./dokumenter-elementer/VelgFraTidligereOpplastetFiler";
import {HOYERE_UTDANNING__DOKUMENTTYPE} from "../../services/dokumenterAPI";
import {ExternalLink} from "../../primitives/Link";
import Text from "../../primitives/Text";
import {injectIntl} from "react-intl";


const HoyereUtdanning = () => {
    return (
        <div className='dokumentasjon-kategori'>
            <h4><Text id='høyere_utdanning'/></h4>
            <div className='extra-info'>
                <ResultatutvekslingLink/>
            </div>
            <TilknyttedeDokumenter type={HOYERE_UTDANNING__DOKUMENTTYPE} withActions/>
            <Dropzone
                dispatchOnDrop={acceptedFiles => uploadDokumenter(acceptedFiles, true, HOYERE_UTDANNING__DOKUMENTTYPE)}/>
            <VelgFraTidligereOpplastetFiler type={HOYERE_UTDANNING__DOKUMENTTYPE}/>
        </div>
    )
};

const ResultatutvekslingLink = injectIntl(({intl}) =>
    <ExternalLink href={intl.formatMessage({id: 'resultatutveksling_url'})}>
        <Text id='se_hvilke_institusjoner_vi_henter_resultater_fra_automatisk'/>
    </ExternalLink>
);



export default HoyereUtdanning;