export const ENV_EVUAPI = process.env.REACT_APP_ENV_EVUAPI;
export const TOKEN_ISSUER = process.env.REACT_APP_TOKEN_ISSUER;
export const OPPRETT_BRUKER = process.env.REACT_APP_OPPRETT_BRUKER;
export const SYNC_DSF = process.env.REACT_APP_SYNC_DSF;

export const APPTEKSTER = ENV_EVUAPI + '/apptekster/appmodul/EVUWEB3/EVUWEB3/?inst=:instnr';
export const KURSLISTE = ENV_EVUAPI + '/kurs?inst=:instnr';
export const KURSINFO = ENV_EVUAPI + '/kurs/:kursId?inst=:instnr';
export const PAMELDING = ENV_EVUAPI + '/deltaker/:deltakernr/pamelding/:kursId/';
export const PAMELDING_STEG_STATUS = ENV_EVUAPI + '/deltaker/:deltakernr/pamelding/:kursId/stegstatus/:stegkode';
export const SPORRESKJEMA = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/sporsmal/';
export const PROFIL = ENV_EVUAPI + '/deltaker/:deltakernr';
export const ADRESSE = ENV_EVUAPI + '/deltaker/:deltakernr/adresser/:type';
export const TELEFONER = ENV_EVUAPI + '/deltaker/:deltakernr/telefoner/:type';
export const DOKUMENTER = ENV_EVUAPI + '/deltaker/:deltakernr/dokumenter/';
export const POSTNUMRE = ENV_EVUAPI + '/postnumre/:postnummer';
export const INSTITUSJONER = ENV_EVUAPI + '/institusjoner';
export const LAND = ENV_EVUAPI + '/land?inst=:instnr';
export const DOKUMENT = ENV_EVUAPI + '/deltaker/:deltakernr/dokumenter/:dokumentid';
export const DOKUMENT_UPLOAD = ENV_EVUAPI + '/deltaker/:deltakernr/dokumenter/';
export const LANDSKODER = ENV_EVUAPI + '/telefonlandnr?inst=:instnr';
export const GSK = ENV_EVUAPI + '/deltaker/:deltakernr/gsk/';
export const SOKNADER = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/';
export const SOKNAD = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId';
export const SOKNADSDOKUMENTER = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/dokumenter/';
export const SOKNADSDOKUMENT = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/dokumenter/:dokumentid';
export const PLANINFO_BEKREFTELSE = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/planinfobekreftelser/';
export const KURSMANGLER = ENV_EVUAPI + '/deltaker/:deltakernr/kursmangler/';
export const APPEGENSKAPER = ENV_EVUAPI + '/appmoduler/EVUWEB3/EVUWEB3/egenskaper/?inst=:instnr';
export const SAMTYKKER = ENV_EVUAPI + '/deltaker/:deltakernr/akseptanser/';
export const TIDLIGERE_KURS = ENV_EVUAPI + '/deltaker/:deltakernr/kursprotokoll/';
export const AKTIVE_KURS = ENV_EVUAPI + '/deltaker/:deltakernr/aktivekurs/';
export const SYNC_KORR = ENV_EVUAPI + '/deltaker/:deltakernr/synkkorr/';
export const PERSON = ENV_EVUAPI + '/personer/:personlopenr/';
export const FORETAK = ENV_EVUAPI + '/personer/:personlopenr/foretak/';
export const FORETAK_GODKJENNING = ENV_EVUAPI + '/personer/:personlopenr/foretak/:foretakkode/kurs/:kursid';
export const FAKTURAER = ENV_EVUAPI + '/deltaker/:deltakernr/fakturaer/';
export const FAKTURA = ENV_EVUAPI + '/deltaker/:deltakernr/fakturaer/:fakturanr';
export const ARBEIDSERFARINGER = ENV_EVUAPI + '/deltaker/:deltakernr/arbeidserfaringer/';
export const ARBEIDSERFARING = ENV_EVUAPI + '/deltaker/:deltakernr/arbeidserfaringer/:id';
export const ARBEIDSERFARING_DOKUMENT = ENV_EVUAPI + '/deltaker/:deltakernr/arbeidserfaringer/:id/dokument';
export const ARBEIDSERFARING_SOKNASTILKNYTNING = ENV_EVUAPI + '/deltaker/:deltakernr/arbeidserfaringer/:id/soknadstilknytning/:kursId';
export const ORGANISASJON = ENV_EVUAPI + '/foretak/:organisasjonsnummer';
export const FAKTURAINFO = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/fakturainfo/';
export const EKSAMEN = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/eksamen';
export const FULLFOR_SOKNAD = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/fullfor';
export const SVAR_PA_TILBUD = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/svar';
export const TREKK_SOKNAD = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/trekk';
export const ANGRE_TREKK_SOKNAD = ENV_EVUAPI + '/deltaker/:deltakernr/soknader/:kursId/angreTrekk';
export const API_INFO = ENV_EVUAPI + '/info';
export const RESULTATUTVEKSLING = ENV_EVUAPI + '/deltaker/:deltakernr/resultatutveksling/';

export const FSWS = 'https://fsws.usit.no';
export const LOGOER = FSWS + '/fellesws/logo?inst=:instnr&malform=:malform&reserve=J';

