import * as routes from "./apiRoutes";
import axios from "axios";
import {createKursId} from "../ducks/kursoversikt";
import {createAktivtKurs} from "../ducks/aktiveKurs";
import {parseIsoDate} from "../utils/common";
import {createRequestConfig} from "../utils/request";


export function fetchAktiveKurs(deltakernr, evuapiToken) {

    const route = routes.AKTIVE_KURS.replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);

    return axios.get(route, config)
        .then(response => {
            return transformOutput(response.data);
        })
        .catch(error => {
            throw error;
        });
}


function transformOutput(data){
    return data.map(kurs => createAktivtKurs({
        kursnavn: kurs.kursnavn,
        kursId: createKursId(kurs.kurskode, kurs.tidskode),
        datoFra: parseIsoDate(kurs.dato_fra),
        datoTil: parseIsoDate(kurs.dato_til),
        info: kurs.tilbudstekst,
    }));
}