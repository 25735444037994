import React from 'react';
import './min-side.scss';
import PageTitle from "../PageTitle";
import VarselDeltakerDefault from "./varsler/VarselDeltakerDefault";
import VarselSoknaderVenterPaSvar from "./varsler/VarselSoknaderVenterPaSvar";
import VarselSoknaderVenterPaDokumenter from "./varsler/VarselSoknaderVenterPaDokumenter";
import VarselUferdigeSoknader from "./varsler/VarselUferdigeSoknader";
import VarselAvvisteSoknader from "./varsler/VarselAvvisteSoknader";
import VarselSoknaderTilGodkjenning from "./varsler/VarselSoknaderTilGodkjenning";
import VarselSoknaderManglerFakturainfo from "./varsler/VarselSoknaderManglerFakturainfo";
import {compose} from "redux";
import {requireDeltakernummer} from "../../high-order-components/requireDeltakernummer";
import withSoknaderLoaded from "../../high-order-components/withSoknaderLoaded";
import requireLogin from "../../high-order-components/requireLogin";
import VarselSaksbehandlerDefault from "./varsler/VarselSaksbehandlerDefault";
import Text from "../../primitives/Text";

const MinSide = () => {
    return (
        <div className='min-side'>
            <PageTitle id='min_side'/>
            <h1>
                <Text id="min_side"/>
            </h1>
            <div className='cards-container'>
                <VarselSoknaderTilGodkjenning/>
                <VarslerSomKreverSoknader/>
                <VarselSaksbehandlerDefault/>
            </div>
        </div>
    );
};

const VarslerSomKreverSoknader = compose(
    Component => requireDeltakernummer(Component, false),
    withSoknaderLoaded,
)(() =>
    <>
        <VarselSoknaderVenterPaSvar/>
        <VarselSoknaderVenterPaDokumenter/>
        <VarselSoknaderManglerFakturainfo/>
        <VarselAvvisteSoknader/>
        <VarselUferdigeSoknader/>
        <VarselDeltakerDefault/>
    </>
);

export default requireLogin(MinSide);