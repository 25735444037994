import EditableField from "../../EditableField";
import React from "react";
import InlineEdit from "../../inline-edit/InlineEdit";
import FormFocusControl from "../../inline-edit/FormFocusControl";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormField,
    getErrorLagring, getFormDataFodselsdato,
    lagreProfilFelt, resetErrorLagringForFelt,
    resetFormdata
} from "../../../ducks/profil";
import useInlineEdit from "../../../hooks/useInlineEdit";
import {formatDate} from "../../../ducks/i18n";
import InputFodselsdato from "./InputFodselsdato";
import {getErrorMessageId, isTextValidISODate} from "../../../utils/dateUtil";

const FODSELSDATO_FELT = 'fodselsdato';

export const Fodselsdato = ({editDisabled}) => {

    const fodselsdato = useSelector(getFormDataFodselsdato);
    const isDatoValid = isTextValidISODate(fodselsdato);
    const formattedFodselsdato = useSelector(state => fodselsdato && isDatoValid && formatDate(state, new Date(fodselsdato)));
    const errorLagring = useSelector(state => getErrorLagring(state, FODSELSDATO_FELT));

    const dispatch = useDispatch();
    const submitChanges = () => dispatch(lagreProfilFelt(FODSELSDATO_FELT));
    const resetChanges = () => dispatch(resetFormdata());
    const onChange = value => {
        dispatch(changeFormField(FODSELSDATO_FELT, value));
        dispatch(resetErrorLagringForFelt(FODSELSDATO_FELT));
    };

    const [isInEditMode, openEditing, closeEditing, attemptedClosed] = useInlineEdit(submitChanges, isDatoValid, errorLagring);
    const errorMessageId = getErrorMessageId(fodselsdato, false, errorLagring, attemptedClosed,'fodselsdato_obligatorisk');

    return (
        <InlineEdit
            resetChanges={resetChanges}
            isInEditMode={isInEditMode}
            openEditing={openEditing}
            closeEditing={closeEditing}
            editDisabled={editDisabled}
        >
            <FormFocusControl onFocusOut={closeEditing}>
                <InputFodselsdato
                    value={fodselsdato}
                    onChange={onChange}
                    errorMessageId={errorMessageId}
                />
            </FormFocusControl>
            <EditableField value={formattedFodselsdato}
                           valueNameId='fodselsdato'
                           editDisabled={editDisabled}
            />
        </InlineEdit>
    );
};

export default Fodselsdato;
