import Button from "../../../primitives/Button";
import React, {useState} from "react";
import TidligereBruktBox from "./TidligereBruktBox";
import {useSelector} from "react-redux";
import {getIsInEditMode} from "../../../ducks/arbeidserfaring";
import Text from "../../../primitives/Text";

const VelgFraTidligereBrukt = () => {

    const [showVelgboks, setShowVelgboks] = useState(false);
    const isEditMode = useSelector(getIsInEditMode);

    if(isEditMode){
        return null;
    }

    if (showVelgboks) {
        return <TidligereBruktBox close={() => setShowVelgboks(false)}/>;
    }

    return (
        <Button onClick={() => setShowVelgboks(true)}
            className='velg-fra-tidligere-opplastet-button'>
            <Text id='velg_fra_tidligere_brukt'/> ↓
        </Button>
    )

};

export default VelgFraTidligereBrukt;
