import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {fetchTidligereKurs} from "../services/tidligereKursAPI";
import {getTranslation} from "./i18n";

export const NAME = 'tidligereKurs';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;


export function loadPending() {
    return {
        type: LOAD_PENDING,
    };
}

export function loadSuccess(data) {
    return {
        type: LOAD_SUCCESS,
        data,
    };
}

export function loadFailure(error) {
    return {
        type: LOAD_FAILURE,
        error,
    };
}


export function loadTidligereKurs() {
    return function (dispatch, getState) {

        if (isLoading(getState())) {
            return;
        }
        dispatch(loadPending());

        const deltakernr = getAktueltDeltakernr(getState());
        const evuapiToken = getEvuapiToken(getState());

        return fetchTidligereKurs(deltakernr, evuapiToken).then(
            data => dispatch(loadSuccess(data)),
            error => dispatch(loadFailure(error))
        );
    };
}

export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    data: [],
};

export function createTidligereKurs({kursnavn, kursId, datoFra, datoTil, datoKursbevis, dokumentnr, filnavn, isGodkjent}) {
    return {
        kursnavn,
        kursId,
        datoFra,
        datoTil,
        datoKursbevis,
        dokumentnr,
        filnavn,
        isGodkjent,
    }
}


function tidligereKurs(state = initialState, action) {

    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                isDataLoaded: false,
                error: undefined,
                data: [],
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                data: action.data,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error
            };
        default:
            return state
    }
}

function getState(state) {
    return state[NAME];
}

export function getError(state) {
    return getState(state).error;
}

export function isLoading(state) {
    return getState(state).isLoading;
}

export function isDataLoaded(state) {
    return getState(state).isDataLoaded;
}

export function getTidligereKurs(state) {
    return getState(state).data.map(kurs => (
        {
            ...kurs,
            kursnavn: getTranslation(state, kurs.kursnavn)
        })
    );
}

export default tidligereKurs;