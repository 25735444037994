import {useDispatch} from "react-redux";
import Button from "../../../primitives/Button";
import {toggleCollapseKurs} from "../../../ducks/foretaksgodkjenning";
import KursDetaljer from "./KursDetaljer";
import Soknader from "../soknader/Soknader";
import React from "react";
import KursActions from "./KursActions";
import NyeSoknaderAlert from "./NyeSoknaderAlert";
import Icon from "../../../primitives/Icon";
import {menuDownIconPath} from "../../../utils/icons";

const Kurs = ({kursId, navn, collapsed, hash, foretakkode, makskapasitet}) => {
    const dispatch = useDispatch();
    return (
        <div className={`kurs ${collapsed ? `collapsed` : `extended`}`}>
            <Button
                onClick={() => dispatch(toggleCollapseKurs(kursId))}
                className='toggle-kurs-button'
            >
                <h3>
                    {navn} <NyeSoknaderAlert kursId={kursId} foretakkode={foretakkode}/> <Icon path={menuDownIconPath} title={collapsed ? 'vis_mer' : 'vis_mindre'}/>
                </h3>
            </Button>
            <KursDetaljer
                kursId={kursId}
                foretakkode={foretakkode}
                makskapasitet={makskapasitet}
            />
            {!collapsed &&
            <>
                <KursActions
                    kursId={kursId}
                    foretakkode={foretakkode}
                    hash={hash}
                />
                <Soknader
                    kursId={kursId}
                    foretakkode={foretakkode}
                />
            </>
            }
        </div>
    )
};

export default Kurs;