import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {getKursId as getValgtKursId} from "./kursinfo";
import * as soknadsdokumenterAPI from "../services/soknadsdokumenterAPI";
import {showPopup} from "./popup";
import {createErrorPopupContent} from "../utils/request";
import {getDokumenterByType, getSperreSlettingFraDokumentarkiv, setSperreSletting} from "./dokumenter";
import React from "react";
import FeedbackErrors from "../components/FeedbackErrors";
import ErrorsTilknytninger from "../components/steg-dokumentasjon/dokumenter-elementer/ErrorsTilknytninger";

export const NAME = 'soknadsdokumenter';

const LOAD_SOKNADSDOKUMENTER_PENDING = `${NAME}/LOAD_SOKNADSDOKUMENTER_PENDING`;
const LOAD_SOKNADSDOKUMENTER_SUCCESS = `${NAME}/LOAD_SOKNADSDOKUMENTER_SUCCESS`;
const LOAD_SOKNADSDOKUMENTER_FAILURE = `${NAME}/LOAD_SOKNADSDOKUMENTER_FAILURE`;

const LEGG_TIL_SOKNADSDOKUMENT = `${NAME}/LEGG_TIL_SOKNADSDOKUMENT`; // id
const FJERN_SOKNADSDOKUMENT = `${NAME}/FJERN_SOKNADSDOKUMENT`; // id
const TOGGLE_TILKNYTNING_PENDING = `${NAME}/TOGGLE_TILKNYTNING_PENDING`; // id
const TOGGLE_TILKNYTNING_SUCCESS = `${NAME}/TOGGLE_TILKNYTNING_SUCCESS`; // id
const TOGGLE_TILKNYTNING_FAILURE = `${NAME}/TOGGLE_TILKNYTNING_FAILURE`; // id, error
const RESET_TILKNYTNING_ERRORS = `${NAME}/RESET_TILKNYTNING_ERRORS`;


export function leggTilSoknadsdokument(id, sperreSletting) {
    return {
        type: LEGG_TIL_SOKNADSDOKUMENT,
        id,
        sperreSletting,
    }
}

export function fjernSoknadsdokument(id) {
    return {
        type: FJERN_SOKNADSDOKUMENT,
        id: id,
    }
}

export function toggleTilknytningPending(id) {
    return {
        type: TOGGLE_TILKNYTNING_PENDING,
        id: id,
    }
}

export function toggleTilknytningSuccess(id) {
    return {
        type: TOGGLE_TILKNYTNING_SUCCESS,
        id: id,
    }
}

export function toggleTilknytningFailure(id, error) {
    return {
        type: TOGGLE_TILKNYTNING_FAILURE,
        id,
        error
    }
}

export function resetTilknytningErrors() {
    return {
        type: RESET_TILKNYTNING_ERRORS,
    }
}

export function loadSoknadsdokumenterPending(kursId) {
    return {
        type: LOAD_SOKNADSDOKUMENTER_PENDING,
        kursId: kursId,
    };
}

export function loadSoknadsdokumenterSuccess(idListe) {
    return {
        type: LOAD_SOKNADSDOKUMENTER_SUCCESS,
        data: idListe,
    };
}


export function loadSoknadsdokumenterFailure(error) {
    return {
        type: LOAD_SOKNADSDOKUMENTER_FAILURE,
        error: error
    };
}

export function loadSoknadsdokumenter() {
    return function (dispatch, getState) {

        const state = getState();

        if (getIsLoading(state)) {
            return;
        }

        const evuapiToken = getEvuapiToken(state);
        const deltakernr = getAktueltDeltakernr(state);
        const kursId = getValgtKursId(state);

        dispatch(loadSoknadsdokumenterPending(kursId));

        return soknadsdokumenterAPI.getSoknadsdokumenter(evuapiToken, deltakernr, kursId).then(
            doks => dispatch(loadSoknadsdokumenterSuccess(doks)),
            error => dispatch(loadSoknadsdokumenterFailure(error))
        );
    };
}


export function knyttFlereDokumenterTilSoknad(idListe) {
    return function (dispatch) {
        dispatch(resetTilknytningErrors());
        idListe.forEach(id => {
            dispatch(knyttTilSoknad(id));
        });
    }
}

export function knyttTilSoknad(id) {
    return function (dispatch, getState) {
        dispatch(toggleTilknytningPending(id));

        const state = getState();
        const evuapiToken = getEvuapiToken(state);
        const deltakernr = getAktueltDeltakernr(state);
        const kursId = getValgtKursId(state);
        const isKnyttetTilFlereSoknader = getSperreSlettingFraDokumentarkiv(state, id);

        return soknadsdokumenterAPI.leggDokumentTilSoknad(evuapiToken, deltakernr, kursId, id)
            .then(() => {
                dispatch(leggTilSoknadsdokument(id, isKnyttetTilFlereSoknader));
                dispatch(toggleTilknytningSuccess(id));
            })
            .catch(error => {
                dispatch(toggleTilknytningFailure(id, error));
                dispatch(showPopup(createDokumenterErrorPopupContent()));
            });
    }
}

function createDokumenterErrorPopupContent() {
    return {
        style: {wsError: true},
        children:
            <FeedbackErrors>
                <ErrorsTilknytninger/>
            </FeedbackErrors>,
    };
}

export function fjernTilknytning(id) {
    return function (dispatch, getState) {

        dispatch(toggleTilknytningPending(id));

        const state = getState();
        const evuapiToken = getEvuapiToken(state);
        const deltakernr = getAktueltDeltakernr(state);
        const kursId = getValgtKursId(state);
        const isKnyttetTilFlereSoknader = getIsKnyttetTilFlereSoknader(state, id);

        return soknadsdokumenterAPI.fjernDokumentFraSoknad(evuapiToken, deltakernr, kursId, id)
            .then(() => {
                dispatch(fjernSoknadsdokument(id));
                dispatch(toggleTilknytningSuccess(id));
                if (!isKnyttetTilFlereSoknader) {
                    dispatch(setSperreSletting(id, false));
                }
            })
            .catch(error => {
                dispatch(toggleTilknytningFailure(id, error));
                dispatch(showPopup(createErrorPopupContent(error)));
            });
    }
}


export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    kursId: undefined,
    data: [],
    checksLoading: [],
    tilknytningErrors: [],
};

function soknadsdokumenter(state = initialState, action) {

    switch (action.type) {
        case LOAD_SOKNADSDOKUMENTER_PENDING:
            return {
                ...state,
                isLoading: true,
                kursId: action.kursId,
                error: undefined
            };
        case LOAD_SOKNADSDOKUMENTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                data: [...action.data],
            };
        case LOAD_SOKNADSDOKUMENTER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        case TOGGLE_TILKNYTNING_PENDING:
            return {
                ...state,
                checksLoading: [...state.checksLoading, action.id]
            };
        case TOGGLE_TILKNYTNING_SUCCESS:
            return {
                ...state,
                checksLoading: state.checksLoading.filter(id => id !== action.id)
            };
        case TOGGLE_TILKNYTNING_FAILURE:
            return {
                ...state,
                checksLoading: state.checksLoading.filter(id => id !== action.id),
                tilknytningErrors: [
                    ...state.tilknytningErrors,
                    {
                        id: action.id,
                        error: action.error
                    }
                ]
            };
        case RESET_TILKNYTNING_ERRORS:
            return {
                ...state,
                tilknytningErrors: []
            };
        case LEGG_TIL_SOKNADSDOKUMENT:
            return {
                ...state,
                data: [
                    ...state.data,
                    {
                        id: action.id,
                        sperreSletting: action.sperreSletting
                    }
                ]
            };
        case FJERN_SOKNADSDOKUMENT:
            return {
                ...state,
                data: state.data.filter(soknadsdokument => soknadsdokument.id !== action.id),
            };
        default:
            return state
    }
}

export function getState(state) {
    return state[NAME];
}

export function getKursId(state) {
    return getState(state).kursId;
}

export function getIsLoading(state) {
    return getState(state).isLoading;
}

export function getIsDataLoaded(state) {
    return getState(state).isDataLoaded && getValgtKursId(state) === getKursId(state);
}

export function getError(state) {
    return getState(state).error;
}

export function getSoknadsdokumenterIdListe(state) {
    return getState(state).data.map(dokument => dokument.id);
}

export const getTilknyttedeDokumenterByType = (state, type) => {
    const idListe = getSoknadsdokumenterIdListe(state);
    return getDokumenterByType(state, type).filter(dokument => idListe.includes(dokument.id))
};

export const getLedigeDokumenterByType = (state, type) => {
    const idListe = getSoknadsdokumenterIdListe(state);
    return getDokumenterByType(state, type).filter(dokument => !idListe.includes(dokument.id))
};

export function isSelected(state, id) {
    return getSoknadsdokumenterIdListe(state).includes(id);
}

export function getTilknytningerLoading(state) {
    return getState(state).checksLoading;
}

export function getIsNyTilknyttningLoadingByType(state, type) {
    const idListeAvType = getDokumenterByType(state, type).map(dok => dok.id);
    const IdListeTilknyttet = getSoknadsdokumenterIdListe(state);
    const idListeLoading = getTilknytningerLoading(state);
    return idListeLoading.some(id => idListeAvType.includes(id) && !IdListeTilknyttet.includes(id));
}

export function isTilknytningLoading(state, id) {
    return getTilknytningerLoading(state).includes(id);
}

export function getTilknytningErrors(state) {
    return getState(state).tilknytningErrors;
}

export function getSoknadsdokumenter(state) {
    return getState(state).data;
}

export function getSoknadsdokument(state, id) {
    return getSoknadsdokumenter(state).find(dokument => dokument.id === id);
}

export function getIsKnyttetTilFlereSoknader(state, id) {
    const soknadsdokument = getSoknadsdokument(state, id);
    return soknadsdokument && soknadsdokument.sperreSletting;
}

export default soknadsdokumenter;