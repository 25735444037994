import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getForetaksListeAsSelectOptions,
    getSelectedForetaksKode,
    setSelectedForetakkode
} from "../../ducks/foretaksgodkjenning";
import Select from "../../primitives/Select";
import Text from "../../primitives/Text";

const ForetakSelect = () => {
    const options = useSelector(getForetaksListeAsSelectOptions);
    const selectedValue = useSelector(getSelectedForetaksKode);
    const dispatch = useDispatch();
    if (options.length === 1) {
        return (
            <h2>
                {options[0].label}
            </h2>
        )
    }
    return (
        <div className='foretak-select'>
            <Select
                label={'foretak'}
                value={selectedValue}
                options={options}
                onChange={e => dispatch(setSelectedForetakkode(e.value))}
                placeholder={<Text id='velg_placeholder'/>}
                classNamePrefix='react-select'
                noOptionsMessage={() => <Text id='ingen_treff'/>}
            />
        </div>
    )
};

export default ForetakSelect;