import React from 'react';
import MenubarDropdown from "./MenubarDropdown";
import {getLocale} from '../../ducks/i18n';
import LanguageOption from "../LanguageOption";
import {useSelector} from "react-redux";
import './menubar-language.scss';
import Icon from "../../primitives/Icon";
import { mdiCheck, mdiTranslate  } from '@mdi/js';
import {getLocalLabel, LOCALES_LIST} from "../../utils/i18nUtil";

const MenubarLanguage = () => {

    const locale = useSelector(getLocale);
    const otherLanguages = LOCALES_LIST.filter(localeKey => localeKey !== locale);

    return (
        <div id='change-lang' className='menubar-button'>
            <MenubarDropdown
                ariaLabelTextId='velg_språk'
                toggler={{
                    text: <LanguageLabelEnglish/>,
                    Icon: <Icon path={mdiTranslate}/>,
                    shortcut: locale && locale.toUpperCase(),
                }}
            >
                <ChosenLanguageLabel locale={locale}/>
                {otherLanguages.map((localeKey, index) =>
                    <LanguageOption
                        key={index}
                        locale={localeKey}

                    />
                )}
            </MenubarDropdown>
        </div>
    )
};

const LanguageLabelEnglish = () => <span lang={'en'}>Language</span>;

const ChosenLanguageLabel = ({locale}) =>
    <li className='chosen-language'>
        <Icon path={mdiCheck} title='valgt_språk'/>
        {getLocalLabel(locale)}
    </li>;

export default MenubarLanguage;