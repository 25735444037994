import React from 'react';
import {showPopup} from "../../ducks/popup";
import {
    angreTrekkSoknad,
    resettSoknadAction,
    sendSvarPaTilbud,
    slettUfullfortSoknad,
    trekkSoknad,
} from "../../ducks/soknader";
import {connect} from "react-redux";
import {kursIdToUrlString, pages} from "../../utils/common";
import {
    ACTION_TYPE_ANGRE_TREKK,
    ACTION_TYPE_DOKUMENTASJON,
    ACTION_TYPE_ENDRE,
    ACTION_TYPE_ENDRE_FAKTURAINFO,
    ACTION_TYPE_ENDRE_SVAR,
    ACTION_TYPE_SE,
    ACTION_TYPE_SLETTE,
    ACTION_TYPE_SVARE,
    ACTION_TYPE_TREKKE,
    ACTION_TYPE_TREKKE_FRA_VENTELISTE,
    STATUS_PAMELDT,
} from "../../utils/soknadStatus";
import {STEG_TYPE_DOKUMENTASJON, STEG_TYPE_FAKTURAINFO} from "../pamelding/steg/Steg";
import PropTypes from "prop-types";
import TrekkSoknad from "./TrekkSoknad";
import OKButtonWithFeedback from "./OKButtonWithFeedback";
import SvarPaTilbud from "./SvarPaTilbud";
import Text from "../../primitives/Text";
import {useNavigate} from "react-router";

export const SoknadRowActionButton = props => {
    const {children} = props;
    const navigate = useNavigate();
    const onClick = onClickFromType(props, navigate);
    return (
        <button
            onClick={onClick}
            disabled={!Boolean(onClick)}
        >
            {children}
        </button>
    );
};

const onClickFromType = ({
                             type,
                             kursId,
                             status,
                             innenforFrist,
                             showPopup,
                             sendSvarPaTilbud,
                             resettSoknadAction,
                             trekkSoknad,
                             angreTrekkSoknad,
                             slettUfullfortSoknad
                         }, navigate) => {

    if (!innenforFrist && type !== ACTION_TYPE_SE) {
        return;
    }
    switch (type) {
        case ACTION_TYPE_ENDRE:
        case ACTION_TYPE_SE:
            return () => {
                navigate(`${pages.pamelding}/${kursIdToUrlString(kursId)}`);
            };
        case ACTION_TYPE_DOKUMENTASJON:
            return () => {
                navigate(`${pages.pamelding}/${kursIdToUrlString(kursId)}?steg=${STEG_TYPE_DOKUMENTASJON}`);
            };
        case ACTION_TYPE_ENDRE_FAKTURAINFO:
            return () => {
                navigate(`${pages.pamelding}/${kursIdToUrlString(kursId)}?steg=${STEG_TYPE_FAKTURAINFO}`);
            };
        case ACTION_TYPE_SVARE:
            return () => {
                showPopup({
                    title: <Text id='svar_pa_tilbud'/>,
                    customOKButton: <OKButtonWithFeedback onClick={() => sendSvarPaTilbud(kursId)}/>,
                    preventCloseOnOK: true,
                    onCancel: resettSoknadAction,
                    children: <SvarPaTilbud kursId={kursId}/>,
                });
            };
        case ACTION_TYPE_ENDRE_SVAR:
            return () => {
                showPopup({
                    title: <Text id='endre_svar_pa_tilbud'/>,
                    customOKButton: <OKButtonWithFeedback onClick={() => sendSvarPaTilbud(kursId)}/>,
                    preventCloseOnOK: true,
                    onCancel: resettSoknadAction,
                    children: <SvarPaTilbud kursId={kursId}/>,
                });
            };
        case ACTION_TYPE_TREKKE:
            const direktePamelding = status === STATUS_PAMELDT;
            return () => {
                showPopup({
                    title: <Text id={direktePamelding ? 'trekk_påmelding' : 'trekke_soknad'}/>,
                    customOKButton: <OKButtonWithFeedback onClick={() => trekkSoknad(kursId)}/>,
                    onCancel: resettSoknadAction,
                    children:
                        <TrekkSoknad
                            textId={direktePamelding ?
                                'er_du_sikker_du_vil_trekke_påmeldingen_din'
                                :
                                'er_du_sikker_du_vil_trekke_søknaden_din'
                            }
                            kursId={kursId}
                        />
                });
            };
        case ACTION_TYPE_ANGRE_TREKK:
            return () => angreTrekkSoknad(kursId);
        case ACTION_TYPE_TREKKE_FRA_VENTELISTE:
            return () => {
                showPopup({
                    title: <Text id='trekk_fra_venteliste'/>,
                    customOKButton: <OKButtonWithFeedback onClick={() => trekkSoknad(kursId)}/>,
                    onCancel: resettSoknadAction,
                    children:
                        <TrekkSoknad
                            textId='er_du_sikker_du_vil_trekke_deg_fra_venteliste'
                            kursId={kursId}
                        />
                });
            };
        case ACTION_TYPE_SLETTE:
            return () => {
                showPopup({
                    title: <Text id='slette_soknad'/>,
                    customOKButton: <OKButtonWithFeedback onClick={() => slettUfullfortSoknad(kursId)}/>,
                    onCancel: resettSoknadAction,
                    children:
                        <TrekkSoknad
                            textId='er_du_sikker_du_vil_slette_søknaden_din'
                            kursId={kursId}
                        />
                });
            };
        default:
            return undefined;
    }
};


SoknadRowActionButton.propTypes = {
    type: PropTypes.string.isRequired,
    kursnavn: PropTypes.string.isRequired,
    kursId: PropTypes.PropTypes.shape({
        kurskode: PropTypes.string.isRequired,
        tidskode: PropTypes.string.isRequired,
    }).isRequired,
    status: PropTypes.string.isRequired,
    innenforFrist: PropTypes.bool.isRequired,
    showPopup: PropTypes.func.isRequired,
    sendSvarPaTilbud: PropTypes.func.isRequired,
    resettSoknadAction: PropTypes.func.isRequired,
    trekkSoknad: PropTypes.func.isRequired,
    angreTrekkSoknad: PropTypes.func.isRequired,
};


const mapDispatchToProps = {
    showPopup,
    sendSvarPaTilbud,
    resettSoknadAction,
    trekkSoknad,
    angreTrekkSoknad,
    slettUfullfortSoknad,
};

export default connect(undefined, mapDispatchToProps)(SoknadRowActionButton);