import * as routes from "./apiRoutes";
import axios from "axios";
import {createRequestConfig} from "../utils/request";


export async function getOrganisasjon(organisasjonsnummer, evuapiToken) {
    const route = routes.ORGANISASJON.replace(':organisasjonsnummer', organisasjonsnummer);
    const config = createRequestConfig(evuapiToken);
    const response = await axios.get(route, config);
    return transformResponse(response.data);
}


export const transformResponse = (data) => {
    const navn = data && data.navn;
    return navn || 'gyldig_organisasjonsnummer';
};