import {useDispatch} from "react-redux";
import Text from "../../primitives/Text";
import React from "react";
import ClickableArea from "../ClickableArea";
import FormattedDate from "../../primitives/FormattedDate";
import {showPopup} from "../../ducks/popup";
import {SMS_DOKUMENTTYPE} from "../../ducks/inngaendeDokumenter";
import {mdiMessageProcessing} from "@mdi/js";
import Icon from "../../primitives/Icon";


export const Sms = ({dokument}) => {

    const dispatch = useDispatch();
    const {innhold, datoMottatt} = dokument;

    return (
        <ClickableArea onClick={() => dispatch(showPopup(getSMSPopupContent(dokument)))}>
            <span className='type'><Icon path={mdiMessageProcessing}/><Text id={SMS_DOKUMENTTYPE}/></span>
            <div className='innhold'>
                {innhold}
            </div>
            <FormattedDate className='dato-mottat' date={datoMottatt} withTime={true}/>
        </ClickableArea>
    )
};


const getSMSPopupContent = ({datoMottatt, innhold, telefonnr}) => {
    return {
        title: <span><Text id='sendt_til'/>{telefonnr}</span>,
        children: <div className='sms-innhold'>
            <FormattedDate className='sms-innhold__dato' date={datoMottatt}/>:
            <div className='body'>{innhold}</div>
        </div>
    }
};

export default Sms;