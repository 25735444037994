import React from "react";
import {useSelector} from "react-redux";
import Text from "../../../primitives/Text";
import Adresse from "../../adresse/Adresse";
import {isAdresseEmpty} from "../../../utils/adresseUtil";
import {TYPE_ADRESSE_FAKTURA} from "../../../utils/common";
import {
    getFakturaadresse, getFakturaepost,
    getFakturamottaker, getFakturareferanse,
    getOrganisasjonsnummer,
    isBedriftBetaler, getBrukKontaktinfoadresse
} from "../../../ducks/betalingsinfo";
import {mdiMapMarker} from "@mdi/js";
import Icon from "../../../primitives/Icon";

export const StegOversiktFakturainfo = () => {

    const bedriftBetaler = useSelector(isBedriftBetaler);
    const fakturaadresse = useSelector(getFakturaadresse);
    const fakturamottaker = useSelector(getFakturamottaker);
    const organisasjonsnummer = useSelector(getOrganisasjonsnummer);
    const fakturareferanse = useSelector(getFakturareferanse);
    const epost = useSelector(getFakturaepost);
    const brukKontaktinfoadresse = useSelector(getBrukKontaktinfoadresse);

    return (
        <div className='steg-oversikt__steg__fakturainfo'>
            {fakturamottaker && <div>{fakturamottaker}</div>}
            {bedriftBetaler && organisasjonsnummer && <div>{organisasjonsnummer}</div>}
            {bedriftBetaler && fakturareferanse && <div>{fakturareferanse}</div>}
            {bedriftBetaler && epost && <div>{epost}</div>}
            {brukKontaktinfoadresse ?
                <div className='steg-oversikt__steg__fakturainfo__bruk-kontaktinfoadresse'>
                    <Icon path={mdiMapMarker} title='fakturaadresse'/>
                    {bedriftBetaler ? <Text id='bruk_arbeidsadresse'/> : <Text id='bruk_hjemmeadresse'/>}
                </div>
                :
                !isAdresseEmpty(fakturaadresse) && <Adresse adresse={fakturaadresse} type={TYPE_ADRESSE_FAKTURA}/>
            }
        </div>
    )
};


