import React from 'react';
import PropTypes from 'prop-types'
import FieldHeader from "./FieldHeader";

const ReadOnlyField = ({title, value, iconPath, altText, className = '', children}) => {
    const justIconInHeaderClass = iconPath && !title ? 'just-icon-in-header' : 'text-in-header';
    return (
        <div className={`read-only-field ${justIconInHeaderClass} ${className}`}>
            <FieldHeader text={title} iconPath={iconPath} altText={altText}/>
            <span className='value'>{value || children}</span>
        </div>
    )
};

ReadOnlyField.propTypes = {
    title: PropTypes.string,
    value: PropTypes.any,
    iconPath: PropTypes.string,
    altText: PropTypes.string,
    className: PropTypes.string,
};

export default ReadOnlyField