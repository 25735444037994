import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactSelect from "react-select";
import Label from "./Label";
import {nanoid} from "nanoid";
import find from "lodash/find";
import isEqual from "lodash/isEqual";
import isPlainObject from "lodash/isPlainObject";
import Text from "./Text";

class Select extends Component {

    constructor(props) {
        super(props);
        this.state = {
            id: nanoid(),
            selectedOption: this.makeOptionFromValue()
        };
        this.selectRef = React.createRef();
    }

    componentDidUpdate() {
        const {conditionForFocus} = this.props;
        this.setFocusOnCondition(conditionForFocus);
    }

    setFocusOnCondition = condition => {
        if (condition) {
            this.selectRef.current.focus();
        }
    };

    makeOptionFromValue = () => {
        const {value, options} = this.props;

        if (isPlainObject(value)){
            return find(options, option => {return isEqual(option.value, value)});
        }

        return find(options, {value: value});
    };

    handleChange = selectedOption => {
        const {onChange} = this.props;

        this.setState({
            ...this.state,
            selectedOption,
        });

        onChange(selectedOption);
    };

    render() {
        const {
            label, labelJustForSR, iconPathForLabel, justIconInLabel, altText,
            options, clearable, autoFocus,
            className = '', overrideId,
            translateLabel = true,
            placeholder = <Text id='velg_placeholder'/>,
            hasError,
            conditionForFocus,
            autoComplete = 'off',
            ...restOfProps
        } = this.props;

        const {id} = this.state;
        const {selectedOption} = this.state;


        const classes = `${justIconInLabel ? 'input-with-label-just-icon' : ''} 
        ${hasError ? 'select-validate-error' : ''} 
        ${className} react-select`;

        const style = {
            control: (base) => ({
                ...base,
                borderColor: '#707070',
                '&:hover': {borderColor: '#888888'}
            }),
            dropdownIndicator: base => ({
                ...base,
                'color': '#707070',
                '&:hover': {color: '#888888'}
            })
        }

        return (
            <div className={classes}>
                <Label htmlFor={overrideId ? overrideId : id}
                       text={label}
                       justForSR={labelJustForSR}
                       iconPath={iconPathForLabel}
                       justIcon={justIconInLabel}
                       altText={altText}
                       translate={translateLabel}
                       showColon={true}
                />
                <ReactSelect
                    {...restOfProps}
                    inputId={overrideId ? overrideId : id}
                    aria-labelledby={overrideId ? overrideId : id}
                    value={selectedOption}
                    options={options}
                    onChange={this.handleChange}
                    clearable={clearable}
                    autoFocus={autoFocus || conditionForFocus}
                    className={justIconInLabel ? 'just-icon-in-label' : ''}
                    classNamePrefix='react-select'
                    placeholder={placeholder}
                    noOptionsMessage={() => <Text id='ingen_treff'/>}
                    ref={this.selectRef}
                    autoComplete={autoComplete}
                    styles={style}
                />
            </div>
        )
    }
}


Select.propTypes = {
    label: PropTypes.string,
    labelJustForSR: PropTypes.bool,
    iconPathForLabel: PropTypes.string,
    justIconInLabel: PropTypes.bool,
    value: PropTypes.any,
    onChange: PropTypes.func,
    options: PropTypes.any,
    clearable: PropTypes.bool,
    autoFocus: PropTypes.bool,
    conditionForFocus: PropTypes.bool,
    className: PropTypes.string,
    overrideId: PropTypes.string,
    autoComplete: PropTypes.string,
    placeholder: PropTypes.node,
    hasError: PropTypes.bool,
};


export default Select