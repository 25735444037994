import React, {Component} from 'react';
import PageTitle from "./PageTitle";
import {ADMINISTRERE_COOKIES_INFO_URL, INGEN_OVERSETTELSE} from "../utils/common";
import {injectIntl} from 'react-intl';
import '../sass/less-fun.scss';
import Text from "../primitives/Text";


export class Cookies extends Component {


    render() {
        const {formatMessage} = this.props.intl;
        const lenkeText = formatMessage({id: 'gå_til_nettside_for_mer_info_om_cookies'});
        const visLenke = lenkeText !== INGEN_OVERSETTELSE;

        return (
            <article className='cookies'>
                <PageTitle id='bruk_av_informasjonskapsler'/>

                <h1><Text id='bruk_av_informasjonskapsler_og_annen_lokal_lagring'/></h1>
                <p><Text id='cookies_er_tekstfiler'/></p>
                <p><Text id='evuweb_lagrer_informasjon'/></p>
                <p>
                    <Text id='du_kan_selv_slette_alle_lokalt_lagrede_data'/>
                    {visLenke &&
                    <React.Fragment>
                        <a className="link"
                           href={ADMINISTRERE_COOKIES_INFO_URL}
                           target="_blank"
                           rel="noopener noreferrer">
                            {lenkeText}
                        </a>
                        .
                    </React.Fragment>
                    }
                </p>
                <p><Text id='evuweb_bruker_ikke_tredjeparts_informasjonskapsler'/></p>


                <h2><Text id='oversikt_over_data_som_blir_lagret'/></h2>

                <h3><Text id='fast_lagring'/></h3>
                <p><Text id='dette_er_data_som_ikke_slettes_ved_utlogging'/></p>

                <h4><code>i18n</code></h4>
                <p><Text id='her_lagres_din_språkpreferanse'/></p>

                <h3><Text id='sesjonsavhengig_lagring'/></h3>
                <p><Text id='dette_er_data_som_slettes_ved_utlogging'/></p>

                <h4><code>tokens-dataporten</code></h4>
                <p><Text id='brukes_til_å_verifisere_din_identitet'/></p>

                <h4><code>nonce</code></h4>
                <p><Text id='brukes_for_å_sikre_påloggingen_din'/></p>

                <h4><code>state-*</code></h4>
                <p><Text id='brukes_for_å_sikre_påloggingen_din'/></p>

                <h4><code>evuapi-token</code></h4>
                <p><Text id='brukes_til_å_identifisere_deg_som_bruker'/></p>

                <h4><code>redux-state</code></h4>
                <p><Text id='her_lagres_navn_på_institusjon_osv'/></p>

                <h4><code>local-storage-test</code></h4>
                <p><Text id='brukes_for_å_teste_at_lokallagring_er_aktivert'/></p>

            </article>
        )
    }
}

export default injectIntl(Cookies);