import React from 'react';
import {useSelector} from "react-redux";
import {getEndreFrist, getStatus} from "../../ducks/pamelding";
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import {STATUS_IKKE_FULLFORT} from "../../utils/soknadStatus";
import FormattedDate from "../../primitives/FormattedDate";
import Text from "../../primitives/Text";

const UtgattFristVarsel = () => {
    const {dato: endrefrist} = useSelector(getEndreFrist);
    const status = useSelector(getStatus);
    return status === STATUS_IKKE_FULLFORT && endrefrist < Date.now() ?
        <Feedback type={FEEDBACK_TYPE_VARSEL}>
            <Text id={'søknadsfrist_utløpt'} values={{ frist: <FormattedDate date={endrefrist} /> }}/>
        </Feedback>
    : null;
};

export default UtgattFristVarsel;