import {getPosition, getScrollPosition} from "../../utils/common";
import React, {useRef} from "react";
import {useMountEffect} from "../../hooks/mounting-hooks";

const ScrollIntoView = ({children}) => {

    const node = useRef(null);

    const scrollIntoView = () => {
        const {offsetHeight: elementHeight} = node.current;
        const {scrollTo, innerHeight} = window;
        const {y: distanceTop} = getPosition(node.current);
        const {scrollTop, scrollLeft} = getScrollPosition();

        if (innerHeight < elementHeight) {
            // Scroll til starten av elementet når elementet er høyere enn skjermen
            scrollTo(scrollLeft, distanceTop - 10);
            return;
        }

        if (innerHeight + scrollTop < elementHeight + distanceTop) {
            // Scroll ned slik at slutten av elementet treffer bunnen av skjermen dersom elementet går nedenfor skjermen
            scrollTo(scrollLeft, distanceTop + elementHeight - innerHeight + 10);
        }

        // Ikke gjør noen ting når skjerm er høy nok og hele elementet er synlig
    };

    useMountEffect(scrollIntoView);

    return (
        <div ref={node}>
            {children}
        </div>
    )
};

export default ScrollIntoView;