import React from 'react';
import {getAntallSoknaderSomVenterPaDokumenter, getSoknaderSomVenterPaDokumenter,} from "../../../ducks/soknader";
import {useSelector} from "react-redux";
import Card from "../Card";
import {soknaderIconPath} from "../../../utils/icons";
import {getFirstOf, getPathToSteg, pages} from "../../../utils/common";
import withSoknaderLoaded from "../../../high-order-components/withSoknaderLoaded";
import Button from "../../../primitives/Button";
import {STEG_TYPE_DOKUMENTASJON} from "../../pamelding/steg/Steg";
import {compose} from "redux";
import {requireDeltakernummer} from "../../../high-order-components/requireDeltakernummer";
import Text from "../../../primitives/Text";
import {ACTION_TYPE_DOKUMENTASJON} from "../../../utils/soknadStatus";
import FormattedDate from "../../../primitives/FormattedDate";
import {useNavigate} from "react-router";

const VarselSoknaderVenterPaDokumenter = () => {

    const soknaderSomVenterPaDokumenter = useSelector(getSoknaderSomVenterPaDokumenter);
    const antallSoknaderSomVenterPaDokumenter = useSelector(getAntallSoknaderSomVenterPaDokumenter);

    if (antallSoknaderSomVenterPaDokumenter === 0) return null;

    const {actions} = soknaderSomVenterPaDokumenter[0];
    const action = actions.find(action => action.type === ACTION_TYPE_DOKUMENTASJON);

    const {kursnavn} = getFirstOf(soknaderSomVenterPaDokumenter);
    const textId = antallSoknaderSomVenterPaDokumenter === 1 ? 'din_søknad_på_kurs_venter_pa_dokumentasjon' : 'du_har_soknader_som_venter_pa_dokumentasjon';

    return (
        <Card
            tittel={<Text id='dokumentmangler'/>}
            varsel={true}
            iconPath={soknaderIconPath}
            actions={{
                lenke: antallSoknaderSomVenterPaDokumenter > 1 && {
                    tekst: <Text id='gå_til_søknader'/>,
                    til: pages.soknader
                },
                button: <LastOppButton />
            }}
        >
            <Text
                id={textId}
                antallSoknader={antallSoknaderSomVenterPaDokumenter}
                kursnavn={kursnavn}
            />
            <Text
                id='dokumentasjonsfristen_er'
                frist={<FormattedDate date={action.frist} withTime={true}/>}
            />
        </Card>
    )
};

const LastOppButton = () => {

    const soknaderSomVenterPaDokumenter = useSelector(getSoknaderSomVenterPaDokumenter);
    const antallSoknaderSomVenterPaDokumenter = useSelector(getAntallSoknaderSomVenterPaDokumenter);

    const navigate = useNavigate();
    if (antallSoknaderSomVenterPaDokumenter !== 1) return null;
    const soknad = soknaderSomVenterPaDokumenter[0];
    const path = getPathToSteg(soknad.id, STEG_TYPE_DOKUMENTASJON);
    return (
        <Button onClick={() => navigate(path)}>
            <Text id='legg_til_dokumentasjon'/>
        </Button>
    )
};

export default compose(
    Component => requireDeltakernummer(Component, false),
    withSoknaderLoaded,
)(VarselSoknaderVenterPaDokumenter);