import {useSelector} from "react-redux";
import {getKurs} from "../../ducks/kursinfo";
import {ExternalLink} from "../../primitives/Link";
import React from "react";
import Text from "../../primitives/Text";
import {mdiSchool} from '@mdi/js';
import Icon from "../../primitives/Icon";


export const Krav = ({classname}) => {
    const kurs = useSelector(getKurs);
    const {merknadKrav, nettsideKrav} = kurs;

    if (!(merknadKrav || nettsideKrav)) {
        return null;
    }

    return (
        <div className={`krav ${classname}`}>
            <h3>
                <Icon path={mdiSchool} className='forkunnskap-icon'/>
                <Text id={'krav'}/>
            </h3>
            <ul>
                {merknadKrav && <li>{merknadKrav}</li>}
                {nettsideKrav && <li>
                    <ExternalLink href={nettsideKrav}>
                        <Text id='informasjon_om_opptakskrav'/>
                    </ExternalLink>
                </li>}
            </ul>
        </div>
    )
};