import * as routes from "./apiRoutes";
import axios from "axios/index";

export function getLandskoder(instnr) {

    const route = routes.LANDSKODER.replace(':instnr',instnr);

    return axios.get(route)
        .then(response => {
            return response.data.telefonland;
        })
        .catch(error => {
            throw error;
        });
}

