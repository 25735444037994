import React from "react";
import {Link} from "react-router-dom";
import {pages} from "../../utils/common";
import Text from "../../primitives/Text";
import Icon from "../../primitives/Icon";
import {mdiLoginVariant} from "@mdi/js";

const MenubarLogin = () => {
    return (
        <div id='login' className='menubar-button'>
            <Link to={pages.minSide}>
                <Icon path={mdiLoginVariant}/>
                <Text id='login'/>
            </Link>
        </div>
    )
};

export default MenubarLogin;