import {useSelector} from "react-redux";
import {getUploadsMedFeil} from "../../../ducks/dokumenter";
import Text from "../../../primitives/Text";
import React from "react";
import WSErrorDescription from "../../WSErrorDescription";

const ErrorsUpload = () => {
    const errorsUploads = useSelector(getUploadsMedFeil);
    return (<>
        <p>
            <Text id='følgende_dokumenter_ble_ikke_opplastet'/>
        </p>
        <ul>
            {errorsUploads.map((errorMap, index) =>
                <li key={index}>
                    <span className='element-beskrivelse'>{errorMap.filnavn}</span>
                    <WSErrorDescription error={errorMap.error}/>
                </li>
            )}
        </ul>
    </>)
};

export default ErrorsUpload;