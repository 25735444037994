import EditableField from "../../EditableField";
import React from "react";
import InlineEdit from "../../inline-edit/InlineEdit";
import FormFocusControl from "../../inline-edit/FormFocusControl";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormField,
    getErrorLagring,
    getFormDataMalform,
    lagreProfilFelt, resetErrorLagringForFelt,
    resetFormdata
} from "../../../ducks/profil";
import InputMalform, {getMalformByCode} from "../../InputMalform";
import useInlineEdit from "../../../hooks/useInlineEdit";
import {getRequestErrorTextId} from "../../../utils/request";

const MALFORM_FELT = 'malform';

export const Malform = () => {

    const malform = useSelector(getFormDataMalform);
    const errorLagring = useSelector(state => getErrorLagring(state, MALFORM_FELT));
    const errorMessageId = errorLagring && getRequestErrorTextId(errorLagring);

    const dispatch = useDispatch();
    const submitChanges = () => dispatch(lagreProfilFelt(MALFORM_FELT));
    const resetChanges = () => dispatch(resetFormdata());
    const onChange = value => {
        dispatch(changeFormField(MALFORM_FELT, value));
        dispatch(resetErrorLagringForFelt(MALFORM_FELT));
    };

    const [isInEditMode, openEditing, closeEditing] = useInlineEdit(submitChanges, true, errorLagring);

    return (
        <InlineEdit
            resetChanges={resetChanges}
            isInEditMode={isInEditMode}
            openEditing={openEditing}
            closeEditing={closeEditing}
        >
            <FormFocusControl onFocusOut={closeEditing}>
                <InputMalform
                    value={malform}
                    onChange={selectedValue => onChange(selectedValue)}
                    errorMessageId={errorMessageId}
                />
            </FormFocusControl>
            <EditableField value={getMalformByCode(malform)} valueNameId='malform'/>
        </InlineEdit>
    );
};

export default Malform;
