import React from 'react';
import PropTypes from "prop-types";
import {getFeedbackTypeFromError} from "../utils/request";
import Feedback from "./Feedback";
import WSErrorDescription from "./WSErrorDescription";
import Text from "../primitives/Text";

const WSError = ({error, className = '', tittel}) => {
    const classes = `ws-error ${className}`;
    return (
        <Feedback type={getFeedbackTypeFromError(error)} className={classes}>
            <h4>
                <Text id={tittel ? tittel : 'noe_feilet_under_oppkobling_til_API'}/>:
            </h4>
            <WSErrorDescription error={error}/>
        </Feedback>
    );
};


export default WSError;


WSError.propTypes = {
    className: PropTypes.string,
    tittel: PropTypes.string,
    error: PropTypes.object,
};