import * as opprettBrukerAPI from '../services/opprettBrukerAPI';
import {
    getTelefonError,
    hasTrueProperty,
} from "../utils/common";
import {
    getDataportenAccessToken,
    getInstitusjonsnr,
    loadEvuApiToken
} from "./session";
import {getErrorMessageId} from "../utils/epostUtil";
import {getErrorMessageId as getDateErrorMessageId} from "../utils/dateUtil";

export const NAME = 'nyBruker';

const LOAD_DATA_FOR_NY_BRUKER_PENDING = `${NAME}/LOAD_DATA_FOR_NY_BRUKER_PENDING`;
const LOAD_DATA_FOR_NY_BRUKER_SUCCESS = `${NAME}/LOAD_DATA_FOR_NY_BRUKER_SUCCESS`;
const LOAD_DATA_FOR_NY_BRUKER_FAILURE = `${NAME}/LOAD_DATA_FOR_NY_BRUKER_FAILURE`;

const OPPRETT_BRUKER_PENDING = `${NAME}/OPPRETT_BRUKER_PENDING`;
export const OPPRETT_BRUKER_SUCCESS = `${NAME}/OPPRETT_BRUKER_SUCCESS`;
const OPPRETT_BRUKER_FAILURE = `${NAME}/OPPRETT_BRUKER_FAILURE`;
const RESETT_OPPRETT_ERROR = `${NAME}/RESETT_OPPRETT_ERROR`;

const FORSOK_OPPRETT = `${NAME}/FORSOK_OPPRETT`;

const CHANGE_FORMDATA = `${NAME}/CHANGE_FORMDATA`;
const CHANGE_TELEFONNUMMER = `${NAME}/CHANGE_TELEFONNUMMER`;
const CHANGE_TELEFONLAND = `${NAME}/CHANGE_TELEFONLAND`;


export function loadDataForNyBrukerPending() {
    return {
        type: LOAD_DATA_FOR_NY_BRUKER_PENDING,
    };
}


export function loadDataForNyBrukerSuccess(personData) {
    return {
        type: LOAD_DATA_FOR_NY_BRUKER_SUCCESS,
        data: personData,
    };
}


export function loadDataForNyBrukerFailure(error) {
    return {
        type: LOAD_DATA_FOR_NY_BRUKER_FAILURE,
        error: error
    };
}


export function opprettBrukerPending() {
    return {
        type: OPPRETT_BRUKER_PENDING,
    };
}

export function opprettBrukerSuccess() {
    return {
        type: OPPRETT_BRUKER_SUCCESS
    };
}

export function opprettBrukerFailure(error) {
    return {
        type: OPPRETT_BRUKER_FAILURE,
        error: error
    };
}

export function resettOpprettError() {
    return {
        type: RESETT_OPPRETT_ERROR,
    };
}

export function forsokOpprett() {
    return {
        type: FORSOK_OPPRETT
    };
}

export const changeFormData = (key, value) => {
    return {
        type: CHANGE_FORMDATA,
        key: key,
        value: value
    };
};

export const changeFormField = (key, value) => dispatch => {
    dispatch(changeFormData(key, value));
    dispatch(resettOpprettError());
};

export function changeTelefonnummer(nummer) {
    return {
        type: CHANGE_TELEFONNUMMER,
        nummer
    }
}

export function changeTelefonland({landskode, landnummer}) {
    return {
        type: CHANGE_TELEFONLAND,
        landskode,
        landnummer,
    }
}


export function loadDataForNyBruker() {
    return function (dispatch, getState) {
        dispatch(loadDataForNyBrukerPending());

        const state = getState();
        const instnr = getInstitusjonsnr(state);
        const dataportenToken = getDataportenAccessToken(state);

        return opprettBrukerAPI.getPersonData(instnr, dataportenToken).then(
            personData => dispatch(loadDataForNyBrukerSuccess(personData)),
            error => dispatch(loadDataForNyBrukerFailure(error))
        );
    };
}


export function opprettBruker() {
    return function (dispatch, getState) {
        dispatch(opprettBrukerPending());

        const state = getState();
        if (!isFormValid(state)) {
            return;
        }

        const instnr = getInstitusjonsnr(state);
        const dataportenAccessToken = getDataportenAccessToken(state);
        const data = getFormData(state);

        return opprettBrukerAPI.opprettBruker(data, instnr, dataportenAccessToken).then(
            () => {
                dispatch(opprettBrukerSuccess());
                dispatch(loadEvuApiToken());
            },
            error => {
                dispatch(opprettBrukerFailure(error))
            }
        );
    };
}


export const createFormData = (
    {
        fornavn = '',
        etternavn = '',
        fodselsdato,
        kjonn = '',
        statsborgerskap,
        malform = '',
        epost = '',
        epostArbeid = '',
        telefon = createTelefon({}),
    }) => {

    return {
        fornavn,
        etternavn,
        fodselsdato,
        kjonn,
        statsborgerskap,
        malform,
        epost,
        epostArbeid,
        telefon,
    }
};


export const createTelefon = (
    {
        nummer = '',
        landskode = '',
        landnummer,
    }) => {

    return {
        nummer,
        landskode,
        landnummer,
    }
};


export const createNyBruker = (
    {
        formData = createFormData({}),
        isLoading = false,
        error,
        isDataLoaded = false,
        isOppretting,
        isOpprettingSuksessfull,
        errorOpprett,
        forsoktOpprett = false,
        kanEndreFodselsdato = false,
        kanEndreKjonn = false,
        epostFraKorr,
        mobilFraKorr,
        kanRedigerePersoninfo,
        personinfoHentetFra
    }) => {

    return {
        isLoading,
        error,
        isDataLoaded,
        formData,
        isOppretting,
        isOpprettingSuksessfull,
        errorOpprett,
        forsoktOpprett,
        kanEndreFodselsdato,
        kanEndreKjonn,
        epostFraKorr,
        mobilFraKorr,
        kanRedigerePersoninfo,
        personinfoHentetFra
    }
};

export const initialState = createNyBruker({});

function nyBruker(state = initialState, action) {
    switch (action.type) {
        case LOAD_DATA_FOR_NY_BRUKER_PENDING:
        case LOAD_DATA_FOR_NY_BRUKER_SUCCESS:
        case LOAD_DATA_FOR_NY_BRUKER_FAILURE:
            return load(state, action);
        case FORSOK_OPPRETT:
        case OPPRETT_BRUKER_PENDING:
        case OPPRETT_BRUKER_SUCCESS:
        case OPPRETT_BRUKER_FAILURE:
        case RESETT_OPPRETT_ERROR:
            return opprettBrukerReducer(state, action);
        case CHANGE_FORMDATA:
        case CHANGE_TELEFONNUMMER:
        case CHANGE_TELEFONLAND:
            return {
                ...state,
                formData: formData(state.formData, action),
            };
        default:
            return state
    }
}


function load(state = initialState, action) {
    switch (action.type) {
        case LOAD_DATA_FOR_NY_BRUKER_PENDING:
            return {
                ...state,
                isLoading: true,
                isDataLoaded: false,
                error: undefined
            };
        case LOAD_DATA_FOR_NY_BRUKER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                formData: createFormData({...action.data}),
                kanEndreFodselsdato: !action.data.fodselsdato,
                kanEndreKjonn: !action.data.kjonn,
                epostFraKorr: action.data.epostFraKorr,
                mobilFraKorr: action.data.mobilFraKorr,
                personinfoHentetFra: action.data.personinfoHentetFra,
                kanRedigerePersoninfo: action.data.kanRedigerePersoninfo,
            };
        case LOAD_DATA_FOR_NY_BRUKER_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                isDataLoaded: true,
            };
        default:
            return state
    }
}


function opprettBrukerReducer(state = initialState, action) {
    switch (action.type) {
        case FORSOK_OPPRETT:
            return {
                ...state,
                forsoktOpprett: true,
            };
        case OPPRETT_BRUKER_PENDING:
            return {
                ...state,
                isOppretting: true,
                isOpprettingSuksessfull: undefined,
                errorOpprett: undefined
            };
        case OPPRETT_BRUKER_SUCCESS:
            return {
                ...state,
                isOppretting: false,
                isOpprettingSuksessfull: true,
                errorOpprett: undefined
            };
        case OPPRETT_BRUKER_FAILURE:
            return {
                ...state,
                isOppretting: false,
                isOpprettingSuksessfull: false,
                errorOpprett: action.error
            };
        case RESETT_OPPRETT_ERROR:
            return {
                ...state,
                errorOpprett: undefined
            };
        default:
            return state;
    }
}


function formData(state = initialState.formData, action) {
    switch (action.type) {
        case CHANGE_FORMDATA:
            return {
                ...state,
                [action.key]: action.value,
            };
        case CHANGE_TELEFONNUMMER:
            return {
                ...state,
                telefon: {
                    ...state.telefon,
                    nummer: action.nummer,
                }
            };
        case CHANGE_TELEFONLAND:
            return {
                ...state,
                telefon: {
                    ...state.telefon,
                    landskode: action.landskode,
                    landnummer: action.landnummer,
                }
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME];
}


export function getData(state) {
    return getState(state).data;
}

export function getFormData(state) {
    return getState(state).formData;
}


export function isDataLoaded(state) {
    return getState(state).isDataLoaded;
}


export function isLoading(state) {
    return getState(state).isLoading;
}

export function getError(state) {
    return getState(state).error;
}


export function getFormErrors(state) {
    const {
        fornavn,
        etternavn,
        fodselsdato,
        kjonn,
        statsborgerskap,
        malform,
        epost,
        epostArbeid,
        telefon
    } = getFormData(state);
    const {nummer = '', landskode = '',} = telefon || {};

    return {
        fornavn: !fornavn,
        etternavn: !etternavn,
        fodselsdato: getDateErrorMessageId(fodselsdato, true, undefined, true, 'fodselsdato_obligatorisk'),
        kjonn: !kjonn,
        statsborgerskap: statsborgerskap === undefined,
        malform: !malform,
        epost: getErrorMessageId(epost, true, undefined, true),
        epostArbeid: getErrorMessageId(epostArbeid, false, undefined, true),
        telefon: getTelefonError(landskode, nummer, true),
    }
}

export function getForsoktOpprett(state) {
    return getState(state).forsoktOpprett;
}

export function isFormValid(state) {
    return !hasTrueProperty(getFormErrors(state));
}

export function isOppretting(state) {
    return getState(state).isOppretting;
}


export function isOpprettingSuksessfull(state) {
    return getState(state).isOpprettingSuksessfull;
}

export function getKanRedigerePersoninfo(state) {
    return getState(state).kanRedigerePersoninfo;
}

export function getPersoninfoHentetFra(state) {
    return getState(state).personinfoHentetFra;
}

export function kanEndreFodselsdato(state) {
    return getState(state).kanEndreFodselsdato;
}

export function kanEndreKjonn(state) {
    return getState(state).kanEndreKjonn;
}

export function getNyBrukerNavnForMenybar(state) {
    const formData = getFormData(state);
    if (!getKanRedigerePersoninfo(state) && formData.fornavn && formData.etternavn) {
        return `${formData.fornavn} ${formData.etternavn}`;
    }
    return undefined;
}

export const getTelefon = state => getFormData(state).telefon;
export const getEpost = state => getFormData(state).epost;
export const getEpostArbeid = state => getFormData(state).epostArbeid;
export const getMalform = state => getFormData(state).malform;
export const getStatsborgerskap = state => getFormData(state).statsborgerskap;
export const getFornavn = state => getFormData(state).fornavn;
export const getEtternavn = state => getFormData(state).etternavn;
export const getFulltNavn = state => {
    const fornavn = getFornavn(state);
    const etternavn = getEtternavn(state);
    return `${fornavn} ${etternavn}`;
}
export const getFodselsdato = state => getFormData(state).fodselsdato;
export const getKjonn = state => getFormData(state).kjonn;

export const getErrorOpprett = state => getState(state).errorOpprett;

export const isMobilFraKorr = state => getState(state).mobilFraKorr;
export const getIsEpostFraKorr = state => getState(state).epostFraKorr;

export default nyBruker;