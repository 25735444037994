import React from 'react'
import PropTypes from 'prop-types'
import Laster from "../components/Laster";
import WSError from '../components/WSError';
import {useDispatch, useSelector} from "react-redux";
import {useMountEffect} from "../hooks/mounting-hooks";
import Text from "../primitives/Text";

const InitialLoadWrapper = ({loadingSelector, loadedSelector, errorSelector, loadAction, args, loadMessage, reloadOnMount, children}) => {

    const isLoading = useSelector(loadingSelector);
    const isLoaded = useSelector(loadedSelector);
    const error = useSelector(errorSelector);
    const dispatch = useDispatch();

    useMountEffect(()=>{
        if((!isLoading && !isLoaded) || reloadOnMount){
            dispatch(loadAction(args));
        }
    });

    if (error) {
        return <WSError error={error}/>;
    }

    if (isLoading || !isLoaded) {
        return (
            <Laster>
                {loadMessage || <Text id='laster_inn' />}
            </Laster>
        )
    }

    return children;
};

export default InitialLoadWrapper;

InitialLoadWrapper.propTypes = {
    loadingSelector: PropTypes.func.isRequired,
    loadedSelector: PropTypes.func.isRequired,
    errorSelector: PropTypes.func.isRequired,
    loadAction: PropTypes.func.isRequired,
    loadMessage: PropTypes.node,
    args: PropTypes.any,
    reloadOnMount: PropTypes.bool
};