import dateFormat from "dateformat";
import sanitizeHtml from "sanitize-html";
import {LOCALES_LIST} from "./i18nUtil";

export const APPLICATION_NAME = "EVUweb";


export function logge(text) {
    if (process.env.NODE_ENV !== 'production') {
        console.log(text)
    }
}

const CONTEXT = "/evuweb";

export const ICON_COLOR = "#607D8B";

export const pages = {
    minSide: `${CONTEXT}/min-side`,
    soknader: `${CONTEXT}/soknader`,
    kurs: `${CONTEXT}/kurs`,
    pamelding: `${CONTEXT}/pamelding`,
    dokumenter: `${CONTEXT}/dokumentasjon`,
    meldingsarkiv: `${CONTEXT}/meldingsarkiv`,
    mineKurs: `${CONTEXT}/mine-kurs`,
    profil: `${CONTEXT}/profil`,
    samtykker: `${CONTEXT}/samtykke`,
    fakturaoversikt: `${CONTEXT}/fakturaoversikt`,
    foretaksgodkjenning: `${CONTEXT}/foretaksgodkjenning`,
    login: `${CONTEXT}/login`,
    loginHandler: `${CONTEXT}/loginHandler?loginMethod=:loginMethod`,
    institusjon: `${CONTEXT}/institusjon`,
    nyBruker: `${CONTEXT}/ny-bruker`,
    cookies: `${CONTEXT}/cookies`,
    localStorage: `${CONTEXT}/local-storage`,
    arbeidserfaring: `${CONTEXT}/arbeidserfaring`,
};

export const ADMINISTRERE_COOKIES_INFO_URL = 'https://nettvett.no/slik-administrer-du-informasjonskapsler/';

export const KEY_ENTER = 'Enter';
export const KEY_KODE_ENTER = 13;
export const KEY_KODE_ESC = 27;
export const KEY_KODE_SPACEBAR = 32;
export const KEY_TAB = 'Tab';

export const MAX_LENGDE_NORSK_TELEFON = 8;
export const MAX_LENGDE_UTELANDSK_TELEFON_MED_KODE = 15;

export const NORGE_KODE = '47';

export const MAX_LENGDE_ADRESSELINJE = 60;
export const MAX_LENGDE_POSTADRESSE = 30;
export const MAX_LENGDE_EPOST = 100;
export const MAX_LENGDE_FRITEKST_SPORSMAL = 2000;
export const MAX_LENGDE_FORNAVN = 30;
export const MAX_LENGDE_ETTERNAVN = 30;

export const TYPE_ADRESSE_HJEM = 'hjemstedsadresse';
export const TYPE_ADRESSE_ARBEID = 'arbeidsadresse';
export const TYPE_ADRESSE_FAKTURA = 'fakturaadresse';

export const INGEN_OVERSETTELSE = '-';

export const DAG_I_MILLISEKUNDER = 24 * 60 * 60 * 1000;
export const MILLISECONDS_IN_SECOND = 1000;

export const MAX_FILE_SIZE_MB = 15;

export function hasTrueProperty(value) {
    return (
        typeof value === "object"
            ?
            Object.keys(value).some(key => hasTrueProperty(value[key]))
            :
            value
    )
}


export function isObjectEmpty(value) {
    return !Boolean(Object.keys(value).length)
}


export function redirectTo(page) {
    window.location.assign(page)
}


export function triggersButton(event) {
    const keyCode = event.which || event.keyCode;
    return (event.key === KEY_ENTER || keyCode === KEY_KODE_SPACEBAR);
}

export function isEnterPressed(event) {
    return (event.key === KEY_ENTER);
}

export function isEscPressed(event) {
    const keyCode = event.which || event.keyCode;
    return (keyCode === KEY_KODE_ESC);
}

export function isTabPressed(event) {
    return (!event.shiftKey && event.key === KEY_TAB);
}

export function isShiftTabPressed(event) {
    return (event.shiftKey && event.key === KEY_TAB);
}

export function isSpacePressed(event) {
    const keyCode = event.which || event.keyCode;
    return (keyCode === KEY_KODE_SPACEBAR);
}

export function getValueByLanguage(translations, locale) {
    if (!(translations instanceof Array)) {
        return translations;
    }

    const translation = findTranslationByLocale(translations, locale);
    if (translation && translation.value) {
        return translation.value;
    }

    const fallbackTranslation = LOCALES_LIST
        .map(locale => findTranslationByLocale(translations, locale))
        .find(translation => Boolean(translation));

    return fallbackTranslation && fallbackTranslation.value;
}

export function getTranslationLangByLanguage(translations, locale) {
    if (!(translations instanceof Array)) {
        return undefined;
    }

    const translation = findTranslationByLocale(translations, locale);
    if (translation && translation.value) {
        return undefined;
    }

    const fallbackTranslation = LOCALES_LIST
        .map(locale => findTranslationByLocale(translations, locale))
        .find(translation => Boolean(translation));

    if (fallbackTranslation && fallbackTranslation.value){
        return getLocaleByMalform(fallbackTranslation.lang);
    }

    return undefined;
}


function findTranslationByLocale(translations, locale) {
    const lang = getLangByLocale(locale);
    return translations.find(translation => translation.lang === lang && Boolean(translation.value));
}


export function getLangByLocale(locale) {
    const localeToLangMap = {
        'nb': 'BOKMÅL',
        'nn': 'NYNORSK',
        'en': 'ENGELSK',
        default: 'BOKMÅL',
    };

    return localeToLangMap[locale] || localeToLangMap.default;
}

export function getLocaleByMalform(malform) {

    const malformToLocaleMap = {
        'NYNORSK': 'nn',
        'ENGELSK': 'en',
        default: 'nb',
    };

    return malformToLocaleMap[malform] || malformToLocaleMap.default;
}


export function formatDateByLocale(date, locale, withTime) {

    if (typeof date !== 'object') {
        return date;
    }

    const formatStringDate = locale === 'en' ? 'dd mmm yyyy' : 'dd.mm.yyyy';
    const formatStringTime = withTime ? ' HH:MM:ss' : '';

    const formatString = formatStringDate + formatStringTime;
    const inUTC = !withTime;

    return dateFormat(date, formatString, inUTC);
}

export function formatAmountByLocale(amount, locale) {

    const localeNBForNN = (locale === 'nn' ? 'nb' : locale);
    const formatter = new Intl.NumberFormat(localeNBForNN, {
        style: "currency",
        currency: "NOK",
        currencySign: "accounting"
    });

    return amount && formatter.format(amount);

}

export function isPostnummerValid(postnummer) {
    return postnummer && (postnummer.length === 4) && containsOnlyDigits(postnummer);
}

export function getTelefonError(landskode, telefonnr, obligatorisk = false) {

    if (!telefonnr) {
        return obligatorisk ? 'mobilnummer_obligatorisk' : undefined;
    }

    if (!containsOnlyDigits(telefonnr)) {
        return 'nummer_kan_bare_ha_tall';
    }

    if (!landskode || landskode === NORGE_KODE) {
        if (telefonnr.length > MAX_LENGDE_NORSK_TELEFON) {
            return 'norsk_nummer_maks_antall_tegn';
        }
        if (telefonnr.length !== 8) {
            return 'norsk_nummer_skal_være_nøyaktig_8_tegn';
        }
    }

    if (landskode && landskode !== NORGE_KODE) {
        if (telefonnr.length + landskode.length > MAX_LENGDE_UTELANDSK_TELEFON_MED_KODE) {
            return 'utenlandsk_nummer_maks_antall_tegn';
        }
    }

}


export function containsOnlyDigits(value) {
    return /^\d+$/.test(value);
}


export function deepFindChanges(value, oldValue) {

    let changes = undefined;

    for (const prop in value) {

        if (!value.hasOwnProperty(prop)) {
            continue;
        }

        let propChanges = undefined;

        if (typeof value[prop] === "object") {
            propChanges = deepFindChanges(value[prop], oldValue[prop]);
        } else if (value[prop] !== oldValue[prop]) {
            propChanges = value[prop];
        }

        if (propChanges !== undefined) {
            changes = {
                ...changes,
                [prop]: propChanges,
            }
        }

    }

    return changes;
}


export function parseDato(dato) {

    const parts = dato.split(' ');
    const dateParts = /(.*)\.(.*)\.(.*)/g.exec(parts[0]);
    const timeParts = /(.*):(.*):(.*)/g.exec(parts[1]);

    if (!dateParts) {
        return undefined;
    }

    const day = parseInt(dateParts[1]);
    const month = parseInt(dateParts[2]) - 1;
    const year = parseInt(dateParts[3]);
    const date = new Date(year, month, day);

    if (timeParts) {
        date.setHours(parseInt(timeParts[1]));
        date.setMinutes(parseInt(timeParts[2]));
        date.setSeconds(parseInt(timeParts[3]));
    } else {
        date.setHours(0, 0, 0);
    }

    date.setMilliseconds(0);

    return date;

}

export const parseIsoDate = date => date && new Date(fixISOStringForIE(date));

export const fixISOStringForIE = dateString => {
    const hasTime = dateString.includes('T');
    if (!hasTime) return dateString;

    //til "1984-02-25T00:00:00+00:00" format from "1984-02-25T00:00:00+0000"
    return `${dateString.substring(0, dateString.length - 2)}:${dateString.substring(dateString.length - 2, dateString.length)}`
};

export function kursIdToString(kursId) {
    return kurskodeOgTidskodeToString(kursId.kurskode, kursId.tidskode);
}

export function kurskodeOgTidskodeToString(kurskode, tidskode) {
    return `${kurskode},${tidskode}`;
}

export function kursIdToUrlString(kursId) {
    return kursIdStringToPathVariable(kursIdToString(kursId));
}

export function kursIdStringToPathVariable(kursId) {
    return kursId.replace('/', encodeURIComponent('/'));
}

export function equalDates(date1, date2) {
    return date1.getTime() === date2.getTime();
}

export function brukerNavnFromProfilData(data) {
    return `${data.fornavn} ${data.etternavn}`;
}

export function isEmailValid(email, obligatorisk = false) {

    if (!email) {
        return !obligatorisk;
    }

    if (email.length > MAX_LENGDE_EPOST) {
        return false;
    }

    // Kopiert fra StudentWeb:
    // Tatt fra http://www.regular-expressions.info/email.html
    // Utvidet til å akseptere store bokstaver.
    const emailPattern = /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/;

    return emailPattern.test(email);
}

export function generateStegHeaderRef(id) {
    return `steg-${id}`;
}


const kjonnByCode = {
    'M': 'mann',
    'K': 'kvinne',
    default: 'ikke_oppgitt',
};


export function getKjonnByCode(code) {
    return kjonnByCode[code] || kjonnByCode.default;
}


export function sanitizeHtmlText(html) {
    return html && sanitizeHtml(html, {
        allowedTags: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6',
            'hr', 'a', 'p', 'span', 'br',
            'b', 'i', 'em', 'strong',],
        allowedAttributes: {
            'a': ['href', 'target'], //TODO: se på muligheter for å sikre at rel attributtet settes til "noopener noreferrer" hvis target="_blank"
            'p': ['style'],
            'span': ['style'],
        }
    })
}

export function removeHtml(html) {
    return html && sanitizeHtml(html, {
        allowedTags: [],
        allowedAttributes: {}
    })
}

export function getPosition(element) {
    let xPosition = 0;
    let yPosition = 0;

    while (element) {
        xPosition += (element.offsetLeft - element.scrollLeft + element.clientLeft);
        yPosition += (element.offsetTop - element.scrollTop + element.clientTop);
        element = element.offsetParent;
    }

    return {x: xPosition, y: yPosition};
}

export function getScrollPosition() {
    const doc = document.documentElement;
    const scrollLeft = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
    const scrollTop = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    return {
        scrollLeft,
        scrollTop,
    }
}

export function getTotalTextLengthInArray(textArray) {
    if (!textArray) {
        return 0;
    }
    const sumReducer = (accumulator, currentValue) => accumulator + currentValue.length;
    return textArray.reduce(sumReducer, 0)
}

export const fristHarUtgatt = frist => Date.now() > frist;

export const nullToUndefined = value => value !== null ? value : undefined;

export const scrollToTopOfPage = () => window.scrollTo(0, 0);

export const getPathToPameldingForKurs = kursId => `${pages.pamelding}/${kursIdToUrlString(kursId)}`;
export const getPathToSteg = (kursId, stegType) => `${getPathToPameldingForKurs(kursId)}?steg=${stegType}`;

export const sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
};


export const getFirstOf = array =>
    Boolean(array) && array.length > 0 ? array[0] : undefined;