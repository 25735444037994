import {MAX_FILE_SIZE_MB} from "../../../utils/common";
import React from "react";
import AllowedFiletypes from "../../AllowedFiletypes";
import Text from "../../../primitives/Text";

const InfoFilopplasting = () => {
    return (
        <div>
            <p><AllowedFiletypes/></p>
            <p><Text
                id='maksimal_filstørrelse_er'
                values={{maksStorrelse: MAX_FILE_SIZE_MB, enhet: 'MB'}}/>
            </p>
        </div>
    )
};

export default InfoFilopplasting;