import axios from "axios/index";
import {
    getEvuapiToken,
    getIsApiTokenFetchedAndActive,
    getIsFetchingApiToken,
    skrivingSkalBlokkeres
} from "../ducks/session";
import {createCustomResponseError} from "../utils/request";
import {
    API_INFO,
    APPEGENSKAPER,
    APPTEKSTER,
    ENV_EVUAPI,
    INSTITUSJONER,
    LAND,
    LANDSKODER,
    OPPRETT_BRUKER,
    SYNC_DSF,
    TOKEN_ISSUER
} from "../services/apiRoutes";
import {getRequestHeaders} from "../requestUtils";
import delayUntilTokenIsFetched from "../delayUntilTokenIsFetched";
import {fetchApiToken} from "../token";
import {removeQueryParams} from "../utils/urlUtils";

const isGet = method => method === 'get';

const SKRIVERETTIGHETT_IKKE_AKTIVERT = 'SKRIVERETTIGHETT_IKKE_AKTIVERT';

const API_ROUTES_WITHOUT_AUTHORIZATION = [
    removeQueryParams(LANDSKODER),
    removeQueryParams(APPEGENSKAPER),
    removeQueryParams(APPTEKSTER),
    removeQueryParams(LAND),
    removeQueryParams(INSTITUSJONER),
    removeQueryParams(API_INFO),
];

const API_ROUTES_BEHIND_DATAPORTEN = [
    removeQueryParams(OPPRETT_BRUKER),
    removeQueryParams(SYNC_DSF),
];

const API_ROUTE_KURS_BASE_URL =  ENV_EVUAPI + '/kurs';

export function applyAxiosInterceptor(getState, dispatch) {

    axios.interceptors.request.use(async config => {
        if (isRouteWithoutAuthorization(config.url)
            || isRoutesBehindGatekeeper(config.url)
            || isTokenApiRoute(config.url)) {
            return config;
        }

        return getConfigWithAuthorizationHeader(config, getState, dispatch);

    });

    axios.interceptors.request.use(config => {
        const {method} = config;
        if (!isGet(method) && skrivingSkalBlokkeres(getState())) {
            return {
                type: SKRIVERETTIGHETT_IKKE_AKTIVERT,
                headers: {},
                url: "",
                method,
            };
        }
        return config;
    });

    axios.interceptors.response.use(response => {
        const {config} = response;
        if (config.type === SKRIVERETTIGHETT_IKKE_AKTIVERT) {
            return Promise.reject(createCustomResponseError('9999999'));
        }
        return Promise.resolve(response)
    });
}

const isTokenApiRoute = url => {
    const urlWithoutParams = removeQueryParams(url);
    const tokenApiRouteWithoutParams = removeQueryParams(TOKEN_ISSUER);
    return urlWithoutParams === tokenApiRouteWithoutParams;
}

const isRouteWithoutAuthorization = url => {
    const urlWithoutParams = removeQueryParams(url);
    const isKurs = urlWithoutParams.startsWith(API_ROUTE_KURS_BASE_URL)
    return (API_ROUTES_WITHOUT_AUTHORIZATION.filter(route => route === urlWithoutParams).length > 0 || isKurs);
}

const isRoutesBehindGatekeeper = url => {
    const urlWithoutParams = removeQueryParams(url);
    return API_ROUTES_BEHIND_DATAPORTEN.filter(route => route === urlWithoutParams).length > 0;
}

const getConfigWithAuthorizationHeader = async (config, getState, dispatch) => {
    const isAPiTokenActive = getIsApiTokenFetchedAndActive(getState());
    const isFetchingApiToken = getIsFetchingApiToken(getState());

    if (isAPiTokenActive) {
        return addAuthorizationHeader(config, getState);
    }

    if (isFetchingApiToken) {
        await delayUntilTokenIsFetched(getState);
    } else {
        await dispatch(fetchApiToken());
    }

    return addAuthorizationHeader(config, getState);
}

const addAuthorizationHeader = (config, getState) => {
    const apiToken = getEvuapiToken(getState());
    return {
        ...config,
        headers: getRequestHeaders(apiToken)
    };
}