import {useSelector} from "react-redux";
import React from "react";
import {getErrorSyncMedKorr, isSyncingMedKorr, syncMedKorr, isSyncSuccess as isSyncSuccessSelector} from "../../../ducks/profil";
import {HentPaNyttButtonMedFeedback} from "./HentPaNyttButtonMedFeedback";

export const SyncMedKorrForProfil = () => {

    const isSyncing = useSelector(isSyncingMedKorr);
    const isSyncSuccess = useSelector(isSyncSuccessSelector);
    const errorSync = useSelector(getErrorSyncMedKorr);

    return <HentPaNyttButtonMedFeedback
        isLoading={isSyncing}
        isDataLoaded={isSyncSuccess}
        error={errorSync}
        onClickAction={syncMedKorr()}
    />;

};