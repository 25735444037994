import React from 'react';
import {antallVarseltyper as antallVarseltyperSelector, getAntallSoknader} from "../../../ducks/soknader";
import {useSelector} from "react-redux";
import Card from "../Card";
import {soknaderIconPath} from "../../../utils/icons";
import {pages} from "../../../utils/common";
import withSoknaderLoaded from "../../../high-order-components/withSoknaderLoaded";
import {compose} from "redux";
import {requireDeltakernummer} from "../../../high-order-components/requireDeltakernummer";
import {isKurskatalogAktivert as isKurskatalogAktivertSelector} from "../../../ducks/appegenskaper";
import Text from "../../../primitives/Text";

const VarselDeltakerDefault = () => {
    const antallVarseltyper = useSelector(antallVarseltyperSelector);
    const antallSoknader = useSelector(getAntallSoknader);
    const isKurskatalogAktivert = useSelector(isKurskatalogAktivertSelector);
    if (antallVarseltyper > 0) return null;
    const lenke = antallSoknader === 0 && isKurskatalogAktivert ? {
        tekst: <Text id='gå_til_kurskatalog'/>,
        til: pages.kurs
    } : {
        tekst: <Text id='gå_til_søknader'/>,
        til: pages.soknader
    };
    return (
        <Card
            tittel={<Text id='dine_søknader'/>}
            varsel={false}
            iconPath={soknaderIconPath}
            actions={{lenke}}
        >
            {antallSoknader > 0 ?
                <Text
                    id='du_har_søknader_under_behandling'
                    values={{antallSoknader: antallSoknader}}
                />
                :
                <Text
                    id={isKurskatalogAktivert ? 'du_har_ingen_søknader_under_behandling_kurskatalog' : 'du_har_ingen_søknader_under_behandling'}
                />
            }
        </Card>
    )
};

export default compose(
    Component => requireDeltakernummer(Component, false),
    withSoknaderLoaded,
)(VarselDeltakerDefault);