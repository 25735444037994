import * as routes from "./apiRoutes";
import {kursIdStringToPathVariable} from "../utils/common";
import axios from "axios";
import {createRequestConfig} from "../utils/request";

export function getSoknadsdokumenter(evuapiToken, deltakernr, kursId) {

    const route = routes.SOKNADSDOKUMENTER
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursIdStringToPathVariable(kursId));

    const config = createRequestConfig(evuapiToken);

    return axios.get(route, config)
        .then(response => {
            return transformSoknadsdokumenterFromWS(response.data);
        })
        .catch(error => {
            throw error;
        });
}

function transformSoknadsdokumenterFromWS(data) {
    return data ? data.map(dok => (
        {
            id: dok.dokumentnr,
            sperreSletting: dok.sletting_sperret,
        }
    )) : [];
}

export function leggDokumentTilSoknad(evuapiToken, deltakernr, kursId, dokumentId) {

    const route = routes.SOKNADSDOKUMENTER
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursIdStringToPathVariable(kursId));

    const config = createRequestConfig(evuapiToken);

    const payload = {
        "dokumentnr": dokumentId
    };

    return axios.post(route, payload, config)
        .catch(error => {
            throw error;
        });

}


export function fjernDokumentFraSoknad(evuapiToken, deltakernr, kursId, dokumentId) {

    const route = routes.SOKNADSDOKUMENT
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursIdStringToPathVariable(kursId))
        .replace(':dokumentid', dokumentId);

    const config = createRequestConfig(evuapiToken);

    return axios.delete(route, config)
        .catch(error => {
            throw error;
        });
}