import React from 'react';
import Input from "../../../primitives/Input";
import Error from "../../Error";
import {MAX_LENGDE_NORSK_TELEFON, MAX_LENGDE_UTELANDSK_TELEFON_MED_KODE} from "../../../utils/common";
import PropTypes from "prop-types";
import Text from "../../../primitives/Text";

const InputTelefonNummer = ({nummer, erNyTelefon, onChange, errorMessageId, typingDone = true, isFocusOn}) => {

    const showError = shouldShowError(errorMessageId, typingDone, nummer);

    return (
        <>
            <Input
                conditionForFocus={isFocusOn}
                label='nummer'
                value={nummer}
                hasError={showError}
                className={`edit-telefon ${erNyTelefon && 'ny'}`}
                onChange={onChange}
                type='tel'
                autoComplete='tel-national'
            />
            {showError &&
            <Error>
                <Text
                    id={errorMessageId}
                    values={{
                        maxLengdeNorskNummer: MAX_LENGDE_NORSK_TELEFON,
                        maxLengdeUtenlandskNummer: MAX_LENGDE_UTELANDSK_TELEFON_MED_KODE,
                    }}
                />
            </Error>
            }
        </>
    );
};


const shouldShowError = (errorMessageId, typingDone) => {

    if (!errorMessageId) {
        return false;
    }

    if (typingDone) {
        return true;
    }

    const errorsToHideBeforeTypingIsDone = [
        'mobilnummer_obligatorisk',
        'norsk_nummer_skal_være_nøyaktig_8_tegn'
    ];
    return !(errorsToHideBeforeTypingIsDone.includes(errorMessageId));
};


InputTelefonNummer.propTypes = {
    isFocusOn: PropTypes.bool,
    nummer: PropTypes.string.isRequired,
    erNyTelefon: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    errorMessageId: PropTypes.string,
    typingDone: PropTypes.bool,
};

export default InputTelefonNummer;