import React from 'react';
import {useSelector} from "react-redux";
import HTMLReactParser from "html-react-parser";
import {getTranslatedPlaninfoData} from '../../ducks/planinfo'
import PlaninfoBekreftelse from './PlaninfoBekreftelse'
import './planinfo.scss'
import {ExternalLink} from "../../primitives/Link";
import {compose} from "redux";
import withSoknadsdokumenter from "../../high-order-components/withSoknadsdokumenter";
import withPlaninfoLoaded from "../../high-order-components/withPlaninfoLoaded";

export const Planinfo = () => {
    const planinfo = useSelector(getTranslatedPlaninfoData);
    return (
        <div className='planinfo'>
            {planinfo.map(({
                               kode,
                               merknadtekst,
                               merknadtekstLang,
                               url,
                               urlLang,
                               status_bekreft,
                           }) =>
                <div className="planinfo__element" key={kode}>
                    {merknadtekst &&
                    <p className="planinfo__element__merknadtekst"
                       lang={merknadtekstLang}
                    >
                        {HTMLReactParser(merknadtekst)}
                    </p>
                    }
                    {url &&
                    <p className='planinfo__element__link' lang={urlLang}>
                        <ExternalLink
                            href={url}>{url}
                        </ExternalLink>
                    </p>
                    }
                    {status_bekreft && <PlaninfoBekreftelse kode={kode}/>}
                    <hr/>
                </div>
            )}
        </div>
    )
};

export default compose(
    withSoknadsdokumenter,
    withPlaninfoLoaded,
)(Planinfo);
