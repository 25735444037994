import React from 'react';
import CardActions from "./CardActions";
import PropTypes from "prop-types";
import './card.scss';
import Icon from "../../primitives/Icon";
import {mdiAlertCircleOutline} from "@mdi/js";

const Card = ({iconPath, varsel = false, children, actions, tittel}) => {
    return (
        <div className="card">
            <div className="card-header">
                {iconPath &&
                    <div className="card-header__icon">
                        <Icon path={iconPath}/>
                    </div>
                }
                <h2 className="card-header__title">
                    {tittel}
                </h2>
            </div>
            <div className="card-content">
                {varsel &&
                    <div className='card-content__icon'>
                        <Icon
                            path={mdiAlertCircleOutline}
                            className='varsel'
                        />
                    </div>
                }
                <div className='card-content__text'>
                    {children}
                </div>
            </div>
            {actions && <CardActions actions={actions}/>}
        </div>
    );
};


Card.propTypes = {
    tittel: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]).isRequired,
    actions: PropTypes.any,
    varsel: PropTypes.bool,
    Icon: PropTypes.object,
};


export default Card;