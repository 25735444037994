import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getEditIsSaving,
    getEditIsVedvarende,
    getEditFraDato,
    getEditTilDato,
    setEditFieldValue, getEditHarPeriode, getEditWasSaveClicked, getIsEditFraDatoStorreEnnTilDato
} from "../../../ducks/arbeidserfaring";
import Input from "../../../primitives/Input";
import Checkbox from "../../../primitives/Checkbox";
import Text from "../../../primitives/Text";
import Error from "../../Error";
import {isTextValidISODate} from "../../../utils/dateUtil";


const InputPeriode = () => {
    const dispatch = useDispatch();
    const fraDato = useSelector(getEditFraDato);
    const tilDato = useSelector(getEditTilDato);
    const isSaving = useSelector(getEditIsSaving);
    const isVedvarende = useSelector(getEditIsVedvarende);
    const saveWasClicked = useSelector(getEditWasSaveClicked);
    const harPeriode = useSelector(getEditHarPeriode);
    const isEditFraDatoStorreEnnTilDato = useSelector(getIsEditFraDatoStorreEnnTilDato);
    const hasError = saveWasClicked && (isEditFraDatoStorreEnnTilDato || !harPeriode);
    const errorTextId = !harPeriode ? 'vennligst_legg_inn_fra_og_til_dato' : 'fra_dato_må_være_før_til_dato';
    const showErrorFormatFraDato = saveWasClicked && !isTextValidISODate(fraDato);
    const showErrorFormatTilDato = saveWasClicked && !isTextValidISODate(tilDato);
    const updateFra = e => dispatch(setEditFieldValue({
        field: 'fraDato',
        value: e.target.value,
    }));
    const updateTil = e => dispatch(setEditFieldValue({
        field: 'tilDato',
        value: e.target.value,
    }));
    const toggleVedvarende = () => dispatch(setEditFieldValue({
        field: 'isVedvarende',
        value: !isVedvarende,
    }));
    return (
        <div className={'periode'}>
            <div>
                <Input
                    label={'fra'}
                    onChange={updateFra}
                    value={fraDato}
                    type='date'
                    disabled={isSaving}
                    hasError={hasError || showErrorFormatFraDato}
                />
                {showErrorFormatFraDato &&
                <Error>
                    <Text id='feil_datoformat'/>
                </Error>
                }
            </div>
            <div>
                <Input
                    label={'til'}
                    onChange={updateTil}
                    value={tilDato}
                    type='date'
                    disabled={isSaving || isVedvarende}
                    hasError={hasError || showErrorFormatTilDato}
                />
                {showErrorFormatTilDato &&
                <Error>
                    <Text id='feil_datoformat'/>
                </Error>
                }
            </div>
            <div>
                <Checkbox
                    label={<Text id='nåværende_arbeidsplass' />}
                    checked={isVedvarende}
                    onChange={toggleVedvarende}
                    disabled={isSaving}
                />
            </div>
            {hasError &&
            <Error>
                <Text id={errorTextId}/>
            </Error>
            }
        </div>
    )
};

export default InputPeriode;