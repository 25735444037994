import React, {useEffect} from 'react';
import {getInstitusjon, setRedirectAfterSelectedInstitution} from "../ducks/session";
import {useDispatch, useSelector} from "react-redux";
import {pages} from "../utils/common";
import queryString from "query-string";
import {getInstitusjonByAkronym} from "../ducks/institusjoner";
import LoggerPaSpinner from "../components/login/LoggerPaSpinner";
import {isSameInstitusjon} from "../utils/institusjonerUtil";
import {useLocation, useNavigate} from "react-router";

const requireInstitusjonSelected = Component => props => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const params = queryString.parse(location.search);
    const pathInst = useSelector(state => getInstitusjonByAkronym(state, params.inst));
    const sessionInst = useSelector(getInstitusjon);

    if (sessionInstByttesTilPathInst(pathInst, sessionInst)) {
        return <LoggerPaSpinner />
    }

    useEffect(() =>{
        if (!sessionInst) {
            dispatch(setRedirectAfterSelectedInstitution(location.pathname));
            navigate(pages.institusjon);
            return null;
        }
    })

    return <Component {...props} />
};

const sessionInstByttesTilPathInst = (pathInst, sessionInst) => {
    return pathInst && !isSameInstitusjon(pathInst, sessionInst);
};

export default requireInstitusjonSelected;