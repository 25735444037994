import queryString from "query-string";
import {getInstitusjonByAkronym} from "../ducks/institusjoner";
import {useDispatch, useSelector} from "react-redux";
import {getInstitusjon, setInstitusjon} from "../ducks/session";
import {useEffect} from "react";
import {isSameInstitusjon} from "../utils/institusjonerUtil";
import {useLocation} from "react-router";

const useInstFromLocation = () => {
    const location = useLocation();
    const params = queryString.parse(location.search);
    const pathInst = useSelector(state => getInstitusjonByAkronym(state, params.inst));
    const sessionInst = useSelector(getInstitusjon);
    const dispatch = useDispatch();
    useEffect(() => {
        if (pathInst && !isSameInstitusjon(pathInst, sessionInst)) {
            dispatch(setInstitusjon(pathInst));
        }
    }, [pathInst, sessionInst, dispatch]);
};

export default useInstFromLocation;