import {useDispatch, useSelector} from "react-redux";
import {showPopup} from "../ducks/popup";
import React from "react";
import {getIsDeleted, getIsUnderDeletion, slettDokument} from "../ducks/dokumenter";
import Text from "../primitives/Text";

const useDeleteDocument = (id, filnavn) => {

    const dispatch = useDispatch();
    const isUnderDeletion = useSelector(state => getIsUnderDeletion(state, id));
    const isDeleted = useSelector(state => getIsDeleted(state, id));

    const deleteDocument = () => {
        const popUpContent = {
            onOK: () => dispatch(slettDokument(id)),
            title: <Text id='slette_dokument'/>,
            children: <Text
                id='er_du_sikker_på_at_du_vil_slette_fil'
                values={{filnavn: <em>{filnavn}</em>}}
            />,
            okButtonContent: <Text id='ja'/>,
            cancelButtonContent: <Text id='nei'/>,
        };
        dispatch(showPopup(popUpContent));
    };


    return [deleteDocument, isUnderDeletion, isDeleted];
};

export default useDeleteDocument;