import {ReadMoreText} from "../../ReadMoreText";
import {ExternalLink} from "../../../primitives/Link";
import React from "react";
import './opptakskrav.scss';
import {useSelector} from "react-redux";
import {getMerknadskrav, getNettsideKrav} from "../../../ducks/kursinfo";
import Text from "../../../primitives/Text";

const Oppratkskrav = ({withTitle = true}) => {

    const merknadKrav = useSelector(getMerknadskrav);
    const nettsideKrav = useSelector(getNettsideKrav);

    return (
        <div className='opptakskrav'>
            {withTitle && <div>
                <Text id='last_opp_dokumentasjon_for_å_dokumentere'/>
                {(merknadKrav || nettsideKrav) ? ':' : '.'}
            </div>}

            {merknadKrav &&
            <div className='merknad-krav'>
                <span className='punkt'>•</span>
                <ReadMoreText textLength={merknadKrav.length}>
                    {merknadKrav}
                </ReadMoreText>
            </div>
            }
            {nettsideKrav &&
            <div className='nettside-krav'>
                <span className='punkt'>•</span>
                <ExternalLink href={nettsideKrav}>
                    <Text id='informasjon_om_opptakskrav'/>
                </ExternalLink>
            </div>
            }
        </div>
    )
};

export default Oppratkskrav;