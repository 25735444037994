import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {mdiHelp} from "@mdi/js";
import {showPopup} from "../../ducks/popup";
import Button from "../../primitives/Button";
import {getPersoninfoHentetFra} from "../../ducks/nyBruker";
import {useIntl} from "react-intl";
import Text from "../../primitives/Text";


export const HelpButton = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const kilde = useSelector(getPersoninfoHentetFra);
    const kildeTranslated = intl.formatMessage({id: kilde});
    return (
        <Button onClick={() => dispatch(showPopup(getPopupContent(kildeTranslated)))}
                iconPath={mdiHelp}
                altText='trykk_for_mer_info'
                className='help-button'
        />
    )
};

export const getPopupContent = (kilde) => {
    return {
        title: <Text id='informasjon'/>,
        children: <div>
            <Text id='personinfo_hentet_fra_kilde' values={{kilde}}/>
        </div>,
    };
};

export default HelpButton;