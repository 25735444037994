import React from 'react';
import {loadSamtykker, isDataLoaded, isLoading, getError} from "../ducks/samtykke";
import InitialLoadWrapper from "../wrappers/InitialLoadWrapper";
import {useSelector} from "react-redux";
import {getRequestErrorCode} from "../utils/request";
import Feedback from "../components/Feedback";
import Text from "../primitives/Text";

const withSamtykkerLoaded = Component => props => {
    const error = useSelector(getError);
    if(getRequestErrorCode(error) === 404000){
        return (
            <Feedback>
                <Text id='ingen_samtykker_tilgjengelig' />
            </Feedback>
        )
    }
    return (
        <InitialLoadWrapper
            loadAction={loadSamtykker}
            loadingSelector={isLoading}
            loadedSelector={isDataLoaded}
            errorSelector={getError}
            loadMessage={<Text id='laster_inn_samtykker'/>}
        >
            <Component {...props} />
        </InitialLoadWrapper>
    )
};

export default withSamtykkerLoaded;