import {containsOnlyDigits} from "./common";
import {getRequestErrorTextId} from "./request";

export const ORGANISASJONSNUMMER_LENGDE = 9;

export const getOrganisasjonsErrorMessageId = (organisasjonsnummer, focused, apiError) => {

    if (!organisasjonsnummer)
        return undefined;

    if (!containsOnlyDigits(organisasjonsnummer))
        return 'organisasjonsnummer_kan_kun_inneholde_tall';

    if (!focused && organisasjonsnummer.length < ORGANISASJONSNUMMER_LENGDE)
        return 'organisasjonsnummer_skal_være_nøyaktig_9_tegn';

    if (apiError) {
        return getRequestErrorTextId(apiError);
    }

    return undefined;
};


export const isOrganisasjonsnummerValid = organisasjonsnummer => {
    return organisasjonsnummer && organisasjonsnummer.length===ORGANISASJONSNUMMER_LENGDE && containsOnlyDigits(organisasjonsnummer);
};