import React from 'react'
import withSoknadsdokumenter from "../../high-order-components/withSoknadsdokumenter";
import {geHarStegEksamen} from "../../ducks/pamelding";
import {useSelector} from "react-redux";
import Feedback from "../Feedback";
import './steg-dokumentasjon.scss';
import {getKurs} from "../../ducks/kursinfo";
import withArbeidserfaringLoaded from "../../high-order-components/withArbeidserfaringLoaded";
import GenerellStudiekompetanse from "./GenerellStudiekompetanse";
import HoyereUtdanning from "./HoyereUtdanning";
import Annet from "./Annet";
import ArbeidserfaringKategori from "./ArbeidserfaringKategori";
import {compose} from "redux";
import withDokumenterLoaded from "../../high-order-components/withDokumenterLoaded";
import FileTypesInfoButton from "./opptakskrav/MerInfoButton";
import Oppratkskrav from "./opptakskrav/Opptakskrav";
import Kursmangler from "./kursmangler/Kursmangler";
import {getKursmanglerForValgtKurs} from "../../ducks/kursmangler";
import withKursmanglerLoaded from "../../high-order-components/withKursmanglerLoaded";
import MerInfoButton from "./kursmangler/MerInfoButton";
import {getSkalTaEksamen} from "../../ducks/eksamen";
import Text from "../../primitives/Text";


export const StegDokumentasjon = () => {

    const skalTaEksamen = useSelector(getSkalTaEksamen);
    const harStegEksamen = useSelector(state => geHarStegEksamen(state));
    const {harGSKKrav, harKravOmHoyereUtd, harKravOmPraksis, harAndreKrav} = useSelector(getKurs);
    const kursmangler = useSelector(getKursmanglerForValgtKurs);

    if (!skalTaEksamen && harStegEksamen) {
        return <DokumentasjonIkkeNodvendig/>
    }

    return (
        <div className='soknadsdokumenter'>
            {kursmangler.length?
                <div className='kursmangler-wrapper'>
                    <Kursmangler kursmangler={kursmangler}/>
                    <MerInfoButton/>
                </div>
                :
                <div className='opptakskrav-wrapper'>
                    <Oppratkskrav/>
                    <FileTypesInfoButton/>
                </div>
            }
            {harGSKKrav && <GenerellStudiekompetanse/>}
            {harKravOmHoyereUtd && <HoyereUtdanning/>}
            {harKravOmPraksis && <ArbeidserfaringKategori/>}
            {harAndreKrav && <Annet/>}
        </div>
    );
};


const DokumentasjonIkkeNodvendig = () =>
    <Feedback className='steg-ikke-nodvendig'>
        <h3>
            <Text id='dokumentasjon_ikke_nødvendig'/>
        </h3>
        <p>
            <Text id='ikke_behov_for_dokumentasjon_uten_eksamen'/>
        </p>
    </Feedback>
;


export default compose(
    withSoknadsdokumenter,
    withDokumenterLoaded,
    withArbeidserfaringLoaded,
    withKursmanglerLoaded
)(StegDokumentasjon);