import {injectIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {getIsSoknadstilknytningLoading, toggleArbeidserfaringSoknadstilknytning} from "../../../ducks/arbeidserfaring";
import Text from "../../../primitives/Text";
import {showPopup} from "../../../ducks/popup";
import Button from "../../../primitives/Button";
import React, {useRef, useState} from "react";
import ArbeidserfaringListItem from "../../arbeidserfaring/ArbeidserfaringListItem";
import ArbeidserfaringPresentation from "../../arbeidserfaring/ArbeidserfaringPresentation";
import {mdiCloseCircle} from "@mdi/js";
import useMoveFocusToNextElementDocumentation from "../../../hooks/useMoveFocusToNextElementDocumentation";

const TilknyttetArbeidserfaring = ({arbeidserfaring}) => {
    const {id, arbeidsgiver} = arbeidserfaring;
    return (
        <ArbeidserfaringListItem className='tilknyttet'>
            <ArbeidserfaringPresentation
                arbeidserfaring={arbeidserfaring}
                withEndreButton
            />
            <div className='arbeidserfaring__actions'>
                <RemoveButton id={id} arbeidsgiver={arbeidsgiver}/>
            </div>
        </ArbeidserfaringListItem>
    )
};


const RemoveButton = injectIntl(({intl, id}) => {
    const isTilknytningLoading = useSelector(state => getIsSoknadstilknytningLoading(state, id));
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [isFjernerTilknytning, setIsFjernerTilknytning] = useState(false);

    useMoveFocusToNextElementDocumentation(ref, isFjernerTilknytning, 'button');

    const slettOnClick = () => {
        const popUpContent = {
            onOK: () => {dispatch(toggleArbeidserfaringSoknadstilknytning(id)); setIsFjernerTilknytning(true)},
            title: <Text id='fjerne_tilknytning'/>,
            children: <Text id='er_du_sikker_på_at_du_vil_fjerne_tilknytningen'/>,
            okButtonContent: <Text id='ja'/>,
            cancelButtonContent: <Text id='nei'/>,
        };
        dispatch(showPopup(popUpContent));
    };

    return (
        <div ref={ref}>
            <Button
                onClick={slettOnClick}
                iconPath={mdiCloseCircle}
                isInProgress={isTilknytningLoading}
                className='fjern-tilknytning-button'
                altText='fjern_tilknytning'
            />
        </div>
    )
});


export default TilknyttetArbeidserfaring;