import * as routes from "./apiRoutes";
import axios from "axios";
import {kursIdToUrlString, nullToUndefined, parseIsoDate,} from "../utils/common";
import {
    ACTION_TYPE_ENDRE_FAKTURAINFO, ACTION_TYPE_TREKKE,
    actionToFristTypes,
    FRIST_UBEGRENSET,
    getActionsFromStatus,
    STATUS_SOKNAD_REGISTRERT, STATUS_TILBUD_AKSEPTERT, STATUS_TILBUD_AVSLATT
} from "../utils/soknadStatus";
import {createRequestConfig} from "../utils/request";

export const getSoknader = async (deltakernr, evuapiToken) => {

    const route = routes.SOKNADER.replace(':deltakernr', deltakernr);

    const config = createRequestConfig(evuapiToken);

    const response = await axios.get(route, config);
    return transformOutput(response.data);

};


function transformOutput(data) {
    return data.map(soknad => transformSoknadFromWS(soknad));
}

function transformSoknadFromWS(soknad) {
    return {
        id: transformIdFromWS(soknad),
        antallLedigePlasser: nullToUndefined(soknad.antallLedigePlasser),
        status: soknad.status,
        kursnavn: soknad.kursnavn,
        datoFra: parseIsoDate(soknad.datoFra),
        datoTil: parseIsoDate(soknad.datoTil),
        actions: transformActionsFromStatus(soknad),
        postadresse: nullToUndefined(soknad.adrlin3Faktura),
        manglerBetalingsinfo: soknad.manglerBetalingsinfo,
        svarPaTilbudOriginal: getSvarPaTilbudFraStatus(soknad.status),
        skalTaEksamen: soknad.statusEksamen,
    }
}

function transformIdFromWS(soknad) {
    return {
        kurskode: soknad.kurskode,
        tidskode: soknad.tidskode,
    }
}
function getSvarPaTilbudFraStatus(status) {
    if (status === STATUS_TILBUD_AVSLATT) {
        return false;
    }
    if (status === STATUS_TILBUD_AKSEPTERT) {
        return true;
    }
    return undefined;
}

function transformActionsFromStatus(soknad) {

    const actions = [
        soknad.manglerBetalingsinfo && ACTION_TYPE_ENDRE_FAKTURAINFO,
        ...getActionsFromStatus(soknad.status),
    ].filter(Boolean);

    return actions.map(action => {
        return {
            type: action,
            frist: getFristForAction(action, soknad)
        }
    })
}

export const getFristForAction = (action, soknad) => {

    const fristType = actionToFristTypes[action];
    if (!fristType) return FRIST_UBEGRENSET;

    const fristObj = soknad.frister.find(soknadsfrist => soknadsfrist.type === fristType);
    const frist = fristObj && fristObj.dato && parseIsoDate(fristObj.dato);

    if (!frist && soknad.status === STATUS_SOKNAD_REGISTRERT
        && action === ACTION_TYPE_TREKKE) {
        return  FRIST_UBEGRENSET;
    }

    return frist;
};

export function sendSvarPaTilbud(evuapiToken, deltakernr, kursId, value) {

    const route = routes.SVAR_PA_TILBUD
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursId);
    const config = createRequestConfig(evuapiToken);

    const payload = {
        svar: Boolean(value)
    };

    return axios.post(route, payload, config);
}

export function trekkSoknad(evuapiToken, deltakernr, kursId) {

    const route = routes.TREKK_SOKNAD
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursIdToUrlString(kursId));

    const config = createRequestConfig(evuapiToken);

    return axios.post(route, undefined, config);
}

export function angreTrekkSoknad(evuapiToken, deltakernr, kursId) {

    const route = routes.ANGRE_TREKK_SOKNAD
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursIdToUrlString(kursId));

    const config = createRequestConfig(evuapiToken);

    return axios.post(route, undefined, config);
}


export function slettSoknad(evuapiToken, deltakernr, kursId) {

    const route = routes.SOKNAD
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursIdToUrlString(kursId));

    const config = createRequestConfig(evuapiToken);

    return axios.delete(route, config);

}