import React from "react";
import {Link} from "react-router-dom";
import {pages} from "../../utils/common";
import Text from "../../primitives/Text";
import {mdiHome} from "@mdi/js";
import Icon from "../../primitives/Icon";

const MenubarMinSide = () => {
    return (
        <div id='min-side' className='menubar-button'>
            <Link to={pages.minSide}>
                <Icon path={mdiHome}/>
                <Text id="min_side"/>
            </Link>
        </div>
    )
};

export default MenubarMinSide;