import React from 'react';
import Checkbox from "../../../primitives/Checkbox";
import Filter from "./Filter";
import {useDispatch, useSelector} from "react-redux";
import {
    getNettbasertFilter,
    getSamlingFilter,
    getUndervisningsCounter,
    toggleNettFilter,
    toggleSamlingFilter
} from "../../../ducks/kursoversikt";
import {injectIntl} from "react-intl";

const FilterUndervisning = ({intl}) => {
    const nettbasertFilter = useSelector(getNettbasertFilter);
    const samlingbasertFilter = useSelector(getSamlingFilter);
    const undervisningsCounter = useSelector(getUndervisningsCounter);
    const dispatch = useDispatch();
    return (
        <Filter
            labelTextId={`undervisning`}
        >
            <Checkbox
                label={intl.formatMessage({id: 'nettbasert_counter'}, {counter: undervisningsCounter.erNettbasert})}
                onChange={()=>dispatch(toggleNettFilter())}
                checked={nettbasertFilter}
            />
            <Checkbox
                label={intl.formatMessage({id: 'samlingsbasert_counter'}, {counter: undervisningsCounter.erSamlingsbasert})}
                onChange={()=>dispatch(toggleSamlingFilter())}
                checked={samlingbasertFilter}
            />
        </Filter>
    )
};

export default injectIntl(FilterUndervisning);
