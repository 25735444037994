import {getAktueltDeltakernr, getEvuapiToken} from "./session";

import * as sporreskjemaAPI from "../services/sporreskjemaAPI";
import {getLocale, getTranslationLang} from "./i18n";
import {MAX_LENGDE_FRITEKST_SPORSMAL, getValueByLanguage} from "../utils/common";
import {getKursId as getValgtKursId} from "./kursinfo";

export const QUESTION_TYPE_FREETEXT = 'QUESTION_TYPE_FREETEXT';
export const QUESTION_TYPE_POLAR = 'QUESTION_TYPE_POLAR'; // yes/ no question

export const NAME = 'sporreskjema';
export const LOAD_SPORRESKJEMA_PENDING = `${NAME}/LOAD_SPORRESKJEMA_PENDING`;
export const LOAD_SPORRESKJEMA_SUCCESS = `${NAME}/LOAD_SPORRESKJEMA_SUCCESS`;
export const LOAD_SPORRESKJEMA_FAILURE = `${NAME}/LOAD_SPORRESKJEMA_FAILURE`;

export const SEND_SPORRESKJEMA_PENDING = `${NAME}/SEND_SPORRESKJEMA_PENDING`;
export const SEND_SPORRESKJEMA_SUCCESS = `${NAME}/SEND_SPORRESKJEMA_SUCCESS`;
export const SEND_SPORRESKJEMA_FAILURE = `${NAME}/SEND_SPORRESKJEMA_FAILURE`;

export const SET_TEXT_RESPONSE = `${NAME}/SET_TEXT_RESPONSE`;
export const SET_POLAR_RESPONSE = `${NAME}/SET_POLAR_RESPONSE`;


export function loadSporreskjemaPending(kursId) {
    return {
        type: LOAD_SPORRESKJEMA_PENDING,
        kursId: kursId,
    };
}

export function loadSporreskjemaSuccess(sporreskjema) {
    return {
        type: LOAD_SPORRESKJEMA_SUCCESS,
        data: sporreskjema,
    };
}

export function loadSporreskjemaFailure(error) {
    return {
        type: LOAD_SPORRESKJEMA_FAILURE,
        error: error,
    };
}

export function loadSporreskjema() {
    return function (dispatch, getState) {
        const state = getState();

        if (isLoading(state)){
            return;
        }

        const evuapiToken = getEvuapiToken(state);
        const deltakernr = getAktueltDeltakernr(state);
        const kursId = getValgtKursId(state);
        dispatch(loadSporreskjemaPending(kursId));
        return sporreskjemaAPI.getSporreskjema(evuapiToken, deltakernr, kursId).then(
            sporreskjema => dispatch(loadSporreskjemaSuccess(sporreskjema)),
            error => dispatch(loadSporreskjemaFailure(error))
        );
    };
}

export function sendSporreskjemaPending(index) {
    return {
        type: SEND_SPORRESKJEMA_PENDING,
        index: index,
    };
}

export function sendSporreskjemaSuccess(index) {
    return {
        type: SEND_SPORRESKJEMA_SUCCESS,
        index: index,
    };
}

export function sendSporreskjemaFailure(index, error) {
    return {
        type: SEND_SPORRESKJEMA_FAILURE,
        error: error,
        index: index,
    };
}

export function sendSporreskjema(index) {
    return function (dispatch, getState) {
        dispatch(sendSporreskjemaPending(index));
        const evuapiToken = getEvuapiToken(getState());
        const deltakernr = getAktueltDeltakernr(getState());
        const sporsmal = getSporsmalFromIndex(getState(), index);
        const kursId = getValgtKursId(getState());
        return sporreskjemaAPI.postSporreskjema(evuapiToken, deltakernr, kursId, sporsmal).then(
            () => dispatch(sendSporreskjemaSuccess(index)),
            error => dispatch(sendSporreskjemaFailure(index, error))
        );
    };
}

export function setTextResponse(index, response) {
    return {
        type: SET_TEXT_RESPONSE,
        reponse: response,
        index: index,
    };
}

export function setPolarResponse(index, response) {
    return {
        type: SET_POLAR_RESPONSE,
        reponse: response,
        index: index,
    };
}


export const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: undefined,
    kursId: undefined,
    sporsmalListe: [],
    formData: [],
    sendingResponse: [],
};


function sporreskjema(state = initialState, action) {
    switch (action.type) {
        case LOAD_SPORRESKJEMA_PENDING:
            return {
                ...state,
                isLoading: true,
                kursId: action.kursId,
                error: undefined
            };
        case LOAD_SPORRESKJEMA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                sporsmalListe: [...action.data],
                formData: action.data.map(sporsmal => formDataFromSporsmal(sporsmal))
            };
        case LOAD_SPORRESKJEMA_FAILURE:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error
            };
        case SEND_SPORRESKJEMA_PENDING:
            return {
                ...state,
                sendingResponse: [
                    ...state.sendingResponse,
                    action.index,
                ]
            };
        case SEND_SPORRESKJEMA_SUCCESS:
            return {
                ...state,
                sendingResponse: state.sendingResponse.filter(index => index !== action.index),
                // overwrite original data with form data
                sporsmalListe: state.sporsmalListe.map((sporsmal, index) =>
                    index === action.index ? { ...sporsmal, responseText: state.formData[index].responseText, responsePolar: state.formData[index].responsePolar } : sporsmal
                ),
                // reset ws error
                formData: formDataSetError(state.formData, state.sporsmalListe, action.index, undefined)
            };
        case SEND_SPORRESKJEMA_FAILURE:
            return {
                ...state,
                sendingResponse: state.sendingResponse.filter(index => index !== action.index),
                // overwrite form data with original data if question is not freetext, set error
                formData: formDataSetError(state.formData, state.sporsmalListe, action.index, action.error)

            };
        case SET_TEXT_RESPONSE:
            return {
                ...state,
                formData: state.formData.map((spm, index) => index === action.index ? {
                    ...spm,
                    responseText: action.reponse
                } : spm)
            };
        case SET_POLAR_RESPONSE:
            return {
                ...state,
                formData: state.formData.map((spm, index) => index === action.index ? {
                    ...spm,
                    responsePolar: action.reponse
                } : spm)
            };
        default:
            return state
    }
}

function formDataSetError(formDataListe, sporsmalListe, index, error){
    const sporsmal = sporsmalListe[index];
    return formDataListe.map((formData, id) => {
        if(index !== id){
            return formData;
        }
        const newFormData = sporsmal.type === QUESTION_TYPE_FREETEXT || !error ? formData : sporsmal;
        return formDataFromSporsmal(newFormData, error);
    })
}

const formDataFromSporsmal = (sporsmal, error) => ({
    responseText: sporsmal.responseText,
    responsePolar: sporsmal.responsePolar,
    wsError: error,
});

export function getState(state) {
    return state[NAME];
}

function sporsmalHasError(sporsmal, formData){
    return getSporsmalErrorText(sporsmal, formData) !== undefined;
}

function getSporsmalErrorText(sporsmal, formData){
    if(formData.wsError){
        return 'ws_error'
    }
    if(formData.responseText && formData.responseText.length > MAX_LENGDE_FRITEKST_SPORSMAL){
        return 'svar_for_langt';
    }
    if(sporsmal.mandatory && !sporsmalBesvart(sporsmal, formData)){
        return 'spørsmal_ikke_valgfritt_og_må_svares_på';
    }
}

export function sporsmalBesvart(sporsmal, formData) {
    if (sporsmal.type === QUESTION_TYPE_FREETEXT) {
        return formData.responseText && formData.responseText.trim();
    }
    if (sporsmal.type === QUESTION_TYPE_POLAR) {
        return formData.responsePolar !== undefined;
    }
    return true;
}


export function getSporsmal(state) {
    const sporsmalListe = getState(state).sporsmalListe;
    const locale = getLocale(state);
    return sporsmalListe.map((sporsmal, index) => {
        const text = getValueByLanguage(sporsmal.text, locale);
        const formData = getFormDataById(state, index);
        return {
            ...sporsmal,
            ...formData,
            hasError: sporsmalHasError(sporsmal, formData),
            errorText: getSporsmalErrorText(sporsmal, formData),
            isLoading: isSporsmalSendingResponse(state, index),
            sporsmalLang: getTranslationLang(state, sporsmal.text),
            text,
        }
    });
}

export function getSporsmalFromIndex(state, index){
    return getSporsmal(state)[index];
}

export function getSendingResponseList(state){
    return getState(state).sendingResponse;
}

export function isSporsmalSendingResponse(state, index) {
    return getSendingResponseList(state).indexOf(index) > -1;
}

export function getFormData(state){
    return getState(state).formData;
}

export function getFormDataById(state, index){
    return getState(state).formData[index];
}

export function getKursId(state){
    return getState(state).kursId;
}

export function isDataLoaded(state) {
    return getState(state).isDataLoaded && getValgtKursId(state) === getKursId(state);
}

export function isLoading(state) {
    return getState(state).isLoading;
}

export function getError(state) {
    return getState(state).error;
}

export function getIsSporreskjemaValid(state) {
    const sporsmalListe = getSporsmal(state);
    return sporsmalListe.every(s => !s.hasError || !s.mandatory);
}

export default sporreskjema;