import ClickableArea from "../ClickableArea";
import React from "react";
import PropTypes from "prop-types";
import Text from "../../primitives/Text";

export const ButtonWithDescription = ({className, logoSrc, altForLogo, title, descriptionId, onClick}) =>
    <ClickableArea className={`${className}  button-with-description`} onClick={onClick}>
        <div className='button-with-description__logo'>
            <img src={logoSrc} alt={altForLogo}/>
        </div>
        <div className='description'>
            <div className='description__tittel'>
                {title}
            </div>
            <div>
                <Text id={descriptionId}/>
            </div>
        </div>
    </ClickableArea>
;


ButtonWithDescription.propTypes = {
    className: PropTypes.string.isRequired,
    logoSrc: PropTypes.string.isRequired,
    altForLogo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    descriptionId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};
