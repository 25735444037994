import Button, {TERTIARY} from "../../../primitives/Button";
import React from "react";
import {pages} from "../../../utils/common";
import Text from "../../../primitives/Text";
import {useNavigate} from "react-router";

export const ButtonTilbakeTilSoknader = () => {
    const navigate = useNavigate();
    return (
        <Button
            emphasis={TERTIARY}
            onClick={() => navigate(pages.soknader)}
        >
            <Text id='tilbake_til_søknadsliste'/>
        </Button>
    )
};


export default ButtonTilbakeTilSoknader;