import React from 'react';
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import {useSelector} from "react-redux";
import {getOverlappendeSoknader, getTidsIntervallForSoknad} from "../../ducks/soknader";
import {getKursIdObject} from "../../ducks/kursinfo";
import {kursIdToString} from "../../utils/common";
import FormattedDate from "../../primitives/FormattedDate";
import Text from "../../primitives/Text";
import withSoknaderLoaded from "../../high-order-components/withSoknaderLoaded";

const OverlappVarsel = () => {
    const kursId = useSelector(getKursIdObject);
    const overlappendeSoknader = useSelector(state => getOverlappendeSoknader(state, kursId));
    const {datoFra, datoTil} = useSelector(state => getTidsIntervallForSoknad(state, kursId));
    return overlappendeSoknader.length > 0 &&
        <Feedback type={FEEDBACK_TYPE_VARSEL}>
            <Text
                id={'dette_kurset_går_fra_dato_til_dato_og_du_har_søknader'}
                values={{
                    fra: <BoldDate date={datoFra} />,
                    til: <BoldDate date={datoTil} />,
                    antallSoknader: overlappendeSoknader.length,
                }}
            />
            <ul>
                {overlappendeSoknader.map(soknad => <Soknad key={kursIdToString(soknad.id)} {...soknad}/>)}
            </ul>
        </Feedback>
};

const Soknad = ({kursnavn, datoFra, datoTil}) =>
    <li>
        <Text
            id={'kurs_fra_til'}
            values={{
                kurs: kursnavn,
                fra: <BoldDate date={datoFra} />,
                til: <BoldDate date={datoTil} />,
            }}
        />
    </li>
;

const BoldDate = ({date}) => <strong><FormattedDate date={date} /></strong>;

export default withSoknaderLoaded(OverlappVarsel);