import Periode from "./Periode";
import ArbeidsgiverOgStillingstittel
    from "./ArbeidsgiverOgStillingstittel";
import Vedlegg from "../Vedlegg";
import React from "react";
import {useDispatch} from "react-redux";
import Button from "../../primitives/Button";
import {enterEditMode} from "../../ducks/arbeidserfaring";
import {endreIconPath} from "../../utils/icons";

const ArbeidserfaringPresentation = ({arbeidserfaring, withEndreButton}) => {
    const {id, arbeidsgiver, stillingstittel, fraDato, tilDato, dokumentid, dokumentnavn, stillingsprosent, isDeleting} = arbeidserfaring;
    return (
        <div className='arbeidserfaring__info'>
            <Periode fraDato={fraDato} tilDato={tilDato} monthInDigits/>
            {withEndreButton && <EndreArbeidserfaringButton id={id} isDeleting={isDeleting}/>}
            <ArbeidsgiverOgStillingstittel
                arbeidsgiver={arbeidsgiver}
                stillingsprosent={stillingsprosent}
                stillingstittel={stillingstittel}
            />
            <Vedlegg dokumentid={dokumentid} dokumentnavn={dokumentnavn}/>
        </div>
    )
};

const EndreArbeidserfaringButton = ({id, isDeleting}) => {
    const dispatch = useDispatch();
    return (
        <Button
            onClick={() => dispatch(enterEditMode(id))}
            iconPath={endreIconPath}
            disabled={isDeleting}
            className='endre-button'
            altText='endre'
        />
    )
};

export default ArbeidserfaringPresentation;