import {isAdresseEmpty} from "../../utils/adresseUtil";
import {AdresseIcon} from "./AdresseIcon";
import LeggTilNyVerdi from "../LeggTilNyVerdi";
import {Adresse} from "./Adresse";
import React from "react";
import DSFHelpButton, {TYPE_DSF_FELT_ADRESSE} from "../profil/dsf/DSFHelpButton";
import {endreIconPath} from "../../utils/icons";
import Icon from "../../primitives/Icon";

const AdressePresentasjon = ({typeAdresse, adresse, isAdresseSperret}) => {
    return isAdresseEmpty(adresse) ?
        <div className='input-adresse-presentasjon'>
            <AdresseIcon type={typeAdresse}/>
            <LeggTilNyVerdi valueName={typeAdresse}/>
        </div>
        :
        <div className='input-adresse-presentasjon'>
            <Adresse adresse={adresse} type={typeAdresse}/>
            {isAdresseSperret ?
                <DSFHelpButton typeFelt={TYPE_DSF_FELT_ADRESSE}/>
                :
                <Icon path={endreIconPath} className='editable-indikator'/>
            }
        </div>
};

export default AdressePresentasjon;