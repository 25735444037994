import {NORGE_LANDNUMMER} from "../ducks/land";
import {isPostnummerValid, MAX_LENGDE_ADRESSELINJE} from "./common";
import {getPoststed, isPoststederLoaded} from "../ducks/poststeder";
import {UKJENT_POSTSTED} from "../services/postnumreAPI";

export const createAdresse = ({linje1 = '', linje2 = '', land, postadresse = '', postnummer = ''}) => {
    return {
        linje1,
        linje2,
        land,
        postadresse,
        postnummer,
    }
};


export const isAdressePaNorskFormat = adresse => adresse && adresse.land === NORGE_LANDNUMMER;

export const landSkalVises = adresse => adresse && Boolean(adresse.land && adresse.land !== NORGE_LANDNUMMER);

export const isAdresseEmpty = adresse => {
    if (!adresse) return true;
    const {linje1, linje2, postnummer, postadresse} = adresse;
    return !(linje1 || linje2 || postnummer || postadresse || landSkalVises(adresse));
};

export const isAdresseValid = adresseErrors => !Object.keys(adresseErrors).some(key => adresseErrors[key]);


export const getErrorsAdresse = (state, adresse, obligatorisk) => {
    return {
        linje1: Boolean(adresse && adresse.linje1 && adresse.linje1.length > MAX_LENGDE_ADRESSELINJE),
        linje2: Boolean(adresse && adresse.linje2 && adresse.linje2.length > MAX_LENGDE_ADRESSELINJE),
        land: false,
        postadresse: getErrorPostadresse(adresse, obligatorisk),
        postnummer: adresse && getErrorInPostnummer(state, adresse, obligatorisk),
    }
};

export function getErrorPostadresse(adresse, obligatorisk) {
    if (isAdressePaNorskFormat(adresse)) {
        return undefined;
    }

    const {postadresse} = adresse;

    if (!postadresse && obligatorisk) {
        return 'hjemmeadresse_må_fylles_ut';
    }

    return undefined;
}

export function getErrorInPostnummer(state, adresse, obligatorisk) {

    if (!isAdressePaNorskFormat(adresse)) {
        return undefined;
    }
    const {postnummer, linje1, linje2} = adresse;

    if (!postnummer) {
        if (linje1 || linje2) {
            return 'postnummer_må_fylles_ut_for_norske_adresser';
        }
        return obligatorisk ? 'hjemmeadresse_må_fylles_ut' : undefined;
    }

    if (!isPostnummerValid(postnummer)) {
        return 'ugyldig_postnummer';
    }

    if (getPoststed(state, postnummer) === UKJENT_POSTSTED
        || (isPoststederLoaded(state) && !getPoststed(state, postnummer))) {
        return 'postnummer_finnes_ikke';
    }

    return undefined;
}