import {createAdresse, getErrorsAdresse, isAdresseEmpty, isAdresseValid} from "../utils/adresseUtil";
import {getFormDataAdresse, getFulltNavn,} from "./profil";
import {isEmailValid, TYPE_ADRESSE_ARBEID, TYPE_ADRESSE_HJEM} from "../utils/common";
import {getAktueltDeltakernr, getEvuapiToken} from "./session";
import {getKursId} from "./kursinfo";
import {getAPIError as getOrganisasjonsAPIError} from "./organisasjoner";
import {postBetalingsinfo} from "../services/betalingsinfoAPI";
import {getOrganisasjonsErrorMessageId} from "../utils/organisasjonUtil";
import {showPopup} from "./popup";
import {createErrorPopupContent} from "../utils/request";

export const FAKTURAMOTTAKER_MAKSLENGDE = 120;
export const NAME = 'betalingsinfo';
export const LOAD_BETALINGSINFO_SUCCESS = `${NAME}/LOAD_BETALINGSINFO_SUCCESS`;
export const LAGRE_PENDING = `${NAME}/LAGRE_PENDING`;
export const LAGRE_SUCCESS = `${NAME}/LAGRE_SUCCESS`;
export const LAGRE_FAILURE = `${NAME}/LAGRE_FAILURE`;
export const CHANGE_BETALINGSINFO_FIELD = `${NAME}/CHANGE_BETALINGSINFO_FIELD`;
export const CHANGE_FAKTURAADRESSE_FIELD = `${NAME}/CHANGE_FAKTURAADRESSE_FIELD`;
export const SET_BEDRIFT_BETALER = `${NAME}/SET_BEDRIFT_BETALER`;
export const SET_BRUK_KONTAKTINFOADRESSE = `${NAME}/SET_BRUK_KONTAKTINFOADRESSE`;

export const loadBetalingsinfoSuccess = betalingsinfo => {
    return {
        type: LOAD_BETALINGSINFO_SUCCESS,
        betalingsinfo,
    }
};

export function lagreBetalingsinfoPending(felt) {
    return {
        type: LAGRE_PENDING,
        felt
    };
}

export function lagreBetalingsinfoSuccess() {
    return {
        type: LAGRE_SUCCESS,
    };
}

export function lagreBetalingsinfoFailure(error, felt) {
    return {
        type: LAGRE_FAILURE,
        error,
        felt
    };
}

export const changeBetalingsinfoField = (key, value) => {
    return {
        type: CHANGE_BETALINGSINFO_FIELD,
        key,
        value
    }
};

export const changeFakturaadresseField = (key, value) => {
    return {
        type: CHANGE_FAKTURAADRESSE_FIELD,
        key,
        value
    }
};

export function setBedriftBetaler(value) {
    return {
        type: SET_BEDRIFT_BETALER,
        value
    };
}

export function setBrukKontaktinfoadresse(value) {
    return {
        type: SET_BRUK_KONTAKTINFOADRESSE,
        value
    };
}

export const createBetalingsinfo = props => {
    const {
        fakturamottaker,
        organisasjonsnummer = '',
        fakturareferanse = '',
        epost = '',
        adresse = createAdresse({}),
        bedriftBetaler = false,
        brukKontaktinfoadresseFormData = true,
        bedriftBetalerOriginal,
        brukKontaktinfoadresseOriginal,

    } = props;
    return {
        fakturamottaker,
        organisasjonsnummer,
        fakturareferanse,
        epost,
        adresse,
        bedriftBetaler,
        bedriftBetalerOriginal,
        brukKontaktinfoadresseFormData,
        brukKontaktinfoadresseOriginal,
    };
};


export const lagreBetalingsinfo = (felt, showPopupOnError) => async (dispatch, getState) => {
    const isLagring = getIsLagring(getState());
    if (isLagring) {
        return;
    }
    if (!isBetalingsinfoValid(getState())) {
        return;
    }
    dispatch(lagreBetalingsinfoPending(felt));
    try {
        const state = getState();
        const evuapiToken = getEvuapiToken(state);
        const deltakernr = getAktueltDeltakernr(state);
        const kursId = getKursId(state);
        const betalingsinfo = getBetalingsinfo(state);
        const defaultFakturamottaker = getDefaultFakturamottaker(state);
        const brukKontaktinfoadresse = getBrukKontaktinfoadresse(state);
        await postBetalingsinfo(evuapiToken, deltakernr, kursId, betalingsinfo, defaultFakturamottaker, brukKontaktinfoadresse);
        dispatch(lagreBetalingsinfoSuccess());
    } catch (error) {
        dispatch(lagreBetalingsinfoFailure(error, felt));
        showPopupOnError && dispatch(
            showPopup(createErrorPopupContent(error, 'betalingsinformasjon_ble_ikke_lagret'))
        );
    }
};

export const initialState = {
    isLagringFelt: undefined,
    errorsLagring: undefined,
    ...createBetalingsinfo({}),
};

function betalingsinfo(state = initialState, action) {
    switch (action.type) {
        case LOAD_BETALINGSINFO_SUCCESS:
            return {
                ...initialState,
                ...action.betalingsinfo,
                bedriftBetalerOriginal: action.betalingsinfo.bedriftBetaler,
                brukKontaktinfoadresseOriginal: action.betalingsinfo.brukKontaktinfoadresseFormData,
            };
        case LAGRE_PENDING:
            return {
                ...state,
                isLagringFelt: action.felt,
                errorsLagring: {
                    ...state.errorsLagring,
                    [action.felt]: undefined,
                }
            };
        case LAGRE_SUCCESS:
            return {
                ...state,
                isLagringFelt: undefined,
                errorsLagring: undefined,
            };
        case LAGRE_FAILURE:
            return {
                ...state,
                isLagringFelt: undefined,
                errorsLagring: {
                    ...state.errorsLagring,
                    [action.felt]: action.error,
                },
                bedriftBetaler: state.bedriftBetalerOriginal,
                brukKontaktinfoadresseFormData: state.brukKontaktinfoadresseOriginal,
            };
        case SET_BEDRIFT_BETALER:
            return {
                ...state,
                bedriftBetaler: action.value,
            };
        case SET_BRUK_KONTAKTINFOADRESSE:
            return {
                ...state,
                brukKontaktinfoadresseFormData: action.value,
            };
        case CHANGE_BETALINGSINFO_FIELD:
            return {
                ...state,
                [action.key]: action.value,
            };
        case CHANGE_FAKTURAADRESSE_FIELD:
            return {
                ...state,
                adresse: {
                    ...state.adresse,
                    [action.key]: action.value,
                }
            };
        default:
            return state;
    }
}

export const getBetalingsinfo = state => state[NAME];
export const getIsLagring = state => getBetalingsinfo(state).isLagringFelt !== undefined;
export const getIsLagringFelt = state => getBetalingsinfo(state).isLagringFelt;
export const getErrorsLagring = state => getBetalingsinfo(state).errorsLagring;
export const getFakturaadresse = state => getBetalingsinfo(state).adresse;
export const isBedriftBetaler = state => getBetalingsinfo(state).bedriftBetaler;
export const getBrukKontaktinfoadresse = state =>
    getBetalingsinfo(state).brukKontaktinfoadresseFormData && getKontaktinfoadresseFinnes(state);

export const getOrganisasjonsnummer = state => getBetalingsinfo(state).organisasjonsnummer;
export const getFakturareferanse = state => getBetalingsinfo(state).fakturareferanse;
export const getFakturamottaker = state => getBetalingsinfo(state).fakturamottaker;
export const getFakturaepost = state => getBetalingsinfo(state).epost;
export const getErrorLagringForFelt = (state, felt) => {
    const errors = getErrorsLagring(state);
    return errors && errors[felt];
};

export const isFakturamottakerForLang = state => {
    const fakturamottaker = getFakturamottaker(state)
    return fakturamottaker && fakturamottaker.length > FAKTURAMOTTAKER_MAKSLENGDE;
}

export const getOrganisasjonsnummerHarError = state => {
    const organisasjonsnummer = getOrganisasjonsnummer(state);
    const apiError = getOrganisasjonsAPIError(state);
    return Boolean(getOrganisasjonsErrorMessageId(organisasjonsnummer, false, apiError));
};

export const getKontaktinfoadresseFinnes = state => {
    const bedriftBetaler = isBedriftBetaler(state);
    const hjemmeadresse = getFormDataAdresse(state, TYPE_ADRESSE_HJEM);
    const arbeidsadresse = getFormDataAdresse(state, TYPE_ADRESSE_ARBEID);
    if (bedriftBetaler) {
        return !isAdresseEmpty(arbeidsadresse);
    }
    return !isAdresseEmpty(hjemmeadresse);
};

export const getDefaultFakturamottaker = state => {
    const bedriftBetaler = isBedriftBetaler(state);
    const fulltNavn = getFulltNavn(state);
    const arbeidsgiver = getFormDataAdresse(state, TYPE_ADRESSE_ARBEID).linje1;
    return bedriftBetaler ? arbeidsgiver : fulltNavn;
};

export const getFakturaadresseErrors = state => {
    const adresse = getFakturaadresse(state);
    return getErrorsAdresse(state, adresse);
};

export const isBetalingsinfoValid = state => {
    return Boolean(
        isAdresseValid(getFakturaadresseErrors(state))
        && isEmailValid(getFakturaepost(state))
        && !getOrganisasjonsnummerHarError(state)
        && !isFakturamottakerForLang(state)
    );
};

export default betalingsinfo;