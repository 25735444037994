import {useDispatch, useSelector} from "react-redux";
import {changeFormField, getFodselsdato, getForsoktOpprett, kanEndreFodselsdato} from "../../ducks/nyBruker";
import {formatDate} from "../../ducks/i18n";
import {parseIsoDate} from "../../utils/common";
import Input from "../../primitives/Input";
import ReadOnlyField from "../ReadOnlyField";
import React from "react";
import {getErrorMessageId, isTextValidISODate} from "../../utils/dateUtil";
import InPlaceError from "../InPlaceError";
import {mdiCakeVariant} from "@mdi/js";

const Fodselsdato = () => {

    const fodselsdatoISO = useSelector(getFodselsdato);
    const kanEndres = useSelector(kanEndreFodselsdato);
    const fodselsdatoFormattert = useSelector(state => isTextValidISODate(fodselsdatoISO) && formatDate(state, parseIsoDate(fodselsdatoISO)));
    const forsoktOpprett = useSelector(getForsoktOpprett);
    const dispatch = useDispatch();
    const onChange = event => dispatch(changeFormField('fodselsdato', event.target.value));
    const errorMessageId = getErrorMessageId(fodselsdatoISO, true, undefined, forsoktOpprett, 'fodselsdato_obligatorisk');

    return (
        kanEndres ?
            <>
                <Input
                    label='fodselsdato'
                    type='date'
                    iconPathForLabel={mdiCakeVariant}
                    onChange={onChange}
                    hasError={errorMessageId}
                    value={fodselsdatoISO}
                    autoComplete='bday'
                />
                <InPlaceError errorMessageId={errorMessageId}/>
            </>
            :
            <ReadOnlyField
                iconPath={mdiCakeVariant} title='fodselsdato'
                value={fodselsdatoFormattert}
            />

    )
};

export default Fodselsdato;