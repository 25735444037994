import FormattedDate from "../../primitives/FormattedDate";
import Button from "../../primitives/Button";
import Text from "../../primitives/Text";
import React from "react";
import PropTypes from "prop-types";
import UtestaendeFakturaRow from "./UtestaendeFakturaRow";
import FormattedAmount from "../../primitives/FormattedAmount";
import {useDownloadFaktura} from "../../hooks/useDownloadFaktura";
import {mdiDownload} from "@mdi/js";


export const BetaltFakturaRow = ({belop, datoOpprettet, fakturanr}) => {

    const downloadFaktura = useDownloadFaktura(fakturanr);

    return (
        <li className='table-row'>
            <div className='dato'>
                <Text className='header' id='dato'/>
                <FormattedDate date={datoOpprettet}/>
            </div>
            <div className='nummer'>
                <Text className='header' id='nummer'/>
                {fakturanr}
            </div>
            <div className='belop'>
                <Text className='header' id='beløp'/>
                <FormattedAmount amount={belop}/>
            </div>
            <div className='actions'>
                <Button onClick={downloadFaktura}
                        iconPath={mdiDownload}
                >
                    <Text id='hent_faktura'/>
                </Button>
            </div>
        </li>
    )

};

UtestaendeFakturaRow.propTypes = {
    belop: PropTypes.number,
    datoOpprettet: PropTypes.object,
    fakturanr: PropTypes.number,
};