import * as landAPI from "../services/landAPI";
import {getLocale} from "./i18n";
import {getValueByLanguage} from "../utils/common";
import sortBy from 'lodash/sortBy';
import {getInstitusjonsnr} from "./session";

export const NAME = 'land';

export const LOAD_PENDING = `${NAME}/LOAD_PENDING`;
export const LOAD_SUCCESS = `${NAME}/LOAD_SUCCESS`;
export const LOAD_FAILURE = `${NAME}/LOAD_FAILURE`;


export const NORGE_LANDNUMMER = 0;

export function loadPending() {
    return {
        type: LOAD_PENDING,
    };
}

export function loadSuccess(land) {
    return {
        type: LOAD_SUCCESS,
        land: land,
    };
}

export function loadFailure(error) {
    return {
        type: LOAD_FAILURE,
        error: error
    };
}


export function loadLand() {
    return function (dispatch, getState) {

        if(isLoading(getState())){
            return;
        }

        dispatch(loadPending());

        const instnr = getInstitusjonsnr(getState());
        return landAPI.getLand(instnr).then(
            land => dispatch(loadSuccess(land)),
            error => dispatch(loadFailure(error))
        );
    };
}


const initialState = {
    isLoading: false,
    error: undefined,
    data: [],
};


function land(state = initialState, action) {

    switch (action.type) {
        case LOAD_PENDING:
            return {
                ...state,
                isLoading: true,
                error: undefined
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.land,
            };
        case LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME];
}


export function getData(state) {
    return getState(state).data;
}

export function getLocalizedData(state) {
    const locale = getLocale(state);

    return getData(state).map(land => {
        const landnummer = land.landnummer;
        const landnavn = getValueByLanguage(land.landnavn, locale);
        return {
            value: landnummer,
            label: landnavn
        }
    });
}


export function getLandSortedByLabel(state) {

    let result = getLocalizedData(state);

    result = sortBy(result, 'label');
    result = withNorgeOnTop(result);

    return result;
}


function withNorgeOnTop(list) {

    const norge = list.find(elem => elem.value === NORGE_LANDNUMMER);
    if (!norge) {
        return list;
    }

    return [
        norge,
        ...list.filter(elem => elem.value !== NORGE_LANDNUMMER)
    ];

}

export function isLoading(state) {
    return getState(state).isLoading;
}

export function getError(state) {
    return getState(state).error;
}


export function isDataLoaded(state) {
    return Boolean(getData(state).length);
}


export function getLandByNummer(state, landnummer){
    const landOptions = getLocalizedData(state);
    const foundOption = landOptions.find(option => (option.value === landnummer));
    return foundOption ? foundOption.label : landnummer;
}

export default land;

