import {getEvuapiTokenFromWs} from "../services/sessionAPI";
import {containsOnlyDigits, pages, redirectTo,} from '../utils/common'
import {getTranslation} from "./i18n";
import {loadProfil} from "./profil";
import {getRequestErrorTextId, isNotFound404} from "../utils/request";
import {OPPRETT_BRUKER_SUCCESS} from "./nyBruker";
import {
    getBrukernrSaksbehandling,
    getLoginSomType,
    getSkriverettigheterAktivert,
    LOGIN_SOM_TYPE_DELTAKER,
    resetLoginSom
} from "./menu";
import {initialLoad} from "./initialLoad";
import {getProfilData} from "../services/profilApi";
import {getPerson} from "../services/personAPI";
import {
    clearEvuapiTokenFromLocalStorage,
    clearLocalStorage,
    clearTokensFromLocalStorage,
    getJwtPayload,
    isJwtExpired,
    PERSISTED_STATE,
    saveAPITokenToLocalStorage,
    saveToLocalStorage
} from "../utils/sessionUtil";
import {logout} from "../utils/loginUtil";

export const NAME = 'session';

export const SET_DATAPORTEN_TOKEN = `${NAME}/SET_DATAPORTEN_TOKEN`;
export const LOAD_EVU_API_TOKEN_PENDING = `${NAME}/LOAD_EVU_API_TOKEN_PENDING`;
export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`;
export const LOGIN_FAILED = `${NAME}/LOGIN_FAILED`;
export const RESET_LOGIN_FAILED = `${NAME}/RESETT_LOGIN_FAILED`;

export const LOGOUT = `${NAME}/LOGOUT`;

export const LOGIN_SOM_DELTAKER_SAKSBEHANDLING = `${NAME}/LOGIN_SOM_DELTAKER_SAKSBEHANDLING`;
export const LOGIN_SOM_BEDRIFTSBRUKER_SAKSBEHANDLING = `${NAME}/LOGIN_SOM_BEDRIFTSBRUKER_SAKSBEHANDLING`;
export const LOGOUT_SAKSBEHANDLING = `${NAME}/LOGOUT_SAKSBEHANDLING`;
export const SET_LOGIN_SOM_ERROR = `${NAME}/SET_LOGIN_SOM_ERROR`;

export const LOGIN_SET_INSTITUSJON = `${NAME}/LOGIN_SET_INSTITUSJON`;
export const LOGIN_SET_REDIRECT = `${NAME}/LOGIN_SET_REDIRECT`;
export const LOGIN_SET_BRUKER_NAVN = `${NAME}/LOGIN_SET_BRUKER_NAVN`;

export const SET_REDIRECT_AFTER_SELECTED_INSTITUTION = `${NAME}/SET_REDIRECT_AFTER_SELECTED_INSTITUTION`;
export const REMOVE_EVU_API_TOKEN = `${NAME}/REMOVE_EVU_API_TOKEN`;
export const REMOVE_DATAPORTEN_TOKEN = `${NAME}/REMOVE_DATAPORTEN_TOKEN`;

export const SET_IS_FETCHING_API_TOKEN = `${NAME}/SET_IS_FETCHING_API_TOKEN`;
export const SET_EVUAPI_TOKEN = `${NAME}/SET_EVUAPI_TOKEN`;


export function setDataportenToken(dataportenToken) {
    return {
        type: SET_DATAPORTEN_TOKEN,
        dataportenToken
    };
}

export function loadEvuApiTokenPending() {
    return {
        type: LOAD_EVU_API_TOKEN_PENDING,
    };
}

export function setIsFetchingApiToken(value) {
    return {
        type: SET_IS_FETCHING_API_TOKEN,
        value: value,
    }
}

export function setEvuapiToken(token) {
    return {
        type: SET_EVUAPI_TOKEN,
        evuapiToken: token,
    }
}

export function loginSuccess(evuapiToken) {
    return {
        type: LOGIN_SUCCESS,
        evuapiToken: evuapiToken,
    };
}

export function loginFailed(error) {
    return {
        type: LOGIN_FAILED,
        error: error,
    };
}

export function resetLoginFailed() {
    return {
        type: RESET_LOGIN_FAILED,
    };
}


export function logoutSuccess() {
    return {
        type: LOGOUT,
    };
}

export const loginSomDeltakerForSaksbehandling = (deltakernr, skriverettigheter = false) => ({
    type: LOGIN_SOM_DELTAKER_SAKSBEHANDLING,
    deltakernr,
    skriverettigheter,
});

export const loginSomBedriftsbrukerForSaksbehandling = (personlopenr, deltakernr, skriverettigheter = false) => ({
    type: LOGIN_SOM_BEDRIFTSBRUKER_SAKSBEHANDLING,
    personlopenr,
    deltakernr,
    skriverettigheter,
});

export const setLoginSomError = error => ({
    type: SET_LOGIN_SOM_ERROR,
    error,
});

export function logoutDeltakerUnderSaksbehandling() {
    return {
        type: LOGOUT_SAKSBEHANDLING,
    };
}

export function setInst(institusjon) {
    return {
        type: LOGIN_SET_INSTITUSJON,
        institusjon: institusjon,
    }
}

export function resetInst() {
    return {
        type: LOGIN_SET_INSTITUSJON,
        institusjon: undefined,
    }
}

export function setRedirect(path) {
    return {
        type: LOGIN_SET_REDIRECT,
        path: path,
    }
}

export function setBrukerNavn(navn) {
    return {
        type: LOGIN_SET_BRUKER_NAVN,
        navn: navn,
    }
}

export function setRedirectAfterSelectedInstitution(path) {
    return {
        type: SET_REDIRECT_AFTER_SELECTED_INSTITUTION,
        path: path,
    }
}

export function removeEvuApiToken() {
    return {
        type: REMOVE_EVU_API_TOKEN,
    }
}


export function removeDataportenToken() {
    return {
        type: REMOVE_DATAPORTEN_TOKEN,
    }
}

export function loadEvuApiToken() {
    return function (dispatch, getState) {

        if (isLoadEvuApiTokenPending(getState())) {
            return;
        }
        dispatch(loadEvuApiTokenPending());

        const state = getState();
        const institusjonsnr = getInstitusjonsnr(state);
        const access_token = getDataportenAccessToken(state);

        return getEvuapiTokenFromWs(access_token, institusjonsnr).then(
            evuapiToken => {
                dispatch(loginSuccess(evuapiToken));
                dispatch(loadProfil());
                saveAPITokenToLocalStorage(evuapiToken);
                savePersistedSessionToLocalStorage(getState());
            },
            error => {
                dispatch(loginFailed(error));
                if (!isManglerBrukerError(error)) {
                    dispatch(removeDataportenToken());
                    dispatch(removeEvuApiToken());
                    clearTokensFromLocalStorage();
                }
            }
        );
    };
}

export function logoutUserOnTimeout() {// slette denne lanskje?
    return function (dispatch) {
        clearLocalStorage();
        dispatch(logoutSuccess());
    };
}

export function logoutUser() {
    return function (dispatch, getState) {
        const state = getState();
        const id_token = getDataportenId_token(state) || getDataportenIdTokenForLogoutOnError(state);
        clearLocalStorage();
        dispatch(logoutSuccess());
        logout(id_token);
    };
}

export const attemptLoginSomBruker = () => (dispatch, getState) => dispatch(
    getLoginSomType(getState()) === LOGIN_SOM_TYPE_DELTAKER ?
        validateAndLoginSomDeltaker()
        :
        validateAndLoginSomBedriftsbruker()
);

export const validateAndLoginSomDeltaker = () => async (dispatch, getState) => {
    const deltakernr = getBrukernrSaksbehandling(getState());
    const evuapiToken = getEvuapiToken(getState());
    if (!containsOnlyDigits(deltakernr)) {
        dispatch(setLoginSomError('deltakernummer_kan_kun_inneholde_tall'));
        return;
    }
    try {
        await getProfilData(deltakernr, evuapiToken);
        dispatch(loginSomBruker(deltakernr));
    } catch (error) {
        dispatch(setLoginSomError(getRequestErrorTextId(error)));
    }
};

export const validateAndLoginSomBedriftsbruker = () => async (dispatch, getState) => {
    const personlopenr = getBrukernrSaksbehandling(getState());
    const evuapiToken = getEvuapiToken(getState());
    if (!containsOnlyDigits(personlopenr)) {
        dispatch(setLoginSomError('personlopenr_kan_kun_inneholde_tall'));
        return;
    }
    try {
        const {deltakernr, erBedriftsbruker} = await getPerson(evuapiToken, personlopenr);
        if (!erBedriftsbruker) {
            dispatch(setLoginSomError('personløpenr_tilhører_ikke_bedriftsbruker'));
            return;
        }
        dispatch(loginSomBruker(deltakernr, personlopenr));
    } catch (error) {
        dispatch(setLoginSomError(getRequestErrorTextId(error)));
    }
};

export const loginSomBruker = (deltakernr, personlopenr) => async (dispatch, getState) => {
    const skriverettToken = evuapiTokenSkriverettighet(getState());
    const skriverettAktiv = getSkriverettigheterAktivert(getState());
    const harSkriverett = skriverettToken && skriverettAktiv;
    if (getLoginSomType(getState()) === LOGIN_SOM_TYPE_DELTAKER) {
        dispatch(loginSomDeltakerForSaksbehandling(parseInt(deltakernr), harSkriverett));
    } else {
        dispatch(loginSomBedriftsbrukerForSaksbehandling(personlopenr, parseInt(deltakernr), harSkriverett));
    }
    savePersistedSessionToLocalStorage(getState());
    await dispatch(loadProfil());
    dispatch(resetLoginSom());
    redirectTo(pages.minSide);
};

export const logoutDeltaker = () => async (dispatch, getState) => {
    dispatch(logoutDeltakerUnderSaksbehandling());
    savePersistedSessionToLocalStorage(getState());
    await dispatch(loadProfil());
    redirectTo(pages.minSide);
};

export function setInstitusjon(institusjon) {
    return function (dispatch, getState) {
        dispatch(setInst(institusjon));
        savePersistedSessionToLocalStorage(getState());

        dispatch(removeEvuApiToken());
        clearEvuapiTokenFromLocalStorage();

        dispatch(initialLoad());

        const state = getState();
        if (isLoggedInDataporten(state)) {
            dispatch(loadEvuApiToken());
        }

    };
}

export function loginSetPersistedRedirect(path) {
    return function (dispatch, getState) {
        dispatch(setRedirect(path));
        savePersistedSessionToLocalStorage(getState());
    }
}

export function loginSetBrukerNavn(navn) {
    return function (dispatch, getState) {
        dispatch(setBrukerNavn(navn));
        savePersistedSessionToLocalStorage(getState());
    }
}

export function savePersistedSessionToLocalStorage(state) {
    saveToLocalStorage(PERSISTED_STATE, getPersisted(state));
}

export const initialState = {
    dataportenToken: undefined,
    evuapiToken: undefined,
    isLoadEvuApiTokenPending: false,
    isFetchingApiToken: false,
    hasLoginFailed: false,
    loginError: undefined,
    redirectionAfterSelectedInstitutionPath: undefined,
    instnr: undefined,
    persisted: {
        saksbehandling: {
            loginSomError: undefined,
            deltakernr: undefined,
            personlopenr: undefined,
            bedriftsbruker: false,
            skriverettigheter: false,
        },
        institusjon: undefined,
        innloggetBrukerNavn: undefined,
        redirectionPath: undefined,
    },
};

function session(state = initialState, action) {
    switch (action.type) {
        case SET_DATAPORTEN_TOKEN:
            return {
                ...state,
                dataportenToken: action.dataportenToken,
            };
        case LOAD_EVU_API_TOKEN_PENDING:
            return {
                ...state,
                isLoadEvuApiTokenPending: true,
                hasLoginFailed: false,
            };
        case SET_IS_FETCHING_API_TOKEN:
            return {
                ...state,
                isFetchingApiToken: action.value // det er kanskje ikke payload jeg skal bruke her?
            };
        case SET_EVUAPI_TOKEN:
            return {
                ...state,
                evuapiToken: action.evuapiToken,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                evuapiToken: action.evuapiToken,
                deltakernr: action.deltakernr,
                isLoadEvuApiTokenPending: false,
                loginError: undefined,
            };
        case LOGIN_FAILED:
            return {
                ...state,
                hasLoginFailed: true,
                isLoadEvuApiTokenPending: false,
                loginError: action.error,
                dataportenIdTokenForLogoutOnError: state.dataportenToken && state.dataportenToken.id_token
            };
        case RESET_LOGIN_FAILED:
            return {
                ...state,
                hasLoginFailed: false,
                loginError: undefined,
                dataportenIdTokenForLogoutOnError: undefined,
            };
        case OPPRETT_BRUKER_SUCCESS:
            return {
                ...state,
                hasLoginFailed: false,
                loginError: undefined,
            };
        case LOGOUT:
            return {
                ...state,
                dataportenToken: undefined,
                evuapiToken: undefined,
                persisted: persisted(state.persisted, action),
            };
        case REMOVE_EVU_API_TOKEN:
            return {
                ...state,
                evuapiToken: undefined,
            };
        case REMOVE_DATAPORTEN_TOKEN:
            return {
                ...state,
                dataportenToken: undefined,
            };
        case SET_REDIRECT_AFTER_SELECTED_INSTITUTION:
            return {
                ...state,
                redirectionAfterSelectedInstitutionPath: action.path,
            };
        case LOGIN_SOM_DELTAKER_SAKSBEHANDLING:
        case LOGIN_SOM_BEDRIFTSBRUKER_SAKSBEHANDLING:
        case SET_LOGIN_SOM_ERROR:
        case LOGOUT_SAKSBEHANDLING:
        case LOGIN_SET_INSTITUSJON:
        case LOGIN_SET_BRUKER_NAVN:
        case LOGIN_SET_REDIRECT:
            return {
                ...state,
                persisted: persisted(state.persisted, action),
            };
        default:
            return state;
    }
}

function persisted(state = initialState.persisted, action) {
    switch (action.type) {
        case LOGIN_SOM_DELTAKER_SAKSBEHANDLING:
        case LOGIN_SOM_BEDRIFTSBRUKER_SAKSBEHANDLING:
        case LOGOUT_SAKSBEHANDLING:
        case SET_LOGIN_SOM_ERROR:
            return {
                ...state,
                saksbehandling: saksbehandling(state.saksbehandling, action)
            };
        case LOGIN_SET_INSTITUSJON:
            return {
                ...state,
                institusjon: action.institusjon,
            };
        case LOGIN_SET_REDIRECT:
            return {
                ...state,
                redirectionPath: action.path,
            };
        case LOGIN_SET_BRUKER_NAVN:
            return {
                ...state,
                innloggetBrukerNavn: action.navn,
            };
        case LOGOUT:
            return {
                ...initialState.persisted,
            };
        default:
            return state;
    }
}

function saksbehandling(state = initialState.persisted.saksbehandling, action) {
    switch (action.type) {
        case LOGIN_SOM_DELTAKER_SAKSBEHANDLING:
            return {
                ...state,
                loginSomError: undefined,
                bedriftsbruker: false,
                personlopenr: undefined,
                deltakernr: action.deltakernr,
                skriverettigheter: action.skriverettigheter,
            };
        case LOGIN_SOM_BEDRIFTSBRUKER_SAKSBEHANDLING:
            return {
                ...state,
                loginSomError: undefined,
                bedriftsbruker: true,
                personlopenr: action.personlopenr,
                deltakernr: action.deltakernr,
                skriverettigheter: action.skriverettigheter,
            };
        case SET_LOGIN_SOM_ERROR:
            return {
                ...state,
                loginSomError: action.error,
            };
        case LOGOUT_SAKSBEHANDLING:
            return {
                ...state,
                loginSomError: undefined,
                personlopenr: undefined,
                deltakernr: undefined,
                skriverettigheter: false,
            };
        default:
            return state;
    }
}


export function getState(state) {
    return state[NAME];
}

export function getPersisted(state) {
    return getState(state).persisted;
}

export function hasLoginFailed(state) {
    return getState(state).hasLoginFailed;
}

export function isLoadEvuApiTokenPending(state) {
    return getState(state).isLoadEvuApiTokenPending;
}

export function isLoggedIn(state) {
    return isLoggedInDataporten(state) && getState(state).evuapiToken !== undefined;
}

export function isLoggedInDataporten(state) {
    return getState(state).dataportenToken !== undefined;
}

export function getSaksbehandling(state) {
    return getPersisted(state).saksbehandling;
}

export function getRedirectionPath(state) {
    return getPersisted(state).redirectionPath || pages.minSide;
}

export function getDeltakernrUnderSaksbehandling(state) {
    return getSaksbehandling(state).deltakernr;
}

export function isSaksbehandler(state) {
    const tokenPayload = getEvuapiTokenPayload(state);
    return Boolean(tokenPayload && tokenPayload.saksbehandler);
}

export const getPersonlopenrUnderSaksbehandling = state => getSaksbehandling(state).personlopenr;

export const isSaksbehandlerLoggedInSomBedriftsbruker = state => getSaksbehandling(state).bedriftsbruker && Boolean(getPersonlopenrUnderSaksbehandling(state));

export const getHarApiTokenBedriftsbruker = state => {
    const tokenPayload = getEvuapiTokenPayload(state);
    return Boolean(tokenPayload && tokenPayload.bedriftsbruker);
};

export const getIsBedriftsbruker = state => getHarApiTokenBedriftsbruker(state) || isSaksbehandlerLoggedInSomBedriftsbruker(state);

export function isLoggedInSomDeltaker(state) {
    return isSaksbehandler(state) && (getDeltakernrUnderSaksbehandling(state) !== undefined);
}

export const getIsSaksbehandlerLoggedInSomBruker = state => isSaksbehandlerLoggedInSomBedriftsbruker(state) || isLoggedInSomDeltaker(state);

export function getAktueltDeltakernr(state) {

    if (isLoggedInSomDeltaker(state)) {
        return getDeltakernrUnderSaksbehandling(state);
    }

    return getDeltakernrFraTokenet(state);
}

export const getAktueltPersonlopenr = state => {

    if (isSaksbehandlerLoggedInSomBedriftsbruker(state)) {
        return getPersonlopenrUnderSaksbehandling(state);
    }

    return getPersonlopenrFraTokenet(state);
};

export function getDeltakernrFraTokenet(state) {
    const tokenPayload = getEvuapiTokenPayload(state);
    return tokenPayload && tokenPayload.deltakernr;
}

export const getPersonlopenrFraTokenet = state => {
    const tokenPayload = getEvuapiTokenPayload(state);
    return tokenPayload && tokenPayload.personlopenr;
};

export function getEvuapiTokenInstnr(state) {
    const tokenPayload = getEvuapiTokenPayload(state);
    return tokenPayload && tokenPayload.institusjonsnr;
}

export function getInstitusjon(state) {
    return getPersisted(state).institusjon;
}

export function getFeideDomene(state) {
    const institusjon = getInstitusjon(state);
    return institusjon && institusjon.feidedomene;
}

export function getInstitusjonsnr(state) {
    const institusjon = getInstitusjon(state);
    return institusjon && institusjon.institusjonsnr;
}

export function getInstitusjonsAkronym(state) {
    const institusjon = getInstitusjon(state);
    return institusjon && institusjon.akronym;
}

export function getInstitusjonsNavn(state) {
    const institusjon = getInstitusjon(state);
    return institusjon && institusjon.navn && getTranslation(state, institusjon.navn);
}

export function getEvuapiToken(state) {
    const evuapiToken = getState(state).evuapiToken;
    return evuapiToken && evuapiToken.token;
}

export function getIsApiTokenFetchedAndActive(state) {
    const token = getEvuapiToken(state);

    if (!token) {
        return false;
    }
    return !isJwtExpired(token);
}

export function getDataportenToken(state) {
    return getState(state).dataportenToken;
}

export function getRedirectionAfterSelectedInstitutionPath(state) {
    return getState(state).redirectionAfterSelectedInstitutionPath;
}

export function getDataportenAccessToken(state) {
    const dataportenTokenPayload = getDataportenToken(state);
    return dataportenTokenPayload && dataportenTokenPayload.access_token;
}

export function getDataportenId_token(state) {
    const dataportenTokenPayload = getDataportenToken(state);
    return dataportenTokenPayload && dataportenTokenPayload.id_token;
}


export function isDataportenTokenExpired(state) {
    const dataportenTokenPayload = getDataportenToken(state);
    return Boolean(dataportenTokenPayload) && dataportenTokenPayload.expires * 1000 < Date.now()
}


export function getEvuapiTokenPayload(state) {
    const evuapiToken = getEvuapiToken(state);
    return evuapiToken && getJwtPayload(evuapiToken);
}

export function isEvuapiTokenExpired(state) {
    const evuapiTokenPayload = getEvuapiTokenPayload(state);
    return Boolean(evuapiTokenPayload) && evuapiTokenPayload.exp * 1000 < Date.now()
}

export function getInnloggetBrukerNavn(state) {
    return getPersisted(state).innloggetBrukerNavn;
}

export function getLoginError(state) {
    return getState(state).loginError;
}

export function isManglerBrukerInnloggingsError(state) {
    const error = getLoginError(state);
    return isManglerBrukerError(error);
}

export function isManglerBrukerError(error) {
    return isNotFound404(error);
}

export function isSessionExpired(state) {
    return isDataportenTokenExpired(state) || isEvuapiTokenExpired(state);
}

export const evuapiTokenSkriverettighet = state => getEvuapiTokenPayload(state).skriverettighet;
export const skriverettighetAktivert = state => getSaksbehandling(state).skriverettigheter;
export const skrivingSkalBlokkeres = state => Boolean(isLoggedInSomDeltaker(state) && !skriverettighetAktivert(state));
export const getLoginSomError = state => getSaksbehandling(state).loginSomError;
export const getDataportenIdTokenForLogoutOnError = state => getState(state).dataportenIdTokenForLogoutOnError;
export const getKanSynkeDsf = state => getEvuapiTokenPayload(state).kanSynkeDsf;
export const getIsFetchingApiToken = state => getState(state).isFetchingApiToken;

export default session;