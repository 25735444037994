import React from "react";
import AdresseForm from "../adresse/AdresseForm";
import {useDispatch, useSelector} from "react-redux";

import {TYPE_ADRESSE_FAKTURA} from "../../utils/common";
import {
    changeFakturaadresseField, getErrorLagringForFelt,
    getFakturaadresse,
    getFakturaadresseErrors, getIsLagringFelt,
    lagreBetalingsinfo
} from "../../ducks/betalingsinfo";
import Spinner from "../Spinner";
import ErrorFormSaving from "../ErrorFormSaving";
import {getForsoktNeste} from "../../ducks/pamelding";

const FELT_FAKTURAADRESSE = 'fakturaadresse';

export const FakturaInputAdresse = () => {
    const adresse = useSelector(getFakturaadresse);
    const errors = useSelector(getFakturaadresseErrors);
    const isLagringFelt = useSelector(getIsLagringFelt);
    const isLagring = isLagringFelt === FELT_FAKTURAADRESSE;
    const errorLagring = useSelector(state => getErrorLagringForFelt(state, FELT_FAKTURAADRESSE));
    const forsoktNeste = useSelector(getForsoktNeste);
    const dispatch = useDispatch();
    const formDataOnChange = (key, value) => {
        dispatch(changeFakturaadresseField(key, value));
    };
    const onBlur = () => dispatch(lagreBetalingsinfo(FELT_FAKTURAADRESSE));

    return (
        <div className='fakturaadresse-input-fields input-group'>
            {isLagring && <Spinner/>}
            <AdresseForm
                typeAdresse={TYPE_ADRESSE_FAKTURA}
                onChange={formDataOnChange}
                adresse={adresse}
                errors={errors}
                attemptedClosed={forsoktNeste}
                onBlur={onBlur}
            />
            {errorLagring &&
            <ErrorFormSaving
                error={errorLagring}
                provIgjen={onBlur}
            />
            }
        </div>
    );
};