import * as routes from "./apiRoutes";
import axios from "axios/index";
import {isNotFound404} from "../utils/request";


export const UKJENT_POSTSTED = 'Ukjent poststed';


export function getPoststed(postnummer, evuapiToken) {

    const route = routes.POSTNUMRE.replace(':postnummer', postnummer);

    const config = {
        headers: {
            'Authorization': 'Bearer ' + evuapiToken
        }
    };

    return axios.get(route, config)
        .then(response => {
            return transformOutput(response.data);
        })
        .catch(error => {
            if (isNotFound404(error)) {
                return UKJENT_POSTSTED;
            }
            throw error;
        });
}


function transformOutput(data) {
    return data.poststed ? data.poststed : UKJENT_POSTSTED;
}