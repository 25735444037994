import React from 'react';
import MenubarLanguage from "./MenubarLanguage";
import MenubarUser from "./MenubarUser";
import SkipLink from "./SkipLink";
import MenubarMinSide from "./MenubarMinSide";
import MenubarKursoversikt from "./MenubarKursoversikt";
import {getInstitusjon, isLoggedIn as isLoggedInSelector} from "../../ducks/session";
import {useSelector} from "react-redux";
import MenubarLogin from "./MenubarLogin";
import MenuBarInstitusjoner from "./MenuBarInstitusjoner";
import './menubar.scss';


export const Menubar = () => {
    const isLoggedIn = useSelector(isLoggedInSelector);
    const inst = useSelector(getInstitusjon);

    return (
        <nav id='menu-bar'>
            <SkipLink/>
            <div id='menu-bar__dropdowns'>
                <div className='hovedlenker'>
                    {isLoggedIn ?
                        <MenubarMinSide/>
                        :
                        inst && <MenubarLogin/>}
                    {!isLoggedIn && <MenuBarInstitusjoner/>}
                    <MenubarKursoversikt/>
                </div>
                <MenubarLanguage/>
                <MenubarUser/>
            </div>
        </nav>
    )
};


export default Menubar;