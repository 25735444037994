import React from 'react'
import {pages} from '../../utils/common'
import {useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import PageTitle from "../PageTitle";
import {APPTEKST_PERSONVERN_URL, getApptekst} from "../../ducks/apptekster";
import {ExternalLink} from "../../primitives/Link";
import {compose} from "redux";
import withInstitusjonerLoaded from "../../high-order-components/withInstitusjonerLoaded";
import requireInstitusjonSelected from "../../high-order-components/requireInstitusjonSelected";
import './login.scss';
import blockedIfLoggedInDataporten from "../../high-order-components/blockedIfLoggedInDataporten";
import {getIsLoginTillat} from "../../ducks/appegenskaper";
import LoginMethods from "./LoginMethods";
import Feedback from "../Feedback";
import withAppegenskaperLoaded from "../../high-order-components/withAppegenskaperLoaded";
import Text from "../../primitives/Text";

const Login = () => {
    const isLoginTillat = useSelector(getIsLoginTillat);
    return (
        <div className='login'>
            <PageTitle id='login'/>
            <div className='login__box-and-links-wrapper'>
                <PersonvernerklaringLink className='personvern-link--show-only-mobile'/>
                {isLoginTillat ?
                    <LoginMethods/>
                    :
                    <Feedback>
                        <Text id='det_er_ikke_mulig_å_logge_på_ved_denne_institusjonen'/>
                    </Feedback>
                }
                <ul className='login__links'>
                    <li><PersonvernerklaringLink className='personvern-link--show-only-desktop'/></li>
                    <li><VelgEnAnnenInstitusjonLink/></li>
                </ul>
            </div>
        </div>
    )
};


const PersonvernerklaringLink = ({className}) => {
    const personvernURL = useSelector(state => getApptekst(state, APPTEKST_PERSONVERN_URL));
    return (
        <ExternalLink href={personvernURL} className={className}>
            <Text id='personvernerklæring'/>
        </ExternalLink>
    )
};

const VelgEnAnnenInstitusjonLink = () =>
    <Link className='link' to={pages.institusjon}>
        <Text id='velg_en_annen_institusjon'/>
    </Link>
;


export default compose(
    blockedIfLoggedInDataporten,
    requireInstitusjonSelected,
    withInstitusjonerLoaded,
    withAppegenskaperLoaded,
)(Login);