import * as routes from "./apiRoutes";
import {createRequestConfig} from "../utils/request";
import axios from "axios";
import {createAdresse} from "../utils/adresseUtil";
import {createBetalingsinfo} from "../ducks/betalingsinfo";
import {NORGE_LANDNUMMER} from "../ducks/land";

const WS_TYPE_ADRESSE_HJEM = 'HJEM';
const WS_TYPE_ADRESSE_ARBEID = 'ARB';
const WS_TYPE_ADRESSE_FAKTURA = 'FAKTURA';

export const postBetalingsinfo = async (evuapiToken, deltakernr, kursId, betalingsinfo, defaultFakturamottaker, brukKontaktinfoadresse) => {
    const route = routes.FAKTURAINFO
        .replace(':deltakernr', deltakernr)
        .replace(':kursId', kursId)
    ;
    const config = createRequestConfig(evuapiToken);
    const payload = transformBetalingsinfoToWS(betalingsinfo, defaultFakturamottaker, brukKontaktinfoadresse);
    await axios.post(route, payload, config);
};

const transformBetalingsinfoToWS = (betalingsinfo, defaultFakturamottaker, brukKontaktinfoadresse) => {
    const adresse = betalingsinfo.adresse;
    return {
        fakturamottaker: betalingsinfo.fakturamottaker || defaultFakturamottaker,
        fakturareferanse: betalingsinfo.fakturareferanse,
        adressetype: getWSAdresseType(betalingsinfo, brukKontaktinfoadresse),
        fakturaadresse: {
            orgnr_faktura: betalingsinfo.organisasjonsnummer,
            adresselinje1: adresse && adresse.linje1,
            adresselinje2: adresse && adresse.linje2,
            sted: adresse && adresse.postadresse,
            postnummer: adresse && adresse.postnummer,
            landnummer: adresse && adresse.land,
            emailadresse_faktura: betalingsinfo.epost
        },
    }
};

const getWSAdresseType = (betalingsinfo, brukKontaktinfoadresse) => {
    const bedriftBetaler = betalingsinfo.bedriftBetaler;
    if (!brukKontaktinfoadresse) {
        return WS_TYPE_ADRESSE_FAKTURA;
    }
    return bedriftBetaler ? WS_TYPE_ADRESSE_ARBEID : WS_TYPE_ADRESSE_HJEM;
};

export const transformBetalingsinfoFromWS = soknad => createBetalingsinfo({
    fakturamottaker: soknad.fakturamottaker,
    organisasjonsnummer: soknad.orgnrFaktura && soknad.orgnrFaktura.toString(),
    fakturareferanse: soknad.fakturareferanse,
    epost: soknad.emailAdresseFaktura,
    adresseType: soknad.adressetypeFaktura,
    bedriftBetaler: getBedriftBetalerFromWSData(soknad),
    brukKontaktinfoadresseFormData: getBrukKontaktinfoadresse(soknad.adressetypeFaktura),
    adresse: createAdresse({
        linje1: soknad.adrlin1Faktura,
        linje2: soknad.adrlin2Faktura,
        land: soknad.adresseLandnrFaktura || NORGE_LANDNUMMER,
        postadresse: soknad.adrlin3Faktura,
        postnummer: soknad.postnrFaktura,
    }),
});

const getBrukKontaktinfoadresse = adressetypeFaktura => {
    if (!adressetypeFaktura)
        return true;
    return [WS_TYPE_ADRESSE_HJEM, WS_TYPE_ADRESSE_ARBEID].includes(adressetypeFaktura);
}

const getBedriftBetalerFromWSData = soknad => {
    if (soknad.adressetypeFaktura === WS_TYPE_ADRESSE_ARBEID) {
        return true;
    }
    if (soknad.adressetypeFaktura === WS_TYPE_ADRESSE_HJEM) {
        return false;
    }
    return Boolean(
        soknad.orgnrFaktura
        || soknad.fakturareferanse
        || soknad.emailAdresseFaktura
    );
};
