import React, {Component} from 'react';
import Telefoner from "./telefoner/Telefoner";
import Adresser from "./adresser/Adresser";
import Eposter from "./eposter/Eposter";
import withLandLoaded from "../../high-order-components/withLandLoaded";
import withProfildataLoaded from "../../high-order-components/withProfildataLoaded";

import './kontakinfo.scss';
import withSoknaderLoaded from "../../high-order-components/withSoknaderLoaded";

export class Kontaktinfo extends Component {
    render() {
        const {page} = this.props;
        return (
            <div className='kontaktinfo'>
                <div className='cards-container priority-1'>
                    <Telefoner page={page}/>
                    <Eposter page={page}/>
                </div>
                <div className='cards-container priority-2'>
                    <Adresser page={page}/>
                </div>
            </div>
        )
    }
}

export default withProfildataLoaded(
    withLandLoaded(
        withSoknaderLoaded(
            Kontaktinfo
        )
    )
);
