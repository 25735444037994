import React from "react";
import {useSelector} from "react-redux";
import {getUtestaendeFakturaer} from "../../ducks/fakturaoversikt";
import Feedback from "../Feedback";
import Text from "../../primitives/Text";
import UtestaendeFakturaRow from "./UtestaendeFakturaRow";
import "./utestaende.scss";



export const Utestaende = () => {

    const fakturaer = useSelector(getUtestaendeFakturaer);

    if (fakturaer.length === 0) {
        return (
            <Feedback role="tabpanel" id="utestaende">
                <Text id='du_har_ingen_utestående_fakturaer'/>
            </Feedback>

        )
    }

    return (
        <div role="tabpanel" className='utestaende' id="utestaende">
            <div className='table-header'>
                <Text className='dato' id={'dato'}/>
                <Text className='nummer' id={'nummer'}/>
                <span className='alert'/>
                <Text className='betalingsfrist' id={'betalingsfrist'}/>
                <Text className='belop' id={'beløp'}/>
                <span className='actions'/>
            </div>
            <ol>
                {fakturaer.map(faktura => <UtestaendeFakturaRow {...faktura} key={faktura.fakturanr}/>)}
            </ol>
        </div>
    )
};


export default Utestaende;