import React from "react";
import useInlineEdit from "../../../hooks/useInlineEdit";
import EditableField from "../../EditableField";
import {toUnicode} from "punycode";
import {workIconPath} from "../../../utils/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    changeFormField, EPOST_ARBEID_FELT, getErrorLagring,
    getFormDataEpostArbeid,
    lagreProfilFelt, resetErrorLagringForFelt,
    resetFormdata
} from "../../../ducks/profil";
import InputEpost from "./InputEpost";
import {getErrorMessageId, isEpostHasError} from "../../../utils/epostUtil";
import FormFocusControl from "../../inline-edit/FormFocusControl";
import InlineEdit from "../../inline-edit/InlineEdit";

export const Arbeidsepost = () => {

    const epostArbeid = useSelector(getFormDataEpostArbeid);
    const errorLagring = useSelector(state => getErrorLagring(state, EPOST_ARBEID_FELT));

    const isChangesValid = !isEpostHasError(epostArbeid, false);

    const dispatch = useDispatch();
    const submitChanges = () => dispatch(lagreProfilFelt(EPOST_ARBEID_FELT));
    const resetChanges = () => dispatch(resetFormdata());

    const [isInEditMode, openEditing, closeEditing, attemptedClosed] = useInlineEdit(submitChanges, isChangesValid, errorLagring);
    const errorMessageId = getErrorMessageId(epostArbeid, false, errorLagring, attemptedClosed);

    return (
        <InlineEdit
            resetChanges={resetChanges}
            isInEditMode={isInEditMode}
            openEditing={openEditing}
            closeEditing={closeEditing}
        >
            <FormFocusControl onFocusOut={closeEditing}>
                <Input epost={epostArbeid} errorMessageId={errorMessageId}/>
            </FormFocusControl>
            <Presentation epost={epostArbeid}/>
        </InlineEdit>
    );
};


const Input = ({isFocusOn, epost, errorMessageId}) => {
    const dispatch = useDispatch();
    const onChange = value => {
        dispatch(changeFormField(EPOST_ARBEID_FELT, value));
        dispatch(resetErrorLagringForFelt(EPOST_ARBEID_FELT));
    };

    return (
        <InputEpost
            epost={epost}
            onChange={onChange}
            errorMessageId={errorMessageId}
            isFocusOn={isFocusOn}
            labelId='arbeidsepost'
        />
    )
};


const Presentation = ({epost}) =>
    <EditableField value={epost && toUnicode(epost)}
                   valueNameId='arbeidsepost'
                   iconPath={workIconPath}
                   justIconInHeader
    />
;

export default Arbeidsepost;