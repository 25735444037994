import React from 'react';
import Feedback, {FEEDBACK_TYPE_VARSEL} from "./Feedback";
import Text from "../primitives/Text";

const NoRoute = () =>
    <Feedback type={FEEDBACK_TYPE_VARSEL}>
        <h2>
            404
        </h2>
        <p>
            <Text id="404_message"/>
        </p>
    </Feedback>
;

export default NoRoute;