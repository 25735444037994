import {useDispatch, useSelector} from "react-redux";
import {changeFormField, getForsoktOpprett, getKjonn, kanEndreKjonn} from "../../ducks/nyBruker";
import {getTranslationForList} from "../../ducks/i18n";
import {KJONN} from "../../utils/lists";
import Select from "../../primitives/Select";
import Error from "../Error";
import ReadOnlyField from "../ReadOnlyField";
import {getKjonnByCode} from "../../utils/common";
import React from "react";
import Text from "../../primitives/Text";
import {mdiHumanMaleFemale} from "@mdi/js";

const Kjonn = () => {

    const kjonn = useSelector(getKjonn);
    const kanEndres = useSelector(kanEndreKjonn);
    const kjonnListe = useSelector(state => getTranslationForList(state, KJONN));
    const forsoktOpprett = useSelector(getForsoktOpprett);
    const hasError = forsoktOpprett && !kjonn;
    const dispatch = useDispatch();
    const onChange = selectedOption => dispatch(changeFormField('kjonn', selectedOption.value));

    return (kanEndres ?
            <>
                <Select
                    label='kjonn'
                    iconPathForLabel={mdiHumanMaleFemale}
                    options={kjonnListe}
                    clearable={false}
                    hasError={hasError}
                    value={kjonn}
                    onChange={onChange}
                    autoComplete='sex'
                />
                {hasError &&
                <Error>
                    <Text id={'kjonn_obligatorisk'}/>
                </Error>
                }
            </>
            :
            <ReadOnlyField iconPath={mdiHumanMaleFemale} title='kjonn' value={<Text id={getKjonnByCode(kjonn)}/>}/>
    )
};

export default Kjonn;