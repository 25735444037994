import {combineReducers} from "redux"
import kursoversiktReducer, {NAME as kursoversikt} from './ducks/kursoversikt'
import kursinfoReducer, {NAME as kursinfo} from './ducks/kursinfo'
import soknaderReducer, {NAME as soknader} from './ducks/soknader'
import profilReducer, {NAME as profil} from './ducks/profil'
import pameldingReducer, {NAME as pamelding} from './ducks/pamelding'
import dokumenterReducer, {NAME as dokumenter} from './ducks/dokumenter'
import inngaendeDokumenterReducer, {NAME as inngaendeDokumenter} from './ducks/inngaendeDokumenter'
import popupReducer, {NAME as popup} from './ducks/popup'
import sessionReducer, {LOGIN_SET_INSTITUSJON, LOGOUT, NAME as session} from './ducks/session'
import i18nReducer, {NAME as i18n} from './ducks/i18n'
import nyBrukerReducer, {NAME as nyBruker} from './ducks/nyBruker'
import poststederReducer, {NAME as poststeder} from './ducks/poststeder'
import institusjonerReducer, {NAME as institusjoner} from './ducks/institusjoner'
import landReducer, {NAME as land} from './ducks/land'
import landskoderReducer, {NAME as landskoder} from './ducks/landskoder'
import sporreskjemaReducer, {NAME as sporreskjema} from './ducks/sporreskjema'
import planinfoReducer, {NAME as planinfo} from './ducks/planinfo'
import kursmanglerReducer, {NAME as kursmangler} from './ducks/kursmangler'
import appteksterReducer, {NAME as apptekster} from './ducks/apptekster'
import appegenskaperReducer, {NAME as appegenskaper} from './ducks/appegenskaper'
import menuReducer, {NAME as menu} from './ducks/menu'
import akseptanserReducer, {NAME as akseptanser} from './ducks/samtykke'
import tidligereKursReducer, {NAME as tidligereKurs} from './ducks/tidligereKurs'
import aktiveKursReducer, {NAME as aktiveKurs} from './ducks/aktiveKurs'
import foretaksgodkjenningReducer, {NAME as foretaksgodkjenning} from './ducks/foretaksgodkjenning'
import fakturaoversiktReducer, {NAME as fakturaoversikt} from './ducks/fakturaoversikt'
import arbeidserfaringReducer, {NAME as arbeidserfaring} from './ducks/arbeidserfaring'
import organisasjonerReducer, {NAME as organisasjoner} from './ducks/organisasjoner'
import soknadsdokumenterReducer, {NAME as soknadsdokumenter} from './ducks/soknadsdokumenter'
import syncDSFReducer, {NAME as syncDSF} from './ducks/syncDSF'
import betalingsinfoReducer, {NAME as betalingsinfo} from './ducks/betalingsinfo'
import eksamenReducer, {NAME as eksamen} from './ducks/eksamen'
import aktivInlineEditReducer, {NAME as aktivInlineEdit} from './ducks/aktivInlineEdit'
import apiInfoReducer, {NAME as apiInfo} from './ducks/apiInfo'


const rootReducer = (state, action) => {
    state = commonStateReducer(state, action);
    return appReducer(state, action);
};


function commonStateReducer(state, action) {
    switch (action.type) {
        case LOGOUT:
            return {
                [institusjoner]: state[institusjoner],
            };
        case LOGIN_SET_INSTITUSJON:
            return {
                [session]: state[session],
                [institusjoner]: state[institusjoner],
                [poststeder]: state[poststeder],
            };
        default:
            return state
    }
}


export const appReducer = combineReducers({
    [kursoversikt]: kursoversiktReducer,
    [kursinfo]: kursinfoReducer,
    [soknader]: soknaderReducer,
    [profil]: profilReducer,
    [pamelding]: pameldingReducer,
    [dokumenter]: dokumenterReducer,
    [inngaendeDokumenter]: inngaendeDokumenterReducer,
    [popup]: popupReducer,
    [session]: sessionReducer,
    [i18n]: i18nReducer,
    [nyBruker]: nyBrukerReducer,
    [poststeder]: poststederReducer,
    [institusjoner]: institusjonerReducer,
    [land]: landReducer,
    [landskoder]: landskoderReducer,
    [sporreskjema]: sporreskjemaReducer,
    [planinfo]: planinfoReducer,
    [kursmangler]: kursmanglerReducer,
    [apptekster]: appteksterReducer,
    [appegenskaper]: appegenskaperReducer,
    [menu]: menuReducer,
    [akseptanser]: akseptanserReducer,
    [tidligereKurs]: tidligereKursReducer,
    [aktiveKurs]: aktiveKursReducer,
    [foretaksgodkjenning]: foretaksgodkjenningReducer,
    [fakturaoversikt]: fakturaoversiktReducer,
    [arbeidserfaring]: arbeidserfaringReducer,
    [organisasjoner]: organisasjonerReducer,
    [soknadsdokumenter]: soknadsdokumenterReducer,
    [syncDSF]: syncDSFReducer,
    [betalingsinfo]: betalingsinfoReducer,
    [eksamen]: eksamenReducer,
    [aktivInlineEdit]: aktivInlineEditReducer,
    [apiInfo]: apiInfoReducer,
});

export default rootReducer