import React from "react";
import {connect} from 'react-redux';
import {getSporsmal, QUESTION_TYPE_POLAR} from "../../../ducks/sporreskjema";
import PropTypes from "prop-types";

import withSporreskjemaLoaded from "../../../high-order-components/withSporreskjemaLoaded";
import Text from "../../../primitives/Text";
import {mdiCommentQuestionOutline} from "@mdi/js";
import Icon from "../../../primitives/Icon";

class StegOversiktSporreskjema extends React.Component {

    getBesvarteSporsmal = () => {
        const {sporsmalListe} = this.props;
        return sporsmalListe.filter(sporsmal =>
            sporsmal.responsePolar !== undefined || sporsmal.responseText
        );
    };

    polarResponseToText = response => {
        switch (response) {
            case true:
                return <Text id='ja'/>;
            case false:
                return <Text id='nei'/>;
            default:
                return ``;
        }
    };

    render() {
        const besvarteSporsmal = this.getBesvarteSporsmal();
        return (
            <div className='steg-oversikt__steg__sporreskjema'>
                {besvarteSporsmal.length > 0 ?
                    <Sporsmal
                        sporsmalList={besvarteSporsmal}
                        polarResponseToText={this.polarResponseToText}
                    />
                    :
                    <Text id='ingen_sporsmal_besvart'/>
                }
            </div>
        )
    }
}

const Sporsmal = ({sporsmalList, polarResponseToText}) => sporsmalList.map(sporsmal => {
    return (
        <div key={sporsmal.kode} className='steg-oversikt__steg__sporreskjema__sporsmal'>
            <div className='steg-oversikt__steg__sporreskjema__sporsmal__tekst'>
                <Icon path={mdiCommentQuestionOutline}/>
                {sporsmal.text}
            </div>
            <div className='steg-oversikt__steg__sporreskjema__sporsmal__svar'>
                {sporsmal.type === QUESTION_TYPE_POLAR ? polarResponseToText(sporsmal.responsePolar) : sporsmal.responseText}
            </div>
        </div>
    )
});

StegOversiktSporreskjema.propTypes = {
    sporsmalListe: PropTypes.arrayOf(
        PropTypes.shape({
            kode: PropTypes.string.isRequired
            , text: PropTypes.string.isRequired
            , type: PropTypes.string.isRequired
            , responseText: PropTypes.string
            , responsePolar: PropTypes.bool
        })
    ).isRequired
};

const mapStateToProps = state => {
    return {
        sporsmalListe: getSporsmal(state),
    }
};

export default withSporreskjemaLoaded(
    connect(mapStateToProps)(StegOversiktSporreskjema)
);