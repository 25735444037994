import {useSelector} from "react-redux";
import {getAntallUbesvarteSoknaderForKurs} from "../../../ducks/foretaksgodkjenning";
import React from "react";

const NyeSoknaderAlert = ({kursId, foretakkode}) => {
    const antallUbesvarteSoknader = useSelector(state => getAntallUbesvarteSoknaderForKurs(state, kursId, foretakkode));
    return antallUbesvarteSoknader > 0 &&
        <div className='nye-soknader-alert'>
            {antallUbesvarteSoknader}
        </div>
};

export default NyeSoknaderAlert;