import React from "react";
import Checkbox from "../../../primitives/Checkbox";
import './steg-eksamen.scss';
import {useDispatch, useSelector} from "react-redux";
import Error from "../../Error";
import {
    endreSkalTaEksamenOgLagre,
    getEksamenBesvart,
    getErrorLagring,
    getIsLagring,
    getSkalTaEksamen
} from "../../../ducks/eksamen";
import {getForsoktNeste} from "../../../ducks/pamelding";
import FeedbackWSError from "../../FeedbackWSError";
import Text from "../../../primitives/Text";

const StegEksamen = () => {

    const skalTaEksamen = useSelector(getSkalTaEksamen);
    const isLagring = useSelector(getIsLagring);
    const besvart = useSelector(getEksamenBesvart);
    const forsoktNeste = useSelector(getForsoktNeste);
    const errorLagring = useSelector(getErrorLagring);

    const dispatch = useDispatch();
    const svarJa = () => dispatch(endreSkalTaEksamenOgLagre(true));
    const svarNei = () => dispatch(endreSkalTaEksamenOgLagre(false));

    return (
        <div className='steg-skal-ta-eksamen flat-card'>
            <fieldset>
                <h3>
                    <legend>
                        <Text id='vil_du_gå_opp_til_eksamen'/>
                    </legend>
                </h3>
                <p>
                    <Text id='dette_kurset_har_valgfri_eksamen'/>
                </p>
                <Checkbox
                    label={<Text id='ja'/>}
                    onChange={svarJa}
                    checked={skalTaEksamen === true}
                    loading={skalTaEksamen && isLagring}
                />
                <Checkbox
                    label={<Text id='nei'/>}
                    onChange={svarNei}
                    checked={skalTaEksamen === false}
                    loading={skalTaEksamen === false && isLagring}
                />
                {forsoktNeste && !besvart &&
                <Error>
                    <Text id='spørsmal_ikke_valgfritt_og_må_svares_på'/>
                    &nbsp;<Text id='vil_du_gå_opp_til_eksamen'/>
                </Error>
                }
                {errorLagring && <FeedbackWSError error={errorLagring}/>}
            </fieldset>
        </div>
    )
};

export default StegEksamen;