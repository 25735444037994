import React from 'react';
import {useDispatch} from 'react-redux'
import PropTypes from "prop-types";
import {changeLocale} from '../ducks/i18n';
import {ListOnClickLink} from "../primitives/ListLink";
import {getLocalLabel} from "../utils/i18nUtil";


export const LanguageOption = ({locale}) => {
    const dispatch = useDispatch();
    return (
        <ListOnClickLink onClick={() => dispatch(changeLocale(locale))}>
            <span lang={locale}>
                {getLocalLabel(locale)}
            </span>
        </ListOnClickLink>
    )
};

LanguageOption.propTypes = {
    locale: PropTypes.string.isRequired
};

export default LanguageOption;