import {connect} from "react-redux";
import Button, {PRIMARY} from "../../primitives/Button";
import {isSoknadActionPending} from "../../ducks/soknader";
import React from "react";
import PropTypes from "prop-types";
import Text from "../../primitives/Text";

const OKButtonWithFeedback = ({onClick, isSoknadActionPending}) => (
    <Button onClick={onClick}
            emphasis={PRIMARY}
            isInProgress={isSoknadActionPending}
    >
        <Text id='ok'/>
    </Button>
);

const mapStateToProps = state => {
    return {
        isSoknadActionPending: isSoknadActionPending(state),
    }
};

export default connect(mapStateToProps)(OKButtonWithFeedback);


OKButtonWithFeedback.propTypes = {
    onClick: PropTypes.func.isRequired,
    isSoknadActionPending: PropTypes.bool.isRequired,
};