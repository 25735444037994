import Button from "../../../primitives/Button";
import React, {useState} from "react";
import TidligereOpplastetBox from "./TidligereOpplastetBox";
import Text from "../../../primitives/Text";

const VelgFraTidligereOpplastetFiler = ({type}) => {

    const [showVelgboks, setShowVelgboks] = useState(false);

    if (showVelgboks) {
        return <TidligereOpplastetBox type={type} close={() => setShowVelgboks(false)}/>;
    }

    return (
        <Button onClick={() => setShowVelgboks(true)}
                className='velg-fra-tidligere-opplastet-button'>
            <Text id='velg_fra_tidligere_opplastet_filer'/> ↓
        </Button>
    )
};

export default VelgFraTidligereOpplastetFiler;
