import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    forsokOpprett,
    isFormValid as isFormValidSelector, isOppretting as isOpprettingSelector,
    opprettBruker,
} from "../../ducks/nyBruker";
import GrunnleggendeInformasjon from "./GrunnleggendeInformasjon";
import Annet from "./Annet";
import Epost from "./Epost";
import {PRIMARY} from "../../primitives/Button";
import Button from "../../primitives/Button";
import withPersonDataForNyBruker from "../../high-order-components/withPersonDataForNyBruker";
import './nyBruker.scss';
import PageTitle from "../PageTitle";
import Telefon from "./Telefon";
import {getIsSaksbehandlerLoggedInSomBruker, logoutUser} from "../../ducks/session";
import {compose} from "redux";
import {withEvuApiTokenLoaded} from "../../high-order-components/withEvuApiTokenLoaded";
import {showPopup} from "../../ducks/popup";
import requireDataportenLogin from "../../high-order-components/requireDataportenLogin";
import redirectIfDeltakerFinnes from "../../high-order-components/redirectIfDeltakerFinnes";
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import FeedbackOppretting from "./FeedbackOppretting";
import withLandLoaded from "../../high-order-components/withLandLoaded";
import Text from "../../primitives/Text";


export const NyBruker = () => {

    const isSaksbehandlerLoggedInSomBruker = useSelector(getIsSaksbehandlerLoggedInSomBruker);
    const isFormValid = useSelector(isFormValidSelector);
    const isOppretting = useSelector(isOpprettingSelector);
    const dispatch = useDispatch();


    if (isSaksbehandlerLoggedInSomBruker) {
        return (
            <Feedback type={FEEDBACK_TYPE_VARSEL}>
                <Text id='obs_deltakernummer_kan_ikke_opprettes_på_vegne_av_bruker'/>
            </Feedback>
        )
    }

    const onClickOpprett = () => {
        dispatch(forsokOpprett());
        if (isFormValid) {
            dispatch(opprettBruker())
        }
    };

    return (
        <div className='ny-bruker'>
            <PageTitle id='ny_bruker'/>
            <h1>
                <Text id='ny_bruker'/>
            </h1>
            <form>
                <div className='cards-container'>
                    <GrunnleggendeInformasjon/>
                    <Annet/>
                </div>
                <div className='cards-container'>
                    <Telefon/>
                    <Epost/>
                </div>
                <div className='action-panel'>
                    <FeedbackOppretting/>
                    <Button onClick={() => logout(dispatch)}>
                        <Text id='avbryt'/>
                    </Button>
                    <Button type='button'
                            emphasis={PRIMARY}
                            onClick={onClickOpprett}
                            isInProgress={isOppretting}
                    >
                        <Text id='opprett_bruker'/>
                    </Button>
                </div>
            </form>
        </div>
    )
};

const logout = (dispatch) => {
    const popupContent = {
        title: <Text id="logg_ut"/>,
        onOK: () => {
            dispatch(logoutUser());
        },
        children: <Text id="du_blir_nå_logget_ut"/>
    };
    dispatch(showPopup(popupContent));
};


export default compose(
    redirectIfDeltakerFinnes,
    requireDataportenLogin,
    withEvuApiTokenLoaded,
    withPersonDataForNyBruker,
    withLandLoaded,
)(NyBruker);