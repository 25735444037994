import React from "react";
import FormattedDate from "../../primitives/FormattedDate";
import "./datoer.scss"
import {mdiCalendarRange} from "@mdi/js";
import Icon from "../../primitives/Icon";


const Datoer = ({datoFra, datoTil}) => {

    if (!datoFra && !datoTil){
        return null;
    }

    const datoerAltTextId = datoFra && datoTil ? 'datoer' : 'dato';

    return <div className='datoer'>
        <Icon path={mdiCalendarRange} className='dato-icon' title={datoerAltTextId}/>
        <FormattedDate date={datoFra}/>
        {datoFra && datoTil && <span className='datoer__dash'>–</span>}
        <FormattedDate date={datoTil}/>
    </div>
};

export default Datoer;