import React from 'react';
import Feedback, {FEEDBACK_TYPE_VARSEL} from "../Feedback";
import {useSelector} from "react-redux";
import {getKurs} from "../../ducks/kursinfo";
import {getFulltKursVarsel} from "../../utils/kursVarsel";
import {getAntallLedigePlasser, getForetakNavn, getStatus} from "../../ducks/pamelding";
import {STATUS_IKKE_FULLFORT} from "../../utils/soknadStatus";
import Text from "../../primitives/Text";

const FulltKursVarsel = () => {
    const kurs = {
        ...useSelector(getKurs),
        antallLedigePlasser: useSelector(getAntallLedigePlasser),
    };
    const status = useSelector(getStatus);
    const foretakNavn = useSelector(getForetakNavn);
    const {varselTextId, varselTextForetakId} = getFulltKursVarsel(kurs, status !== STATUS_IKKE_FULLFORT);
    return varselTextId ?
        <Feedback type={FEEDBACK_TYPE_VARSEL} showIcon={false}>
            <Text
                id={foretakNavn ? varselTextForetakId : varselTextId}
                foretakNavn={foretakNavn}
            />
        </Feedback>
        : null;
};

export default FulltKursVarsel