import InlinePresentation from "./InlinePresentation";
import React from "react";
import PropTypes from "prop-types";
import FormResetAndClickOutsideControl from "./FormResetAndClickOutsideControl";
import useSavedAnimation from "./useSavedAnimation";
import './inline-edit.scss';
import ScrollIntoView from "./ScrollIntoView";

const InlineEdit = ({children, resetChanges, editDisabled, isInEditMode, openEditing, closeEditing, className}) => {

    const runSavedAnimation = useSavedAnimation(isInEditMode);

    const form = children[0];
    const presentation = children[1];
    const error = children[2];

    const classNamesMode = isInEditMode ? 'inline-edit--edit-mode' : 'inline-edit--presentation-mode';
    const classNamesSaved = runSavedAnimation ? 'inline-edit--saved-animation' : '';
    const classNames = `inline-edit ${classNamesMode} ${classNamesSaved} ${className}`;

    if (editDisabled) {
        return (
            <div className={className}>
                {presentation}
            </div>
        )
    }

    return isInEditMode ?
        <ScrollIntoView>
            <FormResetAndClickOutsideControl
                className={classNames}
                closeEditing={closeEditing}
                resetChanges={resetChanges}
            >
                {error}
                {form}
            </FormResetAndClickOutsideControl>
        </ScrollIntoView>

        :
        <InlinePresentation openEditing={openEditing} className={classNames}>
            {presentation}
        </InlinePresentation>

};


InlineEdit.propTypes = {
    children: PropTypes.node.isRequired,
    resetChanges: PropTypes.func,
    editDisabled: PropTypes.bool,
    isInEditMode: PropTypes.bool,
    openEditing: PropTypes.func.isRequired,
    closeEditing: PropTypes.func.isRequired,
    className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
};

export default InlineEdit;