import React, {useState} from 'react';
import './tabs.scss'
import Text from "../primitives/Text";

const Tabs = ({labels, contents}) => {

    const [activeTab, setActiveTab] = useState(0);

    return <div className='tabs'>
        <div className='tabs__panel' role='tablist'>
            {labels.map((label, index) =>
                <button id={'tab_' + label}
                        onClick={() => setActiveTab(index)}
                        key={index}
                        className={index === activeTab ? 'active-tab' : ''}
                        role='tab'
                        aria-selected={index === activeTab}
                        aria-controls={label}>
                    <Text id={label}/>
                </button>
            )}
        </div>
        <div className='tabs__content'>
            {contents[activeTab]}
        </div>
    </div>
};


export default Tabs;