import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {getRedirectionPath, isLoggedIn} from '../ducks/session';
import Redirect from "../components/Redirect";

export class RedirectIfLoggedIn extends React.Component {

    render() {
        const {children, isLoggedIn, redirectionPath} = this.props;
        if (isLoggedIn) {
            return <Redirect to={redirectionPath}/>
        }

        return children;
    }
}

const mapStateToProps = state => {
    return {
        isLoggedIn: isLoggedIn(state),
        redirectionPath: getRedirectionPath(state),
    }
};

RedirectIfLoggedIn.propTypes = {
    children: PropTypes.node.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    redirectionPath: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(RedirectIfLoggedIn)

