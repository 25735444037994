import Input from "../../primitives/Input";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Organisasjonsnavn} from "./Organisasjonsnavn";
import {getAPIError, resetError} from "../../ducks/organisasjoner";
import {getOrganisasjonsErrorMessageId, ORGANISASJONSNUMMER_LENGDE} from "../../utils/organisasjonUtil";
import InPlaceError from "../InPlaceError";
import {
    changeBetalingsinfoField,
    getErrorLagringForFelt,
    getOrganisasjonsnummer,
    lagreBetalingsinfo
} from "../../ducks/betalingsinfo";
import ErrorFormSaving from "../ErrorFormSaving";

export const FELT_ORGANISASJON = 'organisasjon';

export const InputOrganisasjon = () => {

    const organisasjonsnummer = useSelector(getOrganisasjonsnummer);
    const apiError = useSelector(getAPIError);
    const errorLagring= useSelector(state => getErrorLagringForFelt(state, FELT_ORGANISASJON));
    const [focused, setFocused] = useState(false);
    const errorMessageId = getOrganisasjonsErrorMessageId(organisasjonsnummer, focused, apiError);
    const dispatch = useDispatch();
    const onBlur = () => dispatch(lagreBetalingsinfo(FELT_ORGANISASJON));

    return (
        <div className='input-organisasjonsnummer bedrift-input'>
            <div className='input-organisasjonsnummer__input-and-error-wrapper'>
                <Input label='organisasjonsnummer'
                       value={organisasjonsnummer}
                       onChange={e => {
                           dispatch(changeBetalingsinfoField('organisasjonsnummer', e.target.value));
                           dispatch(resetError());
                       }}
                       maxLength={ORGANISASJONSNUMMER_LENGDE}
                       hasError={errorMessageId || errorLagring}
                       onBlur={() => {
                           setFocused(false);
                           onBlur();
                       }}
                       onFocus={() => setFocused(true)}
                />
                <InPlaceError errorMessageId={errorMessageId}/>
                {errorLagring &&
                <ErrorFormSaving
                    error={errorLagring}
                    provIgjen={onBlur}
                />
                }
            </div>
            <Organisasjonsnavn organisasjonsnummer={organisasjonsnummer}/>
        </div>
    )
};

