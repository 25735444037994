import React from "react";
import PropTypes from "prop-types";
import Text from "../../../primitives/Text";

const Filter = ({labelTextId, children}) =>
    <fieldset className="filtre__filter">
        <legend>
            <Text id={labelTextId}/>
        </legend>
        {children}
    </fieldset>
;

Filter.propTypes = {
    labelTextId: PropTypes.string.isRequired,
};

export default Filter;