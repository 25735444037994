import React from 'react'
import PropTypes from "prop-types"
import './button.scss'
import {checkmarkIconPath} from "../utils/icons";
import Spinner from "../components/Spinner";
import Text from "./Text";
import Icon from "./Icon";

export const PRIMARY = 'primary';
export const SECONDARY = 'secondary';
export const TERTIARY = 'tertiary';

const Button = (props) => {
    const {children, emphasis = SECONDARY, iconPath, danger, altText, className = '', isInProgress, isSuccessfullyDone, type='button', ...restOfProps} = props;

    let classNames = `button ${emphasis} ${danger ? 'danger' : ''} ${className} `;
    classNames += iconPath ? (children ? 'with-icon' : 'icon-button') : '';

    return (
        isInProgress ?
            <Spinner className='for-button loader'/>
            :
            isSuccessfullyDone ?
                <Icon path={checkmarkIconPath} className='for-button done-icon'/>
                :
                <button className={classNames} {...restOfProps} type={type}>
                    {iconPath && <Icon path={iconPath}/>}
                    {children}
                    {altText && <span className='tooltip'><Text id={altText}/></span>}
                </button>
    );
};


Button.propTypes = {
    emphasis: PropTypes.oneOf([PRIMARY, SECONDARY, TERTIARY]),
    children: PropTypes.node,
    iconPath: PropTypes.string,
    altText: PropTypes.string,
    altTextValues: PropTypes.object,
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isInProgress: PropTypes.bool,
    isSuccessfullyDone: PropTypes.bool,
    type: PropTypes.string,
};


export default Button