import React from "react";
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import withPlaninfoLoaded from "../../../high-order-components/withPlaninfoLoaded";
import {getTranslatedPlaninfoDataMedBekreftelse} from "../../../ducks/planinfo";
import {formatDate} from "../../../ducks/i18n";
import {removeHtml} from "../../../utils/common";
import Text from "../../../primitives/Text";
import {mdiCommentCheckOutline} from "@mdi/js";
import Icon from "../../../primitives/Icon";


class StegOversiktPlaninfo extends React.Component {
    render() {
        const {planinfoData, formatDate} = this.props;
        return (
            <div className='steg-oversikt__steg__planinfo'>
                {planinfoData.length > 0 ? <Bekreftelser planinfoData={planinfoData} formatDate={formatDate} /> : <Text id='ingen_relevant_informasjon'/>}
            </div>
        )
    }
}

const Bekreftelser = ({planinfoData, formatDate}) => planinfoData.map(data => {
    const dato_bekreftet = data.dato_bekreftet;
    return (
        <div className='steg-oversikt__steg__planinfo__data' key={data.kode}>
            <div className='steg-oversikt__steg__planinfo__data__ikon'>
                <Icon path={mdiCommentCheckOutline}/>
            </div>
            <div className='steg-oversikt__steg__planinfo__data__body'>
                <div className='steg-oversikt__steg__planinfo__data__body__merknadtekst'>
                    {removeHtml(data.merknadtekst)}
                </div>
                <div className='steg-oversikt__steg__planinfo__data__body__bekreftelsesdato'>
                    <Text
                        id='bekreftet_dato'
                        values={{dato: formatDate(dato_bekreftet)}}
                    />
                </div>
            </div>
        </div>
    )
});

StegOversiktPlaninfo.propTypes = {
    planinfoData: PropTypes.arrayOf(
        PropTypes.shape({
            dato_bekreftet: PropTypes.object,
            merknadtekst: PropTypes.string.isRequired,
            kode: PropTypes.string.isRequired,
        })
    ).isRequired
};

const mapStateToProps = state => {
    return {
        planinfoData: getTranslatedPlaninfoDataMedBekreftelse(state),
        formatDate: dato => formatDate(state, dato, false),
    }
};

export default withPlaninfoLoaded(
    connect(mapStateToProps)(StegOversiktPlaninfo)
);