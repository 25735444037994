import React from 'react';
import PropTypes from 'prop-types';
import StegHeader from './StegHeader';
import StegActions from '../steg-actions/StegActions';
import StegContent from './StegContent';
import {generateStegHeaderRef} from "../../../utils/common";
import {useDispatch, useSelector} from "react-redux";
import {erAktivtSteg, erStegPabegynt, getStegValidators} from "../../../ducks/pamelding";
import StegEksamen from "./StegEksamen";
import StegProfil from "./StegProfil";
import Soknadsdokumenter from "../../steg-dokumentasjon/StegDokumentasjon";
import StegSporreskjema from "./StegSporreskjema";
import StegPlaninfo from "./StegPlaninfo";
import StegOversikt from "../steg-oversikt/StegOversikt";
import StegSamtykke from "./StegSamtykke";
import Betalingsinformasjon from "../../betalingsinformasjon/Betalingsinformasjon";
import {lagreBetalingsinfo} from "../../../ducks/betalingsinfo";
import {FELT_BRUK_KONTAKTINFOADRESSE} from "../../betalingsinformasjon/VelgAdresse";

export const STEG_TYPE_EKSAMEN = 'EKSAMEN';
export const STEG_TYPE_PROFIL = 'PROFIL';
export const STEG_TYPE_DOKUMENTASJON = 'DOKUMENTASJON';
export const STEG_TYPE_FAKTURAINFO = 'FAKTURAINFO';
export const STEG_TYPE_SPORRESKJEMA = 'SPORRESKJEMA';
export const STEG_TYPE_PLANINFO = 'PLANINFO';
export const STEG_TYPE_SAMTYKKE = 'SAMTYKKE';
export const STEG_TYPE_OVERSIKT = 'OVERSIKT';

const useStegComponent = (stegType) => {
    const validators = useSelector(getStegValidators);
    const dispatch = useDispatch();
    const components = {
        [STEG_TYPE_EKSAMEN]: StegEksamen,
        [STEG_TYPE_PROFIL]: StegProfil,
        [STEG_TYPE_DOKUMENTASJON]: Soknadsdokumenter,
        [STEG_TYPE_FAKTURAINFO]: Betalingsinformasjon,
        [STEG_TYPE_SPORRESKJEMA]: StegSporreskjema,
        [STEG_TYPE_PLANINFO]: StegPlaninfo,
        [STEG_TYPE_SAMTYKKE]: StegSamtykke,
        [STEG_TYPE_OVERSIKT]: StegOversikt,
    };
    const onNextClick = {
        [STEG_TYPE_FAKTURAINFO]: () => dispatch(lagreBetalingsinfo(FELT_BRUK_KONTAKTINFOADRESSE, true)),
    };
    return {
        StegComponent: components[stegType] || StegOversikt,
        validator: validators[stegType],
        onNextClick: onNextClick[stegType]
    }
};

const Steg = ({steg}) => {

    const {
        id,
        type,
        sekvensnr,
        redigerbart,
        valgfritt,
        error,
        actions,
        settTilbake,
    } = steg;

    const aktivt = useSelector(state=>erAktivtSteg(state, id));
    const pabegynt = useSelector(state=>erStegPabegynt(state, id));

    const {StegComponent, validator, onNextClick} = useStegComponent(type);

    const classNames = `steg ${pabegynt && redigerbart && 'clickable'} ${aktivt ? 'active' : 'inactive'} ${error && 'error'}`;

    const headerRef = generateStegHeaderRef(id);
    const contentId = `${headerRef}-content`;

    return (
        <div className={classNames}>
            <StegHeader
                buttonId={headerRef}
                contentId={contentId}
                id={id}
                type={type}
                sekvensnr={sekvensnr}
                valgfritt={valgfritt}
                error={error}
            />
            <StegContent
                buttonId={headerRef}
                contentId={contentId}
                aktivt={aktivt}
            >
                <StegComponent />
                <StegActions
                    id={id}
                    type={type}
                    actions={actions}
                    validator={validator}
                    onNextClick={onNextClick}
                    settTilbake={settTilbake}
                />
            </StegContent>
        </div>
    )
};

Steg.propTypes = {
    steg: PropTypes.shape({
        id: PropTypes.number.isRequired,
        type: PropTypes.string.isRequired,
        error: PropTypes.string,
    }).isRequired,
};

export default Steg;
