import React from "react";
import {useSelector} from "react-redux";
import {formatDate} from "../ducks/i18n";

const FormattedDate = ({className, date, withTime}) => {
    const formattedDate = useSelector(state => formatDate(state, date, withTime));
    return date ? <span className={className}>{formattedDate}</span> : null;
};

export default FormattedDate;
