import React from 'react';
import Text from "../../../primitives/Text";
import {useSelector} from "react-redux";
import {getKursHarGodkjenning} from "../../../ducks/foretaksgodkjenning";
import Actions from "./Actions";
import Spinner from "../../Spinner";
import {mdiAlertCircleOutline, mdiCheck, mdiClose} from "@mdi/js";
import Icon from "../../../primitives/Icon";

const Soknad = ({deltakernr, kursId, foretakkode, navn, epost, status, isGodkjent, pending}) => {
    const {className} = statusFromIsGodkjent(isGodkjent);
    const harGodkjenning = useSelector(state => getKursHarGodkjenning(state, kursId, foretakkode));
    const Navn = () => <div className={'navn'}>{navn}</div>;
    const Epost = () => <div className={'epost'}>{epost}</div>;
    return (
        <tr className={className}>
            {harGodkjenning &&
            <td className='godkjenning'>
                <StatusGodkjenning isGodkjent={isGodkjent}/>
            </td>
            }
            <td className='soker'>
                <Navn/>
                <Epost/>
            </td>
            <td className='status'>
                <StatusSoknad status={status}/>
            </td>
            <td className='alle-felt'>
                {harGodkjenning && <StatusGodkjenning isGodkjent={isGodkjent}/>}
                <Navn/>
                <Epost/>
                <StatusSoknad status={status}/>
            </td>
            <td>
                {pending ?
                    <Spinner/>
                    :
                    <Actions
                        deltakernr={deltakernr}
                        kursId={kursId}
                        foretakkode={foretakkode}
                        harGodkjenning={harGodkjenning}
                        isGodkjent={isGodkjent}
                        status={status}
                    />
                }

            </td>
        </tr>
    )
};

const StatusGodkjenning = ({isGodkjent}) => {
    const {textId, iconPath} = statusFromIsGodkjent(isGodkjent);
    return (
        <div className={'status-godkjenning'}>
            <Icon path={iconPath}/>
            <Text id={textId}/>
        </div>
    )
};

const StatusSoknad = ({status}) =>
    <div className={'status-soknad'}>
        <Text id={`STATUS/${status}`}/>
    </div>;

const statusFromIsGodkjent = isGodkjent => {
    switch (isGodkjent) {
        case undefined:
            return {
                textId: 'ny',
                className: 'ny',
                iconPath: mdiAlertCircleOutline,
            };
        case false:
            return {
                textId: 'avvist',
                className: 'avvist',
                iconPath: mdiClose,
            };
        case true:
        default:
            return {
                textId: 'godkjent',
                className: 'godkjent',
                iconPath: mdiCheck,
            };
    }
};

export default Soknad;