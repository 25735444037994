import * as pameldingApi from '../services/pameldingApi'
import {
    getAktueltDeltakernr,
    getEvuapiToken,
    getInstitusjonsnr,
    skrivingSkalBlokkeres
} from "./session";
import {getHarKravOmHoyereUtd, getKursId as getValgtKursId, getKursIdObject} from "./kursinfo";
import {loadSoknader} from "./soknader";
import {
    STEG_TYPE_SAMTYKKE,
    STEG_TYPE_DOKUMENTASJON,
    STEG_TYPE_EKSAMEN,
    STEG_TYPE_OVERSIKT,
    STEG_TYPE_PLANINFO,
    STEG_TYPE_PROFIL,
    STEG_TYPE_SPORRESKJEMA,
    STEG_TYPE_FAKTURAINFO
} from "../components/pamelding/steg/Steg";
import {
    ACTION_TYPE_ENDRE, ACTION_TYPE_ENDRE_FAKTURAINFO,
    actionToFristTypes,
    FRIST_UBEGRENSET,
    STATUS_IKKE_FULLFORT,
    STATUS_MANGLER_DOKUMENTASJON,
    STATUS_SENDT_TIL_GODKJENNING
} from "../utils/soknadStatus";
import {isProfilFormValid, loadProfil, syncMedKorr} from "./profil";
import {getIsSporreskjemaValid} from "./sporreskjema";
import {isPlaninfobekreftelseValid} from "./planinfo";
import {isSamtykkerValid} from "./samtykke";
import {ERROR_CODE_SOKNAD_FINNES, ERROR_CODE_SOKNAD_FULLFORT, getRequestErrorCode} from "../utils/request";
import {resetSoknadstilknytningPending} from "./arbeidserfaring";
import {isBetalingsinfoValid, loadBetalingsinfoSuccess} from "./betalingsinfo";
import {getEksamenBesvart, loadSkalTaEksamenSuccess} from "./eksamen";
import {syncMedDSF} from "./syncDSF";
import {startResultatUtveksling} from "../services/resultatutvekslingAPI";

export const NAME = 'pamelding';

export const LOAD_PAMELDING_PENDING = `${NAME}/LOAD_PAMELDING_PENDING`;
export const LOAD_PAMELDING_SUCCESS = `${NAME}/LOAD_PAMELDING_SUCCESS`;
export const LOAD_PAMELDING_FAILURE = `${NAME}/LOAD_PAMELDING_FAILURE`;

export const LAGRE_PAMELDING_PENDING = `${NAME}/LAGRE_PAMELDING_PENDING`;
export const LAGRE_PAMELDING_SUCCESS = `${NAME}/LAGRE_PAMELDING_SUCCESS`;
export const LAGRE_PAMELDING_FAILURE = `${NAME}/LAGRE_PAMELDING_FAILURE`;
export const LAGRE_PAMELDING_RESET = `${NAME}/LAGRE_RESET`;

export const ENDRE_STEG_FORSOK = `${NAME}/ENDRE_STEG_FORSOK`;
export const ENDRE_STEG = `${NAME}/ENDRE_STEG`;
export const TILBAKE_TIL_SISTE_STEG_TOGGLE = `${NAME}/TILBAKE_TIL_SISTE_STEG_TOGGLE`;
export const SETT_STEG_TIL_FULLFORT = `${NAME}/SETT_STEG_TIL_FULLFORT`;
export const SETT_STEG_TIL_IKKE_FULLFORT = `${NAME}/SETT_STEG_TIL_IKKE_FULLFORT`;
export const SET_FORETAKKODE_OG_HASH_FOR_NY_SOKNAD = `${NAME}/SET_FORETAKKODE_OG_HASH_FOR_NY_SOKNAD`;

export const IDLE_STEG_ID = -1;

export function loadPameldingPending(kursId) {
    return {
        type: LOAD_PAMELDING_PENDING,
        kursId: kursId,
    };
}

export const loadPameldingSuccess = data => {
    const {betalingsinfo, ...pameldingsdata} = data;
    return {
        type: LOAD_PAMELDING_SUCCESS,
        data: pameldingsdata,
        betalingsinfo,
    }
};

export function loadPameldingFailure(error) {
    return {
        type: LOAD_PAMELDING_FAILURE,
        error: error
    };
}

export function lagrePameldingPending() {
    return {
        type: LAGRE_PAMELDING_PENDING,
    };
}

export function lagrePameldingSuccess(nyStatus) {
    return {
        type: LAGRE_PAMELDING_SUCCESS,
        nyStatus,
    };
}

export function lagrePameldingFailure(error) {
    return {
        type: LAGRE_PAMELDING_FAILURE,
        error: error
    };
}

export function lagrePameldingResett() {
    return {
        type: LAGRE_PAMELDING_RESET,
    };
}


export const loadPamelding = () => async (dispatch, getState) => {
    const state = getState();
    const kursId = getValgtKursId(state);
    const deltakernr = getAktueltDeltakernr(state);
    const evuapiToken = getEvuapiToken(state);
    const instnr = getInstitusjonsnr(state);
    const kursIdObject = getKursIdObject(state);
    const skrivingBlokkeres = skrivingSkalBlokkeres(state);
    const foretakkode = getForetakkodeNySoknad(state);
    const hash = getHashNySoknad(state);
    const harKravOmHoyereUtd = getHarKravOmHoyereUtd(state);
    dispatch(loadPameldingPending(kursId));
    dispatch(resetSoknadstilknytningPending());
    try {
        const response = await pameldingApi.getPameldingsdata(evuapiToken, instnr, kursIdObject, deltakernr, skrivingBlokkeres, foretakkode, hash);
        const {isNySoknad, betalingsinfo, skalTaEksamen, ...pameldinsdata} = response;
        if (isNySoknad) {
            await dispatch(syncMedKorr(false));
            await dispatch(syncMedDSF(false));
            await dispatch(loadProfil());
            if (harKravOmHoyereUtd) {
                await startResultatUtveksling(deltakernr, evuapiToken);
            }
        }
        dispatch(loadPameldingSuccess(pameldinsdata));
        dispatch(loadBetalingsinfoSuccess(betalingsinfo));
        dispatch(loadSkalTaEksamenSuccess(skalTaEksamen));
    } catch (error) {
        dispatch(loadPameldingFailure(error))
    }
};

export const setForetakkodeOgHashForNySoknad = (foretakkode, hash) => ({
    type: SET_FORETAKKODE_OG_HASH_FOR_NY_SOKNAD,
    foretakkode,
    hash,
});


export function fullforSoknad() {
    return function (dispatch, getState) {
        const state = getState();
        const evuapiToken = getEvuapiToken(state);
        const deltakernr = getAktueltDeltakernr(state);
        const kursId = getValgtKursId(state);
        const aktivtStegnr = getAktivtStegnr(state);
        const stegType = getStegTypeFromId(state, aktivtStegnr);
        dispatch(lagrePameldingPending());
        return pameldingApi.fullforSoknad(evuapiToken, deltakernr, kursId).then(
            nyStatus => {
                dispatch(lagrePameldingSuccess(nyStatus));
                dispatch(loadSoknader());
                pameldingApi.postFullfortSteg(evuapiToken, kursId, deltakernr, stegType).then().catch()
            },
            error => {
                if ([ERROR_CODE_SOKNAD_FINNES, ERROR_CODE_SOKNAD_FULLFORT].includes(getRequestErrorCode(error))) {
                    dispatch(loadPamelding());
                } else {
                    dispatch(lagrePameldingFailure(error));
                }
            }
        );
    };
}

export const fullforSteg = () => {
    return (dispatch, getState) => {
        const state = getState();
        const aktivtStegnr = getAktivtStegnr(state);
        const nyttStegnr = getNesteRedigerbareSteg(state);
        const fullfort = erStegFullfort(state, aktivtStegnr);
        const evuapiToken = getEvuapiToken(state);
        const deltakernr = getAktueltDeltakernr(state);
        const kursId = getValgtKursId(state);
        const stegType = getStegTypeFromId(state, aktivtStegnr);
        dispatch(endreAktivtSteg(nyttStegnr));
        if (!fullfort) {
            dispatch(settStegTilFullfort(aktivtStegnr));
            pameldingApi.postFullfortSteg(evuapiToken, kursId, deltakernr, stegType).then().catch()
        }
    }
};

export const forsokNeste = () => ({
    type: ENDRE_STEG_FORSOK,
});

export const settStegTilFullfort = stegnr => ({
    type: SETT_STEG_TIL_FULLFORT,
    stegnr,
});

export const settStegTilIkkeFullfort = stegnr => ({
    type: SETT_STEG_TIL_IKKE_FULLFORT,
    stegnr,
});

export function endreAktivtSteg(nyttStegnr) {
    return {
        type: ENDRE_STEG,
        nyttStegnr,
    }
}

export function tilbakeTilSisteSteg(stegnr) {
    return {
        type: TILBAKE_TIL_SISTE_STEG_TOGGLE,
        stegnr,
    }
}

export const createFrist = props => {
    const {
        type,
        dato,
    } = props;
    return {
        type,
        dato,
    };
};


export const initialState = {
    isLoading: false,
    loadError: undefined,
    lagreError: undefined,
    isLagring: false,
    isLagringSuksessfull: false,
    kursId: undefined,
    erSoknadStengt: false,
    aktivtStegnr: 0,
    pabegyntStegnr: 0,
    antallLedigePlasser: undefined,
    forsoktNeste: false,
    status: undefined,
    kanFullfores: true,
    steg: [],
    frister: [],
    foretakkode: undefined,
    foretaknavn: undefined,
    foretakkodeNySoknad: undefined,
    hashNySoknad: undefined,
};

function pamelding(state = initialState, action) {
    switch (action.type) {
        case LOAD_PAMELDING_PENDING:
        case LOAD_PAMELDING_SUCCESS:
        case LOAD_PAMELDING_FAILURE:
            return load(state, action);
        case LAGRE_PAMELDING_PENDING:
        case LAGRE_PAMELDING_SUCCESS:
        case LAGRE_PAMELDING_FAILURE:
        case LAGRE_PAMELDING_RESET:
            return lagre(state, action);
        case SET_FORETAKKODE_OG_HASH_FOR_NY_SOKNAD:
            return {
                ...state,
                foretakkodeNySoknad: action.foretakkode,
                hashNySoknad: action.hash,
            };
        case TILBAKE_TIL_SISTE_STEG_TOGGLE:
        case SETT_STEG_TIL_FULLFORT:
        case SETT_STEG_TIL_IKKE_FULLFORT:
            return {
                ...state,
                steg: state.steg.map((steg) => stegReducer(steg, action))
            };
        case ENDRE_STEG:
            return {
                ...state,
                aktivtStegnr: action.nyttStegnr,
                pabegyntStegnr: action.nyttStegnr > state.pabegyntStegnr ? action.nyttStegnr : state.pabegyntStegnr,
                forsoktNeste: false,
            };
        case ENDRE_STEG_FORSOK:
            return {
                ...state,
                forsoktNeste: true,
            };
        default:
            return state;
    }
}

function load(state = initialState, action) {
    switch (action.type) {
        case LOAD_PAMELDING_PENDING:
            return {
                ...state,
                isLoading: true,
                kursId: action.kursId,
                loadError: undefined,
            };
        case LOAD_PAMELDING_SUCCESS:
            const data = action.data;
            const aktivtStegnr = getAktivtSteg(data.steg, data.status, data.kanFullfores);
            const steg = settOppStateForAlleSteg(data.steg, data.status, data.kanFullfores);
            return {
                ...state,
                ...data,
                isLoading: false,
                aktivtStegnr: aktivtStegnr,
                pabegyntStegnr: getAktivtStegFromFullfort(steg),
                steg,
            };
        case LOAD_PAMELDING_FAILURE:
            return {
                ...state,
                isLoading: false,
                loadError: action.error
            };
        default:
            return state;
    }
}


const getAktivtSteg = (alleSteg, status, kanFullfores) => {
    if (status === STATUS_MANGLER_DOKUMENTASJON) {
        return alleSteg.findIndex(steg => steg.type === STEG_TYPE_DOKUMENTASJON);
    }
    if (kanFullfores) {
        return getAktivtStegFromFullfort(alleSteg);
    }
    return alleSteg.findIndex(steg => steg.type === STEG_TYPE_OVERSIKT);
};

const getAktivtStegFromFullfort = alleSteg => {
    let stegnr = -1;
    alleSteg.forEach(steg => {
        if (steg.id > stegnr && steg.fullfort && steg.redigerbart) {
            stegnr = steg.id;
        }
    });
    return stegnr === alleSteg.length - 1 ? stegnr : stegnr + 1;
};

const settOppStateForAlleSteg = (alleSteg, status, kanFullfores) => {
    const forsteRedigerbareSteg = getForsteRedigerbareSteg(alleSteg);
    return alleSteg.map(steg =>
        settOppSteg(steg, status, kanFullfores, forsteRedigerbareSteg)
    );
};

const settOppSteg = (steg, status, kanFullfores, forsteRedigerbareSteg) => {
    return {
        ...steg,
        actions: {
            forrige: !erForsteRedigerbareSteg(steg, forsteRedigerbareSteg),
            neste: !erSisteSteg(steg),
            avbryt: status === STATUS_IKKE_FULLFORT && kanFullfores,
            fullfor: erSisteSteg(steg) && status === STATUS_IKKE_FULLFORT,
        },
        settTilbake: false,
        forsoktNeste: false,
    }
};

const getForsteRedigerbareSteg = alleSteg => alleSteg.find(steg => steg.redigerbart);
const erForsteRedigerbareSteg = (steg, forsteRedigerbareSteg) => steg.id === forsteRedigerbareSteg.id;
export const erSisteSteg = steg => erSisteStegType(steg.type);
export const erSisteStegType = type => type === STEG_TYPE_OVERSIKT;

function lagre(state = initialState, action) {
    switch (action.type) {
        case LAGRE_PAMELDING_PENDING:
            return {
                ...state,
                isLagring: true,
                lagreError: undefined
            };
        case LAGRE_PAMELDING_SUCCESS:
            return {
                ...state,
                isLagring: false,
                isLagringSuksessfull: true,
                lagreError: undefined,
                erSoknadStengt: true,
                status: action.nyStatus,
            };
        case LAGRE_PAMELDING_FAILURE:
            return {
                ...state,
                isLagring: false,
                isLagringSuksessfull: false,
                lagreError: action.error,
            };
        case LAGRE_PAMELDING_RESET:
            return {
                ...state,
                isLagring: false,
                isLagringSuksessfull: false,
                erSoknadStengt: false,
                lagreError: undefined,
            };
        default:
            return state;
    }
}

function stegReducer(steg, action) {
    switch (action.type) {
        case TILBAKE_TIL_SISTE_STEG_TOGGLE:
            return steg.id === action.stegnr ?
                {
                    ...steg,
                    settTilbake: !steg.settTilbake
                } : steg;
        case SETT_STEG_TIL_FULLFORT:
            return steg.id === action.stegnr ?
                {
                    ...steg,
                    fullfort: true
                } : steg;
        case SETT_STEG_TIL_IKKE_FULLFORT:
            return steg.id === action.stegnr ?
                {
                    ...steg,
                    fullfort: false
                } : steg;
        default:
            return steg;
    }
}

export function getState(state) {
    return state[NAME];
}

export function isPameldingLoading(state) {
    return getState(state).isLoading;
}

export function getLoadError(state) {
    return getState(state).loadError;
}

export function getLagreError(state) {
    return getState(state).lagreError;
}

export function getAlleSteg(state) {
    return getState(state).steg;
}

export function getStatus(state) {
    return getState(state).status;
}

export function erSoknadFullfort(state) {
    return getStatus(state) !== STATUS_IKKE_FULLFORT;
}

export function geHarStegEksamen(state) {
    const steg = getAlleSteg(state);
    return steg.some(steg => steg.type === STEG_TYPE_EKSAMEN);
}

export function getNesteRedigerbareSteg(state) {
    const currentSteg = getAktivtStegnr(state);
    const alleSteg = getAlleSteg(state);
    return alleSteg.find(steg => (steg.id > currentSteg && steg.redigerbart) || steg.type === STEG_TYPE_OVERSIKT).id;
}

export function getForrigeRedigerbareSteg(state) {
    const currentSteg = getAktivtStegnr(state);
    const alleSteg = [...getAlleSteg(state)].reverse();
    return alleSteg.find(steg => (steg.id < currentSteg && steg.redigerbart) || steg.id === 0).id;
}

export const getForsoktNeste = state => getState(state).forsoktNeste;

export const kanFullfores = state => getState(state).kanFullfores;

export const isLagring = state => getState(state).isLagring;
export const isLagringSuksessfull = state => getState(state).isLagringSuksessfull;
export const hasPameldingFeilet = state => !isLagringSuksessfull(state) && getLagreError(state) !== undefined;

export function getSisteSteg(state) {
    const alleSteg = getAlleSteg(state);
    return alleSteg[alleSteg.length - 1];
}

export const getKursId = state => getState(state).kursId;

export const isDataLoaded = state => Boolean(getAlleSteg(state) && getAlleSteg(state).length) && getValgtKursId(state) === getKursId(state);

export const erSoknadStengt = state => getState(state).erSoknadStengt;

export const getStegDetaljerFromId = (state, stegnr) => getAlleSteg(state)[stegnr];
export const erStegFullfort = (state, stegnr) => getStegDetaljerFromId(state, stegnr).fullfort;
export const getStegTypeFromId = (state, stegnr) => getStegDetaljerFromId(state, stegnr).type;


export const getAktivtStegnr = state => getState(state).aktivtStegnr;
export const getPabegyntStegnr = state => getState(state).pabegyntStegnr;
export const erAktivtSteg = (state, stegnr) => getAktivtStegnr(state) === stegnr;
export const erStegPabegynt = (state, stegnr) => getPabegyntStegnr(state) >= stegnr;

const validatorFromType = (state, type) => {
    switch (type) {
        case STEG_TYPE_EKSAMEN:
            return getEksamenBesvart(state);
        case STEG_TYPE_PROFIL:
            return isProfilFormValid(state);
        case STEG_TYPE_SPORRESKJEMA:
            return getIsSporreskjemaValid(state);
        case STEG_TYPE_PLANINFO:
            return isPlaninfobekreftelseValid(state);
        case STEG_TYPE_SAMTYKKE:
            return isSamtykkerValid(state);
        case STEG_TYPE_FAKTURAINFO:
            return isBetalingsinfoValid(state);
        default:
            return true;
    }
};

export const getStegValidators = state => getAlleSteg(state).reduce((acc, cur) => ({
    ...acc,
    [cur.type]: validatorFromType(state, cur.type)
}), {});

export const isStegValid = (state, type) => getStegValidators(state)[type];

export const isSoknadValid = state => getAlleSteg(state).every(steg => isStegValid(state, steg.type));

export const getFrister = state => getState(state).frister;

export const getEndreFrist = state => getFrister(state).find(frist => frist.type === actionToFristTypes[ACTION_TYPE_ENDRE])
    || createFrist({dato: FRIST_UBEGRENSET});


export const getBetalingsinfoFrist = state => {
    const fristObj = getFrister(state).find(frist =>
        frist.type === actionToFristTypes[ACTION_TYPE_ENDRE_FAKTURAINFO]
    );
    return fristObj && fristObj.dato;
};

export const getHarForetak = state => Boolean(getState(state).foretakkode);
export const getForetakNavn = state => getState(state).foretaknavn;
export const getSkalGodkjennesAvForetakVarselSkalVises = state => {
    const skalGodkjennes = getSkalGodkjennes(state);
    const harLedigePlasser = getAntallLedigePlasser(state) !== 0;
    const harStatusSomKanGodkjennes = [STATUS_IKKE_FULLFORT, STATUS_SENDT_TIL_GODKJENNING].includes(getStatus(state));
    return skalGodkjennes && harStatusSomKanGodkjennes && harLedigePlasser;
};

export const getForetakkodeNySoknad = state => getState(state).foretakkodeNySoknad;
export const getHashNySoknad = state => getState(state).hashNySoknad;

export const getAntallLedigePlasser = state => getState(state).antallLedigePlasser;
export const getSkalGodkjennes = state => getHarForetak(state) && getState(state).skalGodkjennes;

export default pamelding;