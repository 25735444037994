import Button from "../../../primitives/Button";
import {showPopup} from "../../../ducks/popup";
import {endreIconPath} from "../../../utils/icons";
import React from "react";
import {useDispatch} from "react-redux";
import Text from "../../../primitives/Text";
import DSFAdvarsel from "./DSFAdvarsel";

export const TYPE_DSF_FELT_ADRESSE = 'din_adresse';
export const TYPE_DSF_FELT_STATSBORGERSAP = 'ditt_statsborgerskap';
export const TYPE_DSF_FELT_NAVN = 'ditt_navn';

export const DSFHelpButton = ({typeFelt}) => {
    const dispatch = useDispatch();
    return (
        <Button onClick={() => dispatch(showPopup(getPopupContent(typeFelt)))}
                iconPath={endreIconPath}
                altText='trykk_for_å_redigere'
                className='help-button'
        />
    )
};

export const getPopupContent = (typeFelt) => {
    return {
        title: <Text id='folkeregisteret'/>,
        children: <DSFAdvarsel typeFelt={typeFelt}/>,
    };
};

export default DSFHelpButton;